export const primaryColor = '#B3CA1F';
export const primaryBackground = '#C0D82433';
export const primaryColorLight = '#8e91db';
export const primaryColorSlight = '#F7FAE5';
export const primaryColorDark = '#4e51a0';
export const black = '#202020';
export const white = '#fff';
export const offWhite = '#f9f9f9';
export const verified = '#B3CA1F';
export const unverified = '#EC8900';
export const alert = '#EC8900';
export const alertBackground = '#f9ead3';
export const dangerColor = '#E84427';
export const dangerColorBackground = '#E844271A';
export const darkBlue = '#28367B';
export const darkBlueIcon = '#273B4A';
export const transparentGreen = '#C0D824';
export const grayBlue = '#A9AFCA';
export const greenBackground = '#ECF3BD';
export const darkGreen = '#A2BA02';
export const disabledColor = '#d9d9d9';
