import { PersonNotificationData } from '@tymbe/schema/person-notification.interface';
import { Form, FormState } from 'informed';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SubmitButton } from '../../../components/buttons';
import TyCheckbox from '../../../components/inputs/TyCheckbox';
import Card from '../../../components/Layout/Card/Card';
import { RequestBody } from '../../../types/ReactQuery';
import { isPaginated } from '../../../types/TymbeApi';
import Spinner from '../../../utils/spinner';

type PersonNotificationForm = {
  lastMinuteNotification: boolean;
};

const NotificationSettingsCard = () => {
  const { person } = useUser();
  const queryClient = useQueryClient();
  const { id: personId } = person;

  const { data: personNotificationData, isLoading } = useQuery(['personNotificationData', personId], async () =>
    feathersClient.service('person-notification').find({ query: { person_id: personId, $limit: 1 } }));

  const { mutateAsync: patchPersonNotification } = useMutation(
    ['patchPersonNotification', personId],
    (req: RequestBody<Partial<PersonNotificationData>>): Promise<PersonNotificationData> =>
      feathersClient.service('person-notification').patch(req.id, req.body),
  );

  const { mutateAsync: createPersonNotification } = useMutation(
    ['createPersonNotification', personId],
    (body: Partial<PersonNotificationData>): Promise<PersonNotificationData> =>
      feathersClient.service('person-notification').create(body),
  );

  if (isLoading) {
    return <Spinner />;
  }

  const personNotification =
    isPaginated(personNotificationData) ? personNotificationData.data : [personNotificationData].flat();

  const onSubmit = async ({ values }: FormState<PersonNotificationForm>) => {
    const notificationData = {
      person_id: personId,
      last_min_app_change: values.lastMinuteNotification,
    };

    if (personNotification.length === 0) {
      try {
        await createPersonNotification(notificationData);
        SuccessAlert('Změny uloženy');
      } catch {
        ErrorAlert('Při vytváření údajů došlo k chybě');
      }
    } else {
      try {
        await patchPersonNotification({ id: personId, body: notificationData });
        SuccessAlert('Změny uloženy');
      } catch {
        ErrorAlert('Při editaci údajů došlo k chybě');
      }
    }
    await queryClient.invalidateQueries('personNotificationData');
  };

  const initialValues = {
    lastMinuteNotification: personNotification?.[0]?.last_min_app_change || false,
  };

  return (
    <Card>
      <h3> Nastavení notifikací: </h3>
      <Form
        name="person-notification-form"
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <div className="flex gap-6 flex-col">
          <TyCheckbox
            id="lastMinuteNotification"
            name="lastMinuteNotification"
            label="Upozornění na odhlášení brigádníka ze směny která začíná za méně než 24 hodin"
          />
          <SubmitButton
            className="self-start ty-button-primary-color"
            buttontext="Uložit nastavení"
          />
        </div>
      </Form>
    </Card>
  );
};
export default NotificationSettingsCard;
