import { Query } from '@feathersjs/feathers';
import { BlockReasons } from '@tymbe/schema/enums';
import { Affix, Col, Row } from 'antd';
import isNumber from 'lodash.isnumber';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NewBlock, { CreateNewBlockFormProps } from './NewBlock';
import blocksColumns from './utils/bansColumns';
import feathersClient from '../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../components/alerts';
import { SecondaryButton } from '../../components/buttons';
import Container from '../../containers';
import AdminTymberBlocksContainer from '../../containers/admin/tymber/blocks';
import { selectAdminError, loadCompanyThunk } from '../../data/store/slices/admin';
import { deleteBanThunk, loadBanThunk } from '../../data/store/slices/ban';
import useURLParamsHandler from '../../hooks/UrlParamsHandler/useURLParamsHandler';
import { TableSorterType } from '../company/index.page';

const DEFAULT_BANS_SORT = { created_at: -1 };

const AdminTymberBlocksPage = () => {
  const pageTitle = 'Blokace';

  const dispatch = useDispatch();
  const error = useSelector(selectAdminError);
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const [showModal, setShowModal] = useState(false);

  const [showPanel, setShowPanel] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const getSearch = (value?: string) => {
    let urlSearch = null;
    if (!value) return urlSearch;
    if (isNumber(+value) && !Number.isNaN(+value)) {
      urlSearch = { $or: [{ 'person.id': +value }, { 'creator.id': +value }], $skip: 0 };
    } else {
      const quickFilter = value.split(' ');
      urlSearch = {
        $modify: { quickFilter },
      };
    }
    return urlSearch;
  };

  const [searchParams, setSearchParams] = useState<Query | null>(getSearch(allSearchParams.search) || null);
  const [sort, setSort] = useState(allSearchParams.sort || DEFAULT_BANS_SORT);

  const [currentPage, setCurrentPage] = useState(allSearchParams.page || 1);
  const [pageSize, setPageSize] = useState(allSearchParams.pageSize || 10);
  const urlDateRange = allSearchParams.dateRange;
  const urlDateToMoment = urlDateRange && urlDateRange.map((date: string) => moment(date));

  const [dateRange, setDateRange] = useState<Moment[] | null>(urlDateToMoment || null);

  const onSearch = (value: string) => {
    if (value === '') {
      setSearchParams(null);
    }
    setCurrentPage(1);
    setSearchParams(getSearch(value));
    setUrlParamsHandler({ page: 1, search: value });
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const onFinishUnblock = () => {
    selectedRowKeys.forEach((x) => {
      // wrong infer from js file, lets just ignore it for now
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(deleteBanThunk(x));
    });

    setSelectedRowKeys([]);
    setShowPanel(false);
    SuccessAlert('Uživatelé odblokováni');
    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loadBanThunk({ $sort: sort }));
  };

  const onSelectChange = (newSelectedRowKeys: string[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (newSelectedRowKeys.length > 0) {
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  };

  const onPaginationChange = (page: number, newPageSize: number) => {
    setUrlParamsHandler({ page });
    setUrlParamsHandler({ pageSize: newPageSize });
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  const loadBans = (err: unknown, params: Query) => {
    if (err) {
      ErrorAlert('Nastala chyba');
    } else {
    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      dispatch(loadBanThunk(params));
    }
  };

  const onCreateNewBlock = async (data: CreateNewBlockFormProps) => {
    const newBlockedPerson = {
      company_id: data.company.id,
      person_id: data.person_id.value,
      note: data.note || null,
      branchoffice_id: data.department?.id || data.branchoffice?.id || null,
      reason: data.reason.value.reason,
      severity: Number(data.reason.value.severity),
    };

    if (newBlockedPerson.reason === BlockReasons.OTHER && !newBlockedPerson.note) {
      ErrorAlert('V případě důvodu "Jiné" je potřeba vyplnit poznámku blokace!');
      return;
    }

    try {
      await feathersClient.service('company-blocked-user').create(
        newBlockedPerson,
        { query: { $cancelApplications: true, $freeManShifts: data.freeManShifts, $notify: true } },
      );
      // wrong infer from js file, lets just ignore it for now
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(loadBanThunk({ $sort: sort }));
      SuccessAlert('Nová blokace vytvořena');
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (err.message === "The company doesn't match the branchoffice") {
        ErrorAlert('Firma musí odpovídat pobočce a oddělení');
        return;
      }
      // eslint-disable-next-line no-console
      console.error(err);
      throw new Error('Při pokusu o vytvoření blokace došlo k chybě');
    }
  };

  useEffect(() => {
    let params: Query = {
      $skip: pageSize * (currentPage - 1),
      $limit: pageSize,
      $sort: sort,
    };

    if (dateRange) {
      params['company_blocked_user.created_at'] = {
        $gte: dateRange[0].toISOString(),
        $lte: dateRange[1].toISOString(),
      };
    }

    params.$sort = sort;

    if (searchParams) {
      params = { ...params, ...searchParams };
    }
    loadBans(error, params);
    // eslint-disable-next-line
  }, [error, pageSize, currentPage, dateRange, searchParams, sort]);

  useEffect(() => {
    dispatch(loadCompanyThunk());
  }, [dispatch]);

  const onTableChange = (
    _pagination: unknown,
    _filters: unknown,
    sorter: TableSorterType,
  ) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'block_date':
          setSort({ created_at: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(DEFAULT_BANS_SORT);
          break;
      }
    } else {
      setSort(DEFAULT_BANS_SORT);
    }
  };

  useEffect(() => {
    setUrlParamsHandler({ sort });
  }, [sort]);

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <AdminTymberBlocksContainer
        pageTitle={pageTitle}
        columns={blocksColumns}
        selectedRowKeys={selectedRowKeys}
        showModal={onShowModal}
        onSearch={onSearch}
        onSelectChange={onSelectChange}
        onDateChange={setDateRange}
        onPaginationChange={onPaginationChange}
        currentPage={currentPage}
        onTableChange={onTableChange}
        defaultPageSize={pageSize}
      />
      <NewBlock
        onSubmit={({ values }) => onCreateNewBlock(values)}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      {showPanel ? (
        <Affix
          style={{ position: 'absolute', bottom: '18px', right: '18px' }}
        >
          <Row className="ty-attendance-panel">
            <Col xs={24} md={19} className="ty-attendance-panel-text-wrapper">
              <div className="ty-attendance-panel-text">
                Bylo vybráno
                {' '}
                {selectedRowKeys.length}
                {' '}
                záznamy(ů)
              </div>
            </Col>

            <Col xs={24} md={5} className="ty-attendance-panel-buttons">
              <div
                className="ty-attendance-panel-button-wrapper"
                style={{ justifyContent: 'end' }}
              >
                <SecondaryButton
                  buttontext="Odblokovat"
                  onClick={onFinishUnblock}
                />
              </div>
            </Col>
          </Row>
        </Affix>
      ) : null}
    </Container>
  );
};

export default AdminTymberBlocksPage;
