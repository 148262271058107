import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

const PopupNotification = () => {
  const notifications = useSelector((state) => state.components.popupNotifications);
  return createPortal(
    <div
      style={{ position: 'fixed', bottom: '30px', right: '30px', width: '600px' }}
    >
      {notifications.map(
        ({ props, component: Comp, id }) => <Comp {...props} id={id} key={id} className="ty-popout-notification" />,
      )}
    </div>,
    document.getElementById('notifications'),
  );
};

export default PopupNotification;
