import { SVGProps } from 'react';

import { PerkId } from '../../../../types/TymbeApi';
import { getPerkNameById } from '../../../../utils';

type AdultIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const AdultIcon = (props: AdultIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{getPerkNameById(PerkId.ADULT)}</title>
    <path d="M14.1471 14.73C13.3671 14.73 12.8471 14.35 12.8471 13.78C12.8471 13.19 13.3671 12.81 14.1471 12.81C14.9371 12.81 15.4571 13.19 15.4571 13.78C15.4571 14.35 14.9371 14.73 14.1471 14.73Z" />
    <path d="M14.1471 11.63C13.5471 11.63 13.1671 11.35 13.1671 10.9C13.1671 10.45 13.5471 10.17 14.1471 10.17C14.7571 10.17 15.1371 10.45 15.1371 10.9C15.1371 11.35 14.7571 11.63 14.1471 11.63Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM11.0471 14.05C11.0471 15.35 12.2471 16.16 14.1471 16.16C16.0571 16.16 17.2471 15.35 17.2471 14.05C17.2471 13.14 16.6571 12.43 15.6171 12.15C16.4471 11.91 16.9071 11.39 16.9071 10.69C16.9071 9.51999 15.8471 8.73999 14.1471 8.73999C12.4571 8.73999 11.3871 9.51999 11.3871 10.69C11.3871 11.4 11.8471 11.92 12.6871 12.15C11.6371 12.42 11.0471 13.13 11.0471 14.05ZM8.31012 8.89999C8.30012 9.83999 7.85012 10.29 6.91012 10.29H6.37012V11.52H7.94012V16H9.66012V8.89999H8.31012Z"
    />
  </svg>
);

export default AdultIcon;
