import moment, { MomentInput } from 'moment-timezone';

const NON_MEDICAL_INTERVAL_START = { hour: 4, minute: 30 };
const NON_MEDICAL_INTERVAL_END = { hour: 23, minute: 30 };

// Checks for datetime being in interval, where the entry medical examination is needed.
// For our purposes this interval is 23:30 - 4:30 (4:30 and 23:30 not included)
const isMedicalExaminationInterval = (start: MomentInput, end: MomentInput) => {
  const momentStart = moment.tz(start, 'Europe/Prague');
  const momentEnd = moment.tz(end, 'Europe/Prague');
  const medicalIntervalStart = momentStart.clone().set(NON_MEDICAL_INTERVAL_END).subtract(1, 'day');
  const medicalIntervalEnd = momentStart.clone().set(NON_MEDICAL_INTERVAL_START);
  const medicalIntervalStart2 = momentStart.clone().set(NON_MEDICAL_INTERVAL_END);
  const medicalIntervalEnd2 = momentStart.clone().set(NON_MEDICAL_INTERVAL_START).add(1, 'day');

  return Math.max(momentStart.valueOf(), medicalIntervalStart.valueOf()) < Math.min(momentEnd.valueOf(), medicalIntervalEnd.valueOf())
    || Math.max(momentStart.valueOf(), medicalIntervalStart2.valueOf()) < Math.min(momentEnd.valueOf(), medicalIntervalEnd2.valueOf());
};

export default isMedicalExaminationInterval;
