/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

export const setOpenSidebarKeysThunk = createAsyncThunk('ui/setOpenSidebarKeys', async (keys) => keys);

const setOpenSidebarKeysReducer = {
  [setOpenSidebarKeysThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [setOpenSidebarKeysThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.openSidebarKeys = action.payload;
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    loading: null,
    error: null,
    openSidebarKeys: [],
  },
  reducers: {},
  extraReducers: {
    ...setOpenSidebarKeysReducer,
  },
});

export const selectUserInterfaceError = createSelector(
  (state) => state.ui.error,
  (error) => {
    if (error !== null) return error;
    return undefined;
  },
);
export const selectUserInterfaceLoading = createSelector(
  (state) => state.ui.loading,
  (loading) => loading,
);

export const selectOpenSidebarKeys = createSelector(
  (state) => state.ui.openSidebarKeys,
  (openSidebarKeys) => {
    if (openSidebarKeys !== null && openSidebarKeys !== undefined) return openSidebarKeys;
    return undefined;
  },
);

export default uiSlice.reducer;
