import { FieldState, useFormApi } from 'informed';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import feathersClient from '../../apiClient';
import styles from '../../pages/shift/create/components/ShiftForm.module.css';
import { BranchofficeData } from '../../types/TymbeApi';
import TyCheckbox from '../inputs/TyCheckbox';
import TyDateRange, { TyDateRangeData } from '../inputs/TyDateRange/TyDateRange';
import TySelectBranchoffice from '../inputs/TySelectBranchoffice';

export interface TemporaryAllocationFormValues {
  date: TyDateRangeData,
  branchoffice: BranchofficeData[],
}

const TemporaryAllocationForm = () => {
  const { companyId } = useParams();

  const formApi = useFormApi<TemporaryAllocationFormValues>();

  const { data: branchoffices } = useQuery(['branchoffice', companyId], async () =>
    feathersClient.service('branchoffice').find({
      query: {
        $eager: '[parent]',
        company_id: companyId,
        parent_id: { $null: true },
      },
    }), { enabled: !!companyId });

  const onSelectAll = ({ value: selectAll }: FieldState<boolean>) => {
    formApi.setValue('branchoffice', selectAll
      ? branchoffices.data
      : []);
  };

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <TyDateRange
          name="date"
          label="Datum"
          className={styles.w_auto}
          sameMonth
          required
        />
        <TySelectBranchoffice
          companyId={companyId}
          type="branchoffice"
          name="branchoffice"
          label="Provozovny"
          isMulti
          required
        />
        <TyCheckbox
          name="all_branchoffices"
          label="Vybrat všechny provozovny"
          onChange={onSelectAll}
        />
      </div>
    </div>
  );
};

export default TemporaryAllocationForm;
