import { ComponentProps, ReactNode, useState } from 'react';

import Button from '../../../../components/buttons/Button';
import AlertModal from '../../../../components/modals/AlertModal';

type ButtonWithModalOwnProps = {
  icon: ReactNode,
  header: string,
  message: string,
  onConfirm: () => void,
  onDecline?: () => void,
};

type ButtonWithModalProps = ButtonWithModalOwnProps & Omit<ComponentProps<'button'>, keyof ButtonWithModalOwnProps>;

const ButtonWithModal = ({ onConfirm, onDecline, icon, header, message, ...rest }: ButtonWithModalProps) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    // Used to stop the row in mpsv table from expanding when any part of the modal is clicked
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()} className="min-w-fit">
      <Button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
        className="enabled:hover:bg-secondary-400 enabled:hover:text-bg bg-secondary-100 min-w-fit disabled:cursor-not-allowed disabled:opacity-70"
        {...rest}
      >{icon}
      </Button>
      <AlertModal
        title={header}
        message={message}
        showModal={openModal}
        onClose={() => setOpenModal(false)}
        onYes={() => {
          onConfirm();
          setOpenModal(false);
        }}
        onNo={onDecline}
        yesButtonText="Ano"
        cancelButtonText="Ne"
      />
    </div>
  );
};

export default ButtonWithModal;
