import { FieldProps, useField } from 'informed';
import React from 'react';
import { GroupBase, PropsValue } from 'react-select';
import { AsyncPaginate, AsyncPaginateProps } from 'react-select-async-paginate';

import './TyInput.css';
import CustomOption from './CustomOption';
import TyInputError from './TyInputError';

type Props<OptionType, Group extends GroupBase<OptionType>, Additional, IsMulti extends boolean> =
  AsyncPaginateProps<OptionType, Group, Additional, IsMulti>;

type DivProps = { label?: string & React.HTMLProps<HTMLDivElement> };

export type TyAsyncMultiSelectProps<Fields extends object,
  Option,
  Group extends GroupBase<Option>,
  Additional,
  IsMulti extends boolean> =
  Omit<Props<Option, Group, Additional, IsMulti>, keyof FieldProps<DivProps, Option | Option[], Fields>>
  & FieldProps<DivProps, Option | Option[], Fields>;

/**
 * @deprecated
 */
const TyAsyncMultiSelect =
  <Fields extends object, Option, Group extends GroupBase<Option>, Additional, IsMulti extends boolean = false>(
    props: TyAsyncMultiSelectProps<Fields, Option, Group, Additional, IsMulti>,
  ) => {
    const { validate } = props;
    const {
      fieldState,
      userProps,
      fieldApi,
      render,
    } = useField<typeof props, PropsValue<Option>>({ ...props });
    const {
      id,
      label,
      className,
      placeholder = ' ',
      loadOptions,
      isClearable = false,
      noOptionsMessage = () => 'Žádné výsledky',
      loadingMessage = () => 'Načítání',
      required = false,
      isDisabled = false,
    } = userProps;
    const { error, showError, focused, value } = fieldState;
    const { setValue, setTouched, setFocused } = fieldApi;

    const focusedClassName = focused || value ? 'ty_input_select_focused' : '';

    return render(
      <div className={`ty_input_field ty_multiselect ${showError && error ? 'ty_input_error' : ''} ${className || ''}`}>
        <AsyncPaginate
          isMulti
          inputId={id}
          value={value}
          placeholder={placeholder}
          className={`ty_select__container ty_input_multiselect ${focusedClassName}`}
          classNamePrefix="ty_select"
          components={{
            Option: CustomOption,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => setValue(e?.length ? e : undefined)}
          onBlur={(e) => {
            setFocused(false, e);
            setTouched(true, e);
          }}
          onFocus={(e) => setFocused(true, e)}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          maxMenuHeight={250}
          isClearable={isClearable}
          isDisabled={isDisabled}
          loadOptions={loadOptions}
          aria-invalid={showError}
          aria-describedby={`${id}-error`}
        />
        {label ? <label htmlFor={id}>{label}</label> : null}
        <TyInputError
          fieldState={fieldState}
          required={required}
          validate={validate}
        />
      </div>,
    );
  };

export default TyAsyncMultiSelect as typeof TyAsyncMultiSelect;
