/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { ComponentProps, ReactNode, useState } from 'react';

export type Option = {
  text: ReactNode,
  icon?: ReactNode,
  onClick?: () => void,
  disabled?: boolean,
};

type DropDownMenuOwnProps = {
  options: Option[],
  onClick?: () => void,
  className?: string,
};
type DropDownMenuProps = DropDownMenuOwnProps & Omit<ComponentProps<'div'>, keyof DropDownMenuOwnProps>;

const DropDownMenu = ({ options, onClick, className = '' }: DropDownMenuProps) => {
  const [key] = useState(Math.random().toString(36).substring(7));
  return (
    <div
      className={
        classNames(
          className,
          'absolute bg-bg w-64 py-2 -translate-x-[14.5rem] translate-y-[1px] rounded-lg shadow-lg text-secondary-700',
        )
      }
    >
      {
        options.map((option, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${key}${index}`}
            onClick={() => {
              option.onClick?.();
              onClick?.();
            }}
            className={classNames(
              'group !rounded-none w-full enabled:hover:bg-secondary-50 text-sm py-1 px-3 flex gap-2 items-center active:bg-secondary-100 disabled:cursor-not-allowed disabled:text-secondary-300 hover:cursor-pointer',
              { 'hover:bg-secondary-50': !option.disabled },
            )}
          >
            {option.icon}{option.text}
          </div>
        ))
      }
    </div>
  );
};

export default DropDownMenu;
