import { PersonData } from '@tymbe/schema/person.interface';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import { ErrorAlert } from '../../../components/alerts';
import { PrimaryButton } from '../../../components/buttons';
import { TrashIcon } from '../../../components/icons';
import AlertModal from '../../../components/modals/AlertModal';
import { RolesData } from '../../../types/TymbeApi';
import { Roles } from '../../../utils/enums';
import Spinner from '../../../utils/spinner';

import styles from '../../../components/tableRenders/HoverRow.module.css';

export type DeleteCompanyUserButtonProps = {
  companyPerson: PersonData;
};

const DeleteCompanyUserButton = ({ companyPerson }: DeleteCompanyUserButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const user = useUser();

  const { mutateAsync: deleteCompanyPerson, isLoading } = useMutation(
    ['deletePerson'],
    async (personId: number) =>
      feathersClient.service('person').remove(personId, { query: { $removeCompanyUser: true } }),
  );

  const hideDeleteButton = (userRole: RolesData[]) => {
    if (userRole.some((role) =>
      [Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN, Roles.COMPANY].includes(role.slug))) {
      return false;
    } return true;
  };

  const onOkClick = async () => {
    try {
      await deleteCompanyPerson(companyPerson.id);
      queryClient.invalidateQueries(['fetchCompanyUsers']);
      setShowModal(false);
    } catch (err) {
      ErrorAlert('Při odebírání uzivatele došlo k chybě');
    }
  };
  return (
    <>
      {!hideDeleteButton(user.role)
        && (
          <>
            <PrimaryButton
              className={`
        ty-row-on-hover-button
        ${styles.ty_hover_remove_button}
      `}
              onClick={() => setShowModal(true)}
              icon={<TrashIcon />}
            />
            {isLoading ? (
              <Spinner size="medium" />
            ) : (
              <AlertModal
                showModal={showModal}
                onClose={() => setShowModal(false)}
                title="Potvrdit odebrání uživatele"
                message={`Chcete opravdu odstranit uživatele: ${companyPerson.first_name} ${companyPerson.last_name}?`}
                onYes={() => onOkClick()}
              />
            )}
          </>
        )}
    </>
  );
};

export default DeleteCompanyUserButton;
