import { CzechHolidayCalculator } from '@tymbe/legislatives/czechia/CzechHolidayCalculator';
import { Holiday } from '@tymbe/legislatives/Holiday';
import { Moment } from 'moment';

export interface HolidayWithDate extends Holiday {
  date: Moment;
}

const holidayCache: Record<string, HolidayWithDate[]> = {};

export const getHolidaysOnDate = (date: Moment): HolidayWithDate[] => {
  if (!date) {
    return [];
  }
  const formattedDate = date.format('YYYY-MM-DD');
  if (holidayCache[formattedDate] === undefined) {
    holidayCache[formattedDate] = new CzechHolidayCalculator().getHolidaysOnDate(date)
      .map((holiday: Holiday) => ({
        ...holiday,
        date,
      }));
  }
  return holidayCache[formattedDate];
};

export const isDayHoliday = (date: Moment): boolean => getHolidaysOnDate(date).length !== 0;
