import moment from 'moment';
import * as XLSX from 'xlsx';

import { AttendanceResolution, AttendanceResolutionDisplay } from '../../enums';

const prepareXlsx = (data, skipHeader) => {
  const ws = XLSX.utils.json_to_sheet(data, { skipHeader });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws);
  return XLSX.write(wb, { type: 'buffer', bookType: 'xlsx' });
};

const exportDataToXLSX = (fileName, data, skipHeader = false) => {
  const dataToExport = prepareXlsx(data, skipHeader);
  const blob = new Blob([dataToExport], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  // Create hidden download button with download action containing our sheet
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = `${fileName}.xlsx`;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};

const renderXLSXEvaluation = (attendance) => {
  if (attendance.resolution !== AttendanceResolution.OK) {
    return AttendanceResolutionDisplay[attendance.resolution];
  }
  switch (attendance.job_evaluation) {
    case 99:
      return 'Pozitivní';
    case 0:
      return 'Negativní';
    default:
      return '-';
  }
};

export const mapConfirmedAttendanceForExport = (data) => data.map((item) => {
  const emoji = renderXLSXEvaluation(item);
  return {
    'ID Docházka': item.id,
    Firma: item.application.shift.company.name,
    Provozovna: item.application.shift.branchoffice?.parent?.name,
    Oddělení: item.application.shift.branchoffice?.name,
    Pozice: item.application.shift?.name,
    'ID Tymbera': item.application.person?.id,
    Jméno: item.application.person.first_name,
    Přijmení: item.application.person.last_name,
    'Směna od ':
      `${moment(item.application.shift.start_time).format('DD.MM.YYYY')} ${moment(item.application.shift.start_time).format('HH:mm')}`,
    'Směna do': `${moment(item.application.shift.end_time).format('DD.MM.YYYY')} ${moment(item.application.shift.end_time).format('HH:mm')}`,
    'Potvrzený čas': +((item.confirmed_time / 60) + (item.confirmed_overtime / 60)),
    'Odměna nadplán': +(item.confirmed_credit_time / 60),
    Celkem: +((+item.confirmed_time + +item.confirmed_overtime) / 60),
    Hodnocení: emoji,
    Potvrdil: `${item.confirmedBy?.first_name} ${item.confirmedBy?.last_name}`,
    'Čas potvzení': moment(item.application.confirmed_at).format('HH:mm DD.MM.YYYY'),
  };
});

export default exportDataToXLSX;
