import { Link } from 'react-router-dom';

import HoverRow from './HoverRow';
import styles from './HoverRow.module.css';
import TimeoutButton from '../buttons/TimeoutButton';
import { EditIcon, TrashIcon } from '../icons';

export interface HoverRowEditDeleteButtonsProps {
  editLink: string,
  hideDelete?: boolean,
  hideEdit?: boolean,
  disableDelete?: boolean,
  removeNotificationMessage: string,
  removeTimeoutFunction: () => void,
  onRemoveOnClick: () => void,
  onRemoveCancel: () => void,
}

const HoverRowEditDeleteButtons = (props: HoverRowEditDeleteButtonsProps) => {
  const {
    editLink,
    removeNotificationMessage,
    removeTimeoutFunction,
    onRemoveCancel,
    onRemoveOnClick,
    hideDelete = false,
    hideEdit = false,
    disableDelete = false,
  } = props;

  return (
    <HoverRow>
      {!hideDelete ? (
        <TimeoutButton
          timeout={10000}
          className={`
              ty-row-on-hover-button
              ${styles.ty_hover_remove_button}
            `}
          type="link"
          timeoutFunction={removeTimeoutFunction}
          notificationMessage={removeNotificationMessage}
          onClick={onRemoveOnClick}
          disabled={!!disableDelete}
          onNotificationCancel={onRemoveCancel}
        >
          <TrashIcon />
        </TimeoutButton>
      ) : ''}
      {!hideEdit ? (
        <Link
          className={`
              ant-btn-primary
              ty-row-on-hover-button
              ${styles.ty_hover_edit_button}
            `}
          to={{
            pathname: editLink,
          }}
        > <EditIcon />
        </Link>
      ) : ''}
    </HoverRow>
  );
};

export default HoverRowEditDeleteButtons;
