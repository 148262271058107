import { PositionData } from '@tymbe/schema/position.interface';
import { Form, FormState } from 'informed';
import { useMutation, useQueryClient } from 'react-query';

import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../../components/buttons';
import TyInput from '../../../../components/inputs/TyInput';
import ModalShell from '../../../../components/modals/ModalShell';
import { RequestBody } from '../../../../types/ReactQuery';

export interface EditPositionProps {
  showModal: boolean,
  setShowModal: (value: boolean) => void,
  position?: Partial<PositionData>
}

const EditPositionModal = ({ showModal, setShowModal, position }: EditPositionProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: patchPosition } = useMutation(
    ['patchPosition'],
    async (req: RequestBody<Partial<PositionData>>): Promise<PositionData> =>
      feathersClient.service('position').patch(req.id, req.body),
  );

  const { mutateAsync: createPosition } = useMutation(
    ['createPosition'],
    async (positionData: Partial<PositionData>): Promise<PositionData> =>
      feathersClient.service('position').create(positionData),
  );

  const onSubmit = async ({ values }: FormState<Partial<PositionData>>) => {
    try {
      const positionData = { name: values.name };

      if (position?.id) {
        await patchPosition({ id: position.id, body: positionData });
      } else {
        await createPosition(positionData);
      }

      queryClient.invalidateQueries(['admin/positions']);
      setShowModal(false);
    } catch {
      ErrorAlert('Při úpravě pozic došlo k chybě');
    }
  };

  return (
    <ModalShell
      showModal={showModal}
      onClose={() => setShowModal(false)}
    >
      <Form name="position_edit" initialValues={{ name: position?.name }} onSubmit={onSubmit}>
        <h3 className="ty-h3 text-align-start">
          {position?.id ? 'Úprava pozice' : 'Nová pozice'}
        </h3>
        <TyInput name="name" placeholder="Název pozice" required />
        <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
        <SecondaryButton className="ml-3" buttontext="Zavřít" onClick={() => setShowModal(false)} />
      </Form>
    </ModalShell>
  );
};

export default EditPositionModal;
