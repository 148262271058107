import { FieldProps, InputProps } from 'informed';

import TyInput, { TyInputProps } from './TyInput';

const TyAutoTrimInput = <Fields extends object, T>(
  { mask, ...props }: FieldProps<InputProps & TyInputProps, T, Fields>,
) => (
  <TyInput
    mask={(value: string) => {
      let temp = value;
      if (typeof mask === 'function') {
        temp = mask(value);
      }
      return temp?.trim?.();
    }}
    maskOnBlur
    {...props}
  />
);

export default TyAutoTrimInput;
