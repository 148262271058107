import { ClipboardEvent, KeyboardEvent } from 'react';

export function formatOnPasteForZip(event: ClipboardEvent) {
  event.preventDefault();
  const newValue = event.clipboardData.getData('text/plain')
    .replace(/ /g, '') // Remove spaces
    .replace(/\D+/g, ''); // Remove non-digit characters
  const input = event.target as HTMLInputElement;
  const lastValue = input.value;
  input.value = newValue;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  input.dispatchEvent(new Event('input', { bubbles: true }));
}

export function blockInvalidCharactersForZip(event: KeyboardEvent<HTMLInputElement>) {
  if (
    event.key === ' '
    || (
      !event.ctrlKey
      && ![0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
        .map(String)
        .includes(event.key)
    )
  ) {
    event.preventDefault();
  }
}
