import Protect from '../../apiClient/Protect';
import { Roles } from '../../utils/enums';
import { ProtectedRouteProps } from '../protectedRoute';

const CompanyRoute = ({ children }: ProtectedRouteProps) => (
  <Protect auth={
    [
      Roles.SUPER_ADMIN,
      Roles.TYMBE_ADMIN,
      Roles.ADMIN,
      Roles.TYMBE_COORDINATOR,
      Roles.COMPANY,
      Roles.BRANCHOFFICE_MANAGER,
      Roles.BRANCHOFFICE_SUPERVISOR,
      Roles.SHIFT_SUPERVISOR,
    ]
  }
  >{children}
  </Protect>
);

export default CompanyRoute;
