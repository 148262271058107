import classNames from 'classnames';
import moment from 'moment-timezone';
import { HTMLAttributes } from 'react';

interface TyCalWeekEventProps<T = any> extends HTMLAttributes<HTMLDivElement> {
  date?: string;
  day?: number | string;
  dayEnd?: number | string;
  data: T;
}

const TyCalWeekEvent = ({ date, day, dayEnd, children, className, ...props }: TyCalWeekEventProps) => {
  if (date) {
    // just convinience
    // eslint-disable-next-line no-param-reassign
    day = moment(date).get('weekday');
  }

  return (
    <div className={classNames('ty-cal-week-event', className)} {...props} data-day={day} data-day-end={dayEnd}>
      {children}
    </div>
  );
};

export default TyCalWeekEvent;
