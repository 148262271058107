import classNames from 'classnames';
import { ComponentProps, useState } from 'react';

import MoreIcon from './icons/MoreIcon';
import DropDownMenu, { Option } from './ShiftDetail/DropDownMenu';
import useOutsideClick from './utils';

type OptionsOwnProps = {
  showMenu?: boolean,
  setShowMenu?: (value: React.SetStateAction<boolean>) => void,
  menuOptions: Option[]
};

type OptionsProps = OptionsOwnProps & Omit<ComponentProps<'div'>, keyof OptionsOwnProps>;

const Options = ({ menuOptions, showMenu, setShowMenu, className = '', ...rest }: OptionsProps) => {
  const [menu, setMenu] = useState(false);
  const handleClick = () => {
    if (setShowMenu) {
      setShowMenu((prev) => !prev);
    } else {
      setMenu((prev) => !prev);
    }
  };
  const handleClose = () => {
    if (setShowMenu) {
      setShowMenu(false);
    } else {
      setMenu(false);
    }
  };
  const ref = useOutsideClick(handleClose);
  return (
    <div
      className={classNames('', className)}
      ref={ref}
      {...rest}
    >
      <button
        type="button"
        className="p-0 h-6"
        aria-label="more options button"
        onClick={handleClick}
      >
        <MoreIcon />
      </button>
      { (showMenu || menu) && (
        <DropDownMenu options={menuOptions} onClick={handleClose} />
      ) }
    </div>
  );
};

export default Options;
