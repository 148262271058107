import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import Container from '../../../containers';
import CreateContainer from '../../../containers/create';
import { signUpUserThunk } from '../../../data/store/slices/auth';

const SignUpPage = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const onFinish = async (credentials) => {
    const { error } = await dispatch(signUpUserThunk(credentials));
    if (error) {
      if (error.name === 'Conflict') {
        ErrorAlert('Chyba. Emailová adresa je již zabraná.', 10);
      } else {
        ErrorAlert('Nepodařilo se nám vytvořit účet. Prosím zkuste znovu.', 10);
      }
    } else {
      SuccessAlert('Registrace proběhla úspěšně');
      ReactGA.event({
        category: 'pre_registration',
        action: 'pre_registration',
        label: 'pre_registration', // optional
      });
      history('/verify/email-sent');
    }
  };

  const onFinishFailed = (err) => {
    err.errorFields.forEach((er) => ErrorAlert(`Chyba : ${er.errors[0]}`));
  };

  return (
    <Container
      header
      contentstyle={{ marginTop: '4%' }}
      iconcolor="#B3CA1F"
      background="#F3F5FD"
      footer={false}
    >
      <CreateContainer onFinish={onFinish} onFinishFailed={onFinishFailed} />
    </Container>
  );
};

export default SignUpPage;
