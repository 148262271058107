import { HTMLAttributes, ReactNode } from 'react';

import styles from './ShiftCard.module.css';

export type ShiftCardProps = HTMLAttributes<HTMLDivElement> & {
  image?: string;
  description: ReactNode;
  label: string;
  time: ReactNode;
  price: ReactNode;
  credits: ReactNode;
  sum: ReactNode;
  lastMinute?: boolean;
  lastPlace?: boolean;
  href?: string;
};

const ShiftCard = ({
  lastPlace = false,
  lastMinute = false,
  image = undefined,
  description,
  label,
  time,
  price,
  credits,
  sum,
  className,
  href = undefined,
  ...rest
}: ShiftCardProps) => (
  <div className={`${styles.wrapper} ${className ?? ''}`} {...rest}>
    {
      lastPlace || lastMinute
        ? (
          <div className={styles.tags}>
            {lastMinute && <div className={styles.tags_lastMinute}>Brzy končí</div>}
            {lastPlace && <div className={styles.tags_lastPlace}>Poslední místo</div>}
          </div>
        )
        : null
    }
    <div className={styles.body}>
      <div className={styles.body_column}>
        <div className={styles.description}>
          {image
            ? (
              <img
                className={styles.ty_job_card__description__image}
                src={image}
                alt="Logo"
              />
            )
            : <div className={styles.description_image_placeholder} />}
          <div className={styles.ty_job_card__description__content}>{description}</div>
        </div>
        <a className={styles.label} href={href}>{label}</a>
      </div>
      <div className={`${styles.body_column} ${styles.body_right_column}`}>
        <div className={styles.info}>
          <div className={styles.info_time}>{time}</div>
          <div className={styles.info_price}>{price}</div>
          <div className={styles.info_credits}>{credits}</div>
        </div>
        <div className={styles.sum}>{sum}</div>
      </div>
    </div>
  </div>
);

export default ShiftCard;
