import { selectCountryOptions } from './TySelectCountry.utils';
import { TySelect } from '../TySelect';

const TySelectCountry = ({
  name = 'country',
  ...props
}) =>
  (
    <TySelect
      name={name}
      {...props}
      options={selectCountryOptions()}
    />
  );
export default TySelectCountry;
