import classNames from 'classnames';
import { ReactNode } from 'react';

type TyCalWeekGroupHeadProps = {
  children: ReactNode;
  className?: string;
};

export default function TyCalWeekGroupHead({ children, className }: TyCalWeekGroupHeadProps) {
  return (
    <div className={classNames('ty-cal-week-group-head', className)}>{children}</div>
  );
}
