import React from 'react';

import { ThumbsDownIcon, ThumbsUpIcon } from '../../../components/icons';
import { AttendanceData } from '../../../types/TymbeApi';
import { dangerColor, verified } from '../../../utils/colors';
import { AttendanceResolutionDisplay } from '../../../utils/enums';

export interface AttendanceDetailsProps {
  attendance: AttendanceData;
}
const AttendanceDetails = ({ attendance }: AttendanceDetailsProps) => {
  const minutesToHours = (minutes: number) => Number(minutes / 60).toFixed(2);

  // 0-49 bad 50-99 good
  const jobEvaluationIcon = (evaluation: number) => {
    if (evaluation === 49) {
      return <span>-</span>;
    }
    if (evaluation > 49) {
      return <ThumbsUpIcon iconcolor={verified} />;
    }
    return <ThumbsDownIcon iconcolor={dangerColor} />;
  };

  return (
    <div className="flex">
      <div className="text-secondary font-semibold text-sm leading-5 w-40">
        <div>
          Potvrzený čas:
        </div>
        <div>
          Stav docházky:
        </div>
        <div>
          Hodnocení:
        </div>
        <div>
          Dotazník vyplněn:
        </div>
      </div>
      <div className="ml-10 w-72">
        <div className="pb-1">
          {minutesToHours(Number(attendance.confirmed_time)
          + Number(attendance.confirmed_overtime))} + {minutesToHours(attendance.confirmed_credit_time)} hod
        </div>
        <div className="pb-1">
          {AttendanceResolutionDisplay[attendance.resolution]}
        </div>
        <div className="pb-1">
          {attendance.job_evaluation || attendance.job_evaluation === 0
            ? jobEvaluationIcon(attendance.job_evaluation)
            : '-'}
        </div>
        <div className="pb-1">
          {attendance?.creditTransaction ? 'Ano' : 'Ne'}
        </div>
      </div>
    </div>
  );
};

export default AttendanceDetails;
