import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

import { CheckmarkIcon, ExclamationCircleReverseIcon } from './index';
import './validation-tooltip.css';

/** @type React.FC<PropTypes.InferType<ValidationTooltipIcon.propTypes>> */
const ValidationTooltipIcon = ({ isValid = false, errorMessage = 'Nevalidní vstup' }) => (
  isValid ? (<span className="ty-input-icon"><CheckmarkIcon /></span>) : (
    <Tooltip overlay={errorMessage} placement="top" trigger={['hover']} overlayClassName="ty-validation-tooltip">
      <span className="ty-input-icon"> <ExclamationCircleReverseIcon /> </span>
    </Tooltip>
  ));

export default ValidationTooltipIcon;

ValidationTooltipIcon.propTypes = {
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
};
