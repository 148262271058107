/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import feathersClient from '../../../../apiClient';

export const signUpUserThunk = createAsyncThunk('auth/signUpUser', async (data) => {
  const user = {
    username: data.email,
    password: data.password,
    person: {
      first_name: data.first_name,
      last_name: data.last_name,
      ...data.person,
    },
  };
  return feathersClient.service('login').create(user);
});

const signUpReducers = {
  [signUpUserThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [signUpUserThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [signUpUserThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const signInUserThunk = createAsyncThunk('auth/signInUser', async (data) => {
  if (!data) {
    await feathersClient.reAuthenticate();
    const { user, accessToken } = await feathersClient.get('authentication');

    return { user, accessToken };
  }
  const authData = { username: data.email, password: data.password };

  await feathersClient.authenticate({
    strategy: 'local',
    ...authData,
  });

  const { user, accessToken, authentication } = await feathersClient.get('authentication');

  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return { user, accessToken, authentication };
});

const signInReducers = {
  [signInUserThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [signInUserThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [signInUserThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.person = action.payload.user.person;
    state.userID = action.payload.user.id;
    state.token = action.payload.accessToken;
  },
};

export const signOutUserThunk = createAsyncThunk('auth/signOutUser', async () => {
  await feathersClient.logout();
});

const signOutReducers = {
  [signOutUserThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [signOutUserThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [signOutUserThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
    state.person = null;
    state.userID = null;
    state.token = null;
  },
};

export const verifyAccountThunk = createAsyncThunk('auth/verifyAccount', async (token) => {
  const result = await feathersClient.service('auth-management').create({
    action: 'verifySignup',
    token,
  });
  return result;
});

const verifyAccountReducers = {
  [verifyAccountThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [verifyAccountThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [verifyAccountThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const forgotPasswordThunk = createAsyncThunk('auth/forgotPassword', async (email) => {
  await feathersClient.service('authmanagement').create({
    action: 'sendResetPwd',
    value: { email },
  });
});

const forgotPasswordReducers = {
  [forgotPasswordThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [forgotPasswordThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [forgotPasswordThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const resetPasswordThunk = createAsyncThunk('auth/resetPassword', async (data) => {
  const { token, password } = data;
  await feathersClient.service('authmanagement').create({
    action: 'resetPwdLong',
    value: { token, password },
  });
});

const resetPasswordReducers = {
  [resetPasswordThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [resetPasswordThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [resetPasswordThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const resendConfirmationThunk = createAsyncThunk(
  'auth/resendEmailConfirmation',
  async (data) => {
    const { email } = data;
    await feathersClient.service('auth-management').create({
      action: 'sendVerifySignup',
      value: {
        email,
      },
    });
  },
);

const resendConfirmationReducers = {
  [resendConfirmationThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [resendConfirmationThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [resendConfirmationThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const updateEmailThunk = createAsyncThunk('auth/updateEmail', async (data) => {
  const { currentEmail, password, updatedEmail, _id } = data;

  await feathersClient.service('authmanagement').create({
    action: 'identityChange',
    value: {
      user: { email: currentEmail },
      password,
      changes: { email: updatedEmail },
    },
  });

  return feathersClient.service('users').patch(_id, {
    isVerified: false,
    email: updatedEmail,
  });
});

const updateEmailReducers = {
  [updateEmailThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [updateEmailThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [updateEmailThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const updatePasswordThunk = createAsyncThunk(
  'auth/updatePassword',
  async ({ email, password, oldPassword }) => {
    await feathersClient.service('authmanagement').create({
      action: 'passwordChange',
      value: {
        user: { email },
        oldPassword,
        password,
      },
    });
  },
);

const updatePasswordReducers = {
  [updatePasswordThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [updatePasswordThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [updatePasswordThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: null,
    error: null,
    userID: null,
    token: null,
    person: null,
  },
  reducers: {},
  extraReducers: {
    ...signUpReducers,
    ...signInReducers,
    ...signOutReducers,
    ...verifyAccountReducers,
    ...forgotPasswordReducers,
    ...resetPasswordReducers,
    ...updateEmailReducers,
    ...updatePasswordReducers,
    ...resendConfirmationReducers,
  },
});

export const selectToken = createSelector(
  (state) => state.auth.token,
  (token) => token,
);

export const selectAuthError = createSelector(
  (state) => state.auth.error,
  (error) => {
    if (error !== null) return error;
    return undefined;
  },
);
export const selectAuthPerson = createSelector(
  (state) => state.auth.person,
  (person) => {
    if (person !== null) {
      return person;
    }
    return undefined;
  },
);
export const selectLoading = createSelector(
  (state) => state.auth.loading,
  (loading) => {
    if (loading !== null) return loading;
    return undefined;
  },
);

export const selectUserID = createSelector(
  (state) => state.auth.userID,
  (userID) => {
    if (userID !== null) return userID;
    return undefined;
  },
);

export const selectIsAuthenticated = createSelector(
  (state) => state.auth.userID,
  (state) => state.auth.token,
  (userID, token) => userID !== null && token !== null,
);

export default authSlice.reducer;
