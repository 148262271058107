import { BlockReasons } from '@tymbe/schema/enums';
import { Col, InputNumber, Radio, Row, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import feathersClient from '../../apiClient';
import { useUser } from '../../apiClient/ApiContext';
import { white, dangerColor, darkBlueIcon, disabledColor } from '../../utils/colors';
import { AttendanceResolution, BlockReasonDisplay, Roles } from '../../utils/enums';
import { SecondaryButton } from '../buttons';

export const UnapprovedAttendanceExtendedRow = ({
  record,
  onBlockDataChange,
  onMankoDataChange,
  onAbsenceDataChange,
  blockData = {
    blocked: false,
    text: '',
    reason: {
      value: BlockReasons.LOW_PERFORMANCE,
      title: 2,
      label: BlockReasonDisplay.low_performance,
    },
  },
  mankoData = { setManko: false, mankoValue: null, mankoReason: null, applicationId: null },
  absenceData = { resolution: null },
}) => {
  const [blockReason, setBlockReason] = useState();
  const [blockNote, setBlockNote] = useState(blockData.text);
  const [mankoVal, setMankoVal] = useState(mankoData.mankoValue);
  const [mankoReason, setMankoReason] = useState(mankoData.mankoReason);
  const [absenceValue, setAbsenceValue] = useState(absenceData.absenceValue);
  const user = useUser();

  const isTymbe = user.hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);

  const disableCompany = !isTymbe && user.person.company?.length && user.person.branchoffice?.length;
  const disableBranchoffice = !isTymbe && user.person.branchoffice?.length && !user.person.branchoffice?.some((brnch) => !brnch.parent_id);

  useEffect(() => {
    setMankoVal(mankoData.mankoValue);
  }, [setMankoVal, mankoData]);

  useEffect(() => {
    setAbsenceValue(absenceData.resolution);
  }, [setAbsenceValue, absenceData]);

  const getMankoStyle = () => {
    if (absenceValue) {
      return { borderColor: disabledColor, backgroundColor: disabledColor };
    }
    return {
      backgroundColor: mankoData.setManko ? dangerColor : white,
      color: mankoData.setManko ? white : darkBlueIcon,
    };
  };

  const getBlockStyle = () => {
    if (absenceValue === AttendanceResolution.UNEXCUSED_ABSENCE) {
      return { borderColor: disabledColor, backgroundColor: disabledColor };
    }
    return {
      backgroundColor: blockData.blocked ? dangerColor : white,
      color: blockData.blocked ? white : darkBlueIcon,
    };
  };

  // only Tymbe can set block reason to prevention
  const { data: blockOptions } = useQuery(['blockOptions'], () =>
    feathersClient.service('block-reasons').find({
      query: {
        $and: [
          ...!isTymbe ? [{ id: { $ne: BlockReasons.PREVENTION } }] : [],
        ],
      },
    }));

  const selectOptions = blockOptions?.data?.map((blockReasonOption) => ({
    value: blockReasonOption.id,
    label: BlockReasonDisplay[blockReasonOption.id],
  })) || [];

  return (
    <div>
      <Row gutter={[16, 24]} style={{ marginLeft: '50px' }}>
        <Col style={{ margin: '10px' }}>
          <Radio.Group
            className="ty-radio-group danger"
            optionType="button"
            value={absenceValue}
          >
            <Radio.Button
              onClick={(change) => onAbsenceDataChange(record, { resolution: change.target.value })}
              value={AttendanceResolution.EXCUSED_ABSENCE}
            >Omluvená absence
            </Radio.Button>
            <Radio.Button
              onClick={(change) => onAbsenceDataChange(record, { resolution: change.target.value })}
              value={AttendanceResolution.UNEXCUSED_ABSENCE}
            >Neomluvená absence
            </Radio.Button>
            <Radio.Button
              onClick={(change) => onAbsenceDataChange(record, { resolution: change.target.value })}
              value={AttendanceResolution.CANCEL}
            >Storno
            </Radio.Button>
          </Radio.Group>
        </Col>

        <Tooltip title={absenceValue ? 'Nelze zadat manko pro absence nebo stornované docházky.' : null}>
          <Col>
            <SecondaryButton
              disabled={!!absenceValue}
              className={absenceValue ? 'disabled ty-button-secondary' : 'ty-button-secondary'}
              // TODO: Avoid using inline css
              style={{ ...getMankoStyle(), margin: '10px' }}
              onClick={() => onMankoDataChange(record, { setManko: !mankoData.setManko })}
            >
              Zadat manko
            </SecondaryButton>
          </Col>
        </Tooltip>

        <Tooltip
          title={absenceValue === AttendanceResolution.UNEXCUSED_ABSENCE
            ? 'Nelze zadat blokaci pro neomluvené docházky.' : null}
        >
          <Col>
            <SecondaryButton
              disabled={absenceValue === AttendanceResolution.UNEXCUSED_ABSENCE}
              className={absenceValue === AttendanceResolution.UNEXCUSED_ABSENCE
                ? 'disabled ty-button-secondary' : 'ty-button-secondary'}
              style={{ ...getBlockStyle(), margin: '10px' }}
              onClick={() => onBlockDataChange(record, { blocked: !blockData.blocked })}
            >
              Ukončit spolupráci
            </SecondaryButton>
          </Col>
        </Tooltip>
      </Row>
      <Row gutter={[16, 24]} style={{ marginLeft: '100px ' }}>
        {mankoData.setManko ? (
          <>
            <Col>
              <InputNumber
                style={{ margin: '10px', minHeight: '56px' }}
                placeholder="Kč"
                rows={2}
                value={mankoVal}
                onBlur={() => onMankoDataChange(record, { mankoValue: mankoVal })}
                onChange={setMankoVal}
              />
            </Col>
            <Col>
              <TextArea
                style={{ margin: '10px' }}
                rows={2}
                cols={70}
                placeholder="Důvod udání manka..."
                value={mankoReason}
                onBlur={() => onMankoDataChange(record, { mankoReason })}
                onChange={(e) => setMankoReason(e.target.value)}
              />
            </Col>
          </>
        ) : null}
        {blockData.blocked ? (
          <div className="mt-2">
            Chci blokovat pro:
            <Col>
              <Radio.Group
                className="ty-radio-group danger m-2"
                optionType="button"
                isRequired
              >
                <Radio.Button
                  onClick={() => onBlockDataChange(record, { branchoffice_id: null })}
                  value={record.shift.company.id}
                  disabled={!!disableCompany}
                >Firmu {record.shift.company.name}
                </Radio.Button>
                <Radio.Button
                  onClick={(change) => onBlockDataChange(record, { branchoffice_id: change.target.value })}
                  value={record.shift.branchoffice.parent.id}
                  disabled={!!disableBranchoffice}
                >Provozovnu {record.shift.branchoffice.parent.name}
                </Radio.Button>
                <Radio.Button
                  onClick={(change) => onBlockDataChange(record, { branchoffice_id: change.target.value })}
                  value={record.shift.branchoffice.id}
                >Oddělení {record.shift.branchoffice.name}
                </Radio.Button>
              </Radio.Group>
              <Select
                name="block_reason"
                size="large"
                mode="single"
                placeholder="Důvod pro zablokování uživatele"
                className="m-2 w-8/12"
                options={selectOptions}
                onBlur={() => onBlockDataChange(record, {
                  reason: blockReason,
                  severity: blockOptions?.data?.find((opt) => opt.id === blockReason).severity,
                })}
                onChange={(e) => setBlockReason(e)}
              />
              <div
                className="w-11/12"
              >
                <TextArea
                  className="m-2"
                  rows={2}
                  cols={70}
                  placeholder="Poznámka k blokaci..."
                  value={blockNote}
                  onBlur={() => onBlockDataChange(record, { text: blockNote })}
                  onChange={(e) => setBlockNote(e.target.value)}
                />
              </div>
            </Col>
          </div>
        ) : null}
      </Row>
    </div>
  );
};
UnapprovedAttendanceExtendedRow.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
  blockData: PropTypes.shape({ blocked: PropTypes.bool, text: PropTypes.string }),
  mankoData: PropTypes.shape({ setManko: PropTypes.bool, mankoValue: PropTypes.number }),
  absenceData: PropTypes.shape({ resolution: PropTypes.string }),
  onBlockDataChange: PropTypes.func.isRequired,
  onMankoDataChange: PropTypes.func.isRequired,
  onAbsenceDataChange: PropTypes.func.isRequired,
};

export const ApprovedAttendanceExpandedRow = ({ record }) => (
  <Row gutter={[20, 24]}>
    <Col offset={1} className="ty-poppins-11">
      ID Docházky: {record.id}
    </Col>
    <Col className="ty-poppins-11">
      Oddělení: {record.branchoffice_department}
    </Col>
    <Col className="ty-poppins-11">
      Docházku potvrdil/a: {`${record.confirmedBy?.first_name} ${record.confirmedBy?.last_name}`}
    </Col>
    <Col className="ty-poppins-11">
      Čas potvrzení: {moment(record.created_at).format('HH:mm DD.MM.YYYY')}
    </Col>
    <Col className="ty-poppins-11">
      Interní označení: {record.application.shift.external_id || ' - '}
    </Col>
  </Row>
);
ApprovedAttendanceExpandedRow.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
};
