import TyDateInput from '../../../../components/inputs/TyDateInput';
import TyTextArea from '../../../../components/inputs/TyTextArea';

const UserBanForm = () => (
  <div className="flex flex-1 flex-col gap-5">
    <TyTextArea id="note_id" required rows={5} label="Důvod" name="note" />
    <div className="flex md:flex-row flex-1 flex-col gap-5">
      <TyDateInput id="start_time_id" className="flex-grow" label="Ban od" name="start_time" />
      <TyDateInput id="end_time_id" className="flex-grow" label="Ban do" name="end_time" />
    </div>
  </div>
);

export default UserBanForm;
