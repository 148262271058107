type SearchElements = { value: number | string, label: string };

const factoryLoadStaticOptions =
  (options: SearchElements[]) =>
    (search: string, prevOptions: Array<unknown>) => {
      if (search.length >= 0) {
        let filteredOptions;
        if (search.length === 0) {
          filteredOptions = options;
        } else {
          filteredOptions = options.filter(({ label }) => label === search);
        }

        const hasMore = filteredOptions.length > prevOptions.length + 10;
        const slicedOptions = filteredOptions.slice(
          prevOptions.length,
          prevOptions.length + 10,
        );

        return {
          options: slicedOptions,
          hasMore,
        };
      }
      return {
        options: [],
      };
    };

export default factoryLoadStaticOptions;
