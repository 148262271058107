/* eslint-disable react/button-has-type */
const PrimaryButton = ({ className, ...rest }: JSX.IntrinsicElements['button']) => (
  <button
    {...rest}
    className={`
      border
      p-1.5
      flex
      align-center
      !rounded-lg
      font-bold
      text-bg
      bg-primary
      border-primary
      hover:border-primary-300
      hover:bg-primary-300
      transition
      ease-in
      ${className}
    `}
  />
);

export default PrimaryButton;
