import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { SubmitButton } from '../../components/buttons';
import { ResponsiveGrid } from '../../components/grids';
import { LockIcon, UserAddIcon } from '../../components/icons';
import Illustration from '../../components/illustration';
import { LockPick } from '../../components/illustrations';
import { PasswordFormInput, SimpleFormInput, SimpleFormItem } from '../../components/inputs';
import LocaleSwitcher from '../../components/LocaleSwitcher';
import { SimpleHeading3Text } from '../../components/texts';
import { darkBlue } from '../../utils/colors';

const AuthenticateContainer = ({ disabled = false, onFinish, onFinishFailed, title }) => {
  const intl = useIntl();

  return (
    <>
      <Illustration svg={<LockPick />} />
      <ResponsiveGrid xs={24} sm={24} md={18} lg={12} xl={8}>
        <Row className="ty-page-bottom" align="center">
          <div className="flex justify-center" style={{ padding: '7px 0 12px   0' }}>
            <SimpleHeading3Text>{title}</SimpleHeading3Text>
          </div>

          <Col span={22}>
            <Form
              hideRequiredMark
              name="signup_form"
              initialValues={{
                remember: true,
              }}
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <SimpleFormInput
                name="email"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ defaultMessage: 'Prosím vložte Váš E-mail' }),
                  },
                  {
                    type: 'email',
                    transform: (value) => value.trim(),
                    message: intl.formatMessage({ defaultMessage: 'Prosím vložte platný E-mail' }),
                  },
                ]}
                placeholder={intl.formatMessage({ defaultMessage: 'E-mail' })}
              />

              <PasswordFormInput
                name="password"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ defaultMessage: 'Prosím vložte Vaše heslo' }),
                  },
                ]}
                placeholder={intl.formatMessage({ defaultMessage: 'Heslo' })}
              />

              <SimpleFormItem>
                <Row
                  gutter={[16, 24]}
                  justify="center"
                  align="middle"
                  style={{ paddingBottom: '15px', paddingTop: '8px' }}
                >
                  <Col
                    style={{ textAlign: 'center', marginBottom: '5px' }}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    <SubmitButton
                      disabled={disabled}
                      size="large"
                      buttontext={intl.formatMessage({ defaultMessage: 'Přihlásit se' })}
                    />
                  </Col>
                  <Col style={{ textAlign: 'center' }} span={24}>
                    <Row justify="space-around">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row justify="start" wrap={false}>
                          <Col span="3" offset="1">
                            <LockIcon iconcolor="#B3CA1F" />
                          </Col>
                          <Col offset="1">
                            <Link
                              style={{
                                color: darkBlue,
                                fontWeight: '500',
                              }}
                              to="/forgot-password"
                            >
                              {intl.formatMessage({ defaultMessage: 'Zapomenuté heslo' })}
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="center">
                          <LocaleSwitcher />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SimpleFormItem>
            </Form>
          </Col>
        </Row>
      </ResponsiveGrid>
    </>
  );
};

AuthenticateContainer.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
export default AuthenticateContainer;
