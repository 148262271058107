import { Classification, flattenedNace } from '@tymbe/job-classifications/job-clasification';
import { useState } from 'react';
import { GroupBase } from 'react-select';

import TyAsyncSelect, { TyAsyncSelectProps } from './TyAsyncSelect';

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';

type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, Classification, GroupBase<Classification>, void, boolean>;
type TySelectNaceClassificationProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps>;

const TySelectNaceClassification = <Fields extends object>({
  ...props
}: TySelectNaceClassificationProps<Fields>) => {
  const allOptions = Object.values(flattenedNace);
  const [filteredOptions, setFilteredOptions] = useState<Classification[]>(allOptions);

  const getOptionLabel = (option: Partial<Classification>) => `[${option.id}] ${option.name}`;

  return (
    <TyAsyncSelect
      {...props}
      loadOptions={() => ({
        options: filteredOptions.slice(0, 25),
        hasMore: false,
      })}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => String(option.id)}
      onInputChange={(inputValue) => {
        setFilteredOptions(allOptions.filter((option) => (
          getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase())
        )));
      }}
    />
  );
};

export default TySelectNaceClassification as typeof TySelectNaceClassification;
