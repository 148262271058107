import { Form } from 'informed';
import { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';

import styles from './Layout.module.css';
import { PrimaryButton } from '../../buttons';
import { FilterIcon } from '../../icons';
import { PageTitle } from '../../texts';

type Props = {
  pageTitle: string
  children: JSX.Element;
  showModal: boolean,
  setShowModal: (show: boolean) => void
  initialValues: object | undefined
  setInitialValues: (values: object | undefined) => void
};

const Layout = ({ pageTitle, children, showModal, setShowModal, initialValues, setInitialValues }: Props) => (
  <>
    <PrimaryButton
      className="ty-icon-button ty-icon-button-toggle bg-primary-400"
      onClick={() => setShowModal(true)}
      icon={<FilterIcon />}
    />
    {showModal && (
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="My dialog"
        className={styles.modal}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <Form
          initialValues={initialValues}
          onReset={() => {
            setInitialValues(undefined);
          }}
          onSubmit={(values) => {
            setInitialValues(values.values);
            setShowModal(false);
          }}
        >

          <div className={styles.header}>
            <div>
              <PageTitle>{pageTitle}</PageTitle>
            </div>
            <div>

              <button
                aria-label="Close modal"
                type="button"
                className={styles.close}
                onClick={() => setShowModal(false)}
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z" fill="#28367B" />
                </svg>
              </button>
            </div>

          </div>
          <div className={styles.children}>
            {children}
          </div>
          <br />

          <svg width="100%" height="1" viewBox="0 0 1051 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="1" fill="#DBDFEE" />
          </svg>

          <div className={styles.footer}>

            {/* eslint-disable-next-line react/button-has-type */}
            <button
              type="reset"
              className={styles.resetButton}
            >

              <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.2 3.6V0L2.7 4.5L7.2 9V5.4C10.179 5.4 12.6 7.821 12.6 10.8C12.6 13.779 10.179 16.2 7.2 16.2C4.221 16.2 1.8 13.779 1.8 10.8H0C0 14.778 3.222 18 7.2 18C11.178 18 14.4 14.778 14.4 10.8C14.4 6.822 11.178 3.6 7.2 3.6Z" fill="#B6CD1E" />
              </svg>
              &nbsp;
              Resetovat filtry
            </button>

            <div className={styles.footerRight}>
              <button
                type="button"
                className="ty-button-secondary "
                onClick={() => setShowModal(false)}
              >

                Zavřít
              </button>

              <button
                type="submit"
                className="ty-button-primary"
              >
                Uložit
              </button>

            </div>

          </div>

        </Form>
      </Modal>
    )}
  </>
);

export default Layout;
