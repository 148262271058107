import { SVGProps } from 'react';

type StarHalfIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const StarHalfIcon = (props: StarHalfIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.6484 9.29464L14.8084 8.87464L12.9184 4.42464C12.5784 3.61464 11.4184 3.61464 11.0784 4.42464L9.18836 8.88464L4.35836 9.29464C3.47836 9.36464 3.11836 10.4646 3.78836 11.0446L7.45836 14.2246L6.35836 18.9446C6.15836 19.8046 7.08836 20.4846 7.84836 20.0246L11.9984 17.5246L16.1484 20.0346C16.9084 20.4946 17.8384 19.8146 17.6384 18.9546L16.5384 14.2246L20.2084 11.0446C20.8784 10.4646 20.5284 9.36464 19.6484 9.29464ZM11.9984 15.6546V6.35464L13.7084 10.3946L18.0884 10.7746L14.7684 13.6546L15.7684 17.9346L11.9984 15.6546Z" />

  </svg>
);

export default StarHalfIcon;
