import { Button, Layout, Skeleton, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import feathersClient from '../../apiClient';
import { useUser } from '../../apiClient/ApiContext';
import { SignOutIcon } from '../../components/icons';
import { signOutUserThunk } from '../../data/store/slices/auth';
import { removeUserAction } from '../../data/store/slices/user';
import { RoleDisplay } from '../../utils/enums';

const { Header } = Layout;

const DesktopPageHeader = ({ height = '55px', background = '#fff', iconcolor = '#202020' }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigator = useNavigate();
  const removeUser = async () => {
    dispatch(signOutUserThunk());
    dispatch(removeUserAction());
    navigator('/signin');
    await feathersClient.logout();
    queryClient.invalidateQueries(['reAuthenticate'], { refetchType: 'none' });
    queryClient.removeQueries('reAuthenticate', { exact: true });
  };

  const user = useUser();
  const headerStyle = {
    height,
    background,
    color: iconcolor,
  };

  return (
    <div>
      <Header style={headerStyle} className="ty-desktop-header">
        <div className="ty-profile-pill">
          <div className="ty-profile-pill-avatar">
            <Skeleton.Avatar style={{ width: '28px', height: '28px' }} />
          </div>
          <Link to="/account" style={{ textDecoration: 'none' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="ty-profile-pill-name">
                {`${user?.person?.first_name} ${user?.person?.last_name}`}
              </div>
              <div className="ty-profile-pill-role">{user.role.map((r) => RoleDisplay[r.slug]).join(', ')}</div>
            </div>
          </Link>
          <Tooltip title="Odhlásit se" placement="left">
            <Button
              className="ty-profile-pill-signout btn"
              style={{ padding: 'none', border: 'none' }}
              onClick={removeUser}
            >
              <SignOutIcon />
            </Button>
          </Tooltip>
        </div>
      </Header>
    </div>
  );
};

DesktopPageHeader.propTypes = {
  background: PropTypes.string,
  height: PropTypes.number,
  iconcolor: PropTypes.string,
};

export default DesktopPageHeader;
