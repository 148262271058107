import { nanoid } from '@reduxjs/toolkit';

import { useUser } from '../../apiClient/ApiContext';
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import CompanyDashboardContainer from '../../containers/company/dashboard';
import { Roles } from '../../utils/enums';

const AdminDashboard = () => {
  const user = useUser();
  const isAdmin = useUser().hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);

  const invitations = [
    { id: nanoid(), type: 'refused', name: 'Stanislav Jakubej' },
    { id: nanoid(), type: 'expired', name: 'Dalibor Branny' },
    { id: nanoid(), type: 'refused', name: 'Jan Kocvara' },
    { id: nanoid(), type: 'refused', name: 'Zdenek Hasek' },
    { id: nanoid(), type: 'refused', name: 'Ondra Dlabola' },
  ];

  const attendance = [
    { id: nanoid(), title: 'Minulý týden', requests: 267 },
    { id: nanoid(), title: 'Tento týden', requests: 214 },
    { id: nanoid(), title: 'Včera', requests: 34 },
  ];

  const columns = [];

  const pageTitle = 'Nástěnka';

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra={isAdmin}
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <CompanyDashboardContainer
          user={user}
          columns={columns}
          invitations={invitations}
          attendance={attendance}
          pageTitle={pageTitle}
        />
      </Wrapper>
    </Container>
  );
};

export default AdminDashboard;
