import { Gender } from './enums';

const getGender = <A>(gender: A): A | string => {
  switch (gender) {
    case Gender.MALE: return 'Muž';
    case Gender.FEMALE: return 'Žena';
    default: return gender;
  }
};
export default getGender;
