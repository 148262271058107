import PropTypes from 'prop-types';

import styles from './HoverRow.module.css';

/** @type React.FC<PropTypes.InferType<HoverRow.propTypes>> */
const HoverRow = ({
  children = null,
}) => (
  <div className={styles.ty_table_row_container}>
    <div className={styles.ty_hover_container}>
      {children}
    </div>
  </div>
);
HoverRow.propTypes = {
  children: PropTypes.node,
};

export default HoverRow;
