import { ApplicationState } from '@tymbe/schema/enums';
import { ManShiftData } from '@tymbe/schema/man-shift.interface';
import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import { useState, useEffect } from 'react';

import OrdersTableExpandedCategory from './OrdersTableExpandedCategory';
import {
  mapOrderTableExpandedCategories,
  OrderTableCategoryTuple,
} from './OrderTableExpandedCategories.utils';

interface OrderTableExpandableProps {
  shift: ShiftStateData,
  onChange: () => void,
}

const categoryOrder: Array<ApplicationState | 'Invitations'> = [
  ApplicationState.CONFIRMED,
  'Invitations',
  ApplicationState.CANCELED_LATE,
  ApplicationState.REJECTED,
  ApplicationState.CANCELED_EARLY,
  ApplicationState.SYSTEM_CANCELED,
];

const OrderTableExpandedCategories = ({ shift, onChange }: OrderTableExpandableProps) => {
  const [mappedData, setMappedData] = useState<OrderTableCategoryTuple>({});
  useEffect(() => {
    setMappedData({
      ...mappedData,
      // FIXME: This mapping is not really needed (if we update components implementation),
      //  it's here to speed up the process of finishing ticket
      ...mapOrderTableExpandedCategories(shift.manShift as ManShiftData[]),
    });
  }, []);

  return (
    <div className="flex flex-col gap-4 w-full h-full py-2 px-12">
      {categoryOrder.map((category) => {
        const cat = mappedData[category];
        if (!cat || cat.data?.length === 0) return null;
        return (
          <OrdersTableExpandedCategory
            key={cat.title}
            category={cat}
            onChange={() => {
              setMappedData({});
              onChange();
            }}
          />
        );
      })}
    </div>
  );
};

export default OrderTableExpandedCategories;
