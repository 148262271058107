import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const loadCompanyUsersThunk = createAsyncThunk('usersContainer/loadCompanyUsersThunk', async ({ query }) => feathersClient.service('company-person').find({
  query: {
    $eager: '[person(withDeleted).login(withDeleted).role(withDeleted)]',
    person: {
      $limit: 50,
    },
    ...query,
  },
}));

const loadCompanyUsersReducer = {
  [loadCompanyUsersThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadCompanyUsersThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [loadCompanyUsersThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

export const createUserThunk = createAsyncThunk('usersContainer/createUser', async (data) => {
  const user = {
    username: data.email,
    password: data.password,
    person: {
      first_name: data.first_name,
      last_name: data.last_name,
      ...data.person,
    },

  };
  return feathersClient.service('login').create(user);
});

const createUserReducers = {
  [createUserThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [createUserThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [createUserThunk.fulfilled]: (state) => ({
    ...state,
    loading: false,
    error: false,
  }),
};

export const patchUserThunk = createAsyncThunk('usersContainer/patchUser', async ({ id, body }) => {
  const user = {
    username: body.email,
    password: body.password,
    person: {
      first_name: body.first_name,
      last_name: body.last_name,
      ...body.person,
    },

  };
  return feathersClient.service('login').patch(id, user);
});

const patchUserReducer = {
  [patchUserThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: false,
  }),
  [patchUserThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [patchUserThunk.fulfilled]: (state) => ({
    ...state,
    loading: false,
    error: false,
  }),
};

export const createCompanyUserThunk = createAsyncThunk('usersContainer/createCompanyUserThunk', async (body) => feathersClient.service('company-person').create(body));

const createCompanyUserReducer = {
  [createCompanyUserThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [createCompanyUserThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [createCompanyUserThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: [...(state.data || []), ...action.payload.data || []],
  }),
};

export const removeCompanyUserThunk = createAsyncThunk(
  'companyUserContainer/remove',
  async (id) => {
    feathersClient.service('company-person').remove(id);
  },
);

const removeCompanyUserReducer = {
  [removeCompanyUserThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removeCompanyUserThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [removeCompanyUserThunk.fulfilled]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
};

const companyUsersSlice = createSlice({
  name: 'usersContainer',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    updateRecord(state, action) {
      const data = state.data.map((x) => (x.id !== action.payload.id ? x : action.payload.record));
      return { ...state, data };
    },
  },
  extraReducers: {
    ...loadCompanyUsersReducer,
    ...createCompanyUserReducer,
    ...createUserReducers,
    ...removeCompanyUserReducer,
    ...patchUserReducer,
  },
});

export const selectUsersForCompany = (state) => state.data;

export const selectUsersForCompanyLoading = createSelector(
  (state) => state.views.companyDetailUsers.loading,
  (loading) => loading,
);

export default companyUsersSlice.reducer;
export const { actions } = companyUsersSlice;
