import { SVGProps } from 'react';

type StarFullIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const StarFullIcon = (props: StarFullIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.4302 9.61158L12.9602 4.77158C12.6702 3.82158 11.3302 3.82158 11.0502 4.77158L9.5702 9.61158H5.1202C4.1502 9.61158 3.7502 10.8616 4.5402 11.4216L8.1802 14.0216L6.7502 18.6316C6.4602 19.5616 7.5402 20.3116 8.3102 19.7216L12.0002 16.9216L15.6902 19.7316C16.4602 20.3216 17.5402 19.5716 17.2502 18.6416L15.8202 14.0316L19.4602 11.4316C20.2502 10.8616 19.8502 9.62158 18.8802 9.62158H14.4302V9.61158Z" />
  </svg>
);

export default StarFullIcon;
