import { FieldProps, useField } from 'informed';
import moment, { Moment } from 'moment-timezone';
import Picker from 'rc-picker';
import { PickerProps, PickerTimeProps } from 'rc-picker/es/Picker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import csCZ from 'rc-picker/lib/locale/cs_CZ';

import TyInputError from './TyInputError';
import { isDayHoliday } from '../../utils/holiday';
import { CalendarIcon, CloseIcon } from '../icons';
import './TyInput.css';

type DateProps = Omit<Partial<PickerProps<Moment>>, 'disabledTime' | 'allowClear' | 'picker'> & {
  label?: string;
  isClearable?: boolean;
  showSecond?: boolean;
  datePresets?: { label: string, date: string | Date | Moment }[];
  disabled?: boolean;
  highlightHolidays?: boolean;
  picker: PickerProps<Moment>['picker'] | 'monthyear';
};

type TimeProps = Omit<Partial<PickerTimeProps<Moment>>, 'picker'> & {
  label?: string;
  disabled?: boolean;
};

type TyTimeInputProps<Fields extends object> = FieldProps<TimeProps, Moment, Fields>;

type TyDateInputProps<Fields extends object> = FieldProps<DateProps, Moment, Fields>;

const getFormat = (picker: DateProps['picker']) => {
  switch (picker) {
    case 'time':
      return ['HH:mm', 'H:mm'];
    case 'year':
      return ['YYYY'];
    case 'month':
      return ['MMMM'];
    case 'monthyear':
      return ['MMMM YYYY'];
    case 'date':
    default:
      return ['L', 'D.M.YYYY'];
  }
};

const TyDatePicker = <Fields extends object>(props: TyDateInputProps<Fields> | TyTimeInputProps<Fields>) => {
  const {
    render,
    fieldState,
    userProps,
    fieldApi,
  } = useField<DateProps, Moment>({ ...props });
  const { error, showError, focused, value } = fieldState;
  const { setValue, setTouched, setFocused, validate } = fieldApi;
  const {
    id,
    label,
    className = '',
    format = getFormat(userProps.picker),
    generateConfig = momentGenerateConfig,
    locale = csCZ,
    open = focused,
    onChange,
    onFocus,
    onBlur,
    suffixIcon = <CalendarIcon />,
    clearIcon = <CloseIcon />,
    showSecond = false,
    datePresets = [],
    renderExtraFooter,
    isClearable = false,
    picker = 'date',
    disabled = false,
    highlightHolidays = false,
    ...rest
  } = userProps;

  const innerOnChange: PickerProps<Moment>['onChange'] = (date, dateString) => {
    setValue(date ?? undefined);
    validate();
    onChange?.(date, dateString);
  };
  const innerOnFocus: PickerProps<Moment>['onFocus'] = (e) => {
    setFocused(true, e);
    onFocus?.(e);
  };
  const innerOnBlur: PickerProps<Moment>['onBlur'] = (e) => {
    setTouched(true, e);
    setFocused(false, e);
    onBlur?.(e);
  };

  const dateRender = (currentDate: Moment): React.ReactNode => {
    const dayIsHoliday = highlightHolidays && isDayHoliday(currentDate);

    return (
      <div className={`rc-picker-cell-inner ${dayIsHoliday && '!text-danger !bg-danger-150 !border-danger'}`}>
        {currentDate.date()}
      </div>
    );
  };

  const innerRenderExtraFooter: PickerProps<Moment>['renderExtraFooter'] = (mode) => (
    <>
      {datePresets.map((datePreset) => <button
        key={datePreset.label}
        type="button"
        onClick={() => setValue(moment(datePreset.date))}
      >{datePreset.label}</button>)}
      {renderExtraFooter?.(mode) ?? null}
    </>
  );

  return render(
    <>
      <Picker<Moment>
        inputRender={(inputProps) => (<input {...inputProps} className="outline-0 min-w-0" />)}
        {...rest}
        allowClear={isClearable}
        className={className}
        disabled={disabled}
        dropdownClassName="ty_input_dropdown"
        id={id}
        format={format}
        generateConfig={generateConfig}
        picker={picker === 'monthyear' ? 'month' : picker}
        locale={locale}
        value={value ? moment(value) : value}
        open={open}
        showSecond={showSecond}
        onChange={innerOnChange}
        onOpenChange={(o) => setFocused(o)}
        onPanelChange={innerOnChange}
        onSelect={(time) => innerOnChange(time, '')}
        onFocus={innerOnFocus}
        onBlur={innerOnBlur}
        // suffixIcon={suffixIcon}
        clearIcon={clearIcon}
        renderExtraFooter={innerRenderExtraFooter}
        dateRender={dateRender}
      />
      {label ? <label htmlFor={id}>{label}</label> : null}
    </>,
  );
};

export default TyDatePicker;
