import { Form, FormState } from 'informed';
import { useCallback } from 'react';

import { SubmitButton } from '../../../components/buttons';
import CloseIcon from '../../../components/icons/CloseIcon';
import TySelectPerson from '../../../components/inputs/TySelectPerson';
import getValidateTymberBlocked from '../../../components/inputs/Validators/tymberBlock';
import ModalShell from '../../../components/modals/ModalShell';
import { PersonData } from '../../../types/TymbeApi';

type InvitationModalProps = {
  exclude?: number[];
  excludeInvitedPersonForShiftId?: number;
  showModal: boolean;
  formDisabled?: boolean;
  onClose: () => void;
  onSubmit?: (formData: FormState<PersonData & { invitation_list: PersonData[] }>) => void;
  company?: {
    id: number | undefined;
    branchoffice: number | undefined | null;
    department: number | undefined;
  };
  shiftDate?: string;
};

const InvitationModal = ({
  exclude,
  excludeInvitedPersonForShiftId,
  showModal,
  onClose,
  onSubmit,
  formDisabled = false,
  company,
  shiftDate,
}: InvitationModalProps) => {
  const validateBlocked = useCallback(
    (value) => getValidateTymberBlocked(shiftDate, company)(value),
    [shiftDate, company],
  );
  return (
    <ModalShell
      showModal={showModal}
      onClose={onClose}
    >
      <div className="flex flex-row justify-between items-center">
        <h2>Koho chcete pozvat?</h2>
        <div>
          <button
            aria-label="Close button"
            type="button"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <Form
        name="invitation-modal"
        onSubmit={onSubmit}
      >
        <div className="p-5">
          <TySelectPerson
            className="w-full"
            name="invitation_list"
            label="Pozvánky"
            isMulti
            onlyInvitable={excludeInvitedPersonForShiftId ? { shiftId: excludeInvitedPersonForShiftId } : undefined}
            exclude={{
              personIds: exclude || [],
            }}
            limitToRelatedCompanies={false}
            filter={{ role: 'tymber' }}
            validate={validateBlocked}
            company={company}
          />
        </div>
        <div className="flex gap-x-1 justify-end">
          <button
            type="button"
            className="ty-button-secondary"
            onClick={onClose}
          >
            Zavřít
          </button>
          <SubmitButton
            className="ty-button-primary"
            disabled={formDisabled}
            buttontext="Pozvat"
          />
        </div>
      </Form>
    </ModalShell>
  );
};
export default InvitationModal;
