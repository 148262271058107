import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';

export const loadGlobalCompanyShiftTemplatesThunk = createAsyncThunk('orders/newOrder/globalShiftTemplates', async (query) => feathersClient.service('shift-template').find({
  query: {
    ...query,
  },
}));

const loadGlobalCompanyShiftTemplatesReducer = {
  [loadGlobalCompanyShiftTemplatesThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadGlobalCompanyShiftTemplatesThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
    requestData: null,
  }),
  [loadGlobalCompanyShiftTemplatesThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
    requestData: action.meta.arg,
  }),
};

const initialState = {
  loading: false,
  error: null,
  data: null,
  requestData: null,
};

const loadGlobalCompanyShiftTemplatesSlice = createSlice({
  name: 'loadGlobalCompanyShiftTemplatesContainer',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    ...loadGlobalCompanyShiftTemplatesReducer,
  },
});

export const selectGlobalCompanyShiftTemplates = (state) => state.data;

export default loadGlobalCompanyShiftTemplatesSlice.reducer;
export const { actions } = loadGlobalCompanyShiftTemplatesSlice;
