/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';
import { transformAdminTymberBlocks } from '../../../../utils';

export const loadBanThunk = createAsyncThunk('ban/request', async (params) => feathersClient.service('company-blocked-user').find({
  query: {
    $eager: '[company, person, creator, branchoffice]',
    $joinRelation: '[company, person, creator]',
    ...params,
  },
}));

const loadBanReducer = {
  [loadBanThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadBanThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [loadBanThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.data = action.payload;
  },
};
export const createBanThunk = createAsyncThunk('ban/create', async (data) => feathersClient.service('company-blocked-user').create(data));

const createBanReducer = {
  [createBanThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createBanThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createBanThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const patchBanThunk = createAsyncThunk('ban/patch', async (data) => feathersClient.service('company-blocked-user').patch(data.id, data.body));
const patchBanReducer = {
  [patchBanThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [patchBanThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [patchBanThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const deleteBanThunk = createAsyncThunk('ban/delete', async (data) => feathersClient
  .service('company-blocked-user')
  .remove(data));
const deleteBanReducer = {
  [deleteBanThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [deleteBanThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [deleteBanThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.data = state.data.data.filter((x) => x.id !== action.id);
  },
};
const banSlice = createSlice({
  name: 'ban',
  initialState: {
    loading: null,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: {
    ...loadBanReducer,
    ...createBanReducer,
    ...patchBanReducer,
    ...deleteBanReducer,
  },
});
export const selectBlocks = createSelector(
  (state) => state.ban.data,
  (res) => ({ ...res, data: transformAdminTymberBlocks(res?.data || []) }),
);

export default banSlice.reducer;
