import EditorJS, { OutputData } from '@editorjs/editorjs';
import EditorBlock from '@tymbe/components/Editor/Editor';
import { JsonLayout } from '@tymbe/ty-docs/types/jsonDocument';
import { useEffect, useRef, useState } from 'react';

import { ErrorAlert } from '../../../../../../../components/alerts';

import { PrimaryButton, SecondaryButton } from '../../../../../../../components/buttons';
import AlertModal from '../../../../../../../components/modals/AlertModal';

interface DocumentTypeEditorProps {
  onSave: (data: OutputData) => void;
  onDelete: () => void;
  editorData: OutputData | undefined;
  importData?: OutputData | undefined;
  setEditorData: (data: OutputData | undefined) => void;
  signal: number;
  layout: JsonLayout;
}

const DocumentTypeEditor = ({
  onSave,
  onDelete,
  editorData,
  importData,
  setEditorData,
  signal,
  layout,
}: DocumentTypeEditorProps) => {
  const editor = useRef<EditorJS>(null);
  const [showModal, setShowModal] = useState(false);
  const shouldBeDisabled =
    editorData?.blocks.every((block) => !block.data) ?? true;

  const saveEditorContent = async (): Promise<void> => {
    if (!editorData) {
      return;
    }
    onSave(editorData);
  };

  const clearEditorContent = async (): Promise<void> => {
    editor.current?.blocks?.clear();
    setShowModal(false);
    onDelete();
  };

  useEffect(() => {
    if (importData) {
      // editor.current?.blocks.delete(); also triggers the onChange event
      editor.current?.blocks.getBlockByIndex(0)?.dispatchChange();
      editor.current?.render(importData);
    }
  }, [signal]);

  return (
    <>
      <EditorBlock
        data={editorData}
        onChange={setEditorData}
        ref={editor}
        holder="editorjs-container"
        layout={layout}
      />
      <div className="flex items-center gap-2 justify-end">
        {editorData && editorData?.blocks.length > 0 && (
          <SecondaryButton
            buttontext="Smazat"
            onClick={() => setShowModal(true)}
          />
        )}
        <PrimaryButton
          disabled={shouldBeDisabled}
          buttontext="Uložit"
          onClick={saveEditorContent}
        />
      </div>
      <AlertModal
        title="Smazání obsahu dokumentu"
        message="Po smazání bude obsah dokumentu nenávratně ztracen."
        yesButtonText="Ano"
        noButtonText="Ne"
        showCancel={false}
        onClose={() => setShowModal(false)}
        onYes={clearEditorContent}
        onNo={() => setShowModal(false)}
        showModal={showModal}
      />
    </>
  );
};

export default DocumentTypeEditor;
