import { HTMLAttributes, ReactNode } from 'react';

import { TableCell } from './TableCell';

export type TableRowProps = HTMLAttributes<HTMLTableRowElement> & {
  cols?: ReactNode[];
};

export const TableRow = ({ children, className, cols = [], ...rest }: TableRowProps) => (
  <tr className={className} {...rest}>
    {!children
      ? cols.map((col) => (
        <TableCell key={cols.indexOf(col).toString()}>{col}</TableCell>
      ))
      : children}
  </tr>
);
