import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { createRef, useState, useEffect } from 'react';
// throws a "Could not find a declaration file for module 'react-csv'" error
// but it presumably works
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

import ExportModal from './ExportModal';
import { useUser } from '../../../apiClient/ApiContext';
import Protect from '../../../apiClient/Protect';
import { PrimaryButton, SecondaryButton } from '../../../components/buttons';
import { firstToUpperCase } from '../../../utils';
import { BranchofficeType, Roles } from '../../../utils/enums';
import { getCSVKeyLabelPairs } from '../../../utils/exports/csv';
import Spinner from '../../../utils/spinner';
import TABLE_DEFINITIONS from '../../../utils/table-definitions';

interface OrdersHeaderPanelProps {
  data?: ShiftStateData[];
  isLoading: boolean;
  selectedRowIds: number[];
  renderSelectedNameExport: () => void;
}

const mapShiftToCSV = (shifts: ShiftStateData[]) => shifts.map((shift: ShiftStateData) => ({
  // FIXME: Works properly and is used like this in many places,
  //  but we need to introduce types into the firstToUpperCase fn
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  day: firstToUpperCase(moment(shift.start_time).format('dd')),
  date: moment(shift.start_time).format('DD.MM.YYYY'),
  from_time: moment(shift.start_time).format('HH:mm'),
  to_time: moment(shift.end_time).format('HH:mm'),
  name: shift.shiftTemplate?.template_name,
  orders_count: shift.orders_count,
  filled_orders_count: shift.filled_orders_count,
  unfilled_orders_count: shift.unfilled_orders_count,
  invitation_orders_count: shift.invitation_orders_count,
  branchoffice_branch: (shift.branchoffice?.parent?.type === BranchofficeType.BRANCH
    && shift.branchoffice?.parent?.name) || (shift.branchoffice?.parent?.type === BranchofficeType.BRANCH
    && shift.branchoffice?.name) || '-',
  branchoffice_department: (shift.branchoffice?.type === BranchofficeType.DEPARTMENT
    && shift.branchoffice?.name) || '-',
}));

const OrdersHeaderPanel = ({
  data,
  isLoading,
  selectedRowIds,
  renderSelectedNameExport,
}: OrdersHeaderPanelProps) => {
  const csvLink = createRef<any>();
  const [CSVFilename, setCSVFilename] = useState('');
  const [CSVData, setCSVData] = useState<unknown[]>([]);
  const [CSVHeaders, setCSVHeaders] = useState([]);
  const [CSVFormDisabled, setCSVFormDisabled] = useState<boolean>(false);
  const [CSVExportReady, setCSVExportReady] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const onCSVExport = () => {
    setCSVFilename(`${moment().format('YYYYMMDD')}_Orders_Table.csv`);
    setCSVData(mapShiftToCSV(data || []));
    setCSVHeaders(getCSVKeyLabelPairs(TABLE_DEFINITIONS.ORDERS));
    setCSVExportReady(true);
    setShowExportModal(false);
  };

  useEffect(() => {
    if (!CSVExportReady) return;
    csvLink.current.link.click();
    setCSVFormDisabled(false);
    setCSVExportReady(false);
  }, [CSVExportReady, csvLink]);

  const user = useUser();
  const showNewOrder = user.person.company.length === 0 || user.person.company.some((company) => !company.is_readonly);

  return (
    <div>
      <Tooltip trigger="hover" overlay="Report obsazenosti vybraných směn" placement="top">
        <SecondaryButton
          style={{ marginRight: '18px' }}
          textstyle={{ fontSize: '12px' }}
          className="w-36"
          size="large"
          buttontext="Report obsazenosti"
          disabled={!selectedRowIds.length}
          onClick={renderSelectedNameExport}
        />
      </Tooltip>
      <Protect
        auth={[
          Roles.SUPER_ADMIN,
          Roles.TYMBE_ADMIN,
          Roles.TYMBE_COORDINATOR,
          Roles.COMPANY,
          Roles.BRANCHOFFICE_MANAGER,
          Roles.BRANCHOFFICE_SUPERVISOR,
        ]}
        redirect={false}
      >
        <CSVLink
          hidden
          ref={csvLink}
          filename={CSVFilename}
          data={CSVData}
          headers={CSVHeaders}
          disabled={CSVFormDisabled}
        />

        <SecondaryButton
          style={{ marginRight: '18px' }}
          textstyle={{ fontSize: '12px' }}
          className="w-28"
          size="large"
          buttontext={isLoading ? <Spinner size="small" loadingText="" /> : 'Export CSV'}
          disabled={CSVFormDisabled || isLoading}
          onClick={() => setShowExportModal(true)}
        />
        {showNewOrder && (
          <Link to="/shift/create">
            <PrimaryButton buttontext="Nová objednávka" id="new_order_button" />
          </Link>
        )}
      </Protect>
      <ExportModal
        onConfirm={onCSVExport}
        onClose={() => setShowExportModal(false)}
        showModal={showExportModal}
      >
        Potvrzením stáhnete csv soubor s objednávkami.
      </ExportModal>
    </div>
  );
};

export default OrdersHeaderPanel;
