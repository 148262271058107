import { useMutation } from 'react-query';

import feathersClient from '../../../../apiClient';
import { SecondaryButton } from '../../../../components/buttons';
import { ApplicationState } from '../../../../types/TymbeApi';

export interface RejectInvitationButtonProps {
  applicationId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
  onError?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const RejectInvitationButton = ({ applicationId, onSuccess, onError }: RejectInvitationButtonProps) => {
  const { mutateAsync: patchInvitation } = useMutation(
    ['application', { id: applicationId }],
    async () => {
      await feathersClient.service('application').patch(applicationId, { state: ApplicationState.REJECTED });
    },
    {
      onSuccess,
      onError,
    },
  );
  return (
    <div className="p-2 text-right">
      <SecondaryButton onClick={patchInvitation}>
        Odmítnout pozvánku
      </SecondaryButton>
    </div>
  );
};

export default RejectInvitationButton;
