import moment, { Moment } from 'moment';

import { FormValues } from '../Libs/transform';

type Option = { label: string, value: string; };

const apiTransformationsTymber = {

  tymber: (tymbers: FormValues[]) => {
    if (tymbers.length === 0) return undefined;
    return {
      'person.id': { $in: tymbers.map((t) => t.value) },
    };
  },

  accountState: (accountState: string) => {
    if (!accountState) return undefined;
    return {
      'accountState.account_state': accountState,
    };
  },

  userType: (type: string) => {
    if (type === 'userTypeTymber') {
      return {
        $modify: { onlyRole: ['tymber'] },
      };
    }
    return undefined;
  },

  gender: (g: string) => {
    if (['male', 'female'].includes(g)) {
      return {
        'personData.gender': (g === 'male') ? 'male' : 'female',
      };
    }
    return undefined;
  },

  nationality: (nationality: FormValues[]) => ({
    'personData.nationality': { $in: nationality.map((c) => c.value) },
  }),

  email: (email: FormValues[]) => ({ 'person.id': { $in: email.map((c) => c.value) } }),

  phone: (phone: FormValues[]) => ({ 'person.id': { $in: phone.map((c) => c.value) } }),

  socialSecurityNumber: (pin: FormValues[]) => ({ 'person.id': { $in: pin.map((c) => c.value) } }),

  ageFrom: (ageFrom: Option) => {
    if (!ageFrom) return undefined;
    return {
      $modify: { age: [undefined, moment().subtract(ageFrom.value, 'years').toISOString()] },
    };
  },

  ageTo: (ageTo: Option) => {
    if (!ageTo) return undefined;
    return {
      $modify: { age: [moment().subtract(ageTo.value, 'years').toISOString(), undefined] },
    };
  },

  bankAccount: (bankAccount: FormValues[]) => ({ 'person.id': { $in: bankAccount.map((b) => b.value) } }),

  address: (address: FormValues[]) => ({
    $or: [
      { 'personData.permanent_address': { $in: address.map((a) => a.value) } },
      { 'personData.contact_address': { $in: address.map((a) => a.value) } },
    ],
  }),

  companyBlock: (companies: FormValues[]) => ({
    $modify: {
      blockedByCompanies: [companies.map((c) => c.value)],
    },

  }),

  registrationDateFrom:
  (date: Moment) => ({ $modify: { registrationDate: [moment(date).startOf('day').toISOString(), undefined] } }),

  registrationDateTo:
    (date: Moment) => ({ $modify: { registrationDate: [undefined, moment(date).endOf('day').toISOString()] } }),

  personNote: (notes: FormValues[]) => ({ 'person.id': { $in: notes.map((n) => n.value) } }),

};

export default apiTransformationsTymber;
