import classNames from 'classnames';
import { ComponentProps } from 'react';

type StatsOwnProps = {
  label: string,
  current: number,
  total: number,
  className?: string,
};
type StatsProps = StatsOwnProps & Omit<ComponentProps<'div'>, keyof StatsOwnProps>;

const Stats = ({ label, current, total, className = '', ...rest }: StatsProps) => (
  <div className={classNames('', className)} {...rest}>
    {label} {current}/{total}
  </div>
);

export default Stats;
