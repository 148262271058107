import { isValidPhoneNumber } from '@tymbe/utils/validations';

const validatePhoneNumber = (phone: string) => {
  // validation happens only if phone is not empty
  if (!phone || isValidPhoneNumber(phone)) return undefined;

  return 'Zadejte platné telefonní číslo s předvolbou';
};

export default validatePhoneNumber;
