import { NavLink, Outlet } from 'react-router-dom';

import Card from '../../../components/Layout/Card';
import NavTabs from '../../../components/navTabs/NavTabs';
import { PageTitle } from '../../../components/texts';
import Container from '../../../containers';

const MailPage = () => (
  <Container
    iconcolor="#B3CA1F"
    background="#fff"
    elevate
    contentstyle={{ paddingLeft: '170px' }}
    desktopHeader
    sidebar
  >
    <div className="px-10 flex flex-col gap-6">
      <PageTitle> Dashboard emailové služby </PageTitle>
      <Card>
        <div className="relative border-b border-secondary-200 pb-2 pl-3">
          <NavTabs>
            <NavLink key="queued" to="queued"> Čekající na odeslání </NavLink>
            <NavLink key="sent" to="sent"> Odeslané </NavLink>
            <NavLink key="failed" to="failed"> Neodeslané </NavLink>
          </NavTabs>
        </div>
      </Card>
      <Outlet />
    </div>
  </Container>
);

export default MailPage;
