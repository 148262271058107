import { PdfDodpRequest } from '@tymbe/schema/pdf/pdf-dodp.interface';
import normalizeFilename from '@tymbe/utils/normalizeFilename';
import { Form, FormState } from 'informed';
import moment from 'moment/moment';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import TemporaryAllocationForm, { TemporaryAllocationFormValues } from './TemporaryAllocationForm';
import feathersClient from '../../apiClient';
import { ErrorAlert } from '../alerts';
import { SubmitButton } from '../buttons';
import Card from '../Layout/Card/Card';

const downloadBlobResponse = (content: Blob, fileName: string) => {
  const url = URL.createObjectURL(content);

  // Create a hidden download link
  const downloadLink = document.createElement('a');
  downloadLink.href = url;

  // Set the 'download' attribute using setAttribute
  downloadLink.setAttribute('download', fileName);

  // Trigger a click event to initiate the download
  downloadLink.click();

  // Remove the download link from the DOM
  downloadLink.remove();
};

const TemporaryAllocationList = () => {
  const { companyId } = useParams();

  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const { mutateAsync: generateAndDownloadPDF } = useMutation(
    ['generate-dodp'],
    async (data: PdfDodpRequest) => feathersClient.service('pdf/dodp').create(data, {
      connection: {
        responseType: 'blob',
      },
    }),
  );

  const onSubmit = useCallback(
    async ({ values }: FormState<TemporaryAllocationFormValues>): Promise<void> => {
      setFormDisabled(true);

      if (!values.date.from || !values.date.to) {
        ErrorAlert('Vyberte prosím začátek a konec datumového rozsahu.');
        setFormDisabled(false);
        return;
      }

      if (!moment(values.date.from).isSame(moment(values.date.to), 'month')) {
        ErrorAlert('Prosím, vyberte rozsah datumů v jednom měsíci.');
        setFormDisabled(false);
        return;
      }

      if (!companyId) {
        ErrorAlert('Nastala chyba při pokusu o zjištění aktuálně zvolené firmy');
        setFormDisabled(false);
        return;
      }

      const requestData = {
        company_id: companyId,
        branchoffice_id: values.branchoffice.map((b) => b.id),
        from: values.date.from.toISOString(),
        to: values.date.to.toISOString(),
      };
      const pdf = await generateAndDownloadPDF(requestData);
      const fileName = values.branchoffice.length === 1 ? `${normalizeFilename(values.branchoffice[0].name)}.pdf` : 'dodp_export.zip';
      downloadBlobResponse(pdf, fileName);
      setFormDisabled(false);
    },
    [companyId, generateAndDownloadPDF],
  );

  return (
    <Card>
      <div className="flex flex-col gap-2 px-6">
        <Form name="temporary-allocation-form" onSubmit={onSubmit}>
          <TemporaryAllocationForm />
          <SubmitButton style={{ marginTop: '18px' }} disabled={formDisabled} buttontext="Generovat" />
        </Form>
      </div>
    </Card>
  );
};

export default TemporaryAllocationList;
