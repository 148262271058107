import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import UserForm, { UserFormValues } from './UserForm';
import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { SubmitButton } from '../../../../components/buttons';
import Card from '../../../../components/Layout/Card';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { LoginData } from '../../../../types/TymbeApi';
import { ContactType } from '../../../../utils/enums';
import { addZerosToPhone } from '../../../../utils/phoneNumber';

const CreateUser = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const history = useNavigate();
  const { companyId } = useParams();

  const { mutateAsync: createLogin } = useMutation(
    ['CreateUser'],
    (req: LoginData): Promise<LoginData> =>
      feathersClient.service('login').create(req, { $eager: '[person.contact]' }),
  );

  const onSubmit = async ({ values } : FormState<UserFormValues>) => {
    setFormDisabled(true);

    let branchoffice;
    if (values.assigned_branchoffices) {
      branchoffice = values.assigned_branchoffices.map((x) => (
        {
          id: x.id,
          title: values.position,
        }
      ));
    }

    const contact = [
      {
        person_id: values.person_id,
        name: 'mobil',
        type: ContactType.MOBILE_PHONE,
        value: addZerosToPhone(values.phone) || null,
      },
      {
        person_id: values.person_id,
        name: 'email',
        type: ContactType.EMAIL,
        value: values.username,
      },
    ];

    const company = [
      {
        id: Number(companyId),
        title: values.position,
      },
    ];

    try {
      await createLogin({
        username: values.username,
        // Password is set by the user in verify process
        password: '',
        person: {
          ...values.person,
          company,
          branchoffice,
          contact,
        },
        role: values.role?.[0],
      } as LoginData);
      SuccessAlert('Uživatel byl úspěšně vytvořen');
    } catch {
      ErrorAlert('Při pokusu o vytvoření uživatele nastala chyba');
    }

    setFormDisabled(false);
    history(`/company/${companyId}/user`);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Nový uživatel </h1>
        <Card>
          <Form<UserFormValues> name="person-form" onSubmit={onSubmit}>
            <UserForm companyId={Number(companyId)} />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default CreateUser;
