import { Col, Collapse, Row } from 'antd';
import PropTypes from 'prop-types';

const CompanyDetailCollapse = ({ data }) => (
  <Collapse
    className="ty-collapse"
    expandIconPosition="right"
    style={{ marginBottom: '18px' }}
    defaultActiveKey={['1']}
  >
    <Collapse.Panel header="Kontaktní a fakturační údaje" key="1">
      <Row gutter={37}>
        <Col
          xs={24}
          md={8}
          className="d-flex"
          style={{
            flexDirection: 'column',
          }}
        >
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">Název firmy</div>
            <div className="ty-plaintext-mobile">
              {data.name}
            </div>
          </div>
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">IČ</div>
            <div className="ty-plaintext-mobile">
              {data.cin}
            </div>
          </div>
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">DIČ</div>
            <div className="ty-plaintext-mobile">
              {data.tin}
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          md={8}
          className="d-flex"
          style={{
            flexDirection: 'column',
          }}
        >
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">Sídlo ulice</div>
            <div className="ty-plaintext-mobile">
              {data.address?.addressline1}
            </div>
          </div>
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">Sídlo ČP</div>
            <div className="ty-plaintext-mobile">
              {data.address?.number}
            </div>
          </div>
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">Sídlo ČO</div>
            <div className="ty-plaintext-mobile">
              {data.address?.co}
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          md={8}
          className="d-flex"
          style={{
            flexDirection: 'column',
          }}
        >
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">Sídlo město</div>
            <div className="ty-plaintext-mobile">
              {data.address?.locality}
            </div>
          </div>
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">PSČ</div>
            <div className="ty-plaintext-mobile">
              {data.address?.zip}
            </div>
          </div>
          <div
            className="d-flex justify-content-between mb-10"
          >
            <div className="ty-h3-mobile">
              Zastupující osoba
            </div>
            <div className="ty-plaintext-mobile">
              {data.contactPerson?.first_name}
              {' '}
              {data.contactPerson?.last_name}

            </div>
          </div>
        </Col>
      </Row>
    </Collapse.Panel>
  </Collapse>
);
CompanyDetailCollapse.propTypes = {
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default CompanyDetailCollapse;
