import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from './SearchBox.module.css';
import { primaryColor, white } from '../../utils/colors';
import { PrimaryButton } from '../buttons';
import withSpinner from '../hoc/withSpinner';
import { SearchIcon } from '../icons';
import { AsyncSearchInput } from '../inputs';

export type SearchBoxProps = {
  name: string
  loading: boolean
  showSearch: boolean
  placeholder?: string
  onParamsSearch: (value: string, fc?: (arg: any) => void) => void
  setSearchParams?: (setValueFunc: () => void) => void;
  setShowSearch: (e: boolean) => void
};

const SpinnerSearchIcon = withSpinner(<Spin size="small" />)(SearchIcon);

const SearchBox: React.FC<SearchBoxProps> = ({
  name = '',
  loading,
  showSearch,
  placeholder = 'Hledat',
  setSearchParams = undefined,
  onParamsSearch,
  setShowSearch,
}) => {
  const [urlParams] = useSearchParams();
  const searchedValue = urlParams.get('search');

  useEffect(() => {
    if (searchedValue) {
      setShowSearch(true);
    }
  }, [searchedValue]);

  return (
    <>
      <div
        className={showSearch ? styles.searchStringOpened : styles.searchString}
      >
        <AsyncSearchInput
          thunk={(value: string) => (setSearchParams ? onParamsSearch(value, setSearchParams)
            : onParamsSearch(value))}
          bordered={false}
          placeholder={placeholder}
          name={name}
        />
      </div>
      <PrimaryButton
        className={`ty-icon-button ty-icon-button-toggle ${showSearch ? styles.primaryButtonOpened : styles.primaryButton}`}
        type="link"
        onClick={() => setShowSearch(!showSearch)}
        icon={(
          <SpinnerSearchIcon
            showSpinner={showSearch && loading}
            iconcolor={showSearch ? primaryColor : white}
          />
        )}
        style={undefined}
        textstyle={undefined}
        buttontext={undefined}
      />
    </>
  );
};
export default SearchBox;
