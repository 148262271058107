import { UPLOAD_MAX_FILE_SIZE } from '../../utils/constants';

export const checkFileSize = (blob: Blob) => blob.size < UPLOAD_MAX_FILE_SIZE;

type FilesProps = {
  signatureBlob?: Blob | null;
  stampBlob?: Blob | null;
  logoBlob?: Blob | null;
};

export const resolveUploadableFiles = (values: FilesProps): FormData | undefined => {
  const blobs = {
    signature: values.signatureBlob,
    stamp: values.stampBlob,
    logo: values.logoBlob,
  };

  if (Object.values(blobs).every((b) => b === undefined)) return undefined;

  const formData = new FormData();

  Object.entries(blobs).forEach(([name, blob]) => {
    if (blob) formData.append(name, blob);
    if (blob === null) formData.append(name, JSON.stringify(null));
  });

  return formData;
};
