import { Query } from '@feathersjs/feathers';
import moment, { Moment } from 'moment';

import { FormValues } from '../Libs/transform';

const apiTransformationsApplication: Query = {

  company: (companies: FormValues[]) => {
    if (companies.length === 0) return undefined;
    const $query = {
      'manShift:shift.company_id': { $in: companies.map((c) => c.value) },
    };
    return $query;
  },

  position: (positions: FormValues[]) => {
    if (positions.length === 0) return undefined;
    const $query = {
      'manShift:shift.name': { $in: positions.map((p) => p.value) },
    };
    return $query;
  },

  tymber: (tymbers: FormValues[]) => {
    if (tymbers.length === 0) return undefined;
    const $query = {
      'application.person_id': { $in: tymbers.map((t) => t.value) },
    };
    return $query;
  },

  branchoffice: (branchoffices: FormValues[]) => {
    if (branchoffices.length === 0) return undefined;
    const $query = {
      'manShift:shift:branchoffice.parent_id': { $in: branchoffices.map((b) => b.value) },
    };
    return $query;
  },

  department: (departments: FormValues[]) => {
    if (departments.length === 0) return undefined;
    const $query = {
      'manShift:shift.branchoffice_id': { $in: departments.map((d) => d.value) },
    };
    return $query;
  },

  orderId: (orderIds: FormValues[]) => {
    if (orderIds.length === 0) return undefined;
    const $query = {
      'manShift:shift.id': { $in: orderIds.map((o) => o.value) },
    };
    return $query;
  },

  shiftTimeFrom:
    (time: Moment) => ({ $modify: { startTime: [moment(time).startOf('minutes').toISOString(), undefined] } }),

  shiftTimeTo:
    (time: Moment) => ({ $modify: { startTime: [undefined, moment(time).startOf('minutes').toISOString()] } }),

  shiftDateFrom: (date: Moment) => ({ $modify: { startDate: [moment(date).startOf('day').toISOString(), undefined] } }),

  shiftDateTo: (date: Moment) => ({ $modify: { startDate: [undefined, moment(date).endOf('day').toISOString()] } }),

  internalName: (name: FormValues) => {
    if (!name) return undefined;
    return {
      'shift.external_id': { $ilike: `%${name}%` },
    };
  },
};

export default apiTransformationsApplication;
