import { OutputData } from '@editorjs/editorjs';
import { Form, FormState } from 'informed';
import Modal from 'react-modal';

import { ErrorAlert } from '../alerts';
import Button from '../buttons/Button';
import CloseIcon from '../icons/CloseIcon';
import TyTextArea from '../inputs/TyTextArea';
import { PageTitle } from '../texts';
import styles from '../TyAdvancedSearch/Components/Layout.module.css';
import validateEditorJsJson from '../../utils/Editor.utils';

type Props = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onSubmit: (data: OutputData | undefined) => void;
};

const EditorJsImportModal = ({ showModal, setShowModal, onSubmit }: Props) => {
  const submitImport = (json: FormState<{ json: string }>) => {
    const validated = validateEditorJsJson(json.values.json);
    if (validated.ok) {
      onSubmit(validated.data);
      setShowModal(false);
    } else {
      ErrorAlert(validated.message);
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="My dialog"
      className={`${styles.modal}`}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        <div>
          <PageTitle>Import dokumentu</PageTitle>
        </div>
        <div className="flex justify-center items-center gap-8">
          <Button className={styles.close} onClick={() => setShowModal(false)}>
            <CloseIcon />
          </Button>
        </div>
      </div>
      <Form className="px-5" onSubmit={submitImport}>
        <TyTextArea className="w-full pb-4 h-auto" id="import_json" name="json" label="JSON dokumentu" />
        <div className="py-8 flex justify-end">
          <div className={styles.footerRight}>
            <button type="submit" className="ty-button-primary">
              Přidat
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditorJsImportModal;
