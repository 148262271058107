import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';

export const loadShiftTemplatesThunk = createAsyncThunk('company/loadShiftTemplate', ({ company_id, query }) => feathersClient.service('shift-template').find({
  query: {
    company_id,
    ...query,
  },
}));

const loadCompanyShiftTemplatesReducer = {
  [loadShiftTemplatesThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadShiftTemplatesThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [loadShiftTemplatesThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

export const createShiftTemplateThunk = createAsyncThunk('company/createShiftTemplate', (body) => feathersClient.service('shift-template').create(body));

const createShiftTemplateReducer = {
  [createShiftTemplateThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),

  [createShiftTemplateThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [createShiftTemplateThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: [...state.data || [], ...action.payload.data || []],
  }),
};

export const patchShiftTemplateThunk = createAsyncThunk(
  'company/patchShiftTemplate',
  async (data) => {
    const { id, body } = data;
    return feathersClient.service('shift-template').patch(id, body);
  },
);

const patchShiftTemplateReducer = {
  [patchShiftTemplateThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [patchShiftTemplateThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [patchShiftTemplateThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    companyError: false,
    data: [...(state.data || []).filter((x) => x?.id !== action.payload.id), action.payload],
  }),
};

export const removeShiftTemplateThunk = createAsyncThunk(
  'company/removeShiftTemplate',
  async (id) => feathersClient.service('shift-template').remove(id),
);

const removeShiftTemplateReducer = {
  [removeShiftTemplateThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removeShiftTemplateThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [removeShiftTemplateThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

const shiftTemplatesSlice = createSlice({
  name: 'shiftTemplateContainer',
  initialState: {
    loading: false,
    error: null,
    pagination: {
      total: null,
      skip: 0,
      limit: 10,
    },
  },
  reducers: {
    updateRecord(state, action) {
      const data = state.data.map((x) => (x.id !== action.payload.id ? x : action.payload.record));
      return { ...state, data };
    },
  },
  extraReducers: {
    ...createShiftTemplateReducer,
    ...loadCompanyShiftTemplatesReducer,
    ...patchShiftTemplateReducer,
    ...removeShiftTemplateReducer,
  },
});

export const selectShiftTemplatesForCompany = (state) => state.data;

export const selectShiftTemplatesPagination = (state) => state.pagination;

export default shiftTemplatesSlice.reducer;
export const { actions } = shiftTemplatesSlice;
