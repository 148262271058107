import { ApplicationData } from '@tymbe/schema/application.interface';

import { SuccessAlert } from '../../../components/alerts';
import CancelInvitationButton from '../../user/shifts/Buttons/CancelInvitationButton';

const renderCancelInvitationButton = (applicationId: number, refetchData: () => void) => (
  <CancelInvitationButton
    applicationId={applicationId}
    onSuccess={() => {
      SuccessAlert('Pozvánka zrušena');
      refetchData();
    }}
  />
);

type RenderApplicationStateProps = {
  application: ApplicationData,
  refetchData: () => void,
};

const renderApplicationStateButton = ({
  application,
  refetchData,
}: RenderApplicationStateProps) => {
  const { invitation } = application;

  if (invitation && application?.state === null) {
    return renderCancelInvitationButton(application.id, refetchData);
  }

  return null;
};
export default renderApplicationStateButton;
