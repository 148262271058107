import { ReleaseTymberAdvancesData } from '@tymbe/schema/release-tymber-advances.interface';
import { Form } from 'informed';

import { SubmitButton } from '../buttons';
import ModalShell from '../modals/ModalShell';
import ReleaseAdvances from '../ReleaseAdvances/ReleaseAdvances';

type ReleaseAdvancesModalProps = {
  advancesAreBeingReleased: boolean;
  onClose: () => void;
  onSubmit: () => void;
  releaseAdvancesResult?: ReleaseTymberAdvancesData & { error?: string };
  showModal: boolean;
  onReleaseAdvancesClick: () => void;
};

const ReleaseTymberAdvancesModal = ({
  advancesAreBeingReleased,
  onClose,
  onSubmit,
  releaseAdvancesResult,
  showModal,
  onReleaseAdvancesClick,
}: ReleaseAdvancesModalProps) => (
  <>
    <ReleaseAdvances
      onClick={() => onReleaseAdvancesClick()}
    />
    {showModal
    && (
      <ModalShell
        onClose={advancesAreBeingReleased ? () => { } : onClose}
        showModal={showModal}
      >
        <div className="flex flex-row justify-between items-center">
          <h2>
            Uvolnění záloh
          </h2>
        </div>

        {releaseAdvancesResult && (
          <>
            <div className="flex flex-row justify-between items-center mb-5">
              {releaseAdvancesResult.error
              ?? `Vyplaceny zálohy za měsíc ${releaseAdvancesResult.month}/${releaseAdvancesResult.year} v celkové výši ${releaseAdvancesResult.sumOfAdvances} Kč, počet firem: ${releaseAdvancesResult.companyCount}.`}
            </div>

            <div className="flex gap-x-1 justify-end">
              <button
                className="ty-button-secondary"
                onClick={onClose}
                type="button"
              >
                Zavřít
              </button>
            </div>
          </>
        )}

        {!releaseAdvancesResult && (
          advancesAreBeingReleased
            ? (
              <div className="flex flex-row justify-between items-center mb-5">
                Uvolňování záloh právě probíhá.
                Neopouštějte prosím tuto stránku.
              </div>
            ) : (
              <>
                <div className="flex flex-row justify-between items-center mb-5">
                  Opravdu chcete nyní spustit uvolnění záloh za předchozí měsíc?
                  <br />
                  Tato operace může trvat i několik minut,
                  během kterých není doporučeno opouštět tuto stránku a provádět další úkony v aplikaci.
                </div>

                <Form onSubmit={onSubmit}>
                  <div className="flex gap-x-1 justify-end">
                    <button
                      className="ty-button-secondary"
                      onClick={onClose}
                      type="button"
                    >
                      Zrušit
                    </button>
                    <SubmitButton
                      buttontext="Uvolnit zálohy"
                      className="ty-button-primary"
                    />
                  </div>
                </Form>
              </>
            )
        )}
      </ModalShell>
    )}
  </>
);

export default ReleaseTymberAdvancesModal;
