import classNames from 'classnames';
import useCollapse from 'react-collapsed';

import TyAsyncSelect from '../../inputs/TyAsyncSelect';
import TyDateInput from '../../inputs/TyDateInput';
import TyInput from '../../inputs/TyInput';
import styles from '../Components/TyAdvancedSearchCore.module.css';
import { labelsValues, scoreSearchValues } from '../Libs/enums';
import {
  loadPositionOptions,
  loadTymberOptions,
  loadCompanyOptions,
  loadBranchofficeOptions,
  loadDepartmentOptions,
  loadConfirmatorOptions,
} from '../Libs/loadOptions';

const Basic: React.FC = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true });

  return (
    <>
      <div className={styles.triggerContainer}>
        <div {...getToggleProps()} className={styles.trigger}>
          <h2>Základní parametry</h2>
        </div>

        <div {...getToggleProps()} className={styles.trigger}>

          <div className={classNames(
            styles.triggerIcon,
            { [styles.triggerIconRotated]: isExpanded },
          )}
          />

        </div>
      </div>

      <section {...getCollapseProps()}>

        <div className={styles.row}>

          <div>
            <h3>Datum směny od - do</h3>
            <TyDateInput
              name={labelsValues.shiftDateFrom.name}
              label={labelsValues.shiftDateFrom.label}
              className={styles.tyInputs}
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.shiftDateTo.name}
              label={labelsValues.shiftDateTo.label}
              className={styles.tyInputs}
            />
          </div>

          <div>
            <h3>Čas směny</h3>
            <TyDateInput
              name={labelsValues.shiftTimeFrom.name}
              label={labelsValues.shiftTimeFrom.label}
              className={styles.tyInputs}
              minuteStep={5}
              picker="time"
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.shiftTimeTo.name}
              label={labelsValues.shiftTimeTo.label}
              className={styles.tyInputs}
              minuteStep={5}
              picker="time"
            />
          </div>

        </div>

        <div className={styles.row}>

          <div>
            <h3>Pozice</h3>

            <TyAsyncSelect
              loadOptions={loadPositionOptions}
              name={labelsValues.position.name}
              label={labelsValues.position.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>

          <div>
            <h3>Tymber</h3>

            <TyAsyncSelect
              loadOptions={loadTymberOptions}
              name={labelsValues.tymber.name}
              label={labelsValues.tymber.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>

        </div>

      </section>

    </>
  );
};

const Other: React.FC = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <>
      <div className={styles.triggerContainer}>
        <div {...getToggleProps()} className={styles.trigger}>
          <h2>Ostatní parametry</h2>
        </div>

        <div {...getToggleProps()} className={styles.trigger}>

          <div className={classNames(
            styles.triggerIcon,
            { [styles.triggerIconRotated]: isExpanded },
          )}
          />

        </div>
      </div>

      <section {...getCollapseProps()}>

        <div className={styles.row}>

          <div>
            <h3>Firma</h3>

            <TyAsyncSelect
              loadOptions={loadCompanyOptions}
              name={labelsValues.company.name}
              label={labelsValues.company.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>

          <div>
            <h3>Provozovna</h3>

            <TyAsyncSelect
              loadOptions={loadBranchofficeOptions}
              name={labelsValues.branchoffice.name}
              label={labelsValues.branchoffice.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
        </div>

        <div className={styles.row}>

          <div>

            <h3>Oddělení</h3>

            <TyAsyncSelect
              loadOptions={loadDepartmentOptions}
              name={labelsValues.department.name}
              label={labelsValues.department.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>

          <div>
            <h3>Hodnocení</h3>
            <TyAsyncSelect
              name={labelsValues.score.name}
              label={labelsValues.score.label}
              loadOptions={async () => ({ options: scoreSearchValues })}
              className={styles.tyInputs}
              isClearable
            />

          </div>

        </div>

        <div className={styles.row}>

          <div>
            <h3>Interní označení</h3>
            <TyInput
              name={labelsValues.internalName.name}
              label={labelsValues.internalName.label}
              className="w-56"
            />

          </div>

          <div>
            <h3>Docházku potvrdil/a</h3>
            <TyAsyncSelect
              loadOptions={loadConfirmatorOptions}
              name={labelsValues.confirmator.name}
              label={labelsValues.confirmator.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>

        </div>

        <div className={styles.row}>

          <div>
            <h3>Datum potvrzení od - do</h3>
            <TyDateInput
              name={labelsValues.confirmDateFrom.name}
              label={labelsValues.confirmDateFrom.label}
              className={styles.tyInputs}
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.confirmDateTo.name}
              label={labelsValues.confirmDateTo.label}
              className={styles.tyInputs}
            />
          </div>

        </div>
      </section>
    </>
  );
};

const Body: React.FC = () => (
  <div className={styles.container}>
    <Basic />
    <br />
    <svg width="100%" height="1" viewBox="0 0 857 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="1" fill="#DBDFEE" />
    </svg>
    <br />
    <Other />
  </div>
);

export default Body;
