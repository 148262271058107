import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import CrossIcon from './icons/CrossIcon';
import ShiftDetailPage from '../shiftDetail.page';

const ShiftDetailModal = () => {
  const navigate = useNavigate();

  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <Modal
      isOpen
      onRequestClose={onModalClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      contentLabel="My dialog"
      className="fixed w-[450px] top-4 right-4 bottom-4 bg-bg p-7 translate-1/2 rounded-lg overflow-auto drop-shadow-lg"
      overlayClassName="bg-overlay fixed inset-0 z-modal"
    >
      <div className="flex justify-between align-middle font-bold">
        Detail Směny <CrossIcon onClick={onModalClose} />
      </div>
      <ShiftDetailPage />
    </Modal>
  );
};

export default ShiftDetailModal;
