import { ComponentPropsWithoutRef } from 'react';

export type TableCellProps = ComponentPropsWithoutRef<'td'> & {
  head?: boolean;
};

const defaultClassName = 'border border-fg-200 p-2';

export const TableCell = ({ children, className, head = false, ...rest }: TableCellProps) => (
  head
    ? <th className={[className, defaultClassName, 'text-secondary', 'bg-fg-100'].join(' ')} {...rest}>{children}</th>
    : <td className={[className, defaultClassName].join(' ')} {...rest}>{children}</td>
);
