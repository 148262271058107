import { useFormApi } from 'informed';
import { useEffect } from 'react';

import { useUser } from '../../../../apiClient/ApiContext';
import TyInput from '../../../../components/inputs/TyInput';
import TySelectRole from '../../../../components/inputs/TySelectRole/TySelectRole';
import validatePhoneNumber from '../../../../components/inputs/Validators/phoneNumber';
import { LoginData } from '../../../../types/TymbeApi';
import { Roles } from '../../../../utils/enums';
import { addPlusToPhone } from '../../../../utils/phoneNumber';

export interface UserFormValues extends LoginData {
  re_password: string,
  phone?: string,
}

type UserFormProps = {
  login?: LoginData
};

const UserForm = ({ login = undefined }: UserFormProps) => {
  const formApi = useFormApi<UserFormValues>();
  const user = useUser();

  useEffect(() => {
    if (!login) return;
    formApi.setValues({
      ...login,
      phone: addPlusToPhone(login.person?.contact?.find((cntct) => cntct.type === 'mobile_phone')?.value),
    });
  }, [formApi, login]);

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <div className="flex flex-row gap-2 flex-wrap">
          <TyInput
            className="w-full md:flex-1"
            type="text"
            name="person.first_name"
            label="Jméno"
            required
          />
          <TyInput
            className="w-full md:flex-1"
            type="text"
            name="person.last_name"
            label="Příjmení"
            required
          />
        </div>
        <TyInput
          name="username"
          type="email"
          label="Email"
          disabled={!!login?.username}
          required
        />
        <TyInput
          name="phone"
          type="phone"
          label="Telefon"
          validate={validatePhoneNumber}
          validateOn="change"
        />
        <TySelectRole
          name="role[0]"
          label="Úroveň oprávnění"
          isDisabled={login?.id === user.id}
          filter={{
            slug:
            [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.TYMBE_ADMIN, Roles.TYMBE_COORDINATOR],
          }}
          required
        />
      </div>
    </div>
  );
};

export default UserForm;
