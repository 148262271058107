import { UtilityData } from '@tymbe/schema/utility.interface';
import { Form, InformedProps } from 'informed';
import { ReactNode } from 'react';

import TyInput from '../../../../components/inputs/TyInput';
import TyTextArea from '../../../../components/inputs/TyTextArea';

type UtilityFormProps = InformedProps<{
  children?: ReactNode;
  title?: string;
}, UtilityData>;

const UtilityForm = ({ children, title, ...rest }: UtilityFormProps) => (
  <Form className="flex flex-col gap-4" {...rest}>
    <h2>{title}</h2>
    <TyInput name="name" label="Název" required />
    <TyInput type="number" name="price" label="Cena" mask={(value) => Number(value).toString()} required />
    <TyTextArea name="description" label="Popis" />
    <TyTextArea name="note" label="Poznámka" />
    {children}
  </Form>
);

export default UtilityForm;
