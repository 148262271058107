import PropTypes from 'prop-types';
import css from './tymbe.module.css';

const Tymbe = ({ textcolor = '#28367B' }) => {
  const isChristmas = new Date().getMonth() === 11;
  return (
    <div className={isChristmas ? css.santa : undefined}>
      <svg
        width="82"
        height="40"
        viewBox="0 0 82 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2845 1.51611C9.94557 1.51611 1.53613 9.80894 1.53613 19.9999C1.53613 30.1909 9.94557 38.4837 20.2845 38.4837H61.7156C72.0545 38.4837 80.4639 30.1909 80.4639 19.9999C80.4639 9.80894 72.0545 1.51611 61.7156 1.51611H20.2845Z"
          fill="#B6CD1E"
        />
        <path
          d="M20.2845 1.5162C9.94553 1.5162 1.53609 9.80903 1.53609 20C1.53609 30.191 9.94553 38.4838 20.2845 38.4838H61.7155C72.0545 38.4838 80.4639 30.191 80.4639 20C80.4639 9.80903 72.0545 1.5162 61.7155 1.5162H20.2845ZM61.7155 40H20.2845C9.09793 40 0 31.0301 0 20C0 8.9728 9.09793 0 20.2845 0H61.7155C72.9021 0 82 8.96991 82 20C82 31.0272 72.9021 40 61.7155 40Z"
          fill={textcolor}
        />
        <path
          d="M45.2205 15.272C43.9794 15.272 43.0971 15.7465 42.5562 16.8113C41.7953 15.6857 41.156 15.272 39.8138 15.272C38.512 15.272 37.5921 15.6655 36.9325 16.9502V15.5295H34.491V25.2401H37.0945V19.3374C37.0945 18.0729 37.6355 17.4421 38.6769 17.4421C39.8167 17.4421 40.239 17.8356 40.239 19.3952V25.2372H42.8425V19.4357C42.8425 18.2523 43.5426 17.364 44.503 17.364C45.4229 17.364 46.0449 18.1915 46.0449 19.3576V25.2401H48.5877V18.5098C48.5848 16.6927 47.202 15.272 45.2205 15.272Z"
          fill={textcolor}
        />
        <path
          d="M55.3222 23.4055C54.0811 23.4055 53.1207 22.2596 53.1207 20.6219C53.1207 18.5096 53.8613 17.4651 55.3019 17.4651C56.8235 17.4651 57.5439 18.472 57.5439 20.4657C57.5439 22.1989 56.6239 23.4055 55.3222 23.4055ZM56.1437 15.2718C54.923 15.2718 53.9625 15.7637 53.222 16.7909V11.9761H50.6589V25.24H53.1612L53.1815 24.0565C53.8034 25.0432 54.7234 25.4975 55.9239 25.4975C58.4059 25.4975 60.1879 23.5646 60.1879 20.2863C60.1879 17.3436 58.6865 15.2718 56.1437 15.2718Z"
          fill={textcolor}
        />
        <path
          d="M65.6119 17.3061C66.6331 17.3061 67.5935 17.9977 67.634 19.4357H63.6101C63.7114 18.096 64.5705 17.3061 65.6119 17.3061ZM65.7334 23.4635C64.4519 23.4635 63.613 22.5144 63.532 21.0561H70.3186C70.3186 17.2077 68.7159 15.272 65.6929 15.272C62.6699 15.272 60.9285 17.2077 60.9285 20.2054C60.9285 23.7182 62.5716 25.4948 65.8521 25.4948C67.9551 25.4948 69.5751 24.3692 70.1768 22.3756H67.4952C67.3129 23.0497 66.5724 23.4635 65.7334 23.4635Z"
          fill={textcolor}
        />
        <path
          d="M22.3701 10.324L11.6812 11.9212L12.0312 14.1839L15.9886 13.5937L17.6317 24.2852L20.3828 23.8743L18.7396 13.1828L22.7173 12.5867L22.3701 10.324Z"
          fill={textcolor}
        />
        <path
          d="M29.2435 21.7101C28.9803 22.9659 28.1037 23.7327 26.8078 23.4693C25.7287 23.2494 25.3035 22.5579 25.5552 21.36L26.7904 15.4832L24.2418 14.9624L22.9256 21.224C22.4859 23.3102 23.5446 24.9971 25.4655 25.3906C26.7991 25.6626 27.9041 25.3646 28.798 24.4184L28.5174 25.7523C28.15 27.5029 26.4085 28.6285 24.6352 28.2668C23.5736 28.0498 22.6884 27.3293 22.2689 26.3426L19.998 27.2772C20.7299 29.0046 22.2776 30.2633 24.1348 30.6424C27.2359 31.2761 30.2849 29.3027 30.9271 26.2413L32.9261 16.7332L30.3977 16.2182L29.2435 21.7101Z"
          fill={textcolor}
        />
      </svg>
    </div>
  );
};

Tymbe.propTypes = {
  textcolor: PropTypes.string,
};

export default Tymbe;
