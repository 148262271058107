import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PrimaryButton, SubmitButton } from '../../components/buttons';
import { FixedGrid, ResponsiveGrid } from '../../components/grids';
import { UserIcon } from '../../components/icons';
import { ManSit } from '../../components/illustrations';
import { PasswordFormInput, SimpleFormItem } from '../../components/inputs';
import { ErrorResult, SuccessResult } from '../../components/results';
import { LinkText, SimpleHeadingText, SimpleText } from '../../components/texts';
import Wrapper from '../../components/wrapper';
import { white } from '../../utils/colors';
import Spinner from '../../utils/spinner';

const ResetContainer = ({ error, loading, emailSent, onFinish, onFinishFailed }) => {
  useEffect(() => {
    if (!loading || error) return undefined;
    const timer = setTimeout(() => window.location.replace(import.meta.env.CLIENT_WEB_URL), 300);
    return () => { clearTimeout(timer); };
  });

  const renderResult = (renderError) => {
    const errorMessage = `${renderError} Please try again.`;
    if (loading) return <Spinner loadingtext="Resetting your password. Please wait" />;
    return error ? (
      <ErrorResult
        title="Password Reset Failed"
        subTitle={errorMessage}
        extra={[
          <PrimaryButton key="signin">
            <LinkText style={{ color: white }} linkTo="/signin" linkText="Sign In" />
          </PrimaryButton>,
        ]}
      />
    ) : (
      <SuccessResult
        title="Password Reset Successful"
      />
    );
  };

  const renderForm = () => (
    <div>
      <ResponsiveGrid xl={10}>
        <FixedGrid align="middle">
          <ManSit />
          <SimpleHeadingText>Tady si nastavíš nové heslo</SimpleHeadingText>
        </FixedGrid>
        <Form
          hideRequiredMark
          name="forgot_password_form"
          initialValues={{
            remember: true,
          }}
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <PasswordFormInput
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your new password',
              },
              { min: 6, message: 'Password must be minimum 6 characters' },
            ]}
            placeholder="Heslo"
          />
          <PasswordFormInput
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: 'Please confirm your password',
              },
              { min: 6, message: 'Password must be minimum 6 characters' },
            ]}
            placeholder="Heslo znovu"
          />
          <SimpleFormItem>
            <Row gutter={[16, 24]} justify="space-around" align="middle">
              <Col
                style={{ textAlign: 'center' }}
                xs={24}
                sm={24}
                md={8}
                lg={6}
                xl={6}
              >
                <SubmitButton buttontext="Uložit heslo" />
              </Col>
            </Row>
          </SimpleFormItem>
        </Form>
        <Row gutter={[16, 24]} justify="space-around" align="middle">
          <Col
            style={{ textAlign: 'center' }}
            xs={24}
            sm={24}
            md={18}
            lg={16}
            xl={16}
          >
            <SimpleText justify="center">Nechceš nakonec heslo změnit?</SimpleText>
            <Row gutter={[16, 24]} justify="center" align="middle">
              <Link to="/signin" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <UserIcon iconcolor="#B3CA1F" />
                <b style={{ marginLeft: '10px' }}>Přihlásit se</b>
              </Link>
            </Row>
          </Col>
        </Row>

      </ResponsiveGrid>
    </div>
  );
  return <Wrapper>{emailSent ? renderResult(error) : renderForm()}</Wrapper>;
};

ResetContainer.propTypes = {
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  emailSent: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
};

export default ResetContainer;
