import { ForeignerOrigin, Gender } from '@tymbe/schema/enums';
import { foreignerOriginTexts } from '@tymbe/utils/foreignerOriginTexts';

export const genderOptions = [
  {
    label: 'Muž',
    value: Gender.MALE,
  },
  {
    label: 'Žena',
    value: Gender.FEMALE,
  },
];
export const foreignerOptions = Object.values(ForeignerOrigin).map((item) => ({
  label: foreignerOriginTexts[item].unofficial
    ? `${foreignerOriginTexts[item].official} ${foreignerOriginTexts[item].unofficial}`
    : foreignerOriginTexts[item].official,
  value: item,
}));
