import { CompanyBlockedUserData } from '@tymbe/schema/company-blocked-user.interface';
import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../apiClient';
import { ErrorAlert } from '../../../components/alerts';
import { PrimaryButton, SecondaryButton, SubmitButton } from '../../../components/buttons';
import { EditIcon } from '../../../components/icons';
import TyInput from '../../../components/inputs/TyInput';
import TySelectCompany from '../../../components/inputs/TySelectCompany';
import ModalShell from '../../../components/modals/ModalShell';
import { DeepPartial } from '../../../types/utils';

export interface EditBlockProps {
  block: CompanyBlockedUserData;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const EditBlock = ({ block, onSuccess }: EditBlockProps) => {
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: patchBlock } = useMutation(
    ['editBlock'],
    async (req): Promise<void> => {
      await feathersClient.service('company-blocked-user').patch(block.id, req);
    },
    {
      onSuccess,
    },
  );

  const initialValues = {
    company: block?.company,
    note: block?.note,
  };

  const onSubmit = async ({ values }: FormState<Partial<CompanyBlockedUserData>>) => {
    const blockFormData: DeepPartial<CompanyBlockedUserData> = {
      note: values.note,
    };

    try {
      await patchBlock(blockFormData);
      setShowModal(false);
    } catch {
      ErrorAlert('Při editaci blokace došlo k chybě');
    }
  };
  return (
    <>
      <div className="p-1">
        <PrimaryButton
          className="ty-icon-button ty-icon-button-toggle bg-primary"
          type="button"
          icon={<EditIcon />}
          onClick={() => setShowModal(true)}
        />
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <h3 className="ty-h3 text-align-start">
            Upravit důvod blokace
          </h3>

          <TySelectCompany
            name="company"
            label="Firma"
            className="w-full md:flex-1 pb-2"
            required
            isDisabled
          />
          <TyInput
            name="note"
            label="Poznámka"
            className="w-full md:flex-1"
            required
          />
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton className="ml-3" buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default EditBlock;
