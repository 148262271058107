/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';
import { ApplicationState } from '../../../../utils/enums';

export const loadJobsThunk = createAsyncThunk('job/loadJobs', async (query) => {
  const jobs = await feathersClient.service('shift').find({
    query: {
      $eager: '[company,branchoffice.address,position,shiftTemplate,documentType,perk,manShift]',
      ...query,
    },
  });
  return jobs;
});

const loadJobsReducer = {
  [loadJobsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.jobsData = null;
  },
  [loadJobsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload && action.error.message;
    state.jobsData = null;
    state.jobs = null;
  },
  [loadJobsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.jobsData = action.payload;
    state.jobs = action.payload.data;
  },
};

export const loadMoreJobsThunk = createAsyncThunk('job/loadMoreJobs', (query) =>
  feathersClient.service('shift').find({
    query: {
      $eager: '[company,branchoffice.address,position,shiftTemplate,documentType,perk,manShift]',
      ...query,
    },
  }));

const loadMoreJobsReducer = {
  [loadMoreJobsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.jobsData = null;
  },
  [loadMoreJobsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload && action.error.message;
    state.jobsData = null;
  },
  [loadMoreJobsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.jobsData = action.payload;
    state.jobs = state.jobs ? [...state.jobs, ...action.payload.data] : [];
  },
};

export const loadApplicationsThunk = createAsyncThunk(
  'job/application',
  async (
    query = {
      $eager: '[attendance.paymentRequest, manShift, shift.[branchoffice,company.address]]',
    },
  ) => {
    const applications = await feathersClient.service('application').find({
      query,
    });
    return applications;
  },
);

const loadApplicationsReducer = {
  [loadApplicationsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.applicationsData = null;
    state.applications = null;
  },
  [loadApplicationsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.applicationsData = null;
    state.applications = null;
  },
  [loadApplicationsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.applicationsData = action.payload;
    state.applications = action.payload.data;
  },
};
export const loadInvitationsThunk = createAsyncThunk('job/applications-invitations', async () => {
  const invitations = await feathersClient.service('application').find({
    query: {
      $eager: '[attendance, manShift, shift.[branchoffice,company.address]]',
      invitation: true,
      state: {
        $null: true,
      },
    },
  });
  return invitations;
});

const loadInvitationsReducer = {
  [loadInvitationsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadInvitationsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.invitationsData = null;
    state.invitations = null;
  },
  [loadInvitationsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.invitationsData = action.payload;
    state.invitations = action.payload.data;
  },
};

export const loadConfirmedApplicationsThunk = createAsyncThunk(
  'job/applicationConfirmed',
  async () => {
    const applications = await feathersClient.service('application').find({
      query: {
        $eager: '[attendance, manShift, shift.[company.address,branchoffice]]',
        state: ApplicationState.CONFIRMED,
      },
    });
    return applications;
  },
);

const loadConfirmedApplicationsReducer = {
  [loadConfirmedApplicationsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.applicationsConfirmedData = null;
  },
  [loadConfirmedApplicationsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.applicationsConfirmedData = null;
    state.applicationsConfirmed = null;
  },
  [loadConfirmedApplicationsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.applicationsConfirmedData = action.payload;
    state.applicationsConfirmed = action.payload.data;
  },
};

export const claimShiftThunk = createAsyncThunk('jobs/claim-shift', (data) => {
  const { job } = data.jobs;
  const req = {
    shift_id: job.id,
  };

  return feathersClient.service('claim-shift').create(req);
});

const claimShiftReducers = {
  [claimShiftThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [claimShiftThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [claimShiftThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};
export const patchManShiftThunk = createAsyncThunk('jobs/patchManShift', async (data) => {
  const { id, body } = data;
  await feathersClient.service('man-shift').patch(id, body);
});

const patchManShiftReducer = {
  [patchManShiftThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [patchManShiftThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [patchManShiftThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const createApplicationExcuseThunk = createAsyncThunk(
  'jobs/createApplicationExcuse',
  async (data) => {
    await feathersClient.service('application-excuse').create(data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
);

const createApplicationExcuseReducer = {
  [createApplicationExcuseThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createApplicationExcuseThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createApplicationExcuseThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};
export const loadApplicationExcuseThunk = createAsyncThunk(
  'job/loadApplicationExcuse',
  async (query) => {
    const applicationsExcuse = await feathersClient.service('application-excuse').find({
      query,
    });
    return applicationsExcuse;
  },
);

const loadApplicationExcuseReducer = {
  [loadApplicationExcuseThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadApplicationExcuseThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.applicationExcuseData = null;
    state.applicationExcuse = null;
  },
  [loadApplicationExcuseThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.applicationExcuseData = action.payload;
    state.applicationExcuse = action.payload.data;
  },
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    loading: false,
    error: null,
    jobs: null,
    jobsData: null,
    invitations: null,
    invitationsData: null,
    applications: null,
    applicationsData: null,
    applicationsConfirmedData: null,
    applicationsConfirmed: null,
    applicationExcuse: null,
    applicationExcuseData: null,
  },
  reducers: {},
  extraReducers: {
    ...loadJobsReducer,
    ...loadMoreJobsReducer,
    ...claimShiftReducers,
    ...loadApplicationsReducer,
    ...loadInvitationsReducer,
    ...loadConfirmedApplicationsReducer,
    ...patchManShiftReducer,
    ...createApplicationExcuseReducer,
    ...loadApplicationExcuseReducer,
  },
});

export const selectJobsError = createSelector(
  (state) => state.jobs.error,
  (error) => {
    if (error !== null) return error;
    return undefined;
  },
);
export const selectJobsLoading = createSelector(
  (state) => state.jobs.loading,
  (loading) => loading,
);

export const selectJobs = createSelector(
  (state) => state.jobs.jobs,
  (jobs) => jobs || [],
);

export const selectJobsData = createSelector(
  (state) => state.jobs.jobsData,
  (jobsData) => jobsData || [],
);
export const selectShiftApplications = createSelector(
  (state) => state.jobs.applications,
  (applications) => applications || [],
);
export const selectInvitations = createSelector(
  (state) => state.jobs.invitations,
  (invitations) => invitations || [],
);

export const selectConfirmedApplications = createSelector(
  (state) => state.jobs.applicationsConfirmed,
  (applicationsConfirmed) => applicationsConfirmed || [],
);
export const selectApplicationExcuse = createSelector(
  (state) => state.jobs.applicationExcuse,
  (res) => res || [],
);

export const { removeJob: removeJobAction, setJob: setJobAction } = jobsSlice.actions;

export default jobsSlice.reducer;
