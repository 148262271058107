import classNames from 'classnames';
import EmojiPicker, { EmojiClickData, SuggestionMode } from 'emoji-picker-react';
import { FieldProps, useField } from 'informed';
import { ComponentProps, useState } from 'react';

import Emoji from './Emoji';
import useOutsideClick from './utils';

type EmojiPickerOwnProps = {
  className?: string
};

type EmojiPickerProps = EmojiPickerOwnProps & Omit<ComponentProps<'div'>, keyof EmojiPickerOwnProps>;

const EmojiPickerComponent = <Fields extends object>(props: FieldProps<EmojiPickerProps, string, Fields>) => {
  const { userProps, fieldApi, fieldState } = useField<EmojiPickerProps, string>(props);
  const { setValue } = fieldApi;
  const { value } = fieldState;
  const {
    className,
    ...rest
  } = userProps;

  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false));

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    setValue(emojiData.emoji);
    setOpen(false);
  };

  return (
    <div className="inline-block" ref={ref}>
      <button type="button" onClick={() => setOpen((prevValue) => !prevValue)} className="p-0">
        <Emoji className={classNames('hover:bg-secondary-200', className)} {...rest} emoji={value} />
      </button>
      <EmojiPicker
        open={open}
        style={{ position: 'absolute', zIndex: 1000 }}
        lazyLoadEmojis
        suggestedEmojisMode={SuggestionMode.RECENT}
        onEmojiClick={(emojiData) => handleEmojiClick(emojiData)}
      />
    </div>
  );
};

export default EmojiPickerComponent;
