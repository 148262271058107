import { FieldProps, RadioGroupProps, useField } from 'informed';
import { useMemo } from 'react';

import TyInputError from './TyInputError';
import TyRadio from './TyRadio';
import TyRadioGroupContext from './TyRadioGroupContext';

import './TyInput.css';

const TyRadioGroup = (props: FieldProps<RadioGroupProps, string | undefined, Record<string, unknown>>) => {
  const { required, validate } = props;
  const { fieldApi, fieldState, userProps } = useField<RadioGroupProps, string | undefined>(props);
  const { id, label, options, children } = userProps;
  const { showError, error } = fieldState;

  const groupContext = useMemo(() => ({
    radioGroupApi: fieldApi,
    radioGroupState: fieldState,
    ...props,
    id,
  }), [fieldApi, fieldState, id, props]);

  return (
    <TyRadioGroupContext.Provider value={groupContext}>
      <fieldset
        aria-describedby={`${id}-error`}
        className={`ty_input_field ${showError && error ? 'ty_input_error' : ''}`}
      >
        <legend>
          {label}
          <TyInputError
            fieldState={fieldState}
            required={required}
            validate={validate}
          />
        </legend>
        {options ? options.map((option) => <TyRadio key={`${id}-${option.value}`} {...option} />) : children}
      </fieldset>
    </TyRadioGroupContext.Provider>
  );
};

export default TyRadioGroup;
