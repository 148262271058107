import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { Form, FormState } from 'informed';

import { SubmitButton } from '../../../../../components/buttons';
import TyRadio from '../../../../../components/inputs/TyRadio';
import TyRadioGroup from '../../../../../components/inputs/TyRadioGroup';
import ModalShell from '../../../../../components/modals/ModalShell';

type ChoosePropertyModalProps = {
  showModal: boolean;
  initialValues: { editedProperty: PaySupplementType };
  onClose: () => void;
  onSubmit?: (formData: FormState<{ editedProperty: PaySupplementType }>) => void;
};

const ChoosePropertyModal = ({
  showModal,
  initialValues,
  onClose,
  onSubmit,
}: ChoosePropertyModalProps) => (
  <ModalShell
    showModal={showModal}
    onClose={onClose}
  >
    <div className="flex flex-row justify-between items-center">
      <h2>Globální editace pro</h2>
    </div>
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {/* TODO OD - fix the style */}
      <div className="flex gap-y-1 justify-between items-center h-max">
        <TyRadioGroup
          className="ty-radio-group"
          name="editedProperty"
        >
          <TyRadio
            className="pb-5"
            value={PaySupplementType.Holiday}
            label="Příplatek za svátek"
          />
          <TyRadio
            className="pb-5"
            value={PaySupplementType.Night}
            label="Příplatek za noční"
          />
          <TyRadio
            className="pb-7"
            value={PaySupplementType.Weekend}
            label="Příplatek za víkend"
          />
        </TyRadioGroup>
      </div>
      <div className="flex gap-x-1 justify-end">
        <button
          type="button"
          className="ty-button-secondary"
          onClick={onClose}
        >
          Zrušit
        </button>
        <SubmitButton
          className="ty-button-primary"
          buttontext="Vybrat"
        />
      </div>
    </Form>
  </ModalShell>
);

export default ChoosePropertyModal;
