import { GroupBase, OptionsOrGroups } from 'react-select';

import TyAsyncSelect, { TyAsyncSelectProps } from './TyAsyncSelect';
import feathersClient from '../../apiClient';
import { PositionData } from '../../types/TymbeApi';

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';
type TySelectPositionProps<Fields extends object> =
  Omit<TyAsyncSelectProps<Fields, PositionData, GroupBase<PositionData>, void, false>, ProvidedProps>;

const loadPositionOptions = async (
  search: string,
  options: OptionsOrGroups<PositionData, GroupBase<PositionData>>,
) => {
  const resp = await feathersClient.service('position').find({
    query: { name: { $ilike: `%${search}%` } },
  });

  return {
    options: resp.data,
    hasMore: resp.total > options.length + resp.data.length,
  };
};

const TySelectPosition = <Fields extends object>({
  debounceTimeout = 1000,
  ...rest
}: TySelectPositionProps<Fields>) => (
  <TyAsyncSelect
    {...rest}
    loadOptions={loadPositionOptions}
    debounceTimeout={debounceTimeout}
    getOptionLabel={(option) => option.name}
    getOptionValue={(option) => String(option.id)}
  />
);

export default TySelectPosition as typeof TySelectPosition;
