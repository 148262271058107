import { ValidateResp } from '@tymbe/schema/invite-check.interface';
import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import { FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';

import TableColumnWithControls from './TableColumnWithControls';
import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import { SuccessAlert } from '../../../components/alerts';
import { SubmitButton } from '../../../components/buttons';
import ModalShell from '../../../components/modals/ModalShell';
import InvitationModal from '../../../pages-legacy/company/orders/InvitationModal';
import {
  NoFreeManShiftBehavior,
  invite,
} from '../../../pages-legacy/user/shifts/ShiftUtils/ShiftInvite';
import { PersonData } from '../../../types/TymbeApi';
import { Roles } from '../../../utils/enums';

interface InvitationCountColumnProps {
  shift: ShiftStateData,
  showControls: boolean,
  onFinished: () => void,
}

interface Result {
  tymberId: number,
  noFreeManShift?: boolean,
  applicationExists?: boolean,
  success?: boolean,
  error?: unknown,
}

type CheckInvitationData = {
  person_id: number,
  shift_id: number | number[],
};

const InvitationsCountColumn = ({ shift, showControls, onFinished }: InvitationCountColumnProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showResultsModal, setShowResultsModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [invitationList, setInvitationList] = useState<PersonData[]>([]);
  const [resultList, setResultList] = useState<Result[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorModalMessages, setErrorModalMessages] = useState<string[] | undefined>(undefined);

  const user = useUser();

  const { mutateAsync: checkInvitation } = useMutation(
    ['check-invitation'],
    (data: CheckInvitationData[]) => feathersClient.service('invite-check').create(data),
  );

  const sendInvitationRequests = async (
    tymberIds: number[],
    shiftId: number,
    canCreateManShift: NoFreeManShiftBehavior,
  ) => {
    const newResultList: Result[] = [];
    // must not run in parallel to prevent race conditions
    // eslint-disable-next-line no-restricted-syntax
    for (const tymberId of tymberIds) {
      try {
        const {
          noFreeManShift,
          applicationExists,
          application: inviteApplication,
        // eslint-disable-next-line no-await-in-loop
        } = await invite(tymberId, shiftId, canCreateManShift);

        if (noFreeManShift) {
          newResultList.push({ tymberId, noFreeManShift });
        } else if (applicationExists) {
          newResultList.push({ tymberId, success: true, applicationExists });
        } else {
          newResultList.push({ tymberId, success: true });
        }
      } catch (error) {
        newResultList.push({ tymberId, error });
      }
    }

    if (newResultList.every((result) => result.success)) {
      SuccessAlert('Pozvánky úspěšně odeslány');
      onFinished();
      return;
    }

    setResultList(newResultList);
    setShowResultsModal(true);
  };

  const onSubmit = async ({ values }: FormState<PersonData & { invitation_list: PersonData[] }>) => {
    setDisabled(true);

    if (!values || !values.invitation_list) return;
    setInvitationList(values.invitation_list);

    const tymberIds = values.invitation_list.map((person: PersonData) => person.id);

    const checkResult: ValidateResp = await checkInvitation(
      tymberIds.map((tymber) => ({ shift_id: shift.id, person_id: tymber })),
    );

    if (!checkResult.canInvite && !user.hasRoles([Roles.SUPER_ADMIN])) {
      setShowErrorModal(true);
      setErrorModalMessages(checkResult.messages);
    } else {
      sendInvitationRequests(tymberIds, shift.id, NoFreeManShiftBehavior.Return);
      setShowModal(false);
    }
    setDisabled(false);
  };

  const addManShift = async () => {
    const tymberIds = invitationList.map((person: PersonData) => person.id);
    sendInvitationRequests(tymberIds, shift.id, NoFreeManShiftBehavior.CreateNew);
    setShowResultsModal(false);
  };

  return (
    <>
      <TableColumnWithControls
        showControls={showControls}
        onPlusClick={() => setShowModal(true)}
      >
        {shift.invitation_orders_count}
      </TableColumnWithControls>
      <InvitationModal
        excludeInvitedPersonForShiftId={shift.id}
        formDisabled={disabled}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={onSubmit}
        company={{
          id: shift.company_id,
          branchoffice: shift.branchoffice?.parent_id,
          department: shift.branchoffice_id,
        }}
        shiftDate={shift.start_time}
      />
      <ModalShell showModal={showResultsModal} onClose={() => { setShowResultsModal(false); onFinished(); }}>
        <h3 className="ty-h3 text-align-start">
          Nepodařilo se pozvat všechny zvolené tymbery.
        </h3>

        {resultList.some((result) => result.success) && (
          <>
            <h4>Úspěšně pozvaní:</h4>
            <ul className="pl-4">
              {resultList.filter((result) => result.success).map((result) => (
                <li key={result.tymberId}>
                  {result.tymberId} - {`${invitationList.find((person) => person.id === result.tymberId)?.first_name} ${invitationList.find((person) => person.id === result.tymberId)?.last_name}`}
                </li>
              ))}
            </ul>
          </>
        )}

        <h4>Nezdařilo se:</h4>
        <ul className="pl-4">
          {resultList.filter((result) => !result.success).map((result) => (
            <li key={result.tymberId}>
              {result.tymberId} - {`${invitationList.find((person) => person.id === result.tymberId)?.first_name} ${invitationList.find((person) => person.id === result.tymberId)?.last_name}`}
              {result.noFreeManShift && ' - není volné místo, objednávku lze navýšit'}
              {result.error && ` - chyba - ${result.error}`}
            </li>
          ))}
        </ul>

        <div className="flex gap-x-1 justify-end items-end">
          <button
            type="button"
            className="ty-button-secondary"
            onClick={() => { setShowResultsModal(false); onFinished(); }}
          >
            Zavřít
          </button>
          {resultList.some((result) => result.noFreeManShift) && (
            <SubmitButton
              className="ty-button-primary mt-5"
              buttontext="Navýšit"
              onClick={addManShift}
            />
          )}
        </div>
      </ModalShell>
      <ModalShell showModal={showErrorModal} onClose={() => setShowErrorModal(false)}>
        <div className="flex flex-col gap-10 justify-between items-center">
          <h2> Tyto uživatele není možné pozvat </h2>
          <ul>
            {errorModalMessages?.map((msg) => <li key={msg}> {msg} </li>)}
          </ul>
          <div className="self-end">
            <button
              type="button"
              className="ty-button-secondary"
              onClick={() => setShowErrorModal(false)}
            >
              Zavřít
            </button>
          </div>
        </div>
      </ModalShell>
    </>
  );
};

export default InvitationsCountColumn;
