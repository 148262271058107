import { PositionData } from '@tymbe/schema/position.interface';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import EditPositionModal from './components/EditPositionModal';
import feathersClient from '../../../apiClient';
import AdminTable from '../../../components/AdminTable';
import { PrimaryButton } from '../../../components/buttons';
import Button from '../../../components/buttons/Button';
import { EditIcon, TrashIcon } from '../../../components/icons';
import AlertModal from '../../../components/modals/AlertModal';
import { PageTitle } from '../../../components/texts';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import useURLParamsHandler from '../../../hooks/UrlParamsHandler/useURLParamsHandler';
import Spinner from '../../../utils/spinner';

const AdminAdministrationPositionsPage = () => {
  const pageTitle = 'Pozice';
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const [currentPage, setCurentPage] = useState(allSearchParams.page || 1);
  const [pageSize, setPageSize] = useState(allSearchParams.pageSize || 10);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pickedPosition, setPickedPosition] = useState<Partial<PositionData>>();

  const queryClient = useQueryClient();

  const { mutateAsync: removePosition } = useMutation(
    ['removePosition'],
    async (positionId: number): Promise<PositionData> =>
      feathersClient.service('position').remove(positionId),
  );

  const positions = useQuery(['admin/positions', pageSize, currentPage], () =>
    feathersClient.service('position').find({
      query: {
        $skip: pageSize * (currentPage - 1),
        $limit: pageSize,
        $sort: { name: 1 },
      },
    }));

  const onPaginationChange = (page: number, newPageSize: number) => {
    setUrlParamsHandler({ page, pageSize: newPageSize });
    setCurentPage(page);
    setPageSize(newPageSize);
  };

  const onEditPosition = (position: PositionData) => {
    setPickedPosition(position);
    setShowModal(true);
  };

  const onRemovePosition = (position: PositionData) => {
    setShowDeleteModal(true);
    setPickedPosition(position);
  };

  const onSetShowModal = (value: boolean) => {
    if (!value) setPickedPosition(undefined);
    setShowModal(value);
  };

  const onSetShowDeleteModal = (value: boolean) => {
    if (!value) setPickedPosition(undefined);
    setShowDeleteModal(value);
  };

  const columns = [
    {
      title: 'Název pozice',
      dataIndex: 'name',
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 80,
      render: (_: number, row: PositionData) => (
        <div className="flex justify-between ">
          <Button
            onClick={() => onEditPosition(row)}
            className="border-0 bg-primary"
          >
            <EditIcon />
          </Button>
          <Button
            onClick={() => onRemovePosition(row)}
            className="border-error-500"
          >
            <TrashIcon />
          </Button>
        </div>
      ),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ];

  return (
    <Wrapper padding="0px" margin="0px 22px 18px 31px">
      {positions.isLoading ? (
        <Spinner />
      ) : (
        <Container
          iconcolor="#B3CA1F"
          background="#fff"
          elevate
          contentstyle={{ paddingLeft: '170px' }}
          desktopHeader
          sidebar
          sidebarExtra
        >
          <div className="flex justify-between align-center">
            <PageTitle>{pageTitle}</PageTitle>
            <PrimaryButton
              buttontext="Nová pozice"
              onClick={() => setShowModal(true)}
            />
          </div>
          <EditPositionModal
            showModal={showModal}
            setShowModal={onSetShowModal}
            position={pickedPosition}
          />
          <AdminTable
            rowKey={(position: PositionData) => position.id}
            columns={columns}
            data={positions.data}
            isLoading={positions.isLoading}
            onPaginationChange={onPaginationChange}
            pageSizeOptions={[10, 20, 50, 100]}
            currentPage={currentPage}
          />
        </Container>
      )}
      <AlertModal
        title="Smazání pozice"
        message={`Opravdu si přejete pozici ${
          pickedPosition?.name ?? ''
        } smazat?`}
        yesButtonText="Ano"
        noButtonText="Ne"
        showCancel={false}
        onClose={() => onSetShowDeleteModal(false)}
        onYes={async () => {
          if (!pickedPosition?.id) { throw new Error('Chyba pri pokusu o zmazání'); }
          await removePosition(pickedPosition.id);
          onSetShowDeleteModal(false);
          queryClient.invalidateQueries(['admin/positions']);
        }}
        onNo={() => onSetShowDeleteModal(false)}
        showModal={showDeleteModal}
      />
    </Wrapper>
  );
};

export default AdminAdministrationPositionsPage;
