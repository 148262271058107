import isArray from 'lodash.isarray';
import moment, { Moment } from 'moment';
import { Options } from 'react-select';

import { LabelsValues } from './enums';
import { isPerkData } from '../../../types/TymbeApi';
import { getPerkNameById } from '../../../utils';

const removeItem = <A extends { value: unknown, id: unknown }>([...arr]: A[], value: unknown) => {
  const arrIndex = arr.findIndex((v) => v.value === value || v.id === value);

  if (arrIndex < 0) return arr;

  const newArray = arr.slice();
  newArray.splice(arrIndex, 1);
  return newArray;
};

interface TransformedData {
  label: string,
  value: string | Moment,
  id?: number | string,
  removeName: string,
  removeValue: string | Option[],
}

export type Option = { label: string, value: string; };

export type FormValues = Record<string, Options<Option> | Option | Moment | boolean | number | string | undefined>;

const transform = (formValues: FormValues, labelsValues: LabelsValues) => {
  let transformedData: TransformedData[] = [];

  Object.entries(formValues).forEach(([formKey, formValue]) => {
    if (['orderType', 'accountState', 'gender', 'userType'].includes(formKey) && typeof formValue === 'string') {
      transformedData = [
        ...transformedData,
        {
          label: labelsValues[formKey].label,
          value: (formValue) ? labelsValues[formValue].label : '',
          removeName: formKey,
          removeValue: '',
        },
      ];
    } else if (formKey === 'requirements' && isArray(formValue)) {
      formValue.forEach((option) => {
        transformedData = [
          ...transformedData,
          {
            label: labelsValues[formKey].label,
            value: (isPerkData(option)) ? getPerkNameById(option.id) : option.name,
            id: option.id,
            removeName: formKey,
            removeValue: removeItem(formValue, option.id),
          },
        ];
      });
    } else if (isArray(formValue)) {
      if (formValue.length > 0 && formValue[0].label) {
        formValue.forEach((option) => {
          transformedData = [
            ...transformedData,
            {
              label: labelsValues[formKey].label,
              value: option.label,
              id: option.value,
              removeName: formKey,
              removeValue: removeItem(formValue, option.value),
            },
          ];
        });
      }
      // time date picker or single select
    } else {
      let val;
      // time date picker
      if (moment.isMoment(formValue)) {
        val = formValue.format(labelsValues[formKey].format);
      } else if (
        [
          'internalName',
        ].includes(formKey)
        && formValue
        && typeof formValue === 'string'

      ) {
        val = formValue;
      } else if (
        [
          'ageFrom',
          'ageTo',
          'applicationPriceFrom',
          'applicationPriceTo',
          'applicationCreditsFrom',
          'applicationCreditsTo',
          'score',
        ].includes(formKey)
        && formValue
        && typeof formValue === 'object'
        && Object.hasOwn(formValue, 'value')
      ) {
        val = formValue.value;
      } else if (
        [
          'applicationState',
        ].includes(formKey)
        && formValue
        && typeof formValue === 'object'
        && Object.hasOwn(formValue, 'value')
      ) {
        val = formValue.label;
      } else {
        val = formValue ? 'Ano' : 'Ne';
      }

      transformedData = [
        ...transformedData,
        {
          label: labelsValues[formKey].label,
          value: (formValue) ? val : '',
          removeName: labelsValues[formKey].name,
          removeValue: '',
        },
      ];
    }
  });
  return transformedData;
};

export default transform;
