import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const loadPositionThunk = createAsyncThunk('company/position', async (query) => feathersClient.service('position').find({
  query: {
    ...query,
  },
}));

const loadPositionReducer = {
  [loadPositionThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadPositionThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: [],
  }),
  [loadPositionThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const positionSlice = createSlice({
  name: 'positionContainer',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    ...loadPositionReducer,
  },
});

export const selectPositionData = createSelector(
  (state) => state.views.companyPosition,
  (companyPositionSlice) => companyPositionSlice.data
  ,
);
export default positionSlice.reducer;
export const { actions } = positionSlice;
