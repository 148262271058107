import moment from 'moment';

export const paramsToQuerySwitch = (paramType: string, paramValue: string) => {
  switch (paramType) {
    case 'email':
    case 'phone':
    case 'address':
    case 'companyBlock':
    case 'socialSecurityNumber':
    case 'bankAccount':
    case 'nationality':
    case 'dateRange':
    case 'company':
    case 'branchoffice':
    case 'applicationState':
    case 'orderId':
    case 'applicationId':
    case 'position':
    case 'attendanceId':
    case 'applicationPriceFrom':
    case 'applicationPriceTo':
    case 'applicationCreditsFrom':
    case 'applicationCreditsTo':
    case 'internalName':
    case 'showSystemCancelled':
    case 'score':
    case 'onlyValidDocuments':
    case 'tymber':
    case 'department':
    case 'month':
    case 'documentTypes':
    case 'showPublished':
    case 'showUnpublished':
    case 'onlyUnresolved':
    case 'sort':
      return JSON.parse(paramValue);
    case 'ageFrom':
    case 'ageTo':
      return { label: paramValue, value: Number(paramValue) };
    case 'registrationDateFrom':
    case 'shiftDateFrom':
      return moment(paramValue).startOf('day');
    case 'registrationDateTo':
    case 'shiftDateTo':
      return moment(paramValue).endOf('day');
    case 'shiftTimeFrom':
    case 'shiftTimeTo':
      return moment(paramValue).startOf('minutes');
    case 'dateYearFormat':
      return moment(paramValue);
    case 'dateMonthFormat':
      return moment(paramValue);
    case 'page':
    case 'pageSize':
      return Number(paramValue);
    default:
      return paramValue;
  }
};

export const queryToParamsSwitch = (
  paramType: string,
  paramValue: any,
) => {
  switch (paramType) {
    case 'email':
    case 'phone':
    case 'address':
    case 'companyBlock':
    case 'socialSecurityNumber':
    case 'bankAccount':
    case 'nationality':
    case 'dateRange':
    case 'company':
    case 'branchoffice':
    case 'applicationState':
    case 'orderId':
    case 'applicationId':
    case 'position':
    case 'attendanceId':
    case 'applicationPriceFrom':
    case 'applicationPriceTo':
    case 'applicationCreditsFrom':
    case 'applicationCreditsTo':
    case 'internalName':
    case 'showSystemCancelled':
    case 'score':
    case 'onlyValidDocuments':
    case 'tymber':
    case 'department':
    case 'month':
    case 'documentTypes':
    case 'showPublished':
    case 'showUnpublished':
    case 'onlyUnresolved':
    case 'sort':
      return JSON.stringify(paramValue);
    case 'ageFrom':
    case 'ageTo':
      return paramValue.value;
    case 'registrationDateFrom':
    case 'shiftDateFrom':
      return moment(paramValue).startOf('day').toISOString();
    case 'registrationDateTo':
    case 'shiftDateTo':
      return moment(paramValue).endOf('day').toISOString();
    case 'shiftTimeFrom':
    case 'shiftTimeTo':
      return moment(paramValue).startOf('minutes').toISOString();
    case 'dateYearFormat':
      return paramValue.year();
    case 'dateMonthFormat':
      return paramValue.month() + 1;
    default:
      return paramValue.toString();
  }
};
