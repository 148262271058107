// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore says it doesn't export Forbidden, GeneralError and BadRequest but works correctly
import { FeathersError, Forbidden, GeneralError, BadRequest } from '@feathersjs/errors';
import { UtilityData } from '@tymbe/schema/utility.interface';
import classNames from 'classnames';
import { Form, FormState, Scope, useFormState } from 'informed';
import moment, { Moment } from 'moment';
import { ComponentProps, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';

import CalendarImage from './CalendarImage';
import ShiftCreateForm, { ShiftCreateFormValues } from './ShiftCreateForm';
import ShiftCreatePersonInvite from './ShiftCreatePersonInvite';
import ShiftPublishButton from './ShiftPublishButton';
import ShiftRow from './ShiftRow';
import { formatShiftText, ShiftsToAdd } from './utils';
import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import {
  ApplicationData,
  ManShiftData,
  hasTableProp,
  isDocumentTypeData,
  isPerkData, CompanyData, ShiftData, PerkData, BranchofficeData, DocumentTypeData, PersonData,
} from '../../../../types/TymbeApi';
import CrossIcon from '../icons/CrossIcon';
import InviteToAllIcon from '../icons/InviteToAllIcon';

type FormStateCallerProps<Field extends object, P extends keyof FormState<Field>> = {
  onChange: (value: FormState<Field>[P]) => void,
  state: P,
};
const FormStateCaller = <Field extends object, P extends keyof FormState<Field>>
({ onChange, state }: FormStateCallerProps<Field, P>) => {
  const formState = useFormState<Field>();
  const requestedState = formState[state];

  useEffect(() => {
    onChange(requestedState);
  }, [onChange, requestedState, state]);

  return null;
};

type ShiftCreateOwnProps = {
  company: CompanyData;
  branchoffice: BranchofficeData;
  onPublish?: () => void;
  onCancel?: () => void;
};

type ShiftCreateProps = ShiftCreateOwnProps & Omit<ComponentProps<'div'>, keyof ShiftCreateOwnProps>;

const mapToShiftData = (
  values: Partial<ShiftsToAdd>,
  company: CompanyData,
  branchoffice: BranchofficeData,
) => {
  let start_time;
  let end_time;
  if (values.startTime && values.endTime) {
    start_time = moment(`${values.date?.format('DD.MM.YYYY')} ${values.startTime}`, 'DD.MM.YYYY hh:mm');
    end_time = moment(`${values.date?.format('DD.MM.YYYY')} ${values.endTime}`, 'DD.MM.YYYY hh:mm');

    if (end_time <= start_time) {
      end_time.add(1, 'day');
    }
  }

  let documentTypes: DocumentTypeData[] = [];
  let perks: PerkData[] = [];
  let utility: UtilityData[] = [];

  let quantity = values.spaces || 0;
  const invitation_list = values.invitations || [];

  if (invitation_list.length > quantity) {
    quantity = invitation_list.length;
  }

  const manShiftCount = Number.isNaN(Number(quantity)) ? 0 : Number(quantity);
  const newDocTypes = values.requirements?.filter(isDocumentTypeData) as DocumentTypeData[] | undefined || [];
  const newPerks = values.requirements?.filter(isPerkData) || [];

  documentTypes = [
    ...(values.shiftTemplate?.documentType || []),
    ...newDocTypes,
  ];
  perks = [...(values.shiftTemplate?.perk || []), ...newPerks];
  utility = [...(values.utilities || [])];

  return {
    company_id: company.id,
    shift_template: values.shiftTemplate,
    shift_template_id: values.shiftTemplate?.id,
    perk: perks,
    utility,
    documentType: documentTypes,
    job_classification_ids: values.shiftTemplate?.job_classification_ids,
    billing_rate: Number(values.shiftTemplate?.billing_rate) || 0,
    margin: Number(values.shiftTemplate?.margin) || 0,
    branchoffice_id: branchoffice.id,
    position_id: values.shiftTemplate?.position_id,
    contact_person: values.emergencyContact,
    name: values.shiftTemplate?.name || values.shiftTemplate?.template_name,
    manShift: new Array(manShiftCount).fill({}),
    payment_base: Number(values.shiftTemplate?.payment_base),
    credits_min: Number(values.shiftTemplate?.credits_min),
    credits: Number(values.shiftTemplate?.credits),
    invitation_credits: Number(values.shiftTemplate?.invitation_credits),
    start_time: start_time ? start_time.toISOString() : undefined,
    end_time: end_time ? end_time.toISOString() : undefined,
    contest_start: moment().toISOString(),
    contest_end: moment(start_time).subtract(1, 'hour').toISOString(),
    description: values.shiftTemplate?.description,
    instruction: values.shiftTemplate?.instruction,
    instruction_newcomers: values.shiftTemplate?.instruction_newcomers,
    pay_supplement: values.shiftTemplate?.pay_supplement,
    expected_hpp_weekly_hours: values.shiftTemplate?.expected_hpp_weekly_hours,
    maximum_hpp_trial_period: values.shiftTemplate?.maximum_hpp_trial_period,
    labels: values.shiftTemplate?.labels,
  };
};

const ShiftCreate = ({ company, branchoffice, onPublish, onCancel }: ShiftCreateProps) => {
  const queryClient = useQueryClient();
  const [showInvite, setShowInvite] = useState(false);
  const [shifts, setShifts] = useState<ShiftsToAdd[]>([]);
  const [personToInvite, setPersonToInvite] = useState<PersonData[]>([]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [error, setError] = useState<{ msg: string, err: string } | undefined>();
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);

  const checkIfValid = (valid: FormState<object>['valid']) => {
    setFormIsValid(valid);
  };

  const displayError = (err: unknown, where: string) => {
    if (err instanceof Forbidden) {
      setError({
        msg: `Chyba při autentikaci - ${where}`,
        err: JSON.stringify(err, null, 2),
      });
    } else if (err instanceof BadRequest) {
      setError({
        msg: `Chyba v dotazu - ${where}`,
        err: JSON.stringify(err, null, 2),
      });
    } else if (err instanceof FeathersError) {
      setError({
        msg: `Nečekaná chyba - ${where}`,
        err: JSON.stringify(err, null, 2),
      });
    } else if (err instanceof GeneralError) {
      setError({
        msg: `Chybějíci data - ${where}`,
        err: JSON.stringify(err, null, 2),
      });
    }
  };

  const { mutateAsync: createShifts } = useMutation(
    ['delete-shift'],
    async (shiftsToCreate: Partial<ShiftData>[]) => feathersClient.service('shift').create(shiftsToCreate, { query: { $eager: 'manShift' } }),
    {
      onSuccess: (createdShifts) => {
        SuccessAlert(formatShiftText(createdShifts.length));
      },
      onError: (err) => {
        displayError(err, 'vytváření směn');
      },
    },
  );

  const { mutateAsync: createApplications } = useMutation(
    ['delete-shift'],
    async (applicationsToCreate: Partial<ApplicationData>[]) => feathersClient.service('application').create(applicationsToCreate),
    {
      onSuccess: () => {
        SuccessAlert('Pozvánky vytvořeny.');
      },
      onError: (err) => {
        displayError(err, 'vytváření pozvánek.');
      },
    },
  );

  const onAddShifts = (values: ShiftCreateFormValues) => {
    const shiftsToAdd: ShiftsToAdd[] = values.dates.map((date) => ({
      date,
      startTime: values.startTime,
      endTime: values.endTime,
      spaces: values.spaces,
      requirements: values.requirements,
      utilities: values.utilities,
      shiftTemplate: values.shiftTemplate,
      emergencyContact: values.emergencyContact,
      invitations: [],
    }));
    setShifts((prev) => [...prev, ...shiftsToAdd]);
    setShowInvite(true);
  };

  const removeShift = (index: number) => {
    setShifts((prev) => prev.filter((_, i) => index !== i));
  };

  const setShiftSpaces = (index: number, spaces: number) => {
    if (Number.isNaN(spaces)) return;
    const shiftsCopy = [...shifts];
    shiftsCopy[index] = { ...shiftsCopy[index], spaces };
    setShifts(shiftsCopy);
  };

  const setShiftInvitations = (index: number, invitations: PersonData[]) => {
    const shiftsCopy = [...shifts];
    shiftsCopy[index] = { ...shiftsCopy[index], invitations };
    invitations.forEach((person) => {
      queryClient.setQueryData(['person', person.id], person);
    });
    setShifts(shiftsCopy);
  };

  const setAllShiftInvitations = (invitations: PersonData[]) => {
    if (!invitations.length) return;
    const shiftsCopy = [...shifts];
    shifts.forEach((_, index) => {
      shiftsCopy[index] = { ...shiftsCopy[index], invitations };
    });
    invitations.forEach((person) => {
      queryClient.setQueryData(['person', person.id], person);
    });
    setShifts(shiftsCopy);
  };

  const setShiftStartTime = (index: number, startTime: Moment) => {
    const shiftsCopy = [...shifts];
    shiftsCopy[index] = { ...shiftsCopy[index], startTime };
    setShifts(shiftsCopy);
  };

  const setShiftEndTime = (index: number, endTime: Moment) => {
    const shiftsCopy = [...shifts];
    shiftsCopy[index] = { ...shiftsCopy[index], endTime };
    setShifts(shiftsCopy);
  };

  const removePersonFromInvitations = (shiftIndex: number, inviteIndex: number) => {
    const shiftsCopy = [...shifts];
    const newInvitations = shiftsCopy[shiftIndex].invitations.filter((_, index) => index !== inviteIndex);
    shiftsCopy[shiftIndex] = { ...shiftsCopy[shiftIndex], invitations: newInvitations };
    setShifts(shiftsCopy);
  };

  const onPublishShifts = async () => {
    setError(undefined);
    try {
      setIsPublishing(true);
      // Map shifts without invitations and create shifts
      const shiftsWithoutInvitations = shifts.filter((sh) => sh.invitations.length === 0);
      if (shiftsWithoutInvitations.length) {
        const shiftsWithoutInvToPublish: Partial<ShiftData>[] =
          shiftsWithoutInvitations
            .map((sh) => {
              const { shift_template, ...mappedShift } = mapToShiftData(sh, company, branchoffice);
              const start_time = moment(`${sh.date.format('DD.MM.YYYY')} ${sh.startTime.format('HH:mm')}`, 'DD.MM.YYYY hh:mm');
              const end_time = moment(`${sh.date.format('DD.MM.YYYY')} ${sh.endTime.format('HH:mm')}`, 'DD.MM.YYYY hh:mm');

              if (end_time <= start_time) {
                end_time.add(1, 'day');
              }

              return {
                ...mappedShift,
                perk: mappedShift.perk,
                documentType: mappedShift.documentType.map((doc) => ({
                  id: doc.id,
                }) as DocumentTypeData),
                utility: mappedShift.utility.map((util) => ({
                  id: util.id,
                })) as UtilityData[],
                start_time: start_time.toISOString(),
                end_time: end_time.toISOString(),
                contest_start: moment().toISOString(),
                contest_end: start_time.subtract(1, 'hour').toISOString(),
              };
            });
        await createShifts(shiftsWithoutInvToPublish);
      }

      // Map shifts with invitations and create both shifts and applications
      const shiftsWithInvitations = shifts.filter((sh) => sh.invitations.length > 0);
      if (shiftsWithInvitations.length) {
        const shiftsWithInvToPublish: Partial<ShiftData>[] =
        shiftsWithInvitations
          .map((sh) => {
            const { shift_template, ...mappedShift } = mapToShiftData(sh, company, branchoffice);
            const start_time = moment(`${sh.date.format('DD.MM.YYYY')} ${sh.startTime.format('HH:mm')}`, 'DD.MM.YYYY hh:mm');
            const end_time = moment(`${sh.date.format('DD.MM.YYYY')} ${sh.endTime.format('HH:mm')}`, 'DD.MM.YYYY hh:mm');

            if (end_time <= start_time) {
              end_time.add(1, 'day');
            }

            return {
              ...mappedShift,
              perk: mappedShift.perk,
              documentType: mappedShift.documentType.map((doc) => ({
                id: doc.id,
              }) as DocumentTypeData),
              utility: mappedShift.utility.map((util) => ({
                id: util.id,
              })) as UtilityData[],
              start_time: start_time.toISOString(),
              end_time: end_time.toISOString(),
              contest_start: moment().toISOString(),
              contest_end: start_time.subtract(1, 'hour').toISOString(),
            };
          });
        const newShiftsWithInv = await createShifts(shiftsWithInvToPublish);

        const applications = shiftsWithInvitations.map((sh, i) => {
          const newShift = newShiftsWithInv[i];
          return sh.invitations.map((invitation, key) => ({
            credits: Number(newShift.invitation_credits),
            payment_base: Number(newShift.payment_base),
            invitation: true,
            state: null,
            person_id: invitation.id,
            man_shift_id: newShift.manShift?.[key].id,
            manShiftReverse: {
              id: newShift.manShift?.[key].id,
              shift_id: newShift.id,
            } as ManShiftData,
            employer_id: newShift.company_id,
          }));
        }).flat();
        await createApplications(applications);
      }
      setIsPublishing(false);
      onPublish?.();
    } catch (err: unknown) {
      setIsPublishing(false);
      if (!(err instanceof FeathersError) || !hasTableProp(err.data)) {
        ErrorAlert('Nastala chyba');
        throw err;
      }
      switch (err.data?.table || err.message) {
        case 'application': {
          ErrorAlert(
            'Směny byly úspěšně vytvořeny, nebylo možné vytvořit pozvánky',
          );
          break;
        }

        case 'shift':
          ErrorAlert('Nepovedlo se vytvořit směny');
          break;

        case 'ERROR/AUTH: Shift must be created in future':
          ErrorAlert('Směna musí být vytvořena v budoucnosti');
          break;

        case 'Salary limit exceeded':
          // eslint-disable-next-line no-case-declarations
          const errData = err.data as Record<string, unknown>;
          ErrorAlert(
            `Tymber ${errData.person_id} ${errData.person_full_name} by touto směnou překročil rozhodný příjem, avšak má zaplou kontrolu překročení, proto jej není možné pozvat.`,
          );
          break;

        default:
          ErrorAlert('Nastala chyba');
          break;
      }
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-1">
        <div className="bg-secondary-50 min-h-[568px] min-w-[336px]">
          <div className="flex gap-3 px-6 border-b border-secondary-200 h-12">
            <button
              type="button"
              className={
                classNames(
                  'h-12 !rounded-none px-0 py-3.5 font-medium text-[14px] leading-[20px]',
                  { 'border-b-2 border-secondary-600 text-secondary-600': !showInvite },
                  { 'text-secondary-400': showInvite },
                )
              }
              onClick={() => {
                setPersonToInvite([]);
                setShowInvite(false);
              }}
            >
              Vypsat směny
            </button>
            <button
              type="button"
              className={
                classNames(
                  'h-12 !rounded-none px-0 py-3.5 font-medium text-[14px] leading-[20px]',
                  { 'border-b-2 border-secondary-600 text-secondary-600': showInvite },
                  { 'text-secondary-400': !showInvite },
                  'disabled:text-secondary-400 disabled:cursor-not-allowed',
                )
              }
              onClick={() => setShowInvite(true)}
              disabled={!shifts.length}
            >
              Pozvat pracovníky
            </button>
          </div>
          {!showInvite ? (
            <Form onSubmit={({ values }: FormState<ShiftCreateFormValues>) => onAddShifts(values)}>
              <ShiftCreateForm
                companyId={company.id}
                branchOfficeId={branchoffice.id}
              />
            </Form>
          ) : (
            <Form>
              <ShiftCreatePersonInvite
                setPersonToInvite={setPersonToInvite}
                company={{
                  id: company.id,
                  branchoffice: branchoffice.parent?.id,
                  department: branchoffice.id,
                }}
              />
            </Form>
          )}
        </div>
        <div className="w-full p-6">
          {shifts.length ? (
            <>
              <div
                className="grid grid-cols-5 text-xs font-medium text-secondary-500 py-1 border-b border-secondary-100"
              >
                <div>Datum</div>
                <div>Čas</div>
                <div>Míst</div>
                <div>Pracovníci</div>
              </div>
              <div className="w-full">
                <Form onSubmit={() => onPublishShifts()}>
                  <FormStateCaller onChange={checkIfValid} state="valid" />
                  {shifts.map((shift, index) => (
                    <Scope scope={`shift${index}`} key={`shift${index + 1}`}>
                      <ShiftRow
                        shift={shift}
                        index={index}
                        setShiftStartTime={setShiftStartTime}
                        setShiftEndTime={setShiftEndTime}
                        setShiftInvitations={setShiftInvitations}
                        setShiftSpaces={setShiftSpaces}
                        personToInvite={personToInvite}
                        removeShift={removeShift}
                        removePersonFromInvitations={removePersonFromInvitations}
                        company={{
                          id: company.id,
                          branchoffice: branchoffice.parent?.id,
                          department: branchoffice.id,
                        }}
                      />
                    </Scope>
                  ))}
                </Form>
                <div className="w-full flex justify-end px-8 py-2">
                  <button
                    type="button"
                    className="text-xs text-secondary-400 font-semibold flex items-center gap-2"
                    onClick={() => {
                      setAllShiftInvitations(personToInvite);
                    }}
                  >
                    <InviteToAllIcon className="w-[18px]" />
                    Pozvat na vše
                  </button>
                </div>
              </div>
            </>

          ) : (
            <div className="flex flex-col justify-center items-center w-full h-full">
              <CalendarImage className="mb-[30px]" />
              <div className="font-medium text-[16px] leading-[24px] text-secondary-900">
                Vyberte více dní v kalendáři
              </div>
              <div className="text-sm text-secondary-500">Můžete tím přidat více směn současně</div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center gap-3 py-4 px-6 border-t border-secondary-100">
        <div className={
          classNames(
            'p-2 border border-error-500 rounded-lg bg-error-100 text-error font-medium flex gap-4',
            { invisible: !error },
          )
        }
        >
          {error?.msg}
          <button
            type="button"
            className="p-0 rounded-none underline hover:text-error-600"
            onClick={() => setOpenModal(true)}
          >
            Detail
          </button>
        </div>
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="py-2 px-4 border text-sm font-semibold border-secondary-300"
            onClick={() => {
              setShowInvite(false);
              setShifts([]);
              onCancel?.();
            }}
          >Zrušit
          </button>
          <ShiftPublishButton
            className=""
            shifts={shifts}
            isPublishing={isPublishing}
            onClick={() => onPublishShifts()}
            hasErrors={!formIsValid}
          />
        </div>
      </div>
      <Modal
        isOpen={openModal}
        style={{
          overlay: { zIndex: 2000, backgroundColor: 'rgba(17, 24, 39, 0.8)' },
          content: {
            width: '50%',
            height: '50%',
            transform: 'translateX(50%) translateY(35%)',
          },
        }}
      >
        <button
          aria-label="close button"
          onClick={() => setOpenModal(false)}
          type="button"
          className="absolute right-2 top-2 p-0 hover:text-error"
        ><CrossIcon />
        </button>
        <h2>Detail chyby</h2>
        <pre className="text-lg">
          {error?.err}
        </pre>
      </Modal>
    </div>
  );
};

export default ShiftCreate;
