import { Outlet } from 'react-router-dom';

import Wrapper from './index';
import Container from '../../containers';

const PageWrapper = () => (
  <Container
    iconcolor="#B3CA1F"
    background="#fff"
    elevate
    contentstyle={{ paddingLeft: '170px' }}
    desktopHeader
    sidebar
    sidebarExtra
  >
    <Wrapper padding="0px" margin="0px 22px 18px 31px">
      <Outlet />
    </Wrapper>
  </Container>
);

export default PageWrapper;
