import classNames from 'classnames';
import moment from 'moment-timezone';
import { Children, ReactElement, ReactNode } from 'react';

import TyCalWeekEvent from './TyCalWeekEvent';
import TyCalWeekGroupHead from './TyCalWeekGroupHead';
import TyCalWeekGroupTail from './TyCalWeekGroupTail';

type TyCalWeekGroupProps = {
  children?: ReactNode;
  flat?: boolean;
  className?: string;
  classNameDay?: string;
  noGrid?: boolean;
  // used by TyCalWeek
  // eslint-disable-next-line react/no-unused-prop-types
  position?: 'top' | 'bottom';
};

const TyCalWeekGroup = ({
  children,
  flat = false,
  noGrid = false,
  className,
  classNameDay,
}: TyCalWeekGroupProps) => {
  const childrenArray = Children.toArray(children);
  // separate TyCalWeekGroupHead and TyCalWeekGroupTail components
  const head = childrenArray.find((c) => c && (c as ReactElement).type === TyCalWeekGroupHead);
  const tail = childrenArray.find((c) => c && (c as ReactElement).type === TyCalWeekGroupTail);
  // get body children
  const body = childrenArray.filter((c) => ![head, tail].includes(c));

  if (flat) {
    return (
      <>
        {head}
        <div className={classNames('ty-cal-week-group', { 'ty-cal-week-no-grid': noGrid }, className)}>
          {[...Array(7)].map((_, i) => (
            <div
              // we do not have any better key here
              // eslint-disable-next-line react/no-array-index-key
              key={`grid-y-${i}`}
              className="ty-cal-week-grid-y"
              data-day={i + 1}
            />
          ))}
          {body}
        </div>
        {tail}
        <div className="ty-cal-week-grid-x" />
      </>
    );
  }

  const days = body.reduce((acc: (typeof childrenArray)[], elem) => {
    const props = (elem as ReactElement).props ?? {};
    if (!elem) {
      return acc;
    }

    let day = props.day || props['data-day'] || 1;

    if (props.date) {
      day = moment(props.date).get('weekday');
    }

    acc[day] = [...(acc[day] || []), elem];

    return acc;
  }, []);

  return (
    <>
      {head}
      <div className="ty-cal-week-group">
        {[...Array(7)].map((_, i) => (
          <div
            // we do not have any better key here
            // eslint-disable-next-line react/no-array-index-key
            key={`grid-y-${i}`}
            className="ty-cal-week-grid-y"
            data-day={i + 1}
          />
        ))}
        {days.map((day, i) => (
          // we do not have any better key here
          // eslint-disable-next-line react/no-array-index-key
          <div key={`day-${i}`} data-day={i + 1} className={classNameDay}>
            {day}
          </div>
        ))}
      </div>
      {tail}
      <div className="ty-cal-week-grid-x" />
    </>
  );
};

TyCalWeekGroup.Head = TyCalWeekGroupHead;
TyCalWeekGroup.Tail = TyCalWeekGroupTail;
TyCalWeekGroup.Event = TyCalWeekEvent;

export default TyCalWeekGroup;
