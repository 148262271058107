import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import { DangerButton } from '../../../components/buttons';
import { ResponsiveGrid } from '../../../components/grids';
import Illustration from '../../../components/illustration';
import { ResetActivationIllustration } from '../../../components/illustrations';
import { SimpleFormItem } from '../../../components/inputs';
import { SimpleHeading1Text, SmallText } from '../../../components/texts';
import { dangerColor, white } from '../../../utils/colors';

const ActivateAlertContainer = () => (
  <div>
    <SimpleHeading1Text> Je potřeba aktivovat učet</SimpleHeading1Text>

    <Illustration
      svg={<ResetActivationIllustration />}
      style={{ width: '165px', height: '149px' }}
    />
    <ResponsiveGrid>
      <SmallText>
        Při registraci jsme ti poslali na e-mail odkaz, kterým musíš svůj účet
        aktivovat, aby bylo možné přihlašovat se na brigády
      </SmallText>

      <SimpleFormItem>
        <Row gutter={[16, 24]} justify="space-around" align="middle">
          <Col
            style={{ textAlign: 'center', marginTop: '51px' }}
            xs={24}
            sm={24}
            md={8}
            lg={6}
            xl={6}
          >
            <Link to="/identity-validation">
              <DangerButton
                size="large"
                textstyle={{ color: white }}
                style={{ color: dangerColor, backgroundColor: dangerColor }}
                buttontext="Aktivovat učet"
              />
            </Link>
          </Col>
        </Row>
      </SimpleFormItem>
    </ResponsiveGrid>
  </div>
);

export default ActivateAlertContainer;
