import { SVGProps } from 'react';

type RemovePersonIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const RemovePersonIcon = (props: RemovePersonIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.5 8C13.5 5.79 11.71 4 9.5 4C7.29 4 5.5 5.79 5.5 8C5.5 10.21 7.29 12 9.5 12C11.71 12 13.5 10.21 13.5 8ZM1.5 18V19C1.5 19.55 1.95 20 2.5 20H16.5C17.05 20 17.5 19.55 17.5 19V18C17.5 15.34 12.17 14 9.5 14C6.83 14 1.5 15.34 1.5 18ZM17.5 10H21.5C22.05 10 22.5 10.45 22.5 11C22.5 11.55 22.05 12 21.5 12H17.5C16.95 12 16.5 11.55 16.5 11C16.5 10.45 16.95 10 17.5 10Z" />
  </svg>
);

export default RemovePersonIcon;
