import { ForeignerOrigin } from '@tymbe/schema/enums';

export const foreignerOriginTexts: Record<ForeignerOrigin, { official: string, unofficial?: string }> = {
  [ForeignerOrigin.EU_CITIZEN]: {
    official: 'Občan EU',
  },
  [ForeignerOrigin.EHP_CITIZEN]: {
    official: 'Občan EHP',
    unofficial: ' Island, Norsko, Lichtenštejnsko',
  },
  [ForeignerOrigin.SWITZERLAND_CITIZEN]: {
    official: 'Občan Švýcarska',
  },
  [ForeignerOrigin.GBR_CITIZEN]: {
    official: 'Občan GBR',
  },
  [ForeignerOrigin.EU_FAMILY_CITIZEN]: {
    official: 'Rodinný příslušník občana EU/EHP nebo Švýcarska',
  },
  [ForeignerOrigin.TEMPORARY_PROTECTION_HOLDER_UKRAINE]: {
    official: 'Držitel dočasné ochrany - Ukrajina',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_A]: {
    official: 'Cizinec podle § 98 písm. a)',
    unofficial: 'Cizinec s povoleným trvalým pobytem',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_B]: {
    official: 'Cizinec podle § 98 písm. b)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_C]: {
    official: 'Cizinec podle § 98 písm. c)',
    unofficial: 'Cizinec, kterému byl udělen azyl nebo doplňková ochrana',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_D]: {
    official: 'Cizinec podle § 98 písm. d)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_E]: {
    official: 'Cizinec podle § 98 písm. e)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_F]: {
    official: 'Cizinec podle § 98 písm. f)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_G]: {
    official: 'Cizinec podle § 98 písm. g)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_H]: {
    official: 'Cizinec podle § 98 písm. h)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_I]: {
    official: 'Cizinec podle § 98 písm. i)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_J]: {
    official: 'Cizinec podle § 98 písm. j)',
    unofficial: 'Cizinec, který se na území České republiky soustavně připravuje na budoucí povolání',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_K]: {
    official: 'Cizinec podle § 98 písm. k)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_L]: {
    official: 'Cizinec podle § 98 písm. l)',
    unofficial: 'Cizinec, který na území České republiky pobývá na základě povolení k dlouhodobému pobytu za účelem společného soužití rodiny, jde-li o společné soužití rodiny s cizincem podle písmene a) nebo c) nebo s cizincem, který na území České republiky pobývá na základě platného povolení k dlouhodobému pobytu.',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_M]: {
    official: 'Cizinec podle § 98 písm. m)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_N]: {
    official: 'Cizinec podle § 98 písm. n)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_O]: {
    official: 'Cizinec podle § 98 písm. o)',
    unofficial: 'Cizinec, který získal střední nebo vyšší odborné vzdělání nebo vyšší odborné vzdělání v konzervatoři podle školského zákona nebo vysokoškolské vzdělání podle zákona o vysokých školách.',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_P]: {
    official: 'Cizinec podle § 98 písm. p)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_Q]: {
    official: 'Cizinec podle § 98 písm. q)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_R]: {
    official: 'Cizinec podle § 98 písm. r)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_LETTER_S]: {
    official: 'Cizinec podle § 98 písm. s)',
  },
  [ForeignerOrigin.FOREIGNER_BY_98_A]: {
    official: 'Cizinec podle § 98a',
  },
};

export function getForeignerOriginText(foreignerOrigin: ForeignerOrigin) {
  const { official, unofficial } = foreignerOriginTexts[foreignerOrigin];
  return unofficial ?? official;
}
