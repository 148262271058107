import moment from 'moment';
import useCollapse from 'react-collapsed';

import { MinusOutlinedIcon, PlusOutlinedIcon } from '../../../components/icons';
import UserLink from '../../../components/links/UserLink';
import { TableCell, TableRow } from '../../../components/Table';
import { ApplicationData } from '../../../types/TymbeApi';

const ValueWithLabel = ({ title, value }: { title: string, value: string | number | undefined | null }) => (
  <div className="d-flex mb-10">
    <div className="font-semibold text-sm pr-2">{title}</div>
    <div className="font-normal text-fg-600 text-sm flex items-center text-right">{value || '-'}</div>
  </div>
);

const ExpandableShiftRow = ({ application }: { application: ApplicationData }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  let branchofficeParent = application.shift?.branchoffice;
  while (branchofficeParent?.parent) {
    branchofficeParent = branchofficeParent.parent;
  }

  const cols =
    [
      <div {...getToggleProps()}>{isExpanded ? <MinusOutlinedIcon /> : <PlusOutlinedIcon />}</div>,
      application.id,
      application.shift?.company?.name || '',
      branchofficeParent?.name || '',
      application.shift?.name || '',
      `${moment(application.shift?.start_time).format('DD. MM. YYYY HH:mm')}-${moment(application.shift?.end_time).format('HH:mm')}`,
      application.person ? <UserLink person={application.person} /> : '',
      application.state || '',
    ];

  return (
    <>
      <TableRow cols={cols} />

      {isExpanded && (
        <TableRow>
          <TableCell className="p-0" colSpan={cols.length}>
            <div {...getCollapseProps()}>
              <div className="flex flex-row">
                <div className="flex-col m-2 ml-12">
                  <ValueWithLabel title="Oddělení" value={application?.shift?.branchoffice?.name} />
                  <ValueWithLabel title="Interní označení" value={application?.shift?.external_id} />
                </div>
                <div className="flex-col m-2 ml-12">
                  <ValueWithLabel
                    title="Základ"
                    value={application?.payment_base && (+application.payment_base).toFixed(2)}
                  />
                  <ValueWithLabel title="Kredity" value={application?.credits && (+application.credits).toFixed(2)} />
                </div>
                <div className="flex-col m-2 ml-12">
                  <ValueWithLabel title="Pozvánka" value={application?.invitation ? 'Ano' : 'Ne'} />
                  <ValueWithLabel
                    title="Čas vytvoření"
                    value={moment(application.created_at).format('DD. MM. YYYY HH:mm')}
                  />
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ExpandableShiftRow;
