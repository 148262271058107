import classNames from 'classnames';
import moment, { MomentInput } from 'moment-timezone';
import {
  Children,
  ComponentType,
  HTMLAttributes,
  ReactComponentElement,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';

import TyCalDayOfWeek from './TyCalDayOfWeek';
import TyCalWeekEvent from './TyCalWeekEvent';
import TyCalWeekGroup from './TyCalWeekGroup';
import TyCalWeekHead from './TyCalWeekHead';
import TyCalWeekHeader from './TyCalWeekHeader';
import TyCalWeekTail from './TyCalWeekTail';
import './styles.css';
import TyCalWeekContext, { TyCalWeekContextType } from './TyCalWeekContext';

interface TyCalWeekProps extends HTMLAttributes<HTMLDivElement> {
  date: MomentInput;
  start?: 'monday' | 'sunday';
  headComp?: ComponentType<{ date: string; day: number }>;
  groupPosition?: 'top' | 'bottom';
  children?: ReactNode;
  classNameDay?: string;
}

const TyCalWeek = ({
  date = Date.now(),
  start,
  headComp: HeadComp = TyCalDayOfWeek,
  groupPosition = 'bottom',
  children,
  classNameDay,
  className,
  ...rest
}: TyCalWeekProps) => {
  const m = moment(date);

  // normalize children to array
  const childrenArray = Children.toArray(children);
  // separate TyCalWeekHeadLeft and TyCalWeekHeadRight components
  const headLeft = childrenArray.find(
    (c): c is ReactComponentElement<typeof TyCalWeekHead> => !!c && (c as ReactElement).type === TyCalWeekHead,
  );
  const headRight = childrenArray.find(
    (c): c is ReactComponentElement<typeof TyCalWeekTail> => !!c && (c as ReactElement).type === TyCalWeekTail,
  );

  // separate TyCalWeekGroup components
  const groups = childrenArray.filter(
    (c): c is ReactComponentElement<typeof TyCalWeekGroup> => !!c && (c as ReactElement).type === TyCalWeekGroup,
  );
  // separate TyCalWeekEvent components
  const events = childrenArray.filter(
    (c): c is ReactComponentElement<typeof TyCalWeekEvent> => !!c && (c as ReactElement).type === TyCalWeekEvent,
  );

  const eventsByGroup = events.reduce(
    (acc, event) => {
      if (!event.props.date) return acc;
      const group = moment(event.props.date).format('HH:00');
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(event);
      return acc;
    },
    {} as Record<string, ReactComponentElement<typeof TyCalWeekEvent>[]>,
  );

  const eventData = events.map((event) => ({
    date: event.props.date || '',
    data: event.props.data,
  }));

  const startDate = m.clone();
  const startDay = start
    ? startDate.set({
      weekday: start === 'monday' ? 0 : -1,
    })
    : startDate;

  const calContext: TyCalWeekContextType = useMemo(() => ({ startDate: startDay.toISOString(), eventData }), [
    startDay,
    eventData,
  ]);

  return (
    <TyCalWeekContext.Provider value={calContext}>
      <div className={classNames('ty-cal-week', className)} {...rest}>
        <div className="ty-cal-week-top" />
        <TyCalWeekGroup flat noGrid>
          {headLeft}
          {headRight}
          <TyCalWeekHeader headComp={HeadComp} />
        </TyCalWeekGroup>
        {groups.filter((group) => group.props.position === 'top')}
        {groupPosition === 'top' && groups.filter((group) => !group.props.position)}

        {Object.keys(eventsByGroup)
          .sort()
          .map((group) => (
            <TyCalWeekGroup key={group} classNameDay={classNameDay}>
              <TyCalWeekGroup.Head>{group}</TyCalWeekGroup.Head>
              {eventsByGroup[group]}
            </TyCalWeekGroup>
          ))}

        {groupPosition === 'bottom' && groups.filter((group) => !group.props.position)}
        {groups.filter((group) => group.props.position === 'bottom')}
      </div>
    </TyCalWeekContext.Provider>
  );
};

TyCalWeek.Head = TyCalWeekHead;
TyCalWeek.Tail = TyCalWeekTail;
TyCalWeek.Group = TyCalWeekGroup;
TyCalWeek.Event = TyCalWeekGroup.Event;
TyCalWeek.Header = TyCalWeekHeader;

export default TyCalWeek;
