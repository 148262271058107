import Protect from '../../apiClient/Protect';
import { Roles } from '../../utils/enums';
import { ProtectedRouteProps } from '../protectedRoute';

const AdminRoute = ({ children }: ProtectedRouteProps) => (
  <Protect auth={
    [
      Roles.SUPER_ADMIN,
      Roles.TYMBE_ADMIN,
      Roles.ADMIN,
      Roles.TYMBE_COORDINATOR,
    ]
  }
  >{children}
  </Protect>
);

export default AdminRoute;
