export default (fileName: string) =>
  fileName
    // Transforms chars with accents to separate chars: á -> a + ', š -> s + ˇ
    .normalize('NFKD')
    // Removes diacritic chars 'ˇ"° (replaces them with empty string)
    .replace(/\p{Diacritic}/gu, '')
    // Replaces non alphanumeric symbols with _ (underscore)
    .replace(/\W/g, '_')
    // Replaces multiple _ (underscore) with only single one
    .replace(/(_)+/g, '_')
    .toLowerCase();
