import { ReactNode } from 'react';

import { primaryColor } from '../../../utils/colors';

interface TableColumnWithControlsProps {
  children: ReactNode,
  showControls: boolean,
  onPlusClick?: () => void,
  onMinusClick?: () => void,
}

/*
* Custom Antd component
*/
const TableColumnWithControls = ({
  children,
  showControls,
  onPlusClick,
  onMinusClick,
}: TableColumnWithControlsProps) => (
  <div
    className="ty-table-cell-hoverable flex justify-between"
  >
    {children}
    {
      showControls && (
        <div className="ty-table-cell-controls">
          {onPlusClick ? (
            <button
              type="button"
              className="ty-button-pill"
              onClick={onPlusClick}
              style={{ backgroundColor: primaryColor }}
            >
              +
            </button>
          ) : null }
          {onMinusClick ? (
            <button
              type="button"
              className="ty-button-pill"
              onClick={onMinusClick}
              style={{ backgroundColor: primaryColor }}
            >
              -
            </button>
          ) : null }
        </div>
      )
    }
  </div>
);

export default TableColumnWithControls;
