import { SVGProps } from 'react';

type CheckIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const CheckIcon = (props: CheckIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.79457 15.8749L5.32457 12.4049C4.93457 12.0149 4.30457 12.0149 3.91457 12.4049C3.52457 12.7949 3.52457 13.4249 3.91457 13.8149L8.09457 17.9949C8.48457 18.3849 9.11457 18.3849 9.50457 17.9949L20.0846 7.4149C20.4746 7.0249 20.4746 6.3949 20.0846 6.0049C19.6946 5.6149 19.0646 5.6149 18.6746 6.0049L8.79457 15.8749Z" />
  </svg>
);

export default CheckIcon;
