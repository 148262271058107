import type { Paginated } from '@feathersjs/feathers';

import { ErrorAlert } from '../components/alerts';

export const createPaginatedResponse = <T = never>(data?: T[]) => ({
  total: data?.length ?? 0,
  data: data ?? [],
  skip: 0,
  limit: data?.length ?? 20,
});

export const getPaginatedResponse = <T = never>(response: T | T[] | Paginated<T> | undefined): Paginated<T> => {
  if (!response) {
    return createPaginatedResponse();
  }
  if (Array.isArray(response)) {
    return createPaginatedResponse(response);
  }
  if (Object.hasOwn(response, 'data')) {
    return response as Paginated<T>;
  }
  return createPaginatedResponse([response]);
};

export const defaultOnError = (err: unknown) => {
  if (err instanceof Error) {
    ErrorAlert(err.message, 15);
  } else {
    ErrorAlert('Neznámá chyba');
  }
};
