import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';

import { useUser } from '../../../../../apiClient/ApiContext';
import TyInput from '../../../../../components/inputs/TyInput';
import TyMarkdownEditor from '../../../../../components/inputs/TyMarkdownEditor';
import TySelectBranchoffice from '../../../../../components/inputs/TySelectBranchoffice';
import { CompanyData } from '../../../../../types/TymbeApi';
import { Roles } from '../../../../../utils/enums';
import CompanyContactPersonForm, { CompanyContactPersonFormValues } from '../../../components/CompanyContactPersonForm';

type DepartmentFormProps = {
  department?: BranchofficeData;
  company: CompanyData;
};

export type DepartmentFormValues = BranchofficeData & CompanyContactPersonFormValues;

const DepartmentForm = ({ company, department = undefined }: DepartmentFormProps) => {
  const isSuperadmin = useUser().hasRoles([
    Roles.SUPER_ADMIN,
  ]);

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <h3>Základní údaje</h3>
        <TySelectBranchoffice
          name="parent"
          companyId={company.id}
          label="Vyberte nadřazenou provozovnu"
          type="branchoffice"
        />
        <TyInput
          name="name"
          label="Název"
          required
          disabled={!isSuperadmin && company?.is_readonly}
        />
        <TyInput
          name="display_name"
          label="Alias"
        />
        <TyMarkdownEditor
          name="instruction"
          label="Pokyny"
          initialValue={department?.instruction}
        />
      </div>
      <CompanyContactPersonForm company={company} initialContactPerson={department?.contactPerson} />
    </div>
  );
};

export default DepartmentForm;
