import moment from 'moment';
import React from 'react';

export interface ShiftInfoDetailComponentProps {
  children?: React.ReactElement | React.ReactElement[] | null;
  detailComponent?: JSX.Element | null;
  label: string;
  lastUpdate?: string;
  createdAt?: string;
  createdBy?: string;
}

const ShiftInfoDetailComponent = (
  {
    children = undefined,
    detailComponent = undefined,
    label,
    lastUpdate = undefined,
    createdAt = undefined,
    createdBy = undefined,
  }: ShiftInfoDetailComponentProps,
) => (
  <div className="p-4">
    <div className="flex">
      <div className="text-secondary font-semibold text-sm leading-5">
        {label}
      </div>
    </div>
    <div className="flex justify-between p-4">
      <div>
        <div className="flex pl-10 pt-2">
          <div className="text-secondary font-semibold text-sm leading-5 w-40">
            <div>
              Poslední změny:
            </div>
            <div>
              Vytvořeno:
            </div>
            {createdBy ? (
              <div>
                Vytvořeno uživatelem:
              </div>
            ) : null}

          </div>
          <div className="text-secondary text-sm leading-5 ml-10 w-72">
            <div>
              {moment(lastUpdate).format('DD.MM.YYYY HH:mm')}
            </div>
            <div>
              {(createdAt && moment(createdAt).format('DD.MM.YYYY HH:mm')) || '-'}
            </div>
            <div>
              {createdBy || null}
            </div>
          </div>
        </div>
        <div className="flex pl-10">
          {detailComponent}
        </div>
      </div>
      <div className="flex flex-wrap max-w-xl justify-end">{children}</div>
    </div>
  </div>

);

export default ShiftInfoDetailComponent;
