/* eslint-disable react/button-has-type */
const Button = ({ className, ...rest }: JSX.IntrinsicElements['button']) => (
  <button
    {...rest}
    className={`
      border
      p-1.5
      flex
      align-center
      !rounded-lg
      font-bold
      ${className}
    `}
  />
);

export default Button;
