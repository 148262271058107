import { UtilityData } from '@tymbe/schema/utility.interface';
import { ComponentProps, useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';

import UtilityForm from './UtilityForm';
import apiClient from '../../../../apiClient';
import { defaultOnError } from '../../../../apiClient/utils';
import { IconButton } from '../../../../components/buttons';
import Button from '../../../../components/buttons/Button';
import { getValuesForPatch } from '../../../../components/forms/form-util';
import { CrossIcon } from '../../../../components/icons';

type AddUtilityProps = {
  className?: string;
  initialData: UtilityData;
};

type UtylityFormType = ComponentProps<typeof UtilityForm>;

const EditUtility = ({ className, initialData }: AddUtilityProps) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const patchUtility = useMutation({
    mutationFn: async (data: Partial<UtilityData>) => apiClient.service('utility').patch(initialData.id, data),
    onSuccess: async () => {
      setShowModal(false);
      await queryClient.invalidateQueries(['utility']);
    },
    onError: defaultOnError,
  });

  const onSubmit: UtylityFormType['onSubmit'] = (formState) => {
    const values = getValuesForPatch(formState);
    patchUtility.mutate(values);
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} className={className}>Upravit</Button>
      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        contentLabel="My dialog"
        className="relative bg-bg p-7 translate-1/2 rounded-lg m-auto min-w-[450px]"
        overlayClassName="bg-overlay fixed top-0 left-0 right-0 bottom-0 flex flex-col overflow-auto p-5 z-modal"
      >
        <IconButton
          style={{ position: 'absolute', top: 5, right: 5 }}
          onClick={() => setShowModal(false)}
        >
          <CrossIcon />
        </IconButton>
        <UtilityForm
          title="Nová pomůcka"
          onSubmit={onSubmit}
          initialValues={initialData}
        >
          <div className="flex gap-4">
            <Button onClick={() => setShowModal(false)}>Zavřít</Button>
            <div className="grow" />
            <Button type="submit" disabled={patchUtility.isLoading}>Uložit</Button>
          </div>
        </UtilityForm>
      </Modal>
    </>
  );
};

export default EditUtility;
