import { useCallback, useState } from 'react';

import Container from '../../../containers';
import EmailChangeContainer from '../../../containers/emailChange';

const EmailChangePage = () => {
  const [loading] = useState(false);
  const [emailSent] = useState(false);

  const onFinish = useCallback(async (/* credentials */) => {

  }, []);

  const onFinishFailed = useCallback((/* error */) => {

  }, []);

  return (
    <Container
      header
      contentstyle={{ marginTop: '4%' }}
      iconcolor="#B3CA1F"
      background="#FEFEFE"
      footer={false}
    >
      <EmailChangeContainer
        loading={loading}
        emailSent={emailSent}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
    </Container>
  );
};

export default EmailChangePage;
