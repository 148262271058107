import { ShiftType } from '@tymbe/schema/enums';
import PropTypes from 'prop-types';

import AdminTable from '../AdminTable';
import { UnapprovedAttendanceExtendedRow } from '../tableExtendedRows';

const UnapprovedAttendanceTable = ({
  rowSelection,
  columns,
  dataSource,
  blockData,
  mankoData,
  absenceData,
  onRowBlockChange,
  onRowMankoChange,
  onRowAbsenceChange,
  onRowSelect,
  pagination,
  onPaginationChange,
  onTableChange,
  isLoading,
  currentPage,
}) => {
  const getRowKey = (record) => record.id;

  return (
    <AdminTable
      rowSelection={{ ...rowSelection, onSelect: onRowSelect }}
      rowKey={getRowKey}
      columns={columns}
      onPaginationChange={onPaginationChange}
      currentPage={currentPage}
      data={{ ...pagination, data: dataSource }}
      onChange={onTableChange}
      isLoading={isLoading}
      expandable={{
        // TODO: This is ticking bomb. Refactor!
        // eslint-disable-next-line react/no-unstable-nested-components
        expandedRowRender: (record) => (
          <div className="row">
            <UnapprovedAttendanceExtendedRow
              record={record}
              blockData={blockData[record.person.id]?.[record.shift.company_id]}
              mankoData={mankoData[record.person.id]?.[record.id]}
              absenceData={absenceData[record.id]}
              onBlockDataChange={onRowBlockChange}
              onMankoDataChange={onRowMankoChange}
              onAbsenceDataChange={onRowAbsenceChange}
            />
          </div>
        ),
        rowExpandable: (record) => record.shift.type !== ShiftType.VACATION,
      }}
    />
  );
};
// TODO: Supply proper shapes of objects/arrays
UnapprovedAttendanceTable.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  rowSelection: PropTypes.object,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  blockData: PropTypes.object.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  mankoData: PropTypes.object.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  absenceData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRowBlockChange: PropTypes.func.isRequired,
  onRowMankoChange: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onRowAbsenceChange: PropTypes.func.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  pagination: PropTypes.object.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
};

export default UnapprovedAttendanceTable;
