import { ComponentProps } from 'react';

type ReleaseAdvancesProps = ComponentProps<'button'>;

const ReleaseAdvances = ({
  children = 'Uvolnit zálohy',
  ...rest
}: ReleaseAdvancesProps) => (
  <button
    className="px-3 py-1 ty-button-secondary"
    type="button"
    {...rest}
  >
    {children}
  </button>
);

export default ReleaseAdvances;
