import Tooltip from 'rc-tooltip';

import { StarIcon } from '../../components/icons';

interface StarRatingProps {
  ratingValue: number | undefined;
}

const StarRating = ({ ratingValue }: StarRatingProps) => {
  const renderRating = (rating: number) => (
    <div className="ty-star-rating" style={{ display: 'flex', flexWrap: 'nowrap' }}>
      {/* hodnoceni mezi 0-99  */}
      <StarIcon
        iconcolor={rating >= 1 ? ' #B6CD1E' : '#28367b'}
        strokecolor={rating >= 1 ? ' #B6CD1E' : undefined}
      />
      <StarIcon
        iconcolor={rating >= 20 ? ' #B6CD1E' : '#28367b'}
        strokecolor={rating >= 20 ? ' #B6CD1E' : undefined}
      />
      <StarIcon
        iconcolor={rating >= 40 ? ' #B6CD1E' : '#28367b'}
        strokecolor={rating >= 40 ? ' #B6CD1E' : undefined}
      />
      <StarIcon
        iconcolor={rating >= 60 ? ' #B6CD1E' : '#28367b'}
        strokecolor={rating >= 60 ? ' #B6CD1E' : undefined}
      />
      <StarIcon
        iconcolor={rating >= 80 ? ' #B6CD1E' : '#28367b'}
        strokecolor={rating >= 80 ? ' #B6CD1E' : undefined}
      />
    </div>
  );
  return (
    <div className="ty-primary-h3">{ratingValue ? renderRating(ratingValue) : (
      <Tooltip trigger={['hover']} overlay="Žádná data">
        <div>
          -
        </div>
      </Tooltip>
    )}
    </div>
  );
};

export default StarRating;
