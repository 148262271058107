import CheckCircleTwoTone from '@ant-design/icons/CheckCircleTwoTone';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import FileUnknownTwoTone from '@ant-design/icons/FileUnknownTwoTone';
import { Query, Service } from '@feathersjs/feathers';
import { captureMessage } from '@sentry/react';
import { Modal } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import AdminTable from '../../../../components/AdminTable';
import { SecondaryButton } from '../../../../components/buttons';
import TimeoutButton from '../../../../components/buttons/TimeoutButton';
import {
  EditIcon,
  PlusOutlinedIcon,
  TrashIcon,
  UploadIcon,
  ViewAltIcon,
} from '../../../../components/icons';
import Card from '../../../../components/Layout/Card/Card';
import SearchBox from '../../../../components/search/SearchBox';
import HoverRow from '../../../../components/tableRenders/HoverRow';
import styles from '../../../../components/tableRenders/HoverRow.module.css';
import useURLParamsHandler from '../../../../hooks/UrlParamsHandler/useURLParamsHandler';
import {
  actions,
  loadDocumentTypesThunk,
  selectDocumentTypesForCompany,
  selectDocumentTypesForCompanyLoading,
} from '../../../../pages-legacy/company/company/documentTypeSlice';
import { DocumentTypeData as DocumentType } from '../../../../types/TymbeApi';
import { verified } from '../../../../utils/colors';
import { DEFAULT_SORT } from '../../../../utils/constants';
import { getDocumentAction } from '../../../../utils/documents';
import { EditDocumentAction } from '../../../../utils/enums';

const documentTypeService: Service<DocumentType> = feathersClient.service('document-type');

const DocumentTypesList = () => {
  const { companyId } = useParams();

  const dispatch = useDispatch();

  const data: Array<DocumentType> = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => selectDocumentTypesForCompany(state.views.companyDocumentTypes),
  );
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const [paginationPageSize, setPaginationPageSize] = useState(allSearchParams.pageSize || 10);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(allSearchParams.page || 1);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [searchParams, setSearchParams] = useState<Query>(
    allSearchParams.search ? { quickFilter: allSearchParams.search?.split(' ') } : {},
  );
  const [sort, setSort] = useState(allSearchParams.sort || DEFAULT_SORT);

  const loading: boolean = useSelector(selectDocumentTypesForCompanyLoading) as boolean;

  const onParamsSearch = (value: string) => {
    const query: Query = {};
    if (value) {
      query.quickFilter = value.split(' ');
    }
    setUrlParamsHandler({ page: 1, search: value });
    setSearchParams(query);
  };

  const onRemoveCancelClick = (record: DocumentType) => {
    const restoreRecord = { ...record, deleted_at: null };
    dispatch(actions.updateRecord({ id: record.id, record: restoreRecord }));
  };

  const onClickRemoveRecord = (record: DocumentType) => {
    try {
      documentTypeService.remove(Number(record.id));
    } catch (ex) {
      onRemoveCancelClick(record);
    }
  };

  const showConfirmRemoveModal = (record: DocumentType) => {
    const onOkClick = () => {
      const deletedRecord = { ...record, deleted_at: moment.utc() };
      dispatch(actions.updateRecord({ id: record.id, record: deletedRecord }));
    };

    return new Promise<void>((resolve, reject) => {
      Modal.confirm({
        title: 'Potvrdit smazání dokumentu',
        width: 450,
        icon: <ExclamationCircleOutlined />,
        content: `Chcete opravdu odstranit dokument: ${record.name}?`,
        onOk() {
          resolve();
          onOkClick();
        },
        onCancel() {
          reject();
        },
      });
    });
  };

  const onPublishCancelClick = (record: DocumentType) => {
    const restoreRecord = { ...{ ...record, branchoffice: null }, published_at: null };
    dispatch(actions.updateRecord({ id: record.id, record: restoreRecord }));
  };

  const onClickPublishRecord = (record: DocumentType) => {
    try {
      documentTypeService.patch(Number(record.id), { published_at: moment().toISOString() });
    } catch (ex) {
      onPublishCancelClick(record);
    }
  };

  const showConfirmPublishModal = (record: DocumentType) => {
    const onOkClick = () => {
      const publishedRecord = {
        ...{ ...record, branchoffice: null },
        published_at: moment().toISOString(),
      };
      dispatch(actions.updateRecord({ id: record.id, record: publishedRecord }));
    };

    return new Promise<void>((resolve, reject) => {
      Modal.confirm({
        title: 'Potvrdit publikování dokumentu',
        width: 450,
        icon: <ExclamationCircleOutlined />,
        content: `Chcete opravdu publikovat dokument: ${record.name}?`,
        onOk() {
          resolve();
          onOkClick();
        },
        onCancel() {
          reject();
        },
      });
    });
  };

  const interactiveDocumentsColumns = [
    {
      title: 'Stav',
      render: (text: string, record: DocumentType) => (record?.published_at ? (
        <Tooltip overlay="Publikováno" placement="top" trigger={['hover']}>
          <CheckCircleTwoTone twoToneColor={verified} />
        </Tooltip>
      ) : (
        <Tooltip overlay="Nepublikováno" placement="top" trigger={['hover']}>
          <FileUnknownTwoTone twoToneColor="orange" />
        </Tooltip>
      )),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      align: 'left',
    },
    {
      title: 'Název',
      render: (text: string, record: DocumentType) => `${record.name}`,
      sorter: true,
      dataIndex: 'name',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      align: 'left',
    },
    {
      title: 'Zařazení',
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (text: string, record: DocumentType) =>
        (record.branchoffice === null ? 'Celá firma' : record?.branchoffice?.name),
    },
    {
      title: 'Platný od',
      align: 'left',
      sorter: true,
      dataIndex: 'published_at',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      className: 'd-flex justify-content-between align-center',
      render: (text: string, record: DocumentType) => {
        const action = getDocumentAction(record);

        if (action === EditDocumentAction.UNKNOWN) {
          captureMessage(`Nepodařilo se zjistit typ dokumentu: ${record.name}`);
        }

        return (
          <>
            {record.published_at === null ? 'Nepublikováno' : moment(record.published_at).format('DD.MM.YYYY HH:mm')}
            <HoverRow>
              {action === EditDocumentAction.EDITOR_JS && (
                <Tooltip overlay="Zobrazit EditorJS" placement="top" trigger={['hover']}>
                  <a
                    aria-label="Zobrazit EditorJS"
                    rel="noreferrer"
                    type="button"
                    className={`
                      ty-button-primary-color
                      ty-icon-button
                      ty-row-on-hover-button
                      ${styles.ty_hover_edit_button}
                    `}
                    href={`/company/${companyId}/document-type/${record.id}/editor`}
                  > <ViewAltIcon iconcolor="white" />
                  </a>
                </Tooltip>
              )}
              {action === EditDocumentAction.GOOGLE && (
                <Tooltip overlay="Zobrazit dokument" placement="top" trigger={['hover']}>
                  <a
                    aria-label="Zobrazit dokument"
                    target="_blank"
                    rel="noreferrer"
                    className={`
                      ty-button-primary-color
                      ty-icon-button
                      ty-row-on-hover-button
                      ${styles.ty_hover_edit_button}
                    `}
                    href={`https://docs.google.com/document/d/${record.template_id}/edit`}
                  > <ViewAltIcon iconcolor="white" />
                  </a>
                </Tooltip>
              )}
              <TimeoutButton
                timeoutFunction={() => onClickRemoveRecord(record)}
                notificationMessage={`Dokument "${record.name}" bude smazán`}
                onClick={() => showConfirmRemoveModal(record)}
                onNotificationCancel={() => onRemoveCancelClick(record)}
                className={`
                  ty-row-on-hover-button
                  ${styles.ty_hover_remove_button}
                `}
              >
                <TrashIcon />
              </TimeoutButton>
              <Tooltip overlay="Upravit" placement="top" trigger={['hover']} overlayClassName={styles.ty_tooltip}>
                <Link
                  className={`
                      ty-button-primary-color
                      ty-icon-button
                      ty-row-on-hover-button
                      ${styles.ty_hover_edit_button}
                    `}
                  to={{
                    pathname: `../document-type/${record?.id}`,
                  }}
                > <EditIcon />
                </Link>
              </Tooltip>
              {record.published_at ? null : (
                <Tooltip overlay="Publikovat dokument" placement="top" trigger={['hover']}>
                  <TimeoutButton
                    timeoutFunction={() => onClickPublishRecord(record)}
                    notificationMessage={`Dokument "${record.name}" bude publikován`}
                    onClick={() => showConfirmPublishModal(record)}
                    onNotificationCancel={() => onPublishCancelClick(record)}
                    className={`
                      ty-button-primary-color
                      ty-icon-button
                      ty-row-on-hover-button
                      ${styles.ty_hover_edit_button}
                    `}
                  >
                    <UploadIcon iconcolor="white" />
                  </TimeoutButton>
                </Tooltip>
              )}
            </HoverRow>
          </>
        );
      },
    },
  ];

  const onPaginationChange = (newPage: number, newPageSize: number) => {
    setUrlParamsHandler({ page: newPage, pageSize: newPageSize });
    setPaginationPageSize(newPageSize);
    setPaginationCurrentPage(newPage);
  };

  useEffect(() => {
    const query = {
      company_id: companyId,
      query: {
        $skip: paginationPageSize * (paginationCurrentPage - 1),
        $limit: paginationPageSize,
        $modify: searchParams,
        $sort: sort,
        $eager: '[branchoffice]',
      },
    };

    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loadDocumentTypesThunk(query)).then(({ payload }) => {
      if (payload?.total || payload?.total === 0) {
        setPaginationTotal(payload.total);
      }
    });
  }, [companyId, dispatch, paginationCurrentPage, paginationPageSize, searchParams, sort]);

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters:Record<string, FilterValue>,
    sorter: SorterResult<unknown>,
  ) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'name':
          setSort({ name: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'published_at':
          setSort({ published_at: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(DEFAULT_SORT);
          break;
      }
    } else {
      setSort(DEFAULT_SORT);
    }
  };

  useEffect(() => {
    setUrlParamsHandler({ sort });
  }, [sort]);

  return (
    <Card>
      <div className="flex flex-col gap-2 px-6">
        <div className="flex justify-end align-center">
          <Link to={`/company/${companyId}/document-type/create`}>
            <SecondaryButton className="flex flex-row justify-center align-center gap-2">
              <PlusOutlinedIcon />
              Nový dokument
            </SecondaryButton>
          </Link>
          <SearchBox
            name="companyDocumentSearchBox"
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            onParamsSearch={onParamsSearch}
            loading={loading}
          />
        </div>

        <AdminTable
          rowKey={(record: DocumentType) => record.id}
          columns={interactiveDocumentsColumns}
          bordered
          size="small"
          className="ty-table"
          rowClassName="ty-row-hover"
          onPaginationChange={onPaginationChange}
          onChange={onTableChange}
          data={{
            total: paginationTotal,
            skip: paginationPageSize * (paginationCurrentPage - 1),
            limit: paginationPageSize,
            data: data?.filter((x: DocumentType) => !x.deleted_at) || [],
          }}
        />
      </div>
    </Card>
  );
};

export default DocumentTypesList;
