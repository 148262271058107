import { SVGProps } from 'react';

type RefreshIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const RefreshIcon = (props: RefreshIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.642 6.35146C16.012 4.72146 13.702 3.78146 11.162 4.04146C7.49196 4.41146 4.47196 7.39146 4.06196 11.0615C3.51196 15.9115 7.26196 20.0015 11.992 20.0015C15.182 20.0015 17.922 18.1315 19.202 15.4415C19.522 14.7715 19.042 14.0015 18.302 14.0015C17.932 14.0015 17.582 14.2015 17.422 14.5315C16.292 16.9615 13.582 18.5015 10.622 17.8415C8.40196 17.3515 6.61196 15.5415 6.14196 13.3215C5.30196 9.44146 8.25196 6.00146 11.992 6.00146C13.652 6.00146 15.132 6.69146 16.212 7.78146L14.702 9.29146C14.072 9.92146 14.512 11.0015 15.402 11.0015H18.992C19.542 11.0015 19.992 10.5515 19.992 10.0015V6.41146C19.992 5.52146 18.912 5.07146 18.282 5.70146L17.642 6.35146Z" />
  </svg>
);

export default RefreshIcon;
