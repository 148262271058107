import isArray from 'lodash.isarray';
import { useMemo } from 'react';

import { darkBlue } from '../../../utils/colors';
import { TagText } from '../../texts';
import transform, { FormValues } from '../Libs/transform';

type TyInteractiveLabelsProps = {
  labelsValues: Record<string, { name: string, label: string, format?: string }>;
  initialValues?: FormValues
  setInitialValues?: (values: FormValues) => void
};

const TyInteractiveLabels =
  ({ labelsValues, initialValues = {}, setInitialValues = undefined }: TyInteractiveLabelsProps) => {
    const transformedValues = useMemo(
      () => transform(initialValues || {}, labelsValues),
      [initialValues, labelsValues],
    );

    const labelsCount = transformedValues.filter((v) => v.value).length;

    return (
      <div>
        { isArray(transformedValues) && transformedValues.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'start',
              margin: '0px 0 35px 0',
              width: '100%',
            }}
          >

            { transformedValues.map((option) => (
              option.value && (
                <div
                  style={{ margin: '5px 5px 5px 0px' }}
                  key={`${option.label}-${option.value}`}
                >
                  <TagText
                    color={darkBlue}
                    statusColor={`${darkBlue}1A`}
                    statusText={`${option.label} ${option.value}`}
                    tyClosable
                    tyOnClose={() => setInitialValues?.({
                      ...initialValues,
                      [option.removeName]: option.removeValue,
                    })}
                  />
                </div>
              )
            ))}

            { labelsCount > 1 && (
              <div
                style={{ margin: '5px 5px 5px 0px' }}
              >
                <TagText
                  statusColor="#262B40"
                  color="#FFFFFF"
                  statusText="Smazat Vše"
                  tyClosable
                  tyOnClose={() => setInitialValues?.({})}
                />
              </div>
            )}

          </div>

        )}
      </div>
    );
  };

export default TyInteractiveLabels;
