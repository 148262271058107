import moment from 'moment';
import useCollapse from 'react-collapsed';
import { useQuery } from 'react-query';

import ShiftInfoDetail from './ShiftInfoDetail';
import shiftStateSwitch from './ShiftUtils/ShiftStateSwitch';
import shiftStyleSettings from './ShiftUtils/ShiftStyleSettings';
import feathersClient from '../../../apiClient';
import { PrimaryButton } from '../../../components/buttons';
import { ApplicationData, ShiftData } from '../../../types/TymbeApi';
import Spinner from '../../../utils/spinner';

export interface ShiftInfoProps {
  applicationId: number;
}

const ShiftInfo = ({ applicationId }: ShiftInfoProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false });

  const { isLoading, data: applicationInfo } = useQuery(
    ['application', applicationId],
    async () => feathersClient.service('application').get(applicationId, {
      query: {
        $eager:
          '[shift(withDeleted).[company(withDeleted), branchoffice(withDeleted).parent], employer,'
          + ' attendance.[creditTransaction, confirmedBy(withDeleted),'
          + ' personLiability, surveyAnswer]]',
      },
    }) as Promise<ApplicationData & { shift: ShiftData }>,
    { staleTime: Infinity },
  );

  if (!applicationInfo) {
    return null;
  }

  const shiftState = shiftStateSwitch(applicationInfo);
  const branchofficeParent = applicationInfo?.shift?.branchoffice?.parent || applicationInfo?.shift?.branchoffice;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="border-secondary-200 border rounded-lg">
      <div className={`${shiftStyleSettings[shiftState].borderColour} border-l-8 rounded`}>
        <div className="flex justify-between p-4">
          <div className="flex">
            <div className="w-40">
              <div className={`${shiftStyleSettings[shiftState].textColour} font-semibold text-sm leading-5`}>
                {applicationInfo?.shift?.company?.name}
              </div>
              <div className="font-semibold text-sm leading-5">
                {moment(applicationInfo?.shift?.start_time).format('DD.MM.YYYY HH:mm')}
              </div>
              <div>{moment(applicationInfo?.shift?.end_time).format('DD.MM.YYYY HH:mm')}</div>
            </div>
            <div className="ml-10">
              <div className={`${shiftStyleSettings[shiftState].textColour}`}>{shiftStyleSettings[shiftState].text}</div>
              <div className="font-semibold text-sm leading-5">{branchofficeParent?.name}</div>
              <div>{applicationInfo?.shift?.name}</div>
            </div>
          </div>
          <PrimaryButton {...getToggleProps()}>
            <span>
              {isExpanded ? 'Skrýt detail směny' : 'Zobrazit detail směny'}
            </span>
          </PrimaryButton>
        </div>
        <div {...getCollapseProps()}>
          {applicationInfo ? <ShiftInfoDetail application={applicationInfo} state={shiftState} /> : null}
        </div>
      </div>
    </div>
  );
};

export default ShiftInfo;
