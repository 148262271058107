import { Tooltip } from "antd";
import { WorkTimeWeekTableData } from '@tymbe/schema/work-time-week-table-data.interface';

export interface ReportingWeekProps {
  week: WorkTimeWeekTableData;
}

const ReportingWeek = ({
  week,
}: ReportingWeekProps) => {
  return (
    <>
    {week && (
      <div className='flex flex-col'>
          <div className='flex flex-col border-b pb-3'>
            <Tooltip title={'Splněná docházka v daném týdnu v %'}>
              <span>
                {week.week_hours_percentage + '%'}
              </span>
            </Tooltip>
            <Tooltip title={'Počet odchozených hodin / počet hodin smluvních hodin v daném týdnu'}>
              <span>
                {week.actual_weekly_hours + "/" + week.expected_weekly_hours}
              </span>
            </Tooltip>
          </div>
        <div className='flex flex-col border-b pb-3 pt-3'>
          <Tooltip title={'Splněná docházka od začátku smlouvy v %'}>
            <span>
              {week.hours_so_far_percentage + '%'}
            </span>
          </Tooltip>
          <Tooltip title={'Počet odchozených hodin / počet smluvních hodin od začátku smlouvy'}>
            <span>
              {week.actual_hours_so_far + "/" + week.expected_hours_so_far}
            </span>
          </Tooltip>
        </div>
        <div className='flex flex-col pt-3'>
          <Tooltip title={'Naplnění smlouvy v %'}>
            <span>
              {week.hours_total_percentage + '%'}
            </span>
          </Tooltip>
          <Tooltip title={'Počet odchnozených hodin od začátku smlouvy / celkový počet hodin ve smlouvě %'}>
            <span>
              {week.actual_hours_so_far + "/" + week.expected_hours_total}
            </span>
          </Tooltip>
        </div>
      </div>
    )}
    </>
  );
};

export default ReportingWeek;
