import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';

export enum PaySupplementAmountType {
  Fixed = 'Fixed',
  Percentage = 'Percentage',
}

export interface PaySupplementRule {
  applies_to_entire_shift: boolean;
  type: PaySupplementAmountType;
  value: number;
}

export type PaySupplementRules = Partial<Record<PaySupplementType, PaySupplementRule>>;
