import React from 'react';

import { ApplicationData } from '../../../types/TymbeApi';
import { ApplicationStateDisplay } from '../../../utils/enums';

export interface ApplicationDetailsProps {
  application: ApplicationData;
}

const ApplicationDetails = ({ application }:ApplicationDetailsProps) => (
  <div className="flex">
    <div className="text-secondary font-semibold text-sm leading-5 w-40">
      <div>
        Základní odměna:
      </div>
      <div>
        Kredity:
      </div>
      <div>
        Zaměstnavatel:
      </div>
      <div>
        Pozvánka:
      </div>
      <div>
        Stav přihlášení:
      </div>
    </div>
    <div className="text-secondary text-sm leading-5 ml-10 w-72">
      <div>
        {application.payment_base}
      </div>
      <div>
        {application.credits}
      </div>
      <div>
        {application.employer?.name}
      </div>
      <div>
        {application.invitation ? 'Ano' : 'Ne'}
      </div>
      <div>
        {!application.state ? null : ApplicationStateDisplay[application.state]}
      </div>
    </div>
  </div>
);

export default ApplicationDetails;
