import { useState, useLayoutEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Container from '../../containers';
import VerifyContainer from '../../containers/verify';
import { verifyAccountThunk, selectAuthError } from '../../data/store/slices/auth';

const VerifyPage = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectAuthError);

  const location = useLocation();
  const history = useNavigate();

  const [loading, setLoading] = useState(true);

  const token = location.search.substring(7);

  const verifyAccount = useCallback(async (verifyToken) => {
    await dispatch(verifyAccountThunk(verifyToken));
    // if (error) console.error(error);
    setLoading(false);
  }, [dispatch]);

  useLayoutEffect(() => {
    if (!token) {
      history('/signin');
    } else {
      verifyAccount(token);
    }
  }, [history, token, verifyAccount]);
  return (
    <Container
      header
      contentstyle={{ marginTop: '4%' }}
      iconcolor="#202020"
      background="#FEFEFE"
      footer={false}
    >
      <VerifyContainer loading={loading} error={error} />
    </Container>
  );
};

export default VerifyPage;
