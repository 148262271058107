import { Form, FormState } from 'informed';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../../components/buttons';
import TyInput from '../../../../components/inputs/TyInput';
import ModalShell from '../../../../components/modals/ModalShell';
import { RequestBody } from '../../../../types/ReactQuery';
import { AttendanceData } from '../../../../types/TymbeApi';
import { DeepPartial } from '../../../../types/utils';

export interface EditApplicationProps {
  attendance: AttendanceData;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const EditJobEvaluation = ({ attendance, onSuccess }: EditApplicationProps) => {
  const [showModal, setShowModal] = useState(false);

  const { mutateAsync: editJobEvaluation } = useMutation(
    ['editApplication'],
    async (req: RequestBody<DeepPartial<AttendanceData>>): Promise<void> => {
      await feathersClient.service('attendance').patch(req.id, req.body);
    },
    {
      onSuccess,
    },
  );

  const initialValues = {
    job_evaluation: attendance.job_evaluation,
  };

  const onSubmit = async ({ values }: FormState<Partial<AttendanceData>>) => {
    const attendanceFormData: DeepPartial<AttendanceData> = {
      job_evaluation: Number(values.job_evaluation),
    };

    try {
      await editJobEvaluation({ id: attendance.id, body: attendanceFormData });
      setShowModal(false);
    } catch {
      ErrorAlert('Při editaci hodnocení došlo k chybě');
    }
  };
  return (
    <>
      <div className="p-2">
        <SecondaryButton onClick={() => setShowModal(true)}>
          Upravit hodnocení
        </SecondaryButton>
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <h3 className="ty-h3 text-align-start">
            Upravit hodnocení
          </h3>
          <div className="flex flex-row gap-2 flex-wrap">
            <TyInput
              name="job_evaluation"
              label="Hodnocení"
              className="w-full md:flex-1"
              required
            />
          </div>
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default EditJobEvaluation;
