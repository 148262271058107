import PropTypes from 'prop-types';

import { PrimaryButton } from '../../components/buttons';
import { SuccessResult, ErrorResult } from '../../components/results';
import { LinkText } from '../../components/texts';
import Wrapper from '../../components/wrapper';
import { white } from '../../utils/colors';
import Spinner from '../../utils/spinner';

const VerifyContainer = ({ error, loading }) => {
  if (loading) return <Spinner loadingtext="Ověřujeme Tvoji emailovou adresu..." />;
  return (
    <Wrapper>
      {error
        ? (
          <ErrorResult
            title="Ověření emailu selhalo"
            subTitle="Nesprávný token nebo je Tvá emailová adresa již ověřena."
            extra={[
              <PrimaryButton key="signin">
                <LinkText
                  style={{ color: white }}
                  linkTo="/signin"
                  linkText="Sign In"
                />
              </PrimaryButton>,
            ]}
          />
        ) : (
          <SuccessResult
            title="Ověření emailové adresy úspěšné"
            subTitle="Vítej mezi registrovanými Tymbery :)"
            extra={[
              <PrimaryButton key="signin">
                <LinkText
                  style={{ color: white }}
                  linkTo="/signin"
                  linkText="Sign In"
                />
              </PrimaryButton>,
            ]}
          />
        )}
    </Wrapper>
  );
};

VerifyContainer.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default VerifyContainer;
