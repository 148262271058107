import { ForeignerOrigin, SalaryLimitType, Gender, CountryCodes } from '@tymbe/schema/enums';
import { PersonSalaryLimitData } from '@tymbe/schema/person-salary-limit.interface';
import { getSalaryLimitMonth } from '@tymbe/utils/person-salary-limit.utils';
import { Form, FormState } from 'informed';
import moment, { Moment } from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { foreignerOptions, genderOptions } from './detailFormOptions';
import { EditUserFormData } from './editUserForm.interface';
import UserDetailForm from './UserDetailForm';
import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SubmitButton } from '../../../components/buttons';
import { getValuesForPatch } from '../../../components/forms/form-util';
import { selectCountryOptions } from '../../../components/inputs/TySelectCountry/TySelectCountry.utils';
import Card from '../../../components/Layout/Card';
import { PageTitle } from '../../../components/texts';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import { RequestBody } from '../../../types/ReactQuery';
import { PersonContactData, PersonDataData } from '../../../types/TymbeApi';
import { DeepPartial } from '../../../types/utils';
import { ContactType, Roles } from '../../../utils/enums';
import { addPlusToPhone, addZerosToPhone } from '../../../utils/phoneNumber';

interface Address {
  id: number | undefined;
  addressline1: string;
  locality: string | null;
  zip: string | null;
  country: string;
}

interface ModifiedFormData {
  birthdate?: Moment | null,
  birthplace?: string | null,
  nationality?: CountryCodes | null,
  family_member_nationality?: CountryCodes | null;
  gender?: Gender | null,
  permanentAddress?: Partial<Address> | null,
  contactAddress?: Partial<Address> | null,
  pin?: string | null,
  person?: {
    id?: number,
    first_name?: string | null,
    last_name?: string | null,
    login?: {
      username?: string,
      person_id?: number,
      id?: number | undefined
    }
  },
  foreigner_origin?: ForeignerOrigin | null,
  bank_account?: string | null,
  payment_note?: string | null
  work_on_hpp_allowed?: boolean | null;
}

const EditUserDetail = () => {
  const { id } = useParams();
  const history = useNavigate();
  const user = useUser();

  const isSuperOrTymbeAdmin = user.role.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
  ].includes(role.slug));

  const canEditEmail = user.role.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
  ].includes(role.slug));

  const { data: personData } = useQuery(['person-data', id], async () =>
    feathersClient.service('person-data').get(id!, {
      query: {
        $eager: '[permanentAddress, contactAddress, person.[login, contact]]',
      },
    }), { enabled: !!id });

  const { data: personSalaryLimit } = useQuery(['person-salary-limit', id], async () =>
    feathersClient.service('person-salary-limit').get(id!));

  const queryClient = useQueryClient();

  const { mutateAsync: patchUser } = useMutation(
    ['PatchUser'],
    async (req: RequestBody<DeepPartial<PersonDataData>>): Promise<void> => {
      await feathersClient.service('person-data').patch(req.id, req.body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['person-data', id]);
      },
    },
  );

  const { mutateAsync: patchUserContact } = useMutation(
    ['PatchUserContact'],
    async (req: RequestBody<Partial<PersonContactData>>): Promise<void> => {
      if (req.id) {
        await feathersClient.service('person-contact').patch(req.id, req.body);
      } else {
        await feathersClient.service('person-contact').create(req.body);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['person-data', id]);
      },
      onError: () => {
        ErrorAlert('Chyba v telefonním čísle');
      },
    },
  );

  const { mutateAsync: setPersonSalaryLimit } = useMutation(
    ['SetPersonSalaryLimit'],
    async (req: RequestBody<PersonSalaryLimitData>): Promise<void> => {
      await feathersClient.service('person-salary-limit').update(req.id, req.body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['person-salary-limit']);
      },
    },
  );

  if (!id) {
    return <Navigate to="/shift" />;
  }

  const personPhone = personData?.person?.contact?.find(
    (contact: PersonContactData) => contact.type === ContactType.MOBILE_PHONE,
  );

  const contactEmail = personData?.person?.contact?.find(
    (contact: PersonContactData) => contact.type === ContactType.EMAIL,
  );

  const initialValues = {
    ...personData,
    first_name: personData?.person?.first_name,
    last_name: personData?.person?.last_name,
    login_email: personData?.person?.login?.username,
    contact_email: contactEmail,
    gender: genderOptions.find((gndr) => gndr.value === personData?.gender) || genderOptions[0],
    birthdate: personData?.birthdate ? moment(personData.birthdate) : undefined,
    nationality: selectCountryOptions().find((country) => country.value === personData?.nationality),
    family_member_nationality: selectCountryOptions().find(
      (nationality) => nationality.value === personData?.family_member_nationality,
    ) || null,
    phone: addPlusToPhone(personPhone?.value),
    contact_address: personData?.contactAddress?.addressline1,
    contact_locality: personData?.contactAddress?.locality,
    contact_zip: personData?.contactAddress?.zip,
    contact_country: selectCountryOptions().find((cntry) => cntry.value === personData?.contactAddress?.country),
    permanent_address: personData?.permanentAddress?.addressline1,
    permanent_locality: personData?.permanentAddress?.locality,
    permanent_zip: personData?.permanentAddress?.zip,
    permanent_country: selectCountryOptions().find((cntry) => cntry.value === personData?.permanentAddress?.country),
    salary_limit_enabled: personSalaryLimit?.limit === SalaryLimitType.AVOID_TAXES,
    foreigner_origin: foreignerOptions.find((option) => option.value === personData?.foreigner_origin) || null,
  };

  const onSubmit = async (formState: FormState<Partial<EditUserFormData>>) => {
    const values = getValuesForPatch(formState);
    const { dirt } = formState;

    const person_id = Number(id);
    if (!person_id) {
      return;
    }
    let formPhone;
    if (values.phone) {
      if (personPhone) {
        formPhone = personPhone;
        formPhone.value = addZerosToPhone(values.phone) || null;
      } else {
        formPhone = {
          person_id,
          type: ContactType.MOBILE_PHONE,
          value: addZerosToPhone(values.phone) || null,
        } as Partial<PersonContactData>;
      }
    }

    let permanentAdd: Partial<Address> | undefined;
    if ((values.permanent_address !== undefined)
      || (values.permanent_country !== undefined)
      || (values.permanent_locality !== undefined)
      || (values.permanent_zip !== undefined)
    ) {
      permanentAdd = {
        addressline1: values.permanent_address || formState.values.permanent_address,
        locality: values.permanent_locality !== undefined
          ? values.permanent_locality
          : formState.values.permanent_locality,
        zip: values.permanent_zip !== undefined
          ? values.permanent_zip
          : formState.values.permanent_zip,
        country: values.permanent_country?.value || formState.values.permanent_country?.value,
      };
    }

    let contactAdd: Partial<Address> | undefined;
    if ((values.contact_address !== undefined)
      || (values.contact_country !== undefined)
      || (values.contact_locality !== undefined)
      || (values.contact_zip !== undefined)
    ) {
      contactAdd = {
        addressline1: values.contact_address || formState.values.contact_address,
        locality: values.contact_locality !== undefined
          ? values.contact_locality
          : formState.values.contact_locality,
        zip: values.contact_zip !== undefined
          ? values.contact_zip
          : formState.values.contact_zip,
        country: values.contact_country?.value || formState.values.contact_country?.value,
      };
    }

    const email: Partial<PersonContactData> = {
      ...(contactEmail ?? {}),
      person_id,
      type: ContactType.EMAIL,
      value: values.login_email,
    };

    const shouldEmailContactBeChanged = contactEmail
      && email.value
      && initialValues.login_email === contactEmail.value
      && email.value !== contactEmail.value;

    const modifiedFormData: ModifiedFormData = {};
    const modifiedKeys = Object.keys(dirt);

    modifiedKeys.forEach((key) => {
      switch (key) {
        case 'gender':
          modifiedFormData.gender = values.gender?.value;
          break;
        case 'permanent_address':
        case 'permanent_country':
        case 'permanent_zip':
        case 'permanent_locality':
          modifiedFormData.permanentAddress = permanentAdd;
          break;
        case 'contact_address':
        case 'contact_country':
        case 'contact_zip':
        case 'contact_locality':
          modifiedFormData.contactAddress = contactAdd;
          break;
        case 'pin':
          modifiedFormData.pin = values.pin?.replace(/\//g, '') || null;
          break;
        case 'first_name':
        case 'last_name':
          modifiedFormData.person = {
            id: person_id,
            first_name: values.first_name,
            last_name: values.last_name,
            login: {
              person_id,
              id: personData?.person?.login?.id,
            },
          };
          break;
        case 'foreigner_origin':
          if (isSuperOrTymbeAdmin) modifiedFormData.foreigner_origin = values.foreigner_origin?.value || null;
          break;
        case 'bank_account':
        case 'payment_note':
          if (isSuperOrTymbeAdmin) modifiedFormData[key as 'bank_account' | 'payment_note'] = values[key] || null;
          break;
        case 'login_email':
          if (canEditEmail) {
            modifiedFormData.person = {
              id: person_id,
              first_name: values.first_name,
              last_name: values.last_name,
              login: {
                person_id,
                id: personData?.person?.login?.id,
                username: values.login_email,
              },
            };
          }
          break;
        case 'salary_limit_enabled':
        case 'phone':
          break;
        case 'birthdate':
          modifiedFormData.birthdate = values.birthdate || null;
          break;
        case 'nationality':
          modifiedFormData.nationality = values.nationality?.value;
          break;
        case 'family_member_nationality':
          if (isSuperOrTymbeAdmin) {
            modifiedFormData.family_member_nationality = values.family_member_nationality?.value || null;
          }
          break;
        case 'work_on_hpp_allowed':
          modifiedFormData.work_on_hpp_allowed = values.work_on_hpp_allowed;
          break;
        default:
          modifiedFormData[key as 'birthplace'] = values[key as 'birthplace'] || null;
          break;
      }
    });

    try {
      const { salary_limit_enabled } = values;
      if (Object.keys(modifiedFormData).length !== 0) {
        await patchUser({
          id,
          body: modifiedFormData as DeepPartial<PersonDataData>,
        });
      }

      // patch user contact on person-contact service for phone number validation on BE
      if (formPhone && addPlusToPhone(formPhone.value) !== initialValues?.phone) {
        await patchUserContact({ id: formPhone.id!, body: formPhone });
      }
      const month = getSalaryLimitMonth();
      await setPersonSalaryLimit({
        id: `${person_id},${month}`,
        body: {
          person_id,
          limit: salary_limit_enabled ? SalaryLimitType.AVOID_TAXES : SalaryLimitType.NONE,
          month,
        } as PersonSalaryLimitData,
      });

      if (shouldEmailContactBeChanged && email.id) {
        await patchUserContact({
          id: email.id,
          body: email,
        });
      }

      history(`/user/${id}`);
      SuccessAlert('Uživatel byl úspěšně upraven');
    } catch (error) {
      if (!(error instanceof Error)) {
        ErrorAlert('Při editaci uživatele došlo k neznámé chybě');
        return;
      }
      if (error.message.includes('salary limit is not permitted for person with ID')) {
        ErrorAlert(
          'Přepnutí hlídání překročení rozhodného příjmu u DPČ již není u tohoto Tymbera v tomto měsíci možné.',
        );
      } else {
        ErrorAlert(`Při editaci uživatele došlo k chybě: ${error.message}`);
      }
    }
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#ff0"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <PageTitle> Detail uživatele </PageTitle>
        <Card>
          <Form<Partial<EditUserFormData>>
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <UserDetailForm />
            <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default EditUserDetail;
