import { Form, FormState } from 'informed';
import moment from 'moment';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import UserBanForm from './UserBanForm';
import feathersClient from '../../../../apiClient';
import { useUser } from '../../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { SubmitButton } from '../../../../components/buttons';
import Card from '../../../../components/Layout/Card';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { RequestBody } from '../../../../types/ReactQuery';
import { PersonBanData } from '../../../../types/TymbeApi';

const EditPersonBan = () => {
  const [formDisabled, setFormDisabled] = useState(false);

  const { banId, id } = useParams();
  const { person } = useUser();
  const navigate = useNavigate();

  const { data } = useQuery(
    ['fetchUserBanDetail', banId],
    async (): Promise<PersonBanData> =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      feathersClient.service('person-ban').get(banId!),
    { enabled: !!banId },
  );

  const { mutateAsync: patchPersonBan } = useMutation(
    ['PatchPersonBan', banId],
    (req: RequestBody<PersonBanData | FormData>): Promise<PersonBanData> =>
      feathersClient.service('person-ban').patch(req.id, req.body),
  );

  if (!id) {
    return <Navigate to="/users" />;
  }

  if (!banId) {
    return <Navigate to={`/user/${id}/bans`} />;
  }

  const onSubmit = async ({ values }: FormState<PersonBanData>) => {
    setFormDisabled(true);

    try {
      await patchPersonBan({
        id: banId,
        body: {
          ...values,
          start_time: moment(values.start_time).startOf('day').toISOString(),
          end_time: moment(values.end_time || null).endOf('day').toISOString(),
          person_id: Number(id),
          created_by: Number(person.id),
        },
      });

      SuccessAlert('Ban byl úspěšně upraven');
    } catch (err) {
      ErrorAlert('Při pokusu o upravení banu nastala chyba');
    }

    setFormDisabled(false);
    navigate(`/user/${id}/bans`);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Upravit ban </h1>
        <Card>
          <Form<PersonBanData>
            name="company-form"
            onSubmit={onSubmit}
            validateOn="change-submit"
            className="flex flex-col gap-5 w-1/2"
            initialValues={{
              note: data?.note,
              start_time: moment(data?.start_time),
              end_time: data?.end_time ? moment(data?.end_time) : null,
            }}
          >
            <UserBanForm />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default EditPersonBan;
