import Container from '../../../containers';
import ActivateAlertContainer from '../../../containers/notifications/activate-alert';

const AlertActivateAccountPage = () => (
  <Container
    header
    contentstyle={{ marginTop: '4%' }}
    iconcolor="#B3CA1F"
    background="#FEFEFE"
    showProfile
    showNotifications
    showBackButton
    footer={false}
  >
    <ActivateAlertContainer />
  </Container>
);

export default AlertActivateAccountPage;
