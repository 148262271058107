import moment, { MomentInput } from 'moment';
import { ComponentProps } from 'react';

import ShiftDetailInfo from './ShiftDetailInfo';
import Emoji from '../Emoji';

type ShiftDetailOwnProps = {
  emoji?: string | null,
  shiftName: string,
  startTime: MomentInput,
  endTime: MomentInput,
  spaces: number,
};
type ShiftDetailProps = ShiftDetailOwnProps & Omit<ComponentProps<'div'>, keyof ShiftDetailOwnProps>;

const ShiftDetail = ({ emoji, shiftName, startTime, endTime, spaces }: ShiftDetailProps) => {
  const start = moment(startTime);
  const end = moment(endTime);

  return (
    <div className="bg-secondary-50 h-[110px] rounded-xl p-4">
      <div className="flex justify-between border-b-secondary-200 border-b pb-3 mb-3">
        <div className="flex gap-3 items-center">
          <Emoji emoji={emoji} className="bg-bg w-8 h-8 text-[18px] leading-[18px]" />
          <div>
            <div className="text-xs text-secondary-400 font-medium">Pozice</div>
            <div className="text-sm text-secondary-900 font-medium">{shiftName}</div>
          </div>
        </div>
      </div>
      <ShiftDetailInfo start={start} end={end} spaces={spaces} />
    </div>
  );
};

export default ShiftDetail;
