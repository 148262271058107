import classNames from 'classnames';
import useCollapse from 'react-collapsed';

import { useUser } from '../../../apiClient/ApiContext';

import TyAsyncSelect from '../../inputs/TyAsyncSelect';
import TyDateInput from '../../inputs/TyDateInput';
import TyRadio from '../../inputs/TyRadio';
import TyRadioGroup from '../../inputs/TyRadioGroup';
import TySelectRequirements from '../../inputs/TySelectRequirements';
import styles from '../Components/TyAdvancedSearchCore.module.css';
import { labelsValues } from '../Libs/enums';
import {
  loadPositionOptions,
  loadCompanyOptions,
  loadBranchofficeOptions,
  loadDepartmentOptions,
  loadShiftIdOptions,
  loadRegisteredTymberOptions,
} from '../Libs/loadOptions';

const Basic: React.FC = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true });

  return (
    <div className={styles.basic}>
      <div className={styles.triggerContainer}>
        <div {...getToggleProps()} className={styles.trigger}>
          <h2>Základní parametry</h2>
        </div>

        <div {...getToggleProps()} className={styles.trigger}>

          <div className={classNames(
            styles.triggerIcon,
            { [styles.triggerIconRotated]: isExpanded },
          )}
          />

        </div>
      </div>

      <section {...getCollapseProps()}>

        <div className={styles.row}>

          <div>
            <h3>Datum směny od - do</h3>
            <TyDateInput
              name={labelsValues.shiftDateFrom.name}
              label={labelsValues.shiftDateFrom.label}
              className={styles.tyInputs}
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.shiftDateTo.name}
              label={labelsValues.shiftDateTo.label}
              className={styles.tyInputs}
            />
          </div>

          <div>
            <h3>Čas směny</h3>
            <TyDateInput
              name={labelsValues.shiftTimeFrom.name}
              label={labelsValues.shiftTimeFrom.label}
              className={styles.tyInputs}
              minuteStep={5}
              picker="time"
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.shiftTimeTo.name}
              label={labelsValues.shiftTimeTo.label}
              className={styles.tyInputs}
              minuteStep={5}
              picker="time"
            />
          </div>

        </div>

        <div className={styles.row}>

          <div>
            <h3>Pozice</h3>

            <TyAsyncSelect
              loadOptions={loadPositionOptions}
              name={labelsValues.position.name}
              label={labelsValues.position.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>

        </div>
      </section>
      <br />
    </div>
  );
};

const Other: React.FC = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const user = useUser();

  return (
    <>
      <div className={styles.triggerContainer}>
        <div {...getToggleProps()} className={styles.trigger}>
          <h2>Ostatní parametry</h2>
        </div>

        <div {...getToggleProps()} className={styles.trigger}>

          <div className={classNames(
            styles.triggerIcon,
            { [styles.triggerIconRotated]: isExpanded },
          )}
          />

        </div>
      </div>

      <section {...getCollapseProps()}>

        <div className={styles.row}>

          <div>
            <h3>Firma</h3>

            <TyAsyncSelect
              loadOptions={loadCompanyOptions}
              name={labelsValues.company.name}
              label={labelsValues.company.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>

          <div>
            <h3>Provozovna</h3>

            <TyAsyncSelect
              loadOptions={loadBranchofficeOptions}
              name={labelsValues.branchoffice.name}
              label={labelsValues.branchoffice.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>

          <div>

            <h3>Oddělení</h3>

            <TyAsyncSelect
              loadOptions={loadDepartmentOptions}
              name={labelsValues.department.name}
              label={labelsValues.department.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>
        </div>

        <div className={styles.row}>

          <div>
            <h3>Podmínky</h3>
            <TySelectRequirements
              name={labelsValues.requirements.name}
              label={labelsValues.requirements.label}
              isClearable
              isMulti
              userRole={user.role}
            />
          </div>

          <div>
            <h3>ID objednávky</h3>
            <TyAsyncSelect
              loadOptions={loadShiftIdOptions}
              name={labelsValues.orderId.name}
              label={labelsValues.orderId.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>

          <div>
            <h3>Tymber</h3>

            <TyAsyncSelect
              loadOptions={loadRegisteredTymberOptions}
              name={labelsValues.tymber.name}
              label={labelsValues.tymber.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />

          </div>
        </div>

        <br />
        <div>
          <h3>Stornované objednávky</h3>
          <TyRadioGroup
            className="ty-radio-group"
            name={labelsValues.orderType.name}
          >
            <TyRadio
              value={labelsValues.canceledOrders.name}
              label={labelsValues.canceledOrders.label}
              // defaultChecked
              checked
            />
            <TyRadio
              value={labelsValues.uncanceledOrders.name}
              label={labelsValues.uncanceledOrders.label}
            />
            <TyRadio
              value={labelsValues.onlyCanceledOrders.name}
              label={labelsValues.onlyCanceledOrders.label}
            />
          </TyRadioGroup>
        </div>
      </section>
    </>
  );
};

const Body: React.FC = () => (
  <div className={styles.container}>
    <Basic />
    <Other />
  </div>
);

export default Body;
