import { Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { TableRangePicker, TimeRangePicker } from '../inputs';

const { Option } = Select;

/**
 * @deprecated
 */
export const UnapprovedAttendanceTableHeader = ({
  handleSelectChange,
  branches = [],
  positions = [],
  handleDateChange,
  handleTimeChange,
}) => (
  <div
    style={{
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '20px',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '14px',
          marginBottom: '18px',
          marginTop: '28px',
        }}
      >
        <small
          style={{
            textAlign: 'start',
            marginBottom: '4px',
          }}
        >
          Činnost
        </small>
        <Select
          placeholder="Činnost"
          style={{ width: 222 }}
          onChange={(change) => handleSelectChange(change, 'position')}
        >
          {positions && positions.length > 0
            ? positions.map((item) => (
              <Option key={item.id} value={item}>
                {item}
              </Option>
            ))
            : null}
        </Select>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '14px',
          marginBottom: '18px',
          marginTop: '28px',
        }}
      >
        <small
          style={{
            textAlign: 'start',
            marginBottom: '4px',
          }}
        >
          Provozovna – Oddělení
        </small>
        <Select
          placeholder="Provozovna – Oddělení"
          style={{ width: 222 }}
          onChange={(change) => handleSelectChange(change, 'branch')}
        >
          {branches && branches.length > 0
            ? branches.map((item) => (
              <Option key={item.id} value={item}>
                {item}
              </Option>
            ))
            : null}
        </Select>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '14px',
          marginBottom: '18px',
          marginTop: '28px',
        }}
      >
        <small
          style={{
            textAlign: 'start',
            marginBottom: '4px',
          }}
        >
          Datum
        </small>
        <TableRangePicker
          style={{
            borderRadius: '10px',
          }}
          format="DD.MM.YYYY"
          ranges={{
            'Minulý měsíc': [
              moment().startOf('month').subtract(1, 'months'),
              moment().endOf('month').subtract(1, 'months'),
            ],
            'Minulý týden': [
              moment().startOf('week').subtract(7, 'days'),
              moment().endOf('week').subtract(7, 'days'),
            ],
            'Tento týden': [moment().startOf('week'), moment().endOf('week')],
            Včera: [
              moment().subtract(1, 'days').startOf('day'),
              moment().subtract(1, 'days').endOf('day'),
            ],
            Dnes: [moment().startOf('day'), moment().endOf('day')],
          }}
          onChange={(change) => handleDateChange(change)}
          onDateChange={() => (null)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '14px',
          marginBottom: '18px',
          marginTop: '28px',
        }}
      >
        <small
          style={{
            textAlign: 'start',
            marginBottom: '4px',
          }}
        >
          Omezit čas začátku směn
        </small>
        <TimeRangePicker
          format="HH:mm"
          minuteStep={15}
          style={{
            borderRadius: '10px',
          }}
          onChangeFrom={(change) => handleTimeChange(change, 0)}
          onChangeTo={(change) => handleTimeChange(change, 1)}
          onChange={handleTimeChange}
        />
      </div>
    </div>
  </div>
);
UnapprovedAttendanceTableHeader.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(PropTypes.string),
  positions: PropTypes.arrayOf(PropTypes.string),
  handleTimeChange: PropTypes.func.isRequired,
};

export const TableHeaderControls = ({ children = {} }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'end',
      backgroundColor: '#fff',
      padding: '0px 10px',
    }}
  >
    {children}
  </div>
);
TableHeaderControls.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
};

// TODO: Finish this
export const TableHeader = ({ hasDateRange = false }) => (
  <>
    {hasDateRange ? (
      <div hidden={!hasDateRange} className="ty-title-row">
        <TableRangePicker />
      </div>
    ) : null}
    <div style={{ textAlign: 'center' }}>
      <TableHeaderControls />
    </div>
  </>
);
TableHeader.propTypes = {
  hasDateRange: PropTypes.bool,
};

export const TableHeaderCompanyFilter = ({ handleSelectChange }) => (
  <div
    style={{
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '14px',
          marginBottom: '18px',
          marginTop: '28px',
        }}
      >
        <small
          style={{
            textAlign: 'start',
            marginBottom: '4px',
          }}
        >
          Činnost
        </small>
        <Select
          defaultValue="Třídění balíků"
          style={{ width: 222 }}
          onChange={handleSelectChange}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '14px',
          marginBottom: '18px',
          marginTop: '28px',
        }}
      >
        <small
          style={{
            textAlign: 'start',
            marginBottom: '4px',
          }}
        >
          Provozovna – Oddělení
        </small>
        <Select
          defaultValue="TESCO - DS Liberec - 12007"
          style={{ width: 222 }}
          onChange={handleSelectChange}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '14px',
          marginBottom: '18px',
          marginTop: '28px',
        }}
      >
        <small
          style={{
            textAlign: 'start',
            marginBottom: '4px',
          }}
        >
          Datum a čas
        </small>
        <Select
          defaultValue="Minulý týden"
          style={{ width: 222 }}
          onChange={handleSelectChange}
        />
      </div>
    </div>
  </div>
);
TableHeaderCompanyFilter.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
};
