import moment from 'moment-timezone';
import { ComponentType } from 'react';

import { useTyCalWeekData } from './TyCalWeekContext';

interface TyCalWeekHeaderProps {
  headComp: ComponentType<{ date: string; day: number }>;
}

const TyCalWeekHeader = ({ headComp: HeadComp }: TyCalWeekHeaderProps) => {
  const { startDate } = useTyCalWeekData();
  return (
    <>{
      [...Array(7)].map((_, i) => (
        <HeadComp
          key={moment(startDate).add({ day: i }).toISOString()}
          date={moment(startDate).add({ day: i }).toISOString()}
          day={i + 1}
        />
      ))
    }
    </>
  );
}

export default TyCalWeekHeader;
