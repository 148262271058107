import moment from 'moment';
import { MomentInput } from 'moment-timezone';

const timezone = 'Europe/Prague';

export const areDatesInSameYearAndMonth = (date1: MomentInput, date2: MomentInput) =>
  date1 && date2 && moment(date1).tz(timezone).isSame(moment(date2).tz(timezone), 'month');

export const isDateInCurrentYearAndMonth = (date: MomentInput) =>
  areDatesInSameYearAndMonth(date, moment());

/**
 * Check if today's date is before the 6th day (inclusive) of the next month after the shift.
 * 
 * Example:
 *  * Shift date is 23.5.2024, today is 3.6.2024, function returns true.
 *  * Shift date is 23.5.2024, today is 8.6.2024, function returns false.
 */
export const isDateBeforeSixthOfNextMonth = (date: MomentInput) =>
  moment().isBefore(moment(date).tz(timezone).endOf('month').add(6, 'd'));
