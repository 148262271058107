import { CompanyBlockedUserData } from '@tymbe/schema/company-blocked-user.interface';
import moment from 'moment';

import { BlockReasonDisplay } from '../../utils/enums';

interface CompanyBlocksTooltipProps {
  blockList?: CompanyBlockedUserData[];
}

const CompanyBlocksTooltip = ({ blockList = [] }: CompanyBlocksTooltipProps) => (
  <div className="w-52 max-h-64 overflow-scroll">
    {blockList.map((block) => (
      <div key={block.company?.id}>
        <div className="ty-h5 text-align-start">
          {block.company?.name}
        </div>
        <div className="font-semibold text-align-start">
          {block.branchoffice?.name}
        </div>
        <div>
          {moment(block.created_at).format('DD.MM.YYYY')}
        </div>
        <div>
          {BlockReasonDisplay[block.reason]}
        </div>
      </div>
    ))}
  </div>
);

export default CompanyBlocksTooltip;
