import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const loadDepartmentsThunk = createAsyncThunk('company/companyDetailDepartment', async ({ id, query }) => feathersClient.service('branchoffice').find({
  query: {
    'branchoffice.company_id': id,
    $eager: '[person, address, parent, contactPerson]',
    ...query,
  },
}));

const loadDepartmentReducer = {
  [loadDepartmentsThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadDepartmentsThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [loadDepartmentsThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

export const removeDepartmentThunk = createAsyncThunk(
  'department/remove',
  async (id, params) => feathersClient.service('branchoffice').remove(id, params),
);

const removeDepartmentReducer = {
  [removeDepartmentThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removeDepartmentThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [removeDepartmentThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: [...state.data || [], ...action.payload.data || []],
  }),
};

const departmentSlice = createSlice({
  name: 'departmentContainer',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    updateRecord(state, action) {
      const data = state.data.map((x) => (x.id !== action.payload.id ? x : action.payload.record));
      return { ...state, data };
    },
  },

  extraReducers: {
    ...loadDepartmentReducer,
    ...removeDepartmentReducer,
  },
});

export const selectDepartmentForCompany = (state) => state.data;

export default departmentSlice.reducer;
export const { actions } = departmentSlice;
