import classNames from 'classnames';
import { ComponentProps } from 'react';

import CheckIcon from './icons/CheckIcon';

type FillPillOwnProps = {
  current: number
  total: number
};

type FillPillProps = FillPillOwnProps & Omit<ComponentProps<'div'>, keyof FillPillOwnProps>;

const FillPill = ({ current, total, ...rest }: FillPillProps) => {
  const isFull = current === total;
  return (
    <div
      className={
        classNames(
          'h-[18px] flex justify-center  items-center rounded-full',
          { 'w-[34px] bg-secondary-50': !isFull },
          { 'w-[35px] bg-primary-400': isFull },
        )
      }
      {...rest}
    >
      <span className={classNames(
        'text-xs font-[400] flex justify-center items-center',
        { 'text-secondary-500': !isFull },
        { 'text-bg': isFull },
      )}
      >
        {isFull ? <>{total} <CheckIcon className="translate-x-[2px] w-[14px] h-[14px] fill-bg" /></> : `${current}/${total}`}
      </span>
    </div>
  );
};

export default FillPill;
