import { Query } from '@feathersjs/feathers';
import { useCallback } from 'react';
import { GroupBase, OptionsOrGroups } from 'react-select';

import TyAsyncSelect, { TyAsyncSelectProps } from './TyAsyncSelect';
import feathersClient from '../../apiClient';
import { BranchofficeData } from '../../types/TymbeApi';

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';
type OwnProps = {
  companyId?: number | string;
  parentId?: number | string;
  type?: 'department' | 'branchoffice';
};
type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, BranchofficeData, GroupBase<BranchofficeData>, void, boolean>;
type TySelectBranchofficeProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps> & OwnProps;

const loadBranchofficeOptions = (
  company_id?: number | string,
  parent_id?: number | string,
  defaultOptionsCount?: number,
  type?: 'department' | 'branchoffice',
) => async (
  search: string,
  options: OptionsOrGroups<BranchofficeData, GroupBase<BranchofficeData>>,
) => {
  const iLike = { $ilike: `%${search}%` };

  const query: Query = {
    company_id,
    parent_id,
    $skip: options.length - (defaultOptionsCount || 0),
  };

  if (type) {
    query.$eager = '[parent]';
    switch (type) {
      case 'department':
        query.parent_id = query.parent_id ?? { $null: false };
        break;
      case 'branchoffice':
        query.parent_id = { $null: true };
        break;
      default:
    }
  }

  if (search !== '' && !Number.isNaN(Number(search))) {
    query.id = Number(search);
  } else {
    query.$or = [{ name: iLike }, { display_name: iLike }];
  }

  const resp = await feathersClient.service('branchoffice').find({
    query,
  });

  return {
    options: resp.data,
    hasMore: resp.total > options.length + resp.data.length,
  };
};

const TySelectBranchoffice = <Fields extends object>({
  companyId = undefined,
  parentId = undefined,
  debounceTimeout = 1000,
  defaultOptions = [],
  type = undefined,
  ...props
}: TySelectBranchofficeProps<Fields>) => {
  const defaultOptionsCount = Array.isArray(defaultOptions) ? defaultOptions.length : 0;
  const loadOptions = useCallback(
    () => loadBranchofficeOptions(companyId, parentId, defaultOptionsCount, type),
    [companyId, parentId, defaultOptionsCount, type],
  );

  const getOptionLabel = (option: Partial<BranchofficeData>) =>
    (option.parent?.name
      ? `[${option.id}] ${option.parent.name} - ${option.name}`
      : `[${option.id}] ${option.name}`);

  return (
    <TyAsyncSelect
      {...props}
      defaultOptions={defaultOptions}
      debounceTimeout={debounceTimeout}
      loadOptions={loadOptions()}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => String(option.id)}
    />
  );
};

export default TySelectBranchoffice as typeof TySelectBranchoffice;
