import { ComponentProps, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import AddPersonUtility from './AddPersonUtility';
import DeletePersonUtility from './DeletePersonUtility';
import EditPersonUtility from './EditPersonUtility';
import RestorePersonUtility from './RestorePersonUtility';
import apiClient from '../../../apiClient';
import { defaultOnError, getPaginatedResponse } from '../../../apiClient/utils';
import Card from '../../../components/Layout/Card';
import Spinner from '../../../components/Spinner';
import Switch from '../../../components/Switch';
import { Table, TableCell, TableHead, TablePagination, TableRow } from '../../../components/Table';
import { Pagination } from '../../../types/TymbeApi';

type OnPageChange = ComponentProps<typeof TablePagination>['onChangePage'];

const UserUtility = () => {
  const { id: personId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<Pagination>({
    $skip: 0,
    $limit: undefined,
    $modify: searchParams.get('$modify') ?? undefined,
  });

  const { data, isLoading } = useQuery(
    ['person-utility', Number(personId), pagination] as [string, string | number, Pagination],
    async ({ queryKey: [, person_id, page] }) => apiClient.service('person-utility').find({
      query: {
        ...page,
        person_id,
        $sort: { company_id: 1, id: 1 },
        $eager: '[utility, company]',
      },
    }),
    {
      keepPreviousData: true,
      enabled: !!personId,
      onError: defaultOnError,
    },
  );

  const onPageChange: OnPageChange = useCallback((pageStart, _pageEnd, pageSize) => {
    setPagination((prevState) => ({
      ...prevState,
      $skip: pageStart,
      $limit: pageSize,
    }));
  }, []);

  const onSwitchChange = (deleted: boolean) => {
    if (deleted) {
      setSearchParams((oldParams) => {
        oldParams.set('$modify', 'deleted');
        return oldParams;
      });
    } else {
      setSearchParams((oldParams) => {
        oldParams.delete('$modify');
        return oldParams;
      });
    }
    setPagination((prevState) => ({
      ...prevState,
      $modify: deleted ? 'deleted' : undefined,
    }));
  };

  const personUtilities = getPaginatedResponse(data);

  if (!personId) {
    return null;
  }

  return (
    <Card className="gap-4 flex flex-col">
      <div className="flex gap-4 justify-end">
        <Switch onChange={onSwitchChange} checked={!!pagination.$modify} />
        <AddPersonUtility personId={personId} />
      </div>
      <Table className="bg-bg table-auto">
        <TableHead>
          <TableRow>
            <TableCell head className="bg-fg-100 max-w-min">Firma</TableCell>
            <TableCell head className="bg-fg-100">Jméno</TableCell>
            <TableCell head className="bg-fg-100">Cena</TableCell>
            <TableCell head className="bg-fg-100">Popis</TableCell>
            <TableCell head className="bg-fg-100">Poznámka</TableCell>
            <TableCell head className="bg-fg-100" />
          </TableRow>
        </TableHead>
        <tbody className="relative">
          <Spinner show={isLoading} className="p-1" absolute />
          {!personUtilities.total && (
            <TableRow><TableCell colSpan={6} className="text-center">Žádné výsledky</TableCell></TableRow>
          )}
          {personUtilities.data.map((utility) => (
            <TableRow key={utility.id}>
              <TableCell align="right">{utility.company?.name}</TableCell>
              <TableCell>[{utility.utility?.id}] {utility.utility?.name}</TableCell>
              <TableCell align="right">{Number(utility.price).toFixed(2)} Kč</TableCell>
              <TableCell>{utility.utility?.description}</TableCell>
              <TableCell>{utility.utility?.note}</TableCell>
              <TableCell>
                <div className="flex flex-row justify-end gap-1">
                  {utility.deleted_at
                    ? <RestorePersonUtility id={utility.id} />
                    : <DeletePersonUtility id={utility.id} />}
                  {utility.deleted_at ? null : <EditPersonUtility initialData={utility} />}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>

      <TablePagination rowsCount={personUtilities.total} onChangePage={onPageChange} />
    </Card>
  );
};

export default UserUtility;
