import iscoJobClassifications from '../assets/iscoJobClassifications.json';
import naceJobClassifications from '../assets/naceJobClassifications.json';

export interface JobClassificationProps {
  type: 'cz_nace' | 'cz_isco';
  id: string;
}

export interface Classification {
  id: string;
  name: string;
  parent?: string;
}

export interface ClassificationTree {
  [id: string]: {
    name: string;
    children?: ClassificationTree;
  }
}

export interface FlattenedClassificationTree {
  [id: string]: Classification;
}

function flattenClassificationTree(classification: ClassificationTree, parent?: string): FlattenedClassificationTree {
  let result: FlattenedClassificationTree = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [id, { name, children }] of Object.entries(classification)) {
    result[id] = {
      id,
      name,
      parent,
    };
    if (children) {
      result = {
        ...result,
        ...flattenClassificationTree(children, id),
      };
    }
  }

  return result;
}

export const flattenedIsco = flattenClassificationTree(iscoJobClassifications);
export const flattenedNace = flattenClassificationTree(naceJobClassifications);

export const jobClassification = ({ id, type }: JobClassificationProps) => {
  const source = type === 'cz_nace' ? flattenedNace : flattenedIsco;
  return source[id]?.name;
};

export default jobClassification;
