import { SVGProps } from 'react';

type EditIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const EditIcon = (props: EditIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.99902 17.4613V20.5013C2.99902 20.7813 3.21902 21.0013 3.49902 21.0013H6.53902C6.66902 21.0013 6.79902 20.9513 6.88902 20.8513L17.809 9.94128L14.059 6.19128L3.14902 17.1013C3.04902 17.2013 2.99902 17.3213 2.99902 17.4613Z" />
    <path d="M20.709 5.63128L18.369 3.29128C17.979 2.90128 17.349 2.90128 16.959 3.29128L15.129 5.12128L18.879 8.87128L20.709 7.04128C21.099 6.65128 21.099 6.02128 20.709 5.63128Z" />
  </svg>
);

export default EditIcon;
