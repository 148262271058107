import { BranchofficeFormValues } from './components/BranchofficeForm';
import { BranchofficeType } from '../../../../utils/enums';

const mapFormValuesToBranchoffice = (values : BranchofficeFormValues, companyId: number) => ({
  name: values.name,
  display_name: values.display_name,
  instruction: values.instruction,
  company_id: companyId,
  parent_id: null,
  contact_person: values.contactPerson?.id,
  type: BranchofficeType.BRANCH,
  address: {
    ...values.address,
    country: values.countryOption.value,
  },
});

export default mapFormValuesToBranchoffice;
