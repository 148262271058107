import { Classification, flattenedIsco } from '@tymbe/job-classifications/job-clasification';
import { AGENCY_ALLOWED_CZ_ISCO_CODES } from '@tymbe/schema/constants';
import { CompanyFulfillmentType } from '@tymbe/schema/enums';
import { useState } from 'react';
import { GroupBase } from 'react-select';

import TyAsyncSelect, { TyAsyncSelectProps } from './TyAsyncSelect';

const allowedIsco = (isco: Classification) =>
  AGENCY_ALLOWED_CZ_ISCO_CODES.some((code) => isco?.id?.startsWith(String(code)));

const getOptionLabel = (option: Partial<Classification>) => `[${option.id}] ${option.name}`;

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';
type OwnProps = {
  fulfillmentType?: CompanyFulfillmentType;
};

type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, Classification, GroupBase<Classification>, void, boolean>;
type TySelectIscoClassificationProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps> & OwnProps;

const TySelectIscoClassification = <Fields extends object>({
  fulfillmentType,
  ...props
}: TySelectIscoClassificationProps<Fields>) => {
  const dontFilterIsco = fulfillmentType && fulfillmentType !== CompanyFulfillmentType.AGENCY;

  const allOptions = dontFilterIsco ? Object.values(flattenedIsco) : Object.values(flattenedIsco).filter(allowedIsco);

  const [filteredOptions, setFilteredOptions] = useState<Classification[]>(allOptions);

  return (
    <TyAsyncSelect
      {...props}
      loadOptions={() => ({
        options: filteredOptions.slice(0, 25),
        hasMore: false,
      })}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => String(option.id)}
      onInputChange={(inputValue) => {
        setFilteredOptions(allOptions.filter((option) => (
          getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase())
        )));
      }}
    />
  );
};

export default TySelectIscoClassification as typeof TySelectIscoClassification;
