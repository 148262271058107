import { SVGProps } from 'react';

import { PerkId } from '../../../../types/TymbeApi';
import { getPerkNameById } from '../../../../utils';

type WomanOnlyIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const WomanOnlyIcon = (props: WomanOnlyIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{getPerkNameById(PerkId.WOMAN_ONLY)}</title>
    <path d="M12 5.5C13.93 5.5 15.5 7.07 15.5 9C15.5 10.93 13.93 12.5 12 12.5C10.07 12.5 8.5 10.93 8.5 9C8.5 7.07 10.07 5.5 12 5.5ZM13 14.41C15.56 13.94 17.5 11.7 17.5 9C17.5 5.96 15.04 3.5 12 3.5C8.96 3.5 6.5 5.96 6.5 9C6.5 11.7 8.44 13.94 11 14.41V16.5H10C9.45 16.5 9 16.95 9 17.5C9 18.05 9.45 18.5 10 18.5H11V19.5C11 20.05 11.45 20.5 12 20.5C12.55 20.5 13 20.05 13 19.5V18.5H14C14.55 18.5 15 18.05 15 17.5C15 16.95 14.55 16.5 14 16.5H13V14.41Z" />
  </svg>
);

export default WomanOnlyIcon;
