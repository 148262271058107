const APPROVED_ATTENDANCE = [
  {
    title: 'Provozovna',
    dataIndex: 'branchoffice_branch',
    align: 'left',
    sorter: (a, b) => a.branchoffice_name.length - b.branchoffice_name.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Oddělení',
    dataIndex: 'branchoffice_department',
    align: 'left',
    sorter: (a, b) => a.branchoffice_department.length - b.branchoffice_department.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Objednávka',
    dataIndex: 'shift_name',
    align: 'left',
    sorter: (a, b) => a.shift_name.length - b.shift_name.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Jméno',
    dataIndex: 'person_firstname',
    align: 'left',
    sorter: (a, b) => a.person_firstname.length - b.person_firstname.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Přijmení',
    dataIndex: 'person_lastname',
    align: 'left',
    sorter: (a, b) => a.person_lastname.length - b.person_lastname.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Datum',
    dataIndex: 'date',
    align: 'left',
    sorter: (a, b) => a.date.length - b.date.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas od',
    dataIndex: 'start_time',
    align: 'left',
    sorter: (a, b) => a.start_time.length - b.start_time.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas do',
    dataIndex: 'end_time',
    align: 'left',
    sorter: (a, b) => a.end_time.length - b.end_time.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Délka směny',
    dataIndex: 'duration',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Interní označení',
    dataIndex: 'id',
    align: 'left',
    sorter: (a, b) => a.id.length - b.id.length,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
];
const REPORT = [
  {
    title: 'Datum',
    dataIndex: 'date',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas od',
    dataIndex: 'start_time',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas do',
    dataIndex: 'end_time',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Provozovna',
    dataIndex: 'branchoffice_branch',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Oddělení',
    dataIndex: 'branchoffice_department',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'ID Tymbera',
    dataIndex: 'person_id',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Přijmení',
    dataIndex: 'person_lastname',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Jméno',
    dataIndex: 'person_firstname',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Datum narození',
    dataIndex: 'person_dob',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
];
const ORDERS = [
  {
    title: 'Den',
    dataIndex: 'day',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Datum',
    dataIndex: 'date',
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas od',
    dataIndex: 'from_time',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas do',
    dataIndex: 'to_time',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Pracovní pozice',
    dataIndex: 'name',
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Obsazeno',
    dataIndex: 'filled_orders_count',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Neobsazeno',
    dataIndex: 'unfilled_orders_count',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Provozovna',
    dataIndex: 'branchoffice_branch',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Oddělení',
    dataIndex: 'branchoffice_department',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
];
const ADMIN_SHIFT_APPLICATION = [
  {
    title: 'ID aplikace',
    dataIndex: 'id',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Firma',
    dataIndex: 'shift_company_name',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Provozovna',
    dataIndex: 'shift_branchoffice_name',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Nástup od',
    dataIndex: 'shift_start',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Nástup do',
    dataIndex: 'shift_end',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Cena základ',
    dataIndex: 'payment_base',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Kredity',
    dataIndex: 'credits',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Pozice',
    dataIndex: 'shift_name',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Jméno',
    dataIndex: 'person_firstname',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Příjmení',
    dataIndex: 'person_lastname',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Datum narození',
    dataIndex: 'person_personData_birthdate',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Mobil',
    dataIndex: 'person_mobile',
    render: (value) => (value || ' - '),
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
];
const CEB_PAYMENT_CONFIRMATION = [
  { title: 'A', dataIndex: 'CEB_11' },
  { title: 'B', dataIndex: 'CEB_ACCOUNT' },
  { title: 'C', dataIndex: 'CEB_TODAY' },
  { title: 'D', dataIndex: 'CEB_PAYOUT' },
  { title: 'E', dataIndex: 'application_person_personData_bank_account' },
  { title: 'F', dataIndex: 'application_person_personData_bank_number' },
  { title: 'G', dataIndex: 'CEB_BANK' },
  { title: 'H', dataIndex: 'CEB_COMPOUND_NUMBER' },
  { title: 'I', dataIndex: '' },
  { title: 'J', dataIndex: 'CEB_APPLICATION_ID' },
  { title: 'K', dataIndex: '' },
  { title: 'L', dataIndex: 'CEB_PAYMENT_NOTE' },
  { title: 'M', dataIndex: '' },
  { title: 'N', dataIndex: '' },
  { title: 'O', dataIndex: '' },
  { title: 'P', dataIndex: '' },
  { title: 'Q', dataIndex: 'application_person_id' },
];
const PAYMENT_CONFIRMATION = [
  { title: 'ID Směny', dataIndex: 'application_shift_id' },
  { title: 'ID Tymber', dataIndex: 'application_person_id' },
  { title: 'Jméno', dataIndex: 'application_person_first_name' },
  { title: 'Příjmení', dataIndex: 'application_person_last_name' },
  { title: 'Číslo účtu', dataIndex: 'application_person_personData_bank_account' },
  { title: 'Kód banky', dataIndex: 'application_person_personData_bank_number' },
  { title: 'Firma ', dataIndex: 'application_shift_company_name' },
  { title: 'Provozovna', dataIndex: 'application_shift_branchoffice_name' },
  { title: 'K vyplacení', dataIndex: 'CEB_PAYOUT' },
  { title: 'Konstatní symbol', dataIndex: 'application_person_personData_constant_variable' },
  { title: 'Datumočas konce směny', dataIndex: '' },
  { title: 'Datumočas potvrzení docházky', dataIndex: '' },
  { title: 'Datumočas požadavku na vyplacení', dataIndex: '' },
  { title: 'Datumočas zpracování', dataIndex: 'attendance_confirmation' },
];
const TABLE_DEFINITIONS = {
  APPROVED_ATTENDANCE,
  UNAPPROVED_ATTENDANCE: null,
  REPORT,
  ORDERS,
  CEB_PAYMENT_CONFIRMATION,
  PAYMENT_CONFIRMATION,
  ADMIN_SHIFT_APPLICATION,
};
export default TABLE_DEFINITIONS;
