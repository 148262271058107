import { ReactElement } from 'react';
import { GroupBase } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';
import { ComponentProps, UseAsyncPaginateParams, withAsyncPaginate } from 'react-select-async-paginate';

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean,
> =
  & CreatableProps<OptionType, IsMulti, Group>
  & UseAsyncPaginateParams<OptionType, Group, Additional>
  & ComponentProps<OptionType, Group, IsMulti>;

type CreatableAsyncPaginateType =
  <Option, Group extends GroupBase<Option>, Additional, IsMulti extends boolean>
  (props: AsyncPaginateCreatableProps<Option, Group, Additional, IsMulti>) => ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as CreatableAsyncPaginateType;

export default CreatableAsyncPaginate;
