import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { SubmitButton } from '../../components/buttons';
import { FixedGrid, ResponsiveGrid, SemiColumn } from '../../components/grids';
import { UserIcon } from '../../components/icons';
import Illustration from '../../components/illustration';
import { CoinFlip } from '../../components/illustrations';
import { PasswordFormInput, SimpleFormInput, SimpleFormItem } from '../../components/inputs';
import LocaleSwitcher from '../../components/LocaleSwitcher';
import { SimpleHeading3Text } from '../../components/texts';

const CreateContainer = ({ onFinish, onFinishFailed }) => {
  const intl = useIntl();

  return (
    <>
      <Illustration svg={<CoinFlip />} />
      <ResponsiveGrid xs={24} xl={8}>
        <Row className="ty-page-bottom" align="center">
          <FixedGrid>
            <div style={{ padding: '7px 0 12px 0' }}>
              <SimpleHeading3Text>
                {intl.formatMessage({ defaultMessage: 'Tady si založíš nový účet' })}
              </SimpleHeading3Text>
            </div>
          </FixedGrid>
          <Col span={21}>
            <Form
              hideRequiredMark
              name="signup_form"
              initialValues={{
                remember: true,
              }}
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={8} justify="space-around" align="middle">
                <SemiColumn>
                  <SimpleFormInput
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          defaultMessage: 'Prosím vložte Vaše Jméno',
                        }),
                      },
                    ]}
                    prefix={false}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Jméno',
                    })}
                  />
                </SemiColumn>

                <SemiColumn>
                  <SimpleFormInput
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          defaultMessage: 'Prosím vložte Vaše Příjmení',
                        }),
                      },
                    ]}
                    prefix={false}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Příjmení',
                    })}
                  />
                </SemiColumn>
              </Row>

              <SimpleFormInput
                name="email"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      defaultMessage: 'Prosím vložte Váš E-mail',
                    }),
                  },
                  {
                    type: 'email',
                    message: intl.formatMessage({
                      defaultMessage: 'Prosím vložte platný E-mail',
                    }),
                  },
                ]}
                prefix={false}
                placeholder={intl.formatMessage({
                  defaultMessage: 'E-mail',
                })}
              />

              <PasswordFormInput
                name="password"
                autoComplete="off"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Heslo',
                })}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      defaultMessage: 'Prosím vložte Vaše Heslo',
                    }),
                  },
                  { min: 6, message: intl.formatMessage({ defaultMessage: 'Minimální délka hesla je 6 znaků' }) },
                ]}
              />

              <SimpleFormItem>
                <Row
                  gutter={[16, 24]}
                  justify="center"
                  align="middle"
                  style={{ paddingBottom: '15px', paddingTop: '8px' }}
                >
                  <Col
                    style={{ textAlign: 'center', marginBottom: '5px' }}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                  >
                    <SubmitButton buttontext={intl.formatMessage({ defaultMessage: 'Registrovat se' })} />
                  </Col>
                  <Col
                    style={{ textAlign: 'center' }}
                    xs={24}
                    sm={24}
                    md={16}
                    lg={14}
                    xl={14}
                  >
                    <Row justify="start" style={{ textAlign: 'center' }}>
                      <Col span="2" offset="8">
                        <UserIcon iconcolor="#B3CA1F" />
                      </Col>
                      <Col offset="0">
                        <Link
                          style={{
                            color: '#202020',
                            fontWeight: '500',
                          }}
                          to="/signin"
                        >
                          {intl.formatMessage({ defaultMessage: 'Přihlásit se' })}

                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col>
                    <LocaleSwitcher />
                  </Col>
                </Row>
              </SimpleFormItem>
            </Form>
          </Col>
        </Row>
      </ResponsiveGrid>
    </>
  );
};

CreateContainer.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
};
export default CreateContainer;
