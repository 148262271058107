interface ArrowRightAltProps {
  height?: number,
  width?: number,
}

const ArrowRightAltIcon = ({ height = 30, width = 30 }: ArrowRightAltProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 -960 960 960" width={width}>
    <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
  </svg>
);

export default ArrowRightAltIcon;
