import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import CrossIcon from './icons/CrossIcon';
import ShiftAttendancePage from '../shiftAttendance.page';

const ShiftDetailModal = () => {
  const navigate = useNavigate();

  const onModalClose = () => {
    navigate(-1);
  };

  return (
    <Modal
      isOpen
      onRequestClose={onModalClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      contentLabel="My dialog"
      className="fixed top-4 right-4 bottom-4 left-48 bg-bg p-7 rounded-lg overflow-auto drop-shadow-lg"
      overlayClassName="bg-overlay fixed inset-0 z-modal"
    >
      <CrossIcon onClick={onModalClose} className="absolute m-10 top-0 right-0 cursor-pointer" />
      <ShiftAttendancePage />
    </Modal>
  );
};

export default ShiftDetailModal;
