import { ApplicationData } from '@tymbe/schema/application.interface';
import { ApplicationState } from '@tymbe/schema/enums';
import { ManShiftData } from '@tymbe/schema/man-shift.interface';

import ShiftState from '../../../pages-legacy/user/shifts/ShiftUtils/ShiftStateEnum';
import shiftStyleSettings from '../../../pages-legacy/user/shifts/ShiftUtils/ShiftStyleSettings';

export interface OrdersTableCategory {
  title: string;
  titleColor: string;
  data?: ApplicationData[];
}

type AppCategories = ApplicationState | 'Invitations';

export type OrderTableCategoryTuple = {
  [key in AppCategories]?: OrdersTableCategory;
};

const createBlankCategory = (config: OrdersTableCategory) => ({
  ...config,
  data: [],
});

export const mapOrderTableExpandedCategories = (manShifts: ManShiftData[]) => {
  const orderTableCategories: OrderTableCategoryTuple = {
    [ApplicationState.CONFIRMED]: createBlankCategory({
      title: 'Přihlášení',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_CONFIRMED].textColour,
    }),
    Invitations: createBlankCategory({
      title: 'Zatím nepřijaté pozvánky',
      titleColor: shiftStyleSettings[ShiftState.INVITATION].textColour,
    }),
    [ApplicationState.CANCELED_EARLY]: createBlankCategory({
      title: 'Zrušeno v termínu',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_CANCELED_EARLY].textColour,
    }),
    [ApplicationState.REJECTED]: createBlankCategory({
      title: 'Odmítnuté pozvánky',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_REJECTED].textColour,
    }),
    [ApplicationState.CANCELED_LATE]: createBlankCategory({
      title: 'Zrušeno po termínu',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_CANCELED_LATE].textColour,
    }),
    [ApplicationState.SYSTEM_CANCELED]: createBlankCategory({
      title: 'Zrušeno systémem',
      titleColor: shiftStyleSettings[ShiftState.APPLICATION_SYSTEM_CANCELED].textColour,
    }),
  };

  manShifts.forEach((ms) => {
    const app = ms.application ? [ms.application] : [];
    const appArr = [...app, ...(ms.canceledApplication || [])];
    appArr
      .sort((a, b) => a.person_id - b.person_id)
      .forEach((curr, index) => {
        const prev = index > 0 ? appArr[index - 1] : undefined;
        // Skip deleted application -> not the most recent
        if (curr.state !== ApplicationState.REJECTED && curr.deleted_at) {
          return;
        }
        // Always add these application states -> if present they are the most recent
        if (
          [
            ApplicationState.CONFIRMED,
            ApplicationState.REJECTED,
            null,
          ].includes(curr.state)
        ) {
          orderTableCategories[curr.state || 'Invitations']?.data?.push(curr);
          return;
        }
        // Skip if the user already has an application in any of the categories
        const isAlreadyIncluded =
          orderTableCategories[prev?.state || 'Invitations']?.data?.find((appl) => appl.person_id === curr.person_id);
        if (isAlreadyIncluded) return;

        orderTableCategories[curr.state || 'Invitations']?.data?.push(curr);
      });
  });

  // Removing duplicates from orderTableCategories[appState].data
  Object.keys(orderTableCategories).forEach((state) => {
    const category = orderTableCategories[state as AppCategories];
    if (!category) return;
    category.data = category.data?.filter(
      ({ id }: ApplicationData, index: number) =>
        category.data?.findIndex((app: ApplicationData) => app.id === id)
        === index,
    );
  });

  return orderTableCategories;
};
