export const columnHeaders = [
  {
    value: '',
    label: '',
    sortable: false,
  },
  {
    value: 'application.id',
    label: 'ID přihlášení',
    sortable: true,
  },
  {
    value: 'shift:company.name',
    label: 'Firma',
    sortable: true,
  },
  {
    value: 'shift:branchoffice.name',
    label: 'Provozovna',
    sortable: true,
  },
  {
    value: 'shift.name',
    label: 'Pozice',
    sortable: true,
  },
  {
    value: 'shift.start_time',
    label: 'Čas',
    sortable: true,
  },
  {
    value: 'person.last_name',
    label: 'Tymber',
    sortable: true,
  },
  {
    value: 'application.state',
    label: 'Stav',
    sortable: true,
  },
];
