/// <reference types="pdfkit" />

import ImageOption = PDFKit.Mixins.ImageOption;

export interface JsonParagraph {
  text: string,
  fontSize?: number,
}

export interface JsonListItem {
  content: string | never,
  items?: JsonListItem[],
}

export interface JsonTable {
  withHeadings: boolean,
  content: string[][],
}

export interface JsonList {
  style: JsonListStyle,
  items: JsonListItem[],
}

export interface JsonHeader {
  level: number,
  text: string,
}

export interface JsonImage {
  options?: ImageOption,
  x?: number,
  y?: number,
  caption?: string,
  file: {
    url: string | Buffer,
  },
}

export interface JsonVariable {
  name: string,
}

export interface JsonColumns {
  cols: JsonDocument[],
}

export interface JsonBlock {
  id: string,
  hasVariables?: boolean,
  type: JsonBlockTypeEnum,
  data: JsonBlockType,
  tunes?: JsonTunes,
}

export type JsonBlockType = JsonParagraph | JsonList | JsonHeader | JsonTable | JsonImage | JsonVariable | JsonColumns;

/**
 * Represents tuning parameters for a JSON-based structure for generating PDF.
 *
 * @interface JsonTunes
 */
export interface JsonTunes {
  /**
   * Specifies a text variant for customization purposes.
   * The content and structure of this property are not strictly defined and can vary based on implementation.
   *
   * @type {unknown}
   * @memberof JsonTunes
   */
  textVariant?: unknown;

  /**
   * Allows tuning the alignment of elements within the editor.
   *
   * @type {{ alignment?: JsonAlignment }}
   * @memberof JsonTunes
   */
  alignmentTune?: { alignment?: JsonAlignment };

  /**
   * Specifies the width of the block. If the content of block exceeds the width, it will wrap.
   *
   * @type {{ blockWidth?: number }}
   * @memberof JsonTunes
   */
  blockWidth?: number,

  /**
   * Defines the position of elements within the editor. Not yet supported by EditorJS.
   * Undefined value of the coordinates (X or Y) is treated as "auto"
   *
   * @type {{
   *     x?: number,
   *     y?: number,
   *   }}
   * @memberof JsonTunes
   */
  position?: {
    x?: number;
    y?: number;
  };
}

export const enum JsonBlockTypeEnum {
  DELIMITER = 'delimiter',
  HEADER = 'header',
  IMAGE = 'image',
  LIST = 'list',
  PARAGRAPH = 'paragraph',
  TABLE = 'table',
  EMPTY_ROW = 'empty_row',
  VARIABLE = 'variable',
  COLUMNS = 'columns',

  // Block type that dumps internal states into a text block
  _DEBUG = '_debug',
}

export const enum JsonListStyle {
  ORDERED = 'ordered',
  UNORDERED = 'unordered',
  ALPHABET = 'alphabet',
  NUMBERALPHA = 'numberalpha',
  ALPHAROMAN = 'alpharoman',
  ROMAN = 'roman',
}

export const enum JsonAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

export const enum JsonLayout {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export interface JsonDocument {
  time: number,
  version: string,
  blocks: JsonBlock[],
}

export interface JsonDocumentOptions {
  layout?: JsonLayout,
  fontSize?: number,
}
