import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const loadApplicationsThunk = createAsyncThunk(
  'admin/loadApplication',
  (
    query = {
      query: { $eager: '[person.[personData, contact], shift.company]' },
    },
  ) => feathersClient.service('application').find(query),
);

const loadApplicationsReducer = {
  [loadApplicationsThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadApplicationsThunk.rejected]: (state, action) => ({
    ...state,
    loading: true,
    error: action.error.message,
    data: null,
  }),
  [loadApplicationsThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: true,
    error: null,
    data: action.payload,
  }),
};

export const removeApplicationThunk = createAsyncThunk(
  'admin/removeApplication',
  (id) => feathersClient.service('application').remove(id),
);

const removeApplicationReducer = {
  [removeApplicationThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removeApplicationThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [removeApplicationThunk.fulfilled]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
};

export const removePaymentRequestThunk = createAsyncThunk(
  'admin/removePaymentRequest',
  (id) => feathersClient.service('payment-request').remove(id),
);

const removePaymentRequestReducer = {
  [removePaymentRequestThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removePaymentRequestThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [removePaymentRequestThunk.fulfilled]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
};

export const removeSurveyAnswerThunk = createAsyncThunk(
  'admin/removeSurveyAnswer',
  (id) => feathersClient.service('survey-answer').remove(null, {
    query: {
      attendance_id: id,
    },
  }),
);

const removeSurveyAnswerReducer = {
  [removeSurveyAnswerThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removeSurveyAnswerThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [removeSurveyAnswerThunk.fulfilled]: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
};

const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    loading: null,
    error: false,
    data: null,
  },
  reducers: {
    updateRecord(state, action) {
      const data = state.data.data.map((x) => (x.id !== action.payload.id ? x : action.payload.record));
      return { ...state, data: { ...state.data, data } };
    },
  },
  extraReducers: {
    ...loadApplicationsReducer,
    ...removeApplicationReducer,
    ...removePaymentRequestReducer,
    ...removeSurveyAnswerReducer,
  },
});

export default applicationSlice.reducer;
export const { actions } = applicationSlice;

export const selectData = (state) => state.data;
