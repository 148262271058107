import { UtilityData } from '@tymbe/schema/utility.interface';
import moment, { Moment } from 'moment';

import { DocumentTypeData, PerkData, PersonData, ShiftTemplateData } from '../../../../types/TymbeApi';
import { calculateWorkTime } from '../../../../utils/time';
import StarEmptyIcon from '../icons/StarEmpty';
import StarFullIcon from '../icons/StarFull';
import StarHalfIcon from '../icons/StarHalf';

type Option = PerkData | DocumentTypeData;

export type ShiftsToAdd = {
  date: Moment;
  startTime: Moment;
  endTime: Moment;
  spaces: number;
  shiftTemplate: ShiftTemplateData;
  requirements: Option[] | null;
  utilities: UtilityData[] | null;
  emergencyContact: string;
  invitations: PersonData[];
};

export const formatWorkerText = (numOfWorkers: number) => {
  switch (numOfWorkers) {
    case 0:
      return '-';
    case 1:
      return '1 pracovník';
    case 2:
    case 3:
    case 4:
      return `${numOfWorkers} pracovníci`;
    default:
      return `${numOfWorkers} pracovníků`;
  }
};

export const formatShiftText = (numOfShifts: number) => {
  switch (numOfShifts) {
    case 1:
      return 'Vytvořena 1 směna.';
    case 2:
    case 3:
    case 4:
      return `${numOfShifts} směny vytvořeny.`;
    default:
      return `Vytvořeno ${numOfShifts} směn.`;
  }
};

const starIcon = (rating: number) => {
  if (Number.isNaN(rating)) {
    return <StarEmptyIcon className="w-[14px]" />;
  }
  if (rating < 2.5) {
    return <StarEmptyIcon className="w-[14px]" />;
  }
  if (rating < 4) {
    return <StarHalfIcon className="w-[14px]" />;
  }
  return <StarFullIcon className="w-[14px]" />;
};

export const formatJobEval = (jobEval: number) => {
  let rating;
  if (jobEval === 99) rating = (jobEval + 1) * 0.05;
  else rating = jobEval * 0.05;
  return (
    <span className="flex items-center gap-0.5 text-secondary-400 text-xs">
      {!Number.isNaN(rating) ? (Math.round(rating * 100) / 100).toFixed(1) : 'N/A'}
      {starIcon(rating)}
    </span>
  );
};

export const formatBirthDate = (birthdate: string | null) => {
  if (!birthdate) return 'N/A';
  return moment(birthdate).toNow(true);
};

export const validateMaxDuration = (startTime: Moment, endTime: Moment) => {
  if (!startTime || !endTime) return undefined;
  const duration = calculateWorkTime(startTime, endTime, true, 'hours');
  if (duration > 13) {
    return 'Maximální délka směny je 13 hodin';
  }
  if (duration < 0) {
    return 'Začátek směny nemůže být po konci';
  }
  return undefined;
};
