import { CountryCodes } from '@tymbe/schema/enums';
import { useIntl } from 'react-intl';

export const CountryCodesAndNames = () => {
  const intl = useIntl();

  const countryCodesList = {
    [CountryCodes.CZE]: intl.formatMessage({
      defaultMessage: 'Česko',
    }),
    [CountryCodes.SVK]: intl.formatMessage({
      defaultMessage: 'Slovensko',
    }),
    [CountryCodes.UKR]: intl.formatMessage({
      defaultMessage: 'Ukrajina',
    }),
    [CountryCodes.AFG]: intl.formatMessage({
      defaultMessage: 'Afghánistán',
    }),
    [CountryCodes.ALA]: intl.formatMessage({
      defaultMessage: 'Alandys',
    }),
    [CountryCodes.ALB]: intl.formatMessage({
      defaultMessage: 'Albánie',
    }),
    [CountryCodes.DZA]: intl.formatMessage({
      defaultMessage: 'Alžírsko',
    }),
    [CountryCodes.ASM]: intl.formatMessage({
      defaultMessage: 'Americká Samoa',
    }),
    [CountryCodes.VIR]: intl.formatMessage({
      defaultMessage: 'Americké Panenské ostrovy',
    }),
    [CountryCodes.AND]: intl.formatMessage({
      defaultMessage: 'Andorra',
    }),
    [CountryCodes.AGO]: intl.formatMessage({
      defaultMessage: 'Angola',
    }),
    [CountryCodes.AIA]: intl.formatMessage({
      defaultMessage: 'Anguilla',
    }),
    [CountryCodes.ATA]: intl.formatMessage({
      defaultMessage: 'Antarktida',
    }),
    [CountryCodes.ATG]: intl.formatMessage({
      defaultMessage: 'Antigua a Barbuda',
    }),
    [CountryCodes.ARG]: intl.formatMessage({
      defaultMessage: 'Argentina',
    }),
    [CountryCodes.ARM]: intl.formatMessage({
      defaultMessage: 'Arménie',
    }),
    [CountryCodes.ABW]: intl.formatMessage({
      defaultMessage: 'Aruba',
    }),
    [CountryCodes.AUS]: intl.formatMessage({
      defaultMessage: 'Austrálie',
    }),
    [CountryCodes.AZE]: intl.formatMessage({
      defaultMessage: 'Ázerbájdžán',
    }),
    [CountryCodes.BHS]: intl.formatMessage({
      defaultMessage: 'Bahamy',
    }),
    [CountryCodes.BHR]: intl.formatMessage({
      defaultMessage: 'Bahrajn',
    }),
    [CountryCodes.BGD]: intl.formatMessage({
      defaultMessage: 'Bangladéš',
    }),
    [CountryCodes.BRB]: intl.formatMessage({
      defaultMessage: 'Barbados',
    }),
    [CountryCodes.BEL]: intl.formatMessage({
      defaultMessage: 'Belgie',
    }),
    [CountryCodes.BLZ]: intl.formatMessage({
      defaultMessage: 'Belize',
    }),
    [CountryCodes.BLR]: intl.formatMessage({
      defaultMessage: 'Bělorusko',
    }),
    [CountryCodes.BEN]: intl.formatMessage({
      defaultMessage: 'Benin',
    }),
    [CountryCodes.BMU]: intl.formatMessage({
      defaultMessage: 'Bermudy',
    }),
    [CountryCodes.BTN]: intl.formatMessage({
      defaultMessage: 'Bhútán',
    }),
    [CountryCodes.BOL]: intl.formatMessage({
      defaultMessage: 'Bolívie',
    }),
    [CountryCodes.BES]: intl.formatMessage({
      defaultMessage: 'Bonaire, Svatý Eustach a Saba',
    }),
    [CountryCodes.BIH]: intl.formatMessage({
      defaultMessage: 'Bosna a Hercegovina',
    }),
    [CountryCodes.BWA]: intl.formatMessage({
      defaultMessage: 'Botswana',
    }),
    [CountryCodes.BVT]: intl.formatMessage({
      defaultMessage: 'Bouvetův ostrov',
    }),
    [CountryCodes.BRA]: intl.formatMessage({
      defaultMessage: 'Brazílie',
    }),
    [CountryCodes.IOT]: intl.formatMessage({
      defaultMessage: 'Britské indickooceánské území',
    }),
    [CountryCodes.VGB]: intl.formatMessage({
      defaultMessage: 'Britské Panenské ostrovy',
    }),
    [CountryCodes.BRN]: intl.formatMessage({
      defaultMessage: 'Brunej',
    }),
    [CountryCodes.BGR]: intl.formatMessage({
      defaultMessage: 'Bulharsko',
    }),
    [CountryCodes.BFA]: intl.formatMessage({
      defaultMessage: 'Burkina Faso',
    }),
    [CountryCodes.BDI]: intl.formatMessage({
      defaultMessage: 'Burundi',
    }),
    [CountryCodes.COK]: intl.formatMessage({
      defaultMessage: 'Cookovy ostrovy',
    }),
    [CountryCodes.CUW]: intl.formatMessage({
      defaultMessage: 'Curaçao',
    }),
    [CountryCodes.TCD]: intl.formatMessage({
      defaultMessage: 'Čad',
    }),
    [CountryCodes.MNE]: intl.formatMessage({
      defaultMessage: 'Černá Hora',
    }),
    [CountryCodes.CHN]: intl.formatMessage({
      defaultMessage: 'Čína',
    }),
    [CountryCodes.DNK]: intl.formatMessage({
      defaultMessage: 'Dánsko',
    }),
    [CountryCodes.COD]: intl.formatMessage({
      defaultMessage: 'Konžská demokratická republika',
    }),
    [CountryCodes.DMA]: intl.formatMessage({
      defaultMessage: 'Dominika',
    }),
    [CountryCodes.DOM]: intl.formatMessage({
      defaultMessage: 'Dominikánská republika',
    }),
    [CountryCodes.DJI]: intl.formatMessage({
      defaultMessage: 'Džibutsko',
    }),
    [CountryCodes.EGY]: intl.formatMessage({
      defaultMessage: 'Egypt',
    }),
    [CountryCodes.ECU]: intl.formatMessage({
      defaultMessage: 'Ekvádor',
    }),
    [CountryCodes.ERI]: intl.formatMessage({
      defaultMessage: 'Eritrea',
    }),
    [CountryCodes.EST]: intl.formatMessage({
      defaultMessage: 'Estonsko',
    }),
    [CountryCodes.ETH]: intl.formatMessage({
      defaultMessage: 'Etiopie',
    }),
    [CountryCodes.FRO]: intl.formatMessage({
      defaultMessage: 'Faerské ostrovy',
    }),
    [CountryCodes.FLK]: intl.formatMessage({
      defaultMessage: 'Falklandy (Malvíny)',
    }),
    [CountryCodes.FJI]: intl.formatMessage({
      defaultMessage: 'Fidži',
    }),
    [CountryCodes.PHL]: intl.formatMessage({
      defaultMessage: 'Filipíny',
    }),
    [CountryCodes.FIN]: intl.formatMessage({
      defaultMessage: 'Finsko',
    }),
    [CountryCodes.FRA]: intl.formatMessage({
      defaultMessage: 'Francie',
    }),
    [CountryCodes.GUF]: intl.formatMessage({
      defaultMessage: 'Francouzská Guyana',
    }),
    [CountryCodes.ATF]: intl.formatMessage({
      defaultMessage: 'Francouzská jižní a antarktická území',
    }),
    [CountryCodes.PYF]: intl.formatMessage({
      defaultMessage: 'Francouzská Polynésie',
    }),
    [CountryCodes.GAB]: intl.formatMessage({
      defaultMessage: 'Gabon',
    }),
    [CountryCodes.GMB]: intl.formatMessage({
      defaultMessage: 'Gambie',
    }),
    [CountryCodes.GHA]: intl.formatMessage({
      defaultMessage: 'Ghana',
    }),
    [CountryCodes.GIB]: intl.formatMessage({
      defaultMessage: 'Gibraltar',
    }),
    [CountryCodes.GRD]: intl.formatMessage({
      defaultMessage: 'Grenada',
    }),
    [CountryCodes.GRL]: intl.formatMessage({
      defaultMessage: 'Grónsko',
    }),
    [CountryCodes.GEO]: intl.formatMessage({
      defaultMessage: 'Gruzie',
    }),
    [CountryCodes.GLP]: intl.formatMessage({
      defaultMessage: 'Guadeloupe',
    }),
    [CountryCodes.GUM]: intl.formatMessage({
      defaultMessage: 'Guam',
    }),
    [CountryCodes.GTM]: intl.formatMessage({
      defaultMessage: 'Guatemala',
    }),
    [CountryCodes.GGY]: intl.formatMessage({
      defaultMessage: 'Guernsey',
    }),
    [CountryCodes.GIN]: intl.formatMessage({
      defaultMessage: 'Guinea',
    }),
    [CountryCodes.GNB]: intl.formatMessage({
      defaultMessage: 'Guinea-Bissau',
    }),
    [CountryCodes.GUY]: intl.formatMessage({
      defaultMessage: 'Guyana',
    }),
    [CountryCodes.HTI]: intl.formatMessage({
      defaultMessage: 'Haiti',
    }),
    [CountryCodes.HMD]: intl.formatMessage({
      defaultMessage: 'Heardův ostrov a MacDonaldovy ostrovy',
    }),
    [CountryCodes.HND]: intl.formatMessage({
      defaultMessage: 'Honduras',
    }),
    [CountryCodes.HKG]: intl.formatMessage({
      defaultMessage: 'Hongkong',
    }),
    [CountryCodes.CHL]: intl.formatMessage({
      defaultMessage: 'Chile',
    }),
    [CountryCodes.HRV]: intl.formatMessage({
      defaultMessage: 'Chorvatsko',
    }),
    [CountryCodes.IND]: intl.formatMessage({
      defaultMessage: 'Indie',
    }),
    [CountryCodes.IDN]: intl.formatMessage({
      defaultMessage: 'Indonésie',
    }),
    [CountryCodes.IRQ]: intl.formatMessage({
      defaultMessage: 'Irák',
    }),
    [CountryCodes.IRN]: intl.formatMessage({
      defaultMessage: 'Írán',
    }),
    [CountryCodes.IRL]: intl.formatMessage({
      defaultMessage: 'Irsko',
    }),
    [CountryCodes.ISL]: intl.formatMessage({
      defaultMessage: 'Island',
    }),
    [CountryCodes.ITA]: intl.formatMessage({
      defaultMessage: 'Itálie',
    }),
    [CountryCodes.ISR]: intl.formatMessage({
      defaultMessage: 'Izrael',
    }),
    [CountryCodes.JAM]: intl.formatMessage({
      defaultMessage: 'Jamajka',
    }),
    [CountryCodes.JPN]: intl.formatMessage({
      defaultMessage: 'Japonsko',
    }),
    [CountryCodes.YEM]: intl.formatMessage({
      defaultMessage: 'Jemen',
    }),
    [CountryCodes.JEY]: intl.formatMessage({
      defaultMessage: 'Jersey',
    }),
    [CountryCodes.ZAF]: intl.formatMessage({
      defaultMessage: 'Jižní Afrika',
    }),
    [CountryCodes.SGS]: intl.formatMessage({
      defaultMessage: 'Jižní Georgie a Jižní Sandwichovy ostrovy',
    }),
    [CountryCodes.SSD]: intl.formatMessage({
      defaultMessage: 'Jižní Súdán',
    }),
    [CountryCodes.JOR]: intl.formatMessage({
      defaultMessage: 'Jordánsko',
    }),
    [CountryCodes.CYM]: intl.formatMessage({
      defaultMessage: 'Kajmanské ostrovy',
    }),
    [CountryCodes.KHM]: intl.formatMessage({
      defaultMessage: 'Kambodža',
    }),
    [CountryCodes.CMR]: intl.formatMessage({
      defaultMessage: 'Kamerun',
    }),
    [CountryCodes.CAN]: intl.formatMessage({
      defaultMessage: 'Kanada',
    }),
    [CountryCodes.CPV]: intl.formatMessage({
      defaultMessage: 'Kapverdy',
    }),
    [CountryCodes.QAT]: intl.formatMessage({
      defaultMessage: 'Katar',
    }),
    [CountryCodes.KAZ]: intl.formatMessage({
      defaultMessage: 'Kazachstán',
    }),
    [CountryCodes.KEN]: intl.formatMessage({
      defaultMessage: 'Keňa',
    }),
    [CountryCodes.KIR]: intl.formatMessage({
      defaultMessage: 'Kiribati',
    }),
    [CountryCodes.CCK]: intl.formatMessage({
      defaultMessage: 'Kokosové (Keelingovy) ostrovy',
    }),
    [CountryCodes.COL]: intl.formatMessage({
      defaultMessage: 'Kolumbie',
    }),
    [CountryCodes.COM]: intl.formatMessage({
      defaultMessage: 'Komory',
    }),
    [CountryCodes.COG]: intl.formatMessage({
      defaultMessage: 'Konžská republika',
    }),
    [CountryCodes.PRK]: intl.formatMessage({
      defaultMessage: 'Korejská lidově demokratická republika',
    }),
    [CountryCodes.KOR]: intl.formatMessage({
      defaultMessage: 'Korejská republika',
    }),
    [CountryCodes.XXK]: intl.formatMessage({
      defaultMessage: 'Kosovo',
    }),
    [CountryCodes.CRI]: intl.formatMessage({
      defaultMessage: 'Kostarika',
    }),
    [CountryCodes.CUB]: intl.formatMessage({
      defaultMessage: 'Kuba',
    }),
    [CountryCodes.KWT]: intl.formatMessage({
      defaultMessage: 'Kuvajt',
    }),
    [CountryCodes.CYP]: intl.formatMessage({
      defaultMessage: 'Kypr',
    }),
    [CountryCodes.KGZ]: intl.formatMessage({
      defaultMessage: 'Kyrgyzstán',
    }),
    [CountryCodes.LAO]: intl.formatMessage({
      defaultMessage: 'Laos',
    }),
    [CountryCodes.LSO]: intl.formatMessage({
      defaultMessage: 'Lesotho',
    }),
    [CountryCodes.LBN]: intl.formatMessage({
      defaultMessage: 'Libanon',
    }),
    [CountryCodes.LBR]: intl.formatMessage({
      defaultMessage: 'Libérie',
    }),
    [CountryCodes.LBY]: intl.formatMessage({
      defaultMessage: 'Libye',
    }),
    [CountryCodes.LIE]: intl.formatMessage({
      defaultMessage: 'Lichtenštejnsko',
    }),
    [CountryCodes.LTU]: intl.formatMessage({
      defaultMessage: 'Litva',
    }),
    [CountryCodes.LVA]: intl.formatMessage({
      defaultMessage: 'Lotyšsko',
    }),
    [CountryCodes.LUX]: intl.formatMessage({
      defaultMessage: 'Lucembursko',
    }),
    [CountryCodes.MAC]: intl.formatMessage({
      defaultMessage: 'Macao',
    }),
    [CountryCodes.MDG]: intl.formatMessage({
      defaultMessage: 'Madagaskar',
    }),
    [CountryCodes.HUN]: intl.formatMessage({
      defaultMessage: 'Maďarsko',
    }),
    [CountryCodes.MKD]: intl.formatMessage({
      defaultMessage: 'Severní Makedonie',
    }),
    [CountryCodes.MYS]: intl.formatMessage({
      defaultMessage: 'Malajsie',
    }),
    [CountryCodes.MWI]: intl.formatMessage({
      defaultMessage: 'Malawi',
    }),
    [CountryCodes.MDV]: intl.formatMessage({
      defaultMessage: 'Maledivy',
    }),
    [CountryCodes.MLI]: intl.formatMessage({
      defaultMessage: 'Mali',
    }),
    [CountryCodes.MLT]: intl.formatMessage({
      defaultMessage: 'Malta',
    }),
    [CountryCodes.IMN]: intl.formatMessage({
      defaultMessage: 'Man',
    }),
    [CountryCodes.MAR]: intl.formatMessage({
      defaultMessage: 'Maroko',
    }),
    [CountryCodes.MHL]: intl.formatMessage({
      defaultMessage: 'Marshallovy ostrovy',
    }),
    [CountryCodes.MTQ]: intl.formatMessage({
      defaultMessage: 'Martinik',
    }),
    [CountryCodes.MUS]: intl.formatMessage({
      defaultMessage: 'Mauricius',
    }),
    [CountryCodes.MRT]: intl.formatMessage({
      defaultMessage: 'Mauritánie',
    }),
    [CountryCodes.MYT]: intl.formatMessage({
      defaultMessage: 'Mayotte',
    }),
    [CountryCodes.UMI]: intl.formatMessage({
      defaultMessage: 'Menší odlehlé ostrovy USA',
    }),
    [CountryCodes.MEX]: intl.formatMessage({
      defaultMessage: 'Mexiko',
    }),
    [CountryCodes.FSM]: intl.formatMessage({
      defaultMessage: 'Mikronésie',
    }),
    [CountryCodes.MDA]: intl.formatMessage({
      defaultMessage: 'Moldavsko',
    }),
    [CountryCodes.MCO]: intl.formatMessage({
      defaultMessage: 'Monako',
    }),
    [CountryCodes.MNG]: intl.formatMessage({
      defaultMessage: 'Mongolsko',
    }),
    [CountryCodes.MSR]: intl.formatMessage({
      defaultMessage: 'Montserrat',
    }),
    [CountryCodes.MOZ]: intl.formatMessage({
      defaultMessage: 'Mosambik',
    }),
    [CountryCodes.MMR]: intl.formatMessage({
      defaultMessage: 'Myanmar',
    }),
    [CountryCodes.NAM]: intl.formatMessage({
      defaultMessage: 'Namibie',
    }),
    [CountryCodes.NRU]: intl.formatMessage({
      defaultMessage: 'Nauru',
    }),
    [CountryCodes.DEU]: intl.formatMessage({
      defaultMessage: 'Německo',
    }),
    [CountryCodes.NPL]: intl.formatMessage({
      defaultMessage: 'Nepál',
    }),
    [CountryCodes.NER]: intl.formatMessage({
      defaultMessage: 'Niger',
    }),
    [CountryCodes.NGA]: intl.formatMessage({
      defaultMessage: 'Nigérie',
    }),
    [CountryCodes.NIC]: intl.formatMessage({
      defaultMessage: 'Nikaragua',
    }),
    [CountryCodes.NIU]: intl.formatMessage({
      defaultMessage: 'Niue',
    }),
    [CountryCodes.NLD]: intl.formatMessage({
      defaultMessage: 'Nizozemsko',
    }),
    [CountryCodes.NFK]: intl.formatMessage({
      defaultMessage: 'Norfolk',
    }),
    [CountryCodes.NOR]: intl.formatMessage({
      defaultMessage: 'Norsko',
    }),
    [CountryCodes.NCL]: intl.formatMessage({
      defaultMessage: 'Nová Kaledonie',
    }),
    [CountryCodes.NZL]: intl.formatMessage({
      defaultMessage: 'Nový Zéland',
    }),
    [CountryCodes.OMN]: intl.formatMessage({
      defaultMessage: 'Omán',
    }),
    [CountryCodes.PAK]: intl.formatMessage({
      defaultMessage: 'Pákistán',
    }),
    [CountryCodes.PLW]: intl.formatMessage({
      defaultMessage: 'Palau',
    }),
    [CountryCodes.PSE]: intl.formatMessage({
      defaultMessage: 'Palestina',
    }),
    [CountryCodes.PAN]: intl.formatMessage({
      defaultMessage: 'Panama',
    }),
    [CountryCodes.PNG]: intl.formatMessage({
      defaultMessage: 'Papua Nová Guinea',
    }),
    [CountryCodes.PRY]: intl.formatMessage({
      defaultMessage: 'Paraguay',
    }),
    [CountryCodes.PER]: intl.formatMessage({
      defaultMessage: 'Peru',
    }),
    [CountryCodes.PCN]: intl.formatMessage({
      defaultMessage: 'Pitcairn',
    }),
    [CountryCodes.CIV]: intl.formatMessage({
      defaultMessage: 'Pobřeží slonoviny',
    }),
    [CountryCodes.POL]: intl.formatMessage({
      defaultMessage: 'Polsko',
    }),
    [CountryCodes.PRI]: intl.formatMessage({
      defaultMessage: 'Portoriko',
    }),
    [CountryCodes.PRT]: intl.formatMessage({
      defaultMessage: 'Portugalsko',
    }),
    [CountryCodes.AUT]: intl.formatMessage({
      defaultMessage: 'Rakousko',
    }),
    [CountryCodes.REU]: intl.formatMessage({
      defaultMessage: 'Réunion',
    }),
    [CountryCodes.GNQ]: intl.formatMessage({
      defaultMessage: 'Rovníková Guinea',
    }),
    [CountryCodes.ROU]: intl.formatMessage({
      defaultMessage: 'Rumunsko',
    }),
    [CountryCodes.RUS]: intl.formatMessage({
      defaultMessage: 'Rusko',
    }),
    [CountryCodes.RWA]: intl.formatMessage({
      defaultMessage: 'Rwanda',
    }),
    [CountryCodes.GRC]: intl.formatMessage({
      defaultMessage: 'Řecko',
    }),
    [CountryCodes.SPM]: intl.formatMessage({
      defaultMessage: 'Saint Pierre a Miquelon',
    }),
    [CountryCodes.SLV]: intl.formatMessage({
      defaultMessage: 'Salvador',
    }),
    [CountryCodes.WSM]: intl.formatMessage({
      defaultMessage: 'Samoa',
    }),
    [CountryCodes.SMR]: intl.formatMessage({
      defaultMessage: 'San Marino',
    }),
    [CountryCodes.SAU]: intl.formatMessage({
      defaultMessage: 'Saúdská Arábie',
    }),
    [CountryCodes.SEN]: intl.formatMessage({
      defaultMessage: 'Senegal',
    }),
    [CountryCodes.MNP]: intl.formatMessage({
      defaultMessage: 'Severní Mariany',
    }),
    [CountryCodes.SYC]: intl.formatMessage({
      defaultMessage: 'Seychely',
    }),
    [CountryCodes.SLE]: intl.formatMessage({
      defaultMessage: 'Sierra Leone',
    }),
    [CountryCodes.SGP]: intl.formatMessage({
      defaultMessage: 'Singapur',
    }),
    [CountryCodes.SVN]: intl.formatMessage({
      defaultMessage: 'Slovinsko',
    }),
    [CountryCodes.SOM]: intl.formatMessage({
      defaultMessage: 'Somálsko',
    }),
    [CountryCodes.ARE]: intl.formatMessage({
      defaultMessage: 'Spojené arabské emiráty',
    }),
    [CountryCodes.USA]: intl.formatMessage({
      defaultMessage: 'Spojené státy',
    }),
    [CountryCodes.SRB]: intl.formatMessage({
      defaultMessage: 'Srbsko',
    }),
    [CountryCodes.CAF]: intl.formatMessage({
      defaultMessage: 'Středoafrická republika',
    }),
    [CountryCodes.SDN]: intl.formatMessage({
      defaultMessage: 'Súdán',
    }),
    [CountryCodes.SUR]: intl.formatMessage({
      defaultMessage: 'Surinam',
    }),
    [CountryCodes.SHN]: intl.formatMessage({
      defaultMessage: 'Svatá Helena',
    }),
    [CountryCodes.LCA]: intl.formatMessage({
      defaultMessage: 'Svatá Lucie',
    }),
    [CountryCodes.BLM]: intl.formatMessage({
      defaultMessage: 'Svatý Bartoloměj',
    }),
    [CountryCodes.KNA]: intl.formatMessage({
      defaultMessage: 'Svatý Kryštof a Nevis',
    }),
    [CountryCodes.MAF]: intl.formatMessage({
      defaultMessage: 'Svatý Martin (FR)',
    }),
    [CountryCodes.SXM]: intl.formatMessage({
      defaultMessage: 'Svatý Martin (NL)',
    }),
    [CountryCodes.STP]: intl.formatMessage({
      defaultMessage: 'Svatý Tomáš a Princův ostrov',
    }),
    [CountryCodes.VCT]: intl.formatMessage({
      defaultMessage: 'Svatý Vincenc a Grenadiny',
    }),
    [CountryCodes.SWZ]: intl.formatMessage({
      defaultMessage: 'Svazijsko',
    }),
    [CountryCodes.SYR]: intl.formatMessage({
      defaultMessage: 'Sýrie',
    }),
    [CountryCodes.SLB]: intl.formatMessage({
      defaultMessage: 'Šalomounovy ostrovy',
    }),
    [CountryCodes.ESP]: intl.formatMessage({
      defaultMessage: 'Španělsko',
    }),
    [CountryCodes.SJM]: intl.formatMessage({
      defaultMessage: 'Špicberky a Jan Mayen',
    }),
    [CountryCodes.LKA]: intl.formatMessage({
      defaultMessage: 'Šrí Lanka',
    }),
    [CountryCodes.SWE]: intl.formatMessage({
      defaultMessage: 'Švédsko',
    }),
    [CountryCodes.CHE]: intl.formatMessage({
      defaultMessage: 'Švýcarsko',
    }),
    [CountryCodes.TJK]: intl.formatMessage({
      defaultMessage: 'Tádžikistán',
    }),
    [CountryCodes.TZA]: intl.formatMessage({
      defaultMessage: 'Tanzanie',
    }),
    [CountryCodes.THA]: intl.formatMessage({
      defaultMessage: 'Thajsko',
    }),
    [CountryCodes.TWN]: intl.formatMessage({
      defaultMessage: 'Tchaj-wan',
    }),
    [CountryCodes.TGO]: intl.formatMessage({
      defaultMessage: 'Togo',
    }),
    [CountryCodes.TKL]: intl.formatMessage({
      defaultMessage: 'Tokelau',
    }),
    [CountryCodes.TON]: intl.formatMessage({
      defaultMessage: 'Tonga',
    }),
    [CountryCodes.TTO]: intl.formatMessage({
      defaultMessage: 'Trinidad a Tobago',
    }),
    [CountryCodes.TUN]: intl.formatMessage({
      defaultMessage: 'Tunisko',
    }),
    [CountryCodes.TUR]: intl.formatMessage({
      defaultMessage: 'Turecko',
    }),
    [CountryCodes.TKM]: intl.formatMessage({
      defaultMessage: 'Turkmenistán',
    }),
    [CountryCodes.TCA]: intl.formatMessage({
      defaultMessage: 'Turks a Caicos',
    }),
    [CountryCodes.TUV]: intl.formatMessage({
      defaultMessage: 'Tuvalu',
    }),
    [CountryCodes.UGA]: intl.formatMessage({
      defaultMessage: 'Uganda',
    }),
    [CountryCodes.URY]: intl.formatMessage({
      defaultMessage: 'Uruguay',
    }),
    [CountryCodes.UZB]: intl.formatMessage({
      defaultMessage: 'Uzbekistán',
    }),
    [CountryCodes.CXR]: intl.formatMessage({
      defaultMessage: 'Vánoční ostrov',
    }),
    [CountryCodes.VUT]: intl.formatMessage({
      defaultMessage: 'Vanuatu',
    }),
    [CountryCodes.VAT]: intl.formatMessage({
      defaultMessage: 'Vatikán',
    }),
    [CountryCodes.GBR]: intl.formatMessage({
      defaultMessage: 'Velká Británie',
    }),
    [CountryCodes.VEN]: intl.formatMessage({
      defaultMessage: 'Venezuela',
    }),
    [CountryCodes.VNM]: intl.formatMessage({
      defaultMessage: 'Vietnam',
    }),
    [CountryCodes.TLS]: intl.formatMessage({
      defaultMessage: 'Východní Timor',
    }),
    [CountryCodes.WLF]: intl.formatMessage({
      defaultMessage: 'Wallis a Futuna',
    }),
    [CountryCodes.ZMB]: intl.formatMessage({
      defaultMessage: 'Zambie',
    }),
    [CountryCodes.ESH]: intl.formatMessage({
      defaultMessage: 'Západní Sahara',
    }),
    [CountryCodes.ZWE]: intl.formatMessage({
      defaultMessage: 'Zimbabwe',
    }),
  };
  return countryCodesList;
};

export const selectCountryOptions = () => {
  const countryOptions = Object.entries(CountryCodesAndNames()).map(
    ([k, v]) => ({ label: v, value: k as CountryCodes }),
  );
  return countryOptions;
};

export const getCountryNameFromCode = (countryCode?: CountryCodes | null): string => {
  const countryCodesAndNames = CountryCodesAndNames();
  return countryCode ? countryCodesAndNames[countryCode] : '';
};
