import moment from 'moment';
import { useState } from 'react';

import ShiftCard from './ShiftCard';
import Unit from '../Unit';

const Description = ({ value: [line1, line2, line3] }: { value: [string, string, string] }) => (
  <>
    <div>{line1}</div>
    <div>{line2}</div>
    <div>{line3}</div>
  </>
);

export default () => {
  const [lastMinute, setLastMinute] = useState(false);
  const [lastPlace, setLastPlace] = useState(false);
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        gap: '1em',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center',
        width: '100vw',
        position: 'absolute',
      }}
    >
      <div style={{ position: 'absolute', top: '2em', display: 'flex', gap: '1em' }}>
        <button onClick={() => setLastMinute(!lastMinute)} type="button">Switch Last Minute</button>
        <button onClick={() => setLastPlace(!lastPlace)} type="button">Switch Last Person</button>
      </div>
      <div>
        <ShiftCard
          image="https://api.lorem.space/image/watch?w=60&h=48"
          description={<Description value={['OZ Tronic', 'OC Nový Smíchov', 'Hradec nad Nisou']} />}
          label="Doplňování zboží"
          time={`${moment('2022-12-24T08:00:00').format('HH:mm')} - ${moment('2022-12-24T16:30:00').format('HH:mm')}`}
          price={<Unit value={120} unit="Kč/h" />}
          credits={<Unit value={50} unit="Kr/h" />}
          sum={<Unit value={170} unit="Kč/h" />}
          lastMinute={lastMinute}
          lastPlace={lastPlace}
          href=""
        />
      </div>
    </div>
  );
};
