import { SVGProps } from 'react';
import { getPerkNameById } from '../../../../utils';
import { PerkId } from '../../../../types/TymbeApi';

type EuCitizenIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const EuCitizenIcon = (props: EuCitizenIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{getPerkNameById(PerkId.EU_CITIZEN)}</title>
    <path d="M9.99766 19.3276V14.3276H13.9977V19.3276C13.9977 19.8776 14.4477 20.3276 14.9977 20.3276H17.9977C18.5477 20.3276 18.9977 19.8776 18.9977 19.3276V12.3276H20.6977C21.1577 12.3276 21.3777 11.7576 21.0277 11.4576L12.6677 3.92761C12.2877 3.58761 11.7077 3.58761 11.3277 3.92761L2.96766 11.4576C2.62766 11.7576 2.83766 12.3276 3.29766 12.3276H4.99766V19.3276C4.99766 19.8776 5.44766 20.3276 5.99766 20.3276H8.99766C9.54766 20.3276 9.99766 19.8776 9.99766 19.3276Z" />
  </svg>
);

export default EuCitizenIcon;
