import { SVGProps } from 'react';

import { PerkId } from '../../../../types/TymbeApi';
import { getPerkNameById } from '../../../../utils';

type ManOnlyIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const ManOnlyIcon = (props: ManOnlyIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{getPerkNameById(PerkId.MAN_ONLY)}</title>
    <path d="M19 4H15C14.45 4 14 4.45 14 5C14 5.55 14.45 6 15 6H16.58L12.61 9.97C11.73 9.36 10.66 9 9.5 9C6.46 9 4 11.46 4 14.5C4 17.54 6.46 20 9.5 20C12.54 20 15 17.54 15 14.5C15 13.34 14.64 12.27 14.03 11.38L18 7.42V9C18 9.55 18.45 10 19 10C19.55 10 20 9.55 20 9V5C20 4.45 19.55 4 19 4ZM9.5 18C7.57 18 6 16.43 6 14.5C6 12.57 7.57 11 9.5 11C11.43 11 13 12.57 13 14.5C13 16.43 11.43 18 9.5 18Z" />
  </svg>
);

export default ManOnlyIcon;
