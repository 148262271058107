import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feathersClient from '../../../../apiClient';
import { useUser } from '../../../../apiClient/ApiContext';
import { ErrorAlert } from '../../../../components/alerts';
import { PrimaryButton } from '../../../../components/buttons';
import { TrashIcon } from '../../../../components/icons';
import AlertModal from '../../../../components/modals/AlertModal';
import { PersonData, RolesData } from '../../../../types/TymbeApi';
import { Roles } from '../../../../utils/enums';
import Spinner from '../../../../utils/spinner';

export type DeleteUserButtonProps = {
  person: PersonData;
};

const DeleteUserButton = ({ person }: DeleteUserButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const user = useUser();

  const { mutateAsync: deletePerson, isLoading } = useMutation(
    ['deletePerson'],
    async (personId: number) =>
      feathersClient.service('person').remove(personId),
  );

  const hideDeleteButton = (userRole: RolesData[]) => {
    if (userRole.some((role) => Roles.SUPER_ADMIN === role.slug)) {
      return false;
    }
    if (userRole.some((role) => Roles.TYMBE_ADMIN === role.slug) && !person.login?.role?.some((role) => Roles.SUPER_ADMIN === role.slug)) {
      return false;
    }
    return true;
  };

  const onOkClick = async () => {
    try {
      await deletePerson(person.id);
      queryClient.invalidateQueries(['person']);
      setShowModal(false);
    } catch (err) {
      ErrorAlert('Při odebírání uživatele došlo k chybě');
    }
  };

  if (hideDeleteButton(user.role)) return null;

  return (
    <>
      <PrimaryButton
        className="ty-icon-button bg-bg"
        onClick={() => setShowModal(true)}
        icon={<TrashIcon />}
        disabled={isLoading}
      />
      {isLoading ? (
        <Spinner size="medium" />
      ) : (
        <AlertModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          title="Potvrdit odebrání uživatele"
          message={`Chcete opravdu odstranit uživatele: ${person.first_name} ${person.last_name}?`}
          onYes={onOkClick}
        />
      )}
    </>
  );
};

export default DeleteUserButton;
