import { EditDocumentAction } from './enums';
import { DocumentTypeData } from '../types/TymbeApi';

// eslint-disable-next-line import/prefer-default-export
export const getDocumentAction = (document: DocumentTypeData): EditDocumentAction => {
  if (document.template_id && !document.json) {
    return EditDocumentAction.GOOGLE;
  }
  if (!document.template_id) {
    return EditDocumentAction.EDITOR_JS;
  }
  return EditDocumentAction.UNKNOWN;
};
