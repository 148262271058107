import { Card, Col, message, Popconfirm, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { forgotPasswordThunk } from './accountSlice';
import NotificationSettingsCard from './components/NotificationSettingsCard';
import { useUser } from '../../apiClient/ApiContext';
import Protect from '../../apiClient/Protect';
import { SecondaryButton } from '../../components/buttons';
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import { Roles } from '../../utils/enums';

const Profile = () => {
  const dispatch = useDispatch();
  const user = useUser();

  const onResetPasswordConfirm = () => {
    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(forgotPasswordThunk(user.username));
    message.info('Do pár chvilek vám pošleme email s formulářem pro nové heslo :)');
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <Card style={{ marginTop: '10px' }}>
          <Row>
            <Col xs={24} lg={{ span: 10, offset: 1 }}>
              <h3 className="ty-h3 text-align-start">
                {user?.person.first_name}
                {' '}
                {user?.person.last_name}
              </h3>
              <p>
                Email:
                {' '}
                {user?.username}
              </p>
              <p> Tel: </p>
            </Col>
          </Row>
        </Card>
        <NotificationSettingsCard />
        <Card style={{ marginTop: '10px' }}>
          <h3> Další nastavení: </h3>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Popconfirm
                placement="topLeft"
                title="Opravdu chcete resetovat heslo?"
                onConfirm={onResetPasswordConfirm}
                okText="Ano"
                cancelText="Ne"
              >
                <SecondaryButton
                  buttontext="Reset hesla"
                />
              </Popconfirm>
            </Col>
            <Protect auth={[Roles.TYMBER]} redirect={false}>
              <Col span={24}>
                <Link className="ty-button-secondary ant-btn" to="/jobs">Profil Tymbera</Link>
              </Col>
            </Protect>
          </Row>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default Profile;
