import { PersonData } from '@tymbe/schema/person.interface';
import { useFormState, useFormApi } from 'informed';
import { Link } from 'react-router-dom';

import TyInput from '../../../components/inputs/TyInput';
import TySelectPerson from '../../../components/inputs/TySelectPerson';
import { CompanyData } from '../../../types/TymbeApi';
import { addPlusToPhone } from '../../../utils/phoneNumber';

type CompanyContactPersonFormProps = {
  initialContactPerson?: PersonData;
  company: CompanyData;
};

export interface CompanyContactPersonFormValues {
  contactPerson?: PersonData;
  contactPersonPosition?: string;
  contactPersonEmail?: string | null;
  contactPersonPhone?: string | null;
}

const getNumberFromContact = (contactPerson?: PersonData): string | undefined => addPlusToPhone(
  contactPerson?.contact?.find((c) => c.type === 'mobile_phone')?.value,
);

const getEmail = (contactPerson?: PersonData): string | null | undefined => contactPerson?.login?.username
  ?? contactPerson?.contact?.find((c) => c.type === 'email')?.value;

const CompanyContactPersonForm = ({ company, initialContactPerson }: CompanyContactPersonFormProps) => {
  const formState = useFormState<CompanyContactPersonFormValues>();
  const formApi = useFormApi<CompanyContactPersonFormValues>();

  const onSelectPerson = () => {
    const { contactPerson } = formState.values;
    if (!contactPerson) return;

    formApi.setValue('contactPersonPosition', contactPerson.company?.[0]?.title);
    formApi.setValue('contactPersonEmail', getEmail(contactPerson));
    formApi.setValue('contactPersonPhone', getNumberFromContact(contactPerson));
  };

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <h3>Kontaktní osoba</h3>
        <TySelectPerson name="contactPerson" companyId={company.id} onChange={onSelectPerson} />
        <div className="flex flex-row gap-2 flex-wrap">
          <TyInput
            className="w-full md:flex-1"
            name="contactPerson.first_name"
            label="Jméno kontaktní osoby"
            disabled
          />
          <TyInput
            className="w-full md:flex-1"
            name="contactPerson.last_name"
            label="Příjmení kontaktní osoby"
            disabled
          />
        </div>
        <TyInput
          name="contactPersonPhone"
          label="Mobil"
          disabled
          initialValue={getNumberFromContact(initialContactPerson)}
        />
        <TyInput
          name="contactPersonEmail"
          label="Email"
          disabled
          initialValue={getEmail(initialContactPerson)}
        />
        <TyInput
          name="contactPersonPosition"
          label="Pozice"
          disabled
          initialValue={initialContactPerson?.company?.[0]?.title}
        />
        {formState.values.contactPerson && (
          <Link to={`/user/${formState.values.contactPerson}/edit`}>Upravit kontaktní údaje</Link>
        )}
      </div>
    </div>
  );
};

export default CompanyContactPersonForm;
