import { PaySupplementRule, PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { Form, FormState } from 'informed';

import { SubmitButton } from '../../../../../components/buttons';
import ModalShell from '../../../../../components/modals/ModalShell';
import PaySupplementItem from '../../../../../components/PaySupplement/PaySupplementItem';

type GlobalEditPropertyModalProps = {
  showModal: boolean;
  paySupplementType: PaySupplementType;
  initialValues: { paySupplement: PaySupplementRule };
  onClose: () => void;
  onSubmit?: (formData: FormState<{ pay_supplement: PaySupplementRules }>) => Promise<void>;
};

const GlobalEditPropertyModal = ({
  showModal,
  paySupplementType,
  initialValues,
  onClose,
  onSubmit,
}: GlobalEditPropertyModalProps) => (
  <ModalShell
    showModal={showModal}
    onClose={onClose}
  >
    <div className="flex flex-row justify-between items-center">
      <h2>Globálně upravit</h2>
    </div>
    <Form
      onSubmit={onSubmit}
    >
      {/* TODO OD - fix the style */}
      <div className="flex gap-y-1 justify-between items-center h-max">
        <PaySupplementItem
          name={`pay_supplement.${paySupplementType}`}
          label="Příplatek"
          type={paySupplementType}
          initialValues={initialValues.paySupplement}
          isDisabled={false}
        />
      </div>
      <div className="flex gap-x-1 justify-end">
        <button
          type="button"
          className="ty-button-secondary"
          onClick={onClose}
        >
          Zrušit
        </button>
        <SubmitButton
          className="ty-button-primary"
          buttontext="Potvrdit"
        />
      </div>
    </Form>
  </ModalShell>
);

export default GlobalEditPropertyModal;
