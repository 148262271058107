import Button from 'antd/lib/button';
import PropTypes from 'prop-types';

import DelayedNotification from '../PopupNotification/DelayNotification';
import usePopupNotification from '../PopupNotification/PopupNotification.hook';

const TimeoutButton = ({
  onClick,
  timeoutFunction,
  timeout = 10000,
  type = 'button',
  notificationMessage,
  onNotificationCancel,
  ...props
}) => {
  const showPopupNotification = usePopupNotification(DelayedNotification);

  const initTimeout = () => {
    let timeoutId;
    const onCancel = showPopupNotification({
      onClick: () => {
        clearTimeout(timeoutId);
        onNotificationCancel();
        onCancel();
      },
      message: notificationMessage,
    });

    timeoutId = setTimeout(() => {
      onCancel();
      timeoutFunction();
    }, timeout);
  };

  const onButtonClick = async () => {
    try {
      await onClick();
      initTimeout();
      // we just want to catch
      // eslint-disable-next-line no-empty
    } catch { }
  };

  return (
    <Button
      className="ty-button-primary"
      {...props}
      type={type}
      onClick={onButtonClick}
    />
  );
};
TimeoutButton.propTypes = {
  type: PropTypes.string,
  timeout: PropTypes.number,
  timeoutFunction: PropTypes.func.isRequired,
  notificationMessage: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  onNotificationCancel: PropTypes.func.isRequired,
};

export default TimeoutButton;
