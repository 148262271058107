import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import { SecondaryButton } from '../buttons';

const DelayedNotification = ({ message, onClick, buttonText = 'Zrušit' }) => (
  <Row className="ty-attendance-panel ty-popout-notification">
    <Col xs={24} md={14} className="ty-attendance-panel-text-wrapper">
      <div className="ty-attendance-panel-text">
        {message}
      </div>
    </Col>

    <Col xs={24} md={10} className="ty-attendance-panel-buttons">
      <div className="ty-attendance-panel-button-wrapper">
        <SecondaryButton
          onClick={onClick}
        >
          {buttonText}
        </SecondaryButton>
      </div>
    </Col>
  </Row>
);

DelayedNotification.propTypes = {
  onClick: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

export default DelayedNotification;
