import moment, { Moment } from 'moment';

import { isPerkData } from '../../../types/TymbeApi';
import { FormValues } from '../Libs/transform';

interface RequirementObj {
  $joinRelation: {
    documentType?: boolean,
    perk?: boolean,
  },
  $or: object[],
}

const apiTransformationShiftState = {

  company: (companies: FormValues[]) => {
    if (companies.length === 0) return undefined;
    return { 'shift_state.company_id': { $in: companies.map((c) => c.value) } };
  },

  position: (positions: FormValues[]) => {
    if (positions.length === 0) return undefined;
    return { 'shift_state.name': { $in: positions.map((p) => p.value) } };
  },

  tymber: (tymbers: FormValues[]) => {
    if (tymbers.length === 0) return undefined;
    return {
      $joinRelation: {
        manShift: {
          application: true,
        },
      },
      'manShift:application.person_id': { $in: tymbers.map((t) => t.value) },
    };
  },

  branchoffice: (branchoffices: FormValues[]) => {
    if (branchoffices.length === 0) return undefined;
    return {
      $joinRelation: {
        branchoffice: {
          parent: true,
        },
      },
      'branchoffice:parent.id': { $in: branchoffices.map((b) => b.value) },
    };
  },

  department: (departments: FormValues[]) => {
    if (departments.length === 0) return undefined;
    return {
      'shift_state.branchoffice_id': { $in: departments.map((d) => d.value) },
    };
  },

  shiftTimeFrom: (time: Moment) => ({ $modify: { startTime: [moment(time).startOf('minutes').toISOString(), undefined] } }),

  shiftTimeTo: (time: Moment) => ({ $modify: { startTime: [undefined, moment(time).startOf('minutes').toISOString()] } }),

  shiftDateFrom: (date: Moment) => ({ $modify: { startDate: [moment(date).startOf('day').toISOString(), undefined] } }),

  shiftDateTo: (date: Moment) => ({ $modify: { startDate: [undefined, moment(date).endOf('day').toISOString()] } }),

  requirements: (requirement: FormValues[]) => {
    if (requirement.length === 0) return undefined;
    const perks = requirement.filter((r) => (isPerkData(r))).map((rr) => (rr.id));
    const documents = requirement.filter((r) => (!isPerkData(r))).map((rr) => (rr.id));

    const requirementObject: RequirementObj = {
      $joinRelation: {},
      $or: [],
    };
    if (documents.length > 0) {
      requirementObject.$joinRelation.documentType = true;
      requirementObject.$or.push({ 'documentType.id': { $in: documents } });
    }
    if (perks.length > 0) {
      requirementObject.$joinRelation.perk = true;
      requirementObject.$or.push({ 'perk.id': { $in: perks } });
    }
    return requirementObject;
  },

  orderId: (orderIds: FormValues[]) => {
    if (orderIds.length === 0) return undefined;
    return {
      'shift_state.id': { $in: orderIds.map((o) => o.value) },
    };
  },

  orderType: (type: string) => {
    if (!type) return undefined;
    if (type === 'uncanceledOrders') return { deleted_at: { $null: true } };
    if (type === 'onlyCanceledOrders') return { deleted_at: { $null: false } };

    return undefined;
  },

};

export default apiTransformationShiftState;
