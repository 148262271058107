import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import { PrimaryButton } from '../../../components/buttons';
import Illustration from '../../../components/illustration';
import { EmailIllustration } from '../../../components/illustrations';
import LocaleSwitcher from '../../../components/LocaleSwitcher';
import { InlineSpinner } from '../../../utils/spinner';

const ActivateAccount = () => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  const intl = useIntl();

  const history = useNavigate();

  const { data: verifyStatus, isLoading } = useQuery(
    ['verifyAccount', token],
    async () => feathersClient.service('auth-management').create({
      action: 'verifySignup',
      token,
    }),
    {
      enabled: !!token,
    },
  );

  const getMessage = (data: any): string => {
    if (!token) {
      return intl.formatMessage({
        defaultMessage: 'Tvůj účet není ověřený. Odkaz pro ověření najdeš ve své e-mailové schránce',
      });
    }
    if (data && ('message' in data) && data.message === 'Only expired logins found') {
      return intl.formatMessage({
        defaultMessage: 'Platnost aktivačního odkazu již vypršela. Do tvé e-mailové schránky jsme zaslali nový',
      });
    }
    if (data) return intl.formatMessage({ defaultMessage: 'Váš email byl právě ověřen' });
    return intl.formatMessage({ defaultMessage: 'Tento email jsme již ověřili' });
  };

  return (
    <>
      <div className="flex-auto text-center justify-center space-y-2">
        <h1>{intl.formatMessage({ defaultMessage: 'Vítej v aplikaci Tymbe' })}</h1>
        <Illustration svg={<EmailIllustration />} />
        {
          isLoading
            ? <InlineSpinner />
            : (
              <>
                <h2>{getMessage(verifyStatus)}</h2>
                {
                  !(verifyStatus && ('message' in verifyStatus) && verifyStatus.message === 'Only expired logins found')
                  && token
                  && (
                    <PrimaryButton
                      onClick={() => history('/finish-registration')}
                    >
                      {intl.formatMessage({ defaultMessage: 'Spustit doregistraci' })}
                    </PrimaryButton>
                  )
                }
              </>
            )
        }
      </div>
      <div className="flex justify-center">
        <LocaleSwitcher />
      </div>
    </>
  );
};

export default ActivateAccount;
