import { TYMBE_ID } from '@tymbe/schema/constants';
import { CompanyFulfillmentType, CountryCodes, Roles } from '@tymbe/schema/enums';
import { useFormApi, useFormState } from 'informed';
import Tooltip from 'rc-tooltip';
import { useEffect } from 'react';

import feathersClient from '../../../apiClient';
import Protect from '../../../apiClient/Protect';
import LogoForm from '../../../components/blob/LogoForm';
import SignatureForm from '../../../components/blob/SignatureForm';
import StampForm from '../../../components/blob/StampForm';
import TyAutoTrimInput from '../../../components/inputs/TyAutoTrimInput';
import TyDateInput from '../../../components/inputs/TyDateInput';
import TyMarkdownEditor from '../../../components/inputs/TyMarkdownEditor';
import { TySelect } from '../../../components/inputs/TySelect';
import TySelectCompany from '../../../components/inputs/TySelectCompany';
import TySelectPerson, { validatePerson } from '../../../components/inputs/TySelectPerson';
import validateZipCode from '../../../components/inputs/Validators/zipCode';
import { CountryOptionType } from '../../../pages-legacy/user/edit/editUserForm.interface';
import { CompanyData, CompanyState } from '../../../types/TymbeApi';
import { blockInvalidCharactersForZip, formatOnPasteForZip } from '../../../utils/zipCode';

type OwnProps = {
  company?: CompanyData;
};

export interface CompanyFormValues extends CompanyData {
  logoBlob: Blob | null,
  stampBlob: Blob | null,
  signatureBlob: Blob | null,
  stateOption: { label: string, value: CompanyState }
  countryOption: CountryOptionType;
  typeOption: { label: string, value: string }
}

export const countryOptions = [
  {
    value: CountryCodes.CZE,
    label: 'Česká republika',
  },
  {
    value: CountryCodes.SVK,
    label: 'Slovensko',
  },
];

const stateOptions = [
  {
    label: 'Aktivní',
    value: CompanyState.ACTIVE,
  },
  {
    label: 'Neaktivní',
    value: CompanyState.PENDING,
  },
  {
    label: 'Blokována',
    value: CompanyState.BANNED,
  },
];

const companyTypeOptions = [
  {
    label: 'Mandát',
    value: CompanyFulfillmentType.MANDATE,
  },
  {
    label: 'Služba',
    value: CompanyFulfillmentType.SERVICE,
  },
  {
    label: 'Agentura',
    value: CompanyFulfillmentType.AGENCY,
  },
];

const CompanyForm = ({ company = undefined }: OwnProps) => {
  const formApi = useFormApi<CompanyFormValues>();
  const formState = useFormState<CompanyFormValues>();

  useEffect(() => {
    if (!company) return;
    formApi.setValues({
      stateOption: stateOptions.find((x) => x.value === company.state),
      countryOption: countryOptions.find((x) => x.value === company.address?.country),
      typeOption: companyTypeOptions.find((x) => x.value === company.fulfillment_type),
      ...company,
    });
  }, [company, formApi]);

  const onCompanyTypeChange = async (companyType: CompanyFulfillmentType) => {
    if (companyType === CompanyFulfillmentType.AGENCY) {
      const result = await feathersClient.service('company').get(TYMBE_ID);
      formApi.setValue('contractor', [result]);
    }
  };

  const validateCSSZVs = (vs: string | undefined) => {
    if (vs && vs.length > 10) {
      return 'Variabilní symbol ČSSZ může mít maximálně 10 znaků';
    }
    return undefined;
  };

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="flex flex-col gap-2 lg:min-w-[42%]">
        <h3 className="ty-h3 text-align-start">
          Základní údaje
        </h3>
        <TySelect
          id="company_form_state"
          className=""
          name="stateOption"
          label="Stav"
          options={stateOptions}
          required
        />

        <TyAutoTrimInput
          id="company_form_name"
          name="name"
          label="Název"
          type="text"
          required
        />

        <TyAutoTrimInput
          id="company_form_cin"
          name="cin"
          type="text"
          label="IČO"
          required
        />

        <TyAutoTrimInput
          id="company_form_tin"
          name="tin"
          type="text"
          label="DIČ"
        />

        <TyAutoTrimInput
          id="company_form_file_number"
          name="file_number"
          label="Zápis v OR"
          required
        />

        <TySelectPerson
          id="company_form_contactPerson"
          name="contactPerson"
          label="Celé jméno zastupující osoby"
          validate={validatePerson}
          validateOn="change-submit"
          initialValue={company?.contactPerson}
          isCreatable
          filter={{ role: 'company' }}
        />

        <h3 className="ty-h3 text-align-start">
          Plnění
        </h3>

        <TySelect
          id="company_form_type_option"
          label="Režim spolupráce"
          name="typeOption"
          options={companyTypeOptions}
          onChange={(newCompanyType) => onCompanyTypeChange(newCompanyType.value.value)}
          required
        />
        {formState.values.typeOption?.value === CompanyFulfillmentType.MANDATE ? null : (
          <TySelectCompany
            id="company_form_contractor"
            label="Kontraktor"
            name="contractor"
            isDisabled={formState.values.typeOption?.value !== CompanyFulfillmentType.SERVICE}
            initialValue={company?.contractor?.length === 0 ? undefined : company?.contractor}
            required="Vyberte prosím kontraktora"
          />
        )}

        <h3 className="ty-h3 text-align-start">
          Sídlo firmy
        </h3>

        <TySelect
          id="company_form_country"
          label="Země"
          initialValue={countryOptions[0]}
          name="countryOption"
          options={countryOptions}
        />

        <TyAutoTrimInput
          id="company_form_address.addressline1"
          name="address.addressline1"
          type="text"
          label="Ulice"
          required
        />

        <div className="flex flex-row gap-2 flex-wrap">
          <TyAutoTrimInput
            id="company_form_address.locality"
            className="w-full md:flex-1"
            name="address.locality"
            type="text"
            label="Město"
            required
          />
          <TyAutoTrimInput
            id="company_form_address.zip"
            className="w-full md:flex-1"
            name="address.zip"
            type="text"
            label="PSČ"
            validate={(value) => validateZipCode(value as string, formState.values.countryOption.value)}
            onPaste={formatOnPasteForZip}
            onKeyDown={blockInvalidCharactersForZip}
          />
        </div>

        <Protect auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN]} redirect={false}>
          <h3 className="ty-h3 text-align-start">
            Plná moc
          </h3>

          <div className="flex flex-row gap-2 flex-wrap">
            <TyDateInput
              id="power_of_attorney_from"
              name="power_of_attorney_from"
              className="w-full md:flex-1"
              label="Platnost od"
              isClearable
              mode="date"
              disabledDate={(current) => {
                const { values } = formState;
                if (!values.power_of_attorney_to) return false;
                return current.isAfter(values.power_of_attorney_to, 'day');
              }}
            />
            <TyDateInput
              id="power_of_attorney_to"
              name="power_of_attorney_to"
              className="w-full md:flex-1"
              label="Platnost do"
              isClearable
              mode="date"
              disabledDate={(current) => {
                const { values } = formState;
                if (!values.power_of_attorney_from) return false;
                return current.isBefore(values.power_of_attorney_from, 'day');
              }}
            />
          </div>

          <h3 className="ty-h3 text-align-start">
            ČSSZ
          </h3>

          <Tooltip trigger="hover" overlay="Unikátní identifikátor zaměstnavatele pro ČSSZ" placement="top">
            <TyAutoTrimInput
              id="company_form_cssz_vs"
              name="cssz_vs"
              label="Variabilní symbol ČSSZ"
              type="text"
              validate={validateCSSZVs}
            />
          </Tooltip>
        </Protect>
      </div>
      <div className="flex flex-col gap-2 lg:min-w-[41%]">
        <h3 className="ty-h3 text-align-start">
          Dodatkové údaje
        </h3>

        <TyAutoTrimInput
          id="company_form_display_name"
          name="display_name"
          label="Alias / Název pro brigádníky"
          type="text"
        />

        <TyAutoTrimInput
          id="company_form_bank_account"
          name="bank_account"
          label="Bankovní účet"
          type="text"
        />

        <TyMarkdownEditor
          id="company_form_note"
          name="note"
          label="Poznámka"
          initialValue={company?.note}
        />
        <LogoForm logo={company?.logo} />
        <SignatureForm signature={company?.signature} />
        <StampForm stamp={company?.stamp} />
        <Protect
          auth={[
            Roles.SUPER_ADMIN,
          ]}
          redirect={false}
        >
          <h4> Tymbe bankovní účet </h4>
          <TyAutoTrimInput
            id="company_form_payment_bank_account"
            name="payment_bank_account"
            label="Tymbe bankovní účet"
            type="text"
          />
        </Protect>
      </div>
    </div>

  );
};

export default CompanyForm;
