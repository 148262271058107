import { ShiftLabels } from '@tymbe/schema/shift-template.interface';

const shiftLabelsText: Record<string, string> = {
  [ShiftLabels.PHYSICALLY_CHALLENGING_JOB]: 'Fyzicky náročná brigáda',
};

export const transformShiftLabels = (labels: string[]) => labels.map((label) => ({
  label: shiftLabelsText[label] || label,
  value: label,
}));

export const shiftLabelsOptions = [{
  label: shiftLabelsText[ShiftLabels.PHYSICALLY_CHALLENGING_JOB],
  value: ShiftLabels.PHYSICALLY_CHALLENGING_JOB,
}];
