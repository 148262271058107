/* eslint-disable react/button-has-type */
const ErrorButton = ({ className = '', ...rest }: JSX.IntrinsicElements['button']) => (
  <button
    {...rest}
    className={`
      border
      p-1.5
      flex
      align-center
      !rounded-lg
      font-bold
      text-bg
      bg-error
      border-error
      hover:border-error-300
      hover:bg-error-300
      transition
      ease-in
      ${className}
    `}
  />
);

export default ErrorButton;
