import moment from 'moment';
import { Moment } from 'moment/moment';
import Tooltip from 'rc-tooltip';
import { useState } from 'react';

import {
  DEFAULT_ORDERS_DATE_RANGE_END,
  DEFAULT_ORDERS_DATE_RANGE_START,
} from './OrdersPage.utils';
import { PrimaryButton } from '../../../components/buttons';
import { TrashIcon } from '../../../components/icons';
import { TableRangePicker } from '../../../components/inputs';
import SearchBox from '../../../components/search/SearchBox';
import TyInteractiveLabels from '../../../components/TyAdvancedSearch/Components/TyInteractiveLabels';
import { labelsValues } from '../../../components/TyAdvancedSearch/Libs/enums';
import TyAdvancedSearchShift from '../../../components/TyAdvancedSearch/Shift';
import { primaryColor, white } from '../../../utils/colors';

export interface OrdersTableRangePickerType {
  from: Moment,
  to: Moment,
}

interface OrdersTablePanelProps {
  onSetDateFilter: (date: OrdersTableRangePickerType) => void;
  onSearch: (input: string) => void;
  onShowInactiveShift: (show: boolean) => void;
  onAdvancedSearch: (search?: object) => void;
  isLoading: boolean;
}

const OrdersTablePanel = ({
  onSetDateFilter,
  onSearch,
  onShowInactiveShift,
  onAdvancedSearch,
  isLoading,
}: OrdersTablePanelProps) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showInactiveShifts, setShowInactiveShift] = useState(false);
  const [advanceSearchShift, setAdvanceSearchShift] = useState({});
  const [
    dateRangePicker,
    setDateRangePicker,
  ] = useState<OrdersTableRangePickerType>({
    from: DEFAULT_ORDERS_DATE_RANGE_START,
    to: DEFAULT_ORDERS_DATE_RANGE_END,
  });

  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <TableRangePicker
            className="rounded-xl p-3"
            onDateChange={(timeArray: Date[] | null) => {
              if (!timeArray) return;

              onSetDateFilter({ from: moment(timeArray[0]), to: moment(timeArray[1]) });
              setDateRangePicker({ from: moment(timeArray[0]), to: moment(timeArray[1]) });
            }}
            value={[dateRangePicker.from, dateRangePicker.to]}
            // FIXME: TableRangePicker component needs to be refactored to TS with proper typing
            //        to support `defaultValue` properly
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defaultValue={[moment().startOf('week'), moment().endOf('week')]}
            ranges={{
              Dnes: [moment(), moment()],
              'Tento týden': [moment().startOf('week'), moment().endOf('week')],
              'Příští týden': [moment().add(1, 'weeks').startOf('week'), moment().add(1, 'weeks').endOf('week')],
              'Tento měsíc': [moment().startOf('month'), moment().endOf('month')],
            }}
          />
        </div>
        <div className="flex gap-2 items-center">
          <SearchBox
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            onParamsSearch={onSearch}
            placeholder="Hledat pracovní pozici/provozovnu"
            loading={isLoading}
            name="OrdersTableSearchBox"
          />
          <TyAdvancedSearchShift
            pageTitle="Filtrování Objednávek"
            setInitialValues={(search?: object) => {
              setAdvanceSearchShift(search ?? {});
              onAdvancedSearch(search);
            }}
            initialValues={advanceSearchShift}
            className="ty-modal"
          />
          <Tooltip trigger="hover" overlay="Zobrazit i neaktivní objednávky" placement="top">
            <PrimaryButton
              className="ty-icon-button ty-icon-button-toggle"
              style={{
                backgroundColor: showInactiveShifts
                  ? primaryColor
                  : white,
              }}
              type="link"
              onClick={() => {
                setShowInactiveShift(!showInactiveShifts);
                onShowInactiveShift(showInactiveShifts);
              }}
              icon={<TrashIcon />}
            />
          </Tooltip>
        </div>
      </div>
      <TyInteractiveLabels
        labelsValues={labelsValues}
        setInitialValues={(values) => {
          setAdvanceSearchShift(values);
          onAdvancedSearch(values);
        }}
        initialValues={advanceSearchShift}
      />
    </>
  );
};

export default OrdersTablePanel;
