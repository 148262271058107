import { MpsvReportData } from '@tymbe/schema/mpsv-report.interface';
import { ComponentProps, useState } from 'react';
import Modal from 'react-modal';

import Button from '../../../../components/buttons/Button';
import ViewIcon from '../../../calendar/components/icons/ViewIcon';
import styles from '../../../calendar/dev/shift-detail/Modal.module.css';
import { prettifyXml } from '../mpsv.utils';

type ShowMpsvRequestButtonOwnProps = {
  mpsvReport: MpsvReportData | undefined
};

type ShowMpsvRequestButtonProps = ShowMpsvRequestButtonOwnProps & Omit<ComponentProps<'button'>, keyof ShowMpsvRequestButtonOwnProps>;

const ShowMpsvRequestButton = ({ mpsvReport, ...rest }: ShowMpsvRequestButtonProps) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    // Used to stop the row in mpsv table from expanding when any part of the modal is clicked
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()} className="min-w-fit">
      <Button
        type="button"
        title="Zobrazení requestu"
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
        className="enabled:hover:bg-secondary-400 enabled:hover:text-bg bg-secondary-100 min-w-fit disabled:cursor-not-allowed disabled:opacity-70"
        {...rest}
      >
        <ViewIcon className="w-3" />
      </Button>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        shouldCloseOnOverlayClick
        className="bg-bg m-auto rounded-xl px-6 py-4 w-[1000px]"
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <h2>Request data</h2>
        <pre
          className="w-full min-h-[200px]"
        >
          { mpsvReport?.request_data && prettifyXml(mpsvReport.request_data) }
        </pre>
        <div className="flex justify-end mt-2 gap-2">
          <Button
            type="button"
            className="w-12 flex justify-center hover:bg-error-300 min-w-fit"
            onClick={() => setOpenModal(false)}
          >Zavřít
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ShowMpsvRequestButton;
