import { OutputData } from '@editorjs/editorjs';
import Ajv from 'ajv';

export enum DocumentAction {
  IMPORT = 'import',
  EXPORT = 'export',
}

type ValidationOutput = {
  ok: boolean | PromiseLike<any>;
  message?: string;
  data?: OutputData;
};

const ajv = new Ajv();

const schema = {
  time: 'integer',
  version: 'string',
  blocks: { type: 'object' },
  options: { layout: 'string', fontSize: 'integer' },
  required: ['time', 'version', 'blocks'],
  // additionalProperties: false,
};

const validate = ajv.compile(schema);

export default function validateEditorJsJson(data: string): ValidationOutput {
  try {
    const parsedJson = JSON.parse(data);
    const valid = validate(parsedJson);
    if (!valid) {
      validate.errors?.forEach((item) => console.error(item.message));
      return { ok: valid, message: 'Vstup není validní EditorJS JSON.' };
    }
    return { ok: valid, data: parsedJson };
  } catch {
    return { ok: false, message: 'Vstup není validní JSON.' };
  }
}
