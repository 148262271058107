import { PersonNoteData } from '@tymbe/schema/person-note.interface';
import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { PrimaryButton, SecondaryButton, SubmitButton } from '../../../components/buttons';
import { EditIcon } from '../../../components/icons';
import TyInput from '../../../components/inputs/TyInput';
import ModalShell from '../../../components/modals/ModalShell';
import { RequestBody } from '../../../types/ReactQuery';

export interface EditNoteProps {
  personNote: PersonNoteData;
}
const EditNote = ({ personNote }: EditNoteProps) => {
  const [showModal, setShowModal] = useState(false);
  const qClient = useQueryClient();

  const { mutateAsync: patchNote } = useMutation(
    ['editNote'],
    async (req: RequestBody<Partial<PersonNoteData>>): Promise<PersonNoteData> =>
      feathersClient.service('person-note').patch(req.id, req.body, { query: { $eager: 'createdBy' } }),
  );

  const onSubmit = async ({ values }: FormState<Partial<PersonNoteData>>) => {
    const noteFormData: Partial<PersonNoteData> = {
      note: values.note,
    };

    try {
      await patchNote({ id: personNote.id, body: noteFormData });
      qClient.invalidateQueries(['person-note']);
      setShowModal(false);
      SuccessAlert('Poznámka upravena');
    } catch {
      ErrorAlert('Při editaci poznámky došlo k chybě');
    }
  };

  return (
    <>
      <div className="p-1">
        <PrimaryButton
          className="ty-icon-button ty-icon-button-toggle bg-primary"
          type="button"
          icon={<EditIcon />}
          onClick={() => setShowModal(true)}
        />
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <Form
          initialValues={{ note: personNote?.note }}
          onSubmit={onSubmit}
        >
          <h3 className="ty-h3 text-align-start">
            Upravit poznámku
          </h3>
          <TyInput
            name="note"
            label="note"
            className="w-full md:flex-1"
            required
          />
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton className="ml-3" buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default EditNote;
