import moment from 'moment';
import useCollapse from 'react-collapsed';

import PaymentDetail from './PaymentDetail';
import { PrimaryButton } from '../../../components/buttons';
import { PaymentAuthorizedData } from '../../../types/TymbeApi';
import { NUMERIC_LOCALE } from '../../../utils/constants';

const valueColor = (value: number): string => {
  if (value > 0) {
    return 'text-success';
  }
  if (value < 0) {
    return 'text-danger-600';
  }
  return '';
};

const strike = (payment: PaymentAuthorizedData): string => {
  if (payment.deleted_at) {
    return 'line-through';
  }
  return '';
};

export interface PaymentRowProps {
  payment: PaymentAuthorizedData;
}

const PaymentRow = ({ payment }: PaymentRowProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false });

  return (
    <div>
      <div className="flex justify-between p-4">
        <div className="flex gap-5">
          <div className={`w-60 shrink-0 ${strike(payment)}`}>
            <div className="text-secondary font-semibold text-sm leading-5">
              {payment.attendance ? payment.attendance.application?.shift?.name : ''}
              {payment.paymentRequest ? 'žádost o výplatu' : ''}
              {payment.note ?? ''}
            </div>
            {payment.attendance
              ? (
                <div className="text-secondary text-sm leading-5">
                  {moment(payment.attendance.application?.shift?.start_time).format('D. M.')}
                  {' '}{moment(payment.attendance.application?.shift?.start_time).format('H:mm')}
                  –{moment(payment.attendance.application?.shift?.end_time).format('H:mm')}
                </div>
              ) : ''}
          </div>
          <div className={`w-40 text-right ${strike(payment)}`}>
            <div className={`ml-10 font-semibold text-sm max-h-36 overflow-y-auto ${valueColor(payment.value)}`}>
              {payment.value > 0 ? '+' : ''}{Number(payment.value).toLocaleString(NUMERIC_LOCALE)} Kč
            </div>
          </div>
          <div>
            <div className={`ml-10 text-sm max-h-36 overflow-y-auto ${strike(payment)}`}>
              {payment.attendance ? 'docházka potvrzena' : ''}
              {payment.paymentRequest ? 'zažádáno' : ''}
              {' '}{moment(payment.created_at).format('D. M.')}
              {' '}{moment(payment.created_at).format('H:mm')}
            </div>
            <div className="ml-10 text-sm max-h-36 overflow-y-auto">
              {payment.deleted_at
                ? (
                  <>
                    zrušeno administrátorem
                    {' '}{moment(payment.deleted_at).format('D. M.')}
                    {' '}{moment(payment.deleted_at).format('H:mm')}
                  </>
                ) : ''}
            </div>
            <div className={`ml-10 text-sm max-h-36 overflow-y-auto ${strike(payment)}`}>
              {payment.paymentRequest && payment.paymentRequest.paymentTransaction
                ? (
                  <>
                    odesláno
                    {' '}{moment(payment.paymentRequest.paymentTransaction.created_at).format('D. M.')}
                    {' '}{moment(payment.paymentRequest.paymentTransaction.created_at).format('H:mm')}
                  </>
                ) : ''}
              {payment.paymentRequest && !payment.paymentRequest.paymentTransaction ? 'zatím neodesláno' : ''}
            </div>
          </div>

        </div>
        {payment.attendance || payment.paymentRequest?.paymentTransaction
          ? (
            <PrimaryButton {...getToggleProps()}>
              <span>
                {isExpanded ? 'Skrýt detail' : 'Zobrazit detail'}
              </span>
            </PrimaryButton>
          ) : null }
      </div>
      <div {...getCollapseProps()}>
        <PaymentDetail payment={payment} />
      </div>
    </div>
  );
};

export default PaymentRow;
