import { Layout } from 'antd';
import PropTypes from 'prop-types';

const { Footer } = Layout;

const PageFooter = ({ background = undefined, color = undefined }) => (
  <Footer
    style={{
      background,
    }}
  >
    <p
      style={{
        color,
        fontSize: '0.65rem',
        textAlign: 'center',
      }}
    />
  </Footer>
);

PageFooter.propTypes = {
  background: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default PageFooter;
