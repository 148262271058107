import { OutputData } from '@editorjs/editorjs';
import { Form } from 'informed';
import { useState } from 'react';
import Modal from 'react-modal';

import { ErrorAlert, SuccessAlert } from '../alerts';
import Button from '../buttons/Button';
import CloseIcon from '../icons/CloseIcon';
import { PageTitle } from '../texts';
import styles from '../TyAdvancedSearch/Components/Layout.module.css';

type Props = {
  showModal: boolean;
  json: OutputData | undefined;
  setShowModal: (show: boolean) => void;
};

const EditorJsExportModal = ({ showModal, setShowModal, json }: Props) => {
  const [formatted, setFormatted] = useState(false);
  const copyExport = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(json));
      SuccessAlert('Zkopírováno.');
    } catch (err) {
      ErrorAlert('Nepodařilo se zkopírovat.');
      console.error('Failed to copy: ', err);
    }
  };
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="My dialog"
      className={`${styles.modal}`}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        <div>
          <PageTitle>Export dokumentu</PageTitle>

        </div>
        <div className="flex justify-center items-center gap-8">
          <Button className={styles.close} onClick={() => setShowModal(false)}>
            <CloseIcon />
          </Button>
        </div>
      </div>
      <Form className="px-5">
        <div className="flex items-center gap-4">
          <Button className="cursor-pointer hover:text-primary hover:border-primary" onClick={copyExport}>
            Zkopírovat export
          </Button>
          <Button
            className="cursor-pointer hover:text-primary hover:border-primary"
            onClick={() => {
              setFormatted((prevValue) => !prevValue);
            }}
          >
            {formatted ? 'Minifikovat export' : 'Formátovat export'}
          </Button>
        </div>
      </Form>
      <div className={styles.children}>
        {formatted ? (
          <pre>{JSON.stringify(json, null, 4)}</pre>
        ) : (
          <p>{JSON.stringify(json)}</p>
        )}
      </div>
    </Modal>
  );
};

export default EditorJsExportModal;
