import { CompanyData } from '@tymbe/schema/company.interface';
import { CompanyFulfillmentType } from '@tymbe/schema/enums';
import { ReleaseAdvancesData } from '@tymbe/schema/release-advances.interface';
import { FormState } from 'informed';
import moment from 'moment-timezone';
import Tooltip from 'rc-tooltip';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import Protect from '../../../apiClient/Protect';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { PrimaryButton, SecondaryButton } from '../../../components/buttons';
import CompanyDetailCollapse from '../../../components/collapses';
import { EditIcon } from '../../../components/icons';
import Card from '../../../components/Layout/Card';
import NavTabs from '../../../components/navTabs/NavTabs';
import ReleaseAdvances from '../../../components/ReleaseAdvances/ReleaseAdvances';
import ReleaseAdvancesModal from '../../../components/ReleaseAdvances/ReleaseAdvancesModal';
import { PageTitle } from '../../../components/texts';
import UploadImage from '../../../components/UploadImage/UploadImage';
import UploadSign from '../../../components/UploadSign';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import { RequestBody } from '../../../types/ReactQuery';
import { UPLOAD_MAX_FILE_SIZE } from '../../../utils/constants';
import downloadBlobResponse from '../../../utils/downloadBlobResponse';
import { Roles } from '../../../utils/enums';
import Spinner from '../../../utils/spinner';
import ExportCSSZModal, { CSSZExportData } from '../components/ExportCSSZModal';

export const CompanyTabKeysEnum = {
  BRANCHOFFICE: 'branchoffices',
  DEPARTMENT: 'departments',
  CONTACT_PEOPLE: 'contact-people',
  SHIFT_TEMPLATES: 'shift-templates',
  STAMPS_SIGNATURES: 'stamps-signatures',
  DOCUMENTS: 'documents',
};

type GenerateMultipleCsszReportData = {
  company_id: number,
  month: number,
  year: number,
};

const checkFileSize = (blob: Blob) => blob.size < UPLOAD_MAX_FILE_SIZE;

const CompanyPage = () => {
  const { companyId } = useParams();

  const [company, setCompany] = useState<CompanyData>();
  const [showReleaseAdvancesModal, setShowReleaseAdvancesModal] = useState(false);
  const [releaseAdvancesResult, setReleaseAdvancesResult] = useState<ReleaseAdvancesData & { error?: string }>();
  const [showCsszModal, setShowCsszModal] = useState<boolean>(false);
  useQuery(
    ['FetchCompany', companyId],
    async () => {
      if (!companyId) return Promise.reject();
      const result = await feathersClient.service('company').get(
        companyId,
        {
          query: { $eager: '[address, contactPerson]' },
        },
      );
      setCompany(result);
      return result;
    },
  );

  const { mutateAsync: requestGenerateCsszReport, isLoading: isGenerateCsszLoading } = useMutation(
    ['generate-cssz-report', companyId],
    async (data: GenerateMultipleCsszReportData) => feathersClient
      .service('/cssz/generate-report')
      .create(data, { connection: { responseType: 'blob' } }),
  );

  const { mutateAsync: patchCompany } = useMutation(
    ['PatchCompany', companyId],
    (req: RequestBody<CompanyData | FormData>): Promise<CompanyData> =>
      feathersClient.service('company').patch(req.id, req.body, req.params),
  );

  const { mutateAsync: releaseAdvances, isLoading: advancesAreBeingReleased } = useMutation(
    ['releaseAdvances', companyId],
    async (data: ReleaseAdvancesData) => feathersClient.service('release-advances').create(data),
  );

  const onChange = async (type: string, blob: Blob) => {
    if (checkFileSize(blob)) {
      const formData = new FormData();
      formData.append(type, blob);

      try {
        await patchCompany(
          { id: Number(companyId), body: formData, params: { headers: { 'Content-Type': 'multipart/form-data' } } },
        );
      } catch {
        ErrorAlert('Při pokusu o editaci firmy došlo k chybě');
      }
    } else {
      ErrorAlert('Soubor musí být menší než 5MB!');
    }
  };

  const onReleaseAdvancesClick = () => {
    setShowReleaseAdvancesModal(!advancesAreBeingReleased);
  };

  const onReleaseAdvancesModalSubmit = async () => {
    if (advancesAreBeingReleased) {
      return;
    }

    try {
      const startOfPreviousPayInterval = moment.tz().startOf('month').subtract(1, 'month');

      const result = await releaseAdvances({
        companyId: Number(companyId),
        year: startOfPreviousPayInterval.year(),
        month: startOfPreviousPayInterval.month() + 1,
      });
      setReleaseAdvancesResult(result);
    } catch {
      const result = {
        companyId: 0,
        year: 0,
        month: 0,
        error: 'Při uvolňování záloh došlo k chybě.',
      };
      setReleaseAdvancesResult(result);
    }
  };

  const onReleaseAdvancesModalClose = () => {
    setShowReleaseAdvancesModal(false);
    setReleaseAdvancesResult(undefined);
  };

  const generateCssz = async ({ values }: FormState<CSSZExportData>) => {
    if (!companyId) {
      return;
    }
    const month = values.month.value + 1;
    try {
      const data = await requestGenerateCsszReport({
        company_id: Number(companyId),
        year: values.year,
        month,
      });

      let fileExtension = data?.type?.split('application/')?.[1];
      if (!fileExtension) {
        // The process to generate this report is very expensive,
        // download the report even though we do not know the response type
        // eslint-disable-next-line no-console
        console.error('[@companyId/index.page.tsx:generateCssz] Could not determine file type');
        fileExtension = data?.type || 'unknown';
      }

      downloadBlobResponse(data, `${values.year}-${month}-${companyId}-cssz_export.${fileExtension}`);
      setShowCsszModal(false);
      SuccessAlert('Export dokončen');
    } catch {
      ErrorAlert('Při ČSSZ exportu došlo k chybě');
    }
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      {!company ? (<Spinner />) : (
        <Wrapper padding="0px" margin="0px 22px 18px 31px">
          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center">
              <PageTitle>
                <div className="flex gap-3">
                  {company.name}
                  <Protect
                    auth={[
                      Roles.SUPER_ADMIN,
                      Roles.TYMBE_ADMIN,
                    ]}
                    redirect={false}
                  >
                    <Link to={`/company/${companyId}/edit`}>
                      <PrimaryButton
                        className="ty-icon-button ty-icon-button-toggle bg-primary"
                        type="link"
                        icon={<EditIcon />}
                      />
                    </Link>
                  </Protect>
                </div>
              </PageTitle>
              <Protect
                auth={[
                  Roles.SUPER_ADMIN,
                  Roles.TYMBE_ADMIN,
                  Roles.COMPANY,
                ]}
                redirect={false}
              >
                <div className="flex gap-1">
                  <Protect
                    auth={[
                      Roles.SUPER_ADMIN,
                    ]}
                    redirect={false}
                  >
                    <ReleaseAdvances
                      onClick={() => onReleaseAdvancesClick()}
                    />

                    {showReleaseAdvancesModal && (
                      <ReleaseAdvancesModal
                        advancesAreBeingReleased={advancesAreBeingReleased}
                        onClose={onReleaseAdvancesModalClose}
                        onSubmit={onReleaseAdvancesModalSubmit}
                        releaseAdvancesResult={releaseAdvancesResult}
                        showModal={showReleaseAdvancesModal}
                      />
                    )}
                  </Protect>
                  <Protect auth={[Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN]} redirect={false}>
                    <Tooltip placement="bottom" overlay="Vygeneruje ohlášku ČSSZ pro vybrané firmy">
                      <span>
                        <SecondaryButton
                          className="ty-button-secondary"
                          buttontext="ČSSZ export"
                          onClick={() => setShowCsszModal(true)}
                        />
                      </span>
                    </Tooltip>
                    <ExportCSSZModal
                      onSubmit={generateCssz}
                      showModal={showCsszModal}
                      setShowModal={setShowCsszModal}
                      companyCount={1}
                      isLoading={isGenerateCsszLoading}
                    />
                  </Protect>
                  <UploadImage
                    allowScale
                    allowRotate
                    name="stamp"
                    accept="image/*,.pdf"
                    isPreviewHidden
                    uploadLabel={(company?.stamp) ? 'Upravit razítko' : 'Nahrát razítko'}
                    onChange={(blob) => onChange('stamp', blob)}
                    modalTitle=""
                    image=""
                  />
                  <UploadImage
                    allowScale
                    allowRotate
                    name="logo"
                    accept="image/*,.pdf"
                    isPreviewHidden
                    uploadLabel={(company?.logo) ? 'Upravit logo' : 'Nahrát logo'}
                    onChange={(blob) => onChange('logo', blob)}
                    modalTitle=""
                    image=""
                  />

                  <UploadSign
                    uploadLabel={(company?.signature) ? 'Upravit podpis' : 'Vložit podpis'}
                    onChange={(blob) => onChange('signature', blob)}
                  />

                </div>
              </Protect>
            </div>
            <CompanyDetailCollapse data={company} />
            <Card>
              <div className="relative border-b border-secondary-200 pb-2 pl-3">
                <NavTabs>
                  <NavLink key="branchofficeList" to={`/company/${companyId}/branchoffice`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Provozovny</NavLink>
                  <NavLink key="departmentList" to={`/company/${companyId}/department`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Oddělení</NavLink>
                  <NavLink key="companyUserList" to={`/company/${companyId}/user`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Uživatelé</NavLink>
                  <NavLink key="shiftTemplateList" to={`/company/${companyId}/shift-template`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Pracovní pozice</NavLink>
                  <Protect
                    auth={[
                      Roles.SUPER_ADMIN,
                      Roles.TYMBE_ADMIN,
                    ]}
                    redirect={false}
                  >
                    <NavLink key="documentTypesList" to={`/company/${companyId}/document-type`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Dokumenty</NavLink>
                  </Protect>
                  {(company?.fulfillment_type === CompanyFulfillmentType.AGENCY) ? (
                    <Protect
                      auth={[
                        Roles.SUPER_ADMIN,
                        Roles.TYMBE_ADMIN,
                        Roles.COMPANY,
                        Roles.BRANCHOFFICE_MANAGER,
                      ]}
                      redirect={false}
                    >
                      <NavLink key="temporaryAllocationList" to={`/company/${companyId}/temporary-allocation-list`} className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Dohoda o dočasném přidělení</NavLink>
                    </Protect>
                  ) : <></>}
                </NavTabs>
              </div>
            </Card>
            <Outlet />
          </div>
        </Wrapper>
      )}
    </Container>
  );
};

export default CompanyPage;
