import { Moment } from 'moment';
import { Options } from 'react-select';

import { Option } from '../Libs/transform';

const apiTransformationsMonthlyWage = {

  company: (companies?: Options<Option>) => {
    if (!companies) return undefined;
    return { employer_id: { $in: companies.map((c) => c.value) } };
  },
  tymber: (tymbers?: Options<Option>) => {
    if (!tymbers) return undefined;
    return { person_id: { $in: tymbers.map((t) => t.value) } };
  },
  dateYearFormat: (time: Moment | undefined) => {
    if (!time) return undefined;
    return { year: time.year() };
  },
  dateMonthFormat: (time: Moment | undefined) => {
    if (!time) return undefined;
    // Moment.js is returning months on the range 0 - 11
    return { month: time.month() + 1 };
  },
};

export default apiTransformationsMonthlyWage;
