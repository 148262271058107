import { Tooltip } from 'antd';
import { components, OptionProps, GroupBase } from 'react-select';

const { Option } = components;

const CustomOption = <O, I extends boolean, G extends GroupBase<O>>(
  props: OptionProps<O, I, G>,
) => {
  const { label } = props;
  return (
    <Option {...props}>
      <Tooltip title={label} placement="top">
        <span>{label}</span>
      </Tooltip>
    </Option>
  );
};

export default CustomOption;
