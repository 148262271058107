import { useContext } from 'react';

import { NotificationContext, NotificationOptions } from '../../contexts/Notifications/NotificationsContext';

interface NotificationHookProps {
  push:(message: string, options?: NotificationOptions) => void;
}

const useNotification = (): NotificationHookProps => {
  const { createNotification } = useContext(NotificationContext);

  return {
    push: createNotification,
  };
};

export default useNotification;
