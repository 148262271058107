import { Query } from '@feathersjs/feathers';
import moment from 'moment';
import { useCallback } from 'react';
import { GroupBase, OptionsOrGroups } from 'react-select';

import TyAsyncSelect, { TyAsyncSelectProps } from './TyAsyncSelect';
import feathersClient from '../../apiClient';
import { isPaginated, ShiftTemplateData } from '../../types/TymbeApi';

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';
type OwnProps = {
  companyId?: number;
  branchofficeId?: number;
  publishedOnly?: boolean;
};

type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, ShiftTemplateData, GroupBase<ShiftTemplateData>, { gTotal: number; }, boolean>;
type TySelectShiftTemplateProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps> & OwnProps;

const loadShiftTemplates = (
  company_id: number,
  getCompanyGlobalTemplates = false,
  branchoffice_id?: number,
  publishedOnly = true,
  search?: string,
  skip?: number,
) => {
  let query: Query = {
    company_id,
    $eager: '[perk, documentType, branchoffice, utility]',
    $modify: getCompanyGlobalTemplates ? 'companyGlobal' : { branchoffice: [branchoffice_id] },
  };

  if (publishedOnly) {
    query = {
      ...query,
      $and: [{ publish_at: { $null: false } }, { publish_at: { $lt: moment().toISOString() } }],
    };
  }

  if (search && search !== '') {
    query = {
      ...query,
      name: { $ilike: `%${search}%` },
      $skip: skip,
    };
  }

  return feathersClient.service('shift-template').find({ query });
};

const loadShiftTemplateOptions = (
  company_id?: number,
  branchoffice_id?: number,
  publishedOnly = false,
  defaultOptionsCount = 0,
) => async (
  search: string,
  prevOptions: OptionsOrGroups<ShiftTemplateData, GroupBase<ShiftTemplateData>>,
  additional?: { gTotal: number; },
) => {
  let options: ShiftTemplateData[] = [];

  if (!branchoffice_id || !company_id) {
    return { options };
  }

  const first = !additional;
  let { gTotal } = additional || { gTotal: 0 };
  const globalsToLoad = gTotal - prevOptions.length > 0 ? gTotal - prevOptions.length : 0;

  let loadLocal = !globalsToLoad;

  let total = 0;
  let limit = 10;
  let hasMore = true;

  if (globalsToLoad || first) {
    const resp = await loadShiftTemplates(
      company_id,
      true,
      branchoffice_id,
      publishedOnly,
      search,
      prevOptions.length - defaultOptionsCount,
    );
    options = isPaginated(resp) ? resp.data : [resp].flat();
    total = isPaginated(resp) ? resp.total : options.length;
    gTotal = total;
    limit = isPaginated(resp) ? resp.limit : limit;
    loadLocal = options.length < limit;
  }

  const prevLocalLength = (prevOptions.length > 0 ? prevOptions.length - gTotal : 0);
  if (loadLocal) {
    const resp = await loadShiftTemplates(
      company_id,
      false,
      branchoffice_id,
      publishedOnly,
      search,
      prevLocalLength - defaultOptionsCount,
    );
    const localOptions = isPaginated(resp) ? resp.data : [resp].flat();
    options = [...options, ...localOptions];
    total = isPaginated(resp) ? resp.total : localOptions.length;
    hasMore = total > localOptions.length + prevLocalLength;
  }

  return { options, hasMore, additional: { gTotal } };
};

const TySelectShiftTemplate = <Fields extends object>({
  companyId = undefined,
  branchofficeId = undefined,
  publishedOnly = false,
  debounceTimeout = 1000,
  defaultOptions = [],
  ...props
}: TySelectShiftTemplateProps<Fields>) => {
  const defaultOptionsCount = Array.isArray(defaultOptions) ? defaultOptions.length : 0;
  const loadOptions = useCallback(
    () => loadShiftTemplateOptions(companyId, branchofficeId, publishedOnly, defaultOptionsCount),
    [branchofficeId, companyId, defaultOptionsCount],
  );

  return (
    <TyAsyncSelect
      {...props}
      defaultOptions={defaultOptions}
      debounceTimeout={debounceTimeout}
      loadOptions={loadOptions()}
      getOptionLabel={(option) => `[${option.id}] ${option.template_name}`}
      getOptionValue={(option) => String(option.id)}
    />
  );
};

export default TySelectShiftTemplate as typeof TySelectShiftTemplate;
