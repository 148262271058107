import { Query } from '@feathersjs/feathers';
import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';
import { CompanyData } from '@tymbe/schema/company.interface';
import { PersonDocumentData } from '@tymbe/schema/person-document.interface';
import { Form, FormState } from 'informed';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import UploadDocumentFile from './UploadDocumentFile';
import feathersClient from '../../../apiClient';
import { useUser } from '../../../apiClient/ApiContext';
import Protect from '../../../apiClient/Protect';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../components/buttons';
import { PlusOutlinedIcon } from '../../../components/icons';
import CloseIcon from '../../../components/icons/CloseIcon';
import TyAsyncSelect from '../../../components/inputs/TyAsyncSelect';
import TyDateInput from '../../../components/inputs/TyDateInput';
import TyInput from '../../../components/inputs/TyInput';
import TySelectBranchoffice from '../../../components/inputs/TySelectBranchoffice';
import TySelectCompany from '../../../components/inputs/TySelectCompany';
import { PageTitle } from '../../../components/texts';
import styles from '../../../components/TyAdvancedSearch/Components/Layout.module.css';
import factoryLoadOptions from '../../../components/TyAdvancedSearch/Libs/factoryLoadOptions';
import { Roles } from '../../../utils/enums';
import { BlobWithMetadata } from './types';

export interface NewDocumentFormValues {
  person_id: number,
  document_type: { label: string, value: string },
  company: CompanyData,
  branchoffice: BranchofficeData,
  name: string,
  valid_from: string | null,
  valid_to: string | null,
  template_data: any | null,
  did: string | null,
  files: Blob | null,
  reviewed_at: string | null,
  reviewed_by: number | null,
  approved: boolean | null,

}
const NewDocument = () => {
  const [showModal, setShowModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<BlobWithMetadata[]>([]);
  const { id: personId } = useParams();
  const user = useUser();
  const qClient = useQueryClient();

  const loadNewDocumentOptions = factoryLoadOptions(
    'document-type',
    ({ search, options }) => {
      const query: Query = {
        $eager: 'company',
        'document_type.name': { $ilike: `%${search}%` },
        'document_type.is_signable': false,
        $skip: options.length,
      };
      return { query };
    },
    (v: { name: string, id: number, company?: { name: string, id: number } }) => ({ label: v.company ? `[${v.id}] [${v.company.id}] [${v.company.name}] ${v.name}` : `[${v.id}] ${v.name}`, value: v.id }),
  );

  const { mutateAsync: createNewDocument } = useMutation(
    ['create-person-document'],
    async (body: Partial<PersonDocumentData>) => {
      await feathersClient.service('person-document').create(body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  );

  const onSubmit = async ({ values }: FormState<Partial<NewDocumentFormValues>>) => {
    const formData = new FormData();

    if (Array.isArray(uploadedFiles)) {
      uploadedFiles.forEach((x) => {
        formData.append('files', x.blob);
      });
    } else {
      console.log('Not array');
      formData.append('files', uploadedFiles);
    }

    formData.append('person_id', `${personId}`);
    formData.append('company_id', `${values.company?.id || null}`);
    formData.append('branchoffice_id', `${values.branchoffice?.id || null}`);
    formData.append('document_type_id', `${values.document_type?.value}`);
    formData.append('name', values.name || 'null');
    formData.append('valid_from', moment(values.valid_from).startOf('day').toISOString());
    formData.append('valid_to', moment(values.valid_to).endOf('day').toISOString());
    formData.append('did', values.did || 'null');
    formData.append('template_data', `${values.template_data || null}`);

    if (user.hasRoles([Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN])) {
      formData.append('reviewed_at', moment().toISOString());
      formData.append('reviewed_by', `${user.person_id}`);
      formData.append('approved', `${true}`);
    }

    try {
      await createNewDocument(formData as any);
      setShowModal(false);
      SuccessAlert('Nový dokument úspěšně vytvořen');
      setUploadedFiles([]);
      qClient.invalidateQueries(['person-documents']);
    } catch {
      ErrorAlert('Při vytváření dokumentu došlo k chybě');
    }
  };

  const getIsDisabledValidFromDate = (date: Moment) => {
    let isDateDisabled = false;
    if (user.hasRoles([Roles.SUPER_ADMIN])) return isDateDisabled;

    const fifthOfThisMonth = moment().set('date', 1).add(4, 'days');

    if (user.hasRoles([Roles.TYMBE_ADMIN]) && moment().isSameOrBefore(fifthOfThisMonth, 'day')) {
      isDateDisabled = date.isBefore(moment().subtract(2, 'month').endOf('month'));
    } else {
      isDateDisabled = date.isBefore(moment().subtract(1, 'month').endOf('month'));
    }
    return isDateDisabled;
  };

  return (
    <Protect
      auth={[
        Roles.SUPER_ADMIN,
        Roles.TYMBE_ADMIN,
        Roles.ADMIN,
        Roles.TYMBE_COORDINATOR,
      ]}
      redirect={false}
    >
      <div>
        <SecondaryButton
          className="flex flex-row justify-center align-center gap-2"
          onClick={() => setShowModal(true)}
        >
          <PlusOutlinedIcon />
          <span> Nový dokument </span>
        </SecondaryButton>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="My dialog"
        className={styles.modal}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <div className={styles.header}>
          <div>
            <PageTitle>Přidat dokument</PageTitle>
          </div>
          <div>
            <button
              aria-label="close button"
              type="button"
              className={styles.close}
              onClick={() => setShowModal(false)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className={styles.children}>
          <Form onSubmit={onSubmit}>
            <div className="grid md:grid-cols-2">
              <div>
                <div>
                  <h3>Typ dokumentu</h3>
                  <TyAsyncSelect
                    loadOptions={loadNewDocumentOptions}
                    name="document_type"
                    label="Typ dokumentu"
                    isClearable
                    required
                  />
                </div>
                <div>
                  <h3>Firma</h3>
                  <TySelectCompany label="Firma" name="company" />
                </div>
                <div>
                  <h3>Provozovna/Oddělení</h3>
                  <TySelectBranchoffice label="Provozovna/Oddělení" name="branchoffice" />
                </div>
                <div>
                  <h3>Název</h3>
                  <TyInput label="Název" name="name" />
                </div>
              </div>
              <div>
                <div>
                  <h3>Platnost od</h3>
                  <TyDateInput
                    label="Platnost od"
                    name="valid_from"
                    disabledDate={getIsDisabledValidFromDate}
                    validate={(date) => (getIsDisabledValidFromDate(date) ? 'Nevalidní vstup' : undefined)}
                    required
                  />
                </div>
                <div>
                  <h3>Platnost do</h3>
                  <TyDateInput
                    label="Platnost do"
                    name="valid_to"
                    className="absolute"
                    validate={(date) => (getIsDisabledValidFromDate(date) ? 'Nevalidní vstup' : undefined)}
                    required
                  />
                </div>

                <div>
                  <h3>Číslo dokumentu</h3>
                  <TyInput label="Číslo dokumentu" name="did" />
                </div>
                <div>
                  <h3>Proměnné smlouvy</h3>
                  <TyInput
                    hidden={!user.hasRoles([Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN])}
                    label="Proměnné smlouvy"
                    name="template_data"
                  />
                </div>
              </div>
              <div>
                <h3>Soubory</h3>
                <UploadDocumentFile setUploadedFiles={setUploadedFiles} uploadedFiles={uploadedFiles} />
              </div>
            </div>
            <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
            <SecondaryButton className="ml-3" buttontext="Zavřít" onClick={() => setShowModal(false)} />
          </Form>
        </div>
      </Modal>
    </Protect>

  );
};

export default NewDocument;
