const enum ShiftState {
  PAYMENT_TRANSACTION,
  PAYMENT_REQUEST,
  SURVEY,
  ATTENDANCE_OK,
  ATTENDANCE_CANCEL,
  ATTENDANCE_BREACH_OF_DISCIPLINE,
  ATTENDANCE_ADMIN_EXCUSED_ABSENCE,
  ATTENDANCE_EXCUSED_ABSENCE,
  ATTENDANCE_UNEXCUSED_ABSENCE,
  SHIFT_HAPPENED,
  APPLICATION_CONFIRMED,
  APPLICATION_REJECTED,
  APPLICATION_CANCELED_EARLY,
  APPLICATION_CANCELED_LATE,
  APPLICATION_SYSTEM_CANCELED,
  INVITATION,
  DEFAULT,
}
export default ShiftState;
