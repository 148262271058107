import { OutputData } from '@editorjs/editorjs';
import { CountryCodes, EU_EEA_SWISS_COUNTRY_CODES } from '@tymbe/schema/enums';

import { CountryCodesAndNames } from '../components/inputs/TySelectCountry/TySelectCountry.utils';

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export const isOutputData = (val: unknown): val is OutputData =>
  val !== null
  && val !== undefined
  && typeof val === 'object' && 'version' in val && 'time' in val && 'blocks' in val && Array.isArray(val.blocks);

export const euEeaSwitzCountryCodeNames = () => {
  const arrayOfAllCountries = CountryCodesAndNames();
  return Object.fromEntries(Object.entries(arrayOfAllCountries).filter(
    ([code]) => EU_EEA_SWISS_COUNTRY_CODES.includes(code as CountryCodes),
  ));
};
