import classNames from 'classnames';
import { ComponentProps } from 'react';

type IconButtonOwnProps = {
  className?: string,
};
type IconButtonProps = IconButtonOwnProps & Omit<ComponentProps<'button'>, keyof IconButtonOwnProps>;

const IconButton = ({ children, className = '', ...rest }: IconButtonProps) => (
  <button
    type="button"
    className={
      classNames(
        'border p-1 !rounded-lg flex justify-center items-center hover:bg-secondary-150 active:bg-secondary-300 w-6',
        className,
      )
    }
    {...rest}
  >
    {children}
  </button>
);

export default IconButton;
