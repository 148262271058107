import { createContext, FC, ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';

import feathersClient from '.';
import { LoginData, PersonData, RolesData } from '../types/TymbeApi';
import { Roles } from '../utils/enums';

const ApiContext = createContext(feathersClient);
export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) throw new Error('useApi must be used within a ApiProvider');
  return context;
};
// eslint-disable-next-line import/prefer-default-export
export const ApiProvider: FC<{ children: ReactNode; }> = ({ children }) =>
  <ApiContext.Provider value={feathersClient}>{children}</ApiContext.Provider>;

export interface UseUserInterface extends LoginData {
  // type of result returned by app.authenticate() and app.reAuthenticate()
  person: PersonData;
  role: RolesData[];
  hasRoles: (role: Roles[]) => boolean;
}

export const useUser = (): UseUserInterface => {
  const api = useContext(ApiContext);
  if (api === undefined) throw new Error('useUser must be used within a ApiProvider');

  const res = useQuery(['reAuthenticate'], async () => api.reAuthenticate(), { retry: false, staleTime: 10000 });

  const { data: { user } = {} } = res;

  const u = {
    ...user,
    hasRoles: (roles: Roles[]) => user?.role.some((x: RolesData) => roles.some((r) => r === x.slug)),
  } as UseUserInterface;

  return u;
};

export const usePersonData = (id: number): PersonData => {
  const api = useContext(ApiContext);
  if (api === undefined) throw new Error('usePersonData must be used within a ApiProvider');
  // If your query function depends on a variable, include it in your query key
  const res = useQuery(['person-data', id], async () => api.service('person-data').get(id), { retry: false, staleTime: 10000, enabled: !!id });

  const { data } = res;

  return data;
};
