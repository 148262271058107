import { useMutation, useQuery } from 'react-query';

import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { PersonDocumentFileData } from '../../../types/TymbeApi';

export interface DocumentListProps {
  documentId: number;
}

export function initDownload(response: Blob, filename: string) {
  const url = URL.createObjectURL(new Blob([response], { type: response.type }));
  const link = document.createElement('a');
  link.href = url;
  const fileName = filename;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
}

const DocumentList = ({ documentId }: DocumentListProps) => {
  const { data: personDocuments } = useQuery(['person-document-list', documentId], async () =>
    feathersClient.service('person-document').get(documentId, {
      query: {
        $eager: '[personDocumentFile]',
      },
    }));

  const { mutateAsync: getDocument } = useMutation(['load-document'], async (id: string) =>
    feathersClient.service('bucket').get(
      id,
      {
        query: {
          $download: true,
        },
        connection: {
          responseType: 'blob',
        },
      },
    ));

  // creates unique file name
  const splitBySlash = (str: string) => {
    const lastIndex = str.lastIndexOf('/');
    return str.slice(lastIndex + 1);
  };

  const downloadDocument = async (id: string) => {
    let documentResponse;
    try {
      documentResponse = await getDocument(id);
      initDownload(documentResponse, id);
      SuccessAlert('Soubor úspěšně uložen');
    } catch {
      ErrorAlert('Při stahování souboru došlo k chybě');
    }
  };
  return (
    <div>{personDocuments?.personDocumentFile.length === 0
      ? <div className="p-4">Žádné soubory ke stažení</div>
      : personDocuments?.personDocumentFile.map((documentFile: PersonDocumentFileData) => (
        <button
          className="hover:underline"
          key={documentFile.file}
          onClick={() => downloadDocument(documentFile.file)}
          type="button"
        >
          {splitBySlash(documentFile.file)}
        </button>
      ))}
    </div>
  );
};

export default DocumentList;
