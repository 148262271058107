import { ReactNode } from 'react';

import { IconButton } from '../../../components/buttons';
import { CloseIcon, CrossIcon } from '../../../components/icons';
import ModalShell from '../../../components/modals/ModalShell';

interface ExportModalProps {
  onClose: () => void;
  onConfirm: () => void;
  showModal: boolean;
  children: ReactNode;
}

const ExportModal = ({
  showModal,
  onClose,
  onConfirm,
  children,
}: ExportModalProps) => (
  <ModalShell
    showModal={showModal}
    onClose={onClose}
  >
    <IconButton
      style={{ position: 'absolute', top: 5, right: 5 }}
      onClick={onClose}
    ><CrossIcon />
    </IconButton>
    <div className="flex flex-row justify-between items-center">
      <h2>Export CSV</h2>
      <div>
        <button
          aria-label="export csv button"
          type="button"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
    <div className="p-5">
      {children}
    </div>
    <div className="flex gap-x-1 justify-end">
      <button
        type="button"
        className="ty-button-secondary"
        onClick={onClose}
      >
        Zavřít
      </button>
      <button
        type="submit"
        className="ty-button-primary"
        onClick={onConfirm}
      >
        Uložit
      </button>
    </div>
  </ModalShell>
);

export default ExportModal;
