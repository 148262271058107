import PropTypes from 'prop-types';

import { FixedGrid } from '../grids';

/** @type React.FC<PropTypes.InferType<Illustration.propTypes>> */
const Illustration = ({ size = '', extraStyle = {}, svg }) => {
  let style;
  if (size === 'full') {
    style = { position: 'absolute', left: 0, right: 0 };
  }
  return (
    <FixedGrid justify="center">
      <div
        style={{
          position: 'relative',
          maxHeight: '300px',
          margin: '35px 10px',
          ...extraStyle,
        }}
      >
        <div className="svg-illustration" style={style}>
          {svg}
        </div>
      </div>
    </FixedGrid>
  );
};

Illustration.propTypes = {
  size: PropTypes.string,
  // Will be replaced by typescript
  // eslint-disable-next-line react/forbid-prop-types
  extraStyle: PropTypes.object,
  svg: PropTypes.element.isRequired,
};
export default Illustration;
