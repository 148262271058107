import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import SignaturePad from 'react-signature-pad-wrapper';

import styles from './UploadSign.module.css';
import { darkBlue } from '../../utils/colors';
import { UploadIcon } from '../icons';

export type UploadSignProps = {
  onChange: (blob: Blob) => void
  uploadLabel?: string
  modalTitle?: string
};

const UploadSign: React.FC<UploadSignProps> = ({
  onChange,
  uploadLabel = 'Vložit podpis',
  modalTitle = 'Podpis, prosím',
}) => {
  const [showModal, setShowModal] = useState(false);

  const padRef = useRef<SignaturePad>(null);

  const onConfirm = () => {
    if (!padRef.current?.isEmpty()) {
      padRef.current?.canvas.current?.toBlob((e) => {
        if (!e) return;
        onChange(e);
      });
      setShowModal(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <button
          type="button"
          className="ty-button-secondary ty-upload-button d-flex"
          onClick={() => setShowModal(true)}
        >
          <UploadIcon iconcolor={darkBlue} />
          <span>{uploadLabel}</span>
        </button>
      </div>

      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          contentLabel="My dialog"
          className={styles.modal}
          overlayClassName="ty-uploaded-image-modal-overlay"
        >
          <h4 className="ty-h4 text-align-start">{modalTitle}</h4>
          <div className={styles.pad}>
            <SignaturePad ref={padRef} />
          </div>
          <br />
          <div className="d-flex justify-content-between align-center">
            <button
              type="button"
              className="ty-button-primary"
              onClick={onConfirm}
            >
              Potvrdit
            </button>

            <button
              type="button"
              className="ty-button-secondary"
              onClick={() => padRef.current?.clear()}
            >
              Smazat
            </button>

            <button
              type="button"
              className="ty-button-secondary"
              onClick={() => setShowModal(false)}
            >
              Zrušit
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UploadSign;
