import React from 'react';

interface LocaleContextValue {
  locale: string | undefined;
  setLocale: (locale: string) => void;
}

const LocaleContext = React.createContext<LocaleContextValue>({
  locale: undefined,
  setLocale: () => {},
});

export default LocaleContext;
