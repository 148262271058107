import { Link } from 'react-router-dom';

const blocksColumns = [
  {
    title: 'Firma',
    dataIndex: 'company_name',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Provozovna/Oddělení',
    dataIndex: 'branchoffice_name',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'ID Tymber',
    dataIndex: 'tymber_id',
    align: 'left',
    render: (id: number) => (
      <Link
        target="_blank"
        to={{
          pathname: `/user/${id}`,
        }}
        className="ty-poppins-12"
      >
        {id}
      </Link>
    ),
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Jméno',
    dataIndex: 'tymber_firstname',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Příjmení',
    dataIndex: 'tymber_lastname',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Důvod',
    dataIndex: 'block_reason',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Datum blokace',
    dataIndex: 'block_date',
    sorter: true,
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Autor blokace (ID Autora)',
    dataIndex: 'created_by',
    align: 'left',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
];

export default blocksColumns;
