import { useFormApi, FormProps, InformedProps, useFormState } from 'informed';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { useCallback, useEffect, useState } from 'react';

import styles from './ShiftForm.module.css';
import { useUser } from '../../../../apiClient/ApiContext';
import Protect from '../../../../apiClient/Protect';
import TyDateInput from '../../../../components/inputs/TyDateInput';
import TyInput from '../../../../components/inputs/TyInput';
import TyMultiDateInput from '../../../../components/inputs/TyMultiDateInput';
import TySelectBranchoffice from '../../../../components/inputs/TySelectBranchoffice';
import TySelectCompany from '../../../../components/inputs/TySelectCompany';
import TySelectPerson from '../../../../components/inputs/TySelectPerson';
import TySelectRequirements from '../../../../components/inputs/TySelectRequirements';
import TySelectShiftTemplate from '../../../../components/inputs/TySelectShiftTemplate';
import TySelectUtility from '../../../../components/inputs/TySelectUtility/TySelectUtility';
import getValidateTymberBlocked from '../../../../components/inputs/Validators/tymberBlock';
import {
  DocumentTypeData,
  isPerkData,
  PerkData,
  PersonData,
  ShiftData,
  isDocumentTypeData,
} from '../../../../types/TymbeApi';
import { removeEmptyDecimals } from '../../../../utils';
import { Roles } from '../../../../utils/enums';
import { calculateWorkTime } from '../../../../utils/time';

export interface ShiftFormValues extends ShiftData {
  requirements: (DocumentTypeData | PerkData)[];
  shift_length: string | undefined;
  quantity: number;
  invitation_list: PersonData[] | undefined;
  start_date: string[] | undefined;
  contact: string;
}

export const validateContactInfo = (contact?: string | null) => {
  if (contact) {
    const isContactInfoValid = /(?:\d{3}[ ]?){3}/.test(contact);
    if (isContactInfoValid) return undefined;
  }
  return 'Pole musí obsahovat telefonní číslo';
};

type OwnProps = {
  shiftId?: string | number;
  companyId?: number;
  hideRequirements?: (PerkData | DocumentTypeData)[];
};

type ShiftFormProps = InformedProps<FormProps & OwnProps, ShiftFormValues>;

const ShiftForm = ({
  companyId,
  hideRequirements = [],
}: ShiftFormProps) => {
  const formApi = useFormApi<ShiftFormValues>();
  const formState = useFormState<ShiftFormValues>();
  const user = useUser();

  const [recapShiftLength, setRecapShiftLength] = useState<string>();
  const [queryCompanyId, setQueryCompanyId] = useState<number | undefined>();

  // Check if companyId is passed from controller (if is, user is not an admin
  // but company level user). These 2 useEffects are here just for combining two
  // sources of possible `company_id` into one variable (state)
  useEffect(() => {
    if (!companyId) return;
    setQueryCompanyId(companyId);
  }, [companyId]);

  useEffect(() => {
    if (!formState.values.company) return;
    setQueryCompanyId(formState.values.company.id);
  }, [formState.values.company]);

  useEffect(() => {
    // Calculate shift-time duration
    if (!formState.values.start_time || !formState.values.end_time) return;
    const startTime = moment(formState.values.start_time);
    const endTime = moment(formState.values.end_time);

    if (endTime <= startTime) {
      endTime.add(1, 'day');
    }

    const rawShiftDuration = calculateWorkTime(startTime, endTime, true, 'hours').toFixed(2);
    formApi.setValue(
      'shift_length',
      (`${removeEmptyDecimals(rawShiftDuration, 0, 2)} hod`),
    );
    setRecapShiftLength(calculateWorkTime(startTime, endTime, false, 'hours').toFixed(2));
  }, [formApi, formState.values.end_time, formState.values.start_time]);

  const isReadonlyCompany = formState.values.company?.is_readonly;
  const canEdit = !isReadonlyCompany || user.role.some((role) => role.slug === Roles.SUPER_ADMIN);

  const validateMaxShiftHours = () => {
    if (!formState.values.start_time || !formState.values.end_time) return undefined;
    const startTime = moment(formState.values.start_time);
    const endTime = moment(formState.values.end_time);

    if (endTime <= startTime) {
      endTime.add(1, 'day');
    }

    const rawShiftDuration = calculateWorkTime(startTime, endTime, true, 'hours').toFixed(2);

    if (Number(rawShiftDuration) > 13) return 'Směna nemůže být delší než 13 hodin';

    return undefined;
  };

  const validateBlocked = useCallback((value) => getValidateTymberBlocked(formState.values.start_date, {
    id: formState.values.company?.id,
    branchoffice: formState.values.branchoffice?.id,
    department: formState.values.department?.id,
  })(value), [formState.values.start_date, formState.values.company?.id,
    formState.values.branchoffice?.id, formState.values.department?.id,
  ]);

  const getDisabledDate = (date: moment.Moment) => {
    if (user.role.some((role) => Roles.SUPER_ADMIN === role.slug)) {
      if (isReadonlyCompany) {
        return date.isAfter(moment());
      }
      return false;
    }
    if (user.role.some((role) => Roles.TYMBE_ADMIN === role.slug)) {
      return date.isBefore(moment(), 'month');
    }
    return date.isBefore(moment(), 'day');
  };
  const range = (start: number, end: number) => Array(end - start + 1).fill(undefined).map((_, idx) => start + idx);

  const disabledTime = (date: moment.Moment) => {
    const today = moment();
    const todayPlusTwo = moment(today).add(2, 'hour');
    const start_date = formApi.getValue('start_date');
    const hasSameDayAsShiftStart = start_date?.some((datee) => moment(datee).isSame(today, 'day')
    || moment(datee).isSame(todayPlusTwo, 'day'));

    if (user.role.some(
      (role) => [Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN, Roles.TYMBE_COORDINATOR].includes(role.slug),
    )
    ) {
      if (isReadonlyCompany && hasSameDayAsShiftStart) {
        return {
          disabledHours: () =>
            range(today.hour(), 23),
          disabledMinutes: () => [],
        };
      }
      return {};
    }
    if (hasSameDayAsShiftStart) {
      const start_time = formApi.getValue('start_time');
      return {
        disabledHours: () =>
          range(0, todayPlusTwo.hour() - 1),
        disabledMinutes: () => {
          if (moment(start_time).hour() !== todayPlusTwo.hour()) {
            return [];
          }
          return range(0, todayPlusTwo.minutes());
        },
      };
    } return {};
  };

  const disabledTimeEnd = (date: moment.Moment) => {
    if (isReadonlyCompany) {
      return disabledTime(date);
    }
    return {};
  };

  const contactInfoOverlay = (
    <div className="w-60">
      Vyplňte prosím telefonní číslo na osobu přítomnou na dané směně.
      Je opravdu důležité v akutních případech,
      kdy brigádníka na cestě k vám zastaví nějaká překážka (např. zamčené dveře),
      kterou by rád překonal a odmakal si nahlášenou směnu.
      Tento nouzový kontakt je viditelný pouze brigádníkům přihlášeným na danou směnu.
    </div>
  );

  return (
    <>
      <h2> Údaje objednávky </h2>
      {companyId ? (
        null
      ) : (
        <Protect
          auth={[
            Roles.SUPER_ADMIN,
            Roles.TYMBE_ADMIN,
            Roles.TYMBE_COORDINATOR,
          ]}
          redirect={false}
        >
          <TySelectCompany
            value={formState.values.company}
            name="company"
            id="company_select"
            label="Firma"
            required="Povinné pole"
            onChange={() => {
              formApi.setValue('branchoffice');
            }}
          />
        </Protect>
      )}
      <div className={styles.row}>
        <div className={styles.column}>
          <TySelectBranchoffice
            id="branchoffice_select"
            name="branchoffice"
            label="Provozovna "
            type="branchoffice"
            defaultOptions
            openMenuOnFocus
            companyId={queryCompanyId || companyId}
            cacheUniqs={[queryCompanyId, companyId]}
            isDisabled={(!queryCompanyId && !companyId) || !canEdit}
            autoSelectSingle
            required
            onChange={() => {
              formApi.setValue('department');
            }}
          />
        </div>
        <div className={styles.column}>
          <TySelectBranchoffice
            id="department_select"
            name="department"
            label="Oddělení"
            parentId={formState.values.branchoffice?.id}
            defaultOptions
            openMenuOnFocus
            companyId={queryCompanyId || companyId}
            cacheUniqs={[
              queryCompanyId,
              companyId,
              formState.values.branchoffice?.id,
            ]}
            isDisabled={!formState.values.branchoffice?.id || !canEdit}
            autoSelectSingle
            required
            onChange={() => {
              formApi.setValue('shift_template');
            }}
          />
        </div>
      </div>
      <TySelectShiftTemplate
        companyId={queryCompanyId || companyId}
        branchofficeId={formState.values.department?.id}
        publishedOnly
        id="shift_template_select"
        name="shift_template"
        label="Pracovní vzor"
        required="Povinné pole"
        defaultOptions
        openMenuOnFocus
        isDisabled={!formState.values.department?.id || !canEdit}
        cacheUniqs={[formState.values.department?.id]}
        autoSelectSingle
        onChange={(shiftTemplate) => {
          const template = Array.isArray(shiftTemplate) ? shiftTemplate[0].value : shiftTemplate.value;
          formApi.setValue('requirements');
          formApi.setValue('utility', template?.utility);
          formApi.setTouched('utility', false);
        }}
      />
      <TySelectRequirements
        id="requirements_select"
        name="requirements"
        label="Podmínky"
        companyId={queryCompanyId || companyId}
        isDisabled={!formState.values.shift_template?.id || !canEdit}
        isClearable={false}
        filterOption={({ data }) => {
          if (isDocumentTypeData(data) && data.name !== 'Student') {
            return false;
          }
          return !hideRequirements.some((x) => (isPerkData(x) ? x.group === data.group : x.id === data.id));
        }}
        isMulti
        userRole={user.role}
      />
      <TySelectUtility
        id="utility_select"
        name="utility"
        label="Pomůcky"
        isDisabled={!formState.values.shift_template?.id || !canEdit}
        isClearable={false}
        isMulti
      />
      <TyInput
        name="quantity"
        label="Počet tymberů"
        type="number"
        min={0}
        required
        showControls={canEdit}
        disabled={!canEdit}
      />
      <Tooltip overlay={contactInfoOverlay}>
        <TyInput
          id="shiftFormContactPerson"
          name="contact"
          label="Nouzový kontakt pro brigádníka"
          disabled={!canEdit}
          validate={validateContactInfo}
          validateOn="change"
        />
      </Tooltip>
      <TyMultiDateInput
        name="start_date"
        label="Datum směny"
        required
        className={styles.w_auto}
        disabledDate={getDisabledDate}
        superNextIcon={null}
        superPrevIcon={null}
        disabled={!canEdit}
        highlightHolidays
      />
      <div className={styles.row}>
        <div className={styles.column}>
          <TyDateInput
            name="start_time"
            label="Pracovní doba od"
            picker="time"
            minuteStep={5}
            required
            className={styles.w_auto}
            disabledTime={(date) => disabledTime(date)}
            disabled={!canEdit}
          />
        </div>
        <div className={styles.column}>
          <TyDateInput
            name="end_time"
            label="Pracovní doba do"
            picker="time"
            minuteStep={5}
            required
            className={styles.w_auto}
            disabledTime={(date) => disabledTimeEnd(date)}
            disabled={!canEdit}
          />
        </div>
      </div>
      <div className={`${styles.row} ${styles.center_vertical}`}>
        <div className={styles.column}>
          <TyInput
            name="shift_length"
            label="Délka směny"
            disabled
            className={styles.w_auto}
            validate={validateMaxShiftHours}
            validateOn="change"
            showErrorIfError
          />
        </div>
        <div className={`${styles.column} ${styles.recap_shift_length_text}`}>
          {recapShiftLength ? (
            <span>
              {removeEmptyDecimals(recapShiftLength, 0, 2)} hod bez přestávky
            </span>
          ) : ''}
        </div>
      </div>
      <TySelectPerson
        id="invitation_select"
        name="invitation_list"
        label="Pozvánky"
        isMulti
        filter={{ role: 'tymber' }}
        company={{
          id: formState.values.company?.id,
          branchoffice: formState.values.branchoffice?.id,
          department: formState.values.department?.id,
        }}
        isDisabled={!canEdit}
        limitToRelatedCompanies={false}
        validate={validateBlocked}
      />
    </>

  );
};

export default ShiftForm;
