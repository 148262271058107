import { getBlocks, isBlocked } from '@tymbe/utils/company-blocked-user';
import classNames from 'classnames';
import { FormState, useFormApi, useFormState } from 'informed';
import moment from 'moment';
import { ComponentProps } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import TySelectPerson from '../../../../components/inputs/TySelectPerson';
import Spinner from '../../../../components/Spinner';
import { ApplicationData, ApplicationState, PersonData, ShiftData } from '../../../../types/TymbeApi';
import FillPill from '../FillPill';
import AccountIcon from '../icons/AccountIcon';
import CheckIcon from '../icons/CheckIcon';
import CrossIcon from '../icons/CrossIcon';
import QuestionIcon from '../icons/QuestionIcon';
import TrashIcon from '../icons/TrashIcon';
import Options from '../Options';
import { formatJobEval } from '../ShiftCreate/utils';

type ParticipantsOwnProps = {
  current: number,
  total: number,
  onInvite: (form: FormState<ParticipantsFormValues>) => Promise<void>,
  applications?: ApplicationData[],
  exclude?: number[],
  shift: ShiftData,
};
type ParticipantsProps = ParticipantsOwnProps & Omit<ComponentProps<'div'>, keyof ParticipantsOwnProps>;

export type ParticipantsFormValues = {
  personSelect: PersonData[]
};

const Participants = ({
  current,
  total,
  onInvite,
  applications,
  exclude = [],
  className = '',
  shift,
  ...rest
}: ParticipantsProps) => {
  const queryClient = useQueryClient();
  const participantsFormApi = useFormApi();
  const participantsFormState = useFormState<ParticipantsFormValues>();
  const company = {
    id: shift.company_id,
    branchoffice: shift.branchoffice?.parent_id,
    department: shift.branchoffice_id,
  };

  const handleSubmit = async () => {
    await onInvite(participantsFormState);
    participantsFormApi.reset();
  };
  const { mutateAsync: patchApplication } = useMutation(
    ['patchApplication'],
    async ({ data, appId }:
    { data: Partial<ApplicationData>, appId: number }) => {
      await feathersClient.service('application').patch(appId, data);
    },
    {
      onError: () => {
        ErrorAlert('Při úpravě přihlášky došlo k chybě');
      },
    },
  );

  const canInvite =
    participantsFormState.values.personSelect
      ? ((participantsFormState.values.personSelect
        && participantsFormState.values.personSelect.length
        && !participantsFormState
          .values.personSelect.some((item) => isBlocked(getBlocks(item.blockedCompany, company))))
        || (participantsFormState
          .values.personSelect.some((item) => isBlocked(getBlocks(item.blockedCompany, company)))
          && !moment(shift.start_time).isAfter(moment())))
      : false;

  const getIcon = (state: ApplicationState | null) => {
    switch (state) {
      case ApplicationState.CONFIRMED:
        return <CheckIcon className="w-1.5" />;
      case null:
        return <QuestionIcon className="w-1.5" />;
      default:
        return <CrossIcon className="w-1.5" />;
    }
  };

  const cancelApplication = async (appId: number) => {
    await patchApplication({ appId, data: { state: ApplicationState.SYSTEM_CANCELED } });
    queryClient.invalidateQueries();
  };

  return (
    <div className={classNames('p-4 rounded-xl border border-secondary-100', className)} {...rest}>
      <div className="font-medium text-sm mb-3 flex gap-[5px] items-center">
        Účastníci <FillPill current={current} total={total} />
      </div>
      <div className="flex gap-2 mb-3">
        <TySelectPerson
          name="personSelect"
          isMulti
          className="cal-person-select"
          exclude={{ personIds: exclude }}
          filter={{ role: 'tymber' }}
          placeholder="Jméno, email, ID"
          filterOption={(option) => !exclude.includes(option.data?.id)}
          company={company}
        />
        <button
          type="button"
          className="h-8 text-primary-800 bg-primary-200 font-semibold text-xs disabled:text-secondary-400 px-3 py-1.5 flex items-center disabled:bg-secondary-100 !rounded-full disabled:cursor-not-allowed"
          onClick={handleSubmit}
          disabled={!canInvite}
        >
          Pozvat
        </button>
      </div>
      <div>
        {applications
          && applications.map((app) => (
            <div
              key={app.id}
              className="py-0.5 px-2 flex justify-between items-center hover:bg-secondary-50 rounded-lg"
            >
              <div className="flex gap-2 items-center">
                <div className="size-6 bg-fg-300 rounded-full text-bg text-center align-middle leading-6 relative">
                  {app.person?.first_name[0]}
                  <div className={
                    classNames(
                      'flex justify-center items-center size-2.5 border-[1.5px] border-bg rounded-full absolute -right-1 -bottom-1 box-content',
                      { 'bg-primary-300': app.state === ApplicationState.CONFIRMED },
                      { 'bg-secondary-300': app.state === null },
                      { 'bg-error': app.state !== null && app.state !== ApplicationState.CONFIRMED },
                    )
                  }
                  >
                    {getIcon(app.state)}
                  </div>
                </div>
                <div className="">
                  <div className="text-secondary-700 font-medium text-sm">
                    {app.person?.first_name} {app.person?.last_name}
                  </div>
                  <div className="text-secondary-400 text-xs">
                    {app.person_id} • {moment(app.person?.personData?.birthdate).toNow(true)}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div>{app.person?.personData ? formatJobEval(app.person.personData.job_evaluation) : <Spinner className="w-4" show />}</div>
                <Options
                  menuOptions={[{
                    text: 'Detail',
                    icon: <AccountIcon className="w-[18px] text-secondary-400" />,
                    onClick: () => { window.open(`/user/${app.person_id}`, '_blank'); },
                  },
                  {
                    text: 'Odebrat',
                    icon: <TrashIcon className="w-[18px] text-secondary-400 group-disabled:text-secondary-200" />,
                    onClick: () => cancelApplication(app.id),
                    disabled: app.state !== null,
                  },
                  ]}
                  className="text-secondary-600"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Participants;
