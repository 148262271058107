import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';
import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { SubmitButton } from '../../../../../components/buttons';
import Wrapper from '../../../../../components/wrapper';
import Container from '../../../../../containers';
import Spinner from '../../../../../utils/spinner';
import DepartmentForm, { DepartmentFormValues } from '../components/DepartmentForm';
import mapFormValuesToDepartment from '../Department.utils';
import { getValuesForPatch } from '../../../../../components/forms/form-util';

const EditDepartment = () => {
  const { companyId, departmentId } = useParams();
  const history = useNavigate();
  const queryClient = useQueryClient();
  const [formDisabled, setFormDisabled] = useState(false);

  const { data: company, isLoading: isLoadingCompany } = useQuery(
    ['FetchCompany', companyId],
    async () => feathersClient.service('company').get(
      Number(companyId),
    ),
    { enabled: !!companyId },
  );

  const { data: department, isLoading: isLoadingDepartment } = useQuery(
    ['FetchDepartment', departmentId],
    async () => feathersClient.service('branchoffice').get(
      Number(departmentId),
      {
        query: { $eager: '[parent, contactPerson.[company,contact,login]]' },
      },
    ),
    { enabled: !!departmentId },
  );

  const onSubmit = async (formState: FormState<BranchofficeData>) => {
    setFormDisabled(true);
    const modifiedAndNulled = getValuesForPatch(formState);
    const data = mapFormValuesToDepartment(modifiedAndNulled as DepartmentFormValues, Number(companyId));

    try {
      await feathersClient.service('branchoffice').patch(department.id, data);
      SuccessAlert('Oddělení upraveno');
      queryClient.invalidateQueries(['FetchDepartment', departmentId]);
      history(`/company/${companyId}/department`);
    } catch {
      ErrorAlert('Nastala chyba');
    }

    setFormDisabled(false);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      {isLoadingCompany || isLoadingDepartment ? (<Spinner />) : (
        <Wrapper padding="0px" margin="0px 22px 18px 31px">
          <h1>Editace oddělení</h1>
          <Form name="create_department_form" onSubmit={onSubmit} initialValues={department}>
            <DepartmentForm company={company} department={department} />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Wrapper>
      )}
    </Container>
  );
};

export default EditDepartment;
