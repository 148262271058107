import { Result } from 'antd';
import PropTypes from 'prop-types';

const SuccessResult = ({ title, subTitle, extra }) => (
  <Result
    className="ty-result-success"
    status="success"
    title={title}
    subTitle={subTitle}
    extra={extra}
  />
);
SuccessResult.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  extra: PropTypes.arrayOf(PropTypes.element).isRequired,
};

const ErrorResult = ({ title, subTitle, extra }) => (
  <Result
    className="ty-result-error"
    status="error"
    title={title}
    subTitle={subTitle}
    extra={extra}
  />
);
ErrorResult.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  extra: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export { SuccessResult, ErrorResult };
