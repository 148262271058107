import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../apiClient';

export const forgotPasswordThunk = createAsyncThunk('auth/forgotPassword', async (email) => {
  await feathersClient.service('auth-management').create({
    action: 'sendResetPassword',
    email,
  });
});

const forgotPasswordReducer = {
  [forgotPasswordThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [forgotPasswordThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [forgotPasswordThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPasswordContainer',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    ...forgotPasswordReducer,
  },
});

export const selectForgotPassword = (state) => state.data;

export default forgotPasswordSlice.reducer;
