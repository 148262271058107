// TODO: We will remove whole redux
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const loadCompanyDetailsThunk = createAsyncThunk('company/companyDetail', async (id) => feathersClient.service('company').get(id, {
  query: {
    $eager: '[address, branchoffice, contactPerson]',
  },
}));

const loadCompanyDetailsReducer = {
  [loadCompanyDetailsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadCompanyDetailsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.data = null;
  },
  [loadCompanyDetailsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.data = action.payload;
  },
};

const companyDetailSlice = createSlice({
  name: 'companyContainer',
  initialState: {
    loading: null,
    error: null,
    pagination: {
      total: null,
      skip: 0,
      limit: 10,
    },
  },
  reducers: {},
  extraReducers: {
    ...loadCompanyDetailsReducer,
  },
});

export const selectCompanyDetails = (state) => state.data;

export const selectCompanyPagination = (state) => state.pagination;

export default companyDetailSlice.reducer;
