import { SVGProps } from 'react';

type QuestionIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const QuestionIcon = (props: QuestionIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.0894 3.05978C13.0537 2.74301 15.0893 3.70611 16.4047 5.57116L16.4072 5.57025C18.1166 7.98304 17.4552 10.5547 16.1163 11.924L14.9904 13.1007C14.3943 13.7215 14.012 14.2336 13.8208 15.016C13.7211 15.4222 13.6741 15.8422 13.6741 16.3379V16.8756H10.179V15.697C10.179 15.0699 10.2812 14.4436 10.4841 13.8356C10.7566 13.0277 11.2118 12.2828 11.8012 11.6803L13.3539 10.069C13.8292 9.60343 14.0237 8.94706 13.8988 8.25691C13.7714 7.53754 13.2147 6.91769 12.5121 6.71228C11.2931 6.3663 10.3081 7.11852 10.0062 8.00677C9.77905 8.72157 9.19219 9.18989 8.51479 9.18989H8.13836C7.62612 9.18989 7.1625 8.94067 6.86655 8.50521C6.58151 8.08711 6.49767 7.55945 6.63768 7.06101C7.23879 4.96135 8.98511 3.39299 11.0894 3.05978ZM10.1773 18.3632H13.6725V22.0002H10.1773V18.3632Z" />
  </svg>
);

export default QuestionIcon;
