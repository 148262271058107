import { useMutation } from 'react-query';

import feathersClient from '../../../../apiClient';
import { SecondaryButton } from '../../../../components/buttons';
import { ApplicationState } from '../../../../types/TymbeApi';

export interface CancelInvitationButtonProps {
  applicationId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
  onError?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const CancelInvitationButton = ({ applicationId, onSuccess, onError, ...props }: CancelInvitationButtonProps) => {
  const { mutateAsync: patchInvitation } = useMutation(
    ['application', { id: applicationId }],
    async () => {
      await feathersClient.service('application').patch(applicationId, { state: ApplicationState.SYSTEM_CANCELED });
    },
    {
      onSuccess,
      onError,
    },
  );
  return (
    <div className="p-2 text-right">
      <SecondaryButton onClick={patchInvitation} {...props}>
        Zrušit pozvánku
      </SecondaryButton>
    </div>
  );
};

export default CancelInvitationButton;
