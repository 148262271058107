import { SalaryAdvanceRestrictionStatus } from '@tymbe/schema/enums';
import { Form, FormState } from 'informed';
import Modal from 'react-modal';

import { ForeclosureData } from '../../pages/administration/foreclosures/foreclosure.types';
import CloseIcon from '../icons/CloseIcon';
import TyAsyncSelect from '../inputs/TyAsyncSelect';
import TyDateInput from '../inputs/TyDateInput';
import { TySelect } from '../inputs/TySelect';
import TySelectCompany from '../inputs/TySelectCompany';
import { PageTitle } from '../texts';
import styles from '../TyAdvancedSearch/Components/Layout.module.css';
import { labelsValues } from '../TyAdvancedSearch/Libs/enums';
import { loadTymberOptions } from '../TyAdvancedSearch/Libs/loadOptions';

type Props = {
  showModal: boolean,
  setShowModal: (show: boolean) => void
  onSubmit: (values: FormState<ForeclosureData>) => Promise<void>
};

const AddForeclosureModal = ({ onSubmit, showModal, setShowModal }: Props) => {
  const typeOptions = [
    {
      label: 'Zadržovat všechno',
      value: SalaryAdvanceRestrictionStatus.FORBIDDEN,
    },
    {
      label: 'Zadržovat od minima',
      value: SalaryAdvanceRestrictionStatus.MIN_ENABLED,
    },
  ];
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="My dialog"
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        <div>
          <PageTitle>Přidat exekuci</PageTitle>
        </div>
        <div>
          <button
            aria-label="close button"
            type="button"
            className={styles.close}
            onClick={() => setShowModal(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className={styles.children}>
        <Form
          onSubmit={(values: FormState<ForeclosureData>) => {
            onSubmit(values);
            setShowModal(false);
          }}
        >
          <div className="grid grid-cols-1 grid-rows-4 md:grid-cols-2 md:grid-rows-2">
            <div>
              <h3>Firma</h3>
              <TySelectCompany
                id="company_select"
                label="Firma"
                name="company"
                required="Vyberte prosím firmu"
              />
            </div>
            <div>
              <h3>Tymber</h3>
              <TyAsyncSelect
                loadOptions={loadTymberOptions}
                name={labelsValues.tymber.name}
                label={labelsValues.tymber.label}
                className={styles.tyInputs}
                required="Vyberte prosím Tymbera"
              />
            </div>
            <div>
              <h3>Platnost od</h3>
              <TyDateInput
                name={labelsValues.dateMonthYearFormat.name}
                label={labelsValues.dateMonthYearFormat.label}
                picker="monthyear"
                className={styles.tyInputs}
                required="Vyberte prosím měsíc a rok"
              />
            </div>
            <div>
              <h3>Typ</h3>
              <TySelect
                id="type_select"
                className=""
                name="type"
                label="Typ"
                options={typeOptions}
                required="Vyberte prosím typ"
              />
            </div>
          </div>
          <svg className="mt-8" width="100%" height="1" viewBox="0 0 1051 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="1" fill="#DBDFEE" />
          </svg>
          <div className="bg-[#F3F5FD] py-8 px-4 flex justify-end">
            <div className={styles.footerRight}>
              <button
                type="button"
                className="ty-button-secondary "
                onClick={() => setShowModal(false)}
              >
                Zavřít
              </button>
              <button
                type="submit"
                className="ty-button-primary"
              >
                Přidat
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddForeclosureModal;
