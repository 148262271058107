import getResponseArray from '@tymbe/utils/getResponseArray';
import classNames from 'classnames';
import { FieldState, useFormApi, useFormState } from 'informed';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import ComboBox from './Combobox';
import apiClient from '../../../apiClient';
import { BranchofficeData, CompanyData } from '../../../types/TymbeApi';

type SelectorForm = {
  company: number;
  branchoffice: number;
  department: number;
};

const CompanyBranchSelector = () => {
  const formApi = useFormApi<SelectorForm>();
  const { values } = useFormState<SelectorForm>();
  const { data, isLoading } = useQuery({
    queryKey: ['company', 'branchoffice', 'department', 'list'],
    queryFn: async () => apiClient.service('company').find({
      query: {
        $eager: 'branchoffice(hasChild).child',
        $limit: -1,
      },
    }),
    refetchOnWindowFocus: false,
  });
  const companies = getResponseArray(data);

  const selectedCompany = companies.find((company) => company.id === Number(values.company));
  const branchoffices = selectedCompany?.branchoffice || [];
  const selectedBranchoffice = branchoffices.find((branchoffice) => branchoffice.id === Number(values.branchoffice));
  const departments = selectedBranchoffice?.child || [];

  const onCompanyChange = useCallback(({ value }: FieldState<number>) => {
    const company = companies.find((c) => c.id === Number(value));
    const branchoffice = company?.branchoffice?.length === 1 ? company?.branchoffice?.[0] : { id: undefined };
    if (branchoffice?.id === Number(formApi.getValue('branchoffice'))) {
      return;
    }
    formApi.setValue('branchoffice', branchoffice?.id);
  }, [companies, formApi]);

  const onBranchofficeChange = useCallback(({ value }: FieldState<number>) => {
    const company = companies.find((c) => c.id === Number(formApi.getValue('company')));
    const branchoffice = company?.branchoffice?.find((b) => b.id === Number(value));
    const department = branchoffice?.child?.length === 1 ? branchoffice.child[0] : { id: undefined };
    if (department.id === Number(formApi.getValue('department'))) {
      return;
    }
    formApi.setValue('department', department.id);
  }, [companies, formApi]);

  const getOptionLabel = useCallback((option: CompanyData | BranchofficeData) => `[${option.id}] ${option.name}`, []);
  const getOptionValue = useCallback((option: CompanyData | BranchofficeData) => option.id, []);
  const getValueOption = useCallback((value: number, options: (CompanyData | BranchofficeData)[]) => options.find((option) => option.id === Number(value)), []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ComboBox
        name="company"
        placeholder="Všechny firmy"
        className="inline-block"
        inputClassName="h-8 border rounded-lg border-secondary-400 text-secondary-900 text-sm font-medium p-2"
        menuClassName="flex flex-col gap-2 bg-bg w-full overflow-x-auto leading-normal my-1 shadow-sm rounded-lg py-2"
        itemClassName="px-2 hover:bg-secondary-50 [&.selected]:bg-secondary-50"
        footerClassName="px-2 text-xs text-gray-500 text-right"
        options={companies}
        onChange={onCompanyChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        getValueOption={getValueOption}
      />

      <ComboBox
        name="branchoffice"
        placeholder="Všechny pobočky"
        className={classNames('inline-block', { hidden: !selectedCompany })}
        inputClassName="h-8 border rounded-lg border-secondary-400 text-secondary-900 text-sm font-medium p-2"
        menuClassName="flex flex-col gap-2 bg-bg w-full overflow-x-auto leading-normal my-1 shadow-sm rounded-lg py-2"
        itemClassName="px-2 hover:bg-secondary-50 [&.selected]:bg-secondary-50"
        footerClassName="px-2 text-xs text-gray-500 text-right"
        options={branchoffices}
        onChange={onBranchofficeChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        getValueOption={getValueOption}
      />

      <ComboBox
        name="department"
        placeholder="Všechna oddělení"
        className={classNames('inline-block', { hidden: !selectedBranchoffice })}
        inputClassName="h-8 border rounded-lg border-secondary-400 text-secondary-900 text-sm font-medium p-2"
        menuClassName="flex flex-col gap-2 bg-bg w-full overflow-x-auto leading-normal my-1 shadow-sm rounded-lg py-2"
        itemClassName="px-2 hover:bg-secondary-50 [&.selected]:bg-secondary-50"
        footerClassName="px-2 text-xs text-gray-500 text-right"
        options={departments}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        getValueOption={getValueOption}
      />
    </>
  );
};

export default CompanyBranchSelector;
