import { MailResult } from '@tymbe/schema/enums';
import { MailData } from '@tymbe/schema/mail.interface';
import { Paginated } from '@feathersjs/feathers';
import { useState } from 'react';
import { useQuery } from 'react-query';

import feathersClient from '../../../../apiClient';
import Card from '../../../../components/Layout/Card';
import MailTable from '../components/MailTable';

const SentEmails = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurentPage] = useState<number>(1);
  const [
    sort,
    setSort,
  ] = useState<{ [key: string]: number }>({ created_at: -1 });

  const { data, isLoading } = useQuery(
    ['admin/fetchHistoryEmails', currentPage, pageSize, sort],
    () => feathersClient.service('mail').find({
      query: {
        $eager: 'login',
        $leftJoinRelation: 'login',
        result: MailResult.SUCCESS,
        $skip: (currentPage - 1) * pageSize,
        $limit: pageSize,
        $sort: sort,

      },
    }) as Promise<Paginated<MailData>>,
  );

  const onChangePage = (pageStart: number, pageEnd: number) => {
    setPageSize(pageEnd - pageStart);
    setCurentPage(Math.ceil(pageEnd / (pageEnd - pageStart)));
  };

  const onSort = (key: string, sortOrder: number) => {
    setSort({ [key]: sortOrder });
  };

  return (
    <Card>
      <MailTable
        onSort={onSort}
        data={data}
        isLoading={isLoading}
        onChangePage={onChangePage}
        activeField={Object.keys(sort)?.[0]}
      />
    </Card>
  );
};

export default SentEmails;
