import { Input, Form, Spin, Select, DatePicker, TimePicker } from 'antd';
import debounce from 'lodash.debounce';
import isArray from 'lodash.isarray';
import isNaN from 'lodash.isnan';
import isNumber from 'lodash.isnumber';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectAdminLoading, selectFilteredUserData } from '../../data/store/slices/admin';
import useURLParamsHandler from '../../hooks/UrlParamsHandler/useURLParamsHandler';
import { primaryColor } from '../../utils/colors';
import { ClockIcon, CalendarIcon } from '../icons';

const { RangePicker } = DatePicker;

const { TextArea, Search } = Input;

export const SimpleInput = ({ prefix, placeholder = undefined }) => (
  <Input prefix={prefix} placeholder={placeholder} />
);
SimpleInput.propTypes = {
  prefix: PropTypes.element.isRequired,
  placeholder: PropTypes.string,
};

export const PasswordInput = ({ placeholder = 'Heslo' }) => (
  <Input.Password placeholder={placeholder} />
);
PasswordInput.propTypes = {
  placeholder: PropTypes.string,
};

export const TextAreaInput = ({
  initialValue = undefined,
  label = undefined,
  name = undefined,
  rules = undefined,
  rows = undefined,
  placeholder = undefined,
}) => (
  <Form.Item initialValue={initialValue} label={label} name={name} rules={rules}>
    <TextArea rows={rows} placeholder={placeholder} />
  </Form.Item>
);
TextAreaInput.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  label: PropTypes.node,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.array,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
};

export const SearchInput = ({ placeholder = undefined, onSearch, style = undefined }) => (
  <Search
    placeholder={placeholder}
    allowClear
    onSearch={onSearch}
    style={style}
  />
);
SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export const SimpleFormInput = ({
  initialValue = undefined,
  label = undefined,
  name,
  rules,
  type = undefined,
  prefix = undefined,
  placeholder = undefined,
  onChange,
  onPaste,
  onKeyDown,
}) => (
  <Form.Item initialValue={initialValue} label={label} name={name} rules={rules}>
    <Input type={type} prefix={prefix} placeholder={placeholder} onChange={onChange} onPaste={onPaste} onKeyDown={onKeyDown} />
  </Form.Item>
);
SimpleFormInput.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  name: PropTypes.string.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string,
  prefix: PropTypes.element,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export const PasswordFormInput = ({ label = undefined, name, rules = undefined, placeholder = 'Heslo' }) => (
  <Form.Item label={label} name={name} rules={rules}>
    <Input.Password placeholder={placeholder} />
  </Form.Item>
);
PasswordFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export const PasswordFormInputWithConfirmation = () => (
  <>
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: 'Povinné pole!',
        },
        {
          min: 6,
          type: 'string',
          message: 'Minimální délka hesla je 6 znaků',
        },
      ]}
    >
      <Input.Password placeholder="Heslo" autoComplete="false" />
    </Form.Item>

    <Form.Item
      name="confirm_password"
      dependencies={['password']}
      rules={[
        {
          required: true,
          message: 'Povinné pole!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Hesla se neshodují!'));
          },
        }),
      ]}
    >
      <Input.Password
        placeholder="Heslo znovu"
        autoComplete="false"
      />
    </Form.Item>

  </>
);

export const SimpleFormItem = ({ label = undefined, name = undefined, rules = undefined, children }) => (
  <Form.Item label={label} name={name} rules={rules}>
    {children}
  </Form.Item>
);
SimpleFormItem.propTypes = {
  name: PropTypes.string,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  rules: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  label: PropTypes.string,
};

export const TableRangePicker = ({ onDateChange, defaultValue = undefined, label = '', ...props }) => {
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const sanitizeDateRange = (dateChange) => {
    const cleanDate = [];
    if (!dateChange) {
      onDateChange(null);
      setUrlParamsHandler({ dateRange: '' });
      return null;
    }
    cleanDate[0] = moment(dateChange[0]).startOf('day');
    cleanDate[1] = moment(dateChange[1]).endOf('day');
    onDateChange(cleanDate);
    setUrlParamsHandler({ dateRange: cleanDate });
    return null;
  };

  return (
    <div>
      <small
        style={{
          marginRight: '10px',
        }}
      >
        {label}
      </small>
      <RangePicker
        defaultValue={allSearchParams.dateRange
          ? allSearchParams.dateRange.map((date) => moment(date)) : defaultValue}
        className="ty-range-picker"
        format="DD.MM.YYYY"
        onChange={sanitizeDateRange}
        ranges={{
          Dnes: [moment(), moment()],
          'Tento týden': [moment().startOf('week'), moment().endOf('week')],
          'Tento měsíc': [moment().startOf('month'), moment().endOf('month')],
        }}
        suffixIcon={<CalendarIcon iconcolor={primaryColor} />}
        placeholder={['Od', 'Do']}
        {...props}
      />
    </div>
  );
};
TableRangePicker.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  label: PropTypes.string,
};

export const TimeRangePicker = ({ onChange, label = undefined, ...props }) => {
  const [timeArray, setTimeArray] = useState([null, null]);
  const [disabledFrom, setDisabledFrom] = useState([]);
  const [disabledTo, setDisabledTo] = useState([]);

  const onTimeChange = (value, index) => {
    const timeArrayCopy = timeArray.slice();
    if (index === 0 || index === 1) {
      timeArrayCopy[index] = value;
      setTimeArray(timeArrayCopy);
    }
    if (timeArray.length === 2) {
      onChange(timeArrayCopy); // onChange() is not a function
    }
    return timeArray;
  };

  useEffect(() => {
    const dayHours = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    ];

    if (timeArray[0]) {
      const hour = timeArray[0].hour();
      setDisabledTo(dayHours.filter((s) => s < hour));
    } else {
      setDisabledTo([]);
    }
    if (timeArray[1]) {
      const hour = timeArray[1].hour();
      setDisabledFrom(dayHours.filter((x) => x > hour));
    } else {
      setDisabledFrom([]);
    }
  }, [timeArray]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'start', marginRight: '14px' }}
      onChange={onChange}
    >
      <div style={{ flexDirection: 'column' }}>
        <small
          style={{
            marginRight: '10px',
          }}
        >
          {label}
        </small>
        <TimePicker
          disabledHours={() => disabledFrom}
          className="ty-range-picker"
          onChange={(val) => onTimeChange(val, 0)}
          suffixIcon={<ClockIcon iconcolor={primaryColor} />}
          placeholder="od"
          {...props}
        />
      </div>
      <div style={{ flexDirection: 'column' }}>
        <small
          style={{
            marginRight: '10px',
          }}
        >
          {label}
        </small>
        <TimePicker
          disabledHours={() => disabledTo}
          className="ty-range-picker"
          onChange={(val) => onTimeChange(val, 1)}
          suffixIcon={<ClockIcon iconcolor={primaryColor} />}
          placeholder="do"
          {...props}
        />
      </div>
    </div>
  );
};
TimeRangePicker.propTypes = {
  onTimeChange: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export const AsyncSearchInput = ({ thunk, debounceTimeout = 500, ...props }) => {
  const [searchParams] = useSearchParams();
  const searchedValue = searchParams.get('search');

  const handleSearch = (value) => {
    const trimmedValue = value.trim();
    // Check if user searching by ID (number) or if the string at least 3 characters long
    if (
      (trimmedValue && !isNaN(+trimmedValue) && isNumber(+trimmedValue))
      || (trimmedValue && trimmedValue.length > 2)
      || value === ''
    ) {
      thunk(trimmedValue);
    }
  };

  const debounceFetcher = debounce(handleSearch, debounceTimeout);
  const onChange = (change) => debounceFetcher(change.target.value);

  useEffect(() => {
    if (searchedValue) {
      debounceFetcher(searchedValue);
    }
    // don't repeat useEffect, trigger it only on load when searchValue exists
  }, []);

  return <Input defaultValue={searchedValue} onChange={onChange} {...props} />;
};
AsyncSearchInput.propTypes = {
  thunk: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
  loading: PropTypes.oneOf([PropTypes.func, PropTypes.bool]),
};

export const AsyncSearchSelectInputField = ({
  fetchOptions,
  optionsSelector = selectFilteredUserData,
  optionsRender = (options) => options.map((x) => ({
    value: x.id,
    label: `${x.first_name} ${x.last_name} (#${x.id})`,
  })),
  debounceTimeout = 500,
  placeholder,
  cleanValues,
  ...props
}) => {
  const fetching = useSelector(selectAdminLoading);
  const options = useSelector(optionsSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const renderOptions = () => {
    if (isArray(options) && options.length === 0) {
      return null;
    }
    return isArray(options)
      ? optionsRender(options)
      : null;
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      // Check if user searching by ID (number) or if the string at least 3 characters long
      if ((value && !isNaN(+value) && isNumber(+value)) || (value && value.length > 2)) {
        fetchOptions(value);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      tagRender={() => null}
      onFocus={() => {
        setShowPlaceholder(false);
      }}
      onBlur={() => {
        setIsOpen(false);
      }}
      onKeyDown={() => {
        setIsOpen(true);
      }}
      open={isOpen}
      onSelect={() => {
        setIsOpen(false);
      }}
      placeholder={showPlaceholder ? placeholder : ''}
      {...props}
      options={renderOptions()}
    />
  );
};
AsyncSearchSelectInputField.propTypes = {
  fetchOptions: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
  cleanValues: PropTypes.func,
  optionsRender: PropTypes.func,
  optionsSelector: PropTypes.func,
  placeholder: PropTypes.string,
};
