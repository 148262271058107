import { CompanyPersonData } from '@tymbe/schema/company-person.interface';
import { RoleData } from '@tymbe/schema/role.interface';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import Protect from '../../../../apiClient/Protect';
import AdminTable from '../../../../components/AdminTable';
import { SecondaryButton } from '../../../../components/buttons';
import { EditIcon, PlusOutlinedIcon } from '../../../../components/icons';
import Card from '../../../../components/Layout/Card';
import SearchBox from '../../../../components/search/SearchBox';
import HoverRow from '../../../../components/tableRenders/HoverRow';
import styles from '../../../../components/tableRenders/HoverRow.module.css';
import useURLParamsHandler from '../../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { DEFAULT_SORT } from '../../../../utils/constants';
import { RoleDisplay, Roles } from '../../../../utils/enums';
import DeleteCompanyUserButton from '../../components/DeleteCompanyUserButton';

const CompanyUserList = () => {
  const { companyId } = useParams();

  const [query, setQuery] = useState(
    { company_id: companyId, $eager: '[person(withDeleted).login(withDeleted).role(withDeleted)]' },
  );

  const { data: companyUserData, isLoading } = useQuery(
    ['fetchCompanyUsers', query],
    async () => feathersClient.service('company-person').find({ query }),
    { enabled: !!companyId },
  );

  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const [paginationPageSize, setPaginationPageSize] = useState(allSearchParams.pageSize || 10);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(allSearchParams.page || 1);
  const [showSearch, setShowSearch] = useState(false);
  const [searchParams, setSearchParams] = useState<{ quickFilter: string[] } | undefined>(
    allSearchParams.search ? { quickFilter: allSearchParams.search?.split(' ') } : undefined,
  );
  const [sort, setSort] = useState(allSearchParams.sort || DEFAULT_SORT);

  useEffect(() => {
    const q = {
      company_id: companyId,
      $eager: '[person(withDeleted).login(withDeleted).role(withDeleted)]',
      $joinRelation: '[person]',
      $skip: paginationPageSize * (paginationCurrentPage - 1),
      $limit: paginationPageSize,
      $modify: searchParams,
      $sort: sort,
    };

    setQuery(q);
  }, [companyId, paginationCurrentPage, paginationPageSize, searchParams, sort]);

  const onParamsSearch = (search: string) => {
    setPaginationCurrentPage(1);
    setUrlParamsHandler({ page: 1, search });
    const quickFilter = search?.split(' ') ?? undefined;
    setSearchParams({ quickFilter });
  };

  const contactPersonColumns = [
    {
      title: 'Jméno',
      dataIndex: 'name',
      render: (text: string, record: CompanyPersonData) => `${record.person?.first_name} ${record.person?.last_name}`,
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      align: 'left',
    },
    {
      title: 'Pozice',
      dataIndex: 'title',
      render: (text: string, record: CompanyPersonData) => `${record.title}`,
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      align: 'left',
    },
    {
      title: 'Úroveň práv',
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      className: 'd-flex justify-content-between align-center',
      // TODO: Render permission level once implemented instead of static placeholder
      render: (text: string, record: CompanyPersonData) => (
        <>
          {record.person?.login?.role?.map((r: RoleData) => RoleDisplay[r.slug as Roles]).join(', ')}
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.COMPANY,
              Roles.BRANCHOFFICE_MANAGER,
            ]}
            redirect={false}
          >
            <HoverRow>
              {record.person && <DeleteCompanyUserButton companyPerson={record.person} /> }
              <Link
                className={`
                    ty-button-primary-color
                    ty-icon-button
                    ty-row-on-hover-button
                    ${styles.ty_hover_edit_button}
                  `}
                to={{
                  pathname: `/company/${companyId}/create-company-user/${record.person?.login?.id}`,
                }}
              > <EditIcon />
              </Link>
            </HoverRow>
          </Protect>
        </>
      ),
    },
  ];

  const onPaginationChange = (newPage: number, newPageSize: number) => {
    setUrlParamsHandler({ page: newPage, pageSize: newPageSize });
    setPaginationPageSize(newPageSize);
    setPaginationCurrentPage(newPage);
  };

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    sorter: SorterResult<unknown>,
  ) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'name':
          setSort({ 'person.last_name': sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'title':
          setSort({ title: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(DEFAULT_SORT);
          break;
      }
    } else {
      setSort(DEFAULT_SORT);
    }
  };

  useEffect(() => {
    setUrlParamsHandler({ sort });
  }, [sort]);

  return (
    <Card>
      <div className="flex flex-col gap-2 px-6">
        <div className="flex justify-end align-center">
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.COMPANY,
              Roles.BRANCHOFFICE_MANAGER,
            ]}
            redirect={false}
          >
            <Link to={`/company/${companyId}/create-company-user`}>
              <SecondaryButton className="flex flex-row justify-center align-center gap-2">
                <PlusOutlinedIcon />
                Nový uživatel
              </SecondaryButton>
            </Link>
          </Protect>
          <SearchBox
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            onParamsSearch={onParamsSearch}
            loading={isLoading}
            name="company-user-search"
          />
        </div>

        <AdminTable
          rowKey={(record: CompanyPersonData) => record.person?.id}
          columns={contactPersonColumns}
          bordered
          size="small"
          className="ty-table"
          rowClassName="ty-row-hover"
          onPaginationChange={onPaginationChange}
          currentPage={paginationCurrentPage}
          onChange={onTableChange}
          data={{
            total: companyUserData?.total || 0,
            skip: paginationPageSize * (paginationCurrentPage - 1),
            limit: paginationPageSize,
            data: companyUserData?.data?.filter((x: CompanyPersonData) => !x.deleted_at) || [],
          }}
        />
      </div>
    </Card>
  );
};

export default CompanyUserList;
