import { PersonUtilityData } from '@tymbe/schema/person-utility.interface';
import { PersonData } from '@tymbe/schema/person.interface';
import { Form, InformedProps } from 'informed';
import { ComponentProps, ReactNode, useRef } from 'react';
import { useQuery } from 'react-query';

import apiClient from '../../../apiClient';
import { defaultOnError } from '../../../apiClient/utils';
import TyInput from '../../../components/inputs/TyInput';
import TySelectCompany from '../../../components/inputs/TySelectCompany';
import TySelectPerson from '../../../components/inputs/TySelectPerson';
import TySelectUtility from '../../../components/inputs/TySelectUtility/TySelectUtility';

type UtilityFormProps = InformedProps<{
  children?: ReactNode;
  title?: string;
  personId?: number | string;
}, PersonUtilityData>;

type TySelectUtilityProps = ComponentProps<typeof TySelectUtility>;

const PersonUtilityForm = ({ children, title, personId, ...rest }: UtilityFormProps) => {
  const formApiRef: UtilityFormProps['formApiRef'] = useRef();
  useQuery(
    ['person', personId],
    async () => apiClient.service('person').get(String(personId || rest.initialValues?.person_id)),
    {
      enabled: !!personId || !!rest.initialValues?.person_id,
      onSuccess: (data: PersonData) => formApiRef.current?.setValue('person', data),
      onError: defaultOnError,
    },
  );

  const prefillPrice: TySelectUtilityProps['onChange'] = ({ value }) => {
    if (Array.isArray(value)) return;
    formApiRef.current?.setValue('price', value.price);
  };

  return (
    <Form className="flex flex-col gap-4" {...rest} formApiRef={formApiRef}>
      <TySelectPerson name="person" label="Osoba" isDisabled={!!personId} required />
      <TySelectCompany name="company" label="Firma" isClearable />
      <TySelectUtility name="utility" label="Pomůcka" required onChange={prefillPrice} />
      <TyInput type="number" name="price" label="Cena" mask={(value) => Number(value).toString()} required />
      {children}
    </Form>
  );
};

export default PersonUtilityForm;
