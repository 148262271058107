import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { resolveUploadableFiles } from '../../../../../components/blob/blob.utils';
import { SubmitButton } from '../../../../../components/buttons';
import { getValuesForPatch } from '../../../../../components/forms/form-util';
import Wrapper from '../../../../../components/wrapper';
import Container from '../../../../../containers';
import Spinner from '../../../../../utils/spinner';
import mapFormValuesToBranchoffice from '../Branchoffice.utils';
import BranchofficeForm, { BranchofficeFormValues } from '../components/BranchofficeForm';

const EditBranchoffice = () => {
  const { companyId, branchofficeId } = useParams();
  const history = useNavigate();
  const queryClient = useQueryClient();
  const [formDisabled, setFormDisabled] = useState(false);

  const { data: branchoffice, isLoading: isLoadingBranchoffice } = useQuery(
    ['FetchBranchoffice', branchofficeId],
    async () => feathersClient.service('branchoffice').get(
      Number(branchofficeId),
      {
        query: { $eager: '[address, contactPerson.[company,contact,login]]' },
      },
    ),
    { enabled: !!branchofficeId },
  );

  const { data: company, isLoading: isLoadingCompany } = useQuery(
    ['FetchCompany', companyId],
    async () => feathersClient.service('company').get(
      Number(companyId),
    ),
    { enabled: !!companyId },
  );

  const onSubmit = async (formState: FormState<BranchofficeFormValues>) => {
    setFormDisabled(true);
    const modifiedAndNulled = getValuesForPatch(formState);

    if (modifiedAndNulled.address) {
      const { address, countryOption } = formState.values;
      modifiedAndNulled.address = { ...address, ...modifiedAndNulled.address, country: countryOption.value };
    }

    if (modifiedAndNulled.contactPerson !== undefined) {
      modifiedAndNulled.contact_person = modifiedAndNulled.contactPerson?.id ?? null;
      delete modifiedAndNulled.contactPerson;
      delete modifiedAndNulled.contactPersonEmail;
      delete modifiedAndNulled.contactPersonPhone;
      delete modifiedAndNulled.contactPersonPosition;
    }

    try {
      await feathersClient.service('branchoffice').patch(branchoffice.id, modifiedAndNulled);
      const formData = resolveUploadableFiles(modifiedAndNulled);
      if (formData) {
        await feathersClient.service('branchoffice').patch(
          branchoffice.id,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
      }
      SuccessAlert('Provozovna upravena');
      queryClient.invalidateQueries(['FetchBranchoffice', branchofficeId]);
      history(`/company/${companyId}/branchoffice`);
    } catch {
      ErrorAlert('Nastala chyba');
    }

    setFormDisabled(false);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      {isLoadingBranchoffice || isLoadingCompany ? (<Spinner />) : (
        <Wrapper padding="0px" margin="0px 22px 18px 31px">
          <h1>Editace provozovny</h1>
          <Form name="edit_company_form" onSubmit={onSubmit} initialValues={branchoffice}>
            <BranchofficeForm company={company} branchoffice={branchoffice} />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Wrapper>
      )}
    </Container>
  );
};

export default EditBranchoffice;
