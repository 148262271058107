import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { Query } from '@feathersjs/feathers';
import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';
import { Modal } from 'antd';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import { useUser } from '../../../../apiClient/ApiContext';
import Protect from '../../../../apiClient/Protect';
import AdminTable from '../../../../components/AdminTable';
import { SecondaryButton } from '../../../../components/buttons';
import { PlusOutlinedIcon } from '../../../../components/icons';
import Card from '../../../../components/Layout/Card/Card';
import SearchBox from '../../../../components/search/SearchBox';
import HoverRowEditDeleteButtons from '../../../../components/tableRenders/HoverRowEditDeleteButtons';
import useURLParamsHandler from '../../../../hooks/UrlParamsHandler/useURLParamsHandler';
import {
  actions,
  loadDepartmentsThunk,
  removeDepartmentThunk,
  selectDepartmentForCompany,
} from '../../../../pages-legacy/company/company/departmentSlice';
import { RolesData } from '../../../../types/TymbeApi';
import { DEFAULT_SORT } from '../../../../utils/constants';
import { Roles } from '../../../../utils/enums';
import { selectBranchofficesForCompanyLoading } from '../branchoffice/branchofficeSlice';

const DepartmentList = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(
    // wrong infer from js file, lets just ignore it for now
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    (state) => selectDepartmentForCompany(state.views.companyDepartments),
  );

  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const [paginationPageSize, setPaginationPageSize] = useState(allSearchParams.pageSize || 10);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(allSearchParams.page || 1);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [searchParams, setSearchParams] = useState<Query | undefined>(
    allSearchParams.search ? { quickFilter: allSearchParams.search?.split(' ') } : {},
  );
  const [sort, setSort] = useState(allSearchParams.sort || DEFAULT_SORT);
  const user = useUser();

  const loading = useSelector(selectBranchofficesForCompanyLoading);

  const onParamsSearch = (value: string) => {
    setPaginationCurrentPage(1);
    let query;
    if (!value) {
      setSearchParams({});
    } else {
      const departmentQuickFilter = value.split(' ');
      query = {
        departmentQuickFilter,
      };
    }
    setUrlParamsHandler({ page: 1, search: value });
    setSearchParams(query);
  };

  const onPaginationChange = (newPage: number, newPageSize: number) => {
    setUrlParamsHandler({ page: newPage, pageSize: newPageSize });
    setPaginationPageSize(newPageSize);
    setPaginationCurrentPage(newPage);
  };

  const { data: company } = useQuery(
    ['FetchCompany', companyId],
    async () => feathersClient.service('company').get(
      companyId!,
      {
        query: { $eager: '[address, contactPerson]' },
      },
    ),
    { enabled: !!companyId },
  );
  const onCancelClick = (record: BranchofficeData) => {
    const restoreRecord = { ...record, deleted_at: null };
    dispatch(actions.updateRecord({ id: record.id, record: restoreRecord }));
  };

  const showConfirmModal = (record: BranchofficeData) => {
    const onOkClick = () => {
      const deletedRecord = { ...record, deleted_at: moment.utc() };
      dispatch(actions.updateRecord({ id: record.id, record: deletedRecord }));
    };

    return new Promise<void>((resolve, reject) => {
      Modal.confirm({
        title: 'Potvrdit smazání oddělení',
        width: 450,
        icon: <ExclamationCircleOutlined />,
        content: `Chcete opravdu odstranit oddělení: ${record.name}?`,
        onOk() {
          resolve();
          onOkClick();
        },
        onCancel() {
          reject();
        },
      });
    });
  };

  const onClickRemoveRecord = (record: BranchofficeData) => {
    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(removeDepartmentThunk(record.id)).then((result) => {
      if (result?.meta?.requestStatus !== 'fulfilled') onCancelClick(record);
    });
  };

  const hideDeleteButton = (userRole: RolesData[]) => {
    if (company?.is_readonly) {
      return !userRole.some((role) => role.slug === Roles.SUPER_ADMIN);
    }
    if (userRole.some((role) =>
      [Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN, Roles.COMPANY].includes(role.slug))) {
      return false;
    } return true;
  };

  const departmentColumns = [
    {
      title: 'Název provozovny',
      render: (text: string, record: BranchofficeData) => `${record.parent?.name}`,
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Název oddělení',
      dataIndex: 'name',
      align: 'left',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Alias oddělení',
      dataIndex: 'display_name',
      sorter: true,
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Kontaktní osoba',
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      className: 'd-flex justify-content-between align-center',
      render: (text: string, record: BranchofficeData) => (
        <>
          {record.contactPerson?.first_name} {record.contactPerson?.last_name}
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.COMPANY,
              Roles.BRANCHOFFICE_MANAGER,
            ]}
            redirect={false}
          >
            <HoverRowEditDeleteButtons
              editLink={`/company/${companyId}/department/${record.id}`}
              removeTimeoutFunction={() => onClickRemoveRecord(record)}
              hideDelete={hideDeleteButton(user.role)}
              onRemoveOnClick={() => showConfirmModal(record)}
              onRemoveCancel={() => onCancelClick(record)}
              removeNotificationMessage={`Oddělení "${record.name}" bude smazáno`}
            />
          </Protect>
        </>
      ),
    },
  ];

  useEffect(() => {
    const query = {
      'branchoffice.parent_id': { $null: false },
      $skip: paginationPageSize * (paginationCurrentPage - 1),
      $limit: paginationPageSize,
      $sort: sort,
      $modify: searchParams,
    };
    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loadDepartmentsThunk({ id: companyId, query })).then(({ payload }) => {
      if (payload?.total || payload?.total === 0) {
        setPaginationTotal(payload.total);
      }
    });
  }, [companyId, dispatch, paginationCurrentPage, paginationPageSize, searchParams, sort]);

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters:Record<string, FilterValue>,
    sorter: SorterResult<unknown>,
  ) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'name':
          setSort({ name: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'display_name':
          setSort({ display_name: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(DEFAULT_SORT);
          break;
      }
    } else {
      setSort(DEFAULT_SORT);
    }
  };

  useEffect(() => {
    setUrlParamsHandler({ sort });
  }, [sort]);

  return (
    <Card>
      <div className="flex flex-col gap-2 px-6">
        <div className="flex justify-end align-center">
          <Protect
            auth={company?.is_readonly ? [
              Roles.SUPER_ADMIN,
            ] : [
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.COMPANY,
            ]}
            redirect={false}
          >
            <Link to={`/company/${companyId}/department/create`}>
              <SecondaryButton className="flex flex-row justify-center align-center gap-2">
                <PlusOutlinedIcon />
                Nové oddělení
              </SecondaryButton>
            </Link>
          </Protect>
          <SearchBox
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            onParamsSearch={onParamsSearch}
            loading={!!loading}
            name="department-search"
          />
        </div>

        <AdminTable
          rowKey={(record: BranchofficeData) => record.id}
          columns={departmentColumns}
          bordered
          size="small"
          className="ty-table"
          rowClassName="ty-row-hover"
          onPaginationChange={onPaginationChange}
          currentPage={paginationCurrentPage}
          onChange={onTableChange}
          data={{
            total: paginationTotal,
            skip: paginationPageSize * (paginationCurrentPage - 1),
            limit: paginationPageSize,
            data: data?.filter((x: BranchofficeData) => !x.deleted_at) || [],
          }}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </div>
    </Card>
  );
};

export default DepartmentList;
