import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../../components/buttons';
import TyInput from '../../../../components/inputs/TyInput';
import ModalShell from '../../../../components/modals/ModalShell';
import { PersonLiabilityData } from '../../../../types/TymbeApi';
import { DeepPartial } from '../../../../types/utils';

export interface CreateLiabilityProps {
  attendanceId: number;
  personId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const CreateLiability = ({ attendanceId, personId, onSuccess }: CreateLiabilityProps) => {
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: createLiability } = useMutation(
    ['createLiability', attendanceId],
    async (liabilityFormData): Promise<void> => {
      await feathersClient.service('person-liability').create(liabilityFormData);
    },
    {
      onSuccess,
    },
  );

  const onSubmit = async ({ values }: FormState<Partial<PersonLiabilityData>>) => {
    const liabilityFormData: DeepPartial<PersonLiabilityData> = {
      attendance_id: attendanceId,
      person_id: personId,
      amount: Number(values.amount),
      description: values.description,
    };

    try {
      await createLiability(liabilityFormData);
      setShowModal(false);
    } catch {
      ErrorAlert('Při zadávání manka došlo k chybě');
    }
  };

  return (
    <>
      <div className="p-2">
        <SecondaryButton onClick={() => setShowModal(true)}>
          Zadat manko
        </SecondaryButton>
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <Form
          onSubmit={onSubmit}
        >
          <h3 className="ty-h3 text-align-start">
            Zadat manko
          </h3>
          <div className="flex flex-row gap-2 flex-wrap">

            <TyInput
              name="amount"
              label="Kč"
              className="w-full md:flex-1"
              required
            />

            <TyInput
              name="description"
              label="Důvod udání manka"
              className="w-full md:flex-1"
              required
            />
          </div>
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default CreateLiability;
