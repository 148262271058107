import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import adminReducer from './slices/admin';
import authReducer from './slices/auth';
import banReducer from './slices/ban';
import documentsReducer from './slices/documents';
import jobsReducer from './slices/jobs';
import userReducer from './slices/user';
import uiReducer from './slices/user-interface';
import walletReducer from './slices/wallet';
import PopupNotificationSlice from '../../components/PopupNotification/PopupNotification.slice';
import loadBranchofficesReducer from '../../pages/company/@companyId/branchoffice/branchofficeSlice';
import loadCompanyReducer from '../../pages/company/slice';
import loadApplicationsReducer from '../../pages-legacy/company/attendance/applicationSlice';
import loadAttendancesReducer from '../../pages-legacy/company/attendance/attendanceSlice';
import companyAttendancesReducer from '../../pages-legacy/company/attendance/slice';
import loadCompanyUsersReducer from '../../pages-legacy/company/company/companyUsersSlice';
import loadCompanyShiftTemplatesReducer from '../../pages-legacy/company/company/createShiftTemplate/shiftTemplateSlice';
import loadDepartmentReducer from '../../pages-legacy/company/company/departmentSlice';
import loadDocumentTypesReducer from '../../pages-legacy/company/company/documentTypeSlice';
import loadPositionsReducer from '../../pages-legacy/company/company/positionSlice';
import loadCompanyDetailReducer from '../../pages-legacy/company/company/slice';
import loadFilteredCompaniesReducer from '../../pages-legacy/company/orders/newOrder/companiesSlice';
import loadFilteredBranchofficesReducer from '../../pages-legacy/company/orders/newOrder/departmentSlice';
import loadGlobalCompanyShiftTemplatesReducer from '../../pages-legacy/company/orders/newOrder/globalCompanyShiftTemplatesSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['error'],
};

const rootReducers = combineReducers({
  admin: adminReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  documents: documentsReducer,
  jobs: jobsReducer,
  user: userReducer,
  wallet: walletReducer,
  ban: banReducer,
  ui: uiReducer,
  views: combineReducers({
    companyAttendances: companyAttendancesReducer,
    companyList: loadCompanyReducer,
    company: loadCompanyDetailReducer,
    companyShiftTemplates: loadCompanyShiftTemplatesReducer,
    companyBranchoffices: loadBranchofficesReducer,
    companyDepartments: loadDepartmentReducer,
    companyDetailUsers: loadCompanyUsersReducer,
    companyDocumentTypes: loadDocumentTypesReducer,
    filteredCompany: loadFilteredCompaniesReducer,
    companyFilteredBranchoffice: loadFilteredBranchofficesReducer,
    globalCompanyShiftTemplates: loadGlobalCompanyShiftTemplatesReducer,
    companyPosition: loadPositionsReducer,
    applications: loadApplicationsReducer,
    attendances: loadAttendancesReducer,
  }),
  components: combineReducers({
    popupNotifications: PopupNotificationSlice,
  }),
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  ],
});
