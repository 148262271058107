import { FieldProps, useField } from 'informed';
import React from 'react';
import Select, { PropsValue, GroupBase } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';

import './TyInput.css';
import CustomOption from './CustomOption';
import TyInputError from './TyInputError';

type Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> =
  StateManagerProps<Option, IsMulti, Group>;

type DivProps = { label?: string & React.HTMLProps<HTMLDivElement> };

type TyMultiSelectProps<Option, Fields extends object, IsMulti extends boolean, Group extends GroupBase<Option>> =
  Omit<Props<Option, IsMulti, Group>, keyof FieldProps<DivProps, Option, Fields>>
  & FieldProps<DivProps, Option, Fields>;

/**
   * @deprecated
   * Not really deprecated, not working as expected, tho.
   * Getting this error in the production build.
   * Minified React error #130; visit https://reactjs.org/docs/error-decoder.html?invariant=130&args[]=object&args[]= for the full message or use the non-minified dev environment for full errors and additional helpful warnings.
   * Do not have time to fix it now.
   * BUT IS NOT OCCURRING IN THE DEV ENV!!
   */

const TyMultiSelect = <Fields extends object, Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: TyMultiSelectProps<Option, Fields, IsMulti, Group>,
) => {
  const { validate } = props;
  const {
    fieldState,
    userProps,
    fieldApi,
    render,
  } = useField<typeof props, PropsValue<Option>>({ ...props });
  const {
    id,
    label,
    className,
    placeholder = ' ',
    options,
    isClearable = false,
    noOptionsMessage = () => 'Žádné výsledky',
    loadingMessage = () => 'Načítání',
    required = false,
    isDisabled = false,
  } = userProps;
  const { error, showError, focused, value } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  const focusedClassName = focused || value ? 'ty_input_select_focused' : '';

  return render(
    <div className={`ty_input_field ty_multiselect ${showError && error ? 'ty_input_error' : ''} ${className || ''}`}>
      <Select
        isMulti
        inputId={id}
        placeholder={placeholder}
        className={`ty_select__container ty_input_multiselect ${focusedClassName}`}
        classNamePrefix="ty_select"
        components={{
          Option: CustomOption,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        onChange={(e) => setValue(e?.length ? e : undefined)}
        onBlur={(e) => {
          setFocused(false, e);
          setTouched(true, e);
        }}
        onFocus={(e) => setFocused(true, e)}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        maxMenuHeight={250}
        isClearable={isClearable}
        isDisabled={isDisabled}
        options={options}
        value={value}
        aria-invalid={showError}
        aria-describedby={`${id}-error`}
      />
      {label ? <label htmlFor={id}>{label}</label> : null}
      <TyInputError
        fieldState={fieldState}
        required={required}
        validate={validate}
      />
    </div>,
  );
};

export default TyMultiSelect as typeof TyMultiSelect;
