import React, { useState } from 'react';

import Body from './Body';
import Layout from '../Components/Layout';

export type Props = {
  pageTitle: string
  setInitialValues: (values: object | undefined) => void
  initialValues: Record<string, object | undefined>
};

const TyAdvancedSearchUserShift: React.FC<Props> = ({
  pageTitle = 'Kdo hledá ten najde',
  setInitialValues,
  initialValues,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Layout
      pageTitle={pageTitle}
      showModal={showModal}
      setShowModal={setShowModal}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
    >
      <Body />
    </Layout>
  );
};

export default TyAdvancedSearchUserShift;
