import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

interface TyCalDayHeadProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export default function TyCalDayHead({ children, className, ...rest }: TyCalDayHeadProps) {
  return (
    <div className={classNames('ty-cal-week-day-head', className)} {...rest}>
      {children}
    </div>
  );
}
