import { CompanyProp } from '@tymbe/schema/company-blocked-user.interface';
import { getBlocks, isBlocked } from '@tymbe/utils/company-blocked-user';
import { Input, useScopedState, useScope, useFormApi } from 'informed';
import moment, { Moment } from 'moment';
import Tooltip from 'rc-tooltip';
import { ComponentProps, useEffect, useState } from 'react';

import ShiftCreatePersonDetail from './ShiftCreatePersonDetail';
import { ShiftsToAdd, formatWorkerText, validateMaxDuration } from './utils';
import TyDatePicker from '../../../../components/inputs/TyDatePicker';
import { PersonData } from '../../../../types/TymbeApi';
import { calculateWorkTime } from '../../../../utils/time';
import Emoji from '../Emoji';
import AddPersonIcon from '../icons/AddPersonIcon';
import RemovePersonIcon from '../icons/RemovePersonIcon';
import TrashIcon from '../icons/TrashIcon';

type ShiftRowOwnProps = {
  shift: ShiftsToAdd,
  index: number,
  setShiftStartTime: (index: number, startTime: Moment) => void,
  setShiftEndTime: (index: number, startTime: Moment) => void,
  setShiftInvitations: (index: number, invitations: PersonData[]) => void,
  setShiftSpaces: (index: number, spaces: number) => void,
  personToInvite: PersonData[],
  removeShift: (index: number) => void,
  removePersonFromInvitations: (shiftIndex: number, inviteIndex: number) => void,
  company: CompanyProp,
};

type ShiftRowProps =
ShiftRowOwnProps & Omit<ComponentProps<'div'>, keyof ShiftRowOwnProps>;

type ShiftRowFormValues = {
  startTime: Moment,
  endTime: Moment,
  spaces: number,
};

const ShiftRow = ({
  shift,
  index,
  setShiftStartTime,
  setShiftEndTime,
  setShiftInvitations,
  setShiftSpaces,
  personToInvite,
  removeShift,
  removePersonFromInvitations,
  company,
  ...rest
}: ShiftRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const scopedState = useScopedState<ShiftRowFormValues>();
  const scope = useScope();
  const formApi = useFormApi<Record<string, ShiftRowFormValues>>();
  const canInvite = !personToInvite.some((item) => isBlocked(getBlocks(item.blockedCompany, company)))
  || (personToInvite.some((item) => isBlocked(getBlocks(item.blockedCompany, company)))
      && !moment(shift.date).isAfter(moment()));

  let inviteButtonText = 'Pozvat na směnu';
  if (!canInvite) inviteButtonText = 'Blokace';
  if (!personToInvite.length) inviteButtonText = 'Vyberte pracovníky';

  // Add 1 day to end time if it is before start time
  useEffect(
    () => {
      if (!scopedState.value) return;
      const { startTime, endTime } = scopedState.value;

      // If end time is dirty and is before start time, add 1 day
      if (endTime.isBefore(startTime)) {
        const end = endTime.clone().add(1, 'day');
        formApi.setValue(`${scope}.endTime`, end);
      }

      // If end time is dirty and is after start time and duration is 24 hours or more, subtract 1 day
      if (calculateWorkTime(startTime, endTime, true, 'hours') >= 24) {
        const end = endTime.clone().subtract(1, 'day');
        formApi.setValue(`${scope}.endTime`, end);
      }
    },
    [formApi, scopedState.dirty, scopedState.value?.endTime, scopedState.value?.startTime, scope, scopedState.value],
  );

  return (
    <div className="items-center py-3 border-b border-secondary-100" {...rest}>
      <div className="grid grid-cols-5">
        <div className="flex gap-3 items-center">
          <Emoji
            emoji={
              shift.shiftTemplate.emoji
                ? shift.shiftTemplate.emoji
                : '🔥'
            }
            className="bg-secondary-50 w-8 h-8 text-lg"
          />
          <div>
            <div
              className="text-sm text-secondary-900 font-medium"
            >
              {moment(shift.date).format('dd, D. MMMM')}
            </div>
            <div className="text-xs text-secondary-400 font-medium">
              {shift.shiftTemplate.template_name}
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <TyDatePicker<Record<string, ShiftRowFormValues>>
            name="startTime"
            className="h-8 border rounded-lg border-secondary-400 text-secondary-900 text-sm font-medium py-[7px] px-2 w-[60px]"
            picker="time"
            placeholder="hh:mm"
            initialValue={shift.startTime}
            onChange={(value) => setShiftStartTime(index, value.value)}
            validate={(_, values) => validateMaxDuration(values[scope].startTime, values[scope].endTime)}
            validateOn="change"
            validateWhen={[`${scope}.endTime`]}
          />
          -
          <TyDatePicker<Record<string, ShiftRowFormValues>>
            name="endTime"
            className="h-8 border rounded-lg border-secondary-400 text-secondary-900 text-sm font-medium py-[7px] px-2 w-[60px]"
            picker="time"
            placeholder="hh:mm"
            initialValue={shift.endTime}
            onChange={(value) => setShiftEndTime(index, value.value)}
            validate={(_, values) => validateMaxDuration(values[scope].startTime, values[scope].endTime)}
            validateOn="change"
            validateWhen={[`${scope}.startTime`]}
          />
          ({calculateWorkTime(shift.startTime, shift.endTime, false, 'hours').toFixed(2)} h)
        </div>
        <div className="flex items-center">
          <Input
            name="spaces"
            className="h-8 border rounded-lg border-secondary-400 text-secondary-900 text-sm font-medium py-[7px] px-2 w-[60px]"
            initialValue={shift.spaces}
            onChange={(value) => {
              setShiftSpaces(index, Number(value.value));
            }}
            required
            type="number"
            min={0}
            validate={(value) => {
              if (Number.isNaN(Number(value)) || Number(value) < 0) {
                return 'Neplatná hodnota';
              }
              return undefined;
            }}
            validateOn="change"
          />
        </div>
        {shift.invitations.length ? (
          <button
            type="button"
            className="p-0 font-medium text-sm text-secondary-900 underline inline-block text-start"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            {formatWorkerText(shift.invitations.length)}
          </button>
        ) : (
          <div className="font-medium text-sm text-secondary-400">
            -
          </div>
        )}
        <div className="flex items-center justify-end pr-4 gap-4">
          {!shift.invitations.length ? (
            <Tooltip
              overlay={inviteButtonText}
              placement="bottom"
            >
              {/* div is here because the tooltip didn't work on disabled buttons */}
              <div>
                <button
                  type="button"
                  className="h-8 py-[7px] px-3 gap-2 flex justify-center items-center text-bg bg-secondary-600 disabled:bg-secondary-100 disabled:text-secondary-400 font-semibold disabled:cursor-not-allowed"
                  onClick={() => {
                    setShiftInvitations(index, personToInvite);
                  }}
                  disabled={!personToInvite.length || !canInvite}
                >
                  <AddPersonIcon className="w-[18px]" />
                  Pozvat
                </button>
              </div>
            </Tooltip>
          ) : (
            <button
              type="button"
              className="h-8 border border-secondary-300 py-[7px] px-3 gap-2 flex justify-center items-center bg-bg text-secondary-600 font-semibold"
              onClick={() => {
                setShiftInvitations(index, []);
              }}
            >
              <RemovePersonIcon className="w-[18px]" />
              Odebrat
            </button>
          )}
          <button
            type="button"
            aria-label="remove shift"
            className="p-0"
            onClick={() => removeShift(index)}
          >
            <TrashIcon className="w-5" />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div className="p-2 w-full">
          {shift.invitations.map((person, personIndex) => (
            <ShiftCreatePersonDetail
              key={person.id}
              person={person}
              index={index}
              personIndex={personIndex}
              removePersonFromInvitations={removePersonFromInvitations}
            />
          ))}
        </div>
      )}
    </div>

  );
};

export default ShiftRow;
