import { UtilityData } from '@tymbe/schema/utility.interface';
import classNames from 'classnames';
import { Input, useFormApi, useFormState } from 'informed';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { ComponentProps, useEffect, useState } from 'react';

import { validateMaxDuration } from './utils';
import { useUser } from '../../../../apiClient/ApiContext';
import CalMultiDateInput from '../../../../components/inputs/CalMultiDateInput';
import TyDatePicker from '../../../../components/inputs/TyDatePicker';
import TySelectRequirements from '../../../../components/inputs/TySelectRequirements/TySelectRequirements';
import TySelectShiftTemplate from '../../../../components/inputs/TySelectShiftTemplate';
import TySelectUtility from '../../../../components/inputs/TySelectUtility/TySelectUtility';
import { DocumentTypeData, PerkData, ShiftTemplateData } from '../../../../types/TymbeApi';
import { calculateWorkTime } from '../../../../utils/time';
import CalendarIcon from '../icons/CalendarIcon';
import ClockIcon from '../icons/ClockIcon';
import FilterIcon from '../icons/FilterIcon';
import PeopleIcon from '../icons/PeopleIcon';
import PhoneIcon from '../icons/PhoneIcon';
import PlusIcon from '../icons/PlusIcon';
import ShiftAddIcon from '../icons/ShiftAddIcon';
import WorkIcon from '../icons/WorkIcon';

type ShiftCreateFormOwnProps = {
  className?: string,
  companyId: number,
  branchOfficeId: number,
};

type Option = PerkData | DocumentTypeData;

export type ShiftCreateFormValues = {
  dates: Moment[],
  startTime: Moment,
  endTime: Moment,
  spaces: number,
  shiftTemplate: ShiftTemplateData,
  requirements: Option[] | null,
  utilities: UtilityData[] | null,
  emergencyContact: string,
};

type ShiftCreateFormProps = ShiftCreateFormOwnProps & Omit<ComponentProps<'div'>, keyof ShiftCreateFormOwnProps>;

const ShiftCreateForm = ({
  className = '',
  companyId,
  branchOfficeId,
  ...rest
}: ShiftCreateFormProps) => {
  const formState = useFormState<ShiftCreateFormValues>();
  const formApi = useFormApi<ShiftCreateFormValues>();
  const [addRequirement, setAddRequirement] = useState(false);
  const [addUtility, setAddUtility] = useState(false);
  const user = useUser();
  const date = moment().startOf('day');

  // Add 1 day to end time if it is before start time
  useEffect(
    () => {
      const endTimeDirty = formState.dirt.endTime;
      const { startTime, endTime } = formState.values;

      // If end time is dirty and is before start time, add 1 day
      if (endTimeDirty && endTime.isBefore(startTime)) {
        const end = endTime.clone().add(1, 'day');
        formApi.setValue('endTime', end);
      }

      // If end time is dirty and is after start time and duration is 24 hours or more, subtract 1 day
      if (endTimeDirty && calculateWorkTime(startTime, endTime, true, 'hours') >= 24) {
        const end = endTime.clone().subtract(1, 'day');
        formApi.setValue('endTime', end);
      }
    },
    [formApi, formState.dirt.endTime, formState.values, formState.values.endTime, formState.values.startTime],
  );

  return (
    <div
      className={classNames(className, 'p-6')}
      {...rest}
    >
      <div className="flex flex-col gap-3">

        <div className="flex gap-2 items-center text-secondary-400 pl-1.5 font-medium text-xs">
          <WorkIcon className="w-4 text-secondary-300" />
          <TySelectShiftTemplate
            companyId={companyId}
            branchofficeId={branchOfficeId}
            publishedOnly
            id="shift_template_select"
            name="shiftTemplate"
            defaultOptions
            openMenuOnFocus
            required
            className="cal-select ml-1 text-secondary-900"
            placeholder="Vybrat pozici"
          />
        </div>
        <div className="flex gap-2 items-center text-secondary-400 pl-1.5 font-medium text-xs">
          <CalendarIcon className="w-4 text-secondary-300" />
          <CalMultiDateInput
            name="dates"
            required
            className="cal-select ml-1"
            superNextIcon={null}
            superPrevIcon={null}
            highlightHolidays
            initialValue={[]}
          />
          {(!formState.values.dates || !formState.values.dates.length)
            && (
              <div
                className="absolute text-[#949ba9] font-medium pointer-events-none translate-x-[36px] text-sm"
              >Vybrat dny
              </div>
            )}
        </div>
        <div className="flex gap-2 items-center text-secondary-400 pl-1.5 font-medium text-xs">
          <PhoneIcon className="w-4 text-secondary-300" />
          <Input
            required
            name="emergencyContact"
            className="ml-1 w-full text-secondary-900 border border-secondary-400 rounded-lg text-sm py-[5px] px-2 font-medium"
            placeholder="Nouzový kontakt pro brigádníka"
            validate={(value) => {
              if (value) {
                const isContactInfoValid = /(?:\d{3}[ ]?){3}/.test(String(value));
                if (isContactInfoValid) return undefined;
              }
              return 'Pole musí obsahovat telefonní číslo';
            }}
            validateOn="change"
          />
        </div>
        <div className="flex gap-2 items-center text-secondary-400 pl-1.5 font-medium text-xs">
          <ClockIcon className="w-4 text-secondary-300" />
          <TyDatePicker<ShiftCreateFormValues>
            name="startTime"
            className="ml-1 w-[65px] h-8 bg-bg text-secondary-900 border border-secondary-400 rounded-lg text-sm py-[7px] px-2 font-medium"
            picker="time"
            placeholder="hh:mm"
            validate={(_, { startTime, endTime }) => validateMaxDuration(startTime, endTime)}
            validateWhen={['endTime']}
            validateOn="change"
            clean={(value) => value.milliseconds(0)}
            required
            defaultValue={date}
          />
          -
          <TyDatePicker<ShiftCreateFormValues>
            name="endTime"
            className="ml-1 w-[65px] h-8 bg-bg text-secondary-900 border border-secondary-400 rounded-lg text-sm py-[7px] px-2 font-medium"
            picker="time"
            placeholder="hh:mm"
            validate={(_, { startTime, endTime }) => validateMaxDuration(startTime, endTime)}
            validateWhen={['startTime']}
            validateOn="change"
            clean={(value) => value.milliseconds(0)}
            required
            defaultValue={date}
          />h
          ({calculateWorkTime(formState.values.startTime, formState.values.endTime, false, 'hours').toFixed(2)} h)
        </div>
        <div className="flex gap-2 items-center text-secondary-400 pl-1.5 font-medium text-xs">
          <PeopleIcon className="w-4 text-secondary-300" />
          <Input
            required
            name="spaces"
            className="ml-1 w-[65px] text-secondary-900 border border-secondary-400 rounded-lg text-sm py-[5px] px-2 font-medium"
            initialValue={0}
            min={0}
            type="number"
            validate={(value) => {
              if (Number.isNaN(Number(value)) || Number(value) < 0) {
                return 'Pouze kladné číslo';
              }
              return undefined;
            }}
          />
          Míst
        </div>
        <div className="flex gap-2 items-center text-secondary-300 pl-1.5">
          <FilterIcon className="w-4" />
          {!addRequirement ? (
            <button
              type="button"
              className="p-0 flex items-center gap-2 text-secondary-600 font-semibold"
              onClick={() => setAddRequirement(true)}
            >
              <PlusIcon className="w-[18px]" />
              Přidat podmínku
            </button>
          ) : (
            <TySelectRequirements
              name="requirements"
              userRole={user.role}
              className="cal-person-select ml-1"
              placeholder="Vybrat podmínku"
              isMulti
              isClearable
            />
          )}
        </div>
        <div className="flex gap-2 items-center text-secondary-300 pl-1.5">
          <FilterIcon className="w-4" />
          {!addUtility ? (
            <button
              type="button"
              className="p-0 flex items-center gap-2 text-secondary-600 font-semibold"
              onClick={() => setAddUtility(true)}
            >
              <PlusIcon className="w-[18px]" />
              Přidat pomúcku
            </button>
          ) : (
            <TySelectUtility
              name="utilities"
              className="cal-person-select ml-1"
              placeholder="Vybrat pomúcku"
              isMulti
              isClearable
            />
          )}
        </div>
      </div>
      <button
        type="submit"
        className="mt-6 ml-9 py-2 px-4 rounded-full bg-secondary-600 text-bg text-sm font-semibold disabled:cursor-not-allowed flex items-center gap-2 disabled:bg-secondary-100 disabled:text-secondary-400"
        disabled={!formState.values.dates || !formState.values.dates.length}
      >
        <ShiftAddIcon className="w-[18px]" />
        Přidat směny
      </button>
    </div>
  );
};

export default ShiftCreateForm;
