import { useCallback, useState } from 'react';

import Container from '../../../containers';
// import { useDispatch, useSelector } from 'react-redux';
// import { selectAuthError } from '../../../data/store/slices/auth';
import PaymentInfoChangeContainer from '../../../containers/paymentInfo';

const PaymentInfoChangePage = () => {
  // const dispatch = useDispatch();
  // const error = useSelector(selectAuthError);

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const onFinish = useCallback(async () => {
    // console.log(credentials);
    // const { email } = credentials;
    setLoading(true);
    // await dispatch(updateEmailThunk(email));
    // if (error) console.error(error);
    setEmailSent(true);
    setLoading(false);
  }, []);

  const onFinishFailed = () => {};

  return (
    <Container
      header
      contentstyle={{ marginTop: '4%' }}
      iconcolor="#B3CA1F"
      background="#FEFEFE"
      footer={false}
    >
      <PaymentInfoChangeContainer
        loading={loading}
        emailSent={emailSent}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
    </Container>
  );
};

export default PaymentInfoChangePage;
