import { getConst } from '@tymbe/legislatives/Constants';
import { CzechConstantType } from '@tymbe/legislatives/czechia/CzechConstantType';

import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { Relevant, useFormApi, useFormState } from 'informed';
import { useEffect, useState } from 'react';

import TyCheckbox from '../inputs/TyCheckbox';
import TyInput from '../inputs/TyInput';
import TyRadio from '../inputs/TyRadio';
import TyRadioGroup from '../inputs/TyRadioGroup';
import { PaySupplementAmountType, PaySupplementRule, PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';

interface PaySupplementItemProps {
  name: string;
  label: string;
  isDisabled: boolean;
  initialValues?: PaySupplementRules | null;
}

interface PaySupplementFormValues {
  $paySupplement: boolean;
  $Weekend: boolean;
  $WeekendSeparately: boolean;
  $WeekendTogether?: PaySupplementRule;
}

const CzechConstantTypeDict = {
  [PaySupplementType.Holiday]: CzechConstantType.MINIMUM_PAY_SUPPLEMENT_HOLIDAY,
  [PaySupplementType.Saturday]: CzechConstantType.MINIMUM_PAY_SUPPLEMENT_SATURDAY,
  [PaySupplementType.Sunday]: CzechConstantType.MINIMUM_PAY_SUPPLEMENT_SUNDAY,
  [PaySupplementType.Night]: CzechConstantType.MINIMUM_PAY_SUPPLEMENT_NIGHT,
};

function validate(
  value: number,
  amountType: PaySupplementAmountType,
  type: PaySupplementType,
  hourlyRate?: number,
): string | undefined {
  const minValue = getConst(
    CzechConstantTypeDict[type],
    new Date().toISOString(),
    'europe/prague',
  );

  if (!hourlyRate) {
    return undefined;
  }

  if (amountType === PaySupplementAmountType.Percentage && value >= minValue) {
    return undefined;
  }
  if (amountType === PaySupplementAmountType.Fixed && value / hourlyRate >= minValue / 100) {
    return undefined;
  }

  return `Minimální hodnota příplatku je ${minValue} % (${
    hourlyRate * (minValue / 100)
  } Kč)`;
}

const PaySupplementItemWeekend = ({
  name,
  label,
  initialValues,
  isDisabled,
}: PaySupplementItemProps) => {
  const [percentSaturday, setPercentSaturday] = useState(
    initialValues?.Saturday?.type === PaySupplementAmountType.Percentage,
  );
  const [percentSunday, setPercentSunday] = useState(
    initialValues?.Sunday?.type === PaySupplementAmountType.Percentage,
  );

  const formApi = useFormApi();
  const formStatePaySupplement = useFormState<PaySupplementFormValues>();
  const disabledClassName = isDisabled ? 'ty_input_field--is-disabled' : '';

  useEffect(() => {
    if (formStatePaySupplement.values.$Weekend
      && !formStatePaySupplement.values.$WeekendSeparately
      && formStatePaySupplement.values.$WeekendTogether) {
      formApi.setValue(`${name}.Saturday.value`, formStatePaySupplement.values.$WeekendTogether.value);
      formApi.setValue(`${name}.Saturday.type`, formStatePaySupplement.values.$WeekendTogether.type);
      formApi.setValue(`${name}.Saturday.applies_to_entire_shift`, formStatePaySupplement.values.$WeekendTogether.applies_to_entire_shift);
      formApi.setValue(`${name}.Sunday.value`, formStatePaySupplement.values.$WeekendTogether.value);
      formApi.setValue(`${name}.Sunday.type`, formStatePaySupplement.values.$WeekendTogether.type);
      formApi.setValue(`${name}.Sunday.applies_to_entire_shift`, formStatePaySupplement.values.$WeekendTogether.applies_to_entire_shift);
    }
  }, [formApi, formStatePaySupplement.values, formStatePaySupplement.values.$WeekendTogether?.type,
    formStatePaySupplement.values.$WeekendTogether?.value,
    formStatePaySupplement.values.$WeekendTogether?.applies_to_entire_shift, name]);

  return (
    <div>
      <TyCheckbox
        name="$Weekend"
        label={label}
        className="mb-1.5 mt-3"
        disabled={isDisabled}
        defaultValue={Boolean(initialValues?.Saturday)}
      />
      <Relevant<PaySupplementFormValues>
        when={({ formState }) => formState.values.$Weekend}
      >
        <TyCheckbox
          name="$WeekendSeparately"
          label="Zadat rozdílné příplatky pro sobotu a neděli"
          className="mb-1.5 mt-3"
          disabled={isDisabled}
          defaultValue={JSON.stringify(initialValues?.Saturday) !== JSON.stringify(initialValues?.Sunday)}
        />
        <Relevant<PaySupplementFormValues>
          when={({ formState }) => formState.values.$Weekend && !formState.values.$WeekendSeparately}
        >
          <div className="flex flex-wrap gap-3 items-center">
            <div className="w-1/2 relative">
              <TyInput
                className="w-full"
                label="Příplatek"
                name="$WeekendTogether.value"
                type="number"
                required="Povinné pole"
                disabled={isDisabled}
                validate={(value, values) => validate(
                  value,
                  (values.pay_supplement as PaySupplementRules)[PaySupplementType.Saturday]?.type as PaySupplementAmountType,
                  PaySupplementType.Saturday,
                  values.payment_base as number,
                )}
                defaultValue={initialValues?.Saturday?.value}
              />
              {percentSaturday && percentSunday && <span className="absolute bottom-3 right-12">%</span>}
            </div>
            <div className="flex flex-wrap flex-col flex-1 gap-1">
              <TyRadioGroup
                className="ty-radio-group"
                name="$WeekendTogether.type"
                onChange={(fieldState) => {
                  setPercentSaturday(fieldState.value === PaySupplementAmountType.Percentage);
                  setPercentSunday(fieldState.value === PaySupplementAmountType.Percentage);
                  formApi.validate();
                }}
                defaultValue={initialValues?.Saturday?.type ?? PaySupplementAmountType.Fixed}
                disabled={isDisabled}
              >
                <TyRadio
                  value={PaySupplementAmountType.Fixed}
                  label="Fixní částka"
                  className={disabledClassName}
                  checked
                  disabled={isDisabled}
                />
                <TyRadio
                  value={PaySupplementAmountType.Percentage}
                  label="V procentech"
                  className={disabledClassName}
                  disabled={isDisabled}
                />
              </TyRadioGroup>

              <TyCheckbox
                name="$WeekendTogether.applies_to_entire_shift"
                label="Platí na celou směnu"
                className="mb-1"
                defaultValue={initialValues?.Saturday?.applies_to_entire_shift}
                disabled={isDisabled}
              />
            </div>
          </div>
        </Relevant>
        <Relevant<PaySupplementFormValues>
          when={({ formState }) => (formState.values.$Weekend && formState.values.$WeekendSeparately)}
        >
          <div className="flex flex-wrap gap-3 items-center">

            <div className="w-1/2 flex relative">
              <TyInput
                className="w-full"
                label="Příplatek sobota"
                name={`${name}.Saturday.value`}
                type="number"
                required="Povinné pole"
                disabled={isDisabled}
                validate={(value, values) => validate(
                  value,
                  (values.pay_supplement as PaySupplementRules)[PaySupplementType.Saturday]?.type as PaySupplementAmountType,
                  PaySupplementType.Saturday,
                  values.payment_base as number,
                )}
                defaultValue={initialValues?.Saturday?.value}
              />
              {percentSaturday && <span className="absolute bottom-3 right-12">%</span>}
            </div>
            <div className="flex flex-wrap flex-col flex-1 gap-1">
              <TyRadioGroup
                className="ty-radio-group"
                name={`${name}.Saturday.type`}
                onChange={(fieldState) => {
                  setPercentSaturday(fieldState.value === PaySupplementAmountType.Percentage);
                  formApi.validate();
                }}
                defaultValue={initialValues?.Saturday?.type ?? PaySupplementAmountType.Fixed}
                disabled={isDisabled}
              >
                <TyRadio
                  value={PaySupplementAmountType.Fixed}
                  label="Fixní částka"
                  className={disabledClassName}
                  checked
                  disabled={isDisabled}
                />
                <TyRadio
                  value={PaySupplementAmountType.Percentage}
                  label="V procentech"
                  className={disabledClassName}
                  disabled={isDisabled}
                />
              </TyRadioGroup>

              <TyCheckbox
                name={`${name}.Saturday.applies_to_entire_shift`}
                label="Platí na celou směnu"
                className="mb-1"
                defaultValue={initialValues?.Saturday?.applies_to_entire_shift}
                disabled={isDisabled}
              />
            </div>
            <div className="w-1/2 flex relative">
              <TyInput
                className="w-full"
                label="Příplatek neděle"
                name={`${name}.Sunday.value`}
                type="number"
                required="Povinné pole"
                disabled={isDisabled}
                validate={(value, values) => validate(
                  value,
                  (values.pay_supplement as PaySupplementRules)[PaySupplementType.Sunday]?.type as PaySupplementAmountType,
                  PaySupplementType.Sunday,
                  values.payment_base as number,
                )}
                defaultValue={initialValues?.Sunday?.value}
              />
              {percentSunday && <span className="absolute bottom-3 right-12">%</span>}
            </div>
            <div className="flex flex-wrap flex-col flex-1 gap-1">
              <TyRadioGroup
                className="ty-radio-group"
                name={`${name}.Sunday.type`}
                onChange={(fieldState) => {
                  setPercentSunday(fieldState.value === PaySupplementAmountType.Percentage);
                  formApi.validate();
                }}
                defaultValue={initialValues?.Sunday?.type ?? PaySupplementAmountType.Fixed}
                disabled={isDisabled}
              >
                <TyRadio
                  value={PaySupplementAmountType.Fixed}
                  label="Fixní částka"
                  className={disabledClassName}
                  checked
                  disabled={isDisabled}
                />
                <TyRadio
                  value={PaySupplementAmountType.Percentage}
                  label="V procentech"
                  className={disabledClassName}
                  disabled={isDisabled}
                />
              </TyRadioGroup>

              <TyCheckbox
                name={`${name}.Sunday.applies_to_entire_shift`}
                label="Platí na celou směnu"
                className="mb-1"
                defaultValue={initialValues?.Sunday?.applies_to_entire_shift}
                disabled={isDisabled}
              />
            </div>
          </div>
        </Relevant>
      </Relevant>
    </div>
  );
};

export default PaySupplementItemWeekend;
