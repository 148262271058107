import { Layout, Row, Col, Button, Badge } from 'antd';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import { UserProfileIcon, BackArrowIcon, BellIcon } from '../../components/icons';
import { TyBrigadyLogo } from '../../components/illustrations';
import { darkBlue } from '../../utils/colors';

const { Header } = Layout;

const PageHeader = ({
  height = '68px',
  background = '#fff',
  iconcolor = '#202020',
  elevate = false,
  showBackButton = false,
  backButtonLink = null,
  showlogo = false,
  showProfile = false,
  showNotifications = false,
  user = undefined,
  children = null,
}) => {
  const navigate = useNavigate();
  const headerStyle = {
    height,
    background,
    color: iconcolor,
    padding: '21px 18px',
    boxShadow: elevate ? '0px 1px 10px rgba(153, 153, 153, 1)' : null,
  };

  return (
    <div>
      <Header style={headerStyle} className="header">
        <Row
          gutter={4}
          justify="around"
          style={{ display: 'flex', justifyContent: 'space-between' }}
          align="middle"
        >
          {/* TODO: get rid of this, too complicated */}
          {/* eslint-disable-next-line no-nested-ternary */}
          {showBackButton
            ? (
              <Col span={10}>
                <Button
                  type="link"
                  style={{ display: 'flex', alignItems: 'center', paddingLeft: '0' }}
                  onClick={() => (
                    backButtonLink
                      ? navigate(backButtonLink)
                      : navigate(-1)
                  )}
                >
                  <div
                    style={{
                      color: darkBlue,
                      fontWeight: '500',
                      fontSize: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ width: '25px', marginRight: '3px' }}>
                      <BackArrowIcon iconcolor={darkBlue} />
                    </span>
                    <span
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '18px',
                        fontFamily: 'Poppins',
                      }}
                    >
                      Zpět
                    </span>
                  </div>
                </Button>
              </Col>
            )
            /* TODO: get rid of this, too complicated */
            /* eslint-disable-next-line no-nested-ternary */
            : !showlogo
              ? null
              : showProfile
                ? (
                  <Col span={10} style={{ height: '26px' }}>
                    <TyBrigadyLogo />
                  </Col>
                )
                : (
                  <Col span={10} offset={7} style={{ height: '26px' }}>
                    <TyBrigadyLogo />
                  </Col>
                )}
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            {showNotifications && user
              ? (
                <div
                  style={{
                    padding: '0 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Badge
                    size="small"
                    count={user.person?.personData != null ? 0 : 1}
                  >
                    <Link to="/notifications">
                      <BellIcon iconcolor={darkBlue} />
                    </Link>
                  </Badge>
                </div>
              )
              : null}
            {showProfile
              ? (
                <div
                  style={{
                    padding: '0 0 0 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Link to="/profile">
                    <UserProfileIcon />
                  </Link>
                </div>
              )
              : null}
          </Col>
        </Row>
        {children}
      </Header>
    </div>
  );
};

PageHeader.propTypes = {
  background: PropTypes.string,
  height: PropTypes.number,
  elevate: PropTypes.bool,
  iconcolor: PropTypes.string,
  showBackButton: PropTypes.bool,
  backButtonLink: PropTypes.string,
  showlogo: PropTypes.bool,
  showProfile: PropTypes.bool,
  showNotifications: PropTypes.bool,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  children: PropTypes.node,
};

export default PageHeader;
