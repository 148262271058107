import moment from 'moment';

import feathersClient from '../apiClient';
import { ApplicationState, isPaginated } from '../types/TymbeApi';

export const findPersonAppsByCompanyId = async (personId: number, companyId: number) => {
  const result = await feathersClient.service('application').find(
    {
      query: {
        $eager: '[attendance, manShift, shift.[company, branchoffice, manShift], person]',
        $joinRelation: '[shift]',
        $or: [
          { state: ApplicationState.CONFIRMED },
          {
            state: { $null: true },
            invitation: true,
          },
        ],
        person_id: personId,
        'shift.company_id': companyId,
        'shift.end_time': { $gt: moment().format() },
      },
    },
  );

  return Array.isArray(result) || isPaginated(result) ? result : Array.of(result);
};

export const cancelPersonApps = (applicationIds: number[]) => feathersClient.service('application').patch(
  null,
  { state: ApplicationState.SYSTEM_CANCELED },
  {
    query: {
      id: {
        $in: applicationIds,
      },
    },
  },
);

export const freeupManShiftByAppIds = (appIds: number[]) =>
  feathersClient.service('man-shift')
    .patch(null, { application_id: null }, { query: { application_id: { $in: appIds } } });

export const removeManShiftByAppIds = (appIds: number[]) =>
  feathersClient.service('man-shift').remove(null, { query: { application_id: { $in: appIds } } });

export const blockUserInCompany = async (
  person_id: number,
  company_id: number,
  reason: string,
  severity:number,
  branchoffice_id?: number,
  note?: string,
  freeManShifts = false,
) => {
  await feathersClient.service('company-blocked-user').create(
    {
      company_id,
      person_id,
      branchoffice_id,
      reason,
      severity,
      note,
    },
    { query: { $cancelApplications: true, $freeManShifts: freeManShifts, $notify: true } },
  );
};
