import moment, { Moment } from 'moment';
import { Options } from 'react-select';

import { Option } from '../Libs/transform';

const apiTransformationsAdminShift = {

  shiftTimeFrom:
    (time?: Moment) => ({ $modify: { startTime: [moment(time).startOf('minutes').toISOString(), undefined] } }),

  shiftTimeTo:
    (time?: Moment) => ({ $modify: { startTime: [undefined, moment(time).startOf('minutes').toISOString()] } }),

  shiftDateFrom: (date?: Moment) => ({ $modify: { startDate: [moment(date).startOf('day').toISOString(), undefined] } }),

  shiftDateTo: (date?: Moment) => ({ $modify: { startDate: [undefined, moment(date).endOf('day').toISOString()] } }),

  company: (companies?: Options<Option>) => {
    if (!companies) return undefined;
    return { 'shift.company_id': { $in: companies.map((c) => c.value) } };
  },

  branchoffice: (branchoffices?: Options<Option>) => {
    if (!branchoffices) return undefined;
    return {
      'shift:branchoffice.parent_id': { $in: branchoffices.map((b) => b.value) },
    };
  },

  applicationState: (state?: Option) => {
    if (!state) return undefined;
    if (state.value === 'invitation_not_accepted') {
      return {
        'application.state': { $null: true },
      };
    }
    return {
      'application.state': state.value,
    };
  },

  orderId: (orderIds?: Options<Option>) => {
    if (!orderIds) return undefined;
    return {
      'shift.id': { $in: orderIds.map((o) => o.value) },
    };
  },

  applicationId: (appId?: Options<Option>) => {
    if (!appId) return undefined;
    return {
      'application.id': { $in: appId.map((a) => a.value) },
    };
  },

  position: (positions?: Options<Option>) => {
    if (!positions) return undefined;
    return { 'shift.name': { $in: positions.map((p) => p.value) } };
  },

  department: (departments?: Options<Option>) => {
    if (!departments) return undefined;
    return {
      'shift.branchoffice_id': { $in: departments.map((d) => d.value) },
    };
  },

  tymber: (tymbers?: Options<Option>) => {
    if (!tymbers) return undefined;
    return {
      'person.id': { $in: tymbers.map((t) => t.value) },
    };
  },

  applicationPriceFrom: (price?: Option) => {
    if (!price) return undefined;
    return {
      'application.payment_base': { $gte: price.value },
    };
  },

  applicationPriceTo: (price?: Option) => {
    if (!price) return undefined;
    return {
      'application.payment_base': { $lte: price.value },
    };
  },

  applicationCreditsFrom: (credits?: Option) => {
    if (!credits) return undefined;
    return {
      'application.credits': { $gte: credits.value },
    };
  },
  applicationCreditsTo: (credits?: Option) => {
    if (!credits) return undefined;
    return {
      'application.credits': { $lte: credits.value },
    };
  },

  internalName: (name?: Option) => {
    if (!name) return undefined;
    return {
      'shift.external_id': { $ilike: `%${name}%` },
    };
  },

};

export default apiTransformationsAdminShift;
