import Protect from '../../apiClient/Protect';
import { Roles } from '../../utils/enums';
import { ProtectedRouteProps } from '../protectedRoute';

const CompanyAdminRoute = ({ children }: ProtectedRouteProps) => (
  <Protect auth={
    [
      Roles.SUPER_ADMIN,
      Roles.TYMBE_ADMIN,
      Roles.TYMBE_COORDINATOR,
      Roles.COMPANY,
      Roles.BRANCHOFFICE_MANAGER,
    ]
  }
  >
    {children}
  </Protect>
);

export default CompanyAdminRoute;
