const columnHeaders = [
  {
    value: 'person.id',
    label: 'ID Tymbera',
    sortable: true,
  },
  {
    value: 'person.first_name',
    label: 'Tymber',
    sortable: true,
  },
  {
    value: 'company.name',
    label: 'Firma',
    sortable: true,
  },
  {
    value: 'salary_advance_restrictions.valid_from',
    label: 'Platnost od',
    sortable: true,
  },
  {
    value: 'salary_advance_restrictions.valid_to',
    label: 'Platnost do',
    sortable: true,
  },
  {
    value: 'salary_advance_restrictions.status',
    label: 'Typ',
    sortable: true,
  },
  {
    value: '',
    label: 'Akce',
    sortable: false,
  },
];

export default columnHeaders;
