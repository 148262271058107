import { PaymentStatusData } from '@tymbe/schema/payment-status.interface';
import { Form, FormState } from 'informed';
import { SubmitButton } from '../../../../../components/buttons';
import TyRadio from '../../../../../components/inputs/TyRadio';
import TyRadioGroup from '../../../../../components/inputs/TyRadioGroup';
import ModalShell from '../../../../../components/modals/ModalShell';

type PayoutStatusModalProps = {
  showModal: boolean;
  initialValues: PaymentStatusData;
  onClose: () => void;
  onSubmit?: (formData: FormState<PaymentStatusData>) => void;
};

const PayoutStatusModal = ({
  onClose,
  onSubmit,
  showModal,
  initialValues,
}: PayoutStatusModalProps) => (
  <ModalShell
    showModal={showModal}
    onClose={onClose}
  >
    <div className="flex flex-row justify-between items-center">
      <h2>Upravit splatnost odměny</h2>
    </div>
    <Form
  initialValues={initialValues}
  onSubmit={onSubmit}
>
  <div className="flex flex-col gap-y-1 h-max w-full">
    <TyRadioGroup
      className="ty-radio-group w-full"
      name="payoutStatus"
    >
      <TyRadio
        className="pb-5 w-full"
        value="1"
        label="1 pracovní den"
      />
      <TyRadio
        className="pb-5 w-full"
        value="5"
        label="5 pracovních dní"
      />
      <TyRadio
        className="pb-7 w-full"
        value="10"
        label="10 pracovních dní"
      />
      <TyRadio
        className="pb-7 w-full"
        value="15"
        label="15 pracovních dní"
      />
      <TyRadio
        className="pb-7 w-full"
        value="20"
        label="20 pracovních dní"
      />
    </TyRadioGroup>
  </div>
  <div className="flex gap-x-1 justify-end">
    <button
      type="button"
      className="ty-button-secondary"
      onClick={onClose}
    >
      Zavřít
    </button>
    <SubmitButton
      className="ty-button-primary"
      buttontext="Uložit"
    />
  </div>
</Form>
  </ModalShell>
);

export default PayoutStatusModal;
