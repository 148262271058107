import { Query } from '@feathersjs/feathers';
import { useCallback } from 'react';
import { GroupBase, OptionsOrGroups } from 'react-select';

import feathersClient from '../../../apiClient';
import { isPaginated, RolesData } from '../../../types/TymbeApi';
import { RoleDisplay } from '../../../utils/enums';
import TyAsyncSelect, { TyAsyncSelectProps } from '../TyAsyncSelect';

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';

interface Props {
  filter?: { slug: string | string[] };
}
type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, RolesData, GroupBase<RolesData>, void, boolean> & Props;
type TySelectRoleProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps>;

const loadRoleOptions = (
  defaultOptionsCount?: number,
  filter?: Props['filter'],
) => async (
  search: string,
  options: OptionsOrGroups<RolesData, GroupBase<RolesData>>,
) => {
  const iLike = { $ilike: `%${search}%` };

  const query: Query = {
    $skip: options.length - (defaultOptionsCount || 0),
  };

  if (search !== '' && !Number.isNaN(Number(search))) {
    query.id = Number(search);
  } else {
    query.$or = [{ name: iLike }, { slug: iLike }];
  }

  if (filter?.slug) {
    const slug = Array.isArray(filter.slug) ? filter.slug : [filter.slug];
    query.slug = { $in: slug };
  }

  const resp = await feathersClient.service('role').find({
    query,
  });

  if (!isPaginated(resp)) {
    return {
      options: Array.isArray(resp) ? resp : [resp],
      hasMore: false,
    };
  }

  return {
    options: resp.data,
    hasMore: resp.total > options.length + resp.data.length,
  };
};

const TySelectRole = <Fields extends object>({
  debounceTimeout = 1000,
  defaultOptions = [],
  filter,
  ...props
}: TySelectRoleProps<Fields>) => {
  const defaultOptionsCount = Array.isArray(defaultOptions) ? defaultOptions.length : 0;
  const loadOptions = useCallback(
    () => loadRoleOptions(defaultOptionsCount, filter),
    [defaultOptionsCount, filter],
  );

  return (
    <TyAsyncSelect
      {...props}
      defaultOptions={defaultOptions}
      debounceTimeout={debounceTimeout}
      loadOptions={loadOptions()}
      getOptionLabel={(option) => (`[${option.id}] ${RoleDisplay[option.slug] || option.name}`)}
      getOptionValue={(option) => String(option.id)}
    />
  );
};

export default TySelectRole as typeof TySelectRole;
