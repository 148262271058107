import { Params } from '@feathersjs/feathers';
import isArray from 'lodash.isarray';
import { GroupBase, OptionsOrGroups } from 'react-select';

import feathersClient, { ServiceTypes } from '../../../apiClient';
import { ErrorAlert } from '../../alerts';

type QueryParam<O> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search: string, endpoint: keyof ServiceTypes, options: OptionsOrGroups<O, GroupBase<O>>, additional: any
};

const factoryLoadOptions = <O, R>(
  endpoint: keyof ServiceTypes,
  query: (options: QueryParam<O>) => Params,
  transform: (v: R) => O,
) => async (
  search: string,
  options: OptionsOrGroups<O, GroupBase<O>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additional?: any,
) => {
  const q = query({ search, endpoint, options, additional });

  const resp = await feathersClient.service(endpoint).find(q)
    .catch((err) => {
      if (err?.code === 404) {
        ErrorAlert('Žádná data');
      } else {
        ErrorAlert('Během načítání dat nastala chyba');
      }

      return [];
    });

  const r = isArray(resp) ? resp : resp.data;
  const total = isArray(resp) ? resp.length : resp.total;

  return {
    options: [...r.map((val: R) => transform(val))],
    hasMore: total > options.length + r.length,
    additional: {},
  };
};

export default factoryLoadOptions;
