export const addPlusToPhone = (phone: string | null | undefined) => {
  if (phone) {
    return phone.replace('00', '+');
  }
  return undefined;
};

export const addZerosToPhone = (phone: string | null | undefined) => {
  if (phone) {
    return phone.replace('+', '00');
  }
  return undefined;
};
