import moment, { duration, DurationInputArg1, Moment, MomentInput, unitOfTime } from 'moment';

// "Night work" is between 22 and 6 hour by the law. The 23 to 5 is our defined interval to have some buffer.
export const checkIsNightHour = (hour: number) => (hour >= 23 || hour < 5);

export const checkNightInterval = (startTime: Moment, endTime: Moment) => {
  // FIXME: check if duration is higher > 24
  // NOTE: check if .is_between() is a possibility here
  const startHour = Number(startTime.format('HH'));
  const endHour = Number(endTime.format('HH'));
  if (endHour < startHour) return true;
  return (checkIsNightHour(startHour) || checkIsNightHour(endHour));
};

export const calculateWorkTimeFromDuration = (
  workDuration: DurationInputArg1,
  withBreak = false,
  unit: unitOfTime.Base = 'milliseconds',
): number => {
  const ms = moment.duration(workDuration).as('ms');
  const chunk = moment.duration({ hours: 6, ms: 1 });
  const res = withBreak
    ? ms
    : ms - Math.trunc(ms / chunk.asMilliseconds()) * duration({ minutes: 30 }).asMilliseconds();
  return duration(res).as(unit);
};

export const calculateWorkTime = (
  startTime: MomentInput,
  endTime: MomentInput,
  withBreak = false,
  unit: unitOfTime.Base = 'milliseconds',
): number => {
  const ms = moment(endTime).diff(startTime, 'ms', true);

  return calculateWorkTimeFromDuration(ms, withBreak, unit);
};

export const getAge = (date: MomentInput) => (date && moment().diff(date, 'years'));
