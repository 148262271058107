import { useFormApi } from 'informed';
import { ComponentProps } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { formatBirthDate, formatJobEval } from './utils';
import feathersClient from '../../../../apiClient';
import Spinner from '../../../../components/Spinner';
import { PersonData } from '../../../../types/TymbeApi';
import AccountIcon from '../icons/AccountIcon';
import TrashIcon from '../icons/TrashIcon';
import Options from '../Options';

type ShiftCreatePersonDetailOwnProps = {
  person: PersonData,
  index: number,
  personIndex: number,
  removePersonFromInvitations: (shiftIndex: number, inviteIndex: number) => void,
};

type ShiftCreatePersonDetailProps =
ShiftCreatePersonDetailOwnProps & Omit<ComponentProps<'div'>, keyof ShiftCreatePersonDetailOwnProps>;

const ShiftCreatePersonDetail = ({
  person,
  index,
  personIndex,
  removePersonFromInvitations,
  ...rest
}: ShiftCreatePersonDetailProps) => {
  const formApi = useFormApi();

  // TODO: change person parameter to personId
  // const { data: personData } = useQuery(
  //   ['person', person.id],
  //   () => feathersClient.service('person').get(person.id),
  //   {
  //     staleTime: 1000 * 60 * 5,
  //     keepPreviousData: true,
  //   },
  // );

  const { data: personDataData, isLoading } = useQuery(
    ['personData', person.id],
    () => feathersClient.service('person-data').get(person.id),
    {
      staleTime: 1000 * 60 * 5,
      keepPreviousData: true,
    },
  );

  return (
    <div
      {...rest}
      className="p-2 w-1/2 flex justify-between items-center bg-secondary-50 rounded-lg mb-1"
    >
      <div className="flex items-center gap-2">
        <div className="rounded-full w-6 h-6 bg-secondary-400 text-bg text-[16px] leading-[24px] text-center font-medium">
          {person.first_name[0]}
        </div>
        <div>
          <div className="text-secondary-700 font-medium text-sm">{person.first_name} {person.last_name}</div>
          <div className="text-secondary-400 text-xs flex gap-2 items-center">{person.id} • {
            personDataData ? formatBirthDate(personDataData.birthdate) : <Spinner className="w-4" show />
          }
          </div>
        </div>
      </div>
      <div className="flex items-center gap-1">
        <div>{personDataData ? formatJobEval(personDataData.job_evaluation) : <Spinner className="w-4" show />}</div>
        <Options
          menuOptions={[{
            text: <Link to={`/user/${person.id}`} target="_blank" className="w-full flex items-center gap-2"><AccountIcon className="w-[18px] text-secondary-400" />Detail</Link>,
          },
          {
            text: 'Odebrat',
            icon: <TrashIcon className="w-[18px] text-secondary-400 group-disabled:text-secondary-200" />,
            onClick: () => {
              removePersonFromInvitations(index, personIndex);
              formApi.reset();
            },
          },
          ]}
          className="text-secondary-600"
        />
      </div>

    </div>
  );
};

export default ShiftCreatePersonDetail;
