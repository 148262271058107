import React from 'react';

type WithSpinnerProps = {
  showSpinner: boolean
};

const withSpinner = (spinner: JSX.Element) => (
  <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const ComponentWithSpinner = ({ showSpinner, ...props }: WithSpinnerProps & P) => (showSpinner
      ? spinner : <WrappedComponent {...props as P} />);
    ComponentWithSpinner.displayName = `withSpinner(${displayName})`;

    return ComponentWithSpinner;
  }
);

export default withSpinner;
