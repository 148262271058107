import { Spin, Layout, Row, Col } from 'antd';
import PropTypes from 'prop-types';

const { Content } = Layout;

export const InlineSpinner = () => (
  <Spin />
);

const Spinner = ({ loadingText = 'Načítáme', size = 'large' }) => (
  <Layout>
    <Content style={{ marginTop: '20%' }}>
      <Row justify="center" align="middle">
        <Col>
          <Spin
            size={size}
            tip={loadingText}
          />
        </Col>
      </Row>
    </Content>
  </Layout>
);

Spinner.propTypes = {
  loadingText: PropTypes.string,
  size: PropTypes.string,
};

export default Spinner;
