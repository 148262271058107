import { ApplicationState } from '@tymbe/schema/enums';
import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import moment from 'moment-timezone';

import ExportOccupancyReport from './ExportOccupancyReport';
import OrderInfoButton from './OrderInfoButton';
import { hasShiftSomeApplicationInState } from './OrdersPage.utils';
import { firstToUpperCase } from '../../../utils';
import { BranchofficeType } from '../../../utils/enums';

type GetOrdersTableColumnsProps = {
  renderOrdered: (text: unknown, shiftState: ShiftStateData, rowId: number | string) => void;
  renderInvitation: (text: unknown, shiftState: ShiftStateData, rowId: number | string) => void;
};

const getOrdersTableColumns = ({
  renderOrdered,
  renderInvitation,
}: GetOrdersTableColumnsProps) => [
  {
    title: 'Den',
    dataIndex: 'day',
    // TODO: Incorrect JSX static types, this works just fine and is used in many places (but in JSX)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    render: (_: unknown, item: ShiftStateData) => firstToUpperCase(moment(item.start_time).format('dd')),
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Datum',
    dataIndex: 'start_time',
    render: (_:unknown, item: ShiftStateData) => moment(item.start_time).format('DD.MM.YYYY'),
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas od',
    dataIndex: 'start_time_only',
    render: (_: unknown, item: ShiftStateData) => moment(item.start_time).format('HH:mm'),
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Čas do',
    dataIndex: 'end_time_only',
    sorter: true,
    render: (_: unknown, item: ShiftStateData) => moment(item.end_time).format('HH:mm'),
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Pracovní pozice',
    dataIndex: 'shiftTemplate.template_name',
    render: (_: unknown, item: ShiftStateData) => item?.shiftTemplate?.template_name ?? 'Dovolená',
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Objednáno',
    dataIndex: 'orders_count',
    render: renderOrdered,
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Obsazeno',
    dataIndex: 'filled_orders_count',
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Pozvaní',
    dataIndex: 'invitation_orders_count',
    render: renderInvitation,
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Neobsazeno',
    dataIndex: 'unfilled_orders_count',
    sorter: true,
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Provozovna',
    dataIndex: 'branchoffice.branch',
    render: (_: unknown, item: ShiftStateData) => (item.branchoffice?.parent?.type === BranchofficeType.BRANCH
          && item.branchoffice?.parent?.name)
        || (item.branchoffice?.parent?.type === BranchofficeType.BRANCH
          && item.branchoffice?.name) || '-',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: 'Oddělení',
    dataIndex: 'branchoffice_department',
    render: (_: unknown, item: ShiftStateData) => (item.branchoffice?.type === BranchofficeType.DEPARTMENT
      && item.branchoffice?.name) || '-',
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
  {
    title: '',
    dataIndex: 'actions',
    width: 60,
    render: (_: number, row: ShiftStateData) => (
      <div className="flex justify-between">
        <OrderInfoButton shiftId={row.id} />
        {hasShiftSomeApplicationInState(row, ApplicationState.CONFIRMED)
          ? <ExportOccupancyReport shift={row} /> : ''}
      </div>
    ),
    responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
  },
];

export default getOrdersTableColumns;
