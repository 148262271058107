import { MpsvReportResponseStatus, MpsvReportStatus } from '@tymbe/schema/enums';
import getResponseArray from '@tymbe/utils/getResponseArray';
import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import ChangeMpsvStatusButton from './components/ChangeMpsvStatusButton';
import ResendMpsvRequestButton from './components/ResendMpsvRequestButton';
import ShowMpsvRequestButton from './components/ShowMpsvRequestButton';
import StornoMpsvButton from './components/StornoMpsvButton';
import { mpsvTableColumnHeaders } from './mpsv.utils';
import feathersClient from '../../../apiClient';
import { getPaginatedResponse } from '../../../apiClient/utils';
import Spinner from '../../../components/Spinner';
import { Table, TableHead, TablePagination, TableRow, TableSortHeader } from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import { TableRowExpandable } from '../../../components/Table/TableRowExpandable';
import { PageTitle } from '../../../components/texts';

const MpsvForeignerTablePage = () => {
  const [sortField, setSortField] = useState(mpsvTableColumnHeaders[5].value);
  const [sortAscendingOrder, setSortAscendingOrder] = useState<number>(-1);
  const [paginationStart, setPaginationStart] = useState<number>(0);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(defaultPageSizeOptions[0].value);

  const query = {
    $eager: '[person.personData, employer, mpsvReport, shift.[branchoffice.parent]]',
    $sort: {
      [sortField]: sortAscendingOrder,
      'shift.start_time': sortField !== 'shift.start_time' ? -1 : sortAscendingOrder,
    },
  };

  const now = moment().tz('Europe/Prague');
  const { data: errData, isFetching: errFetching } = useQuery(
    ['mpsv-application', {
      ...query,
      $modify: 'mpsvScheduled',
      $or: [{
        'mpsvReport.status': { $in: [MpsvReportStatus.ERROR] },
      }, {
        'mpsvReport.response_status': { $in: [MpsvReportResponseStatus.ERROR, MpsvReportResponseStatus.FAILURE] },
      }, {
        'shift.start_time': {
          $gte: now.startOf('day').toISOString(),
          $lte: now.endOf('day').toISOString(),
        },
        mpsvReport: { $null: true },
      }],
      $limit: -1,
    }] as const,
    async ({ queryKey }) => getResponseArray(await feathersClient
      .service('application')
      .find({ query: queryKey[1] })),
    { refetchOnWindowFocus: false },
  );

  const { data: okData, isFetching: okFetching } = useQuery(
    ['mpsv-application', {
      ...query,
      $joinRelation: '[shift.branchoffice.parent, mpsvReport, person]',
      $or: [{
        'mpsvReport.status': { $nin: [MpsvReportStatus.ERROR] },
        'mpsvReport.response_status': { $nin: [MpsvReportResponseStatus.ERROR, MpsvReportResponseStatus.FAILURE] },
      },
      {
        mpsvReport: { $null: true },
        'shift.start_time': {
          $gte: now.endOf('day').toISOString(),
        },
      },
      {
        mpsvReport: { $null: true },
        'shift.start_time': {
          $lte: now.startOf('day').toISOString(),
        },
      }],
      $skip: paginationStart,
      $limit: paginationPageSize,
    }] as const,
    async ({ queryKey }) => getPaginatedResponse(await feathersClient
      .service('application')
      .find({ query: queryKey[1] })),
    { refetchOnWindowFocus: false },
  );

  const setSort = (field: string, sortOrder: number) => {
    if (field !== sortField) {
      setSortField(field);
    }
    setSortAscendingOrder(sortOrder);
  };

  const onPageChange = (pageStart: number, pageEnd: number) => {
    setPaginationStart(pageStart);
    setPaginationPageSize(pageEnd - pageStart);
  };

  return (
    <>
      <div className="items-center flex justify-between">
        <PageTitle>MPSV Tabulka</PageTitle>
        <Spinner show={!!errData && !!okData && (errFetching || okFetching)} className="w-6" />
      </div>
      <div className="bg-bg">
        <Table>
          <TableHead>
            <TableRow>
              {mpsvTableColumnHeaders.map((col) => (
                <TableSortHeader
                  key={col.label}
                  field={col.sortable ? col.value : undefined}
                  activeField={sortField}
                  onSort={setSort}
                >
                  {col.label}
                </TableSortHeader>
              ))}
            </TableRow>
          </TableHead>
          { (!!errData && !!okData) && (
            <tbody>
              {errData.map((item) => (
                <TableRowExpandable
                  className="h-10 bg-error-200"
                  key={`${item.id}${item.person_id}${item.employer_id}`}
                  cantExpand={!(item.mpsvReport && item.mpsvReport.length)}
                  expandColSpan={9}
                  cols={[
                    '❗',
                    <Link to={`/user/${item.person_id}`}>{item.person_id}</Link>,
                    <Link to={`/user/${item.person_id}`}>{`${item.person?.first_name} ${item.person?.last_name}`}</Link>,
                    <Link to={`/company/${item.employer_id}`}>{item.employer?.name}</Link>,
                    moment(item.shift?.start_time).tz('Europe/Prague').format('DD.MM.YYYY'),
                    item.shift?.branchoffice?.parent?.name,
                    item.mpsvReport?.[0]?.status === MpsvReportStatus.ERROR
                      ? item.mpsvReport?.[0]?.response_status.toUpperCase()
                      : item.mpsvReport?.[0]?.status.toUpperCase(),
                    item.mpsvReport?.[0]?.created_at ? moment(item.mpsvReport?.[0]?.created_at).tz('Europe/Prague').format('DD.MM.YYYY - HH:mm') : '-',
                    <div className="flex gap-2 items-center">
                      { (!!item.mpsvReport?.length) && (
                        <>
                          <ShowMpsvRequestButton mpsvReport={item.mpsvReport[0]} />
                          { (item.mpsvReport[0].status !== MpsvReportStatus.MANUAL
                            && item.mpsvReport[0].status !== MpsvReportStatus.STORNO)
                            && (
                              <ChangeMpsvStatusButton mpsvReport={item.mpsvReport[0]} />
                            ) }
                          { item.mpsvReport[0].status !== MpsvReportStatus.STORNO && (
                            <StornoMpsvButton applicationId={item.id} mpsvReport={item.mpsvReport?.[0]} />
                          ) }
                        </>
                      ) }
                      <ResendMpsvRequestButton applicationId={item.id} />
                    </div>,
                  ]}
                >
                  <div className="p-1">
                    <pre>
                      <div className="font-bold text-lg">Response data</div>
                      {(item.mpsvReport && item.mpsvReport[0]?.response_data) ? item.mpsvReport[0]?.response_data : '-'}
                    </pre>
                    <div className="font-bold text-lg">Error</div>
                    {(item.mpsvReport && item.mpsvReport[0]?.error) ? item.mpsvReport[0]?.error[0].message : '-'}
                  </div>
                </TableRowExpandable>
              ))}
              {okData.data.map((item) => (
                <TableRowExpandable
                  className="h-10"
                  key={`${item.id}${item.person_id}${item.employer_id}`}
                  cantExpand={!(item.mpsvReport && item.mpsvReport.length)}
                  expandColSpan={9}
                  cols={[
                    '✅',
                    <Link to={`/user/${item.person_id}`}>{item.person_id}</Link>,
                    <Link to={`/user/${item.person_id}`}>{`${item.person?.first_name} ${item.person?.last_name}`}</Link>,
                    <Link to={`/company/${item.employer_id}`}>{item.employer?.name}</Link>,
                    moment(item.shift?.start_time).tz('Europe/Prague').format('DD.MM.YYYY'),
                    item.shift?.branchoffice?.parent?.name,
                    item.mpsvReport?.[0]?.status ? item.mpsvReport?.[0]?.status.toUpperCase() : '-',
                    item.mpsvReport?.[0]?.created_at ? moment(item.mpsvReport?.[0]?.created_at).tz('Europe/Prague').format('DD.MM.YYYY - HH:mm') : '-',
                    <div className="flex gap-2 items-center">
                      {(!!item.mpsvReport
                        && !!item.mpsvReport.length)
                        && (
                          <ShowMpsvRequestButton mpsvReport={item.mpsvReport[0]} />
                        )}
                      {(!!item.mpsvReport
                        && !!item.mpsvReport.length
                        && item.mpsvReport[0].status !== MpsvReportStatus.MANUAL
                        && item.mpsvReport[0].status !== MpsvReportStatus.STORNO)
                        && (
                          <ChangeMpsvStatusButton mpsvReport={item.mpsvReport[0]} />
                        )}
                    </div>,
                  ]}
                >
                  <pre className="p-1">
                    <div className="font-bold text-lg">Response data</div>
                    {item.mpsvReport?.[0]?.response_data ?? null}
                  </pre>
                </TableRowExpandable>
              ))}
            </tbody>
          )}
        </Table>
        {(!errData && !okData && (errFetching || okFetching)) && (
          <div className="flex justify-center items-center p-1">
            <Spinner show className="w-8" />
          </div>
        )}
      </div>
      <TablePagination
        rowsCount={okData?.total}
        onChangePage={onPageChange}
      />
    </>
  );
};

export default MpsvForeignerTablePage;
