import { BrowserTracing, init as initSentry } from '@sentry/react';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/cs_CZ';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as StoreProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import Store from './data/store';
import * as serviceWorker from './serviceWorker';

import 'moment/locale/cs';

if (['production', 'stage'].includes(import.meta.env.MODE)) {
  initSentry({
    dsn: 'https://0732540b862440d28f20dd73a78ca66f@o4503999020531712.ingest.sentry.io/4503999175524352',
    environment: import.meta.env.MODE,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();

const persistor = persistStore(Store);
moment.locale('cs');
moment.updateLocale('cs', {
  months: {
    isFormat: /^MMMM/,
  },
});
ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider contextSharing client={queryClient}>
    <StoreProvider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </PersistGate>
    </StoreProvider>
    {import.meta.env.MODE !== 'test' && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>,
  // </React.StrictMode>
  document.getElementById('root'),
);

// If you want to enable PWA, switch
// unregister() to register() below.
serviceWorker.unregister();
