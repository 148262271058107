import { Params, Query } from '@feathersjs/feathers';
import { ComponentProps, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import DeleteUserButton from './components/DeleteUser';
import apiClient from '../../../apiClient';
import { getPaginatedResponse } from '../../../apiClient/utils';
import { PrimaryButton } from '../../../components/buttons';
import { EditIcon } from '../../../components/icons';
import SearchBox from '../../../components/search/SearchBox';
import Spinner from '../../../components/Spinner';
import { Table, TableCell, TableHead, TablePagination, TableRow } from '../../../components/Table';
import useURLParamsHandler from '../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { Pagination } from '../../../types/TymbeApi';
import { ContactType, RoleDisplay, Roles } from '../../../utils/enums';
import PlusIcon from '../../calendar/components/icons/PlusIcon';

type OnPageChange = ComponentProps<typeof TablePagination>['onChangePage'];

const InternalUsersPage = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchParams, setSearchParams] = useState<Query | null>();
  const [{ allSearchParams, tyAdvanceSearch }, setUrlParamsHandler] = useURLParamsHandler();

  const [pagination, setPagination] = useState<Pagination>({
    $skip: 0,
    $limit: undefined,
  });

  const advanceQuery = {
    ...pagination,
    ...searchParams,
    $eager: '[login.[role], contact, accountState, personData]',
    $joinRelation: '[login.[role], accountState]',
    $leftJoinRelation: '[contact, personData]',
    $any: {
      'login:role.slug': { $in: [Roles.ADMIN, Roles.SUPER_ADMIN, Roles.TYMBE_ADMIN, Roles.TYMBE_COORDINATOR] },
    },
    $sort: { created_at: -1 },
  };

  const { data, isLoading } = useQuery(
    ['person', advanceQuery],
    async ({ queryKey }) => apiClient.service('person').find({
      query: {
        ...queryKey[1] as Query,
      },
    }),
    { refetchOnWindowFocus: false },
  );

  const onPageChange: OnPageChange = useCallback((pageStart, _pageEnd, pageSize) => {
    setPagination((prevState) => ({
      ...prevState,
      $skip: pageStart,
      $limit: pageSize,
    }));
  }, []);

  const onSearch = (value: string) => {
    if (value === '') {
      setUrlParamsHandler({ search: '' });
      return setSearchParams(null);
    }
    const query: Params = {
      $eager: '[login.[role], contact, personData]',
      $joinRelation: '[login.[role]]',
      $leftJoinRelation: '[contact, personData]',
      $any: {
        'login:role.slug': 'tymber',
      },
      $modify: { quickFilter: (value as string).split(' ') },
    };

    setUrlParamsHandler({ page: 1, search: value });
    return setSearchParams(query);
  };

  const users = getPaginatedResponse(data);

  return (
    <>
      <div className="flex gap-4 items-center">
        <h1 className="grow">Interní uživatelé</h1>
        <div className="flex flex-row items-center justify-end m-2 space-x-2">
          <SearchBox
            name="tymberSearch"
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            onParamsSearch={onSearch}
            loading={isLoading}
            placeholder="Jméno, ID, Mobil nebo Email uživatele"
          />
          <Link to="/administration/internal-users/create">
            <PrimaryButton
              className="ty-icon-button ty-icon-button-toggle bg-primary"
              type="link"
              icon={<PlusIcon />}
            />
          </Link>
        </div>
      </div>
      <Table className="bg-bg table-auto">
        <TableHead>
          <TableRow>
            <TableCell head className="bg-fg-100">ID</TableCell>
            <TableCell head className="bg-fg-100">Jméno</TableCell>
            <TableCell head className="bg-fg-100">Email/Login</TableCell>
            <TableCell head className="bg-fg-100">Mobil</TableCell>
            <TableCell head className="bg-fg-100">Úroveň práv</TableCell>
            <TableCell head className="bg-fg-100" />
          </TableRow>
        </TableHead>
        <tbody className="relative">
          {!users.total && !isLoading && (
            <TableRow><TableCell colSpan={5} className="text-center">Žádné výsledky</TableCell></TableRow>
          )}
          {users.data.map((user) => (
            <TableRow key={user.id}>
              <TableCell align="right">{user.id}</TableCell>
              <TableCell>{user.first_name} {user.last_name}</TableCell>
              <TableCell align="right">{user.login?.username}</TableCell>
              <TableCell>{user.contact?.find((cntct) => cntct.type === ContactType.MOBILE_PHONE)?.value}</TableCell>
              <TableCell>{user.login?.role?.map((rl) => rl.slug && RoleDisplay[rl.slug])}</TableCell>
              <TableCell>
                <div className="flex flex-row justify-end gap-1">
                  <DeleteUserButton person={user} />
                  <Link
                    to={`/administration/internal-users/${user.id}`}
                  >
                    <PrimaryButton
                      className="ty-icon-button ty-icon-button-toggle"
                      type="link"
                      icon={<EditIcon />}
                    />
                  </Link>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Spinner show={isLoading} className="w-10 p-1 m-auto" />
      <TablePagination rowsCount={users.total} onChangePage={onPageChange} />
    </>
  );
};

export default InternalUsersPage;
