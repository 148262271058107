import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';

export const loadBranchofficesThunk = createAsyncThunk('branchoffice/load', async ({ id, query }) => feathersClient.service('branchoffice').find({
  query: {
    company_id: id,
    $eager: '[person, address, parent, contactPerson.[note,contact]]',
    ...query,
  },
}));

const loadBranchofficesReducer = {
  [loadBranchofficesThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadBranchofficesThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [loadBranchofficesThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

export const createBranchofficeThunk = createAsyncThunk('branchofficeSlice/create', (body) => feathersClient.service('branchoffice').create(body));

const createBranchofficeReducer = {
  [createBranchofficeThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [createBranchofficeThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [createBranchofficeThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: [...state.data || [], ...action.payload.data || []],
  }),
};

export const patchBranchofficeThunk = createAsyncThunk(
  'branchofficeSlice/patch',
  async (data) => {
    const { id, body } = data;
    return feathersClient.service('branchoffice').patch(id, body);
  },
);

const patchBranchofficeReducer = {
  [patchBranchofficeThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [patchBranchofficeThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [patchBranchofficeThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    companyError: false,
    data: [...(state.data || []).filter((x) => x?.id !== action.payload.id), action.payload],
  }),
};

export const removeBranchofficeThunk = createAsyncThunk(
  'branchofficeSlice/remove',
  async (id) => feathersClient.service('branchoffice').remove(id),
);

const removeBranchofficeReducer = {
  [removeBranchofficeThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removeBranchofficeThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [removeBranchofficeThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: [...state.data || [], ...action.payload.data || []],
  }),
};

const branchofficeSlice = createSlice({
  name: 'branchofficeContainer',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {
    updateRecord(state, action) {
      const data = state.data.map((x) => (x.id !== action.payload.id ? x : action.payload.record));
      return { ...state, data };
    },
  },
  extraReducers: {
    ...loadBranchofficesReducer,
    ...createBranchofficeReducer,
    ...patchBranchofficeReducer,
    ...removeBranchofficeReducer,
  },
});

export const selectBranchofficesForCompany = (state) => state.data;

export const selectBranchofficesForCompanyLoading = createSelector(
  (state) => state.views.companyBranchoffices.loading,
  (loading) => loading,
);

export default branchofficeSlice.reducer;
export const { actions } = branchofficeSlice;
