import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SubmitButton } from '../../../components/buttons';
import Card from '../../../components/Layout/Card';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import { RequestBody } from '../../../types/ReactQuery';
import { CompanyData } from '../../../types/TymbeApi';
import { createDefaultContract, mapCompanyData } from '../company.utils';
import CompanyForm, { CompanyFormValues } from '../components/CompanyForm';

const CreateCompany = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const history = useNavigate();

  const { mutateAsync: createCompany } = useMutation(
    ['CreateCompany'],
    (req: CompanyData | FormData): Promise<CompanyData> => feathersClient.service('company').create(req),
  );

  const { mutateAsync: patchCompany } = useMutation(
    ['PatchCompany'],
    (req: RequestBody<CompanyData | FormData>): Promise<CompanyData> =>
      feathersClient.service('company').patch(req.id, req.body, req.params),
  );

  const onSubmit = async ({ values }: FormState<CompanyFormValues>) => {
    setFormDisabled(true);

    const companyData = mapCompanyData(values);

    let company_id;
    let fulfillmentType;
    try {
      const { id: comp_id, fulfillment_type } = await createCompany(companyData);
      company_id = comp_id;
      fulfillmentType = fulfillment_type;
    } catch {
      ErrorAlert('Při pokusu o vytvoření firmy došlo k chybě');
      setFormDisabled(false);
      return;
    }

    if (!company_id) {
      ErrorAlert('Nebylo možné získat identifikátor firmy');
      setFormDisabled(false);
      return;
    }

    if (!fulfillmentType) {
      ErrorAlert('Nebylo možné získat typ firmy');
      setFormDisabled(false);
      return;
    }

    await createDefaultContract(company_id, fulfillmentType);

    // Check if we have files to upload, if not, redirect
    if (!values.logoBlob && !values.signatureBlob && !values.stampBlob) {
      SuccessAlert('Nová firma byla úspěšně vytvořena');
      setFormDisabled(false);
      history('/company');
      return;
    }

    try {
      // Files
      const formData = new FormData();
      if (values.logoBlob) formData.append('logo', values.logoBlob);
      if (values.signatureBlob) formData.append('signature', values.signatureBlob);
      if (values.stampBlob) formData.append('stamp', values.stampBlob);

      await patchCompany({
        id: company_id,
        body: formData,
        params: { headers: { 'Content-Type': 'multipart/form-data' } },
      });
      SuccessAlert('Nová firma byla úspěšně vytvořena');
      history('/company');
    } catch {
      ErrorAlert('Při dokončení vytváření nové firmy došlo k chybě');
      setFormDisabled(false);
    }
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Nová firma </h1>
        <Card>
          <Form<CompanyFormValues> name="company-form" onSubmit={onSubmit}>
            <CompanyForm />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default CreateCompany;
