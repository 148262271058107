import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../../components/buttons';
import TyInput from '../../../../components/inputs/TyInput';
import ModalShell from '../../../../components/modals/ModalShell';
import { RequestBody } from '../../../../types/ReactQuery';
import { ApplicationData } from '../../../../types/TymbeApi';
import { DeepPartial } from '../../../../types/utils';

export interface EditApplicationProps {
  application: ApplicationData;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const EditApplication = ({ application, onSuccess }: EditApplicationProps) => {
  const [showModal, setShowModal] = useState(false);

  const { mutateAsync: patchApplication } = useMutation(
    ['editApplication'],
    async (req: RequestBody<DeepPartial<ApplicationData>>): Promise<void> => {
      await feathersClient.service('application').patch(req.id, req.body);
    },
    {
      onSuccess,
    },
  );

  const initialValues = {
    payment_base: application?.payment_base,
    credits: application?.credits,
  };

  const onSubmit = async ({ values }: FormState<Partial<ApplicationData>>) => {
    const applicationFormData: DeepPartial<ApplicationData> = {
      payment_base: Number(values.payment_base),
      credits: Number(values.credits),
    };

    try {
      await patchApplication({ id: application.id, body: applicationFormData });
      setShowModal(false);
    } catch {
      ErrorAlert('Při editaci přihlášky došlo k chybě');
    }
  };
  return (
    <>
      <div className="p-2 text-right">
        <SecondaryButton onClick={() => setShowModal(true)}>
          Upravit cenu přihlášky
        </SecondaryButton>
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <h3 className="ty-h3 text-align-start">
            Upravit cenu přihlášky
          </h3>
          <div className="flex flex-row gap-2 flex-wrap">

            <TyInput
              name="payment_base"
              label="Základní odměna"
              className="w-full md:flex-1"
              required
            />

            <TyInput
              name="credits"
              label="Kredity"
              className="w-full md:flex-1"
              required
            />
          </div>
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default EditApplication;
