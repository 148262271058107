import { Form, FormApi, FormState, FormStateAccessor, Input, Radio, RadioGroup, Scope } from 'informed';
import moment, { Moment } from 'moment';
import { useReducer, useRef } from 'react';

import TyDatePicker from '../../../../components/inputs/TyDatePicker';
import Spinner from '../../../../components/Spinner';
import { ApplicationData } from '../../../../types/TymbeApi';
import { calculateWorkTime } from '../../../../utils/time';
import Inicials from '../Inicials';

type ShiftAttendanceProps = {
  applications: ApplicationData[];
  startDate: string;
  endDate: string;
  onSubmit: (values: FormValues) => Promise<unknown> | void;
};

export type FormValues = {
  id: number;
  start: Moment;
  end: Moment;
  rating: number;
}[];

const ShiftAttendance = ({ applications, startDate, endDate, onSubmit }: ShiftAttendanceProps) => {
  const formApiRef = useRef<FormApi<FormValues>>();
  const { current: formApi } = formApiRef;
  const [saving, savingDispatch] = useReducer(
    (prevState: Record<number, boolean>, action: { id: number, loading: boolean }) =>
      ({ ...prevState, [action.id]: action.loading }),
    Object.fromEntries(applications.map((app) => [app.id, false])),
  );

  const onSubmitInner = async (formState: FormState<FormValues>) => {
    await onSubmit(formState.values);
  };

  const submitSingle = async (key: number) => {
    const values = formApi?.getValue(key);
    if (!values) return;
    savingDispatch({ id: values.id, loading: true });
    try {
      await onSubmit([values]);
    } catch {
      /* can be safelly ignored */
    } finally {
      savingDispatch({ id: values.id, loading: false });
    }
  };

  return (
    <Form<FormValues> className="flex flex-col" formApiRef={formApiRef} onSubmit={onSubmitInner}>
      <div className="bg-secondary-50 p-4">Header</div>
      <div className="grid grid-cols-[minmax(min-content,_1fr)_auto_auto_auto_auto_1fr] gap-4 p-4 bg-bg">
        <strong>Brigádník</strong>
        <strong>Příchod</strong>
        <strong>Odchod</strong>
        <strong>Délka směny</strong>
        <strong>Hodnocení</strong>
        {applications.map((application, key) => (
          <div
            className="grid grid-cols-subgrid col-start-1 col-span-full gap-4 align-center border border-fg-100 p-2 rounded-lg"
            key={application.id}
          >
            <Scope scope={`${key}`}>
              <Input name="id" required type="hidden" initialValue={application.id} />
              <div className="no-wrap">
                <Inicials
                  name={`${application.person?.first_name} ${application.person?.last_name}`}
                  className="mr-1"
                />
                {application.person?.first_name} {application.person?.last_name}
              </div>
              <TyDatePicker
                className="ml-1 w-[60px] text-secondary-900 border border-secondary-400 rounded-lg text-sm py-1.5 px-2 font-medium"
                name="start"
                picker="time"
                initialValue={moment(startDate)}
              />
              <TyDatePicker
                className="ml-1 w-[60px] text-secondary-900 border border-secondary-400 rounded-lg text-sm py-1.5 px-2 font-medium"
                name="end"
                picker="time"
                initialValue={moment(endDate)}
              />
              <FormStateAccessor<FormValues>>
                {
                  (formState) => {
                    const duration = moment.duration(calculateWorkTime(
                      formState.values[key].start,
                      formState.values[key].end,
                    ));
                    return (
                      <div className="ml-1 text-secondary-900 border border-secondary-400 rounded-lg text-sm py-1.5 px-2">
                        {duration.hours()}h {duration.minutes()}min
                      </div>
                    );
                  }
                }
              </FormStateAccessor>
              <div>
                <RadioGroup name="rating" required defaultValue="49">
                  <Radio value="0" /> 👎
                  <Radio value="99" /> 👍
                </RadioGroup>
              </div>
              <div className="justify-self-end">
                {!application.attendance && (
                  <button
                    className="px-3 py-1.5 font-semibold text-bg border border-secondary bg-secondary text-xs leading-5 hover:bg-primary-300"
                    type="button"
                    onClick={() => submitSingle(key)}
                  >
                    Potvrdit
                  </button>
                )}
                {application.attendance && <Spinner show={saving[application.id]} />}
              </div>
            </Scope>
          </div>
        ))}
      </div>
    </Form>
  );
};

export default ShiftAttendance;
