import { Form } from 'informed';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import TyInput from '../../../../components/inputs/TyInput';
import TyTextArea from '../../../../components/inputs/TyTextArea';
import Card from '../../../../components/Layout/Card';
import { PageTitle } from '../../../../components/texts';
import Container from '../../../../containers';

const EmailDetail = () => {
  const { mailId } = useParams();
  const { data } = useQuery(
    ['fetchEmail', mailId],
    // we know that this variable is set since we have it in { enabled: !!mailId }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => feathersClient.service('mail').get(mailId!),
    { enabled: !!mailId },
  );

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <div className="px-10 flex flex-col gap-6">
        <PageTitle> Detail emailu </PageTitle>
        <Card>
          <Form
            initialValues={{
              recipient: data?.recipient,
              from: data?.from,
              reply_to: data?.reply_to,
              subject: data?.subject,
              body: data?.body_html,
            }}
            className="flex flex-col gap-6"
          >
            <TyInput
              label="Příjemce"
              disabled
              name="recipient"
            />
            <TyInput
              label="Odesílatel"
              disabled
              name="from"
            />
            <TyInput
              label="Odesílatel"
              disabled
              name="Adresa pro odpověď"
            />
            <TyInput
              label="Předmět"
              disabled
              name="subject"
            />
            <TyTextArea
              label="Obsah"
              disabled
              name="body"
            />
          </Form>
          <div className="flex flex-col gap-6">
            <h3> Přílohy </h3>
            <div className="flex flex-row gap-6">
              {data?.attachment?.map((att) => (
                <div className="flex flex-col w-60 h-16 bg-info rounded-lg items-center justify-center">
                  <span className="font-bold"> {att.name} </span>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </Container>
  );
};

export default EmailDetail;
