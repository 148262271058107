import { useDispatch } from 'react-redux';

import { addNotification, removeNotification } from './PopupNotification.slice';

const getId = (() => {
  let notificationCounter = 0;
  // eslint-disable-next-line no-return-assign -- it is simplier this way
  return () => notificationCounter += 1;
})();

function usePopupNotification(component) {
  const dispatch = useDispatch();
  return (props) => {
    const id = getId();
    dispatch(addNotification({ id, component, props }));
    return () => dispatch(removeNotification(id));
  };
}

export default usePopupNotification;
