import { ContactType } from '@tymbe/schema/enums';
import { LoginData } from '@tymbe/schema/src/login.interface';
import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { SubmitButton } from '../../../../components/buttons';
import Card from '../../../../components/Layout/Card';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { addZerosToPhone } from '../../../../utils/phoneNumber';
import UserForm, { UserFormValues } from '../components/UserForm';

const CreateInternalUser = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const history = useNavigate();

  const { mutateAsync: createLogin } = useMutation(
    ['CreateUser'],
    (req: LoginData): Promise<LoginData> =>
      feathersClient.service('login').create(req, { $eager: '[person.contact]' }),
  );

  const onSubmit = async ({ values } : FormState<UserFormValues>) => {
    setFormDisabled(true);

    const contact = [
      {
        person_id: values.person_id,
        name: 'mobil',
        type: ContactType.MOBILE_PHONE,
        value: addZerosToPhone(values.phone) || null,
      },
      {
        person_id: values.person_id,
        name: 'email',
        type: ContactType.EMAIL,
        value: values.username,
      },
    ];

    try {
      await createLogin({
        username: values.username,
        // Password is set by the user in verify process
        password: '',
        person: {
          ...values.person,
          contact,
        },
        role: values.role?.[0],
      } as LoginData);
      SuccessAlert('Uživatel byl úspěšně vytvořen');
    } catch {
      ErrorAlert('Při pokusu o vytvoření uživatele nastala chyba');
    }

    setFormDisabled(false);
    history('/administration/internal-users');
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Nový uživatel </h1>
        <Card>
          <Form<UserFormValues> name="person-form" onSubmit={onSubmit}>
            <UserForm />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default CreateInternalUser;
