import classNames from 'classnames';
import { ComponentProps } from 'react';

type EmojiOwnProps = {
  emoji?: string | null,
  className?: string
};
type EmojiProps = EmojiOwnProps & Omit<ComponentProps<'div'>, keyof EmojiOwnProps>;

const Emoji = ({ emoji, className = '', ...rest }: EmojiProps) => (
  <div
    className={classNames('flex justify-center items-center rounded-[4px]', className)}
    {...rest}
  >
    <span>
      {emoji ?? '✕'}
    </span>
  </div>
);

export default Emoji;
