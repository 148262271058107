import moment from 'moment';

import styles from './ShiftForm.module.css';
import { useUser } from '../../../../apiClient/ApiContext';
import { TagText } from '../../../../components/texts';
import { isPerkData, PersonData, ShiftData } from '../../../../types/TymbeApi';
import { getPerkNameById, removeEmptyDecimals } from '../../../../utils';
import { dangerColor, darkBlue } from '../../../../utils/colors';
import { getRequirements } from '../../../../utils/shift';
import { calculateWorkTime } from '../../../../utils/time';

interface ShiftRecapitulationProps {
  values: Partial<ShiftData>;
  dates: string[] | undefined;
  invitations: PersonData[] | undefined;
}

const ShiftRecapitulation = ({
  values,
  dates,
  invitations,
}: ShiftRecapitulationProps) => {
  const user = useUser();
  let rawShiftDuration;
  if (values.start_time && values.end_time) {
    rawShiftDuration = calculateWorkTime(values.start_time, values.end_time, true, 'hours').toFixed(2);
  }
  return (
    <div className={`${styles.column_big} ${styles.m_left_4pr}`}>
      <h2> Rekapitulace </h2>
      <div className={styles.row}>
        <div className={`${styles.column}`}>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Provozovna: </span>
            <span> {values.branchoffice?.parent?.name} </span>
          </div>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Oddělení: </span>
            <span> {values.branchoffice?.name} </span>
          </div>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Název objednávky pro veřejnost: </span>
            <span>{values.shift_template?.name || values.name}</span>
          </div>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Počet tymberů: </span>
            <span>{values.manShift?.length}</span>
          </div>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Pracovní doba od: </span>
            <span>{values.start_time ? moment(values.start_time).format('HH:mm') : null}</span>
          </div>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Pracovní doba do: </span>
            <span>{values.end_time ? moment(values.end_time).format('HH:mm') : null}</span>
          </div>
          <div className={styles.column}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Datum směny: </span>
            <span className={`${styles.row} ${styles.gap_none}`}>
              {dates?.map((x) => (
                <TagText
                  key={x}
                  color={darkBlue}
                  statusColor={`${darkBlue}1A`}
                  statusText={moment(x).format('DD.MM.YYYY')}
                />
              ))}
            </span>
          </div>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Délka směny: </span>
            <span> {rawShiftDuration} </span>
          </div>
          <div className={styles.row}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Čas bez přestávky: </span>
            <span>{rawShiftDuration ? `${removeEmptyDecimals(calculateWorkTime(values.start_time, values.end_time, false, 'hours'), 0, 2)} hod` : null}</span>
          </div>
          <div className={styles.column}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Podmínky pracovní pozice: </span>
            <span className={`${styles.row} ${styles.gap_none}`}>
              {getRequirements(values.shift_template, user.role).map((req) => (
                <TagText
                  key={req.id}
                  color={darkBlue}
                  statusColor={isPerkData(req) && !req.is_visible ? `${dangerColor}1A` : `${darkBlue}1A`}
                  statusText={!isPerkData(req) ? (req.name || req.display_name) : getPerkNameById(req.id)}
                />
              ))}
            </span>
          </div>
          <div className={styles.column}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Podmínky objednávky: </span>
            <span className={`${styles.row} ${styles.gap_none}`}>
              {getRequirements(values, user.role)?.filter((x) => {
                if (isPerkData(x)) {
                  return !values.shift_template?.perk?.some(({ group }) => group === x.group);
                }
                return !values.shift_template?.documentType?.some(({ id }) => id === x.id);
              }).map((req) => (
                <TagText
                  key={req.id}
                  color={darkBlue}
                  statusColor={isPerkData(req) && !req.is_visible ? `${dangerColor}1A` : `${darkBlue}1A`}
                  statusText={!isPerkData(req) ? (req.name || req.display_name) : getPerkNameById(req.id)}
                />
              ))}
            </span>
          </div>
          <div className={styles.column}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Pomůcky objednávky: </span>
            <span className={`${styles.row} ${styles.gap_none}`}>
              {values.utility?.map((req) => (
                <TagText
                  key={req.id}
                  color={darkBlue}
                  statusColor={`${darkBlue}1A`}
                  statusText={req.name}
                />
              ))}
            </span>
          </div>
          <div className={styles.column}>
            <span className={`${styles.bold} ${styles.min_width_250}`}> Pozvaní Tymbeři: </span>
            <span className={`${styles.row} ${styles.gap_none}`}> {invitations?.map((tymb) => (
              <TagText
                key={tymb.id}
                color={darkBlue}
                statusColor={`${darkBlue}1A`}
                statusText={`${tymb.first_name} ${tymb.last_name}`}
              />
            ))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftRecapitulation;
