import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';

export const loadFilteredBranchofficesThunk = createAsyncThunk('orders/newOrder/department', async (query) => feathersClient.service('branchoffice').find({
  query: {
    ...query,
  },
}));

const loadFilteredBranchofficesReducer = {
  [loadFilteredBranchofficesThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadFilteredBranchofficesThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
    requestData: null,
  }),
  [loadFilteredBranchofficesThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
    requestData: action.meta.arg,
  }),
};

const initialState = {
  loading: false,
  error: null,
  data: null,
  requestData: null,
};

const filteredBranchofficesSlice = createSlice({
  name: 'filteredNewOrderDepartmentContainer',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    ...loadFilteredBranchofficesReducer,
  },
});

export const selectFilteredBranchoffices = (state) => state.data;

export default filteredBranchofficesSlice.reducer;
export const { actions } = filteredBranchofficesSlice;
