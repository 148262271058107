import { BlockTool, BlockToolData, ToolboxConfig } from "@editorjs/editorjs";
import styles from './Editor.module.css';

interface Data {
  name?: string;
}

interface ConstructorParams {
  data: Data;
}

export default class EditorBlockVariable implements BlockTool {
  private data: Data;

  static get toolbox(): ToolboxConfig {
    return {
      title: 'Block variable',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M560-280H120v-400h720v120h-80v-40H200v240h360v80Zm-360-80v-240 240Zm560 200v-120H640v-80h120v-120h80v120h120v80H840v120h-80Z"/></svg>',
    };
  }

  constructor({ data }: ConstructorParams) {
    this.data = data;
  }

  public render(): HTMLElement {
    const inputWrapper = document.createElement('div');
    inputWrapper.classList.add(styles['editor_variable_wrapper']);
    inputWrapper.innerHTML = `<input class=${styles['editor_variable']} value=${this.data?.name ?? ''} placeholder='Enter block variable' />`;
    return inputWrapper;
  }

  public save(blockContent: HTMLElement): BlockToolData<{ name: string }> {
    return {
      name: blockContent.getElementsByTagName('input')[0].value,
    };
  }

  // ------------------------------
  // TODO - code below will be used when we have a list of variables and we can create one tool button offering a list of variables to choose from instead of creating them one by one using an input (like above).
    // Code below is not a final version. For example, the name attribute is not being changed on save for the select version plus there are some errors in console and css module is not used. There are two versions:

    // private variableNames: string[] = ['variable1', 'variable2', 'variable3'];

    //////////////////////////
    //////// EITHER: ////////
    /////////////////////////
    // public render(): HTMLElement {
    //   const wrapper = document.createElement('div');
    //   wrapper.classList.add('editor-variable-wrapper');
    //   const button = document.createElement( 'button');
    //   wrapper.appendChild(button);
    //   button.classList.add('editor-variable');
    //   button.innerText = this.data?.name ?? 'Select block variable';
    //   button.onclick = () => {list.classList.toggle('hidden')};
    //   const list = document.createElement('div');
    //   list.classList.add('editor-variable-list', 'hidden');
    //   this.variableNames.forEach((variableName) => {
    //     const item = document.createElement('button');
    //     item.innerText = variableName;
    //     item.classList.add('editor-variable-list-item');
    //     list.appendChild(item);
    //     item.onclick = () => {
    //       button.innerText = variableName;
    //       list.classList.toggle('hidden');
    //     };
    //   });
    //   wrapper.appendChild(list);
    //   return wrapper;
    // }

    // public save(blockContent: HTMLElement): BlockToolData<{ name: string }> {
    //   console.log('blockContent', blockContent)
    //   console.log('this.data.name', this.data.name)
    //   return {
    //     name: blockContent.getElementsByTagName('button')[0].innerText,
    //   };
    // }

    //////////////////////
    //////// OR: ////////
    /////////////////////
    // public render(): HTMLSelectElement {
    //   const select = document.createElement('select');
    //   const option = new Option(this.data.name ?? 'Select variable', this.data.name ?? 'Select variable');
    //   select.appendChild(option);
    //   this.variableNames.forEach((variableName) => {
    //     const option = new Option(variableName, variableName);
    //     select.appendChild(option);
    //   });
    //   select.onchange = () => this.save(select);
    //   console.log('this.data.name:', this.data.name)
    //   return select;
    // }

    // public save(blockContent: HTMLSelectElement): BlockToolData<{ name: string }> {
    //   return {
    //     name: blockContent.value,
    //   };
    // }
}
