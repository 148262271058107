// TODO: We will get rid of redux anyway
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const loadApplicationThunk = createAsyncThunk('company/attendance/loadApplicationThunk', (params) =>
  feathersClient.service('application').find({
    query: {
      ...params,
      $modify: {
        ...params.$modify,
        unconfirmed: true,
      },
    },
  }));

const loadApplicationReducer = {
  [loadApplicationThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadApplicationThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.data = null;
  },
  [loadApplicationThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.data = action.payload.data;
    state.pagination = {
      total: action.payload.total,
      skip: action.payload.skip,
      limit: action.payload.limit,
    };
  },
};

export const loadAttendanceForExportThunk = createAsyncThunk(
  'company/attendance/loadAttendanceForExportThunk',
  (params) =>
    feathersClient.service('attendance').find({
      query: {
        $eager: '[application.[shift.[branchoffice.parent, company], person], confirmedBy]',
        $joinRelation: '[application.[shift.branchoffice, person]]',
        $limit: -1,
        ...params,
      },
    }),
);

const loadAttendanceForExportReducer = {
  [loadAttendanceForExportThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadAttendanceForExportThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.data = null;
  },
  [loadAttendanceForExportThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

const attendanceSlice = createSlice({
  name: 'attendanceContainer',
  initialState: {
    loading: null,
    error: null,
    data: null,
    pagination: {
      total: null,
      skip: 0,
      limit: 10,
    },
  },
  reducers: {},
  extraReducers: {
    ...loadApplicationReducer,
    ...loadAttendanceForExportReducer,
  },
});

export const selectAttendances = (state) => state.data;
export const selectAttendanceLoading = createSelector(
  (state) => state.loading,
  (loading) => {
    if (loading !== null) return loading;
    return undefined;
  },
);
export const selectAttendancePagination = createSelector(
  (state) => state.pagination,
  (pagination) => pagination,
);
export default attendanceSlice.reducer;
