import ModalShell from './ModalShell';
import { IconButton } from '../buttons';
import { CrossIcon, ExclamationCircleOutlined } from '../icons';

interface AlertModalProps {
  showModal: boolean;
  onClose: () => void;
  title: string;
  message: string;
  cancelButtonText?: string;
  yesButtonText?: string;
  noButtonText?: string;
  onYes: () => void;
  onNo?: () => void;
  showCancel?: boolean
}
const yesNoCommonCSS = `border-[1px]
h-8
flex
align-center
rounded-sm
font-bold
text-[white]
hover:transition
hover:ease-in
hover:text-[white]`;

const AlertModal = ({
  title,
  showModal,
  message,
  cancelButtonText = 'Zrušit',
  yesButtonText = 'OK',
  noButtonText = 'NO',
  onYes,
  onNo = undefined,
  onClose,
  showCancel = true,
}: AlertModalProps) => (
  <ModalShell
    showModal={showModal}
    onClose={onClose}
  >
    <IconButton
      style={{ position: 'absolute', top: 5, right: 5 }}
      onClick={onClose}
    ><CrossIcon />
    </IconButton>
    <div className="flex flex-row align-start gap-5">
      <span className="mt-3.5"> <ExclamationCircleOutlined className="w-[22px] text-[orange]" /> </span>
      <div className="flex flex-col flex-grow gap-2">
        <h3> {title} </h3>
        <p className="leading-normal"> {message} </p>
        <div className="flex flex-row pt-8 flex-grow justify-end gap-3">
          <button
            type="button"
            hidden={!showCancel}
            onClick={onClose}
            className="border-[1px] h-8 font-bold flex align-center rounded-sm"
          > {cancelButtonText}
          </button>
          {onNo && (
            <button
              type="button"
              onClick={onNo}
              className={`
                ${yesNoCommonCSS}
                bg-danger
                border-danger
                hover:border-danger-300
                hover:bg-danger-300`}
            > {noButtonText}
            </button>
          )}
          <button
            type="button"
            onClick={onYes}
            className={`
              ${yesNoCommonCSS}
              bg-primary
              border-primary
              hover:border-primary-300
              hover:bg-primary-300`}
          > {yesButtonText}
          </button>
        </div>
      </div>
    </div>
  </ModalShell>
);

export default AlertModal;
