import { Query } from '@feathersjs/feathers';

import factoryLoadOptions from './factoryLoadOptions';
import { ApplicationState } from '../../../types/TymbeApi';

type Company = { name: string };
type Parent = { name: string };

export const loadTymberOptions = factoryLoadOptions(
  'person',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
    };
    if (search && !Number.isNaN(Number(search))) {
      query.id = Number(search);
    } else {
      query.$or = [
        { 'person.first_name': { $ilike: `%${search}%` } },
        { 'person.last_name': { $ilike: `%${search}%` } },
      ];
    }
    return { query };
  },
  (v: { id: number, first_name: string, last_name: string }) => ({ label: `#${v.id} ${v.first_name} ${v.last_name}`, value: v.id }),
);

export const loadNationalityOptions = factoryLoadOptions(
  'person-data',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
      nationality: { $ilike: `%${search}%` },
      $select: ['nationality'],
      $distinct: ['nationality'],
      $joinRelation: '[person.[login.[role]]]',
      $any: {
        'person:login:role.slug': 'tymber',
      },
    };
    return { query };
  },
  (v: { nationality: string }) => ({ label: `${v.nationality}`, value: v.nationality }),
);

export const loadPinOptions = factoryLoadOptions(
  'person-data',
  ({ search, options }) => {
    const query: Query = {
      pin: { $ilike: `%${search.replace(/\//g, '')}%` },
      $skip: options.length,

      $joinRelation: '[person.[login.[role]]]',
      $any: {
        'person:login:role.slug': 'tymber',
      },
    };
    return { query };
  },
  (v: { pin: string, person_id: string }) => ({ label: `${v.pin}`, value: v.person_id }),
);

export const loadPersonNoteOption = factoryLoadOptions(
  'person-note',
  ({ search, options }) => {
    const query: Query = {
      'person_note.note': { $ilike: `%${search}%` },
      $skip: options.length,
      $joinRelation: '[createdBy.[login.[role]]]',
      $any: {
        'createdBy:login:role.slug': 'tymber',
      },
    };
    return { query };
  },
  (v: { note: string, person_id: string }) => ({ label: `${v.note}`, value: v.person_id }),
);

export const loadAddressOptions = factoryLoadOptions(
  'address',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
      $joinRelation: '[personData.[person.[login.[role]]]]',
      $any: {
        'personData:person:login:role.slug': 'tymber',
      },
    };
    if (search) {
      query.$or = [
        { addressline1: { $ilike: `%${search}%` } },
        { addressline2: { $ilike: `%${search}%` } },
        { addressline3: { $ilike: `%${search}%` } },
        { locality: { $ilike: `%${search}%` } },
        { zip: { $ilike: `%${search}%` } },
      ];
    }
    return { query };
  },
  (v: {
    addressline1: string,
    addressline2: string,
    addressline3: string,
    locality: string,
    zip: string,
    id: number
  }) =>
    ({
      label: [v.addressline1, v.addressline2, v.addressline3, v.locality, v.zip].filter((n) => n).join(', '),
      value: v.id,
    })
  ,
);

export const loadRegisteredTymberOptions = factoryLoadOptions(
  'person',
  ({ search, options }) => {
    const registeredConditions = [
      { 'application.state': ApplicationState.CONFIRMED },
      {
        'application.state': { $null: true },
        'application.invitation': true,
      },
    ];

    const query: Query = {
      $joinRelation: '[application]',
      $skip: options.length,
    };
    if (search && !Number.isNaN(Number(search))) {
      query.$and = {
        'person.id': Number(search),
        $or: registeredConditions,
      };
    } else {
      query.$or = [
        {
          'person.first_name': { $ilike: `%${search}%` },
          $or: registeredConditions,
        },
        {
          'person.last_name': { $ilike: `%${search}%` },
          $or: registeredConditions,

        },
      ];
    }
    return { query };
  },
  (v: { id: number, first_name: string, last_name: string }) => ({ label: `#${v.id} ${v.first_name} ${v.last_name}`, value: v.id }),
);

export const loadBankAccountOptions = factoryLoadOptions(
  'person-data',
  ({ search, options }) => {
    const query: Query = {
      bank_account: { $ilike: `%${search}%` },
      $skip: options.length,
      $joinRelation: '[person.[login.[role]]]',
      $any: {
        'person:login:role.slug': 'tymber',
      },
    };
    return { query };
  },
  (v: { person_id: number, bank_account: string, }) => ({ label: `${v.bank_account}`, value: v.person_id }),
);

export const loadEmailOptions = factoryLoadOptions(
  'login',
  ({ search, options }) => {
    const query: Query = {
      username: { $ilike: `%${search.toString()}%` },
      $joinRelation: 'role',
      $any: {
        'role.slug': 'tymber',
      },
      $skip: options.length,
    };
    return { query };
  },
  (v: { person_id: number, username: string }) => ({ label: `#${v.person_id} ${v.username}`, value: v.person_id }),
);

export const loadPhoneOptions = factoryLoadOptions(
  'person-contact',
  ({ search, options }) => {
    const query: Query = {
      type: 'mobile_phone',
      value: { $ilike: `%${search.toString()}%` },
      $joinRelation: '[person.[login.[role]]]',
      $any: {
        'person:login:role.slug': 'tymber',
      },
      $skip: options.length,
    };
    return { query };
  },
  (v: { person_id: number, value: string }) => ({ label: `#${v.person_id} ${v.value}`, value: v.person_id }),
);

export const loadPositionOptions = factoryLoadOptions(
  'shift',
  ({ search, options }) => {
    const query: Query = {
      $select: ['name'],
      $distinct: ['name'],
      name: { $ilike: `%${search}%` },
      $skip: options.length,
    };
    return { query };
  },
  (v: { name: string }) => ({ label: v.name, value: v.name }),
);

export const loadCompanyOptions = factoryLoadOptions(
  'company',
  ({ search, options }) => {
    const query: Query = {
      name: { $ilike: `%${search}%` },
      $skip: options.length,
    };
    return { query };
  },
  (v: { id: number, name: string }) => ({ label: `#${v.id} ${v.name}`, value: `${v.id}` }),
);

export const loadCompanyBlockedPersonOptions = factoryLoadOptions(
  'company',
  ({ search, options }) => {
    const query: Query = {
      $modify: 'withBlockedPerson',
      name: { $ilike: `%${search}%` },
      $skip: options.length,
    };
    return { query };
  },
  (v: { id: number, name: string }) => ({ label: `#${v.id} ${v.name}`, value: `${v.id}` }),
);

export const loadBranchofficeOptions = factoryLoadOptions(
  'branchoffice',
  ({ search, options }) => {
    const query: Query = {
      parent_id: { $null: true },
      'branchoffice.name': { $ilike: `%${search}%` },
      $eager: '[company]',
      $joinRelation: '[company]',
      $skip: options.length,
    };

    return { query };
  },
  (v: { name: string, company: Company, id: number }) => ({ label: `${v.company.name} - ${v.name}`, value: `${v.id}` }),
);

export const loadDepartmentOptions = factoryLoadOptions(
  'branchoffice',
  ({ search, options }) => {
    const query: Query = {
      'branchoffice.parent_id': { $null: false },
      'branchoffice.name': { $ilike: `%${search}%` },
      $eager: '[company, parent]',
      $joinRelation: '[company, parent]',
      $skip: options.length,
    };
    return { query };
  },
  (v: { name: string, company: Company, parent: Parent, id: number }) => ({ label: `${v.company.name} - ${v.parent.name} - ${v.name}`, value: `${v.id}` }),
);

export const loadConfirmatorOptions = factoryLoadOptions(
  'person',
  ({ search, options }) => {
    const query: Query = {
      $modify: 'onlyApprover',
      $skip: options.length,
    };
    if (search && !Number.isNaN(Number(search))) {
      query.id = Number(search);
    } else {
      query.$or = [
        { 'person.first_name': { $ilike: `%${search}%` } },
        { 'person.last_name': { $ilike: `%${search}%` } },
      ];
    }

    return { query };
  },
  (v: { id: number, first_name: string, last_name: string }) => ({ label: `#${v.id} ${v.first_name} ${v.last_name}`, value: `${v.id}` }),
);

export const loadShiftIdOptions = factoryLoadOptions(
  'shift',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
    };
    if (search && !Number.isNaN(Number(search))) {
      query.id = Number(search);
    }
    return { query };
  },
  (v: { id: number }) => ({ label: `#${v.id}`, value: v.id }),
);
export const loadApplicationIdOptions = factoryLoadOptions(
  'application',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
    };
    if (search && !Number.isNaN(Number(search))) {
      query.id = Number(search);
    }
    return { query };
  },
  (v: { id: number }) => ({ label: `#${v.id}`, value: v.id }),
);

export const loadAttendanceIdOptions = factoryLoadOptions(
  'attendance',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
    };
    if (search && !Number.isNaN(Number(search))) {
      query.id = Number(search);
    }
    return { query };
  },
  (v: { id: number }) => ({ label: `#${v.id}`, value: v.id }),
);

export const loadApplicationPrice = factoryLoadOptions(
  'application',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
      $distinct: ['payment_base'],
      $select: ['payment_base'],
    };
    if (search && !Number.isNaN(Number(search))) {
      query.payment_base = Number(search);
    }
    return { query };
  },
  (v: { payment_base: number }) => ({ label: `${v.payment_base}`, value: v.payment_base }),
);

export const loadApplicationCredits = factoryLoadOptions(
  'application',
  ({ search, options }) => {
    const query: Query = {
      $skip: options.length,
      $distinct: ['credits'],
      $select: ['credits'],
    };
    if (search && !Number.isNaN(Number(search))) {
      query.credits = Number(search);
    }
    return { query };
  },
  (v: { credits: number }) => ({ label: `${v.credits}`, value: v.credits }),
);
