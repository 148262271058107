import { CompanyProp } from '@tymbe/schema/src/company-blocked-user.interface';
import { getBlocks, isBlocked } from '@tymbe/utils/company-blocked-user';
import moment from 'moment';

import { PersonData } from '../../../types/TymbeApi';

const getValidateTymberBlocked = (shiftDate?: string | string[], company?: CompanyProp) =>
  (value?: PersonData | PersonData[]) => {
    if (!value || !shiftDate) return undefined;
    if (!Array.isArray(shiftDate) && moment(shiftDate).isBefore(moment())) {
      return undefined;
    }
    if (Array.isArray(value)) {
      const blocked = value.some((item) => isBlocked(getBlocks(item.blockedCompany, company)));
      if (!blocked) return undefined;
      if (Array.isArray(shiftDate)) {
        const dateAfter = shiftDate.some((item) => moment(item).isAfter(moment()));
        if (dateAfter) return 'Blokace';
        return undefined;
      }
      return 'Blokace';
    }
    return undefined;
  };

export default getValidateTymberBlocked;
