import classNames from 'classnames';
import { FieldProps, useField } from 'informed';
import { useEffect, useRef, useState } from 'react';

type ComboBoxProps<O, V> = {
  getOptionLabel?: (option: O) => string;
  getOptionValue?: (option: O) => V;
  getValueOption?: (value: V, options: O[]) => O | undefined;
  options?: O[];
  placeholder?: string;
  className?: string;
  menuClassName?: string;
  itemClassName?: string;
  inputClassName?: string;
  footerClassName?: string;
};

const ComboBox = <O, T, Fields extends object>(props: FieldProps<ComboBoxProps<O, T>, T, Fields>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const {
    fieldState,
    fieldApi,
    render,
    userProps,
  } = useField<ComboBoxProps<O, T>, T>({ ...props });

  const {
    getOptionLabel = (option?: O) => String(option ?? ''),
    getValueOption = (value) => value as unknown as O,
    getOptionValue = (option: O) => option as unknown as T,
    options = [],
    placeholder,
    className,
    menuClassName,
    itemClassName,
    inputClassName,
    footerClassName,
  } = userProps;
  const currentOption = getValueOption(fieldState.value, options);
  const [search, setSearch] = useState(currentOption ? getOptionLabel(currentOption) : placeholder || '');

  useEffect(() => {
    if (inputRef.current === document.activeElement) {
      return;
    }
    setSearch(currentOption ? getOptionLabel(currentOption) : placeholder || '');
  }, [currentOption, getOptionLabel, placeholder]);

  const filteredOptions = options.filter((option) => getOptionLabel(option)
    .toLowerCase()
    .includes(search.toLowerCase()));

  return render(
    <div className={classNames('relative', className)}>
      <input
        className={inputClassName}
        onFocus={() => {
          setIsOpen(true);
          setSearch('');
        }}
        onBlur={() => {
          setIsOpen(false);
          setSearch(currentOption ? getOptionLabel(currentOption) : placeholder || '');
        }}
        ref={inputRef}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        title={search}
      />
      <div
        // eslint-disable-next-line quote-props
        className={classNames('absolute z-50', menuClassName, { 'hidden': !isOpen })}
        onMouseDown={(e) => e.preventDefault()}
      >
        <div
          className={classNames('cursor-pointer', itemClassName)}
          onClick={() => {
            fieldApi.setValue();
            setIsOpen(false);
            inputRef.current?.blur();
          }}
        >
          {placeholder}
        </div>
        {filteredOptions.slice(0, 5).map((option) => (
          <div
            className={classNames('cursor-pointer', itemClassName, { selected: currentOption === option })}
            key={JSON.stringify(getOptionValue(option))}
            onClick={() => {
              fieldApi.setValue(getOptionValue(option));
              setIsOpen(false);
              inputRef.current?.blur();
            }}
          >
            {getOptionLabel(option)}
          </div>
        ))}
        <div className={footerClassName}>{Math.max(filteredOptions.length - 5, 0)} více</div>
      </div>
    </div>,
  );
};

export default ComboBox;
