import { Col, Row } from 'antd';
import { Debug, Form } from 'informed';
import moment from 'moment';

import TyAsyncMultiSelect from '../../components/inputs/TyAsyncMultiSelect';
import TyAsyncSelect from '../../components/inputs/TyAsyncSelect';
import TyCheckbox from '../../components/inputs/TyCheckbox';
import TyDateInput from '../../components/inputs/TyDateInput';
import TyInput from '../../components/inputs/TyInput';
import TyMultiDateInput from '../../components/inputs/TyMultiDateInput';
import TyMultiSelect from '../../components/inputs/TyMultiSelect';
import TyRadio from '../../components/inputs/TyRadio';
import TyRadioGroup from '../../components/inputs/TyRadioGroup';
import { TySelect } from '../../components/inputs/TySelect';
import TyTextArea from '../../components/inputs/TyTextArea';
import { PdfFrame } from '../../components/PdfFrame/PdfFrame';
import TyAdvancedSearchAttendance from '../../components/TyAdvancedSearch/Attendance';
import UploadSign from '../../components/UploadSign';
import Wrapper from '../../components/wrapper';
import Container from '../../containers';

const options = [];
for (let i = 0; i < 50; i += 1) {
  options.push({
    value: i + 1,
    label: `Color ${i + 1}`,
  });
}

const optionsTwo = [];
for (let i = 0; i < 50; i += 1) {
  optionsTwo.push({
    value: i + 1,
    label: `Flavour ${i + 1}`,
  });
}

export const groupedOptions = [
  {
    label: 'Colours',
    options,
  },
  {
    label: 'Flavours',
    options: optionsTwo,
  },
];

const sleep = (ms) => new Promise((resolve) => {
  setTimeout(() => {
    resolve();
  }, ms);
});

const loadOptions = async (search, prevOptions) => {
  if (search.length === 0 || search.length > 2) {
    await sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchLower));
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10,
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  }
  return {
    options: [],
  };
};

const loadGroupedOptions = async (search, prevOptions) => {
  if (search.length === 0 || search.length > 2) {
    await sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = groupedOptions;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = groupedOptions.map((item) => ({
        label: item.label,
        options: item.options.filter(({ label }) => label.toLowerCase().includes(searchLower)),
      }));
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10,
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  }
  return {
    options: [],
  };
};
// eslint-disable-next-line react/button-has-type, no-console
const SentryTest = () => <button onClick={() => console.log(test.no.exist)}>Break the world</button>;

const FormExample = () => {
  const validateGandalfSurname = (val) => {
    if (!val || val.length <= 3) {
      return 'Gandalf měl delší jméno než 3 symboly';
    }
    return undefined;
  };

  const validateFrodoSurname = (val) => {
    if (!val || val !== 'Pytlík') {
      return `Já ti dám ${val} ty rošťáku!`;
    }
    return undefined;
  };

  const validateMinimumAge = (val) => {
    if (!val || val < 18) return `You, youngling, shall not pass since you are only ${val}`;
    return undefined;
  };

  const validateTextArea = (val) => {
    if (val.length < 50) return 'Mooore... I need more... Letters!';
    return undefined;
  };

  // example - function to choose which dates will be disabled - every date, which day is > 10
  const disabledWeekends = (current) => current.day() === 0 || current.day() === 6;

  return (
    <Container
      header
      contentstyle={{ marginTop: '4%' }}
      iconcolor="#B3CA1F"
    >
      <Wrapper>
        <Form>
          <Row gutter={20} align="middle">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              <div>
                <h3> Number input (default) </h3>
                <TyInput label="Kolik je Froddovi" type="number" min={0} name="numberInputTest" />
              </div>
              <div>
                <h3> Number input (with custom controls) </h3>
                <TyInput
                  label="number input!"
                  name="labeledNumberInputTest"
                  type="number"
                  showControls
                />
              </div>
              <div>
                <h3> Number input (with custom controls + validation + required) </h3>
                <TyInput
                  label="18+ only!"
                  required="Hey, I am needed! Required! Important! Fill me! 〈 ͡° ͜ʖ ͡°〉"
                  validate={validateMinimumAge}
                  name="labeledValidationNumberInputTest"
                  type="number"
                  min={1}
                  showControls
                />
              </div>
              <div>
                <h3> Text input (validation) </h3>
                <TyInput
                  validate={validateGandalfSurname}
                  name="labeledInputTest"
                  label="Surname of Gandalf"
                />
              </div>
              <div>
                <h3> Required input </h3>
                <TyInput
                  required="Toto pole je povinné ty ďáble"
                  name="labeledRequiredInputTest"
                  type="text"
                  label="Where Sauron lives"
                />
              </div>
              <div>
                <h3> Required input (required + validation) </h3>
                <TyInput
                  required="Toto pole je povinné ty ďáble"
                  validate={validateFrodoSurname}
                  name="labeledRequiredValidatedInputTest"
                  type="text"
                  label="What is the last name of Frodo"
                />
              </div>
              <div>
                <h3> Password input (labeled) </h3>
                <TyInput
                  name="labeledPasswordInputTest"
                  type="password"
                  label="I'd like to know your password 〈 ͡° ͜ʖ ͡°〉"
                />
              </div>
              <div>
                <h3> TextArea input (required + validation) </h3>
                <TyTextArea
                  validate={validateTextArea}
                  required="Toto pole je povinné!"
                  rows={10}
                  name="labeledTextAreaTest"
                  label="Hey Ciri, tell me a joke"
                />
              </div>
              <div>
                <h3>Radio buttons</h3>
                <TyRadioGroup required="vyplň mě uwu" className="ty-radio-group" label="Sex" name="gender">
                  <TyRadio value="male" label="Muž" />
                  <TyRadio value="female" label="Žena" />
                  <TyRadio value="helicopter" label="Bojová helikoptéra" />
                  <TyRadio value="robinson" label="Robinson" />
                  <TyRadio value="doomslayer" label="Doomslayer" />
                  <TyRadio value="xena" label="Xena" />
                  <TyRadio value="lost" label="Ztracená duše" />
                  <TyRadio value="other" label="Jiné" />
                </TyRadioGroup>
              </div>
              <div>
                <h3>Checkboxes</h3>
                <TyCheckbox required name="admin" label="Wanna be admin?" />
                <TyCheckbox name="company" label="Wanna be only company admin?" />
                <TyCheckbox
                  required="Všichni chtějí být gandalf"
                  name="gandalf"
                  label="Wanna be the legendary Gandalf?"
                />
              </div>

              <div>
                <h3> Select (required) </h3>
                <TySelect
                  name="simpleSelect"
                  label="simpleSelect"
                  options={options}
                  isClearable
                />
              </div>

              <div>
                <h3> Multi Select (required) </h3>
                <TyMultiSelect
                  name="simpleMulti"
                  label="simpleMulti"
                  options={options}
                  isClearable
                />
              </div>

              <div>
                <h3> Async Select (required) </h3>
                <TyAsyncSelect
                  loadOptions={loadOptions}
                  name="selectRquired"
                  label="Select"
                  isClearable
                  required
                />
              </div>
              <div>
                <h3> Async Multi Select (required) </h3>
                <TyAsyncMultiSelect
                  loadOptions={loadOptions}
                  name="multiselectRequired"
                  label="Multiselect"
                  isClearable
                  required
                />
              </div>

              <div>
                <h3> Async Select </h3>
                <TyAsyncSelect
                  loadOptions={loadOptions}
                  name="select"
                  label="Select"
                />
              </div>
              <div>
                <h3> Async Multi Select </h3>
                <TyAsyncMultiSelect
                  loadOptions={loadOptions}
                  name="multiselect"
                  label="multiselect"
                />
              </div>

              <div>
                <h3> Async Multi Select Grouped </h3>
                <TyAsyncMultiSelect
                  loadOptions={loadGroupedOptions}
                  name="multiselectGrouped"
                  label="multiselect grouped"
                />
              </div>

              <div>
                <h3>Basic Date Input </h3>
                <TyDateInput
                  name="basicDateInput"
                  label="basic date input"
                />
              </div>

              <div>
                <h3>Basic Date Input + restricted days (weekends) + custom select</h3>
                <TyDateInput
                  name="betterBasicDateInput"
                  label="better basic date input"
                  datePresets={[
                    { label: 'Včera', date: moment().startOf('day').subtract(1, 'day') },
                    { label: 'Dnes', date: moment().startOf('day') },
                    { label: 'Zítra', date: moment().startOf('day').add(1, 'day') },
                  ]}
                  disabledDate={disabledWeekends}
                  isClearable
                  required
                />
              </div>

              <div>
                <h3>Basic Time Input</h3>
                <TyDateInput
                  name="timeInput"
                  label="Enter time"
                  minuteStep={5}
                  required
                  picker="time"
                />
              </div>

              <div>
                <h3>Multiple Date Selector</h3>
                <TyMultiDateInput
                  name="multiDate"
                  label="Enter Dates"
                  required
                  datePresets={[
                    {
                      label: 'Příští týden',
                      date: [0, 1, 2, 3, 4].map((offset) => moment()
                        .add(1, 'w')
                        .startOf('w')
                        .add(offset, 'd')),
                    },
                  ]}
                />
              </div>

              <div>
                <UploadSign onChange={() => { }} />
              </div>

              <div>
                <TyAdvancedSearchAttendance
                  pageTitle="Testovací hledání"
                  onChange={() => console.log('test, takhle nejak budeme posilat data')}
                />
              </div>
              <SentryTest />
              <button className="ty-button-primary" type="submit">Send me to church</button>
            </div>
            <Col offset="2">
              <span> Debug (input values) </span>
              <Debug />
            </Col>

          </Row>
        </Form>
        <PdfFrame url="https://upload.wikimedia.org/wikipedia/commons/a/a4/Placeholder.pdf" />
      </Wrapper>
    </Container>
  );
};

export default FormExample;
