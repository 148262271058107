import classNames from 'classnames';
import { ReactNode } from 'react';

type TyCalWeekGroupTailProps = {
  children: ReactNode;
  className?: string;
};

export default function TyCalWeekGroupTail({ children, className }: TyCalWeekGroupTailProps) {
  return (
    <div className={classNames('ty-cal-week-group-tail', className)}>{children}</div>
  );
}
