import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useUser } from '../../../../apiClient/ApiContext';
import AdminTable from '../../../../components/AdminTable';
import { SecondaryButton } from '../../../../components/buttons';
import { TableRangePicker } from '../../../../components/inputs';
import SearchBox from '../../../../components/search/SearchBox';
import { TableHeaderControls } from '../../../../components/tableHeaders';
import { PageTitle } from '../../../../components/texts';
import Wrapper from '../../../../components/wrapper';
import { selectAdminLoading } from '../../../../data/store/slices/admin';
import { selectBlocks } from '../../../../data/store/slices/ban';
import { Roles } from '../../../../utils/enums';

const AdminTymberBlocksContainer = ({
  pageTitle = '',
  columns,
  onSearch,
  showModal,
  onSelectChange,
  selectedRowKeys,
  onDateChange,
  onPaginationChange,
  onTableChange,
  currentPage = 1,
  defaultPageSize,
}) => {
  const data = useSelector(selectBlocks);
  const isAdmin = useUser().hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);
  const loading = useSelector(selectAdminLoading);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      const index = columns.findIndex((x) => x.dataIndex === 'company_name');
      if (index > -1) {
        columns.splice(index, 1);
      }
    }
  }, [columns, isAdmin]);

  const renderExpandableRow = (record) => (
    <>
      <span style={{ paddingLeft: '34px' }} className="ty-perex-14">
        Poznámka:{' '}
      </span>
      <small style={{ paddingLeft: '34px' }}>{record.note}</small>
    </>
  );

  return (
    <Wrapper padding="0px" margin="0px 22px 18px 31px">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PageTitle>{pageTitle}</PageTitle>
        <div>
          <SecondaryButton buttontext="Zablokovat" onClick={showModal} />
        </div>
      </div>
      <AdminTable
        rowKey="id"
        columns={columns}
        data={data}
        isLoading={loading}
        onPaginationChange={onPaginationChange}
        onChange={onTableChange}
        currentPage={currentPage}
        rowSelection={rowSelection}
        expandable={{
          expandedRowRender: renderExpandableRow,
          rowExpandable: (record) => record.note && record.note,
        }}
        defaultPageSize={defaultPageSize}
        // TODO: This is ticking bomb. Refactor!
        // eslint-disable-next-line react/no-unstable-nested-components
        title={() => (
          <>
            <div className="ty-title-row">
              <TableRangePicker label="Blokace od - do" onDateChange={onDateChange} />
            </div>
            <div style={{ textAlign: 'center' }}>
              <TableHeaderControls>
                <SearchBox
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  onParamsSearch={onSearch}
                  loading={loading}
                  placeholder="Jméno/ID Tymbera nebo ID Autora"

                />
              </TableHeaderControls>
            </div>
          </>
        )}
      />
    </Wrapper>
  );
};

AdminTymberBlocksContainer.propTypes = {
  pageTitle: PropTypes.string,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  onSearch: PropTypes.func,
  showModal: PropTypes.func,
  onSelectChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  currentPage: PropTypes.number,
  onTableChange: PropTypes.func,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  selectedRowKeys: PropTypes.array,
  defaultPageSize: PropTypes.number,
};

export default AdminTymberBlocksContainer;
