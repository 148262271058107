import { createContext, useContext } from 'react';

type TyCalWeekEventData<T = any> = {
  date: string;
  data: T;
};

export interface TyCalWeekContextType<T = any> {
  startDate: string;
  eventData: TyCalWeekEventData<T>[];
}

const TyCalWeekContext = createContext<TyCalWeekContextType>({
  startDate: '',
  eventData: [],
});

export const useTyCalWeekData = <T = any>() => useContext<TyCalWeekContextType<T>>(TyCalWeekContext);

export default TyCalWeekContext;
