import classNames from 'classnames';
import moment from 'moment-timezone';
import { HTMLAttributes } from 'react';

import TyCalDayHead from './TyCalDayHead';

interface TyCalDayOfWeekProps extends HTMLAttributes<HTMLDivElement> {
  date: string;
  day: number;
}

const TyCalDayOfWeek = ({ date, ...rest }: TyCalDayOfWeekProps) => {
  const m = moment(date);
  const now = moment();
  const day = m.get('date');
  const dayName = m.format('dd');
  const isToday = m.isSame(now, 'day');

  return (
    <TyCalDayHead
      className={classNames(
        {
          'text-secondary-900': isToday,
          'text-secondary-500': !isToday,
        },
        'flex flex-col justify-center items-center',
      )}
      {...rest}
    >
      <div className="justify-between font-medium capitalize">
        {dayName} <span className={classNames({ 'bg-primary rounded px-1 text-bg': isToday })}>{day}</span>
      </div>
    </TyCalDayHead>
  );
};

export default TyCalDayOfWeek;
