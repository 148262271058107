import { FeathersError } from '@feathersjs/errors';
import { AttendanceData } from '@tymbe/schema/attendance.interface';
import { AttendanceResolution } from '@tymbe/schema/enums';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import ShiftAttendance, { FormValues } from './components/ShiftAttendance/ShiftAttendance';
import apiClient from '../../apiClient';
import { useUser } from '../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../components/alerts';
import Spinner from '../../components/Spinner';
import { ShiftData } from '../../types/TymbeApi';
import { calculateWorkTime } from '../../utils/time';

const ShiftAttendancePage = () => {
  const user = useUser();
  const { shiftId } = useParams();
  const queryClient = useQueryClient();
  const queryKey: [string, string, number] = ['shift', 'application', Number(shiftId)];

  const { data: shift } = useQuery(
    queryKey,
    async ({ queryKey: key }) =>
      apiClient.service('shift').get(
        key[2],
        {
          query: {
            $joinEager: 'application.[person, attendance]',
            $sort: { 'application:attendance:resolution': 1, 'application:person:last_name': 1 },
          },
        },
      ),
    { enabled: !!shiftId },
  );

  const { mutateAsync: createAttendance } = useMutation({
    mutationKey: ['createAttendance'],
    mutationFn: async (values: Partial<AttendanceData>[]) =>
      apiClient.service('attendance').create(values),
    onMutate: async (values) => {
      await queryClient.cancelQueries({ queryKey });

      const previousShift = queryClient.getQueryData<ShiftData>(queryKey);

      if (!previousShift) return {};

      const attendances = values.map((att) => ({
        id: -1,
        note: null,
        resolution: AttendanceResolution.OK,
        confirmed_by: user.person_id,
        application_id: -1,
        confirmed_time: 0,
        confirmed_credit_time: 0,
        confirmed_overtime: 0,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        deleted_at: null,
        ...att,
      }));

      const newShift = {
        ...previousShift!,
        application: previousShift!.application?.map((app) => ({
          ...app,
          attendance: attendances.find(({ application_id }) => application_id === app.id) ?? app.attendance,
        })),
      };

      queryClient.setQueryData(queryKey, newShift);
      return { previousShift };
    },
    onError: (err, newShift, context) => {
      queryClient.setQueryData(queryKey, context?.previousShift);
      if (err instanceof FeathersError) {
        ErrorAlert(`${err.name}: ${err.message}`, 10);
      } else {
        ErrorAlert('Při vytváření docházky došlo k neznámé chybě');
        console.error(err);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries(queryKey);
      SuccessAlert('Docházka byla úspěšně vytvořena');
    },
  });

  const onSubmit = async (values: FormValues) => {
    const attendances = values.map((vals): Partial<AttendanceData> => ({
      confirmed_time: calculateWorkTime(vals.start, vals.end, false, 'minute'),
      resolution: AttendanceResolution.OK,
      application_id: vals.id,
      confirmed_by: user.person_id,
      job_evaluation: Number(vals.rating),
    }));
    await createAttendance(attendances);
  };

  if (!shiftId) return null;
  if (!shift) return <Spinner show />;

  return (
    <ShiftAttendance
      applications={shift.application ?? []}
      startDate={shift.start_time}
      endDate={shift.end_time}
      onSubmit={onSubmit}
    />
  );
};

export default ShiftAttendancePage;
