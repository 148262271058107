/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../apiClient';

export const loadCompanyThunk = createAsyncThunk(
  'company/list',
  async (params) => feathersClient.service('company').find({
    query: {
      $eager: '[address, branchoffice]',
      ...params,
    },
  }),
);

const loadCompanyReducer = {
  [loadCompanyThunk.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [loadCompanyThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.data = null;
  },
  [loadCompanyThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = null;
    state.data = action.payload.data;
    state.pagination = {
      total: action.payload.total,
      skip: action.payload.skip,
      limit: action.payload.limit,
    };
  },
};

export const createCompanyThunk = createAsyncThunk(
  'company/create',
  async (body) => feathersClient.service('company').create(body),
);

const createCompanyReducer = {
  [createCompanyThunk.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [createCompanyThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createCompanyThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.data = [...state.data, action.payload.data];
  },
};

export const patchCompanyThunk = createAsyncThunk(
  'company/patch',
  async (data) => {
    const { id, body } = data;
    return feathersClient.service('company').patch(id, body);
  },
);

const patchCompanyReducer = {
  [patchCompanyThunk.pending]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [patchCompanyThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [patchCompanyThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.data = [...state.data.filter((x) => x.id !== action.payload.id), action.payload];
  },
};

const companySlice = createSlice({
  name: 'companyContainer',
  initialState: {
    loading: false,
    error: null,
    data: null,
    pagination: {
      total: null,
      skip: 0,
      limit: 10,
    },
  },
  reducers: {},
  extraReducers: {
    ...loadCompanyReducer,
    ...createCompanyReducer,
    ...patchCompanyReducer,
  },
});

export const selectCompanies = (state) => state.data;

export const selectCompanyListPagination = (state) => state.pagination;

export default companySlice.reducer;
