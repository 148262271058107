import { useState } from 'react';

type SwitchProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const Switch = ({ checked: initital = false, onChange = () => {} }: SwitchProps) => {
  const [checked, setChecked] = useState(initital);
  const handleClick = () => {
    setChecked((prev) => {
      onChange(!prev);
      return !prev;
    });
  };
  return (
    <button
      className={`relative inline-flex items-center rounded-full ${!checked
        ? 'bg-success'
        : 'bg-error'} text-bg py-1.5 px-2 transition-colors duration-500 shadow-inner border h-8 w-16`}
      onClick={handleClick}
      type="button"
    >
      <svg
        width="24"
        height="13"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`absolute right-0.5 transform transition-transform ${checked
          ? 'scale-0'
          : 'scale-100'} duration-300`}
      >
        <g clipPath="url(#clip0_74:4634)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.39751 1.04296C7.12735 0.977683 6.81288 0.945557 6.50028 0.945557C6.18768 0.945557 5.87321 0.977683 5.60305 1.04296C5.46799 1.0756 5.33625 1.11835 5.21724 1.17444C5.10889 1.2255 4.96279 1.30956 4.83965 1.45429C4.58996 1.74775 4.5764 2.24063 4.80937 2.55516C5.02995 2.85296 5.39245 2.88411 5.64233 2.63749C5.64471 2.63629 5.64747 2.63494 5.65061 2.63346C5.68637 2.6166 5.74712 2.59435 5.83604 2.57286C6.01384 2.5299 6.24824 2.50335 6.50028 2.50335C6.75232 2.50335 6.98671 2.5299 7.16452 2.57286C7.25344 2.59435 7.31419 2.6166 7.34995 2.63346C7.35309 2.63494 7.35584 2.63629 7.35823 2.63749C7.6081 2.88411 7.97061 2.85296 8.19119 2.55516C8.42416 2.24063 8.4106 1.74775 8.1609 1.45429C8.03776 1.30956 7.89167 1.2255 7.78332 1.17444C7.66431 1.11835 7.53256 1.0756 7.39751 1.04296Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 3.73169H0V5.77455C0.797745 5.77455 1.44444 6.42125 1.44444 7.219V9.04313C1.44444 11.0259 1.44444 12.0173 1.92615 12.7261C2.1235 13.0165 2.37396 13.2669 2.66435 13.4643C3.37316 13.946 4.36454 13.946 6.3473 13.946H6.6527C8.63546 13.946 9.62684 13.946 10.3356 13.4643C10.626 13.2669 10.8765 13.0165 11.0739 12.7261C11.5556 12.0173 11.5556 11.0259 11.5556 9.04313V7.219C11.5556 6.42125 12.2023 5.77455 13 5.77455V3.73169ZM5.17993 7.35273C5.17993 7.09049 4.96734 6.87789 4.7051 6.87789C4.44285 6.87789 4.23026 7.09049 4.23026 7.35273V11.0459C4.23026 11.3082 4.44285 11.5208 4.7051 11.5208C4.96734 11.5208 5.17993 11.3082 5.17993 11.0459V7.35273ZM8.87312 7.35273C8.87312 7.09049 8.66052 6.87789 8.39828 6.87789C8.13603 6.87789 7.92344 7.09049 7.92344 7.35273V11.0459C7.92344 11.3082 8.13603 11.5208 8.39828 11.5208C8.66052 11.5208 8.87312 11.3082 8.87312 11.0459V7.35273Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_74:4634">
            <rect
              width="13"
              height="12.9293"
              fill="white"
              transform="translate(0 0.945557)"
            />
          </clipPath>
        </defs>
      </svg>
      <span
        className={`absolute left-0.5 bg-bg w-6 h-6 rounded-full flex items-center justify-center transition duration-500 transform ${!checked
          ? ''
          : 'translate-x-[2.15rem]'}`}
      >
        <svg
          width="24"
          height="13"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`flex-none transition duration-500 transform text-error ${!checked
            ? 'opacity-0 scale-0'
            : 'opacity-100 scale-100'}`}
        >
          <g clipPath="url(#clip0_74:4634)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.39751 1.04296C7.12735 0.977683 6.81288 0.945557 6.50028 0.945557C6.18768 0.945557 5.87321 0.977683 5.60305 1.04296C5.46799 1.0756 5.33625 1.11835 5.21724 1.17444C5.10889 1.2255 4.96279 1.30956 4.83965 1.45429C4.58996 1.74775 4.5764 2.24063 4.80937 2.55516C5.02995 2.85296 5.39245 2.88411 5.64233 2.63749C5.64471 2.63629 5.64747 2.63494 5.65061 2.63346C5.68637 2.6166 5.74712 2.59435 5.83604 2.57286C6.01384 2.5299 6.24824 2.50335 6.50028 2.50335C6.75232 2.50335 6.98671 2.5299 7.16452 2.57286C7.25344 2.59435 7.31419 2.6166 7.34995 2.63346C7.35309 2.63494 7.35584 2.63629 7.35823 2.63749C7.6081 2.88411 7.97061 2.85296 8.19119 2.55516C8.42416 2.24063 8.4106 1.74775 8.1609 1.45429C8.03776 1.30956 7.89167 1.2255 7.78332 1.17444C7.66431 1.11835 7.53256 1.0756 7.39751 1.04296Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13 3.73169H0V5.77455C0.797745 5.77455 1.44444 6.42125 1.44444 7.219V9.04313C1.44444 11.0259 1.44444 12.0173 1.92615 12.7261C2.1235 13.0165 2.37396 13.2669 2.66435 13.4643C3.37316 13.946 4.36454 13.946 6.3473 13.946H6.6527C8.63546 13.946 9.62684 13.946 10.3356 13.4643C10.626 13.2669 10.8765 13.0165 11.0739 12.7261C11.5556 12.0173 11.5556 11.0259 11.5556 9.04313V7.219C11.5556 6.42125 12.2023 5.77455 13 5.77455V3.73169ZM5.17993 7.35273C5.17993 7.09049 4.96734 6.87789 4.7051 6.87789C4.44285 6.87789 4.23026 7.09049 4.23026 7.35273V11.0459C4.23026 11.3082 4.44285 11.5208 4.7051 11.5208C4.96734 11.5208 5.17993 11.3082 5.17993 11.0459V7.35273ZM8.87312 7.35273C8.87312 7.09049 8.66052 6.87789 8.39828 6.87789C8.13603 6.87789 7.92344 7.09049 7.92344 7.35273V11.0459C7.92344 11.3082 8.13603 11.5208 8.39828 11.5208C8.66052 11.5208 8.87312 11.3082 8.87312 11.0459V7.35273Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_74:4634">
              <rect
                width="13"
                height="12.9293"
                fill="white"
                transform="translate(0 0.945557)"
              />
            </clipPath>
          </defs>
        </svg>
      </span>
    </button>
  );
};

export default Switch;
