import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const notificationSlice = createSlice({
  name: 'popupNotification',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.push(action.payload);
      return state;
    },
    removeNotification: (state, action) => state.filter((x) => x.id !== action.payload),
  },
});

export default notificationSlice.reducer;
export const { addNotification, removeNotification } = notificationSlice.actions;
