import { CompanyBlockedUserData } from '@tymbe/schema/company-blocked-user.interface';
import Tooltip from 'rc-tooltip';

import CompanyBlocksTooltip from './CompanyBlocksTooltip';

interface BlocksProps {
  companyBlockedUser?: CompanyBlockedUserData[];
}

const Blocks = ({ companyBlockedUser = [] }: BlocksProps) => {
  if (!companyBlockedUser.length) return <div>0</div>;

  return (
    <Tooltip trigger={['hover']} overlay={<CompanyBlocksTooltip blockList={companyBlockedUser} />}>
      <div className="flex text-danger">
        {companyBlockedUser.length}
      </div>
    </Tooltip>
  );
};

export default Blocks;
