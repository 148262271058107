import { DepartmentFormValues } from './components/DepartmentForm';
import { BranchofficeType } from '../../../../utils/enums';

const mapFormValuesToDepartment = (values : DepartmentFormValues, companyId: number) => ({
  name: values.name,
  display_name: values.display_name,
  instruction: values.instruction,
  company_id: companyId,
  address_id: null,
  parent_id: values.parent?.id,
  contact_person: values.contactPerson?.id,
  type: BranchofficeType.DEPARTMENT,
});

export default mapFormValuesToDepartment;
