import styles from './Unit.module.css';

export type UnitProps = {
  value: string | number;
  unit: string;
};
const Unit = ({ value, unit }: UnitProps) => (
  <>
    {value}{' '}
    <span className={styles.unit}>{unit}</span>
  </>
);

export default Unit;
