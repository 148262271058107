import { OutputData } from '@editorjs/editorjs';
import { Service } from '@feathersjs/feathers';
import { JsonLayout } from '@tymbe/ty-docs/types/jsonDocument';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import DocumentTypeEditor from './components/DocumentTypeEditor';
import feathersClient from '../../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../../components/alerts';
import Button from '../../../../../../components/buttons/Button';
import { DownloadIcon, PaperIcon, UploadIcon } from '../../../../../../components/icons';
import EditorJsExportModal from '../../../../../../components/modals/EditorJsExportModal';
import EditorJsImportModal from '../../../../../../components/modals/EditorJsImportModal';
import { PageTitle } from '../../../../../../components/texts';
import Wrapper from '../../../../../../components/wrapper';
import Container from '../../../../../../containers';
import { DocumentTypeData } from '../../../../../../types/TymbeApi';
import { isOutputData } from '../../../../../../types/utils';
import { getDocumentAction } from '../../../../../../utils/documents';
import { DocumentAction } from '../../../../../../utils/Editor.utils';
import { EditDocumentAction } from '../../../../../../utils/enums';

interface DocumentTypeParams {
  documentTypeId: string;
  [K: string]: string;
}

const documentTypeService: Service<DocumentTypeData> =
  feathersClient.service('document-type');

const EditDocumentTypePage = () => {
  const navigator = useNavigate();
  const [editorData, setEditorData] = useState<OutputData>();
  const [layout, setLayout] = useState(JsonLayout.PORTRAIT);
  const { documentTypeId } = useParams<DocumentTypeParams>();
  const [showExportModal, setShowExportModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [exportJson, setExportJson] = useState<OutputData>();
  const [importData, setImportData] = useState<OutputData>();
  const [signal, setSignal] = useState(0);

  const { data: documentType, isError } = useQuery(
    ['documentType', documentTypeId],
    async () =>
      documentTypeService.get(Number(documentTypeId), {
        query: { $eager: 'branchoffice' },
      }),
    {
      retry: false,
    },
  );

  useEffect(() => {
    if (documentType) {
      const { json } = documentType;
      if (typeof json === 'string') {
        const parsed = JSON.parse(json);
        setLayout(parsed.options ? parsed.options.layout : JsonLayout.PORTRAIT);
      } else {
        setLayout(json?.options ? json.options.layout : JsonLayout.PORTRAIT);
      }
      setEditorData(isOutputData(json) ? json : undefined);
    }
  }, [documentType, setEditorData]);

  const action = documentType ? getDocumentAction(documentType) : undefined;

  if (isError || (documentType && action !== EditDocumentAction.EDITOR_JS)) {
    navigator('/not-found');
  }

  const submitDocumentContent = async (
    data: OutputData | null,
    isDelete?: boolean,
  ): Promise<void> => {
    if (!documentType) {
      return;
    }

    const document: DocumentTypeData = {
      ...documentType,
      json: isOutputData(data) ? JSON.stringify({ ...data, options: { layout } }) : null,
    };

    const { id } = documentType;
    const body = { json: document.json };
    await documentTypeService.patch(id, body).catch(() => {
      ErrorAlert(
        `Nastala chyba při pokusu o ${
          isDelete ? 'smazání' : 'úpravu'
        } obsahu dokumentu`,
      );
      throw new Error(
        `Nastala chyba při pokusu o ${
          isDelete ? 'smazání' : 'úpravu'
        } obsahu dokumentu`,
      );
    });

    SuccessAlert(
      `Obsah dokumentu byl úspěšně ${isDelete ? 'smazán' : 'upraven'}`,
    );
  };

  const deleteDocumentContent = async (): Promise<void> => {
    submitDocumentContent(null, true);
  };

  const showEditor = (documentType?.json && editorData) || !documentType?.json;

  if (!documentType || !showEditor) {
    return null;
  }

  const importDocument = (data: OutputData | undefined) => {
    setImportData(data);
    setSignal((prevValue) => prevValue + 1);
  };

  const displayModal = (type: DocumentAction) => {
    if (type === DocumentAction.EXPORT) {
      if (editorData) {
        setExportJson(editorData);
        setShowExportModal(true);
      }
    } else if (type === DocumentAction.IMPORT) {
      setShowImportModal(true);
    }
  };
  const changeLayout = () => {
    switch (layout) {
      case JsonLayout.PORTRAIT:
        setLayout(JsonLayout.LANDSCAPE);
        break;
      case JsonLayout.LANDSCAPE:
        setLayout(JsonLayout.PORTRAIT);
        break;
      default:
        break;
    }
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <div className="flex justify-between items-center">
          <PageTitle>
            {documentType?.json ? 'Editace dokumentu' : 'Nový dokument'}
          </PageTitle>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <span>Změnit orientaci: </span>
              <Button
                className="ty-button-primary"
                onClick={changeLayout}
              >{layout === 'portrait' ? <div className="-rotate-90"><PaperIcon /></div> : <PaperIcon />}
              </Button>
            </div>
            <Button
              className="ty-button-primary"
              onClick={() => displayModal(DocumentAction.EXPORT)}
            >
              <DownloadIcon />
            </Button>
            <Button
              className="ty-button-primary"
              onClick={() => displayModal(DocumentAction.IMPORT)}
            >
              <UploadIcon dimensions={22} />
            </Button>
          </div>
        </div>
        <div className="w-full h-full">
          <DocumentTypeEditor
            onSave={submitDocumentContent}
            onDelete={deleteDocumentContent}
            editorData={editorData}
            setEditorData={setEditorData}
            importData={importData}
            signal={signal}
            layout={layout}
          />
        </div>
      </Wrapper>
      {showExportModal && (
        <EditorJsExportModal
          showModal={showExportModal}
          setShowModal={setShowExportModal}
          json={exportJson}
        />
      )}
      {showImportModal && (
        <EditorJsImportModal
          onSubmit={importDocument}
          showModal={showImportModal}
          setShowModal={setShowImportModal}
        />
      )}
    </Container>
  );
};

export default EditDocumentTypePage;
