import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

import { InfoIcon } from '../../../components/icons';
import { primaryColor } from '../../../utils/colors';

export interface OrderInfoButtonProps {
  shiftId: number;
}
const OrderInfoButton = ({ shiftId }: OrderInfoButtonProps) => (
  <Tooltip overlay="Detail" placement="left" trigger={['hover']}>
    <Link to={`/shift/${shiftId}`}>
      <InfoIcon className="ty-table-row-controls" iconcolor={primaryColor} />
    </Link>
  </Tooltip>
);
export default OrderInfoButton;
