import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import { Paginated } from '@feathersjs/feathers';

import AdminTable from '../../../components/AdminTable';

export type OrdersTableSorter = {
  column?: unknown,
  columnKey?: string,
  field?: string,
  order?: 'ascend' | 'descend',
};

type OrdersTableProps = {
  selectedRowIds: number[];
  toggleRowSelection: (changeRows: ShiftStateData | ShiftStateData[], b: boolean) => void;
  isLoading: boolean;
  data: Paginated<ShiftStateData>;
  columns: unknown[];
  currentPage: number;
  onPaginationChange: (page: number, newPageSize: number) => void;
  onTableChange: (pagination: unknown, filters: unknown, sorter: OrdersTableSorter) => void;
  expandable: unknown;
};

const OrdersTable = ({
  selectedRowIds,
  toggleRowSelection,
  data,
  isLoading,
  columns,
  currentPage,
  onPaginationChange,
  expandable,
  onTableChange,
}: OrdersTableProps) => (
  <AdminTable
    rowKey={(record: ShiftStateData) => record.id}
    rowSelection={{
      onSelect: toggleRowSelection,
      selectedRowKeys: selectedRowIds,
      onSelectAll: (selected: unknown, selRows: unknown, changeRows: ShiftStateData | ShiftStateData[]) => {
        toggleRowSelection(changeRows, true);
      },
    }}
    columns={columns}
    data={data}
    onPaginationChange={onPaginationChange}
    isLoading={isLoading}
    expandable={expandable}
    rowClassName="ty-table-row-hoverable"
    currentPage={currentPage}
    onChange={onTableChange}
    pageSizeOptions={[10, 20, 50, 100]}
  />
);

export default OrdersTable;
