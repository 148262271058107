const columnHeaders = [
  {
    value: 'person_id',
    label: 'ID Tymbera',
    sortable: true,
  },
  {
    value: 'person.first_name',
    label: 'Tymber',
    sortable: false,
  },
  {
    value: 'documentType.name',
    label: 'Typ dokumentu',
    sortable: false,
  },
  {
    value: 'created_at',
    label: 'Vytvořeno',
    sortable: true,
  },
];

export default columnHeaders;
