import { Table, DatePicker } from 'antd';
import PropTypes from 'prop-types';

import { PrimaryButton } from '../../../../components/buttons';
import { CalendarIcon, FilterIcon, SearchIcon } from '../../../../components/icons';
import { PageTitle } from '../../../../components/texts';
import Wrapper from '../../../../components/wrapper';
import { primaryColor } from '../../../../utils/colors';

const AdminTymberLogsContainer = ({
  pageTitle = null,
  columns = undefined,
  data = undefined,
  onSearch = undefined,
  onFilter = undefined,
}) => (
  <Wrapper padding="0px" margin="0px 22px 18px 31px">
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <PageTitle>{pageTitle}</PageTitle>
      </div>
      <Table
        bordered
        size="small"
        className="ty-table"
        // TODO: This is ticking bomb. Refactor!
        // eslint-disable-next-line react/no-unstable-nested-components
        title={() => (
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                float: 'left',
                marginTop: '23px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <small style={{ marginRight: '10px' }}>Směny od - do:</small>
              <DatePicker
                suffixIcon={<CalendarIcon iconcolor={primaryColor} />}
                placeholder="Vyberte datum"
              />
            </div>
            <PrimaryButton
              className="ty-icon-button"
              style={{
                float: 'right',
                marginTop: '30px',
                marginLeft: '10px',
                marginBottom: '30px',
              }}
              type="link"
              onClick={() => onSearch()}
              icon={<SearchIcon />}
            />
            <PrimaryButton
              className="ty-icon-button"
              style={{
                float: 'right',
                marginTop: '30px',
                marginBottom: '30px',
              }}
              type="link"
              onClick={() => onFilter()}
              icon={<FilterIcon />}
            />
          </div>
        )}
        columns={columns}
        dataSource={data}
      />
    </>
  </Wrapper>
);

AdminTymberLogsContainer.propTypes = {
  pageTitle: PropTypes.string,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  onSearch: PropTypes.func,
  onFilter: PropTypes.func,
};

export default AdminTymberLogsContainer;
