import { forwardRef, memo, useEffect } from "react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "./EditorTools";
import { JsonLayout } from "@tymbe/ty-docs/types/jsonDocument";

export type EditorBlockProps = {
  data?: OutputData;
  holder: string;
  onChange: (val: OutputData) => void;
  layout: JsonLayout;
};

const EditorBlock = forwardRef(function({ data, onChange, holder, layout }: EditorBlockProps, ref) {
  useEffect(() => {
    const editor = new EditorJS({
      holder,
      tools: EDITOR_TOOLS,
      data,
      autofocus: true,
      onChange: async (api) => onChange(await api.saver.save()),
    });
    if (typeof ref === 'function') {
      ref(editor);
    } else if (ref) {
      ref.current = editor;
    }

    return () => editor.destroy();
  }, [ holder ]);

  return <div id={holder} className={`prose max-w-full ${layout}-editorjs`} />;
});

export default memo(EditorBlock);
