import { Table } from 'antd';
import PropTypes from 'prop-types';

import { DEFAULT_PAGINATION_PROPS } from '../utils/constants';

const showTotal = (total, range) => (
  <small>
    Zobrazuji {range[0]} až {range[1]} z celkem {total} záznamů
  </small>
);

const AdminTable = ({
  data,
  columns,
  isLoading = false,
  onPaginationChange,
  currentPage = 1,
  pageSizeOptions = [10, 20, 50, 100],
  defaultPageSize = 10,
  ...props
}) => (
  <Table
    bordered
    size="small"
    className="ty-table"
    columns={columns}
    dataSource={data?.data || []}
    loading={{
      size: 'large',
      tip: 'Načítají se data',
      spinning: isLoading,
    }}
    pagination={{
      ...DEFAULT_PAGINATION_PROPS,
      pageSizeOptions,
      showTotal,
      current: currentPage,
      total: data?.total || 0,
      onChange: onPaginationChange,
      defaultPageSize,
    }}
    {...props}
  />
);

AdminTable.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    // Will be fixed using typescript
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
  }).isRequired,
  // Will be fixed using typescript
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  onPaginationChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  defaultPageSize: PropTypes.number,
};

export default AdminTable;
