import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, Navigate } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { resolveUploadableFiles } from '../../../../components/blob/blob.utils';
import { SubmitButton } from '../../../../components/buttons';
import { getValuesForPatch } from '../../../../components/forms/form-util';
import Card from '../../../../components/Layout/Card';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { RequestBody } from '../../../../types/ReactQuery';
import { CompanyData } from '../../../../types/TymbeApi';
import { mapCompanyData } from '../../company.utils';
import CompanyForm, { CompanyFormValues } from '../../components/CompanyForm';

const EditCompany = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const { companyId } = useParams();

  const history = useNavigate();

  const { mutateAsync: patchCompany } = useMutation(
    ['PatchCompany'],
    (req: RequestBody<CompanyData | FormData>): Promise<CompanyData> =>
      feathersClient.service('company').patch(req.id, req.body, req.params),
  );

  const { data: company } = useQuery(
    ['FetchCompanyDetail', companyId],
    async (): Promise<CompanyData> =>
      feathersClient.service('company').get(companyId!, {
        query: { $eager: '[address, contactPerson, contractor]' },
      }),

    { enabled: !!companyId },
  );

  if (!companyId) {
    return <Navigate to="/company" />;
  }

  const onSubmit = async (formState: FormState<CompanyFormValues>) => {
    if (!companyId) {
      if (!company?.address_id) ErrorAlert('Nebylo možné dohledat identifikátor adresy');
      history('/company');
      return;
    }
    setFormDisabled(true);
    const modifiedAndNulled = getValuesForPatch(formState);
    const companyData = mapCompanyData({
      ...modifiedAndNulled as CompanyFormValues, address_id: company?.address_id || undefined,
    });

    try {
      await patchCompany({ id: companyId, body: companyData });
      const formData = resolveUploadableFiles(modifiedAndNulled);
      if (formData) {
        await patchCompany(
          { id: companyId, body: formData, params: { headers: { 'Content-Type': 'multipart/form-data' } } },
        );
      }
      SuccessAlert('Firma byla úspěšně upravena');
      history('/company');
    } catch (err) {
      if ((err as Error).message === 'Can\'t change fulfillment to agency when some shift-template contains forbidden ISCO') {
        ErrorAlert(
          'Nelze změnit režim spolupráce na Agenturu, když existuje pracovní pozice s nepovolenou klasifikací ISCO',
        );
      } else if ((err as Error).message === 'Can\'t change fulfillment to agency when some future shift contains forbidden ISCO') {
        ErrorAlert(
          'Nelze změnit režim spolupráce na Agenturu, když existuje směna v budoucnosti s nepovolenou klasifikací ISCO',
        );
      } else {
        ErrorAlert('Při editaci adresy firmy došlo k chybě');
      }
    }
    setFormDisabled(false);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Editace firmy </h1>
        <Card>
          <Form<CompanyFormValues> name="company-form" onSubmit={onSubmit}>
            <CompanyForm company={company} />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default EditCompany;
