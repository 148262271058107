/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';

import feathersClient from '../../../../apiClient';
import { DocumentType } from '../../../../utils/enums';

const documents = [
  {
    document_id: 1,
    name: 'Občanský průkaz',
    description: 'Základní doklad totožnosti pro osoby ze zemí EU',
    documentType: { id: 1, type: DocumentType.ID_CARD, name: 'Občanský průkaz' },
    valid_from: '10.2.2020',
    valid_to: '2025-12-31',
    doc_id: '557847666',
    file: 'image',
    created_at: '1.9.2021',
  },

  {
    document_id: 1,
    name: 'DPP - Tesco stores a.s',
    description: 'PCR test na Covid-19 - WIP',
    documentType: { type: DocumentType.CONTRACT_DPP, name: 'DPP - Tesco stores a.s' },
    valid_from: '10.2.2020',
    valid_to: '31.12.2025',
    doc_id: '557847666',
    file: 'image',
    created_at: '1.9.2021',
    validity_start_at: 'on_sign', // TODO DPC - staci jen takto pro tyhle data?
    validity_end_at: 'year_end', // TODO DPC - staci jen takto pro tyhle data?
  },
  {
    document_id: 2,
    name: 'DPP - Tesco stores a.s',
    description: 'PCR test na Covid-19 - WIP',
    documentType: { type: DocumentType.CONTRACT_DPP, name: 'DPP - Tesco stores a.s' },
    valid_from: '10.2.2015',
    valid_to: '31.12.2020',
    doc_id: '557666847',
    file: 'image',
    created_at: '1.9.2019',
    validity_start_at: 'on_sign', // TODO DPC - staci jen takto pro tyhle data?
    validity_end_at: 'year_end', // TODO DPC - staci jen takto pro tyhle data?
  },
  {
    document_id: 2,
    name: 'Covid-19 PCR Test ',
    description: 'PCR test na Covid-19 -VALID',
    documentType: { type: DocumentType.HEALTH_CARD, name: 'Covid-19 PCR Test ' },
    state: 'accepted',
    valid_from: '10.2.2015',
    valid_to: '31.12.2020',
    doc_id: '557666847',
    file: 'image',
    created_at: '1.9.2019',
  },
  {
    document_id: 2,
    name: 'Covid-19 PCR Test ',
    description: 'PCR test na Covid-19 - WIP',
    documentType: { type: DocumentType.HEALTH_CARD, name: 'Covid-19 PCR Test ' },
    valid_from: '10.2.2015',
    valid_to: '31.12.2020',
    doc_id: '557666847',
    file: 'image',
    created_at: '1.9.2019',
  },
  {
    document_id: 2,
    name: 'Covid-19 PCR Test ',
    description: 'PCR test na Covid-19 - DECLINED',
    documentType: { type: DocumentType.HEALTH_CARD, name: 'Covid-19 PCR Test ' },
    state: 'refused',
    valid_from: '10.2.2015',
    valid_to: '31.12.2020',
    doc_id: '557666847',
    file: 'image',
    created_at: '1.9.2019',
  },
];
export const loadDocumentsThunk = createAsyncThunk('documents/loadDocument', async (query) => {
  const res = await feathersClient.service('person-document').find(query);
  return res;
});

const loadDocumentsReducer = {
  [loadDocumentsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.documents = null;
  },
  [loadDocumentsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.documents = null;
  },
  [loadDocumentsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.documents = [...action.payload.data, ...documents];
  },
};

export const updateDocumentThunk = createAsyncThunk('document/updateDocument', async () => {
  const document = {
    document_id: 1,
    documentType: 'drivers_licence_B',
    valid_from: '10.2. 2020',
    valid_to: '31.12.2025',
    doc_id: '557847666',
    file: 'image',
    created_at: '1.9.2021',
  };
  return { document };
});

const updateDocumentReducer = {
  [updateDocumentThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [updateDocumentThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [updateDocumentThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.document = action.payload.document;
  },
};

export const createPersonalDocumentThunk = createAsyncThunk(
  'jobs/createPersonalDocument',
  async (data) => {
    await feathersClient.service('person-document').create(data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
);

const createPersonalDocumentReducer = {
  [createPersonalDocumentThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createPersonalDocumentThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createPersonalDocumentThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const loadPaySlipsThunk = createAsyncThunk('documents/loadPaySlips', async () => {
  const paySlips = [
    {
      document_id: 1,
      name: 'DPP - Tesco stores a.s',
      documentType: { name: 'paySlip' },
      date: new Date().toISOString(),
      doc_id: '557847666',
      file: 'image',
    },
    {
      document_id: 2,
      name: 'DPP - Tesco stores a.s',
      documentType: { name: 'paySlip' },
      date: new Date().toISOString(),
      doc_id: '557847666',
      file: 'image',
    },
    {
      document_id: 3,
      name: 'DPP - Tesco stores a.s',
      documentType: { name: 'paySlip' },
      date: new Date().toISOString(),
      doc_id: '557847666',
      file: 'image',
    },
    {
      document_id: 4,
      name: 'DPP - Tesco stores a.s',
      documentType: { name: 'paySlip' },
      date: new Date().toISOString(),
      doc_id: '557847666',
      file: 'image',
    },
  ];
  return { paySlips };
});

const loadPaySlipsReducer = {
  [loadPaySlipsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.paySlips = null;
  },
  [loadPaySlipsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.paySlips = null;
  },
  [loadPaySlipsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.paySlips = action.payload.paySlips;
  },
};

export const sendConfirmationRequestThunk = createAsyncThunk(
  'document/confirmationRequest',
  async () => {
    const resp = true;
    return { resp };
  },
);

const sendConfirmationRequestThunkReducer = {
  [sendConfirmationRequestThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [sendConfirmationRequestThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [sendConfirmationRequestThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const loadDocumentTypeThunk = createAsyncThunk(
  'document/loadDocumentType',
  async (query) => feathersClient.service('document-type').find(query),
);
const loadDocumentTypeReducer = {
  [loadDocumentTypeThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadDocumentTypeThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.documentType = null;
  },
  [loadDocumentTypeThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.documentType = action.payload;
    state.documentTypeData = action.payload.data;
  },
};
const documentSlice = createSlice({
  name: 'documents',
  initialState: {
    loading: null,
    error: null,
    documents: null,
    paySlips: null,
    documentType: null,
    documentTypeData: null,
  },
  reducers: {},
  extraReducers: {
    ...loadDocumentsReducer,
    ...loadPaySlipsReducer,
    ...updateDocumentReducer,
    ...sendConfirmationRequestThunkReducer,
    ...loadDocumentTypeReducer,
    ...createPersonalDocumentReducer,
  },
});
export const selectDocumentsLoading = createSelector(
  (state) => state.documents.loading,
  (loading) => loading || false,
);
export const selectDocumentsError = createSelector(
  (state) => state.documents.error,
  (error) => {
    if (error !== null) return error;
    return undefined;
  },
);

export const selectHealthCertificates = createSelector(
  (state) => state.documents.documents,
  (docs) => (
    (docs && docs.filter(
      (doc) => doc.documentType && doc.documentType.type === DocumentType.HEALTH_CARD,
    )) || []
  ),
);

export const selectContracts = createSelector(
  (state) => state.documents.documents,
  (docs) => (
    (docs && docs.filter(
      (doc) => doc.documentType && doc.documentType.type === DocumentType.CONTRACT_DPP,
    )) || []
  ),
);
export const selectPersonal = createSelector(
  (state) => state.documents.documents,
  (docs) => (
    (docs && docs.filter(
      (doc) => doc.documentType
        && (doc.documentType.type === DocumentType.ID_CARD || doc.documentType.type === DocumentType.DRIVING_LICENCE),
    )) || []
  ),
);

export const selectIdAuthorizations = createSelector(
  (state) => state.documents.documents,
  (docs) => (
    (docs && docs.filter(
      (doc) => doc.documentType && doc.documentType.type === DocumentType.ID_CARD,
    )) || []
  ),
);

export const selectPaySlips = createSelector(
  (state) => state.documents.paySlips,
  (paySlips) => paySlips || [],
);

export const selectDocuments = createSelector(
  (state) => state.documents.documents,
  (docs) => docs || [],
);
export const selectDocumentTypes = createSelector(
  (state) => state.documents.documentTypeData,
  (documentType) => documentType || [],
);

export const {
  removeDocument: removeDocumentAction,
  setDocument: setDocumentAction,
} = documentSlice.actions;

export default documentSlice.reducer;
