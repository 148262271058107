import moment from 'moment';
import 'moment/locale/cs';

moment.locale('cs');
const CREDIT_BANK_ACCOUNT = 288487136; // TODO -> move to DB TD-1581

export const getCSVKeyLabelPairs = (columnsDefinition) =>
  columnsDefinition.map((x) => ({
    label: x.title,
    key: x.dataIndexAlias ? x.dataIndexAlias : x.dataIndex,
  }));

export const mapOrdersForCSVExport = (data) =>
  data.map((row) => ({
    day: row.day,
    date: row.date,
    from_time: row.from_time,
    to_time: row.to_time,
    name: row.name,
    orders_count: row.orders_count,
    filled_orders_count: row.filled_orders_count,
    unfilled_orders_count: row.unfilled_orders_count,
    invitation_orders_count: row.invitation_orders_count,
    branchoffice_branch: row.branchoffice_branch,
    branchoffice_department: row.branchoffice_department,
  }));

export const mapPaymentsForCEBExport = (data) => {
  const today = moment().format('DD.MM.YYYY');

  return data.map((row) => {
    const { paymentRequest } = row;
    let ceb_account = CREDIT_BANK_ACCOUNT;

    if (!paymentRequest.credit_payout) {
      const { company } = paymentRequest.paymentAuthorized;
      ceb_account = company.payment_bank_account;

      if (!ceb_account) {
        throw new Error(`Company ${company.name}:${company.id} has empty payment_bank_account`);
      }
    }

    return {
      CEB_11: 11,
      CEB_ACCOUNT: ceb_account,
      CEB_TODAY: today,
      CEB_PAYOUT: Number(row.amount).toFixed(2),
      paymentAuthorized_person_personData_bank_account:
        row.paymentAuthorized_person_personData_bank_account,
      paymentAuthorized_person_personData_bank_number:
        row.paymentAuthorized_person_personData_bank_number,
      CEB_BANK: row.payment_constant,
      CEB_COMPOUND_NUMBER: row.payment_variable,
      I: null,
      CEB_APPLICATION_ID: row.payment_specific,
      K: null,
      CEB_PAYMENT_NOTE: row.payment_note,
    };
  });
};
export const mapPaymentsForExport = (data) => data.map((row) => ({
  'ID Směny': row.id,
  'ID Tymber': row.paymentAuthorized_person_id,
  Jméno: row.paymentAuthorized_person_first_name,
  Příjmení: row.paymentAuthorized_person_last_name,
  'Číslo účtu': row.paymentAuthorized_person_personData_bank_account,
  'Kód banky': row.paymentAuthorized_person_personData_bank_number,
  Firma: row.paymentAuthorized_company_name,
  'K vyplacení': row.payment_amount,
  'Konstatní symbol': row.paymentAuthorized_person_personData_constant_variable,
  'Datumočas požadavku na vyplacení': moment(row.created_at)
    .format(
      'DD.MM.YYYY HH:mm',
    ),
  'Datumočas zpracování': row.paymentTransaction?.updated_at
    ? moment(row.paymentTransaction?.updated_at)
      .format(
        'DD.MM.YYYY HH:mm',
      )
    : '-',
}));
