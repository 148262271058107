import getResponseArray from '@tymbe/utils/getResponseArray';
import { Form, FormApi, FormState } from 'informed';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useIsFetching, useQuery, useQueryClient } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import CalendarItem from './components/CalendarItem';
import TyCalWeek from './components/CalWeek/TyCalWeek';
import CompanyBranchSelector from './components/CompanyBranchSelector';
import DaySummary from './components/DaySummary';
import DayTags from './components/DayTags';
import IconButton from './components/IconButton';
import ChevronIcon from './components/icons/ChevronIcon';
import CrossIcon from './components/icons/CrossIcon';
import ShiftCreate from './components/ShiftCreate/ShiftCreate';
import TemplateFilter from './components/TemplateFilter';
import apiClient from '../../apiClient';
import TyDatePicker from '../../components/inputs/TyDatePicker';
import Spinner from '../../components/Spinner';
import useSearchString from '../../hooks/UrlParamsHandler/useSearchParams';
import { queryKeyContains } from '../../utils/reactQueryUtils';

type UseCalendarShiftsFilters = {
  templates?: number[],
  companyId?: number,
  branchofficeId?: number,
  departmentId?: number,
};

const useCalendarShifts = (start: string, filters: UseCalendarShiftsFilters = {}) =>
  useQuery(['shifts', 'calendar', start, filters], async () => {
    const query = {
      start_time: {
        $gte: moment(start).startOf('week').toISOString(),
        $lte: moment(start).endOf('week').toISOString(),
      },
      'shift.company_id': filters.companyId,
      parent_id: filters.branchofficeId,
      branchoffice_id: filters.departmentId,
      $limit: -1,
      $modify: 'withApplicationCounts',
      $eager: '[application.attendance, perk, shiftTemplate]',
      $joinRelation: 'branchoffice',
      shift_template_id: {},
    };
    if (filters.templates?.length) {
      query.shift_template_id = { $in: filters.templates };
    }
    return apiClient.service('shift').find({ query });
  }, { staleTime: 1000 * 5, keepPreviousData: true });

const CalendarPage = () => {
  const ref = useRef<FormApi<object>>();
  const queryClient = useQueryClient();
  const now = moment().startOf('week').toISOString();
  const [searchParams, setSearchParams] = useSearchString();
  const start = searchParams.get('start') ?? now;
  const companyId = Number(searchParams.get('company_id')) || undefined;
  const branchofficeId = Number(searchParams.get('branchoffice_id')) || undefined;
  const departmentId = Number(searchParams.get('department_id')) || undefined;
  const [initialValues] = useState({ company: companyId, branchoffice: branchofficeId, department: departmentId });
  const [templates, setTemplates] = useState<number[]>([]);
  const location = useLocation();
  const [createModal, setCreateModal] = useState(false);
  const calendarIsFetching = useIsFetching({ predicate: queryKeyContains('calendar') });

  const shiftReq = useCalendarShifts(start, { templates, companyId, branchofficeId, departmentId });
  const shifts = getResponseArray(shiftReq.data).filter((s) => Number(s.orders_count));
  const { data: company } = useQuery(
    ['company', companyId],
    () => apiClient.service('company').get(companyId!),
    { enabled: !!companyId, staleTime: 1000 * 60 * 5 },
  );
  const { data: department } = useQuery(
    ['branchoffice', departmentId],
    () => apiClient.service('branchoffice').get(departmentId!),
    { enabled: !!departmentId, staleTime: 1000 * 60 * 5 },
  );

  const onCompanyBranchChange = useCallback(({ values }: FormState<any>) => {
    const newParams = new URLSearchParams(searchParams);

    if (values.company) {
      newParams.set('company_id', values.company);
    } else {
      newParams.delete('company_id');
    }
    if (values.branchoffice) {
      newParams.set('branchoffice_id', values.branchoffice);
    } else {
      newParams.delete('branchoffice_id');
    }
    if (values.department) {
      newParams.set('department_id', values.department);
    } else {
      newParams.delete('department_id');
    }

    setSearchParams(newParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const newValues = {
      company: companyId,
      branchoffice: branchofficeId,
      department: departmentId,
    };
    ref.current?.setValues(newValues);
  }, [branchofficeId, companyId, departmentId]);

  const handleNextWeek = useCallback(() => {
    const newStart = moment(start).add(1, 'week').toISOString();
    const newParams = new URLSearchParams(searchParams);
    if (newStart === now) {
      newParams.delete('start');
    } else {
      newParams.set('start', newStart);
    }
    setSearchParams(newParams);
  }, [now, searchParams, setSearchParams, start]);

  const setWeek = useCallback((date: Moment) => {
    const newStart = date.startOf('week').toISOString();
    const newParams = new URLSearchParams(searchParams);
    if (newStart === now) {
      newParams.delete('start');
    } else {
      newParams.set('start', newStart);
    }
    setSearchParams(newParams);
  }, [now, searchParams, setSearchParams]);

  const handlePrevWeek = useCallback(() => {
    const newStart = moment(start).subtract(1, 'week').toISOString();
    const newParams = new URLSearchParams(searchParams);
    if (newStart === now) {
      newParams.delete('start');
    } else {
      newParams.set('start', newStart);
    }
    setSearchParams(newParams);
  }, [now, searchParams, setSearchParams, start]);

  const getMonthTitle = (s: string, e?: string) => {
    const startDate = moment(s);
    const endDate = e ? moment(e) : startDate;
    return startDate.get('month') === endDate.get('month')
      ? startDate.format('MMMM')
      : `${startDate.format('MMM')} - ${endDate.format('MMM')}`;
  };

  return (
    <div className="bg-[#f3f5fd]">
      <Form
        onChange={onCompanyBranchChange}
        formApiRef={ref}
        className="flex gap-2 py-2"
        initialValues={initialValues}
      >
        <CompanyBranchSelector />
      </Form>

      <TemplateFilter start={start} companyId={companyId} branchofficeId={departmentId} onChange={setTemplates} />

      <div className="flex align-center mb-1">
        <h5 className="capitalize flex-1 flex gap-2">
          <Form
            onChange={({ values }) => {
              if (values.date) setWeek(values.date);
            }}
          >
            <TyDatePicker
              name="date"
              datePresets={[
                {
                  label: 'Tento týden',
                  date: moment().startOf('week').toISOString(),
                },
              ]}
              defaultValue={moment(start)}
              // there is no easy way to extract input render
              // eslint-disable-next-line react/no-unstable-nested-components
              inputRender={(props) => (
                <label className="flex align-center">
                  {getMonthTitle(
                    start,
                    moment(start).add(7, 'day').toISOString(),
                  )}
                  {moment(start).format(' YYYY')}
                  <ChevronIcon className="rotate-90" />
                  <input hidden {...props} />
                </label>
              )}
            />
          </Form>
          <Spinner className="size-4" show={!!calendarIsFetching} />
        </h5>
        <button
          className="py-1 px-3 text-sm font-semibold bg-primary-200 text-primary-800 disabled:bg-secondary-100 disabled:text-secondary-400"
          type="button"
          disabled={!company || !department}
          onClick={() => setCreateModal(!createModal)}
          title={!company || !department ? 'Vyberte firmu a oddělení' : undefined}
        >
          Vypsat směny
        </button>
      </div>
      <TyCalWeek date={start} groupPosition="bottom" className="rounded">
        <TyCalWeek.Head className="flex flex-nowrap !flex-row !px-0 gap-1 !justify-center">
          <IconButton onClick={handlePrevWeek}>
            <ChevronIcon className="-scale-x-100" />
          </IconButton>
          <IconButton onClick={handleNextWeek}>
            <ChevronIcon />
          </IconButton>
        </TyCalWeek.Head>
        <TyCalWeek.Group flat position="top">
          <TyCalWeek.Header headComp={DayTags} />
        </TyCalWeek.Group>
        <TyCalWeek.Group flat position="bottom">
          <TyCalWeek.Header headComp={DaySummary} />
        </TyCalWeek.Group>
        {shifts.map((shift) => (
          <TyCalWeek.Event date={shift.start_time} data={shift} key={shift.id}>
            <Link
              // to={`/${moment(shift.start_time).isBefore() ? 'shiftAttendace' : 'shiftDetail'}/${shift.id}`}
              to={`/shiftDetail/${shift.id}`}
              state={{ background: location }}
            >
              <CalendarItem
                data-date={shift.start_time}
                currentApplications={shift.filled_orders_count || 0}
                totalApplications={shift.orders_count || 0}
                emoji={shift.emoji || shift.shiftTemplate?.emoji}
                start={shift.start_time}
                end={shift.end_time}
                className="cursor-pointer"
                perks={shift.perk}
              />
            </Link>
          </TyCalWeek.Event>
        ))}
        {shiftReq.isLoading && (
          <TyCalWeek.Group flat noGrid>
            <div className="w-full col-span-full">Loading</div>
          </TyCalWeek.Group>
        )}
      </TyCalWeek>
      {!company || !department ? null : (
        <Modal
          isOpen={createModal}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          ariaHideApp={false}
          className="fixed top-4 right-4 bottom-4 left-4 bg-bg rounded-lg overflow-auto drop-shadow-lg z-50 flex flex-col"
          overlayClassName="bg-overlay fixed inset-0 z-modal"
          onRequestClose={() => setCreateModal(false)}
        >
          <div className="px-6 py-4 flex align-center border-b border-secondary-100">
            Vypsat směny
            <CrossIcon onClick={() => setCreateModal(false)} className="cursor-pointer ml-auto" />
          </div>
          <ShiftCreate
            company={company}
            branchoffice={department}
            onPublish={() => {
              queryClient.invalidateQueries({ predicate: queryKeyContains('calendar') });
              setCreateModal(false);
            }}
            onCancel={() => {
              setCreateModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default CalendarPage;
