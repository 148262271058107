import { Service } from '@feathersjs/feathers';
import {
  DocumentCategory,
  DocumentType,
  DocumentValidityDurationUnit,
  DocumentValidityEnd,
  DocumentValidityStart,
  Roles,
} from '@tymbe/schema/enums';
import { Card, Col, Row } from 'antd';
import { Form, FormState } from 'informed';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import feathersClient from '../../../../../apiClient';
import Protect from '../../../../../apiClient/Protect';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { SubmitButton } from '../../../../../components/buttons';
import TyCheckbox from '../../../../../components/inputs/TyCheckbox';
import { PageTitle } from '../../../../../components/texts';
import Wrapper from '../../../../../components/wrapper';
import Container from '../../../../../containers';
import { BranchofficeData, DocumentTypeData } from '../../../../../types/TymbeApi';
import DocumentTypeFormContainer, {
  categoryOptions,
  DocumentTypeFormContainerFields,
  typeOptions, validityDurationOptions, validityEndOptions,
  validityStartOptions,
}
  from '../components/DocumentTypeFormContainer';
import { createDocumentTypeWithTemplate }
  from '../utils/DocumentTypeForm.utils';

interface DocumentTypeFormParams {
  companyId: string;
  documentTypeId: string;
  [K: string]: string;
}

const documentTypeService: Service<DocumentTypeData> = feathersClient.service('document-type');

const documentTypeDetail = async (documentTypeId: number | string) =>
  documentTypeService.get(documentTypeId, { query: { $eager: 'branchoffice' } });

const DocumentTypeFormPage = () => {
  const { companyId, documentTypeId } = useParams<DocumentTypeFormParams>();
  const [formDisabled, setFormDisabled] = useState(!!documentTypeId);

  const defaultBranchofficeOption =
    {
      id: 0,
      name: 'Pro celou firmu',
    } as BranchofficeData;

  const { data: documentType } = useQuery(
    ['documentType', `documentType-${documentTypeId}`],
    async () => documentTypeDetail(Number(documentTypeId)),
    { enabled: !!documentTypeId, onSettled: () => setFormDisabled(false) },
  );

  const navigate = useNavigate();

  const createDocumentType = useCallback(async (formState: FormState<DocumentTypeFormContainerFields>) => {
    setFormDisabled(true);
    const { values } = formState;

    await createDocumentTypeWithTemplate(Number(companyId), formState, Number(documentTypeId))
      .catch((err) => {
        ErrorAlert(err.message);
      });

    setFormDisabled(false);
    SuccessAlert(!documentTypeId ? 'Dokument byl úspěšně vytvořen' : 'Dokument byl úspěšně upraven');
    return navigate(`/company/${companyId}/document-type`);
  }, [companyId, documentTypeId, navigate]);

  const getDefaultValue = <T,> (
    inputValue: T | null | undefined,
    selectOptions: { value: T, label: string }[],
    notFoundValue: { value: T, label: string } | undefined) => (
    inputValue
      ? selectOptions.find((vdo: { value: T, label: string }) => vdo.value === inputValue)
      : notFoundValue
  );

  const getType = (type: DocumentType | undefined) =>
    getDefaultValue<DocumentType>(type, typeOptions, typeOptions[0]) || typeOptions[0];

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <Form<DocumentTypeFormContainerFields>
          initialValues={{
            ...documentType,
            name: documentType?.name,
            display_name: documentType?.display_name,
            is_published: { value: !!documentType?.published_at },
            assigned_branchoffice: documentType?.branchoffice || defaultBranchofficeOption,
            editorJS: { value: true },
            type: getType(documentType?.type),
            category: getDefaultValue<DocumentCategory>(documentType?.category, categoryOptions, undefined),
            validity_start_at: getDefaultValue<DocumentValidityStart>(
              documentType?.validity_start_at,
              validityStartOptions,
              undefined,
            ),
            validity_end_at: getDefaultValue<DocumentValidityEnd>(
              documentType?.validity_end_at,
              validityEndOptions,
              undefined,
            ),
            validity_duration: documentType?.validity_duration,
            validity_duration_unit: getDefaultValue<DocumentValidityDurationUnit>(
              documentType?.validity_duration_unit,
              validityDurationOptions,
              undefined,
            ),
          }}
          name="create-document-type-form"
          className="ty-form"
          onSubmit={createDocumentType}
        >
          <PageTitle>{!documentTypeId ? 'Nový dokument' : 'Editace dokumentu'}</PageTitle>

          <Card style={{ marginTop: '18px' }}>
            <Row gutter={[10, 10]}>
              <Col xs={24} lg={10}>
                <div className="flex flex-col gap-[15px]">
                  <DocumentTypeFormContainer
                    companyId={Number(companyId)}
                  />
                  <Protect
                    auth={[
                      Roles.SUPER_ADMIN,
                      Roles.TYMBE_ADMIN,
                      Roles.ADMIN,
                    ]}
                    redirect={false}
                  >
                    <TyCheckbox
                      name="editorJS"
                      label="Chci použít editorJS"
                      defaultValue={!documentTypeId ? false : !documentType?.template_id}
                      disabled={!!documentTypeId}
                    />
                  </Protect>
                  <SubmitButton
                    textstyle={undefined}
                    disabled={formDisabled}
                    style={{ marginTop: '18px' }}
                    buttontext="Uložit"
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default DocumentTypeFormPage;
