import classNames from 'classnames';
import { HTMLAttributes, ReactNode, useState } from 'react';

import { TableCell } from './TableCell';

export type TableRowExpandableProps = HTMLAttributes<HTMLTableRowElement> & {
  cols: ReactNode[];
  cantExpand?: boolean;
  expandColSpan?: number;
};

export const TableRowExpandable = ({
  children,
  className,
  cols = [],
  cantExpand = false,
  expandColSpan,
  ...rest
}: TableRowExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <tr className={classNames({ 'hover:bg-secondary-200 cursor-pointer': !cantExpand }, className)} {...rest} onClick={() => setIsExpanded((prev) => !prev)}>
        {
          cols.map((col, index) => (
            <TableCell key={`cell${index + 1}`}>{col}</TableCell>
          ))
        }
      </tr>
      { (!cantExpand && isExpanded) && <tr><td colSpan={expandColSpan || cols.length}>{children}</td></tr> }
    </>
  );
};
