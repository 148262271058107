import React from 'react';
import { useMutation } from 'react-query';

import feathersClient from '../../../../apiClient';
import { SecondaryButton } from '../../../../components/buttons';
import { ApplicationState } from '../../../../types/TymbeApi';

export interface AcceptInvitationButtonProps {
  applicationId: number;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
  onError?: (error: unknown, variables: any, context: unknown) => void | Promise<unknown>;
}

const AcceptInvitationButton = ({ applicationId, onSuccess, onError }: AcceptInvitationButtonProps) => {
  const { mutateAsync: patchInvitation } = useMutation(
    ['application', { id: applicationId }],
    async () => {
      await feathersClient
        .service('application')
        .patch(applicationId, { state: ApplicationState.CONFIRMED });
    },
    {
      onSuccess,
      onError,
    },
  );
  return (
    <div className="p-2 text-right">
      <SecondaryButton onClick={patchInvitation}>
        Přijmout pozvánku
      </SecondaryButton>
    </div>
  );
};
export default AcceptInvitationButton;
