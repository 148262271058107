import ShiftState from './ShiftStateEnum';
import { ApplicationState, ApplicationStateDisplay, AttendanceResolutionDisplay } from '../../../../utils/enums';

const shiftStyleSettings = {
  [ShiftState.PAYMENT_TRANSACTION]: {
    textColour: 'text-primary-500',
    borderColour: 'border-primary-500',
    text: 'Výplata zpracována',
  },
  [ShiftState.PAYMENT_REQUEST]: {
    textColour: 'text-danger-400',
    borderColour: 'border-danger-400',
    text: 'Žádost o výplatu odeslána',
  },
  [ShiftState.ATTENDANCE_OK]: {
    textColour: 'text-primary-600',
    borderColour: 'border-primary-600',
    text: AttendanceResolutionDisplay.ok,
  },
  [ShiftState.ATTENDANCE_CANCEL]: {
    textColour: 'text-error-600',
    borderColour: 'border-error-600',
    text: AttendanceResolutionDisplay.cancel,
  },
  [ShiftState.ATTENDANCE_BREACH_OF_DISCIPLINE]: {
    textColour: 'text-error500',
    borderColour: 'border-error-500',
    text: AttendanceResolutionDisplay.breach_of_discipline,
  },
  [ShiftState.ATTENDANCE_ADMIN_EXCUSED_ABSENCE]: {
    textColour: 'text-error-600',
    borderColour: 'border-error-600',
    text: AttendanceResolutionDisplay.admin_excused_absence,
  },
  [ShiftState.ATTENDANCE_EXCUSED_ABSENCE]: {
    textColour: 'text-error-600',
    borderColour: 'border-error-600',
    text: AttendanceResolutionDisplay.excused_absence,
  },
  [ShiftState.ATTENDANCE_UNEXCUSED_ABSENCE]: {
    textColour: 'text-error-500',
    borderColour: 'border-error-500',
    text: AttendanceResolutionDisplay.unexcused_absence,
  },
  [ShiftState.SHIFT_HAPPENED]: {
    textColour: 'text-secondary-600',
    borderColour: 'border-secondary-600',
    text: 'Docházka nepotvrzena',
  },
  [ShiftState.APPLICATION_CONFIRMED]: {
    textColour: 'text-primary-400',
    borderColour: 'border-primary-400',
    text: ApplicationStateDisplay[ApplicationState.CONFIRMED],
  },
  [ShiftState.APPLICATION_REJECTED]: {
    textColour: 'text-error-800',
    borderColour: 'border-error-800',
    text: ApplicationStateDisplay[ApplicationState.REJECTED],
  },
  [ShiftState.APPLICATION_CANCELED_EARLY]: {
    textColour: 'text-error-600',
    borderColour: 'border-error-600',
    text: ApplicationStateDisplay[ApplicationState.CANCELED_EARLY],
  },
  [ShiftState.APPLICATION_CANCELED_LATE]: {
    textColour: 'text-error-500',
    borderColour: 'border-error-500',
    text: ApplicationStateDisplay[ApplicationState.CANCELED_LATE],
  },
  [ShiftState.APPLICATION_SYSTEM_CANCELED]: {
    textColour: 'text-error-600',
    borderColour: 'border-error-600',
    text: ApplicationStateDisplay[ApplicationState.SYSTEM_CANCELED],
  },
  [ShiftState.INVITATION]: {
    textColour: 'text-danger-400',
    borderColour: 'border-danger-400',
    text: 'Pozvánka na směnu odeslána',
  },
  [ShiftState.DEFAULT]: {
    textColour: 'text-fg-300',
    borderColour: 'border-fg-300',
    text: 'Něco se nepovedlo',
  },
};
export default shiftStyleSettings;
