import { Descriptions, Row, Col } from 'antd';

import { SubmitButton } from '../../components/buttons';
import { FixedGrid, ResponsiveGrid } from '../../components/grids';
import Illustration from '../../components/illustration';
import { PayInfoChangeIllustration } from '../../components/illustrations';
import { SimpleFormItem } from '../../components/inputs';
import { SimpleHeading1Text, SimpleHeading3Text, SmallText } from '../../components/texts';

const PaymentInfoChangeContainer = () => (
  <div>
    <ResponsiveGrid>
      <FixedGrid>
        <SimpleHeading1Text> Chceš změnit platební údaje?</SimpleHeading1Text>
      </FixedGrid>
    </ResponsiveGrid>
    <Illustration svg={<PayInfoChangeIllustration />} />
    <ResponsiveGrid>
      <SimpleHeading3Text>
        Pro potvrzení změny platebních údajů klikni na tlačítko níže
      </SimpleHeading3Text>
      <SmallText>
        Proč chceme údaje potvrdit? Záleží nám na tom, aby výplata vždycky včas dorazila
        na správný účet. Ten tvůj :)
      </SmallText>
      <div
        style={{
          padding: '10px 20px',
          background: 'white',
          borderRadius: '10px',
        }}
      >
        <Descriptions title="Nové platební údaje:">
          <Descriptions.Item label="Číslo účtu:">123</Descriptions.Item>
          <Descriptions.Item label="Kod banky:">1810000000</Descriptions.Item>
        </Descriptions>
      </div>

      <SimpleFormItem>
        <Row gutter={[16, 24]} justify="space-around" align="middle">
          <Col style={{ textAlign: 'center' }} xs={24} sm={24} md={8} lg={6} xl={6}>
            <SubmitButton block buttontext="Potvrdit platební údaje" />
          </Col>
        </Row>
      </SimpleFormItem>
      <div
        style={{
          padding: '10px 20px',
          border: '1px solid #DBDFEE',
          borderRadius: '10px',
        }}
      >
        <SmallText>
          S touhle registrací nemáš nic společného? Dej nám prosím vědět na
          info@tymbe.com
        </SmallText>
      </div>
    </ResponsiveGrid>
  </div>
);

export default PaymentInfoChangeContainer;
