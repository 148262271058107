import { useNavigate, useParams } from 'react-router-dom';

import ShiftDetailExtended from './components/ShiftDetailExtended';

const ShiftDetailPage = () => {
  const { shiftId } = useParams();
  const navigate = useNavigate();

  const onShiftRemoved = () => {
    navigate(-1);
  }

  if (!shiftId) return null;

  return <ShiftDetailExtended shiftId={Number(shiftId)} onShiftRemoved={onShiftRemoved} />;
};

export default ShiftDetailPage;
