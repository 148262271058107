import { useCallback, useState } from 'react';

import { NotificationContextProvider, NotificationProps, NotificationOptions } from './NotificationsContext';

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const removeNtf = useCallback(
    (id: number) => {
      setNotifications((ntf) => ntf.filter((n) => n.id !== id));
    },
    [],
  );

  const addNtf = useCallback(
    (message: string, options?: NotificationOptions) => {
      // Generate unique ID for notification
      setNotifications((ntf) => {
        let id = 0;
        if (notifications.length !== 0) {
          id = notifications[notifications.length - 1].id + 1;
        }
        return [...ntf, { id, message, options }];
      });
    },
    [notifications],
  );

  return (
    <NotificationContextProvider value={{ notifications, removeNotification: removeNtf, createNotification: addNtf }}>
      {children}
    </NotificationContextProvider>
  );
};
export default NotificationProvider;
