import { Paginated } from '@feathersjs/feathers';

export default <T extends object>(response?: T | T[] | Paginated<T>): T[] =>
  response
    ? Array.isArray(response)
      ? response
      : ('data' in response)
        ? response.data
        : [response]
    : [];
