import { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

type URLSearchParamsInit = string | URLSearchParams | Record<string, string> | undefined;

let lastUpdate = Date.now();
const useSearchString = (initialValue?: URLSearchParamsInit) => {
  const [searchString, setSearchString] = useSearchParams(initialValue);
  const location = useLocation();

  const dedoopedSetSearchString = useCallback((value: URLSearchParamsInit) => {
    const newSearchString = new URLSearchParams(value);
    const currentSearchString = window.location.search.slice(1);

    if (currentSearchString === newSearchString.toString() || location.state?.isBackground) {
      return;
    }

    setSearchString(value, { replace: Date.now() < (lastUpdate + 1000) });
    lastUpdate = Date.now();
  }, [location, setSearchString]);

  useEffect(() => {
    lastUpdate = Date.now();
  }, []);

  return [searchString, dedoopedSetSearchString] as const;
};

export default useSearchString;
