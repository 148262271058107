import { MpsvReportStatus } from '@tymbe/schema/enums';
import classNames from 'classnames';
import { Form, FormState } from 'informed';
import { ComponentProps, ReactNode, useState } from 'react';
import Modal from 'react-modal';

import Button from '../../../../components/buttons/Button';
import { TySelect } from '../../../../components/inputs/TySelect';
import TyTextArea from '../../../../components/inputs/TyTextArea';
import styles from '../../../calendar/dev/shift-detail/Modal.module.css';

type StateModalData = {
  status: {
    value: MpsvReportStatus,
    label: string
  };
  changeNote?: string,
};
type ChangeStatusModalOwnProps = {
  icon: ReactNode,
  body?: ReactNode,
  onSubmit: (values: StateModalData) => Promise<void>,
  status: MpsvReportStatus,
};

type ChangeStatusModalProps = ChangeStatusModalOwnProps & Omit<ComponentProps<'button'>, keyof ChangeStatusModalOwnProps>;

const ChangeStatusModal = ({ icon, onSubmit, body, status, ...rest }: ChangeStatusModalProps) => {
  const [openModal, setOpenModal] = useState(false);

  let statusOptions: { label: MpsvReportStatus, value: MpsvReportStatus }[] = [];
  switch (status) {
    case MpsvReportStatus.OK:
      statusOptions = [
        {
          label: MpsvReportStatus.CHANGE,
          value: MpsvReportStatus.CHANGE,
        },
        {
          label: MpsvReportStatus.STORNO,
          value: MpsvReportStatus.STORNO,
        },
      ];
      break;
    case MpsvReportStatus.CHANGE:
    case MpsvReportStatus.ERROR:
      statusOptions = [
        {
          label: MpsvReportStatus.STORNO,
          value: MpsvReportStatus.STORNO,
        },
      ];
      break;
    default:
      statusOptions = [];
      break;
  }

  return (
    // Used to stop the row in mpsv table from expanding when any part of the modal is clicked
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()} className="min-w-fit">
      <Button
        title="Změnit status reportu"
        onClick={() => {
          setOpenModal(true);
        }}
        className="enabled:hover:bg-secondary-400 enabled:hover:text-bg bg-secondary-100 min-w-fit disabled:cursor-not-allowed disabled:opacity-70"
        {...rest}
      > {icon}
      </Button>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        shouldCloseOnOverlayClick
        className={classNames('bg-bg m-auto rounded-xl px-6 py-4 w-[1000px]')}
        overlayClassName={styles.overlay}
        ariaHideApp={false}
      >
        <Form
          onSubmit={(values: FormState<StateModalData>) => {
            onSubmit(values.values);
            setOpenModal(false);
          }}
        >
          {({ formState }: { formState: FormState<StateModalData> }) => (
            <div>
              <h2>Změnit status reportu </h2>
              <TySelect
                name="status"
                className=""
                label="Status"
                options={statusOptions}
                required="Vyberte prosím status"
              />

              {!!formState.values.status && formState.values.status.value === MpsvReportStatus.CHANGE && (
                <div>
                  <h3>Poznámka</h3>
                  <TyTextArea name="changeNote" className="w-full" />
                </div>
              )}
              <div className="flex justify-end items-center mt-8 gap-2">
                <Button
                  type="button"
                  className="w-12 flex justify-center hover:bg-error-300"
                  onClick={() => setOpenModal(false)}
                >Zavřít
                </Button>
                <Button
                  type="submit"
                  className="w-12 flex justify-center enabled:hover:bg-primary-300 disabled:cursor-not-allowed"
                  title={
                    ((!!formState.values.status && formState.values.status.value === MpsvReportStatus.CHANGE)
                    && !formState.values.changeNote) ? 'Vyplňte poznámku' : 'Uložit změny'
                  }
                  disabled={
                    ((!!formState.values.status && formState.values.status.value === MpsvReportStatus.CHANGE)
                    && !formState.values.changeNote) || !formState.values.status
                  }
                >Uložit
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ChangeStatusModal;
