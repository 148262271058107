import { Tag, Button } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { black, darkBlue, primaryColor } from '../../utils/colors';
import { CloseIcon } from '../icons';

export const SimpleHeadingText = ({ children }) => (
  <h2 className="ty-h2" style={{ textAlign: 'center' }}>
    {children}
  </h2>
);
SimpleHeadingText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]).isRequired,
};

export const SimpleHeading1Text = ({ children = null, align = 'center' }) => (
  <h1 className="ty-h1" style={{ textAlign: align }}>
    {children}
  </h1>
);
SimpleHeading1Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]),
  align: PropTypes.string,
};

export const SimpleHeading2Text = ({ children = null, align = 'center' }) => (
  <h2 className="ty-h2" style={{ textAlign: align }}>
    {children}
  </h2>
);
SimpleHeading2Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]),
  align: PropTypes.string,
};

export const SimpleHeading3Text = ({ children = null, align = 'center' }) => (
  <h3 className="ty-h3" style={{ textAlign: align }}>
    {children}
  </h3>
);
SimpleHeading3Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]),
  align: PropTypes.string,
};

export const SimpleHeading4Text = ({ children = null, align = 'center' }) => (
  <h4 className="ty-h4" style={{ textAlign: align }}>
    {children}
  </h4>
);
SimpleHeading4Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]),
  align: PropTypes.string,
};

export const StrongText = ({ children }) => <b>{children}</b>;
StrongText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]).isRequired,
};

export const SmallText = ({ children, textstyle = undefined }) => (
  <div className="ty-small-text" style={textstyle}>
    {children}
  </div>
);
SmallText.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  textstyle: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),

    PropTypes.element,
  ]).isRequired,
};

export const SimpleText = ({ children }) => <p style={{ textAlign: 'center' }}>{children}</p>;
SimpleText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export const PageTitle = ({ textAlign = undefined, children }) => (
  <h1
    className="ty-pagetitle"
    style={{
      textAlign,
      fontFamily: 'Coolvetica',
      fontSize: '28px',
      lineHeight: '34px',
      marginTop: '35px',
      fontWeight: 'bold',
      color: darkBlue,
      marginBottom: '10px',
      letterSpacing: '1px',
    }}
  >
    {children}
  </h1>
);
PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.element,
  ]).isRequired,
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
};

export const PageSubTitle = ({ children, textAlign = undefined }) => (
  <p
    style={{
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: '500',
      color: darkBlue,
      marginBottom: '18px',
      textAlign,
    }}
  >
    {children}
  </p>
);
PageSubTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
};

export const LinkText = ({ style = { color: primaryColor }, linkTo, linkText }) => (
  <Link
    style={style}
    to={linkTo}
    variant="body2"
  >
    <b> {linkText}</b>
  </Link>
);
LinkText.propTypes = {
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

/** @type React.FC<PropTypes.InferType<TagText.propTypes>> */
export const TagText = ({
  statusColor,
  statusText,
  icon = undefined,
  color = black,
  tyClosable = false,
  tyOnClose = undefined,
  ...props
}) => (
  <Tag
    style={{
      display: 'flex',
      alignItems: 'center',
      borderRadius: '30px',

      padding: '5px 8px',
    }}
    color={statusColor}
    {...props}
  >
    {!icon ? null : <div style={{ marginRight: '7px' }}> {icon} </div>}
    <small
      style={{
        fontFamily: 'Poppins',
        fontSize: '12px',
        lineHeight: '12px',
        fontWeight: '500',
        letterSpacing: '-0.2px',
        color,
      }}
    >
      {statusText}
    </small>
    {!tyClosable ? null : (
      <Button
        className="ty-close-icon"
        shape="circle"
        role="button"
        size="small"
        ghost
        icon={<CloseIcon iconcolor="#28367B" />}
        onClick={tyOnClose}
        style={{ marginLeft: '7px' }}
      />
    )}
  </Tag>
);
TagText.propTypes = {
  statusText: PropTypes.string.isRequired,
  statusColor: PropTypes.string.isRequired,
  icon: PropTypes.node,
  color: PropTypes.string,
  tyClosable: PropTypes.bool,
  tyOnClose: PropTypes.func,
};
