import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import { useMutation } from 'react-query';

import TableColumnWithControls from './TableColumnWithControls';
import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';

interface OrdersCountColumnProps {
  showControls: boolean,
  data: ShiftStateData,
  onClick: () => void,
}

const OrdersCountColumn = ({
  showControls,
  data,
  onClick,
}: OrdersCountColumnProps) => {
  const { mutateAsync: removeManShift } = useMutation(
    ['remove-manShift'],
    async (manShiftId: number) =>
      feathersClient.service('man-shift').remove(manShiftId),
    {
      onError: () => {
        ErrorAlert('Nepodařilo se odebrat volné místo ze směny');
      },
      onSuccess: () => {
        SuccessAlert('Volné místo bylo odebráno');
      },
    },
  );

  const { mutateAsync: addOpenManShift } = useMutation(
    ['create-open-manShift'],
    async (shift_id: number) => feathersClient.service('man-shift').create({ shift_id }),
    {
      onError: () => {
        ErrorAlert('Nepodařilo se přidat místo ke směně');
      },
      onSuccess: () => {
        SuccessAlert('Do objednávky bylo přidáno nové místo');
      },
    },
  );

  const onPlusClick = async ({ id }: ShiftStateData) => {
    await addOpenManShift(id);
    onClick();
  };

  const onMinusClick = async ({ manShift }: ShiftStateData) => {
    const manShiftToRemove = manShift?.find((ms) => ms.application_id === null && ms.deleted_at === null);
    if (!manShiftToRemove) {
      ErrorAlert('Z této objednávky již nelze odebrat místa');
      return;
    }
    await removeManShift(manShiftToRemove.id);
    onClick();
  };

  return (
    <TableColumnWithControls
      showControls={showControls}
      onPlusClick={() => onPlusClick(data)}
      onMinusClick={() => onMinusClick(data)}
    >
      {data.orders_count}
    </TableColumnWithControls>
  );
};

export default OrdersCountColumn;
