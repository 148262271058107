import { GroupBase } from 'react-select';

import { RolesData } from '../../../types/TymbeApi';

import { InternalProps, Option } from './TySelectRequirements.types';
import {
  getRequirementLabel,
  getRequirementValue,
  loadRequirementOptions,
  reduceRequirementOptions,
} from './TySelectRequirements.utils';
import TyAsyncSelect from '../TyAsyncSelect';

export type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';
export type OwnProps = {
  companyId?: number | string;
  userRole?: RolesData[];
};
export type TySelectRequirementsProps<Fields extends object> = Omit<InternalProps<Fields>, ProvidedProps> & OwnProps;

const TySelectRequirements = <Fields extends object>({
  companyId = undefined,
  debounceTimeout = 1000,
  userRole = [],
  ...rest
}: TySelectRequirementsProps<Fields>) => (
  <TyAsyncSelect<Fields, Option, GroupBase<Option>, void, boolean>
    {...rest}
    loadOptions={loadRequirementOptions(companyId, userRole)}
    debounceTimeout={debounceTimeout}
    getOptionLabel={(option) => getRequirementLabel(option, companyId)}
    getOptionValue={getRequirementValue}
    reduceOptions={reduceRequirementOptions}
  />
);

export default TySelectRequirements as typeof TySelectRequirements;
