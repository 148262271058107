import { PaymentStatusData } from '@tymbe/schema/payment-status.interface';
import { SecondaryButton } from '../../../../../components/buttons';

type PaymentStatusButtonProps = {
  payoutStatusData: PaymentStatusData[];
  onClick: () => void;
};

const PayoutStatusButton = ({
  onClick,
  payoutStatusData: payoutStatusData,
}: PaymentStatusButtonProps) => {
  const payoutStatusSwitch = (initialData: PaymentStatusData[]) => {
    if (!initialData || !initialData[0]?.value) return "";
  
    const value = initialData[0].value;
    const unit = value == '1' ? 'den' : 'dní';
    return `(${value} ${unit})`;
  };

  return (
    <SecondaryButton
      style={{ marginRight: '18px' }}
      onClick={onClick}
      size="large"
      buttontext={(
        <div className="flex items-center">
          Výplatní termíny
          <span className="relative flex pl-1 font-bold">
            {payoutStatusSwitch(payoutStatusData)}
          </span>
        </div>
      )}
    />
  );
};

export default PayoutStatusButton;
