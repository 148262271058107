import React, { useEffect, useState } from 'react';

import { TableCell } from './TableCell';
import useURLParamsHandler from '../../hooks/UrlParamsHandler/useURLParamsHandler';
import { darkBlueIcon, primaryColor } from '../../utils/colors';
import { IconButton } from '../buttons';
import { SortDownIcon, SortIcon, SortUpIcon } from '../icons';

export type TableSortHeaderProps = {
  children: React.ReactNode,
  field?: string,
  activeField: string,
  onSort: (field: string, activeSortOrder: number) => void,
};

export const TableSortHeader = ({ children, field = undefined, activeField, onSort }: TableSortHeaderProps) => {
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();
  const [sortOrder, setSortOrder] = useState(field === activeField ? -1 : 0);
  const { sort } = allSearchParams;

  useEffect(() => {
    if (field !== activeField && sortOrder !== 0) {
      setSortOrder(0);
    }
  }, [activeField, field, sortOrder]);

  const changeSort = () => {
    if (!field) return;

    let newSort;
    if (sortOrder === 1) {
      newSort = -1;
    } else {
      newSort = 1;
    }

    setSortOrder(newSort);
    onSort(field, newSort);
    setUrlParamsHandler({ sort: { [field]: newSort } });
  };

  useEffect(() => {
    if (sort) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(sort)) {
        setSortOrder(value);
        onSort(key, value);
      }
    }
    // execute useEffect only on load
  }, []);

  const getIcon = (order: number) => {
    switch (order) {
      case 1:
        return <SortUpIcon iconcolor={primaryColor} />;
      case -1:
        return <SortDownIcon iconcolor={primaryColor} />;
      case 0:
      default:
        return <SortIcon iconcolor={darkBlueIcon} />;
    }
  };

  return (
    <TableCell head>
      { field ? (
        <IconButton
          onClick={changeSort}
          className="p-0 w-max"
        >
          <>
            {children}
            {getIcon(sortOrder)}
          </>
        </IconButton>
        // fragment is needed so that {children} is interpreted correctly
        // eslint-disable-next-line react/jsx-no-useless-fragment
      ) : <>{children}</>}
    </TableCell>
  );
};
