import { CountryCodes, validCzechCountries } from '@tymbe/schema/enums';

import { isValidCzechZipCode, isValidGeneralZipCode } from '../../../utils/validations';

const validateZipCode = (zip: string, country: CountryCodes) => {
  // no error when zip is valid or empty
  const validationFunction = validCzechCountries.includes(country) ? isValidCzechZipCode : isValidGeneralZipCode;
  if (!zip || validationFunction(zip)) return undefined;

  return 'Zadejte platné směrovací číslo';
};
export default validateZipCode;
