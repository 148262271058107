import { FieldProps, InputProps, useField } from 'informed';
import React from 'react';

import TyInputError from './TyInputError';
import { getTextWidth } from '../../utils';
import { MinusOutlinedIcon, PlusOutlinedIcon } from '../icons';

import './TyInput.css';

type Opt = {
  min?: number | string;
  max?: number | string;
};
const getMinMax = (value: number | string, opt: Opt) => {
  const min = opt.min !== undefined ? Number(opt.min) : opt.min;
  const max = opt.max !== undefined ? Number(opt.max) : opt.max;
  let ret = value;
  if (Number.isNaN(+ret)) ret = min || 0;
  if (min !== undefined && min > Number(value)) ret = min;
  if (max !== undefined && max < Number(value)) ret = max;
  return ret;
};

export type TyInputProps = {
  showControls?: boolean;
  suffix?: string;
};

const TyInput = <Fields extends object, T>(
  { showControls, suffix, type = 'text', ...props }: FieldProps<InputProps & TyInputProps, T, Fields>,
) => {
  const {
    render,
    fieldState,
    userProps,
    fieldApi,
    ref,
  } = useField<InputProps, number | string>({ ...props, type });
  const { id, label, className, placeholder = ' ', disabled = false, hidden, ...rest } = userProps;
  const { error, showError, value, maskedValue, focused } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  const stepUp = () => {
    setValue(getMinMax(
      Number(value || 0) + Number(rest.step || 1),
      { min: rest.min, max: rest.max },
    ));
    setTouched(true);
  };

  const stepDown = () => {
    setValue(getMinMax(
      Number(value || 0) - Number(rest.step || 1),
      { min: rest.min, max: rest.max },
    ));
    setTouched(true);
  };

  const onInternalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let final = e.target.value;
    if (type === 'number' && (e.target.value !== '')) {
      final = getMinMax(e.target.value, { min: rest.min, max: rest.max }) as string;
    }

    return setValue(final, e);
  };

  const focusedClassName = focused || value || value === 0 ? 'ty_input_field__focused' : '';
  const disabledClassName = disabled && 'ty_input_field--is-disabled';

  return render(
    <div
      className={`ty_input_field ${showError && error ? 'ty_input_error' : ''} ${focusedClassName} ${className ?? ''} ${disabledClassName} ${hidden ? '!hidden' : ''}`}
    >
      {
        suffix
          ? (
            <div
              className="ty_input_field__suffix"
              style={{ left: suffix && getTextWidth(value, ref.current) }}
            >
              {suffix}
            </div>
          )
          : null
      }
      <div className={`ty_input ${showControls ? 'ty_input_with_controls' : ''}`}>
        <input
          ref={ref}
          id={id}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
          value={!maskedValue && maskedValue !== 0 ? '' : maskedValue}
          onChange={onInternalChange}
          onBlur={(e) => { setTouched(true, e); setFocused(false, e); }}
          onFocus={(e) => { setFocused(true, e); }}
          aria-invalid={showError}
          aria-describedby={`${id}-error`}
        />
      </div>
      {rest.type === 'number' && showControls && (
        <div className="ty_input_controls">
          <button
            aria-label="step down button"
            id="step_down_button"
            type="button"
            onClick={stepDown}
            className="ty-bg-dark-blue"
          >
            <MinusOutlinedIcon />
          </button>
          <button
            aria-label="step up button"
            id="step_up_button"
            type="button"
            onClick={stepUp}
            className="ty-bg-dark-blue"
          >
            <PlusOutlinedIcon />
          </button>
        </div>
      )}
      {label ? <label htmlFor={id}>{label}</label> : null}
      <TyInputError
        fieldState={fieldState}
        required={props.required}
        validate={props.validate}
      />
    </div>,
  );
};

export default TyInput;
