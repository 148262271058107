import moment from 'moment';
import useCollapse from 'react-collapsed';

import DocumentList from './DocumentList';
import EditDocument from './EditDocument';
import { PrimaryButton } from '../../../components/buttons';
import { PersonDocumentData } from '../../../types/TymbeApi';

export interface DocumentInfoProps {
  document: PersonDocumentData;
}

const DocumentInfo = ({ document }: DocumentInfoProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false });

  return (
    <div className="border-secondary-200 border rounded-lg">
      <div className="flex justify-between p-4">
        <div className="flex gap-5">
          <div className="w-40 shrink-0">
            <div className="text-secondary pb-1 font-semibold text-sm leading-5">
              {document.documentType?.name}
            </div>
            <div className="text-secondary pb-1 font-semibold text-sm leading-5">
              {document.company?.name}
            </div>
          </div>
          <div>
            <div className="ml-10 pb-1 text-sm max-h-36 overflow-y-auto">
              {document.valid_from
                ? `
                    Platný od ${moment(document.valid_from).format('DD.MM.YYYY')} do ${moment(document.valid_to).format('DD.MM.YYYY')}
                    ${(document.contract_valid_to && document.valid_to !== document.contract_valid_to) ? `(původně do ${moment(document.contract_valid_to).format('DD.MM.YYYY')})` : ''}
                  `
                : 'Platnost nevyplněna'}
            </div>
            <div className="ml-10 pb-1 text-sm max-h-36 overflow-y-auto">
              {document.did ? `Číslo dokumentu ${document.did}`
                : 'Číslo dokumentu nevyplněno'}
            </div>
            <div className="ml-10 pb-1 text-sm max-h-36 overflow-y-auto">
              {document.approved === null && 'Zatím neschváleno'}
              {document.approved === false && 'Zamítnuto'}
              {document.approved === true && `Schváleno ${moment(document.reviewed_at).format('DD.MM.YYYY')}`}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <EditDocument id={document.id} />
          <PrimaryButton {...getToggleProps()}>
            <span>
              {isExpanded ? 'Skrýt soubory' : 'Zobrazit soubory'}
            </span>
          </PrimaryButton>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <DocumentList documentId={document.id} />
      </div>

    </div>
  );
};
export default DocumentInfo;
