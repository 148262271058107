import { Paginated } from '@feathersjs/feathers';
import Card from 'antd/lib/card/Card';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import AdminTable from '../../../components/AdminTable';
import { SecondaryButton } from '../../../components/buttons';
import { EditIcon, PlusOutlinedIcon, TrashIcon } from '../../../components/icons';
import AlertModal from '../../../components/modals/AlertModal';
import useNotification from '../../../hooks/Notifications/Notification.hook';
import { PersonBanData } from '../../../types/TymbeApi';
import { DEFAULT_SORT } from '../../../utils/constants';

const UserBanList = () => {
  const { id: personId } = useParams();

  const [paginationPageSize, setPaginationPageSize] = useState<number>(10);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(1);
  const [sort, setSort] = useState<Record<string, number>>(DEFAULT_SORT);

  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<PersonBanData>();

  const notifications = useNotification();

  const onPaginationChange = (newPage: number, newPageSize: number) => {
    setPaginationPageSize(newPageSize);
    setPaginationCurrentPage(newPage);
  };

  const { data, refetch } = useQuery(
    ['FetchUserBans'],
    async (): Promise<Paginated<PersonBanData>> => feathersClient.service('person-ban').find({
      query: {
        $eager: '[creator, person]',
        person_id: personId,
        $skip: paginationPageSize * (paginationCurrentPage - 1),
        $limit: paginationPageSize,
        $sort: sort,
      },
    }),
  );

  const queryClient = useQueryClient();

  const { mutateAsync: removePersonBan } = useMutation(
    (personBanId: number): Promise<unknown> =>
      feathersClient.service('person-ban').remove(personBanId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('FetchUserBans');
      },
    },
  );

  const { mutateAsync: revertPersonBan } = useMutation(
    (personBanId: number): Promise<unknown> =>
      feathersClient.service('person-ban').patch(personBanId, { deleted_at: null }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('FetchUserBans');
      },
    },
  );

  const cols = [
    {
      title: 'Důvod',
      dataIndex: 'note',
      align: 'left',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Začátek trvání',
      dataIndex: 'start_time',
      render: (_:string, record: PersonBanData) =>
        moment(record.start_time).format('DD.MM.YYYY'),
      align: 'left',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Konec trvání',
      dataIndex: 'end_times',
      align: 'left',
      render: (_:string, record: PersonBanData) =>
        (!record?.end_time ? null : moment(record?.end_time).format('DD.MM.YYYY')),
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Autor',
      dataIndex: 'creator',
      align: 'left',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      className: 'd-flex justify-content-between align-center',
      render: (_: string, record: PersonBanData) => (
        <>
          <span className="flex flex-grow">
            {record.creator?.first_name} {record.creator?.last_name}
          </span>
          <Link
            className="ty-row-on-hover-button
              float-right
              w-[35px]
              h-[35px]
              mr-2
              border
              bg-primary
              border-primary
              hover:border-primary-300
              hover:bg-primary-300"
            to={{
              pathname: `/user/${record.person_id}/edit-ban/${record.id}`,
            }}
          > <EditIcon />
          </Link>
          <button
            type="button"
            className="ty-row-on-hover-button float-right w-[35px] h-[35px] border hover:border-error-300 border-error"
            onClick={() => {
              setSelectedRecord(record);
              setShowModal(true);
            }}
          >
            <TrashIcon />
          </button>
        </>
      ),
    },
  ];

  const onTableChange = (_pagination: unknown, _filters: unknown, sorter: SorterResult<PersonBanData>) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'note':
          setSort({ note: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'start_time':
          setSort({ start_time: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'end_time':
          setSort({ end_time: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'creator':
          setSort({ created_by: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(DEFAULT_SORT);
          break;
      }
    } else {
      setSort(DEFAULT_SORT);
    }
  };

  useEffect(() => {
    refetch();
  }, [paginationPageSize, paginationCurrentPage, sort, refetch]);

  const onRemovePersonBan = async () => {
    if (!selectedRecord?.id) return;
    setShowModal(false);

    await removePersonBan(selectedRecord.id);
    notifications.push(
      `Smazali jste ban: ${selectedRecord.note}`,
      { buttonText: 'Navrátit ban', onClick: () => revertPersonBan(selectedRecord.id) },
    );
  };

  return (
    <Card>
      <div className="flex flex-col gap-2 px-6">
        <Link className="self-end" to={`/user/${personId}/create-ban`}>
          <SecondaryButton className="flex flex-row justify-center align-center gap-2">
            <PlusOutlinedIcon />
            <span> Nový ban </span>
          </SecondaryButton>
        </Link>
        <AdminTable
          rowKey={(record: PersonBanData) => record.id}
          data={data || {}}
          rowClassName="ty-row-hover"
          columns={cols}
          onChange={onTableChange}
          onPaginationChange={onPaginationChange}
        />
        <AlertModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          title="Pokoušíte se odebrat ban"
          message={`Opravdu chcete odbanovat uživatele: ${selectedRecord?.person?.first_name} ${selectedRecord?.person?.last_name}`}
          onYes={onRemovePersonBan}
        />
      </div>
    </Card>
  );
};

export default UserBanList;
