import Modal from 'react-modal';

export interface ModalShellProps {
  showModal: boolean;
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}

const ModalShell = ({
  showModal,
  onClose = () => {},
  children = '',
  className = '',
}: ModalShellProps) => (
  <Modal
    isOpen={showModal}
    onRequestClose={onClose}
    ariaHideApp={false}
    contentLabel="My dialog"
    className={`fixed w-[450px] top-[30vh] left-[40vw] bg-bg p-7 translate-1/2 rounded-lg ${className}`}
    overlayClassName="ty-uploaded-image-modal-overlay"
  >
    {children}
  </Modal>
);

export default ModalShell;
