import { Paginated, Query } from '@feathersjs/feathers';
import { Blocks, CompanyProp } from '@tymbe/schema/company-blocked-user.interface';
import { getBlocks, isBlocked } from '@tymbe/utils/company-blocked-user';
import moment from 'moment';
import { useCallback } from 'react';
import { GroupBase, OptionsOrGroups } from 'react-select';

import TyAsyncSelect, { isDefaultSelectOption, TyAsyncSelectProps } from './TyAsyncSelect';
import feathersClient from '../../apiClient';
import { PersonData } from '../../types/TymbeApi';
import { ErrorAlert } from '../alerts';

export const validatePerson = (value: PersonData | PersonData[]) => {
  if (!value) return undefined;
  if (Array.isArray(value)) return 'Více než jednoho uživatele není možné nastavit';
  if (!value.first_name || !value.last_name) return 'Uživatel musí mít křestní jméno a příjmení';
  return undefined;
};

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';
type OwnProps = {
  filter?: { role: string },
  exclude?: { personIds: number[] },
  onlyInvitable?: { shiftId: number },
  companyId?: number;
  parentId?: number | string;
  limitToRelatedCompanies?: boolean;
  company?: CompanyProp;
  shiftDate?: string | string[];
};
type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, PersonData, GroupBase<PersonData>, void, boolean>;
type TySelectPersonProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps> & OwnProps;

const loadPersonOptions = (
  defaultOptionsCount?: number,
  filter?: { role: string },
  exclude?: { personIds: number[] },
  limitToRelatedCompanies?: boolean,
  onlyInvitable?: { shiftId: number },
  companyId?: number,
) => async (
  search: string,
  options: OptionsOrGroups<PersonData, GroupBase<PersonData>>,
) => {
  let query: Query = {
    $skip: options.length - (defaultOptionsCount || 0),
    $eager: '[blockedCompany]',
  };

  if (filter?.role) {
    query.$modify = {
      ...query.$modify,
      onlyRole: [filter.role],
    };
  }

  if (onlyInvitable?.shiftId) {
    query.$modify = {
      ...query.$modify,
      onlyInvitable: [onlyInvitable.shiftId],
    };
  }

  if (exclude?.personIds) {
    query.$and = [
      {
        'person.id': { $nin: exclude.personIds },
      },
    ];
  }

  if (limitToRelatedCompanies === false) {
    query.$limitToRelatedCompanies = false;
  } else {
    query.$limitToRelatedCompanies = true;
  }

  if (search !== '' && !Number.isNaN(Number(search))) {
    query = {
      ...query,
      'person.id': Number(search),
    };
  } else {
    query.$modify = {
      ...query.$modify,
      searchName: [search],
    };
  }

  if (companyId) {
    query = {
      ...query,
      $joinRelation: '[company]',
      $eager: '[company, contact, login]',
      'company.id': companyId,
    };
  }

  const resp = await feathersClient.service('person').find({
    query,
  }).catch(() => {
    ErrorAlert('Nastala chyba');
    return { data: [], total: 0 };
  }) as Paginated<PersonData>;

  return {
    options: resp.data,
    hasMore: resp.total > options.length + resp.data.length,
  };
};

const formatNewOption = (inputValue: string) => {
  const [first_name, last_name] = inputValue.split(' ');
  return {
    id: 0,
    first_name,
    last_name: last_name ?? '',
  } as PersonData;
};

const formatLabel = (person: PersonData, blocks: Blocks) => {
  const name = `[${person.id}] ${person.first_name} ${person.last_name}`;
  if (blocks.company) {
    return `${name} - Blokace na firmu`;
  } if (blocks.branchoffice) {
    return `${name} - Blokace na provozovnu`;
  } if (blocks.department) {
    return `${name} - Blokace na oddelení`;
  }
  return name;
};

const TySelectPerson = <Fields extends object>({
  debounceTimeout = 1000,
  defaultOptions = [],
  limitToRelatedCompanies = true,
  onlyInvitable,
  filter,
  exclude,
  companyId,
  company,
  ...props
}: TySelectPersonProps<Fields>) => {
  const defaultOptionsCount = Array.isArray(defaultOptions) ? defaultOptions.length : 0;
  const loadOptions = useCallback(
    () => loadPersonOptions(defaultOptionsCount, filter, exclude, limitToRelatedCompanies, onlyInvitable, companyId),
    [defaultOptionsCount, filter, exclude, limitToRelatedCompanies, onlyInvitable],
  );

  return (
    <TyAsyncSelect
      {...props}
      defaultOptions={defaultOptions}
      debounceTimeout={debounceTimeout}
      loadOptions={loadOptions()}
      getOptionLabel={(option) => {
        if (isDefaultSelectOption(option)) return (option.label);
        return formatLabel(option, getBlocks(option.blockedCompany, company));
      }}
      formatCreateLabel={(inputValue: string) => `Vytvořit nového uživatele ${inputValue}`}
      formatNewOption={formatNewOption}
      getOptionValue={(option) => String(option.id)}
    />
  );
};

export default TySelectPerson as typeof TySelectPerson;
