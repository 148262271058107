import isMedicalExaminationInterval from '@tymbe/utils/isMedicalExaminationInterval';
import moment, { MomentInput } from 'moment';

import { Roles } from './enums';
import { calculateWorkTime, checkNightInterval } from './time';
import { RolesData, ShiftData, ShiftTemplateData } from '../types/TymbeApi';

export const checkIsAdultShift = (start_time: MomentInput = '', end_time: MomentInput = '') => {
  const startTime = moment(start_time);
  const endTime = moment(end_time);

  if (endTime < startTime) {
    endTime.add('1', 'day');
  }
  const rawShiftDuration = calculateWorkTime(startTime, endTime, true, 'hours');

  return checkNightInterval(startTime, endTime) || Number(rawShiftDuration) > 8.5;
};

export const isMedicalExaminationShift = (start_time: MomentInput, end_time: MomentInput) => {
  const startTime = moment(start_time);
  const endTime = moment(end_time);

  if (endTime < startTime) {
    endTime.add('1', 'day');
  }
  return isMedicalExaminationInterval(startTime, endTime);
};

export const getRequirements = (
  shiftData?: Partial<ShiftTemplateData> | Partial<ShiftData>,
  userRole?: RolesData[],
) => {
  let returnedPerks = shiftData?.perk?.filter((perk) => perk.is_visible);

  if (userRole && userRole.some((role) => [
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.TYMBE_COORDINATOR,
  ].includes(role.slug))) {
    returnedPerks = shiftData?.perk;
  }
  return [...returnedPerks || [], ...shiftData?.documentType || []];
};
