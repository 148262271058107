export const mpsvOldTableColumnHeaders = [
  {
    value: 'application.employer_id',
    label: 'Company',
    sortable: true,
  },
  {
    value: 'application:person.last_name',
    label: 'Tymber',
    sortable: true,
  },
  {
    value: 'status',
    label: 'Status',
    sortable: true,
  },
  {
    value: 'created_at',
    label: 'Created at',
    sortable: true,
  },
];

export const mpsvTableColumnHeaders = [
  {
    value: '',
    label: 'Stav',
    sortable: false,
  },
  {
    value: 'person_id',
    label: 'Tymber ID',
    sortable: true,
  },
  {
    value: 'person.last_name',
    label: 'Tymber',
    sortable: true,
  },
  {
    value: 'employer_id',
    label: 'Company',
    sortable: true,
  },
  {
    value: 'shift.start_time',
    label: 'Nástup',
    sortable: true,
  },
  {
    value: 'shift:branchoffice.parent_id',
    label: 'Pracoviště',
    sortable: true,
  },
  {
    value: 'mpsvReport.status',
    label: 'Status',
    sortable: true,
  },
  {
    value: 'mpsvReport.created_at',
    label: 'Vznik hlášení',
    sortable: true,
  },
  {
    value: '',
    label: '',
    sortable: false,
  },
];

export const prettifyXml = (sourceXml: string) => {
  const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString([
    // describes how we want to modify the XML - indent everything
    '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
    '  <xsl:strip-space elements="*"/>',
    '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
    '    <xsl:value-of select="normalize-space(.)"/>',
    '  </xsl:template>',
    '  <xsl:template match="node()|@*">',
    '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
    '  </xsl:template>',
    '  <xsl:output indent="yes"/>',
    '</xsl:stylesheet>',
  ].join('\n'), 'application/xml');

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  const resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};
