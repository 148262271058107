import { isValidBankAccount } from '@tymbe/bank-account';
import { PaymentRequestData } from '@tymbe/schema/payment-request.interface';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

import { ExclamationCircleIcon } from '../../../../../components/icons';
import { TableCell, TableRow } from '../../../../../components/Table';
import { dangerColor } from '../../../../../utils/colors';

function getBankAccountPart(
  creditTransaction: PaymentRequestData['creditTransaction'],
  paymentAuthorized: PaymentRequestData['paymentAuthorized'],
  position: number,
) {
  const creditTransBA = creditTransaction?.person?.personData?.bank_account;
  const paymentAuthBA = paymentAuthorized?.person?.personData?.bank_account;
  const bankAccount = creditTransBA || paymentAuthBA;

  if (!bankAccount || !isValidBankAccount(bankAccount)) {
    return (
      <Tooltip overlay="Nevalidní číslo účtu">
        <span className="px-1">
          <ExclamationCircleIcon iconcolor={dangerColor} />
        </span>
      </Tooltip>
    );
  }

  return bankAccount.split('/')[position];
}

function renderDate(datetime: string) {
  return moment(datetime).format('HH:mm DD.MM.YYYY');
}

interface PaymentRequestRowProps {
  paymentRequest: PaymentRequestData;
  onChange: (rowId: number) => void;
  checked: boolean;
}

const formatNumberWithLeadingZeros = (input: number, minLength: number): string => String(input).padStart(minLength, '0');

const getVariableSymbol = (paymentRequest: PaymentRequestData) => {
  if (paymentRequest?.credit_payout) {
    if (!paymentRequest?.creditTransaction) return 'Chybí creditTransaction';
    const { created_at } = paymentRequest.creditTransaction;
    return moment(created_at).format('YYYYMM');
  }
  if (!paymentRequest?.paymentAuthorized) return 'Chybí paymentAuthorized';
  const { date_time, company_id } = paymentRequest.paymentAuthorized;
  const dateFormat = moment(date_time).format('YYYYMM');
  return `${dateFormat}${formatNumberWithLeadingZeros(company_id, 4)}`;
};

const PaymentRequestRow = ({
  paymentRequest,
  checked,
  onChange,
}: PaymentRequestRowProps) => {
  const person = (paymentRequest.paymentAuthorized || paymentRequest.creditTransaction)?.person;
  return (
    <TableRow>
      <TableCell>
        <input
          type="checkbox"
          className="w-5 h-5"
          onChange={() => onChange(paymentRequest.id)}
          checked={checked}
        />
      </TableCell>
      <TableCell>{paymentRequest.credit_payout ? 'Kredity' : 'Peníze'}</TableCell>
      <TableCell>
        <Link
          to={{
            pathname: `/user/${person?.id}`,
          }}
          className="ty-poppins-12"
        >
          {person?.id}
        </Link>
      </TableCell>
      <TableCell>{person?.first_name}</TableCell>
      <TableCell>{person?.last_name}</TableCell>
      <TableCell>
        {getBankAccountPart(paymentRequest.creditTransaction, paymentRequest.paymentAuthorized, 0)}
      </TableCell>
      <TableCell>
        {getBankAccountPart(paymentRequest.creditTransaction, paymentRequest.paymentAuthorized, 1)}
      </TableCell>
      <TableCell>{getVariableSymbol(paymentRequest)}</TableCell>
      <TableCell>{paymentRequest.paymentAuthorized?.company?.name}</TableCell>
      <TableCell>{paymentRequest.amount}</TableCell>
      <TableCell>{renderDate(paymentRequest.created_at)}</TableCell>
      <TableCell>
        {paymentRequest.paymentTransaction?.exported_at
          ? renderDate(paymentRequest.paymentTransaction.exported_at)
          : '-'}
      </TableCell>
    </TableRow>
  );
};
export default PaymentRequestRow;
