import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import './PdfFrame.styles.css';

export type PdfFrameProps = {
  url: string;
  className?: string;
};
/**
 *
 * @param url - What PDF should be displayed.
Its value can be an URL, a file (imported using `import ... from ...` or from file input form element), or an object with parameters
check react-pdf docs for more info
 * @param className - defaults to ty_pdf_frame, change it to fit the purpose
 */
export const PdfFrame = ({ url, className = 'ty_pdf_frame' }: PdfFrameProps) => {
  const doc = useRef<HTMLDivElement>();
  const [width, setWidth] = useState<number>(0);
  const [pages, setPages] = useState<number>(0);
  const updateWidth = () => {
    setWidth((doc.current?.childNodes[0] as HTMLDivElement)?.clientWidth);
  };

  const onLoad = (pdf: PDFDocumentProxy) => {
    setPages(pdf.numPages);
    setTimeout(updateWidth, 1);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  });

  const getPages = (w: number) => {
    let i;
    const list = [];
    for (i = 1; i <= pages; i += 1) {
      list.push(
        <Page pageNumber={i} key={i} width={w} className="ty_pdf_page" />,
      );
    }
    return list;
  };

  return (
    <div
      className={className}
      ref={doc as React.MutableRefObject<HTMLDivElement>}
    >
      <Document file={url} onLoadSuccess={onLoad}>
        {getPages(width)}
      </Document>
    </div>
  );
};
