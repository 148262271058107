import moment from 'moment';

import { NUMERIC_LOCALE } from '../../../utils/constants';
import { CreditTransactionData } from '../../../../../../libs/schema/src/credit-transaction.interface';

const valueColor = (value: number): string => {
  if (value > 0) {
    return 'text-success';
  }
  if (value < 0) {
    return 'text-danger-600';
  }
  return '';
};

const strike = (credit: CreditTransactionData): string => {
  if (credit.deleted_at) {
    return 'line-through';
  }
  return '';
};

export interface PaymentRowProps {
  credit: CreditTransactionData;
}

const CreditRow = ({ credit }: PaymentRowProps) => {
  return (
    <div className="border-secondary-200 border rounded-lg mb-3">
      <div className="flex justify-between p-4">
        <div className="flex gap-5">
          <div className={`w-60 shrink-0 ${strike(credit)}`}>
            {(credit.attendance?.application?.shift ||
              credit.attendance_id) && (
              <div>
                <div className="text-secondary font-semibold text-sm leading-5">
                  Docházka
                </div>
                <div className="text-secondary text-sm leading-5">
                  {moment(credit.created_at).format('DD.MM.YYYY')}
                </div>
              </div>
            )}
            {(credit.paymentRequest || credit.payment_request_id) && (
              <div>
                <div className="text-secondary font-semibold text-sm leading-5">
                  Žádost o výplatu
                </div>
                <div className="text-secondary text-sm leading-5">
                  {moment(credit.paymentRequest?.created_at).format('DD.MM.YYYY')}
                </div>
              </div>
            )}
            {credit.personLiability && (
              <div>
                <div className="text-secondary font-semibold text-sm leading-5">
                  Manko
                </div>
                <div className="text-secondary text-sm leading-5">
                  {moment(credit.created_at).format('DD.MM.YYYY')}
                </div>
              </div>
            )}
          </div>
          <div className={`w-40 text-right ${strike(credit)}`}>
            <div
              className={`ml-10 font-semibold text-sm max-h-36 overflow-y-auto ${valueColor(
                Number(credit.amount)
              )}`}
            >
              {Number(credit.amount) > 0 ? '+' : ''}
              {Number(credit.amount).toLocaleString(NUMERIC_LOCALE)} Kč
            </div>
          </div>
          <div>
            <div
              className={`ml-10 text-sm max-h-36 overflow-y-auto ${strike(
                credit
              )}`}
            >
              {credit.attendance?.application?.shift && (
                <>
                  směna {moment(credit.attendance?.application?.shift.start_time).format('D. M.')}
                </>
              )}
            </div>
            <div className="ml-10 text-sm max-h-36 overflow-y-auto">
              {credit.paymentRequest && credit.paymentTransaction && (
                <>
                  vyplaceno {moment(credit.paymentTransaction.created_at).format('DD.MM.YYYY')}
                </>
              )}
              {credit.paymentRequest &&
                !credit.paymentTransaction &&
                'zatím neodesláno'}
            </div>
            <div
              className={`ml-10 text-sm max-h-36 overflow-y-auto ${strike(
                credit
              )}`}
            >
              {credit.personLiability && (
                <>popis: {credit.personLiability.description}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditRow;
