import { Query } from '@feathersjs/feathers';
import moment, { Moment } from 'moment';

import {
  BAD_JOB_EVALUATION_SCORE,
  DEFAULT_JOB_EVALUATION_SCORE,
  GOOD_JOB_EVALUATION_SCORE,
} from '../../../utils/constants';
import { AttendanceResolution } from '../../../utils/enums';
import { FormValues } from '../Libs/transform';

const apiTransformationsAttendance: Query = {

  company: (companies: FormValues[]) => {
    if (companies.length === 0) return undefined;
    const $query = {
      $joinRelation: {
        application: {
          shift: true,
        },
      },
      'application:shift.company_id': { $in: companies.map((c) => c.value) },
    };
    return $query;
  },

  position: (positions: FormValues[]) => {
    if (positions.length === 0) return undefined;
    const $query = {
      $joinRelation: {
        application: {
          shift: true,
        },
      },
      'application:shift.name': { $in: positions.map((p) => p.value) },
    };
    return $query;
  },

  tymber: (tymbers: FormValues[]) => {
    if (tymbers.length === 0) return undefined;
    const $query = {
      $joinRelation: {
        application: {
          person: true,
        },
      },
      'application:person.id': { $in: tymbers.map((t) => t.value) },
    };
    return $query;
  },

  branchoffice: (branchoffices: FormValues[]) => {
    if (branchoffices.length === 0) return undefined;
    const $query = {
      $joinRelation: {
        application: {
          shift: {
            branchoffice: true,
          },
        },
      },
      'application:shift:branchoffice.parent_id': { $in: branchoffices.map((b) => b.value) },
    };
    return $query;
  },

  department: (departments: FormValues[]) => {
    if (departments.length === 0) return undefined;
    const $query = {
      $joinRelation: {
        application: {
          shift: true,
        },
      },
      'application:shift.branchoffice_id': { $in: departments.map((d) => d.value) },
    };
    return $query;
  },

  score: (score: FormValues) => {
    if (!score) return undefined;
    const jobEvaluationArray = [
      GOOD_JOB_EVALUATION_SCORE,
      BAD_JOB_EVALUATION_SCORE,
      DEFAULT_JOB_EVALUATION_SCORE,
    ];

    const resolutionArray = [
      AttendanceResolution.ADMIN_EXCUSED_ABSENCE,
      AttendanceResolution.EXCUSED_ABSENCE,
      AttendanceResolution.UNEXCUSED_ABSENCE,
      AttendanceResolution.CANCEL,
    ];

    const $query = {
      $or: [
        {
          ...(jobEvaluationArray.includes(score.value as number) && { job_evaluation: score.value }),
        },

        {
          ...(resolutionArray.includes(score.value as string) && { resolution: score.value }),
        },
      ],
    };

    return $query;
  },

  confirmator: (confirmators: FormValues[]) => {
    if (confirmators.length === 0) return undefined;
    const $query = { confirmed_by: { $in: confirmators.map((c) => c.value) } };

    return $query;
  },

  shiftTimeFrom:
    (time: Moment) => ({ $modify: { startTime: [moment(time).startOf('minutes').toISOString(), undefined] } }),

  shiftTimeTo:
    (time: Moment) => ({ $modify: { startTime: [undefined, moment(time).startOf('minutes').toISOString()] } }),

  shiftDateFrom:
    (date: Moment) => ({ $modify: { startDate: [moment(date).startOf('day').toISOString(), undefined] } }),

  shiftDateTo: (date: Moment) => ({ $modify: { startDate: [undefined, moment(date).endOf('day').toISOString()] } }),

  confirmDateFrom:
    (date: Moment) => ({ $modify: { createdAt: [moment(date).startOf('day').toISOString(), undefined] } }),

  confirmDateTo: (date: Moment) => ({ $modify: { createdAt: [undefined, moment(date).endOf('day').toISOString()] } }),

  internalName: (name: FormValues) => {
    if (!name) return undefined;
    return {
      $joinRelation: {
        application: {
          shift: true,
        },
      },
      'application:shift.external_id': { $ilike: `%${name}%` },
    };
  },

};

export default apiTransformationsAttendance;
