import { FC, useState } from 'react';
import Modal from 'react-modal';

import { IconButton } from '../buttons';
import { CloseIcon, DeleteIcon, VisibilityIcon } from '../icons';

interface UploadedImageProps {
  image: Blob;
  alt: string;
  removeImage: () => void;
  name?: string;
}

const UploadedImage: FC<UploadedImageProps> = ({ image, alt, removeImage, name }) => {
  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => {
    setShowModal(true);
  };

  const onHideModal = () => {
    setShowModal(false);
  };

  const getImageUrl = (imageBlob: Blob) => URL.createObjectURL(imageBlob);

  return (
    <div>
      <div
        className="ty-uploaded-image-container"
      >
        <Modal
          isOpen={showModal}
          onRequestClose={onHideModal}
          contentLabel="My dialog"
          className="ty-uploaded-image-modal"
          overlayClassName="ty-uploaded-image-modal-overlay"
        >
          <IconButton
            onClick={onHideModal}
            className="ty-uploaded-image-modal-button"
          >
            <CloseIcon iconcolor="#28367b" />
          </IconButton>
          <img
            src={getImageUrl(image)}
            alt={alt}
          />
        </Modal>
        <img
          src={getImageUrl(image)}
          alt={alt}
          className="ty-uploaded-image"
        />
        <div className="ty-uploaded-image-buttons-container">
          {!showModal && (
            <IconButton
              onClick={onShowModal}
              className="ty-uploaded-image-buttons"
            >
              <VisibilityIcon strokecolor="#F3F5FD" />
            </IconButton>
          )}
          {!showModal && (
            <IconButton
              onClick={removeImage}
              className="ty-uploaded-image-buttons"
            >
              <DeleteIcon strokecolor="#F3F5FD" />
            </IconButton>
          )}
        </div>
      </div>
      {name && <h4>{name}</h4>}
    </div>
  );
};

export default UploadedImage;
