import { Query } from '@feathersjs/feathers';
import merge from 'lodash.merge';
import objection from 'objection';

type JoinRelationObjectType = { [Key: string]: JoinRelationObjectType | boolean };

let result = '';

function joinRelationRecursiveHelper(value: unknown, path?: unknown) {
  if (typeof value === 'object' && value !== null) {
    result += (path !== undefined) ? `${path}.[` : '';
    Object.entries(value).map(([key, val]): any => ([key, joinRelationRecursiveHelper(val, key)]));
  } else if (value === true) {
    result += (result.slice(-1) === '[' || result === '') ? `${path}` : `,${path}`;
  }
  return result;
}

export function transformJoinRelation(joinRelationObject?: JoinRelationObjectType) {
  if (!joinRelationObject) return undefined;

  let res = joinRelationRecursiveHelper(joinRelationObject);
  const count = (res.split('[').length - 1 || 0);
  res = `${res}${']'.repeat(count)}`;

  result = '';
  return `[${res}]`;
}

const resetValue = (value: unknown) => {
  if (!value && value !== 0 && value !== '') return null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (typeof value === 'object') {
    return Object.fromEntries(Object.entries(value).map(([key, val]): any => ([key, resetValue(val)])));
  }
  return value;
};

type Transforms<A> = {
  [K in keyof A]: (value: A[K]) => Query | undefined;
};

const apiBuilder = <A extends object>(values: A, transforms: Transforms<A>): Query => {
  const transformed = Object.entries(values).map(([key, value]) => transforms[key as keyof A](value));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const relation = objection.RelationExpression.create({});
  const merged = resetValue(merge({}, ...transformed)) as Query;

  if (merged.$joinRelation) {
    merged.$joinRelation = relation.merge(merged.$joinRelation).toString();
  }

  return merged;
};

export default apiBuilder;
