import { TableCell } from './TableCell';

interface TableSelectHeaderProps {
  selectAll: () => void;
  checked: boolean;
}

const TableSelectHeader = ({ selectAll, checked }: TableSelectHeaderProps) => (
  <TableCell head>
    <input
      type="checkbox"
      className="w-5 h-5"
      onChange={selectAll}
      checked={checked}
    />
  </TableCell>
);

export default TableSelectHeader;
