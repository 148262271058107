import moment from 'moment/moment';
import { ComponentProps } from 'react';

import TyCalWeek from './CalWeek/TyCalWeek';
import { useTyCalWeekData } from './CalWeek/TyCalWeekContext';
import Stats from './Stats';
import { ShiftData } from '../../../types/TymbeApi';
import { getDuration } from '../../../utils';

type HeaderProps = ComponentProps<typeof TyCalWeek.Header>;

const DaySummary: HeaderProps['headComp'] = ({ day, date }) => {
  const { eventData } = useTyCalWeekData<ShiftData>();
  const dayEvents = eventData.filter(({ date: eventDate }) => moment(date).isSame(eventDate, 'day'));
  const totalApplications = dayEvents.reduce((acc, { data }) => acc + Number(data.orders_count ?? 0), 0);
  const currentApplications = dayEvents.reduce((acc, { data }) => acc + Number(data.filled_orders_count ?? 0), 0);
  const totalHours = dayEvents.reduce(
    (acc, { data }) => acc + (getDuration(data.start_time, data.end_time, 'hours') * (data.orders_count ?? 0)),
    0,
  );
  const currentHours = dayEvents.reduce(
    (acc, { data }) => acc + (getDuration(data.start_time, data.end_time, 'hours') * (data.filled_orders_count ?? 0)),
    0,
  );

  return (
    <div className="p-0 text-right" data-day={day}>
      <Stats label="směn" current={currentApplications} total={totalApplications} />
      <Stats label="hodin" current={currentHours} total={totalHours} />
    </div>
  );
};

export default DaySummary;
