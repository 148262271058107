import { RadioProps } from 'informed';
import React, { ReactNode, useContext } from 'react';

import TyRadioGroupContext from './TyRadioGroupContext';

import './TyInput.css';

type ProvidedProps = 'id' | 'checked' | 'type';

type FullRadioProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof RadioProps | ProvidedProps> & RadioProps;

export interface TyRadioProps extends Omit<FullRadioProps, 'label'> {
  label: ReactNode;
  checked?: boolean;
}

const TyRadio = ({ label, value, className, ...props }: TyRadioProps) => {
  const tyRadioGroupContext = useContext(TyRadioGroupContext);
  if (!tyRadioGroupContext) return null;

  const { radioGroupApi, radioGroupState, id } = tyRadioGroupContext;
  const { setValue, setTouched } = radioGroupApi;
  const { value: groupValue } = radioGroupState;

  return (
    <div className={`ty_input_field ${className}`}>
      <input
        {...props}
        id={`${id}-${value}`}
        value={value}
        className={`ty_input ${className}`}
        checked={groupValue === value}
        onChange={(e) => {
          if (!e.target.checked) return;
          setValue(value, e);
        }}
        onBlur={(e) => {
          setTouched(true, e);
        }}
        type="radio"
      />
      <label htmlFor={`${id}-${value}`}>{label}</label>
    </div>
  );
};

export default TyRadio;
