import getResponseArray from '@tymbe/utils/getResponseArray';
import classNames from 'classnames';
import { Input, useFieldApi, useFieldState } from 'informed';
import moment, { Moment } from 'moment';
import { ComponentProps, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import apiClient from '../../../../apiClient';
import TyDatePicker from '../../../../components/inputs/TyDatePicker';
import TySelectRequirements from '../../../../components/inputs/TySelectRequirements/TySelectRequirements';
import { Option } from '../../../../components/inputs/TySelectRequirements/TySelectRequirements.types';
import {
  DocumentTypeEnum,
  isDocumentTypeData,
  isPerkData,
  PerkData,
  PerkGroup,
  PerkId,
  RolesData,
  ShiftData,
} from '../../../../types/TymbeApi';
import { checkIsAdultShift, getRequirements, isMedicalExaminationShift } from '../../../../utils/shift';
import EmojiPicker from '../EmojiPicker';
import CalendarIcon from '../icons/CalendarIcon';
import ClockIcon from '../icons/ClockIcon';
import FilterIcon from '../icons/FilterIcon';
import PeopleIcon from '../icons/PeopleIcon';
import PlusIcon from '../icons/PlusIcon';

type ShiftDetailFormOwnProps = {
  shift: ShiftData,
  userRole: RolesData[];
  className?: string,
  onCancel: () => void,
};

export type ShiftDetailFormValues = {
  date: Moment,
  startTime: Moment,
  endTime: Moment,
  spaces: number,
  requirements: Option[] | null;
  emoji: string;
};

type ShiftDetailFormProps = ShiftDetailFormOwnProps & Omit<ComponentProps<'div'>, keyof ShiftDetailFormOwnProps>;

const adultPerk = { id: PerkId.ADULT, group: PerkGroup.AGE } as PerkData;

const ShiftDetailForm = ({
  shift,
  userRole,
  onCancel,
  className = '',
  ...rest
}: ShiftDetailFormProps) => {
  const { value: date, dirty: dateDirty } = useFieldState<Moment>('date');
  const { value: startTime } = useFieldState<Moment>('startTime');
  const { value: endTime, dirty: endTimeDirty } = useFieldState<Moment>('endTime');
  const { value: requirement } = useFieldState<Option[]>('requirements');
  const { setValue: setStartTime } = useFieldApi('startTime');
  const { setValue: setEndTime } = useFieldApi('endTime');
  const { setValue: setRequirements, getValue: getRquirements } = useFieldApi<Option[]>('requirements');
  const [addedRequrements, setAddedRequrements] = useState<(PerkId | number)[]>([]);

  const timeDiff = endTime?.diff(startTime, 'hours');
  const [addRequirement, setAddRequirement] = useState(false);

  const initialRequirements = [...(shift.perk || []), ...(shift.documentType || [])];

  // Update start time and end time when date changes
  useEffect(
    () => {
      if (dateDirty) {
        const start = date.clone().set({ hour: startTime.hour(), minute: startTime.minute() });
        const end = date.clone().set({ hour: endTime.hour(), minute: endTime.minute() });

        setStartTime(start);
        setEndTime(end);
      }
    },
    [date, dateDirty, endTime, setEndTime, setStartTime, startTime],
  );

  // Add 1 day to end time if it is before start time
  useEffect(
    () => {
      if (endTimeDirty && endTime.isBefore(startTime)) {
        const end = endTime.clone().add(1, 'day');
        setEndTime(end);
      }
    },
    [endTime, endTimeDirty, setEndTime, startTime],
  );

  // Add adult perk if shift fulfills the conditions
  useEffect(
    () => {
      const adultAlreadyPresent = requirement?.some((perk) => perk.id === PerkId.ADULT);

      const shiftForAdults = checkIsAdultShift(startTime, endTime);

      if (shiftForAdults) {
        if (adultAlreadyPresent) return;

        setAddedRequrements((prev) => {
          setRequirements(getRquirements() ? [...getRquirements(), adultPerk] : [adultPerk]);
          return [...prev, PerkId.ADULT];
        });
      } else {
        if (!adultAlreadyPresent || !addedRequrements.includes(PerkId.ADULT)) return;

        setAddedRequrements((prev) => {
          setRequirements(getRquirements()?.filter((perk) => perk.id !== PerkId.ADULT) ?? []);
          return prev.filter((id) => id !== PerkId.ADULT);
        });
      }
    },
    [startTime, endTime, requirement, setRequirements, getRquirements, addedRequrements],
  );

  // Add medical examination perk if shift fulfills the conditions
  const { data: medicalExaminationDocuments } = useQuery(
    ['FetchEntryMedicalExamination'],
    async () => apiClient.service('document-type').find(
      { query: { type: DocumentTypeEnum.MEDICAL_EXAMINATION } },
    ),
    { staleTime: Infinity },
  );
  const medicalExaminationDocumentType = getResponseArray(medicalExaminationDocuments)[0];
  const { data: companyWithContractor } = useQuery(
    ['FetchCompanyContractor', shift.company_id],
    async () => apiClient.service('company').get(
      shift.company_id,
      { query: { $eager: '[contractor]' } },
    ),
    { staleTime: Infinity },
  );
  useEffect(
    () => {
      if (
        !medicalExaminationDocumentType
        || !companyWithContractor
        || !companyWithContractor.contractor?.some((contractor) => contractor.id === 113)
      ) {
        return;
      }

      const medicalExaminationAlreadyPresent = getRquirements()
        ?.some((perk) => perk.id === medicalExaminationDocumentType.id);
      const shiftForMedicalExamination = isMedicalExaminationShift(startTime, endTime);

      if (shiftForMedicalExamination) {
        if (medicalExaminationAlreadyPresent) return;

        setAddedRequrements((prev) => {
          setRequirements(getRquirements()
            ? [...getRquirements(), medicalExaminationDocumentType]
            : [medicalExaminationDocumentType]);
          return [...prev, medicalExaminationDocumentType.id];
        });
      } else {
        if (!medicalExaminationAlreadyPresent || !addedRequrements.includes(medicalExaminationDocumentType.id)) return;

        setAddedRequrements((prev) => {
          setRequirements(getRquirements()?.filter((perk) => perk.id !== medicalExaminationDocumentType.id) ?? []);
          return prev.filter((id) => id !== medicalExaminationDocumentType.id);
        });
      }
    },
    [startTime, endTime, requirement],
  );

  return (
    <div
      className={classNames(className, 'border border-secondary-100 rounded-xl p-4 text-secondary-900')}
      {...rest}
    >
      <div className="text-secondary-900 font-medium text-sm border border-secondary-400 rounded-lg px-2 py-1.5 flex items-center gap-2">
        <EmojiPicker
          className="bg-secondary-50 w-5 h-5 text-[10px] leading-[10px]"
          initialValue={shift.emoji ?? undefined}
          name="emoji"
        />
        <div>{shift.name}</div>
      </div>
      <div className="flex flex-col gap-3 border-t border-b border-secondary-200 my-3 py-3">
        <div className="flex gap-2 items-center text-secondary-300 pl-1.5">
          <CalendarIcon className="w-4" />
          <TyDatePicker
            name="date"
            className="ml-1 border text-secondary-900 border-secondary-400 rounded-lg text-sm py-1.5 px-2 w-full font-medium"
            initialValue={moment(shift.start_time)}
            required
          />
        </div>
        <div className="flex gap-2 items-center text-secondary-300 pl-1.5">
          <ClockIcon className="w-4" />
          <TyDatePicker
            name="startTime"
            className="ml-1 w-[60px] text-secondary-900 border border-secondary-400 rounded-lg text-sm py-1.5 px-2 font-medium"
            picker="time"
            initialValue={moment(shift.start_time)}
            required
          />
          -
          <TyDatePicker
            name="endTime"
            className="w-[60px] text-secondary-900 border border-secondary-400 rounded-lg text-sm py-1.5 px-2 font-medium"
            picker="time"
            initialValue={moment(shift.end_time)}
            required
          />
          {timeDiff}h
        </div>
        <div className="flex gap-2 items-center text-secondary-300 pl-1.5">
          <PeopleIcon className="w-4" />
          <Input
            name="spaces"
            className="ml-1 w-[60px] text-secondary-900 border border-secondary-400 rounded-lg text-sm py-1.5 px-2 font-medium"
            initialValue={Number(shift.unfilled_orders_count)}
          />
          Míst
        </div>
        <div className="flex gap-2 items-center text-secondary-300 pl-1.5">
          <FilterIcon className="w-4" />
          {!addRequirement && !initialRequirements.length ? (
            <button
              type="button"
              className="p-0 flex items-center gap-2 text-secondary-600 font-semibold"
              onClick={() => setAddRequirement(true)}
            >
              <PlusIcon className="w-[18px]" />
              Přidat podmínku
            </button>
          ) : (
            <TySelectRequirements
              name="requirements"
              companyId={shift.company_id}
              userRole={userRole}
              className="cal-person-select ml-1"
              placeholder="Vybrat podmínku"
              isMulti
              isClearable={false}
              initialValue={initialRequirements}
              filterOption={({ data }) => {
                if (isDocumentTypeData(data) && data.name !== 'Student') {
                  return false;
                }
                return !getRequirements(
                  shift.shift_template,
                  userRole,
                ).some((x) => (isPerkData(x) ? x.group === data.group : x.id === data.id));
              }}
            />
          )}
        </div>
      </div>
      <div className="flex gap-[5px] justify-end items-center">
        <button
          type="button"
          onClick={onCancel}
          className="px-3 py-1.5 font-semibold text-secondary-600 border border-secondary-300 text-xs leading-5 hover:bg-secondary-50"
        >
          Zrušit
        </button>
        <button
          type="submit"
          className="px-3 py-1.5 font-semibold text-secondary-600 border border-primary-200 bg-primary-200 text-xs leading-5 hover:bg-primary-300"
        >
          Uložit
        </button>
      </div>
    </div>
  );
};

export default ShiftDetailForm;
