import { Blocks, CompanyProp } from "@tymbe/schema/company-blocked-user.interface";
import { BlockedCompany } from "@tymbe/schema/person.interface";

export const getBlocks = (blockedCompany: BlockedCompany[] | undefined, company: CompanyProp | undefined) => {
  const blocks: Blocks = {
    company: false,
    branchoffice: false,
    department: false,
  };
  if (!company || !blockedCompany) return blocks;
  blockedCompany.forEach((block) => {
    if (block.block_deleted_at !== null) {
      return;
    }
    if (block.id === company.id && block.branchoffice_id === null) {
      blocks.company = true;
      return;
    }
    if (block.branchoffice_id === company.department) {
      blocks.department = true;
      return;
    }
    if (block.branchoffice_id === company.branchoffice) {
      blocks.branchoffice = true;
    }
  });
  return blocks;
};

export const isBlocked = (blocks: Blocks) => Object.values(blocks).find((item) => item) || false;
