import { Moment } from 'moment';
import { ComponentProps } from 'react';

import CalendarIcon from '../icons/CalendarIcon';
import ClockIcon from '../icons/ClockIcon';
import PeopleIcon from '../icons/PeopleIcon';

type ShiftDetailInfoOwnProps = {
  start: Moment,
  end: Moment,
  spaces: number,
};
type ShiftDetailInfoProps = ShiftDetailInfoOwnProps & Omit<ComponentProps<'div'>, keyof ShiftDetailInfoOwnProps>;

const ShiftDetailInfo = ({ start, end, spaces }: ShiftDetailInfoProps) => (
  <div className="flex justify-between text-secondary-500 font-medium">
    <span className="flex gap-1.5">
      <CalendarIcon className="w-4 text-secondary-300" /> {start.format('dd D. M. YYYY')}
    </span>
    <span className="flex gap-1.5">
      <ClockIcon className="w-4 text-secondary-300" />
      {start.format('H:mm')} - {end.format('H:mm')}
      <span className="text-secondary-400">{end.diff(start, 'hours')}h</span>
    </span>
    <span className="flex gap-1.5">
      <PeopleIcon className="w-4 text-secondary-300" /> {spaces} Míst
    </span>
  </div>
);

export default ShiftDetailInfo;
