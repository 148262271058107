import { useEffect } from 'react';

import { SecondaryButton } from '../buttons';

interface DelayedNotificationProps {
  children: React.ReactNode,
  delay?: number,
  buttonText?: string,
  onClick: () => void,
  onTimeout: () => void,
}

const DelayedNotification = ({
  children,
  delay = 5000,
  onClick,
  onTimeout,
  buttonText = 'Zrušit',
}: DelayedNotificationProps) => {
  useEffect(() => {
    const timeout = setTimeout(onTimeout, delay);
    return () => { clearTimeout(timeout); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="flex flex-row justify-between align-center bg-fg-100 rounded-xl py-2 px-5 gap-3"
    >
      <div>{children}</div>
      <SecondaryButton
        type="button"
        onClick={onClick}
      > {buttonText}
      </SecondaryButton>
    </div>
  );
};
export default DelayedNotification;
