import Holidays from 'date-holidays';
import moment from 'moment/moment';
import { ComponentProps } from 'react';

import TyCalWeek from './CalWeek/TyCalWeek';
import { useTyCalWeekData } from './CalWeek/TyCalWeekContext';
import { ShiftData } from '../../../types/TymbeApi';

const holidays = new Holidays('CZ');

type HeaderProps = ComponentProps<typeof TyCalWeek.Header>;

const DayTags: HeaderProps['headComp'] = ({ day, date }) => {
  const { eventData } = useTyCalWeekData<ShiftData>();
  const dayEvents = eventData.filter(({ date: eventDate }) => moment(date).isSame(eventDate, 'day'));

  const hasNoAttendace = dayEvents.some(({ data }) => data.application?.some(({ attendance }) => attendance === null));
  const tags = [];
  const holiday = holidays.isHoliday(new Date(date));
  if (holiday) {
    tags.push(holiday[0].name);
  }

  if (hasNoAttendace && moment().isAfter(date, 'day')) {
    tags.push('Chybějící docházka');
  }
  return <div className="p-0" data-day={day}>{tags.map((tag) => <div key={tag}>{tag}</div>)}</div>;
};

export default DayTags;
