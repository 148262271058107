import { UtilityData } from '@tymbe/schema/utility.interface';
import { useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';

import UtilityForm from './UtilityForm';
import apiClient from '../../../../apiClient';
import { defaultOnError } from '../../../../apiClient/utils';
import { IconButton } from '../../../../components/buttons';
import Button from '../../../../components/buttons/Button';
import { CrossIcon } from '../../../../components/icons';

type AddUtilityProps = {
  className?: string;
};

const AddUtility = ({ className }: AddUtilityProps) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const addUtility = useMutation({
    mutationFn: async (data: Partial<UtilityData>) => apiClient.service('utility').create(data),
    onSuccess: async () => {
      setShowModal(false);
      await queryClient.invalidateQueries('utility');
    },
    onError: defaultOnError,
  });
  return (
    <>
      <Button onClick={() => setShowModal(true)} className={className}>Přidat pomůcku</Button>
      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        contentLabel="My dialog"
        className="relative bg-bg p-7 translate-1/2 rounded-lg m-auto min-w-[450px]"
        overlayClassName="bg-overlay fixed top-0 left-0 right-0 bottom-0 flex flex-col overflow-auto p-5 z-modal"
      >
        <IconButton
          style={{ position: 'absolute', top: 5, right: 5 }}
          onClick={() => setShowModal(false)}
        >
          <CrossIcon />
        </IconButton>
        <UtilityForm
          title="Nová pomůcka"
          onSubmit={({ modified }) => addUtility.mutateAsync(modified)}
        >
          <div className="flex gap-4">
            <Button onClick={() => setShowModal(false)}>Zavřít</Button>
            <div className="grow" />
            <Button type="submit" disabled={addUtility.isLoading}>Uložit</Button>
          </div>
        </UtilityForm>
      </Modal>
    </>
  );
};

export default AddUtility;
