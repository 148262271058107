import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { Card, Col, Row, Table, Button } from 'antd';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { UserAddIcon } from '../../../components/icons';
import { PageTitle } from '../../../components/texts';
import { primaryColor } from '../../../utils/colors';

const CompanyDashboardContainer = ({
  loadUsers = undefined,
  pageTitle = null,
  columns = undefined,
  users = undefined,
  invitations = undefined,
  attendance = undefined,
}) => (
  <>
    <PageTitle>{pageTitle}</PageTitle>
    <Table
      bordered
      // TODO: This is ticking bomb. Refactor!
      // eslint-disable-next-line react/no-unstable-nested-components
      title={() => (
        <h1 style={{ textAlign: 'center' }}>
          Data
          <Button
            style={{ float: 'right', marginTop: '1%' }}
            type="link"
            onClick={(error) => loadUsers(error)}
            icon={<SyncOutlined />}
          />
        </h1>
      )}
      columns={columns}
      dataSource={users}
    />
    <Row gutter={30}>
      <Col span={12}>
        <Card
          style={{
            marginTop: '24px',
          }}
          bodyStyle={{
            padding: '21px 0px 4px 0px',
          }}
        >
          <div
            style={{
              display: 'flex',

              justifyContent: 'space-between',
              padding: '0px 25px 12px 22px',
              textAlign: 'left',
            }}
            className="ty-admin-card-header"
          >
            <div>Pozvánky k řešení</div>
            <div>12</div>
          </div>
          {invitations.map((inv) => (
            <Fragment key={inv.id}>
              {inv.type === 'expired' ? (
                <div className="ty-card-list-item">
                  <div>
                    Pozvánka pro {inv.name} na 20. 08. 2021 14:00
                    vypršela
                  </div>
                  <div>
                    <UserAddIcon iconcolor={primaryColor} />
                  </div>
                </div>
              ) : null}
              {inv.type === 'refused' ? (
                <div className="ty-card-list-item">
                  <div>
                    {inv.name} odmítl pozvánku na 21. 08. 2021 14:00
                  </div>
                  <div>
                    <UserAddIcon iconcolor={primaryColor} />
                  </div>
                </div>
              ) : null}
            </Fragment>
          ))}
        </Card>
      </Col>
      <Col span={12}>
        <Card
          style={{
            marginTop: '24px',
          }}
          bodyStyle={{
            padding: '21px 0px 4px 0px',
          }}
        >
          <div
            style={{
              display: 'flex',

              justifyContent: 'space-between',
              padding: '0px 25px 12px 22px',
              textAlign: 'left',
            }}
            className="ty-admin-card-header"
          >
            <div>Docházka k potvrzení</div>
            <div>45</div>
          </div>
          {attendance.map((att) => (
            <Fragment key={att.id}>
              <div className="ty-card-list-item">
                <div className="ty-card-attendance-title">
                  {att.title}
                </div>
                <div className="ty-card-attendance-stat">
                  <b>{att.requests}</b> &nbsp; pozvánek k potvrzení
                </div>
              </div>
            </Fragment>
          ))}
        </Card>
      </Col>
    </Row>
  </>
);

CompanyDashboardContainer.propTypes = {
  loadUsers: PropTypes.func,
  pageTitle: PropTypes.string,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  invitations: PropTypes.array,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  attendance: PropTypes.array,
};

export default CompanyDashboardContainer;
