import { Paginated, Params, Query } from '@feathersjs/feathers';
import { SalaryAdvanceRestrictionStatus } from '@tymbe/schema/enums';
import { SalaryAdvanceRestrictionsData } from '@tymbe/schema/salary-advance-restrictions.interface';
import { DatePicker } from 'antd';
import { FormState, Form } from 'informed';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';

import { ForeclosureData } from './foreclosure.types';
import columnHeaders from './foreclosure.utils';
import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { PrimaryButton, SecondaryButton, SubmitButton } from '../../../components/buttons';
import Button from '../../../components/buttons/Button';
import AddForeclosureModal from '../../../components/Foreclosure/AddForeclosureModal';
import { AddIcon, TrashIcon } from '../../../components/icons';
import CloseIcon from '../../../components/icons/CloseIcon';
import AlertModal from '../../../components/modals/AlertModal';
import ModalShell from '../../../components/modals/ModalShell';
import SearchBox from '../../../components/search/SearchBox';
import {
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableSortHeader,
} from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import { TableHeaderControls } from '../../../components/tableHeaders';
import { PageTitle } from '../../../components/texts';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import useURLParamsHandler from '../../../hooks/UrlParamsHandler/useURLParamsHandler';

const { MonthPicker } = DatePicker;

const pageTitle = 'Exekuce';

const ValidTo = ({ validTo }: { validTo: string | null }) => {
  const validToMoment = moment(validTo);
  const dateString = validToMoment.isValid() ? validToMoment.format('LL') : 'Neomezeno';

  if (validToMoment.isAfter() || !validToMoment.isValid()) {
    return <strong>{dateString}</strong>;
  }

  return <>{dateString}</>;
};

const AdminForeclosuresPage = () => {
  const queryClient = useQueryClient();
  const [sortField, setSortField] = useState(columnHeaders[2].value);
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddForeclosureModal, setShowAddForeclosureModal] = useState(false);
  const [modalPlaceholder, setModalPlaceholder] = useState<string>('Datum ukončení');
  const [selectedForeclosure, setSelectedForeclosure] = useState<SalaryAdvanceRestrictionsData | null>(null);
  const [foreClosureEndDate, setForeClosureEndDate] = useState<Moment | null>(null);

  const [showSearch, setShowSearch] = useState(false);
  const [searchParams, setSearchParams] = useState<Params | null>();

  const [sortAscendingOrder, setSortAscendingOrder] = useState<number>(-1);
  const [paginationStart, setPaginationStart] = useState<number>(0);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(defaultPageSizeOptions[0].value);

  const query: Params = {
    ...searchParams,
    $eager: '[person, company]',
    $joinRelation: '[person, company]',
    $skip: paginationStart,
    $limit: paginationPageSize,
    // company.name ternary sets company.name as secondary sorting field
    // if company is clicked then it replaces the primary sorting field
    $sort: { [sortField]: sortAscendingOrder, 'company.name': sortField !== 'company.name' ? 1 : sortAscendingOrder },
  };

  const { data, isLoading } = useQuery(
    ['salary-advance-restrictions', query],
    ({ queryKey }): Promise<Paginated<SalaryAdvanceRestrictionsData>> => (feathersClient
      .service('salary-advance-restrictions')
      .find({ query: queryKey[1] as Query })) as Promise<Paginated<SalaryAdvanceRestrictionsData>>,
  );

  const setSort = (field: string, sortOrder: number) => {
    if (field !== sortField) {
      setSortField(field);
    }
    setSortAscendingOrder(sortOrder);
  };

  const onPageChange = (pageStart: number, pageEnd: number) => {
    setPaginationStart(pageStart);
    setPaginationPageSize(pageEnd - pageStart);
  };

  const onSearch = (value: string) => {
    setPaginationStart(1);
    setUrlParamsHandler({ page: 1, search: value });
    if (value === '') {
      return setSearchParams(null);
    }

    let params: Params;

    if (!String(value).startsWith('+') && !Number.isNaN(+value) && typeof +value === 'number') {
      params = {
        $or: [
          { 'company.id': +value },
          { 'person.id': +value },
        ],
      };
    } else {
      params = {
        $modify: { quickFilter: (value as string).split(' ') },
      };
    }

    return setSearchParams(params);
  };

  const foreclosureTypeText = (status: SalaryAdvanceRestrictionStatus) => {
    switch (status) {
      case 'min_enabled':
        return 'Zadržovat od minima';
      case 'forbidden':
        return 'Zadržovat všechno';
      default:
        return 'Neznámy typ';
    }
  };

  const addNewForeclosure = async (foreclosureData: FormState<ForeclosureData>) => {
    const noForeclosureMatch = data?.data.every((item) => {
      if (
        foreclosureData.values.tymber.value === item.person?.id
        && foreclosureData.values.company.id === item.company_id
        && foreclosureData.values.dateMonthYearFormat.tz('europe/prague')
          .set('date', 1).format('DDMMYYYY') === moment(item.valid_from).format('DDMMYYYY')
      ) {
        return false;
      }
      return true;
    });
    if (!noForeclosureMatch) {
      ErrorAlert('Exekuce již existuje.');
      return;
    }
    const foreclosure = {
      person_id: foreclosureData.values.tymber.value,
      company_id: foreclosureData.values.company.id,
      valid_from: foreclosureData.values.dateMonthYearFormat.tz('europe/prague').set('date', 1).startOf('day').format(),
      status: foreclosureData.values.type.value,
    };
    await feathersClient
      .service('salary-advance-restrictions')
      .create(foreclosure);
    queryClient.invalidateQueries({ queryKey: ['salary-advance-restrictions'] });
  };

  const onForeClosureCancellation = (item: SalaryAdvanceRestrictionsData): void => {
    setShowModal(true);
    if (item?.valid_to === null) {
      setModalPlaceholder('Datum ukončení');
    } else {
      setModalPlaceholder(moment(item.valid_to).format('MMMM YYYY'));
    }
    setSelectedForeclosure(item);
  };

  const onForeClosureCancellationSubmit = async (): Promise<void> => {
    if (!selectedForeclosure) return;

    const validTo = moment.tz(foreClosureEndDate, 'Europe/Prague').endOf('month').toISOString();
    if (moment(selectedForeclosure.valid_from).isAfter(validTo)) {
      ErrorAlert('Datum ukončení exekuce nesmí být před jejím začátkem');
      return;
    }

    try {
      await feathersClient.service('salary-advance-restrictions').patch(selectedForeclosure.id, {
        valid_to: validTo,
      });
      SuccessAlert(`Doba ukončení exekuce s ID: ${selectedForeclosure.id} byla úspěšně upravena na ${foreClosureEndDate?.endOf('month').format('DD. MMMM YYYY')}`);
    } catch (error) {
      console.error(error);
      throw new Error(`Při pokusu o změnu exekuce s ID: ${selectedForeclosure.id} došlo k chybě`);
    }

    queryClient.invalidateQueries(['salary-advance-restrictions']);
    setShowModal(false);
  };

  const onForeClosureDelete = (item: SalaryAdvanceRestrictionsData): void => {
    setShowDeleteModal(true);
    setSelectedForeclosure(item);
  };

  const onForeClosureDeleteSubmit = async (): Promise<void> => {
    if (!selectedForeclosure) return;
    try {
      await feathersClient
        .service('salary-advance-restrictions')
        .remove(selectedForeclosure.id);
      setShowDeleteModal(false);
      SuccessAlert(`Exekuce s ID: ${selectedForeclosure?.id} byla úspěšně smazána`);
    } catch (error) {
      console.error(error);
      throw new Error(`Při pokusu o smazání exekuce s ID: ${selectedForeclosure?.id} došlo k chybě`);
    }

    queryClient.invalidateQueries(['salary-advance-restrictions']);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <>
          <div className="items-center flex justify-between">
            <PageTitle>{pageTitle}</PageTitle>
          </div>
          <div className="bg-bg">
            <TableHeaderControls>
              <div className="flex justify-center items-center gap-2">
                <SearchBox
                  name="tymberSearch"
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  onParamsSearch={onSearch}
                  loading={isLoading}
                  placeholder="Jméno nebo ID firmy"
                />
                <PrimaryButton
                  className="ty-icon-button ty-icon-button-toggle bg-primary-400"
                  onClick={() => setShowAddForeclosureModal(true)}
                  icon={<AddIcon />}
                />
                {showAddForeclosureModal && (
                  <AddForeclosureModal
                    onSubmit={addNewForeclosure}
                    showModal={showAddForeclosureModal}
                    setShowModal={setShowAddForeclosureModal}
                  />
                )}
              </div>

            </TableHeaderControls>
            <Table>
              <TableHead>
                <TableRow>
                  {columnHeaders.map((col) => (
                    <TableSortHeader
                      key={columnHeaders.indexOf(col).toString()}
                      field={col.sortable ? col.value : undefined}
                      activeField={sortField}
                      onSort={setSort}
                    >
                      {col.label}
                    </TableSortHeader>
                  ))}
                </TableRow>
              </TableHead>
              <tbody>
                {data?.data.map((item) => (
                  <TableRow
                    key={item.id.toString()}
                    cols={[
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/user/${item.person?.id}`,
                        }}
                      >
                        {item.person?.id}
                      </Link>,
                      item.person?.first_name.concat(' ', item.person?.last_name),
                      item.company?.name,
                      moment(item.valid_from).tz('europe/prague').format('LL'),
                      <ValidTo validTo={item.valid_to} />,
                      foreclosureTypeText(item.status),
                      <div className="flex gap-2 items-center">
                        <SecondaryButton
                          style={{ marginRight: '18px' }}
                          onClick={() => onForeClosureCancellation(item)}
                          size="large"
                          buttontext="Ukončit"
                          className="!mr-0"
                        />
                        <Button
                          onClick={() => onForeClosureDelete(item)}
                          className="border-error-500"
                        >
                          <TrashIcon />
                        </Button>
                      </div>,
                    ]}
                  />
                ))}
              </tbody>
            </Table>
          </div>
          <TablePagination
            rowsCount={data?.total}
            onChangePage={onPageChange}
          />
        </>
      </Wrapper>
      <AlertModal
        title="Smazání exekuce"
        message={`Opravdu si přejete exekuci s ID: ${selectedForeclosure?.id} u firmy ${selectedForeclosure?.company?.name} smazat?`}
        yesButtonText="Ano"
        noButtonText="Ne"
        showCancel={false}
        onClose={() => setShowDeleteModal(false)}
        onYes={onForeClosureDeleteSubmit}
        onNo={() => setShowDeleteModal(false)}
        showModal={showDeleteModal}
      />
      <ModalShell
        showModal={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="flex flex-row justify-between items-start">
          <div>
            <h2>K jakému datu chcete exekuci ukončit?</h2>
            <h4>Zvolte poslední měsíc a rok platnosti exekuce</h4>
          </div>
          <div className="shrink-0">
            <button
              aria-label="Close modal button"
              type="button"
              onClick={() => setShowModal(false)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <Form
          name="invitation-modal"
          onSubmit={onForeClosureCancellationSubmit}
        >
          <div className="w-full pt-1 pb-5">
            <MonthPicker
              className="flex-1 w-full"
              placeholder={modalPlaceholder}
              format={['MMMM YYYY']}
              onChange={(date) => setForeClosureEndDate(date)}
            />
          </div>
          <div className="flex gap-x-1 justify-end">
            <button
              type="button"
              className="ty-button-secondary"
              onClick={() => setShowModal(false)}
            >
              Zavřít
            </button>
            <SubmitButton
              className="ty-button-primary"
              buttontext="Uložit"
            />
          </div>
        </Form>
      </ModalShell>
    </Container>
  );
};

export default AdminForeclosuresPage;
