import classNames from 'classnames';
import { ComponentProps } from 'react';

import { ShiftsToAdd } from './utils';
import Spinner from '../../../../components/Spinner';

type ShiftPublishButtonOwnProps = {
  shifts: ShiftsToAdd[],
  isPublishing: boolean,
  hasErrors: boolean,
};

type ShiftPublishButtonProps =
  ShiftPublishButtonOwnProps & Omit<ComponentProps<'button'>, keyof ShiftPublishButtonOwnProps>;

const ShiftPublishButton = ({ className = '', shifts, isPublishing, hasErrors, ...rest }: ShiftPublishButtonProps) => (
  <button
    {...rest}
    type="submit"
    disabled={!shifts.length || isPublishing || hasErrors}
    className={classNames(
      'py-2 px-4 border text-sm font-semibold border-primary-200 bg-primary-200 text-primary-800 disabled:bg-secondary-100 disabled:border-secondary-100 disabled:text-secondary-400 disabled:cursor-not-allowed w-[160px] h-[38px] flex justify-center items-center',
      className,
    )}
  >
    {isPublishing ? <Spinner className="w-4" show /> : 'Publikovat směny'}
  </button>
);

export default ShiftPublishButton;
