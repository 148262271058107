import TyAsyncSelect from '../../inputs/TyAsyncSelect';
import TyDateInput from '../../inputs/TyDateInput';
import styles from '../Components/TyAdvancedSearchCore.module.css';
import { labelsValues } from '../Libs/enums';
import {
  loadCompanyOptions,
  loadRegisteredTymberOptions,
} from '../Libs/loadOptions';

const Body: React.FC = () => (
  <>
    <div className={styles.row}>

      <div>
        <h3>Rok</h3>
        <TyDateInput
          name={labelsValues.dateYearFormat.name}
          label={labelsValues.dateYearFormat.label}
          className={styles.tyInputs}
          picker="year"
        />
      </div>

      <div>
        <h3>Měsíc</h3>
        <TyDateInput
          name={labelsValues.dateMonthFormat.name}
          label={labelsValues.dateMonthFormat.label}
          className={styles.tyInputs}
          picker="month"
        />
      </div>

    </div>

    <div className={styles.row}>

      <div>
        <h3>Firma</h3>

        <TyAsyncSelect
          loadOptions={loadCompanyOptions}
          name={labelsValues.company.name}
          label={labelsValues.company.label}
          className={styles.tyInputs}
          isClearable
          isMulti
        />

      </div>

      <div>
        <h3>Tymber</h3>

        <TyAsyncSelect
          loadOptions={loadRegisteredTymberOptions}
          name={labelsValues.tymber.name}
          label={labelsValues.tymber.label}
          className={styles.tyInputs}
          isClearable
          isMulti
        />

      </div>
    </div>
  </>
);

export default Body;
