import { FieldProps, useField } from 'informed';
import moment, { Moment } from 'moment';
import Picker from 'rc-picker';
import { PanelMode } from 'rc-picker/es/interface';
import { PickerProps } from 'rc-picker/es/Picker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import csCZ from 'rc-picker/lib/locale/cs_CZ';
import { useCallback } from 'react';

import TyInputError from './TyInputError';
import { getHolidaysOnDate, type HolidayWithDate, isDayHoliday } from '../../utils/holiday';
import { CalendarIcon, CloseIcon, CrossIcon } from '../icons';
import './DateInput.css';
import './TyInput.css';

type Props = Omit<Partial<PickerProps<Moment>>, 'disabledTime' | 'picker' | 'allowClear' | 'defaultValue'> & {
  label?: string;
  isClearable?: boolean;
  datePresets?: { label: string, date: Array<string | Date | Moment> }[];
  highlightHolidays?: boolean;
};
type TyDateInputProps<Fields extends object> = FieldProps<Props, Moment[], Fields>;

const TyDateInput = <Fields extends object>({ ...props }: TyDateInputProps<Fields>) => {
  const {
    render,
    fieldState,
    userProps,
    fieldApi,
  } = useField<TyDateInputProps<Fields>, Moment[]>({ ...props });

  const { error, showError, focused, value } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;
  const {
    id,
    label,
    className = '',
    generateConfig = momentGenerateConfig,
    locale = csCZ,
    open = focused,
    onChange,
    onFocus,
    onBlur,
    suffixIcon = <CalendarIcon />,
    datePresets = [],
    renderExtraFooter,
    isClearable = false,
    highlightHolidays = false,
    ...rest
  } = userProps;

  // Picker uses legacy ref at least it's typed as LegacyRef
  const pickerRef = { current: null } as { current: Picker<Moment> | null };

  const innerOnChange = (date: Moment) => {
    // do we need remove already picked date
    const remove = value?.find((val) => date.isSame(val, 'day'));

    const newValues = [...(value || [])];

    if (remove) {
      newValues.splice(value.indexOf(remove), 1);
    } else {
      newValues.push(date);
    }

    setValue(newValues.length ? newValues : undefined);
  };
  const innerOnFocus: PickerProps<Moment>['onFocus'] = (e) => {
    setFocused(true, e);
  };
  const innerOnBlur: PickerProps<Moment>['onBlur'] = (e) => {
    setTouched(true, e);
    setFocused(false, e);
  };

  const innerRenderExtraFooter: PickerProps<Moment>['renderExtraFooter'] = useCallback((mode: PanelMode) => (
    <>
      {datePresets.map(
        (datePreset) => (
          <button
            key={datePreset.label}
            type="button"
            onClick={() => setValue(datePreset.date.map((date) => moment(date)))}
          >
            {datePreset.label}
          </button>
        ),
      )}
      {renderExtraFooter?.(mode) ?? null}
    </>
  ), [datePresets, renderExtraFooter, setValue]);

  const holidays = value && highlightHolidays
    ? value.map((date: Moment) => getHolidaysOnDate(date)).flat()
    : [];

  const focusedClassName = focused ? 'ty_input_field__focused' : '';
  const filledClassName = value?.length ? 'ty_input_field__filled' : '';

  const dateRender = (date: Moment): React.ReactNode => {
    const dayIsHoliday = highlightHolidays && isDayHoliday(date);

    return (
      <div
        className={`rc-picker-cell-inner
          ${value?.some((d) => d.isSame(date, 'day')) ? 'selected' : ''}
          ${dayIsHoliday && '!text-danger !bg-danger-150 !border-danger'}
        `}
      >
        {date.date()}
      </div>
    );
  };

  return render(
    <>
      <div
        className={`ty_input_field ty_rc_multi_picker ty_rc_picker ${showError && error
          ? 'ty_input_error'
          : ''} ${focusedClassName} ${filledClassName} ${className}`}
      >
        <Picker<Moment>
          {...rest}
          ref={(tmp) => {
            pickerRef.current = tmp;
          }}
          defaultValue={undefined}
          allowClear={isClearable}
          className="ty_input"
          dropdownClassName="ty_input_dropdown ty_input_multi_dropdown"
          id={id}
          format={() => ''}
          generateConfig={generateConfig}
          picker="date"
          locale={locale}
          value={null}
          open={open}
          onSelect={innerOnChange}
          onFocus={innerOnFocus}
          onBlur={innerOnBlur}
          suffixIcon={(
            <>
              {!value?.length ? null : (
                <div
                  role="none"
                  className="clear-icon"
                  onClick={() => setValue(undefined)}
                >
                  <CloseIcon />
                </div>
              )}
              {suffixIcon}
            </>
          )}
          renderExtraFooter={innerRenderExtraFooter}
          dateRender={dateRender}
        />
        {label ? <label htmlFor={id}>{label}</label> : null}
        <TyInputError
          fieldState={fieldState}
          required={props.required}
          validate={props.validate}
        />
        <div
          role="none"
          className="ty_select__value-container"
          onClick={(e) => {
            if (e.target !== e.currentTarget || !pickerRef.current) return;
            pickerRef.current.focus();
          }}
        >
          {value?.sort((a, b) => a.diff(b, 'day')).map((date) => (
            <div className="ty_select__multi-value" key={date.toISOString()}>
              <div className={`ty_select__multi-value__label ${isDayHoliday(date) ? 'ty_select__multi-value__label-warning' : ''}`}>
                {date.format('L')}
              </div>
              <div className="ty_select__multi-value__remove" role="none" onClick={() => innerOnChange(date)}>
                <CrossIcon />
              </div>
            </div>
          ))}
        </div>
      </div>
      {holidays.length !== 0 && (
        <div className="text-danger">
          V některý z vybraných dní je státní svátek ({holidays.map((holiday: HolidayWithDate) => `${holiday.date.format('DD.MM.YYYY')} - ${holiday.name}`).join(', ')}).
        </div>
      )}
    </>,
  );
};

export default TyDateInput;
