import { Perk } from '@tymbe/schema/enums';
import { SVGProps } from 'react';

import AdultIcon from './icons/AdultIcon';
import CrossIcon from './icons/CrossIcon';
import EuCitizenIcon from './icons/EuCitizenIcon';
import ManOnlyIcon from './icons/ManOnlyIcon';
import WomanOnlyIcon from './icons/WomanOnlyIcon';
import { getPerkNameById } from '../../../utils';

type PerkIconProps = { perk: Perk } & Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;

const PerkIcon = ({ perk, ...rest }: PerkIconProps) => {
  switch (perk) {
    case Perk.MAN_ONLY:
      return <ManOnlyIcon {...rest} />;
    case Perk.WOMAN_ONLY:
      return <WomanOnlyIcon {...rest} />;
    case Perk.ADULT:
      return <AdultIcon {...rest} />;
    case Perk.EU_CITIZEN:
      return <EuCitizenIcon {...rest} />;
    default:
      return <CrossIcon {...rest} title={getPerkNameById(perk)} />;
  }
};

export default PerkIcon;
