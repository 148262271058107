import classNames from 'classnames';
import { ReactNode } from 'react';

type InicialsProps = {
  name: string;
  className?: string;
  children?: ReactNode;
}

const Inicials = ({ name, className, children }: InicialsProps) => {
  const inicials = name.split(' ').map((i) => i[0]).join('').toUpperCase();
  return (
    <div className={classNames('inline-block size-6 bg-fg-300 rounded-full text-bg text-center align-middle leading-6 box-content text-xs', className)}>
      {inicials}
      {children}
    </div>
  );
};

export default Inicials;
