import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

const Wrapper = ({
  padding = '2%',
  margin = '2%',
  aligncontent = 'center',
  justify = 'center',
  children,
}) => {
  const style = {
    padding,
    margin,
    alignContent: aligncontent,
  };

  return (
    <div style={style}>
      <Row align="midddle" justify={justify}>
        <Col flex="auto">{children}</Col>
      </Row>
    </div>
  );
};

Wrapper.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  aligncontent: PropTypes.string,
  justify: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

export default Wrapper;
