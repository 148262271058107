import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import feathersClient from '../../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { resolveUploadableFiles } from '../../../../../components/blob/blob.utils';
import { SubmitButton } from '../../../../../components/buttons';
import Wrapper from '../../../../../components/wrapper';
import Container from '../../../../../containers';
import Spinner from '../../../../../utils/spinner';
import mapFormValuesToBranchoffice from '../Branchoffice.utils';
import BranchofficeForm, { BranchofficeFormValues } from '../components/BranchofficeForm';

const CreateBranchoffice = () => {
  const { companyId } = useParams();
  const history = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);

  const { data: company } = useQuery(
    ['FetchCompany', companyId],
    async () => feathersClient.service('company').get(
      Number(companyId),
    ),
    { enabled: !!companyId },
  );

  const onSubmit = async ({ values }: FormState<BranchofficeFormValues>) => {
    setFormDisabled(true);

    const data = mapFormValuesToBranchoffice(values, Number(companyId));

    try {
      const branchoffice = await feathersClient.service('branchoffice').create(data);
      const formData = resolveUploadableFiles(values);
      if (formData) {
        await feathersClient.service('branchoffice').patch(
          branchoffice.id,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );
      }
      SuccessAlert('Nová provozovna byla úspěšně vytvořena');
      history(`/company/${companyId}/branchoffice`);
    } catch {
      ErrorAlert('Nastala chyba');
    }

    setFormDisabled(false);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      {!company ? (<Spinner />) : (
        <Wrapper padding="0px" margin="0px 22px 18px 31px">
          <h1>Nová provozovna</h1>
          <Form name="create_branchoffice_form" onSubmit={onSubmit}>
            <BranchofficeForm company={company} />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Wrapper>
      )}
    </Container>
  );
};

export default CreateBranchoffice;
