import classNames from 'classnames';
import useCollapse from 'react-collapsed';

import Protect from '../../../apiClient/Protect';
import { alert, dangerColor, verified } from '../../../utils/colors';
import { Roles } from '../../../utils/enums';
import factoryLoadStaticOptions from '../../../utils/loadStaticOptions';
import {
  AccountActivityIcon,
  AccountVerificationIcon,
  AccountBanIcon,
} from '../../icons';
import TyAsyncSelect from '../../inputs/TyAsyncSelect';
import TyDateInput from '../../inputs/TyDateInput';
import TyRadio from '../../inputs/TyRadio';
import TyRadioGroup from '../../inputs/TyRadioGroup';
import TySelectCountry from '../../inputs/TySelectCountry/TySelectCountry';
import styles from '../Components/TyAdvancedSearchCore.module.css';
import { labelsValues } from '../Libs/enums';
import {
  loadPhoneOptions,
  loadEmailOptions,
  loadPinOptions,
  loadAddressOptions,
  loadBankAccountOptions,
  loadCompanyBlockedPersonOptions,
  loadPersonNoteOption,
} from '../Libs/loadOptions';

const getAgeOptions = factoryLoadStaticOptions(Array.from({ length: 122 }, (_, i) => ({ value: i + 1, label: `${i + 1}` })));

const Basic: React.FC = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true });

  return (
    <div className={styles.basic}>
      <div className={styles.triggerContainer}>
        <div {...getToggleProps()} className={styles.trigger}>
          <h2>Základní parametry</h2>
        </div>

        <div {...getToggleProps()} className={styles.trigger}>

          <div className={classNames(
            styles.triggerIcon,
            { [styles.triggerIconRotated]: isExpanded },
          )}
          />
        </div>
      </div>

      <section {...getCollapseProps()}>
        <Protect
          auth={[
            Roles.SUPER_ADMIN,
            Roles.TYMBE_ADMIN,
            Roles.ADMIN,
            Roles.TYMBE_COORDINATOR,
          ]}
          redirect={false}
        >
          <div>
            <h3>Stav účtu</h3>
            <div className={styles.row}>
              <TyRadioGroup
                className="ty-radio-group"
                name={labelsValues.accountState.name}
              >
                <TyRadio
                  value={labelsValues.active.name}
                  label={(
                    <>
                      {labelsValues.active.label} <AccountActivityIcon iconcolor={verified} className="inline-block" />
                    </>
                  )}
                />
                <TyRadio
                  value={labelsValues.unverified_proof_of_identity.name}
                  label={(
                    <>
                      {labelsValues.unverified_proof_of_identity.label}
                      <AccountActivityIcon iconcolor={alert} className="inline-block" />

                    </>
                  )}
                />
                <TyRadio
                  value={labelsValues.unverified_account.name}
                  label={(
                    <>
                      {labelsValues.unverified_account.label}
                      <AccountVerificationIcon iconcolor={alert} className="inline-block" />
                    </>
                  )}
                />
                <TyRadio
                  value={labelsValues.ban.name}
                  label={(
                    <>
                      {labelsValues.ban.label}
                      <AccountBanIcon iconcolor={dangerColor} className="inline-block" />

                    </>
                  )}
                />
              </TyRadioGroup>
            </div>
          </div>
        </Protect>
        <div className={styles.row}>
          <div>
            <h3>Pohlaví</h3>
            <div className={styles.row}>
              <TyRadioGroup
                className="ty-radio-group"
                name={labelsValues.gender.name}
              >
                <TyRadio
                  value={labelsValues.male.name}
                  label={labelsValues.male.label}
                />
                <TyRadio
                  value={labelsValues.female.name}
                  label={labelsValues.female.label}
                />
              </TyRadioGroup>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <h3>Email</h3>
            <TyAsyncSelect
              loadOptions={loadEmailOptions}
              name={labelsValues.email.name}
              label={labelsValues.email.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
          <div>
            <h3>Telefon</h3>
            <TyAsyncSelect
              loadOptions={loadPhoneOptions}
              name={labelsValues.phone.name}
              label={labelsValues.phone.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
        </div>
      </section>
      <br />
    </div>
  );
};

const Other: React.FC = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <>
      <div className={styles.triggerContainer}>
        <div {...getToggleProps()} className={styles.trigger}>
          <h2>Ostatní parametry</h2>
        </div>

        <div {...getToggleProps()} className={styles.trigger}>

          <div className={classNames(
            styles.triggerIcon,
            { [styles.triggerIconRotated]: isExpanded },
          )}
          />

        </div>
      </div>
      <section {...getCollapseProps()}>
        <Protect
          auth={[
            Roles.SUPER_ADMIN,
            Roles.TYMBE_ADMIN,
            Roles.ADMIN,
            Roles.TYMBE_COORDINATOR,
          ]}
          redirect={false}
        >
          <div className={styles.row}>
            <div>
              <h3>Věk</h3>
              <TyAsyncSelect
                name={labelsValues.ageFrom.name}
                label={labelsValues.ageFrom.label}
                loadOptions={getAgeOptions}
                isClearable
              />
            </div>
            <div>
              <TyAsyncSelect
                name={labelsValues.ageTo.name}
                label={labelsValues.ageTo.label}
                loadOptions={getAgeOptions}
                isClearable
              />
            </div>
            <div>
              <h3>Občanství</h3>
              <TySelectCountry
                name={labelsValues.nationality.name}
                label={labelsValues.nationality.label}
                className={styles.tyInputs}
                isClearable
                isMulti
              />
            </div>
            <div>
              <h3>Rodné číslo</h3>
              <TyAsyncSelect
                loadOptions={loadPinOptions}
                name={labelsValues.socialSecurityNumber.name}
                label={labelsValues.socialSecurityNumber.label}
                className={styles.tyInputs}
                isClearable
                isMulti
              />
            </div>
          </div>
        </Protect>
        <div className={styles.row}>
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.ADMIN,
              Roles.TYMBE_COORDINATOR,
            ]}
            redirect={false}
          >
            <div>
              <h3>Adresa</h3>
              <TyAsyncSelect
                loadOptions={loadAddressOptions}
                name={labelsValues.address.name}
                label={labelsValues.address.label}
                className={styles.tyInputs}
                isClearable
                isMulti
              />
            </div>
          </Protect>
          <div>
            <h3>Blokace ve firmě</h3>
            <TyAsyncSelect
              loadOptions={loadCompanyBlockedPersonOptions}
              name={labelsValues.companyBlock.name}
              label={labelsValues.companyBlock.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
              Roles.ADMIN,
              Roles.TYMBE_COORDINATOR,
            ]}
            redirect={false}
          >
            <div>
              <h3>Číslo účtu</h3>
              <TyAsyncSelect
                loadOptions={loadBankAccountOptions}
                name={labelsValues.bankAccount.name}
                label={labelsValues.bankAccount.label}
                className={styles.tyInputs}
                isClearable
                isMulti
              />
            </div>
          </Protect>
        </div>
        <div className={styles.row}>
          <div>
            <h3>Poznámka</h3>
            <TyAsyncSelect
              loadOptions={loadPersonNoteOption}
              name={labelsValues.personNote.name}
              label={labelsValues.personNote.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <h3>Datum registrace</h3>
            <TyDateInput
              name={labelsValues.registrationDateFrom.name}
              label={labelsValues.registrationDateFrom.label}
              className={styles.tyInputs}
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.registrationDateTo.name}
              label={labelsValues.registrationDateTo.label}
              className={styles.tyInputs}
            />
          </div>

        </div>
      </section>
    </>
  );
};

export { Basic, Other };
