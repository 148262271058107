import moment, { Moment } from 'moment';
import { Options } from 'react-select';

import {
  BAD_JOB_EVALUATION_SCORE,
  DEFAULT_JOB_EVALUATION_SCORE,
  GOOD_JOB_EVALUATION_SCORE,
} from '../../../utils/constants';
import { ApplicationState, AttendanceResolution } from '../../../utils/enums';
import { Option } from '../Libs/transform';

const apiTransformationsUserShift = {

  shiftTimeFrom:
    (time?: Moment) => ({ $modify: { startTime: [moment(time).startOf('minutes').toISOString(), undefined] } }),

  shiftTimeTo:
    (time?: Moment) => ({ $modify: { startTime: [undefined, moment(time).startOf('minutes').toISOString()] } }),

  shiftDateFrom: (date?: Moment) => (
    { $modify: { startDate: [moment(date).startOf('day').toISOString(), undefined] } }
  ),

  shiftDateTo: (date?: Moment) => ({ $modify: { startDate: [undefined, moment(date).endOf('day').toISOString()] } }),

  company: (companies?: Options<Option>) => {
    if (companies?.length === 0) return undefined;
    return {
      'shift.company_id': { $in: companies?.map((c) => c.value) },
    };
  },

  branchoffice: (branchoffices?: Options<Option>) => {
    if (branchoffices?.length === 0) return undefined;
    return {
      $joinRelation: {
        shift: {
          branchoffice: true,
        },
      },
      'shift:branchoffice.parent_id': { $in: branchoffices?.map((b) => b.value) },
    };
  },

  applicationState: (state?: Option) => {
    if (!state) return undefined;
    if (state.value === 'invitation_not_accepted') {
      return {
        'application.state': { $null: true },
      };
    }
    return {
      'application.state': state.value,
    };
  },

  orderId: (orderIds?: Options<Option>) => {
    if (!orderIds) return undefined;
    return {
      'shift.id': { $in: orderIds.map((o) => o.value) },
    };
  },

  applicationId: (appId?: Options<Option>) => {
    if (!appId) return undefined;
    return {
      'application.id': { $in: appId.map((a) => a.value) },
    };
  },

  position: (positions?: Options<Option>) => {
    if (!positions) return undefined;
    return { 'shift.name': { $in: positions.map((p) => p.value) } };
  },

  attendanceId: (attendances?: Options<Option>) => {
    if (!attendances) return undefined;
    return {
      $joinRelation: {
        shift: true,
        attendance: true,
      },
      'attendance.id': { $in: attendances.map((a) => a.value) },
    };
  },

  applicationPriceFrom: (price?: Option) => {
    if (!price) return undefined;
    return {
      'application.payment_base': { $gte: price.value },
    };
  },

  applicationPriceTo: (price?: Option) => {
    if (!price) return undefined;
    return {
      'application.payment_base': { $lte: price.value },
    };
  },

  applicationCreditsFrom: (credits?: Option) => {
    if (!credits) return undefined;
    return {
      'application.credits': { $gte: credits.value },
    };
  },
  applicationCreditsTo: (credits?: Option) => {
    if (!credits) return undefined;
    return {
      'application.credits': { $lte: credits.value },
    };
  },

  internalName: (name?: Option) => {
    if (!name) return undefined;
    return {
      'shift.external_id': { $ilike: `%${name}%` },
    };
  },

  showSystemCancelled: (systemCancelled?: boolean) => {
    if (systemCancelled) return undefined;
    return {
      $or: [{
        'application.state': { $ne: ApplicationState.SYSTEM_CANCELED },
      },
      { 'application.state': { $null: true } }],
    };
  },

  score: (score?: Option) => {
    if (!score) return undefined;
    const jobEvaluationArray = [
      GOOD_JOB_EVALUATION_SCORE,
      BAD_JOB_EVALUATION_SCORE,
      DEFAULT_JOB_EVALUATION_SCORE,
    ];

    const resolutionArray = [
      AttendanceResolution.ADMIN_EXCUSED_ABSENCE,
      AttendanceResolution.EXCUSED_ABSENCE,
      AttendanceResolution.UNEXCUSED_ABSENCE,
      AttendanceResolution.CANCEL,
    ];

    const $query = {
      $joinRelation: {
        shift: true,
        attendance: true,
      },
      $or: [
        {
          ...(jobEvaluationArray.includes(Number(score.value)) && { 'attendance.job_evaluation': score.value }),
        },

        {
          ...(resolutionArray.includes(score.value as AttendanceResolution)
          && { 'attendance.resolution': score.value }),
        },
      ],

    };

    return $query;
  },

};

export default apiTransformationsUserShift;
