import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Container from '../../../../containers';
import AdminTymberLogsContainer from '../../../../containers/admin/tymber/tymberLogs';
import { selectAdminError } from '../../../../data/store/slices/admin';

const AdminTymberLogsPage = () => {
  const error = useSelector(selectAdminError);

  useEffect(() => {
    // eslint-disable-next-line
  }, [error]);
  const pageTitle = 'Logy';
  const columns = [];
  const data = [];
  const onSearch = () => {
  };
  const onFilter = () => {};

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <AdminTymberLogsContainer
        pageTitle={pageTitle}
        columns={columns}
        data={data}
        onSearch={onSearch}
        onFilter={onFilter}
      />
    </Container>
  );
};

export default AdminTymberLogsPage;
