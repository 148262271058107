import { LoginData } from '@tymbe/schema/src/login.interface';
import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate, Navigate } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { SubmitButton } from '../../../../components/buttons';
import { getValuesForPatch } from '../../../../components/forms/form-util';
import Card from '../../../../components/Layout/Card';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { RequestBody } from '../../../../types/ReactQuery';
import { PersonContactData } from '../../../../types/TymbeApi';
import { ContactType } from '../../../../utils/enums';
import { addZerosToPhone } from '../../../../utils/phoneNumber';
import UserForm, { UserFormValues } from '../components/UserForm';

const EditInternalUser = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);

  const history = useNavigate();
  const { userId } = useParams();

  const { mutateAsync: patchLogin } = useMutation(
    ['CreateLogin'],
    (req: RequestBody<LoginData>): Promise<LoginData> =>
      feathersClient.service('login').patch(req.id, req.body),
  );

  const { data } = useQuery(['FetchLoginDetail', userId], async () =>
    feathersClient.service('login').find(
      {
        query: {
          $eager: '[role, person.contact]',
          person_id: userId,
        },
      },
    ), { enabled: !!userId });

  if (!userId) {
    return <Navigate to="/administration/internal-users" />;
  }
  const login = data?.data[0];

  const personPhone = login?.person?.contact?.find(
    (contact: PersonContactData) => contact.type === ContactType.MOBILE_PHONE,
  );

  const onSubmit = async (formState: FormState<UserFormValues>) => {
    if (!userId) return;
    setFormDisabled(true);
    const values = getValuesForPatch(formState);

    const personContact: Partial<PersonContactData>[] = login?.person?.contact || [];

    if (values.phone !== undefined) {
      if (personPhone) {
        personPhone.value = addZerosToPhone(values.phone) || null;
      } else {
        personContact?.push({
          person_id: login?.person?.id,
          name: 'mobil',
          type: ContactType.MOBILE_PHONE,
          value: addZerosToPhone(values.phone) || null,
        });
      }
    }

    let person;
    if (values.person !== undefined) {
      person = {
        ...values.person,
        id: login?.person?.id,
        contact: personContact.length !== 0 ? personContact : undefined,
      };
    }

    try {
      await patchLogin({
        id: login.id,
        body: {
          role: values.role?.[0],
          person,
        } as LoginData,
      });
      SuccessAlert('Uživatel byl úspěšně upraven');
    } catch {
      ErrorAlert('Při pokusu o upravení uživatele nastala chyba');
    }

    setFormDisabled(false);
    history('/administration/internal-users');
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <div className="flex">
          <h1> Upravení uživatele </h1>
        </div>
        <Card>
          <Form<UserFormValues> name="person-form" onSubmit={onSubmit}>
            <UserForm login={login} />
            <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
          </Form>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default EditInternalUser;
