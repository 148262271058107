import { HTMLAttributes, ReactNode } from 'react';

import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

export type TableHeadProps = HTMLAttributes<HTMLTableSectionElement> & {
  cols?: ReactNode[];
};

export const TableHead = ({ cols = [], className, children }: TableHeadProps) => (
  <thead>
    {!children
      ? (
        <TableRow>
          {cols.map((col) => <TableCell className={[className, 'border-b-fg-200'].join(' ')} head>{col}</TableCell>)}
        </TableRow>
      )
      : children}
  </thead>
);
