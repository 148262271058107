import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Container from '../../../containers';
import Wrapper from 'apps/admin/src/components/wrapper';
import { PageTitle } from 'apps/admin/src/components/texts';
import AdminTable from 'apps/admin/src/components/AdminTable';
import { TableRangePicker } from 'apps/admin/src/components/inputs';
import { useQuery } from 'react-query';
import feathersClient from 'apps/admin/src/apiClient';
import { WorkTimeTableData } from '@tymbe/schema/work-time-table-data.interface';
import { WorkTimeForWeek } from '@tymbe/schema/work-time-for-week.interface';
import ReportingWeek from 'apps/admin/src/components/reportingWeek';

const AdminAdministrationReportingPage = () => {
  const pageTitle = 'Reporting';

  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const query = {
    $limit: pageSize,
    $skip: pageSize * (currentPage - 1),
    from: dateRange ? dateRange[0].toISOString() : "",
    to: dateRange ? dateRange[1].toISOString() : "",
  };

  const { data, isLoading } = useQuery(
    ['work-time', query],
    async () =>
      feathersClient
        .service('work-time')
        .find({query}),
    { enabled: !!query.from && !!query.to}
  );

  const transformWeekDataToTableWeekData = (data: WorkTimeForWeek) => {
    return {
      week_number: data.week_number,
      expected_weekly_hours: data.expected_weekly_hours,
      actual_weekly_hours: data.actual_weekly_hours,
      week_hours_percentage: Math.floor(data.actual_weekly_hours / data.expected_weekly_hours * 100 * 100) / 100,
      expected_hours_so_far: data.expected_total_weekly_hours,
      actual_hours_so_far: data.actual_total_weekly_hours,
      hours_so_far_percentage: Math.floor(data.actual_total_weekly_hours / data.expected_total_weekly_hours * 100 * 100) / 100,
      expected_hours_total: data.expected_contract_hours,
      hours_total_percentage: Math.floor(data.actual_total_weekly_hours / data.expected_contract_hours * 100 * 100) / 100,
    }
  }

  const aggrData: WorkTimeTableData[] = [];
  const originalIndexes: number[] = [];
  for (const item of data as WorkTimeForWeek[] ?? []) {
    if (originalIndexes.includes(item.person_document_id)) {
      const index = originalIndexes.indexOf(item.person_document_id);
      aggrData[index].weekData.push(transformWeekDataToTableWeekData(item));
    } else {
      originalIndexes.push(item.person_document_id);
      aggrData.push({
        person_document_id: item.person_document_id,
        person_id: item.person_id,
        person_name: item.person_name,
        company_id: item.company_id,
        company_name: item.company_name,
        year: item.year,
        weekData: [
          transformWeekDataToTableWeekData(item),
        ],
      });
    }
  }

  const weekColumns = []
  const year = aggrData[0]?.year;
  const firstWeek = aggrData.reduce((acc, curr) => Math.min(acc, curr.weekData[0].week_number), 1000);
  const lastWeek = aggrData.reduce((acc, curr) => Math.max(acc, curr.weekData[curr.weekData.length - 1].week_number), 0);
  
  for (let week = firstWeek; week <= lastWeek; week++) {
    const weekIndex = week - firstWeek; 
    const weekStart = moment(year, "YYYY").week(week).startOf('week').format('DD.MM');
    const weekEnd = moment(year, "YYYY").week(week).endOf('week').format('DD.MM');

    weekColumns.push({
      title: `T${week} (${weekStart} - ${weekEnd})`,
      dataIndex: [`weekData[${weekIndex}]`],
      align: 'left',
      render: (_id: number, row: WorkTimeTableData) => {
        if (!row.weekData[weekIndex] || row.weekData[weekIndex].expected_weekly_hours === 0) {
          return '-';
        }
        return <ReportingWeek week={row.weekData[weekIndex]} />;
      },
    })
  }

  const columns = [
    {
      title: 'Smlouva',
      dataIndex: ['person_document_id'],
      align: 'left',
      width: 100,
      render: (item: string) => item || '-',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Pracovník',
      dataIndex: ['person_name'],
      align: 'left',
      render: (_id: number, row: WorkTimeTableData) => (
        <Link
          target="_blank"
          to={{
            pathname: `/user/${row.person_id}`,
          }}
          className="ty-poppins-12"
        >
          {row.person_name}
        </Link>
      ),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Firma',
      dataIndex: ['company_name'],
      align: 'left',
      render: (_id: number, row: WorkTimeTableData) => (
        <Link
          target="_blank"
          to={{
            pathname: `/company/${row.company_id}`,
          }}
          className="ty-poppins-12"
        >
          {row.company_name}
        </Link>
      ),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    ...weekColumns,
  ];

  const onPaginationChange = (page: number, newPageSize: number) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <PageTitle>{pageTitle}</PageTitle>

        <AdminTable
          rowKey={(row: any) => row.person_document_id}
          columns={columns}
          data={{data: aggrData, total: aggrData.length, skip: pageSize * (currentPage - 1), limit: pageSize }}
          isLoading={isLoading}
          onPaginationChange={onPaginationChange}
          title={() => (
            <>
              <div className="ty-title-row">
                <TableRangePicker onDateChange={setDateRange} />
              </div>
            </>
          )}
        />
      </Wrapper>
    </Container>
  );
};

export default AdminAdministrationReportingPage;
