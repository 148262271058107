import { Perk } from '@tymbe/schema/enums';
import { PerkData } from '@tymbe/schema/perk.interface';
import classNames from 'classnames';
import { MomentInput } from 'moment';
import { ComponentProps } from 'react';

import Emoji from './Emoji';
import FillPill from './FillPill';
import PerkIcon from './PerkIcon';
import ProgressBackground from './ProgressBackground';
import TimeRange from './TimeRange';

type CalendarItemOwnProps = {
  emoji?: string | null,
  start: MomentInput,
  end: MomentInput,
  currentApplications: number,
  totalApplications: number,
  perks?: PerkData[],
  inverted?: boolean,
  className?: string,
};
type CalendarItemProps = CalendarItemOwnProps & Omit<ComponentProps<'div'>, keyof CalendarItemOwnProps>;

const CalendarItem = ({
  emoji,
  start,
  end,
  currentApplications,
  totalApplications,
  perks = [],
  inverted = false,
  className = '',
  ...rest
}: CalendarItemProps) => {
  const progress = currentApplications / totalApplications;
  return (
    <div
      className={
        classNames(
          'relative rounded-md border-[1px] overflow-hidden',
          { 'text-fg border-primary-300': !inverted && currentApplications === totalApplications },
          {
            'text-fg border-primary-300 hover:border-secondary-200 hover:bg-secondary-150':
            !inverted && currentApplications > 0 && currentApplications < totalApplications,
          },
          {
            'text-fg border-secondary-150 hover:border-secondary-200 hover:bg-secondary-150':
            !inverted && currentApplications === 0,
          },
          { 'text-bg bg-secondary-600 border-secondary-600': inverted },
          className,
        )
      }
      {...rest}
    >
      <ProgressBackground
        progress={progress}
        className={
          classNames('z-0 bg-primary-200', { 'bg-primary-800': inverted })
        }
      />
      <div
        className="relative p-1 w-full flex items-center gap-2 !rounded-md"
      >
        <Emoji className="bg-secondary-50 w-8 h-8 text-[18px] leading-[18px]" emoji={emoji} />
        <div>
          <TimeRange start={start} end={end} />
          <FillPill current={currentApplications} total={totalApplications} />
        </div>
      </div>
      {perks.length > 0 && (
        <div className="flex mx-1 py-1 items-center border-t opacity-40 border-fg border-opacity-40">
          {perks.map((perk) => <PerkIcon key={perk.id} className="w-4" perk={perk.id as Perk} />)}
        </div>
      ) }
    </div>
  );
};

export default CalendarItem;
