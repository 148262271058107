import Code from "@editorjs/code";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import ImageTool from "@editorjs/image";
import uploader from "@ajite/editorjs-image-base64";
import EditorBlockVariable from "./blockVariable";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import { UnderlineInlineTool } from "editorjs-inline-tool";
import FontSize from 'editorjs-inline-font-size-tool';
import editorjsColumns from '@calumk/editorjs-columns';
import EditorJS from '@editorjs/editorjs'
import NestedList from "@editorjs/nested-list"

export const EDITOR_TOOLS = {
  code: Code,
  header: {
    class: Header,
    tunes: ['alignmentTune'],
    inlineToolbar: true,
  },
  paragraph: {
    class: Paragraph,
    config: {
      preserveBlank: true,
    },
    inlineToolbar: true,
    tunes: ['alignmentTune'],
  },
  image: {
    class: ImageTool,
    config: {
      uploader,
    },
    tunes: ['alignmentTune'],
  },
  alignmentTune: {
    class: AlignmentTuneTool,
    config:{
      default: "left",
      blocks: {
        header: 'left',
        list: 'left'
      }
    },
  },
  columns : {
    class : editorjsColumns,
    config : {
      tools : {
        code: Code,
        header: {
          class: Header,
          tunes: ['alignmentTune'],
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          config: {
            preserveBlank: true,
          },
          inlineToolbar: true,
          tunes: ['alignmentTune'],
        },
        alignmentTune: {
          class: AlignmentTuneTool,
          config:{
            default: "left",
            blocks: {
              header: 'left',
              list: 'left'
            }
          },
        },
        image: {
          class: ImageTool,
          config: {
            uploader,
          },
          tunes: ['alignmentTune'],
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
        },
        variable: EditorBlockVariable,
        fontsize: FontSize,
        underline: UnderlineInlineTool,      
      },
      EditorJsLibrary : EditorJS,      
    }
  },
  variable: EditorBlockVariable,
  fontsize: FontSize,
  underline: UnderlineInlineTool,
  list: {
    class: NestedList,
    inlineToolbar: true,
  },
};
