import { FieldProps, TextAreaProps, useField } from 'informed';
import { useEffect } from 'react';

import TyInputError from './TyInputError';

import './TyInput.css';

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = `${scrollHeight >= 112 ? scrollHeight : 112}px`;
    }
  }, [textAreaRef, value]);
};

const TyTextArea = <Fields extends object>(props: FieldProps<TextAreaProps, string, Fields>) => {
  const {
    render,
    fieldState,
    userProps,
    fieldApi,
    ref,
  } = useField<TextAreaProps, string>({ ...props });
  const { id, label, className, placeholder = ' ', ...rest } = userProps;
  const { error, showError, maskedValue, focused, value } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  const focusedClassName = focused || value ? 'ty_input_field__focused' : '';

  useAutosizeTextArea(ref.current, value);

  return render(
    <div className={`ty_input_field ${showError && error ? 'ty_input_error' : ''} ${focusedClassName} ${className ?? ''}`}>
      <textarea
        ref={ref}
        id={id}
        className="ty_input"
        placeholder={placeholder}
        {...rest}
        value={!maskedValue ? '' : maskedValue as string}
        onChange={(e) => setValue(e.target.value, e)}
        onBlur={(e) => {
          setTouched(true, e);
          setFocused(false, e);
        }}
        onFocus={(e) => setFocused(true, e)}
        aria-invalid={showError}
        aria-describedby={`${id}-error`}
        rows={props.rows}
      />
      {label ? <label htmlFor={id}>{label}</label> : null}
      <TyInputError
        fieldState={fieldState}
        required={props.required}
        validate={props.validate}
      />
    </div>,
  );
};

export default TyTextArea;
