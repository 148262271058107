import { Form, FormState } from 'informed';
import moment from 'moment';
import Modal from 'react-modal';

import { CloseIcon } from '../../../components/icons';
import TyInput from '../../../components/inputs/TyInput';
import { TySelect } from '../../../components/inputs/TySelect';
import Spinner from '../../../components/Spinner';
import { PageTitle } from '../../../components/texts';
import styles from '../../../components/TyAdvancedSearch/Components/Layout.module.css';

type Props = {
  showModal: boolean,
  isLoading: boolean,
  setShowModal: (show: boolean) => void,
  onSubmit: (values: FormState<CSSZExportData>) => Promise<void>,
  companyCount: number,
};

export type CSSZExportData = {
  month: { label: string, value: number },
  year: number,
  batchLimit?: number,
};

const getMonths = () => moment.months('D MMMM').map((label, value) => ({ label, value }));

const ExportCSSZModal = ({ isLoading, onSubmit, showModal, setShowModal, companyCount }: Props) => {
  const now = moment().tz('europe/prague');
  const currentYear = now.year();

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="My dialog"
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        <div>
          <PageTitle>ČSSZ export</PageTitle>
        </div>
        <div>
          <button
            aria-label="close button"
            type="button"
            className={styles.close}
            onClick={() => setShowModal(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className={styles.children}>
        <Form
          onSubmit={(values: FormState<CSSZExportData>) => {
            onSubmit(values);
          }}
        >
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <h3>Měsíc</h3>
              <TySelect
                required="Povinné pole"
                name="month"
                label="Měsíc"
                options={getMonths()}
              />
              <h3>Rok</h3>
              <TyInput
                showControls
                name="year"
                label="Rok"
                type="number"
                required="Povinné pole"
                inputMode="numeric"
                pattern="^[0-9]+$"
                step="1"
                min={0}
                max={currentYear}
              />
              <h3>Nastavení XML</h3>
              <TyInput
                name="batchLimit"
                label="Max počet entit v XML"
                type="number"
                inputMode="numeric"
                pattern="^[0-9]+$"
                step="1"
                min={1}
                max={1500}
              />
            </div>
            <div className="flex flex-col ml-10">
              <h3>Počet firem: {companyCount}</h3>
              <h2>Tato akce může trvat dlouho, pro úspěšné dokončení exportu neopouštějte tuto stránku!</h2>
            </div>
          </div>

          <svg
            className="mt-8"
            width="100%"
            height="1"
            viewBox="0 0 1051 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="100%" height="1" fill="#DBDFEE" />
          </svg>
          <div className="bg-[#F3F5FD] py-8 px-4 flex justify-end">
            <div className={styles.footerRight}>
              <button
                type="button"
                className="ty-button-secondary "
                onClick={() => setShowModal(false)}
              >
                Zavřít
              </button>
              <button
                type="submit"
                className="ty-button-primary transition-transform duration-500 disabled:cursor-not-allowed disabled:opacity-70"
                disabled={isLoading}
              >
                {!isLoading ? 'Vygenerovat' : <> <p> Generuji... </p> <Spinner show className="ml-6 w-6" /> </>}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ExportCSSZModal;
