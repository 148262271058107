import { CheckboxProps, FieldProps, useField } from 'informed';

import TyInputError from './TyInputError';

import './TyInput.css';

const TyCheckbox = <Fields extends object>(props: FieldProps<CheckboxProps, boolean, Fields>) => {
  const validate = (value: boolean, values: Record<string, unknown>) => {
    if (props.required && (!value)) {
      return typeof props.required === 'string'
        ? props.required
        : 'This field is required';
    }
    return props.validate ? props.validate(value, values) : undefined;
  };
  const {
    render,
    fieldState,
    userProps,
    fieldApi,
    ref,
  } = useField<CheckboxProps, boolean>({ ...props, validate, defaultValue: props.defaultValue ?? false });
  const { id, label, className, placeholder = ' ', disabled, ...rest } = userProps;
  const { value, error, showError } = fieldState;
  const { setValue, setTouched, setFocused } = fieldApi;

  const disabledClassName = disabled ? 'ty_input_field--is-disabled' : '';

  return render(
    <div className={`ty_input_field ty_input_field--checkbox ${showError && error ? 'ty_input_error' : ''} ${disabledClassName} ${className ?? ''}`}>
      <input
        ref={ref}
        id={id}
        className="ty_input"
        placeholder={placeholder}
        {...rest}
        type="checkbox"
        checked={value}
        onChange={(e) => setValue(e.target.checked, e)}
        onBlur={(e) => { setTouched(true, e); setFocused(false, e); }}
        aria-invalid={showError}
        aria-describedby={`${id}-error`}
        disabled={disabled}
      />
      {label ? <label htmlFor={id}>{label}</label> : null}
      <TyInputError fieldState={fieldState} required={props.required} validate={props.validate} />
    </div>,
  );
};

export default TyCheckbox;
