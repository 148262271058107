import { PrimaryButton } from '../../components/buttons';
import { FixedGrid, ResponsiveGrid } from '../../components/grids';
import Illustration from '../../components/illustration';
import { LockPick } from '../../components/illustrations';
import { SimpleHeading1Text, SimpleHeading3Text, SmallText } from '../../components/texts';

const EmailChangeContainer = () => (
  <div>
    <ResponsiveGrid>
      <FixedGrid>
        <SimpleHeading1Text>Chceš změnit e-mailovou adresu?</SimpleHeading1Text>
      </FixedGrid>
    </ResponsiveGrid>
    <Illustration svg={<LockPick />} size="full" />
    <ResponsiveGrid>
      <SimpleHeading3Text>
        Pro potvrzení změny e-mailové adresy klikni na tlačítko níže{' '}
      </SimpleHeading3Text>
      <SmallText>
        Proč musíš potvrdit e-mail? Bez toho se na Tymbe brigádu nepřihlásíš.{' '}
      </SmallText>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
        <PrimaryButton>Potvrdit změnu e-mailu</PrimaryButton>
      </div>
      <div
        style={{
          padding: '10px 20px',
          border: '1px solid #DBDFEE',
          borderRadius: '10px',
        }}
      >
        <SmallText>
          S touhle registrací nemáš nic společného? Dej nám prosím vědět na
          info@tymbe.com
        </SmallText>
      </div>
    </ResponsiveGrid>
  </div>
);

export default EmailChangeContainer;
