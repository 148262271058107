/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import merge from 'lodash.merge';

import feathersClient from '../../../../apiClient';

import { mapById } from '../../../../utils';

export const CLEAR_ERROR = 'CLEAR_ERROR';

export const loadWalletThunk = createAsyncThunk('wallet/loadWallet', async () => {
  // const wallet = await feathersClient.service('wallets').get(id);
  const wallet = {
    id: 22123,
    payment_information: {
      bank_number: '4040',
      account_number: '6321 5523 8243',
    },
    assets: {
      not_claimed: {
        money: 2400,
        credits: 1000,
      },
      claimed: {
        credits: 19000,
        money: 97600,
      },
    },
    unclaimedPayments: [
      {
        company: 'Tesco Stores - Ústí nad Orlicí - COMPLETED',
        bank_number: '4040',
        account_number: '6321 5523 8243',
        amount: { credits: 500, money: 1400, currency: 'Kč', tax: 200 },
        symbols: {
          variable: 1646757,
          specific: 246855,
        },
        transfer_date: '2021-06-27',
        created_at: '2021-06-25',
        shift_start: '08:00',
        shift_end: '16:00',
      },
      {
        company: 'Tesco Stores - Ústí nad Orlicí - PENDING',
        bank_number: '4040',
        account_number: '6321 5523 8243',
        amount: { credits: 500, money: 1400, currency: 'Kč', tax: 200 },
        symbols: {
          variable: 1646757,
          specific: 246855,
        },
        transfer_date: null,
        created_at: '2021-06-25',
        shift_start: '08:00',
        shift_end: '16:00',
      },
    ],
    payments: [
      {
        company: 'Tesco Stores - Ústí nad Orlicí - COMPLETED',
        bank_number: '4040',
        account_number: '6321 5523 8243',
        amount: { credits: 500, money: 1400, currency: 'Kč', tax: 200 },
        symbols: {
          variable: 1646757,
          specific: 246855,
        },
        transfer_date: '2021-06-27',
        created_at: '2021-06-25',
        shift_start: '08:00',
        shift_end: '16:00',
      },
      {
        company: 'Tesco Stores - Ústí nad Orlicí - PENDING',
        bank_number: '4040',
        account_number: '6321 5523 8243',
        amount: { credits: 500, money: 1400, currency: 'Kč', tax: 200 },
        symbols: {
          variable: 1646757,
          specific: 246855,
        },
        transfer_date: null,
        created_at: '2021-06-25',
        shift_start: '08:00',
        shift_end: '16:00',
      },
      {
        company: 'Tesco Stores - Ústí nad Orlicí - COMPLETED',
        bank_number: '4040',
        account_number: '6321 5523 8243',
        amount: { credits: 500, money: 1400, currency: 'Kč', tax: 200 },
        symbols: {
          variable: 1646757,
          specific: 246855,
        },
        transfer_date: '2021-06-27',
        created_at: '2021-06-25',
        shift_start: '08:00',
        shift_end: '16:00',
      },
      {
        company: 'Tesco Stores - Ústí nad Orlicí - COMPLETED',
        bank_number: '4040',
        account_number: '6321 5523 8243',
        amount: { credits: 500, money: 1400, currency: 'Kč', tax: 200 },
        symbols: {
          variable: 1646757,
          specific: 246855,
        },
        transfer_date: '2021-06-27',
        created_at: '2021-06-25',
        shift_start: '08:00',
        shift_end: '16:00',
      },
    ],
  };
  return { wallet };
});

const loadWalletReducer = {
  [loadWalletThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.wallet = null;
  },
  [loadWalletThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.wallet = null;
  },
  [loadWalletThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.wallet = action.payload.wallet;
  },
};

export const loadPaymentRequestThunk = createAsyncThunk('wallet/loadPaymentRequest', async () => {
  const data = await feathersClient.service('payment-request').find({
    query: {
      $sort: {
        created_at: -1,
      },
    },
  });
  const paymentRequestById = mapById('id', data.data);
  const paymentIds = data.data.map((x) => x.id);
  return { data, paymentRequestById, paymentIds };
});

const loadPaymentRequestReducer = {
  [loadPaymentRequestThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.paymentRequests = null;
  },
  [loadPaymentRequestThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.paymentRequests = null;
  },
  [loadPaymentRequestThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.paymentRequestsData = {
      total: action.payload.data.total,
      limit: action.payload.data.limit,
      skip: action.payload.data.skip,
    };
    state.paymentRequests = merge({}, state.paymentRequests, action.payload.paymentRequestById);
    state.paymentRequestIds = [
      ...new Set([...state.paymentRequestsIds, ...action.payload.paymentIds]),
    ];
  },
};

export const createPaymentRequestThunk = createAsyncThunk(
  'wallet/createPaymentRequest',
  async (data) => feathersClient.service('payment-request').create(data),
);

const createPaymentRequestReducer = {
  [createPaymentRequestThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createPaymentRequestThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createPaymentRequestThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const loadPaymentTransactionThunk = createAsyncThunk(
  'wallet/loadPaymentTransaction',
  async () => {
    const data = await feathersClient.service('payment-transaction').find({
      query: {
        $sort: {
          created_at: -1,
        },
      },
    });
    const paymentTransactionById = mapById('id', data.data);
    const paymentIds = data.data.map((x) => x.id);
    return { data, paymentTransactionById, paymentIds };
  },
);

const loadPaymentTransactionReducer = {
  [loadPaymentTransactionThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    // state.paymentTransactions = null;
  },
  [loadPaymentTransactionThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.paymentTransactions = null;
  },
  [loadPaymentTransactionThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.paymentTransactionsData = {
      total: action.payload.data.total,
      limit: action.payload.data.limit,
      skip: action.payload.data.skip,
    };
    state.paymentTransactions = merge(
      {},
      state.paymentTransactions,
      action.payload.paymentTransactionById,
    );
    state.paymentTransactionsIds = [
      ...new Set([...state.paymentTransactionsIds, ...action.payload.paymentIds]),
    ];
  },
};
export const loadSurveyByIdThunk = createAsyncThunk('wallet/loadSurveyById', async (id) => {
  const data = await feathersClient.service('survey').find({
    query: {
      company_id: id,
      $eager: '[company.address, questions.optionGroup.questionOption]',
    },
  });
  // const survey = mapById('id', data.data);
  // console.log('Data: ', data);
  // console.log('Survey: ', survey);
  return data;
});
const loadSurveyByIdReducer = {
  [loadSurveyByIdThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.survey = null;
  },
  [loadSurveyByIdThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [loadSurveyByIdThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    [state.survey] = action.payload.data;
  },
};

export const submitSurveyAnswerThunk = createAsyncThunk(
  'wallet/submitSurveyAnswer',
  async (data) => {
    await feathersClient.service('survey-answer').create(data);
  },
);

const submitSurveyAnswerReducer = {
  [submitSurveyAnswerThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [submitSurveyAnswerThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [submitSurveyAnswerThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};
export const createCreditTransactionThunk = createAsyncThunk(
  'wallet/createCreditTransaction',
  async (data) => {
    await feathersClient.service('credit-transaction').create(data);
  },
);

const createCreditTransactionReducer = {
  [createCreditTransactionThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createCreditTransactionThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createCreditTransactionThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    loading: null,
    error: null,
    paymentRequests: null,
    paymentRequestsIds: [],
    paymentRequestsData: null,
    paymentTransactions: null,
    paymentTransactionsIds: [],
    paymentTransactionsData: null,
    survey: null,
    wallet: null,
  },
  reducers: {
    clearError: (state) => ({ ...state, error: false }),
  },
  extraReducers: {
    ...loadPaymentRequestReducer,
    ...createPaymentRequestReducer,
    ...createCreditTransactionReducer,
    ...loadPaymentTransactionReducer,
    ...loadSurveyByIdReducer,
    ...submitSurveyAnswerReducer,
    ...loadWalletReducer,
  },
});

export const selectWalletError = createSelector(
  (state) => state.wallet.error,
  (error) => {
    if (error !== null) return error;
    return undefined;
  },
);
export const selectWalletLoading = createSelector(
  (state) => state.wallet.loading,
  (loading) => loading,
);

export const selectWallet = createSelector(
  (state) => state.wallet.wallet,
  (wallet) => {
    if (wallet !== null && wallet !== undefined) return wallet;
    return undefined;
  },
);
export const selectSurvey = createSelector(
  (state) => state.wallet.survey,
  (survey) => survey,
);

export const selectPaymentRequests = createSelector(
  (state) => state.wallet.paymentRequests,
  (paymentRequests) => {
    if (paymentRequests !== null && paymentRequests !== undefined) return paymentRequests;
    return undefined;
  },
);
export const selectPaymentTransactions = createSelector(
  (state) => state.wallet.paymentTransactions,
  (paymentTransactions) => {
    if (paymentTransactions !== null && paymentTransactions !== undefined) {
      return paymentTransactions;
    }
    return undefined;
  },
);

export const selectPaymentRequestsIds = createSelector(
  (state) => state.wallet.paymentRequestsIds,
  (paymentRequestsIds) => {
    if (paymentRequestsIds !== null && paymentRequestsIds !== undefined) {
      return paymentRequestsIds;
    }
    return undefined;
  },
);
export const selectPaymentTransactionsIds = createSelector(
  (state) => state.wallet.paymentTransactionsIds,
  (paymentTransactionsIds) => {
    if (paymentTransactionsIds !== null && paymentTransactionsIds !== undefined) {
      return paymentTransactionsIds;
    }
    return undefined;
  },
);
export default walletSlice.reducer;
