import { Query } from '@feathersjs/feathers';
import { BranchofficeData } from '@tymbe/schema/branchoffice.interface';
import { BlockReasons } from '@tymbe/schema/enums';
import { Form, FormState, useFormApi } from 'informed';
import { useState } from 'react';
import Modal from 'react-modal';

import { useUser } from '../../apiClient/ApiContext';
import { CloseIcon, InfoIcon } from '../../components/icons';
import TyAsyncSelect from '../../components/inputs/TyAsyncSelect';
import TyCheckbox from '../../components/inputs/TyCheckbox';
import TySelectBranchoffice from '../../components/inputs/TySelectBranchoffice';
import TySelectCompany from '../../components/inputs/TySelectCompany';
import TyTextArea from '../../components/inputs/TyTextArea';
import { PageTitle } from '../../components/texts';
import styles from '../../components/TyAdvancedSearch/Components/Layout.module.css';
import { labelsValues } from '../../components/TyAdvancedSearch/Libs/enums';
import factoryLoadOptions from '../../components/TyAdvancedSearch/Libs/factoryLoadOptions';
import { loadTymberOptions } from '../../components/TyAdvancedSearch/Libs/loadOptions';
import { CompanyData } from '../../types/TymbeApi';
import { darkBlue } from '../../utils/colors';
import { BlockReasonDisplay, Roles } from '../../utils/enums';

type Props = {
  showModal: boolean,
  setShowModal: (show: boolean) => void
  onSubmit: (values: FormState<CreateNewBlockFormProps>) => Promise<void>
  hideTymberSelect?: boolean;
};

export interface CreateNewBlockFormProps {
  person_id: { value: number };
  company: CompanyData;
  note?: string;
  freeManShifts: boolean;
  department: BranchofficeData | null;
  branchoffice: BranchofficeData | null;
  reason: { value: { reason: BlockReasons, severity: number } };
}

const NewBlock = ({ onSubmit, showModal, setShowModal, hideTymberSelect = false }: Props) => {
  const [disableFreeManShifts, setDisableFreeManShifts] = useState(false);
  const user = useUser();
  const formApi = useFormApi<CreateNewBlockFormProps>();

  const isTymbe = user.hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);

  const loadBlockReasonOptions = factoryLoadOptions(
    'block-reasons',
    ({ search, options }) => {
      const query: Query = {
        $skip: options.length,
        $and: [
          { id: { $ilike: `%${search}%` } },
          ...!isTymbe ? [{ id: { $ne: BlockReasons.PREVENTION } }] : [],
        ],
      };
      return { query };
    },
    (v: { id: BlockReasons; severity: number }) => ({
      label: `${BlockReasonDisplay[v.id]}`,
      value: { reason: v.id, severity: v.severity },
    }),
  );

  const disableCompany = !isTymbe && user.person.company?.length && user.person.branchoffice?.length;
  const disableBranchoffice = !isTymbe && user.person.branchoffice?.length && !user.person.branchoffice?.some((brnch) => !brnch.parent_id);

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="My dialog"
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        <div>
          <PageTitle>Zablokovat Tymbery</PageTitle>
        </div>
        <div>
          <button
            aria-label="close button"
            type="button"
            className={styles.close}
            onClick={() => setShowModal(false)}
          >
            <CloseIcon />
          </button>
        </div>

      </div>
      <div className="bg-[#F3F5FD] mx-5 p-3 flex">
        <div className="pr-2 self-center">
          <InfoIcon iconcolor={darkBlue} />
        </div>
        <div>
          Pro vytvoření blokace pro celou firmu vyplňte pouze pole &quot;Firma&quot;,
          pro blokaci na konkrétní provozovnu nebo oddělení vyplňte pole &quot;Provozovna&quot;
          nebo &quot;Oddělení&quot;.
        </div>
      </div>
      <div className={styles.children}>
        <Form
          onSubmit={(values: FormState<CreateNewBlockFormProps>) => {
            onSubmit(values);
            setShowModal(false);
          }}
        >
          <div className="grid md:grid-cols-2">
            <div>
              <h3>Firma</h3>
              <TySelectCompany
                initialValue={user.person.company?.[0] || null}
                id="company_select"
                label="Firma"
                name="company"
                required="Vyberte prosím firmu"
                isDisabled={!!disableCompany}
                onChange={({ value }) => {
                  const companyData = value as CompanyData;
                  const isReadonly = companyData ? companyData.is_readonly : false;
                  const newDisableFreeManShifts = isReadonly && !user.hasRoles([Roles.SUPER_ADMIN]);

                  if (newDisableFreeManShifts) {
                    formApi.setValue('freeManShifts', true);
                  }
                  setDisableFreeManShifts(newDisableFreeManShifts);
                }}
              />
            </div>
            <div>
              <h3>Provozovna</h3>
              <TySelectBranchoffice
                initialValue={user.person.branchoffice?.find((brnch) => !brnch.parent_id) || undefined}
                id="branchoffice_select"
                label="Provozovna"
                name="branchoffice"
                isDisabled={!!disableBranchoffice}
                type="branchoffice"
                isClearable
              />
            </div>
            <div>
              <h3>Oddělení</h3>
              <TySelectBranchoffice
                initialValue={user.person.branchoffice?.find((brnch) => brnch.parent_id) || undefined}
                id="department_select"
                label="Oddělení"
                type="department"
                name="department"
                required={!!disableBranchoffice}
                isClearable
              />
            </div>
            <div>
              {!hideTymberSelect
              && (
                <><h3>Tymber</h3>
                  <TyAsyncSelect
                    loadOptions={loadTymberOptions}
                    name="person_id"
                    label={labelsValues.tymber.label}
                    className={styles.tyInputs}
                    required="Vyberte prosím Tymbera"
                  />
                </>
              )}
            </div>
            <div>
              <h3>Důvod</h3>
              <TyAsyncSelect
                id="reason_select"
                label="Důvod"
                name="reason"
                required="Vyberte prosím důvod"
                loadOptions={loadBlockReasonOptions}
              />
            </div>
          </div>

          <div>
            <div>
              <h3>Poznámka k blokaci</h3>
              <TyTextArea
                className="w-full"
                id="note"
                label="Poznámka"
                name="note"
              />
            </div>
            <div className="pt-6">
              <TyCheckbox
                disabled={disableFreeManShifts}
                id="freeManShifts_checkbox"
                label="Nahradit uvolněná místa volnou kapacitou"
                name="freeManShifts"
              />
            </div>
          </div>

          <svg className="mt-8" width="100%" height="1" viewBox="0 0 1051 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="1" fill="#DBDFEE" />
          </svg>
          <div className="bg-[#F3F5FD] py-8 px-4 flex justify-end">
            <div className={styles.footerRight}>
              <button
                type="button"
                className="ty-button-secondary "
                onClick={() => setShowModal(false)}
              >
                Zavřít
              </button>
              <button
                type="submit"
                className="ty-button-primary"
              >
                Přidat
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default NewBlock;
