import { FieldState } from 'informed';

import ValidationTooltipIcon from '../icons/ValidationTooltipIcon';

export type TyInputErrorProps<T> = {
  fieldState: FieldState<T>;
  required: unknown;
  validate: unknown;
};

const TyInputError = <T,>({ required, validate, fieldState }: TyInputErrorProps<T>) => (
  fieldState.showError || ((fieldState.touched || fieldState.value) && (required || validate))
    ? (
      <ValidationTooltipIcon
        isValid={fieldState.valid}
        errorMessage={fieldState.showError ? `${fieldState.error}` : undefined}
      />
    )
    : null
);

export default TyInputError;
