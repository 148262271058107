/* eslint-disable no-unused-vars */
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import DesktopPageHeader from '../layouts/pages/DesktopPageHeader';
import PageFooter from '../layouts/pages/PageFooter';
import Sidebar from '../layouts/pages/Sidebar';

const { Content } = Layout;
/** @type React.FC<PropTypes.InferType<Container.propTypes>> */
const Container = ({
  background = undefined,
  sidebar = false,
  sidebarExtra = false,
  desktopHeader = false,
  header = false,
  iconcolor = undefined,
  elevate = false,
  showBackButton = false,
  showNotifications = false,
  backButtonLink = undefined,
  showProfile = false,
  contentstyle = undefined,
  tabMenu = false,
  footer = false,
  footercolor = undefined,
  footerbackground = undefined,
  children,
}) => {
  const layoutStyle = {
    background: background === 'dark' ? '#EFEFEF' : '#FFF',
  };
  return (
    <Layout className="ty" style={layoutStyle}>
      {sidebar ? <Sidebar extra={sidebarExtra} /> : null}
      {desktopHeader ? <DesktopPageHeader extra={sidebarExtra} /> : null}
      <Content style={contentstyle}>{children}</Content>
      {footer ? (
        <PageFooter color={footercolor} background={footerbackground} />
      ) : null}
    </Layout>
  );
};

Container.propTypes = {
  header: PropTypes.bool,
  showBackButton: PropTypes.bool,
  backButtonLink: PropTypes.string,
  showProfile: PropTypes.bool,
  showNotifications: PropTypes.bool,
  background: PropTypes.string,
  iconcolor: PropTypes.string,
  elevate: PropTypes.bool,
  footer: PropTypes.bool,
  tabMenu: PropTypes.bool,
  sidebar: PropTypes.bool,
  sidebarExtra: PropTypes.bool,
  desktopHeader: PropTypes.bool,
  footercolor: PropTypes.string,
  footerbackground: PropTypes.string,
  // will be typed in TS
  // eslint-disable-next-line react/forbid-prop-types
  contentstyle: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node), // as you can render an array of elements
    PropTypes.node, // for a single component/element
  ]).isRequired,
};

export default Container;
