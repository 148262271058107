/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import isArray from 'lodash.isarray';
import merge from 'lodash.merge';

import feathersClient from '../../../../apiClient';
import {
  transformAdminApplication,
  transformAdminCredits,
  transformAdminOrdersTable,
  transformAdminPaymentConfirmation,
  transformAdminReportTable,
  mapById,
  transformApprovedAttendance,
  transformUnapprovedAttendance,
} from '../../../../utils';
import { ApplicationState } from '../../../../utils/enums';

// Move to COMPANY SLICE
export const loadBranchofficesByCompanyIdThunk = createAsyncThunk(
  'company/loadBranchofficesByCompanyId',
  (id) => feathersClient.service('branchoffice').find({
    query: {
      company_id: id,
    },
  }),
);

const loadBranchofficesByCompanyIdReducer = {
  [loadBranchofficesByCompanyIdThunk.pending]: (state) => {
    state.loading = true;
    state.companyError = false;
    state.branchoffices = null;
  },
  [loadBranchofficesByCompanyIdThunk.rejected]: (state, action) => {
    state.loading = false;
    state.companyError = action.error.message;
    state.branchoffices = null;
  },
  [loadBranchofficesByCompanyIdThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.branchoffices = action.payload.data;
  },
};

export const downloadApprovedAttendanceThunk = createAsyncThunk(
  'admin/downloadApprovedAttendance',
  (query = { query: { $eager: '[person.[personData, contact], shift.company]' } }) => feathersClient.service('application').download(query),
);

export const loadApplicationsThunk = createAsyncThunk(
  'admin/loadApplication',
  (query = { query: { $eager: '[person.[personData, contact], shift.company]' } }) => feathersClient.service('application').find(query),
);

const loadApplicationsReducer = {
  [loadApplicationsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadApplicationsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.applicationsData = null;
    state.applications = null;
  },
  [loadApplicationsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.applicationsData = action.payload;
    state.applications = action.payload?.data;
  },
};

export const createApplicationThunk = createAsyncThunk('admin/createApplication', (order) => feathersClient.service('application').create(order));

const createApplicationReducer = {
  [createApplicationThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createApplicationThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createApplicationThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const loadPaymentRequestThunk = createAsyncThunk(
  'admin/loadPaymentRequest',
  (query = {}) => feathersClient.service('payment-request').find({ query }),
);

const loadPaymentRequestReducer = {
  [loadPaymentRequestThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadPaymentRequestThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [loadPaymentRequestThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.paymentRequest = action.payload;
  },
};

export const loadRequestedCreditsThunk = createAsyncThunk(
  'admin/loadRequestedCreditsThunk',
  async () => {
    // const credits = await feathersClient.service('payment-request').find({
    //   query: {
    //     // $eager : '[person, person.personData'],
    //     credit_payout: true
    //   }
    // });

    const credits = {
      data: [
        {
          id: 1,
          person: {
            id: 1,
            first_name: 'Jan',
            last_name: 'Kočvara',
            personData: {
              bank_account: '0000-1111-2222-3333',
            },
          },
          attendance: {
            id: 1,
          },
          created_at: '2021-11-11',
        },
        {
          id: 2,
          person: {
            id: 2,
            first_name: 'Pepe',
            last_name: 'Italiano',
            personData: {
              bank_account: '0000-1111-2222-3333',
            },
          },
          attendance: {
            id: 2,
          },
          created_at: '2021-11-11',
        },
      ],
      total: 2,
      limit: 10,
      skip: 0,
    };

    return credits;
  },
);

const loadRequestedCreditsReducer = {
  [loadRequestedCreditsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.creditsData = null;
    state.credits = null;
  },
  [loadRequestedCreditsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.creditsData = null;
    state.credits = null;
  },
  [loadRequestedCreditsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.creditsData = action.payload;
    state.credits = action.payload.data;
  },
};

export const loadCancelledShiftsThunk = createAsyncThunk(
  'admin/loadCancelledShifts',
  (params) => feathersClient.service('application').find({
    query: {
      $eager: '[shift.[company, branchoffice], person.[personData, contact]]',
      'application.state': ApplicationState.REJECTED,
      ...params,
    },
  }),
);

const loadCancelledShiftsReducer = {
  [loadCancelledShiftsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadCancelledShiftsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.applicationsData = null;
    state.applications = null;
  },
  [loadCancelledShiftsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.applicationsData = action.payload;
    state.applications = action.payload.data;
  },
};

export const loadInvitationsThunk = createAsyncThunk('admin/loadInvitations', () => feathersClient.service('application').find({
  is_invitation: true,
  query: { $eager: '[person.[contact,personData], shift.[company,branchoffice]]' },
}));

const loadInvitationsReducer = {
  [loadInvitationsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
    state.invitationData = null;
    state.invitations = null;
  },
  [loadInvitationsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.invitationData = null;
    state.invitations = null;
  },
  [loadInvitationsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.invitationData = {
      total: action.payload.total,
      // limit: action.paginate.limit,
      skip: action.payload.skip,
    };
    state.invitations = action.payload.data;
  },
};

export const patchApplicationThunk = createAsyncThunk(
  'admin/patchApplication',
  async (data) => {
    const { id, body } = data;
    await feathersClient.service('application').patch(id, body);
  },
);

const patchApplicationReducer = {
  [patchApplicationThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [patchApplicationThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [patchApplicationThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const loadShiftThunk = createAsyncThunk('company/loadShift', async ({ id, query }) =>
  feathersClient.service('shift').get(id, {
    query,
  }));

const loadShiftReducer = {
  [loadShiftThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadShiftThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [loadShiftThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;

    const shift = action.payload;
    if (shift) {
      const { id, manShift } = shift;
      const shiftToUpdate = state.shifts.data.find((s) => s.id === id);
      if (shiftToUpdate) {
        shiftToUpdate.manShift = manShift;
        shiftToUpdate.orders_count = shift.orders_count;
        shiftToUpdate.filled_orders_count = shift.filled_orders_count;
        shiftToUpdate.invitation_orders_count = shift.invitation_orders_count;
        shiftToUpdate.unfilled_orders_count = shift.unfilled_orders_count;
      }
    }
  },
};

export const loadShiftsThunk = createAsyncThunk('company/shifts', (query) => feathersClient.service('shift').find(query));

const loadShiftsReducer = {
  [loadShiftsThunk.pending]: (state) => {
    state.loading = true;
    state.companyError = false;
  },
  [loadShiftsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.companyError = action.error.message;
    state.shifts = null;
  },
  [loadShiftsThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.shifts = action.payload;
  },
};

export const loadShiftTemplatesThunk = createAsyncThunk('admin/shift_template', (query) => feathersClient.service('shift-template').find(query));

const loadShiftTemplatesReducer = {
  [loadShiftTemplatesThunk.pending]: (state) => {
    state.loading = true;
    state.companyError = false;
    state.shiftTemplates = null;
  },
  [loadShiftTemplatesThunk.rejected]: (state, action) => {
    state.loading = false;
    state.companyError = action.error.message;
    state.shiftTemplates = null;
  },
  [loadShiftTemplatesThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.shiftTemplates = action.payload.data;
  },
};

// ADMIN

export const loadCompanyThunk = createAsyncThunk('admin/company', () => feathersClient.service('company').find());

const loadCompanyReducer = {
  [loadCompanyThunk.pending]: (state) => {
    state.loading = true;
    state.companyError = false;
  },
  [loadCompanyThunk.rejected]: (state, action) => {
    state.loading = false;
    state.companyError = action.error.message;
  },
  [loadCompanyThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.companies = action.payload.data;
  },
};

export const loadBranchofficesThunk = createAsyncThunk('admin/branchoffice', () => feathersClient.service('branchoffice').find());

const loadBranchofficesReducer = {
  [loadBranchofficesThunk.pending]: (state) => {
    state.loading = true;
    state.companyError = false;
    state.branchoffices = null;
  },
  [loadBranchofficesThunk.rejected]: (state, action) => {
    state.loading = false;
    state.companyError = action.error.message;
    state.branchoffices = null;
  },
  [loadBranchofficesThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.branchoffices = action.payload.data;
  },
};

export const loadAttendanceThunk = createAsyncThunk('admin/attendance', (params) => feathersClient.service('attendance').find({
  query: {
    $sort: {
      'attendance.created_at': -1,
    },
    ...params,
  },
}));

const loadAttendanceReducer = {
  [loadAttendanceThunk.pending]: (state) => {
    state.loading = true;
    state.companyError = false;
  },
  [loadAttendanceThunk.rejected]: (state, action) => {
    state.loading = false;
    state.companyError = action.error.message;
    state.attendance = null;
  },
  [loadAttendanceThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.companyError = false;
    state.attendance = action.payload.data;
    state.attendanceData = action.payload;
  },
};

export const createAttendanceThunk = createAsyncThunk(
  'admin/createAttendance',
  async (data) => {
    const result = await feathersClient.service('attendance').create(data);

    const attendanceById = mapById('id', isArray(result) ? result : [result]);

    return { attendanceById };
  },
);

const createAttendanceReducer = {
  [createAttendanceThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createAttendanceThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createAttendanceThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.attendanceByID = merge({}, state.attendanceByID, action.payload.attendanceById);
  },
};

export const patchAttendanceThunk = createAsyncThunk(
  'admin/patchAttendance',
  async (data) => {
    const { id, body } = data;

    const result = await feathersClient.service('attendance').patch(id, body);

    const attendanceById = mapById('id', isArray(result) ? result : [result]);

    return { attendanceById };
  },
);

const patchAttendanceReducer = {
  [patchAttendanceThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [patchAttendanceThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [patchAttendanceThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.attendanceByID = merge({}, state.attendanceByID, action.payload.attendanceById);
  },
};

export const loadFilteredUsersThunk = createAsyncThunk('admin/loadFilteredUsers', (query) => feathersClient.service('person').find({
  query,
}));

const loadFilteredUsersReducer = {
  [loadFilteredUsersThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadFilteredUsersThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.filteredUserData = null;
    state.filteredUsers = null;
  },
  [loadFilteredUsersThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.filteredUserData = {
      total: action.payload.total,
      limit: action.payload.limit,
      skip: action.payload.skip,
    };
    state.filteredUsers = action.payload.data;
  },
};

export const loadUsersThunk = createAsyncThunk('admin/loadUsers', (params) => feathersClient.service('person').find({
  query: { $eager: '[login, personData]', ...params },
}));

const loadUsersReducer = {
  [loadUsersThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [loadUsersThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
    state.userData = null;
    state.users = null;
  },
  [loadUsersThunk.fulfilled]: (state, action) => {
    state.loading = false;
    state.error = false;
    state.userData = action.payload;
    state.users = action.payload.data;
  },
};

export const deactivateUserThunk = createAsyncThunk('admin/deactivateUser', (id) => feathersClient.service('users').patch(id, { isActive: false }));

const deactivateUserReducer = {
  [deactivateUserThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [deactivateUserThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [deactivateUserThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const createOrderThunk = createAsyncThunk('admin/createOrder', (order) => feathersClient.service('shift').create(order));

const createOrderReducer = {
  [createOrderThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createOrderThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createOrderThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const patchOrderManShiftsThunk = createAsyncThunk(
  'admin/patchOrderManShiftsThunk',
  (shift) => feathersClient
    .service('shift')
    .patch(shift.id, { manShift: shift.manShift }),
);

const patchOrderManShiftsReducer = {
  [patchOrderManShiftsThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [patchOrderManShiftsThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [patchOrderManShiftsThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const reactivateUserThunk = createAsyncThunk('admin/reactivateUser', (id) => feathersClient.service('users').patch(id, { isActive: true }));

const reactivateUserReducer = {
  [reactivateUserThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [reactivateUserThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [reactivateUserThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const deverifyUserThunk = createAsyncThunk('admin/deverifyUser', (id) => feathersClient.service('users').patch(id, { isVerified: false }));

const deverifyUserReducer = {
  [deverifyUserThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [deverifyUserThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [deverifyUserThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const reverifyUserThunk = createAsyncThunk('admin/reverifyUser', (id) => feathersClient.service('users').patch(id, { isVerified: true }));

const reverifyUserReducer = {
  [reverifyUserThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [reverifyUserThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [reverifyUserThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const resetUserPasswordThunk = createAsyncThunk(
  'admin/reverifyUser',
  async (data) => {
    const { _id, password } = data;
    await feathersClient.service('users').patch(_id, { password });
  },
);

const resetUserPasswordReducer = {
  [resetUserPasswordThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [resetUserPasswordThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [resetUserPasswordThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const createClaimShiftThunk = createAsyncThunk('admin/createClaimShift', (data) => feathersClient.service('claim-shift').create(data));

const createClaimShiftReducer = {
  [createClaimShiftThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createClaimShiftThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createClaimShiftThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const createPaymentTransactionThunk = createAsyncThunk(
  'admin/createPaymentTransaction',
  (data) => feathersClient.service('payment-transaction').create(data),
);

const createPaymentTransactionReducer = {
  [createPaymentTransactionThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [createPaymentTransactionThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [createPaymentTransactionThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

export const patchPaymentTransactionThunk = createAsyncThunk(
  'admin/patchPaymentTransactionThunk',
  (data) => feathersClient
    .service('payment-transaction')
    .patch(data.id, data.paymentTransaction),
);

const patchPaymentTransactionReducer = {
  [patchPaymentTransactionThunk.pending]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [patchPaymentTransactionThunk.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.error.message;
  },
  [patchPaymentTransactionThunk.fulfilled]: (state) => {
    state.loading = false;
    state.error = false;
  },
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    loading: null,
    error: false,
    companyError: null,
    userData: null,
    users: null,
    companies: null,
    branchoffices: null,
    shiftTemplates: null,
    applications: null,
    applicationsData: null,
    invitations: null,
    invitationData: null,
    creditsData: null,
    credits: null,
    applicationsByID: null,
    attendance: null,
    attendanceData: null,
    attendanceByID: null,
    filteredUsers: null,
    filteredUserData: null,
    paymentRequest: null,
  },
  reducers: {},
  extraReducers: {
    ...deactivateUserReducer,
    ...deverifyUserReducer,
    ...createApplicationReducer,
    ...loadApplicationsReducer,
    ...patchApplicationReducer,
    ...loadAttendanceReducer,
    ...loadBranchofficesReducer,
    ...loadCompanyReducer,
    ...loadShiftTemplatesReducer,
    ...loadUsersReducer,
    ...loadFilteredUsersReducer,
    ...patchOrderManShiftsReducer,
    ...reactivateUserReducer,
    ...resetUserPasswordReducer,
    ...reverifyUserReducer,
    ...loadShiftReducer,
    ...loadPaymentRequestReducer,
    ...createPaymentTransactionReducer,
    ...patchPaymentTransactionReducer,
    // Company
    ...createAttendanceReducer,
    ...patchAttendanceReducer,
    ...createOrderReducer,
    ...createClaimShiftReducer,
    ...loadBranchofficesByCompanyIdReducer,
    ...loadInvitationsReducer,
    ...loadCancelledShiftsReducer,
    ...loadShiftsReducer,
    ...loadRequestedCreditsReducer,
  },
});

export const selectAdminLoading = createSelector(
  (state) => state.admin.loading,
  (loading) => {
    if (loading !== null) return loading;
    return undefined;
  },
);

export const selectAdminError = createSelector(
  (state) => state.admin.error,
  (error) => {
    if (error !== null) return error;
    return undefined;
  },
);

export const selectUserData = createSelector(
  (state) => state.admin.userData,
  (userData) => {
    if (userData !== null) {
      return userData;
    }
    return undefined;
  },
);

export const selectBranchoffices = createSelector(
  (state) => state.admin.branchoffices,
  (branchoffices) => branchoffices || [],
);

export const selectShiftTemplates = createSelector(
  (state) => state.admin.shiftTemplates,
  (shiftTemplates) => shiftTemplates || [],
);

export const selectShifts = createSelector(
  (state) => state.admin.shifts,
  (shifts) => shifts,
);

export const selectOrdersTableData = createSelector(
  (state) => state.admin.shifts,
  (shifts) => {
    if (shifts !== null) {
      return { ...shifts, data: transformAdminOrdersTable(shifts?.data || []) };
    }
    return undefined;
  },
);

export const selectCompanies = createSelector(
  (state) => state.admin.companies,
  (companies) => {
    if (companies !== null) {
      return companies;
    }
    return undefined;
  },
);

export const selectApplications = createSelector(
  (state) => state.admin.applicationsData,
  (applicationsData) => ({
    ...applicationsData,
    data: transformAdminApplication(applicationsData?.data || []),
  }),
);

export const selectInvitations = createSelector(
  (state) => state.admin.invitations,
  (invitations) => transformAdminApplication(invitations || []),
);

export const selectCancelledShifts = createSelector(
  (state) => state.admin.applicationsData,
  (applicationsData) => ({
    ...applicationsData,
    data: transformAdminApplication(applicationsData?.data || []),
  }),
);

export const selectRequestedCredits = createSelector(
  (state) => state.admin.creditsData,
  (creditsData) => ({ ...creditsData, data: transformAdminCredits(creditsData?.data || []) }),
);

export const selectAttendance = createSelector(
  (state) => state.admin.attendance,
  (attendance) => attendance || [],
);
export const selectPaymentRequestForPaymentConfirmation = createSelector(
  (state) => state.admin.paymentRequest,
  (paymentRequest) => ({
    ...paymentRequest,
    data: transformAdminPaymentConfirmation(paymentRequest?.data || []),
  }),
);
export const selectUnapprovedAttendance = createSelector(
  (state) => state.admin.applications,
  (applications) => transformUnapprovedAttendance(applications || []),
);

export const selectApprovedAttendance = createSelector(
  (state) => state.admin.applicationsData,
  (applicationsData) => ({
    ...applicationsData,
    data: transformApprovedAttendance(applicationsData?.data || []),
  }),
);
export const selectApplicationsByID = createSelector(
  (state) => state.admin.applicationsByID,
  (applicationsByID) => applicationsByID || [],
);
export const selectAttendanceByID = createSelector(
  (state) => state.admin.attendanceByID,
  (attendanceByID) => attendanceByID || [],
);
export const selectReportTableData = createSelector(
  (state) => state.admin.applicationsData,
  (applicationsData) => ({
    ...applicationsData,
    data: transformAdminReportTable(applicationsData?.data || []),
  }),
);
export const selectFilteredUserData = createSelector(
  (state) => state.admin.filteredUsers,
  (filteredUsers) => {
    if (filteredUsers !== null) {
      return filteredUsers;
    }
    return undefined;
  },
);
export const selectPaymentRequest = createSelector(
  (state) => state.admin.paymentRequest,
  (paymentRequest) => paymentRequest || [],
);

export default adminSlice.reducer;
