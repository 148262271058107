import { SVGProps } from 'react';

type PhoneIconProps = Omit<SVGProps<SVGSVGElement>, 'width' | 'height' | 'viewBox' | 'xmlns'>;
const PhoneIcon = (props: PhoneIconProps) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.2217 15.2681L16.6817 14.9781C16.0717 14.9081 15.4717 15.1181 15.0417 15.5481L13.2017 17.3881C10.3717 15.9481 8.05174 13.6381 6.61174 10.7981L8.46174 8.94807C8.89174 8.51807 9.10174 7.91807 9.03174 7.30807L8.74174 4.78807C8.62174 3.77807 7.77174 3.01807 6.75174 3.01807H5.02174C3.89174 3.01807 2.95174 3.95807 3.02174 5.08807C3.55174 13.6281 10.3817 20.4481 18.9117 20.9781C20.0417 21.0481 20.9817 20.1081 20.9817 18.9781V17.2481C20.9917 16.2381 20.2317 15.3881 19.2217 15.2681Z" />
  </svg>
);

export default PhoneIcon;
