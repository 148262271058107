import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const removeAttendanceThunk = createAsyncThunk(
  'admin/removeApplication',
  (id) => feathersClient.service('attendance').remove(id),
);

const removeAttendanceReducer = {
  [removeAttendanceThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [removeAttendanceThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
  }),
  [removeAttendanceThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
  }),
};

export const loadAttendancesThunk = createAsyncThunk(
  'admin/loadAttendance',
  (params) => feathersClient.service('attendance').find(params),
);

const loadAttendancesReducer = {
  [loadAttendancesThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [loadAttendancesThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [loadAttendancesThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload,
  }),
};

const attendanceSlice = createSlice({
  name: 'attendance',
  initialState: {
    loading: null,
    error: false,
    data: null,
  },
  reducers: {
    updateRecord(state, action) {
      const data = state.data.data.map((x) => (x.id !== action.payload.id ? x : action.payload.record));
      return { ...state, data: { ...state.data, data } };
    },
  },
  extraReducers: {
    ...removeAttendanceReducer,
    ...loadAttendancesReducer,
  },
});

export default attendanceSlice.reducer;
export const { actions } = attendanceSlice;

export const selectData = (state) => state.data;
export const selectAttendanceLoading = (state) => state.loading;
