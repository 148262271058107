import moment, { MomentInput } from 'moment';
import { ComponentProps } from 'react';

type TimeRangeOwnProps = {
  start: MomentInput
  end: MomentInput
};

type TimeRangeProps = TimeRangeOwnProps & Omit<ComponentProps<'div'>, keyof TimeRangeOwnProps>;

const TimeRange = ({ start, end, ...rest }: TimeRangeProps) => (
  <div className="text-xs font-medium" {...rest}>
    {moment(start).format('H:mm')} - {moment(end).format('H:mm')}
  </div>
);

export default TimeRange;
