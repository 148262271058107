import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import feathersClient from '.';
import Spinner from '../utils/spinner';

export interface ProtectProps {
  children: React.ReactNode;
  auth: true | (string | string[])[];
  redirect?: boolean;
}

const Protect: React.FC<ProtectProps> = ({ children, auth, redirect = true }: ProtectProps) => {
  const navigator = useNavigate();
  const location = useLocation();

  const { isFetching, isError, data: { user } = {} } = useQuery(
    ['reAuthenticate'],
    async () => feathersClient.reAuthenticate(),

    { retry: false, staleTime: 10000 },
  );

  const [authorized, setAuthorized] = useState(false);
  const authenticated = !!user && !isError;

  useEffect(() => {
    if (isFetching) return;
    let newAuthorized = authorized;
    if (Array.isArray(auth) && authenticated) {
      const userRoles = user?.role.map((role) => role.slug);
      newAuthorized = auth.some((role) => {
        const roles = Array.isArray(role) ? role : [role];
        return roles.every((r) => userRoles?.includes(r));
      });
      setAuthorized(newAuthorized);
    }

    if (!redirect) return;
    if (!authenticated || !newAuthorized) navigator(`/signin?redirect=${location.pathname}${location.search}`);
  }, [isFetching, redirect, authenticated, authorized, navigator, auth, user]);

  if (redirect) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{authorized && authenticated ? children : <Spinner />}</>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{authorized && authenticated ? children : null}</>;
};

export default Protect;
