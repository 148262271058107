import { OrdersTableCategory } from './OrderTableExpandedCategories.utils';
import OrderTableExpandedRow from '../../../pages-legacy/company/orders/OrderTableExpandedRow';

interface OrdersTableExpandedCategoryProps {
  category: OrdersTableCategory,
  onChange: () => void,
}

const renderDetailText = (text: string, color: string) => (
  <div className={`ty-poppins text-sm ${color}`}>
    {text}
  </div>
);

const OrdersTableExpandedCategory = ({ category, onChange }: OrdersTableExpandedCategoryProps) => (
  <div className="flex flex-col flex-grow" key={`expandedCategory_${category.title}`}>
    {renderDetailText(category.title, category.titleColor)}
    <div className="flex flex-col">
      {category.data?.map((application) => (
        <OrderTableExpandedRow
          key={application.id}
          application={application}
          onStateChanged={onChange}
        />
      ))}
    </div>
  </div>
);

export default OrdersTableExpandedCategory;
