import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { UAFlagIcon, CZFlagIcon } from './icons';
import LocaleContext from '../contexts/LocaleContext';

const LocaleSwitcher: React.FC = () => {
  const intl = useIntl();
  const { setLocale } = useContext(LocaleContext);

  const { locale } = intl;

  const switchLang = () => {
    const newLocale = locale === 'cs' ? 'ua' : 'cs';
    setLocale(newLocale);
  };

  return (
    <button
      type="button"
      className="
      flex
      relative
        items-center
        px-2.5 mx-2.5 text-lg font-extrabold lowercase text-body-color no-underline outline-none
        border-none bg-transparent cursor-pointer
        hover:text-secondary-300 active:text-secondary focus:outline-none"
      onClick={switchLang}
    >
      {
        locale === 'cs'
          ? <><UAFlagIcon />&nbsp;українська</>
          : <><CZFlagIcon />&nbsp;česky</>
      }
    </button>
  );
};

export default LocaleSwitcher;
