import { CompanyProp } from '@tymbe/schema/company-blocked-user.interface';
import { useFieldApi, useFormState } from 'informed';
import { ComponentProps } from 'react';

import TySelectPerson from '../../../../components/inputs/TySelectPerson';
import { PersonData } from '../../../../types/TymbeApi';

type ShiftCreatePersonInviteOwnProps = {
  setPersonToInvite: (value: PersonData[]) => void,
  company: CompanyProp,
};

type ShiftCreatePersonInviteProps =
  ShiftCreatePersonInviteOwnProps & Omit<ComponentProps<'div'>, keyof ShiftCreatePersonInviteOwnProps>;

const ShiftCreatePersonInvite = ({
  setPersonToInvite,
  company,
  ...rest
}: ShiftCreatePersonInviteProps) => {
  const { setValue } = useFieldApi('personSelect');
  const { values } = useFormState<{ personSelect: PersonData[] }>();
  return (
    <div {...rest} className="p-6">
      <div className="font-medium text-xs text-secondary-500">Pracovníci</div>
      <TySelectPerson
        name="personSelect"
        placeholder="Jméno, email, ID"
        className="cal-person-select mt-0.5 mb-6 max-w-[288px] text-fg-900"
        isMulti
        onChange={(value) => {
          if (!Array.isArray(value.value)) setPersonToInvite([value.value]);
          else { setPersonToInvite(value.value); }
        }}
        filter={{ role: 'tymber' }}
        company={company}
      />
      <button
        type="submit"
        onClick={() => {
          setValue([]);
          setPersonToInvite([]);
        }}
        disabled={!values.personSelect || !values.personSelect.length}
        className="py-2 px-3 bg-secondary-600 text-bg text-xs font-semibold rounded-full disabled:bg-secondary-100 disabled:text-secondary-400"
      >Vyčistit
      </button>
    </div>
  );
};

export default ShiftCreatePersonInvite;
