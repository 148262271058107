import { Form } from 'informed';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import defaultPageSizeOptions from './table.utils';
import { SecondaryButton } from '../buttons';
import { TySelect } from '../inputs/TySelect';

export type TablePaginationProps = {
  rowsCount?: number;
  onChangePage: (pageStart: number, pageEnd: number, pageSize: number) => void;
  pageSizeOptions?: { value: number, label: string }[];
};

export const TablePagination = ({
  rowsCount = 0,
  onChangePage,
  pageSizeOptions = defaultPageSizeOptions,
}: TablePaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : pageSizeOptions[0].value;
  const visiblePage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  useEffect(() => {
    if (rowsCount > 0) {
      const pageStart = (visiblePage - 1) * pageSize;
      const pageEnd = pageStart + pageSize;
      const effectivePageEnd = pageEnd > rowsCount ? rowsCount : pageEnd;
      onChangePage(pageStart, effectivePageEnd, pageSize);
    }
  }, [onChangePage, rowsCount, visiblePage, pageSize]);

  const setVisiblePage = (page: number) => {
    setSearchParams((prev) => {
      prev.set('page', page.toString());
      return prev;
    });
  };

  const setPageSize = (size: number) => {
    setSearchParams((prev) => {
      prev.set('pageSize', size.toString());
      return prev;
    });
  };

  const setPrevPage = (currentPage: number) => {
    if (currentPage > 1) {
      setVisiblePage(currentPage - 1);
    }
  };

  const setNextPage = (currentPage: number, totalPages: number) => {
    if (currentPage < totalPages) {
      setVisiblePage(currentPage + 1);
    }
  };

  const changePagination = (option: { value: { value: number } }, prevPageSize: number, currentPage: number) => {
    const newPageSize = option.value.value;

    // Also update the page to keep the records on the page
    const pageStart = (currentPage - 1) * prevPageSize;
    const newPage = Math.floor(pageStart / newPageSize) + 1;
    setVisiblePage(newPage);

    setPageSize(newPageSize);
  };

  // first button - always visible
  const renderPrevPageButton = (currentPage: number) => (
    <SecondaryButton
      className="mr-1"
      disabled={currentPage === 1}
      onClick={() => setPrevPage(currentPage)}
    >
      {'<'}
    </SecondaryButton>
  );

  // first button - always visible
  const renderFirstPageButton = (currentPage: number) => (
    <SecondaryButton
      className={currentPage === 1
        ? 'ty-button-secondary mr-1 !text-[#b3ca1f]'
        : 'ty-button-secondary mr-1'}
      onClick={() => setVisiblePage(1)}
    >
      1
    </SecondaryButton>
  );

  // more previous pages button
  const renderPreviousPagesButton = (currentPage: number) => (
    <SecondaryButton
      className="mr-1"
      onClick={() => setVisiblePage(Math.max(currentPage - 5, 1))}
    >
      ...
    </SecondaryButton>
  );

  // numbered buttons
  const renderNumberedPageButtons = (currentPage: number, totalPages: number) => {
    const pageNumberButtons = [];
    let buttonsStart = currentPage - 2;
    let buttonsEnd = currentPage + 2;
    if (currentPage < 5) {
      buttonsStart = 2;
      buttonsEnd = Math.min(5, totalPages - 1);
    } else if (currentPage > totalPages - 3) {
      buttonsStart = Math.max(2, totalPages - 5);
      buttonsEnd = totalPages - 1;
    }
    for (let i = buttonsStart; i <= buttonsEnd; i += 1) {
      pageNumberButtons.push(
        <SecondaryButton
          key={i}
          className={currentPage === i
            ? 'ty-button-secondary mr-1 !text-[#b3ca1f]'
            : 'ty-button-secondary mr-1'}
          onClick={() => setVisiblePage(i)}
        >
          {i}
        </SecondaryButton>,
      );
    }
    return pageNumberButtons;
  };

  // more next pages button
  const renderMorePagesButton = (currentPage: number, totalPages: number) => (
    <SecondaryButton
      className="mr-1"
      onClick={() => setVisiblePage(Math.min(currentPage + 5, totalPages))}
    >
      ...
    </SecondaryButton>
  );

  // last button - always visible
  const renderLastPageButton = (currentPage: number, totalPages: number) => (
    <SecondaryButton
      className={currentPage === totalPages
        ? 'ty-button-secondary mr-1 !text-[#b3ca1f]'
        : 'ty-button-secondary mr-1'}
      onClick={() => setVisiblePage(totalPages)}
    >
      {totalPages}
    </SecondaryButton>
  );

  // more next pages button
  const renderNextPageButton = (currentPage: number, rows: number, size: number, pageEnd: number) => (
    <SecondaryButton
      className="mr-1"
      disabled={pageEnd === rows}
      onClick={() => setNextPage(currentPage, rows / size)}
    >
      {'>'}
    </SecondaryButton>
  );

  const pageStart = (visiblePage - 1) * pageSize;
  const pageEnd = pageStart + pageSize;
  const effectivePageEnd = pageEnd > rowsCount ? rowsCount : pageEnd;
  const pagesCount = Math.ceil(rowsCount / pageSize);
  const initialPageSize = pageSizeOptions.find((o) => o.value === pageSize) ?? pageSizeOptions[0];

  return (
    <div className="mt-4 flex justify-end w-full">
      <span className="my-auto mr-4 ml-0">
        Zobrazuji {rowsCount !== 0 ? pageStart + 1 : 0} až {effectivePageEnd} z celkem {rowsCount} záznamů
      </span>
      <span className="flex my-auto mr-4 ml-0">
        {renderPrevPageButton(visiblePage)}
        {renderFirstPageButton(visiblePage)}
        {pagesCount > 7 && visiblePage > 4 && renderPreviousPagesButton(visiblePage)}
        {renderNumberedPageButtons(visiblePage, pagesCount)}
        {pagesCount > 7 && visiblePage < pagesCount - 3 && renderMorePagesButton(visiblePage, pagesCount)}
        {pagesCount > 1 && renderLastPageButton(visiblePage, pagesCount)}
        {renderNextPageButton(visiblePage, rowsCount, pageSize, effectivePageEnd)}
      </span>
      <Form>
        <TySelect
          initialValue={initialPageSize}
          name="monthly_dpp_page_size"
          options={pageSizeOptions}
          onChange={(newValue) => changePagination(newValue, pageSize, visiblePage)}
        />
      </Form>
    </div>
  );
};
