import { Query } from '@feathersjs/feathers';
import { CompanyData } from '@tymbe/schema/company.interface';
import { FormState } from 'informed';
import Tooltip from 'rc-tooltip';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import ExportCSSZModal, { CSSZExportData } from './components/ExportCSSZModal';
import { loadCompanyThunk, selectCompanies, selectCompanyListPagination } from './slice';
import feathersClient from '../../apiClient';
import Protect from '../../apiClient/Protect';
import AdminTable from '../../components/AdminTable';
import { ErrorAlert, SuccessAlert } from '../../components/alerts';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { AccountActivityIcon, AccountBanIcon, AccountVerificationIcon } from '../../components/icons';
import SearchBox from '../../components/search/SearchBox';
import { TableHeaderControls } from '../../components/tableHeaders';
import HoverRowEditDeleteButtons from '../../components/tableRenders/HoverRowEditDeleteButtons';
import { PageTitle } from '../../components/texts';
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import { selectAdminError, selectAdminLoading } from '../../data/store/slices/admin';
import useURLParamsHandler from '../../hooks/UrlParamsHandler/useURLParamsHandler';
import { dangerColor, unverified, verified } from '../../utils/colors';
import downloadBlobResponse from '../../utils/downloadBlobResponse';
import { CompanyState, Roles } from '../../utils/enums';

export type TableSorterType = {
  column?: unknown,
  columnKey?: string,
  field?: string,
  order?: 'ascend' | 'descend',
};

type GenerateMultipleCsszReportData = {
  company_ids: number[],
  month: number,
  year: number,
};

const COMPANY_LIST_DEFAULT_SORT = { created_at: -1 };

const CompanyListPage = () => {
  const pageTitle = 'Seznam firem';
  const dispatch = useDispatch();
  const history = useNavigate();

  const [showSearch, setShowSearch] = useState(false);
  const loading = useSelector(selectAdminLoading);
  const [{ allSearchParams }, setUrlParamsHandler] = useURLParamsHandler();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [showCSSZeModal, setShowCSSZModal] = useState(false);

  const getSearchQuery = (value?: string) => {
    if (!value || '') return null;
    let searchQuery = null;
    if (+value) {
      searchQuery = { $or: [{ id: +value }, { cin: +value }], $skip: 0 };
    } else {
      const quickFilter = value.split(' ');
      searchQuery = {
        $modify: { quickFilter },
      };
    }
    return searchQuery;
  };

  const error = useSelector(selectAdminError);
  const [searchParams, setSearchParams] = useState<Query | null>(getSearchQuery(allSearchParams.search) || null);

  const [companyListCurrentPage, setCompanyListCurrentPage] = useState(allSearchParams.page || 1);
  const [companyListPageSize, setCompanyListPageSize] = useState(allSearchParams.pageSize || 10);
  const [sort, setSort] = useState<object>(allSearchParams.sort || COMPANY_LIST_DEFAULT_SORT);

  const { mutateAsync: requestGenerateCsszReport, isLoading: isGenerateCsszLoading } = useMutation(
    ['generate-multiple-cssz-report'],
    async (data: GenerateMultipleCsszReportData) => feathersClient
      .service('/cssz/generate-multiple-report')
      .create(data, { connection: { responseType: 'blob' } }),
  );

  const renderCompanyState = (comp_state: string) => {
    switch (comp_state) {
      case CompanyState.BANNED:
        return (
          <Tooltip placement="right" trigger={['hover']} overlay="Zablokovaný účet">
            <div><AccountBanIcon iconcolor={dangerColor} /></div>
          </Tooltip>
        );
      case CompanyState.ACTIVE:
        return (
          <Tooltip placement="right" trigger={['hover']} overlay="Aktivní účet">
            <div><AccountActivityIcon iconcolor={verified} /></div>
          </Tooltip>
        );
      case CompanyState.PENDING:
        return (
          <Tooltip placement="right" trigger={['hover']} overlay="Neověřený účet">
            <div><AccountVerificationIcon iconcolor={unverified} /></div>
          </Tooltip>
        );
      default:
        return <AccountVerificationIcon iconcolor={unverified} />;
    }
  };

  const onSearch = (value: string) => {
    setCompanyListCurrentPage(1);
    if (!value) {
      setSearchParams({});
      setUrlParamsHandler({ page: 1, search: '' });
    } else {
      setSearchParams(getSearchQuery(value));
      setUrlParamsHandler({ page: 1, search: value });
    }
  };

  const onCompanyListPagination = (newPage: number, newPageSize: number) => {
    setUrlParamsHandler({ page: newPage });
    setUrlParamsHandler({ pageSize: newPageSize });
    setCompanyListPageSize(newPageSize);
    setCompanyListCurrentPage(newPage);
  };

  const renderCompanyName = (company: CompanyData) => (
    <div className="flex align-center">
      {company.name}
    </div>
  );

  const columns = [
    {
      title: 'Stav',
      dataIndex: 'state',
      render: (is_verified: string) => renderCompanyState(is_verified),
      align: 'left',
      width: '30px',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      onCell: (record: CompanyData) => ({
        onClick: () => { history({ pathname: `/company/${record.id}` }); },
      }),
    },
    {
      title: 'Název',
      dataIndex: 'name',
      align: 'left',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (item: unknown, row: CompanyData) => renderCompanyName(row),
      onCell: (record: CompanyData) => ({
        onClick: () => { history({ pathname: `/company/${record.id}` }); },
      }),
    },
    {
      title: 'IČO',
      dataIndex: 'cin',
      align: 'left',
      sorter: true,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      onCell: (record: CompanyData) => ({
        onClick: () => { history({ pathname: `/company/${record.id}` }); },
      }),
    },
    {
      title: 'Adresa',
      align: 'left',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      className: 'd-flex justify-content-between align-center',
      render: (record: CompanyData) => (
        <>
          {record.address?.addressline1} &nbsp;
          {record.address?.locality}
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
            ]}
            redirect={false}
          >
            <HoverRowEditDeleteButtons
              removeNotificationMessage={`Firma "${record.name}" bude smazána`}
              // TODO: Finish the delete logic - this is only to fix console errors
              removeTimeoutFunction={() => null}
              onRemoveCancel={() => null}
              onRemoveOnClick={() => null}
              editLink={`/company/${record.id}/edit`}
            />
          </Protect>
        </>
      ),
    },
  ];
  // wrong infer from js file, lets just ignore it for now
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const companyList = useSelector((state) => selectCompanies(state.views.companyList));
  const companyListPagination = useSelector(
    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (state) => selectCompanyListPagination(state.views.companyList),
  );

  // TODO: abstract pagination to a custom hook, should be doable
  useEffect(() => {
    let params = {
      $skip: companyListPageSize * (companyListCurrentPage - 1),
      $limit: companyListPageSize,
      $sort: {},
    };

    if (searchParams) {
      params = { ...params, ...searchParams };
    }

    params.$sort = sort;

    // wrong infer from js file, lets just ignore it for now
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loadCompanyThunk(params));
  }, [error, dispatch, searchParams, companyListPageSize, companyListCurrentPage, sort]);

  const onSelectChange = (newSelectedRowKeys: string[]) => {
    setSelectedRows(newSelectedRowKeys);
  };

  const onCSSZExportClick = () => {
    setShowCSSZModal(true);
  };

  const generateCSSZ = async (values: FormState<CSSZExportData>) => {
    const csszReportData = {
      company_ids: selectedRows,
      // Month counter starts with 0 (so "January" is 0)
      month: values.values.month.value + 1,
      year: values.values.year,
      batchLimit: values.values.batchLimit,
    };

    try {
      const data = await requestGenerateCsszReport(csszReportData);

      downloadBlobResponse(data, 'cssz_export.zip');
      setShowCSSZModal(false);
      SuccessAlert('Export dokončen');
    } catch {
      ErrorAlert('Při ČSSZ exportu došlo k chybě');
    }
  };

  const onTableChange = (
    _pagination: unknown,
    _filters: unknown,
    sorter: TableSorterType,
  ) => {
    if (sorter?.order) {
      switch (sorter.field) {
        case 'name':
          setSort({ name: sorter.order === 'ascend' ? 1 : -1 });
          break;
        case 'cin':
          setSort({ cin: sorter.order === 'ascend' ? 1 : -1 });
          break;
        default:
          setSort(COMPANY_LIST_DEFAULT_SORT);
          break;
      }
    } else {
      setSort(COMPANY_LIST_DEFAULT_SORT);
    }
  };

  useEffect(() => {
    setUrlParamsHandler({ sort });
  }, [sort]);

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <PageTitle>{pageTitle}</PageTitle>
          <Protect
            auth={[
              Roles.SUPER_ADMIN,
              Roles.TYMBE_ADMIN,
            ]}
            redirect={false}
          >
            <div>
              <Tooltip overlay="Vygeneruje ČSSZ ohlášky pro vybrané firmy">
                <span>
                  <SecondaryButton className="mx-5" buttontext="ČSSZ export" onClick={onCSSZExportClick} disabled={!selectedRows.length} />
                </span>
              </Tooltip>
              <ExportCSSZModal
                onSubmit={generateCSSZ}
                showModal={showCSSZeModal}
                setShowModal={setShowCSSZModal}
                companyCount={selectedRows.length}
                isLoading={isGenerateCsszLoading}
              />
              <Link to="/company/create" about="Navigates to page for creating new company">
                <PrimaryButton buttontext="Nová firma" />
              </Link>
            </div>
          </Protect>
        </div>
        <TableHeaderControls>
          <SearchBox
            name="companySearch"
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            onParamsSearch={onSearch}
            loading={!!loading}
          />
        </TableHeaderControls>
        <AdminTable
          rowKey={(record: CompanyData) => record.id}
          bordered
          size="small"
          className="ty-table"
          columns={columns}
          data={{ ...companyListPagination, data: companyList }}
          rowClassName="ty-pointer-cursor ty-row-hover"
          onPaginationChange={onCompanyListPagination}
          currentPage={companyListCurrentPage}
          onChange={onTableChange}
          pageSizeOptions={[10, 20, 50, 100, 500]}
          defaultPageSize={companyListPageSize}
          rowSelection={{
            selectedRows,
            onChange: onSelectChange,
          }}
        />
      </Wrapper>
    </Container>
  );
};

export default CompanyListPage;
