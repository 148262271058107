import { Link } from 'react-router-dom';

import { PersonData } from '../../types/TymbeApi';

interface UserLinkProps {
  person: PersonData;
  children?: Node | null;
  target?: string;
}

const UserLink = ({ person, children = null, target = undefined }: UserLinkProps) => (
  <Link
    to={{
      pathname: `/user/${person.id}`,
    }}
    state={{
      tymberProps: { user: { ...person } },
    }}
    target={target}
    className="ty-poppins-12"
  >
    {children || `${person?.first_name} ${person?.last_name}`}
  </Link>
);

export default UserLink;
