import { useContext, useRef } from 'react';
import { createPortal } from 'react-dom';

import DelayedNotification from './DelayedNotification';
import { NotificationContext } from '../../contexts/Notifications/NotificationsContext';

const NotificationPanel = () => {
  const notificationsDOM = useRef(document.getElementById('notificationsPanel'));
  const ctx = useContext(NotificationContext);

  if (!notificationsDOM.current) throw new Error('Could not find element by id: notificationsPanel');

  return createPortal(
    <div
      className="flex flex-col-reverse gap-3"
    >
      {ctx.notifications.map((x) => (
        <DelayedNotification
          onClick={() => {
            x.options?.onClick?.();
            ctx.removeNotification(x.id);
          }}
          onTimeout={() => {
            ctx.removeNotification(x.id);
          }}
          key={x.id}
        >
          {x.message}
        </DelayedNotification>
      ))}
    </div>,
    notificationsDOM.current,
  );
};

export default NotificationPanel;
