// eslint-disable-next-line import/no-extraneous-dependencies
import { MessageFormatElement } from '@formatjs/icu-messageformat-parser/types';
import React, { useState, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { IntlProvider } from 'react-intl';
import { unstable_HistoryRouter as Router } from 'react-router-dom';

import './assets/less/App.less';
import './assets/css/style.css';

import { ApiProvider } from './apiClient/ApiContext';
import NotificationPanel from './components/notifications/NotificationPanel';
import PopupNotification from './components/PopupNotification/PopupNotification';
import LocaleContext from './contexts/LocaleContext';
// eslint-disable-next-line import/no-named-as-default
import NotificationProvider from './contexts/Notifications/NotificationProvider';

import messagesCS from './lang/cs.json';
import messagesUA from './lang/ua.json';
import history from './navigator';
import AppRoutes from './routes';

ReactGA.initialize('G-QPEV9FKYH4', {
  // Uncomment to enable debug mode
  // gaOptions: {
  //   debug_mode: true,
  // },
  // gtagOptions: {
  //   debug_mode: true,
  // },
});
interface RootProps {
  authenticated: boolean;
  locale: string;
  messages: Record<string, MessageFormatElement[]> | null;
}

const App: React.FC<RootProps> = () => {
  const [locale, setLocale] = useState('cs');
  const contextValue = useMemo(
    () => ({ locale, setLocale }),
    [locale, setLocale]
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      <IntlProvider
        locale={locale}
        messages={locale === 'cs' ? messagesCS : messagesUA}
        defaultLocale="cs"
      >
        <NotificationProvider>
          <ApiProvider>
            <Router history={history}>
              <AppRoutes />
            </Router>
            <PopupNotification />
            <NotificationPanel />
          </ApiProvider>
        </NotificationProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default App;
