const downloadBlobResponse = (content: Blob, fileName: string) => {
  const url = URL.createObjectURL(content);

  // Create a hidden download link
  const downloadLink = document.createElement('a');
  downloadLink.href = url;

  // Set the 'download' attribute using setAttribute
  downloadLink.setAttribute('download', fileName);

  // Trigger a click event to initiate the download
  downloadLink.click();

  // Remove the download link from the DOM
  downloadLink.remove();
};

export default downloadBlobResponse;
