import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

/** @type React.FC<PropTypes.InferType<ResponsiveGrid.propTypes>> */
export const ResponsiveGrid = ({
  gutter = [16, 24],
  justify = 'space-around',
  align = 'middle',
  span = undefined,
  xs = 20,
  sm = 18,
  md = 16,
  lg = 14,
  xl = 12,
  children,
}) => (
  <Row
    gutter={gutter || [16, 24]}
    justify={justify || 'space-around'}
    align={align || 'middle'}
    style={{ marginLeft: 0, marginRight: 0 }}
  >
    <Col
      span={span}
      style={{ paddingLeft: 0, paddingRight: 0 }}
      xs={!span && (xs || 20)}
      sm={!span && (sm || 18)}
      md={!span && (md || 16)}
      lg={!span && (lg || 14)}
      xl={!span && (xl || 12)}
    >
      {children}
    </Col>
  </Row>
);
ResponsiveGrid.propTypes = {
  justify: PropTypes.string,
  gutter: PropTypes.arrayOf(PropTypes.number),
  span: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  align: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

/** @type React.FC<PropTypes.InferType<FixedGrid.propTypes>> */
export const FixedGrid = ({ justify = undefined, align = undefined, children }) => (
  <Row
    gutter={[16, 24]}
    justify={justify ?? 'space-around'}
    align={align ?? 'middle'}
    style={{ marginLeft: 0, marginRight: 0 }}
  >
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      {children}
    </Col>
  </Row>
);
FixedGrid.propTypes = {
  justify: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

export const SemiColumn = ({ children }) => (
  <Col style={{ textAlign: 'center' }} xs={24} sm={24} md={12} lg={12} xl={12}>
    {children}
  </Col>
);
SemiColumn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};
