import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import feathersClient from '../../../apiClient';

export const resetPasswordThunk = createAsyncThunk('auth/resetPassword', async (data) => {
  const { token, password } = data;
  await feathersClient.service('auth-management').create({
    action: 'resetPassword',
    token,
    password,
  });
});

const resetPasswordReducer = {
  [resetPasswordThunk.pending]: (state) => ({
    ...state,
    loading: true,
    error: null,
  }),
  [resetPasswordThunk.rejected]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message,
    data: null,
  }),
  [resetPasswordThunk.fulfilled]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data,
  }),
};

const resetPasswordSlice = createSlice({
  name: 'resetPasswordContainer',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    ...resetPasswordReducer,
  },
});

export const selectResetPassword = (state) => state.data;

export default resetPasswordSlice.reducer;
