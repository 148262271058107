import classNames from 'classnames';
import { ComponentProps } from 'react';

type ProgressBackgroundOwnProps = {
  className: string,
  progress: number,
};

type ProgressBackgroundProps = ProgressBackgroundOwnProps &
Omit<ComponentProps<'div'>, keyof ProgressBackgroundOwnProps>;

const ProgressBackground = ({ className, progress, ...rest }: ProgressBackgroundProps) => {
  const width = `${progress * 100}%`;
  return (
    <div
      className={
        classNames(
          'absolute h-full pointer-events-none',
          className,
        )
      }
      style={{ width }}
      {...rest}
    />
  );
};

export default ProgressBackground;
