import { Query } from '@feathersjs/feathers';
import { UtilityData } from '@tymbe/schema/utility.interface';
import { useCallback } from 'react';
import { GroupBase, OptionsOrGroups } from 'react-select';

import feathersClient from '../../../apiClient';
import { isPaginated } from '../../../types/TymbeApi';
import TyAsyncSelect, { TyAsyncSelectProps } from '../TyAsyncSelect';

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';

interface Props {
  filter?: { name: string | string[] };
}
type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, UtilityData, GroupBase<UtilityData>, void, boolean> & Props;
type TySelectRoleProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps>;

const loadUtilityOptions = (
  defaultOptionsCount?: number,
  filter?: Props['filter'],
) => async (
  search: string,
  options: OptionsOrGroups<UtilityData, GroupBase<UtilityData>>,
) => {
  const iLike = { $ilike: `%${search}%` };

  const query: Query = {
    $skip: options.length - (defaultOptionsCount || 0),
  };

  if (search !== '' && !Number.isNaN(Number(search))) {
    query.id = Number(search);
  } else {
    query.$or = [{ name: iLike }];
  }

  if (filter?.name) {
    const name = Array.isArray(filter.name) ? filter.name : [filter.name];
    query.name = { $in: name };
  }

  const resp = await feathersClient.service('utility').find({
    query,
  });

  if (!isPaginated(resp)) {
    return {
      options: Array.isArray(resp) ? resp : [resp],
      hasMore: false,
    };
  }

  return {
    options: resp.data,
    hasMore: resp.total > options.length + resp.data.length,
  };
};

const TySelectUtility = <Fields extends object>({
  debounceTimeout = 1000,
  defaultOptions = [],
  filter,
  ...props
}: TySelectRoleProps<Fields>) => {
  const defaultOptionsCount = Array.isArray(defaultOptions) ? defaultOptions.length : 0;
  const loadOptions = useCallback(
    () => loadUtilityOptions(defaultOptionsCount, filter),
    [defaultOptionsCount, filter],
  );

  return (
    <TyAsyncSelect
      {...props}
      defaultOptions={defaultOptions}
      debounceTimeout={debounceTimeout}
      loadOptions={loadOptions()}
      getOptionLabel={(option) => (`[${option.id}] ${option.name}`)}
      getOptionValue={(option) => String(option.id)}
    />
  );
};

export default TySelectUtility as typeof TySelectUtility;
