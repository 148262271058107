import { FieldProps, useField } from 'informed';
import { Moment } from 'moment';
import Picker from 'rc-picker';
import { PickerProps } from 'rc-picker/es/Picker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import csCZ from 'rc-picker/lib/locale/cs_CZ';
import { useRef, useEffect } from 'react';

import styles from './TyDateRange.module.css';
import ArrowRightAltIcon from '../../icons/ArrowRightAltIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import TyInputError from '../TyInputError';

export type TyDateRangeData = {
  from?: Moment | null;
  to?: Moment | null;
};

type Props = Omit<Partial<PickerProps<Moment>>, 'disabledTime' | 'allowClear' | 'picker'> & {
  label?: string,
  disabled?: boolean,
  className?: string,
  allowIllegalRange?: boolean,
  sameMonth?: boolean,
  sameYear?: boolean,
  fromDisabledDate?: (current: Moment) => boolean,
  toDisabledDate?: (current: Moment) => boolean,
};

type TyDateRangeProps<Fields extends object> = FieldProps<Props, Moment, Fields>;

const TyDateRange = <Fields extends object>({ ...props }: TyDateRangeProps<Fields>) => {
  const toPickerRef = useRef<Picker<Moment>>(null);

  const {
    render,
    fieldState,
    userProps,
    fieldApi,
  } = useField<TyDateRangeProps<Fields>, TyDateRangeData>({ ...props });

  const { error, showError, focused, value } = fieldState;
  const { setValue, setTouched, setFocused, validate } = fieldApi;
  const {
    name,
    id,
    label,
    format = ['L', 'D.M.YYYY'],
    locale = csCZ,
    onChange,
    onFocus,
    onBlur,
    className,
    sameMonth = false,
    sameYear = false,
    disabled = false,
    required = false,
    allowIllegalRange = false,
    fromDisabledDate,
    toDisabledDate,
    ...rest
  } = userProps;

  // On whatever input change, validate that the range is legal
  useEffect(() => {
    if (allowIllegalRange) return;
    if (!value?.from || !value?.to) return;
    if (value?.from < value?.to) return;

    setValue({ ...value, to: undefined });
  }, [allowIllegalRange, value, setValue]);

  // On whatever input change, validate that the is in the same unitOfTime granularity
  useEffect(() => {
    let unitOfTime: 'year' | 'month' | undefined;
    if (sameYear) unitOfTime = 'year';
    if (sameMonth) unitOfTime = 'month';

    if (!unitOfTime) return;
    if (!value?.from || !value?.to) return;

    if (value.from.isSame(value.to, 'month')) return;
    setValue({ ...value, to: undefined });
  }, [sameMonth, sameYear, setValue, value]);

  // Validate only when both inner inputs have been populated
  useEffect(() => {
    if (!value?.from || !value?.to) return;
    validate();
  }, [validate, value]);

  const onInternalFocus = () => {
    setTouched(true);
    setFocused(true);
  };

  const onInternalBlur = () => {
    setFocused(false);
  };

  const onFromSet = (val: Moment | null) => {
    // Trigger a focus on the "to" input when "from" changes
    if (toPickerRef.current) {
      // TODO: Figure out why focus does not trigger the popout date selection window
      toPickerRef.current.focus();
    }

    setValue({ ...value, from: val });
  };

  const disableDateEarlierThanFrom = (current: Moment): boolean => {
    if (!value?.from) return false;
    return current.isBefore(value?.from, 'day');
  };

  const disableNotInTheSameUnitOfTime = (current: Moment, granularity: 'month' | 'day' | 'year'): boolean => {
    if (!value?.from) return false;
    return !current.isSame(value?.from, granularity);
  };

  const internalDisableToDate = (current: Moment): boolean => {
    let unitOfTime: 'year' | 'month' | undefined;
    if (sameYear) unitOfTime = 'year';
    if (sameMonth) unitOfTime = 'month';

    if (!allowIllegalRange && !toDisabledDate) {
      if (unitOfTime) return disableDateEarlierThanFrom(current) || disableNotInTheSameUnitOfTime(current, unitOfTime);
      return disableDateEarlierThanFrom(current);
    }

    // If custom toDisabledDate is provided, use it
    return !!(toDisabledDate && toDisabledDate(current));
  };

  const focusedClassName = focused ? 'ty_input_field__focused' : '';
  const filledClassName = value ? 'ty_input_field__filled' : '';
  const disabledClassName = disabled ? 'ty_input_field--is-disabled' : '';

  return render(
    <div
      onFocus={onInternalFocus}
      onBlur={onInternalBlur}
      id={id}
      className={`ty_input_field ty_rc_picker ${showError && error ? 'ty_input_error' : ''}
       ${styles.ty_date_range_wrapper} ${focusedClassName} ${filledClassName} ${disabledClassName} ${className ?? ''}`}
    >
      <Picker<Moment>
        name="from"
        dropdownClassName="ty_input_dropdown"
        className={`${styles.ty_date_range} ty_input flex px-1 z-10`}
        picker="date"
        locale={locale}
        onChange={onFromSet}
        generateConfig={momentGenerateConfig}
        format={format}
        value={value?.from || null}
        disabled={disabled}
        disabledDate={fromDisabledDate}
        onFocus={onInternalFocus}
        {...rest}
      />
      {label ? <label className="z-0" htmlFor={id}>{label}</label> : null}
      <ArrowRightAltIcon height={30} width={30} />
      <Picker<Moment>
        name="to"
        picker="date"
        value={value?.to || null}
        locale={locale}
        className={`${styles.ty_date_range} ty_input flex px-1 z-10`}
        dropdownClassName="ty_input_dropdown"
        onChange={(val) => setValue({ ...value, to: val })}
        generateConfig={momentGenerateConfig}
        ref={toPickerRef}
        disabledDate={internalDisableToDate}
        format={format}
        onFocus={onInternalFocus}
        disabled={disabled}
        {...rest}
      />
      <div className="mr-10">
        <CalendarIcon width={60} height={60} />
      </div>
      <TyInputError
        fieldState={fieldState}
        required={required}
        validate={props.validate}
      />
    </div>,
  );
};

export default TyDateRange;
