import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { PrimaryButton, SubmitButton } from '../../components/buttons';
import { FixedGrid, ResponsiveGrid } from '../../components/grids';
import { UserAddIcon, UserIcon } from '../../components/icons';
import Illustration from '../../components/illustration';
import { LockPick } from '../../components/illustrations';
import { SimpleFormInput, SimpleFormItem } from '../../components/inputs';
import { ErrorResult, SuccessResult } from '../../components/results';
import { LinkText, SimpleHeading3Text } from '../../components/texts';
import { darkBlue, white } from '../../utils/colors';
import Spinner from '../../utils/spinner';

const ForgotContainer = (props) => {
  const { error, loading, emailSent, onFinish, onFinishFailed } = props;

  const renderResult = (renderError) => {
    const errorMessage = `${renderError} Prosím zkuste znovu.`;
    if (loading) return <Spinner loadingtext="Posíláme e-mail. Prosím čekejte." />;
    return renderError ? (
      <ErrorResult
        title="Nepodařilo se zaslat e-mail. Opakujte později."
        subTitle={errorMessage}
        extra={[
          <PrimaryButton key="signin">
            <LinkText
              style={{ color: white }}
              linkTo="/signin"
              linkText="Přihlásit se"
            />
          </PrimaryButton>,
        ]}
      />
    ) : (
      <SuccessResult
        title="E-mail úspěšně zaslán"
        subTitle="Dostanete e-mail s odkazem. Otevřete zaslaný odkaz pro resetování hesla."
        extra={[
          <PrimaryButton key="signin">
            <LinkText
              style={{ color: white }}
              linkTo="/signin"
              linkText="Přihlásit se"
            />
          </PrimaryButton>,
        ]}
      />
    );
  };
  const renderForm = () => (
    <>
      <Illustration svg={<LockPick />} />
      <ResponsiveGrid xs={24} xl={12}>
        <Row className="ty-page-bottom" align="center">
          <FixedGrid>
            <div style={{ padding: '7px 0 12px 0' }}>
              <SimpleHeading3Text>Nepamatuješ si heslo?</SimpleHeading3Text>
            </div>
          </FixedGrid>
          <Col span={21}>
            <FixedGrid>
              <p className="ty-plaintext-14">
                To nic, zadej e-mailovou adresu a my ti pomůžeme heslo obnovit
              </p>
            </FixedGrid>
          </Col>
          <Col span={22}>
            <Form
              hideRequiredMark
              name="forgot_password_form"
              initialValues={{
                remember: true,
              }}
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <SimpleFormInput
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Prosím vložte Váš e-mail',
                  },
                  {
                    type: 'email',
                    message: 'Prosím vložte platný e-mail',
                  },
                ]}
                placeholder="E-mail"
              />

              <SimpleFormItem>
                <Row
                  gutter={[16, 24]}
                  align="middle"
                  style={{ paddingBottom: '15px', paddingTop: '8px' }}
                >
                  <Col
                    style={{ textAlign: 'center', marginBottom: '5px' }}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={6}
                    xl={6}
                  >
                    <SubmitButton size="large" buttontext="Obnovit heslo" />
                  </Col>
                </Row>

                <Row
                  style={{ paddingBottom: '15px', paddingTop: '8px' }}
                >
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row className="grid grid-cols-4 w-full">
                      <Col className="col-span-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <UserIcon iconcolor="#B3CA1F" />
                      </Col>
                      <Col className="col-span-2 text-nowrap" style={{ alignItems: 'center' }}>
                        <Link
                          style={{
                            color: '#202020',
                            fontWeight: '500',
                          }}
                          to="/signin"
                        >
                          Přihlásit se
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </SimpleFormItem>
            </Form>
          </Col>
        </Row>
      </ResponsiveGrid>
    </>
  );

  return emailSent ? renderResult(error) : renderForm();
};

ForgotContainer.propTypes = {
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  emailSent: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
};

export default ForgotContainer;
