import getResponseArray from '@tymbe/utils/getResponseArray';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import Chip from './Chip';
import apiClient from '../../../apiClient';

type TemplateFilterProps = {
  start: string;
  onChange?: (filters: number[]) => void;
  companyId?: number;
  branchofficeId?: number;
};

const useCalendarTemplates = (start: string, selected?: number[], filter: { companyId?: number, branchofficeId?: number } = {}) => useQuery(
  ['shift-template', 'calendar', start, filter],
  async () => {
    const query = {
      $limit: -1,
      $joinRelation: 'shift(withApplicationCounts)',
      $or: [
        { 'shift_template.id': { $in: selected } },
        {
          start_time: {
            $gte: moment(start).startOf('week').toISOString(),
            $lte: moment(start).endOf('week').toISOString(),
          },
          'shift.company_id': filter.companyId,
          'shift.branchoffice_id': filter.branchofficeId,
          'shift.orders_count': { $gt: 0 },
        },
      ],
    };
    return apiClient.service('shift-template').find({ query });
  },
  { staleTime: 1000 * 5, keepPreviousData: true },
);

const TemplateFilter = ({ start, onChange, companyId, branchofficeId }: TemplateFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = searchParams.get('templates')?.split(',').map(Number) || [];
  const templatesReq = useCalendarTemplates(start, filters, { companyId, branchofficeId });
  const templates = getResponseArray(templatesReq.data);

  const toggleFilter = (id: number) => {
    const newFilters = filters.includes(id) ? filters.filter((item) => item !== id) : [...filters, id];
    if (newFilters.length === 0) {
      searchParams.delete('templates');
    } else {
      searchParams.set('templates', newFilters.join(','));
    }
    setSearchParams(searchParams);
  };

  useEffect(
    () => {
      onChange?.(filters);
    },
    // filters as an array is always new reference which will trigger onChange indefinitely
    // since it's generated from searchParams (without any changes) we can safely use it as a dependency
    // searchParams.get('templates') is always a string, which is compared by value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams.get('templates'), onChange],
  );

  return (
    <div className="flex no-wrap gap-2 mb-4 pb-1 overflow-auto h-9">
      {templates.map((template) => (
        <Chip
          className="cursor-pointer"
          emoji={template.emoji}
          shiftName={template.template_name}
          key={template.id}
          onClick={() => toggleFilter(template.id)}
          isSelected={filters.includes(template.id)}
        />
      ))}
    </div>
  );
};

export default TemplateFilter;
