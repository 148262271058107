import classNames from 'classnames';
import { ComponentProps } from 'react';

import ChevronIcon from '../icons/ChevronIcon';

type CalendarImageOwnProps = {
  className?: string,
};
type CalendarImageProps = CalendarImageOwnProps & Omit<ComponentProps<'div'>, keyof CalendarImageOwnProps>;

const CalendarImage = ({ className = '' }: CalendarImageProps) => {
  const dayNames = ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'];
  const days = [
    [1, 8, 15, 22, 29],
    [2, 9, 16, 23, 30],
    [3, 10, 17, 24, 1],
    [4, 11, 18, 25, 2],
    [5, 12, 19, 26, 3],
    [6, 13, 20, 27, 4],
    [7, 14, 21, 28, 5],
  ];
  const isPicked = (num: number) => {
    if (num === 15 || num === 17 || num === 19) return true;
    return false;
  };
  return (
    <div className={classNames('flex justify-center items-center', className)}>
      <div className="w-[165px] h-[165px] bg-secondary-50 rounded-full absolute" />
      <div className="border-[0.5px] border-secondary-200 rounded-[4px] p-1 -rotate-[15deg] bg-bg">
        <div className="flex items-center justify-evenly gap-8">
          <ChevronIcon className="scale-[-1] w-2 text-secondary-300" />
          <div className="text-[7px] leading-[10px] font-medium text-secondary-300">
            Duben 2024
          </div>
          <ChevronIcon className=" w-2 text-secondary-300" />
        </div>
        <div className="grid grid-cols-7 w-full">
          {dayNames.map((name, index) => (
            <div key={name}>
              <div
                className="w-[18px] h-[18px] text-center font-semibold text-secondary-300 text-[7px] leading-[18px]"
              >
                {name}
              </div>
              {days[index].map((num) => (
                <div
                  key={num}
                  className={classNames(
                    'w-[18px] h-[18px] text-center font-medium text-[7px] leading-[18px] rounded-[2px]',
                    { 'text-bg bg-secondary-300': isPicked(num) },
                    { 'text-secondary-300': !isPicked(num) },
                  )}
                >
                  {num}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default CalendarImage;
