import { paramsForServer } from 'feathers-hooks-common';
import Tooltip from 'rc-tooltip';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import { PrimaryButton } from '../../../../components/buttons';
import { TrashIcon } from '../../../../components/icons';
import AlertModal from '../../../../components/modals/AlertModal';
import { ShiftData } from '../../../../types/TymbeApi';

interface Props extends Partial<ShiftData> {
  shiftId: number;
  isEmpty?: boolean
  disabled?: boolean;
}

const DeleteOrder = ({ shiftId, isEmpty = false, disabled = false }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const navigator = useNavigate();

  const { mutateAsync: deleteApplication } = useMutation(
    ['deleteApplication', shiftId],
    (sendEmails: boolean): Promise<ShiftData> =>
      feathersClient.service('shift')
        .remove(
          shiftId,
          paramsForServer({
            sendEmails,
          }),
        ),
  );

  const deleteWholeOrder = async (sendEmails: boolean) => {
    try {
      if (shiftId) await deleteApplication(sendEmails);
      setShowModal(false);
      navigator('/shift');
    } catch (e: unknown) {
      if (e && typeof e === 'object' && Object.hasOwn(e, 'code') && e.code === 403) {
        ErrorAlert('Není možno smazat objednávku, která již proběhla nebo probíhá a je na ní někdo přihlášen.');
      } else {
        ErrorAlert('Objednávku není možné smazat');
      }
      setShowModal(false);
    }
  };

  return (
    <>
      <Tooltip
        overlay={
          disabled
            ? 'Není možno smazat objednávku, která již proběhla nebo probíhá a je na ní někdo přihlášen.'
            : 'Zrušit objednávku'
        }
        placement="right"
      >
        <PrimaryButton
          disabled={disabled}
          title={
            disabled
            && 'Není možno smazat objednávku, která již proběhla nebo probíhá a je na ní někdo přihlášen.'
          }
          className="ty-icon-button ty-icon-button-toggle border-1"
          onClick={() => setShowModal(true)}
          icon={<TrashIcon />}
        />
      </Tooltip>
      <AlertModal
        title="Smazání objednávky"
        message={isEmpty ? 'Opravdu si přejete objednávku smazat' : 'Chcete přihlášené uživatele upozornit na zrušení?'}
        yesButtonText="Ano"
        noButtonText="Ne"
        showCancel={false}
        onClose={() => setShowModal(false)}
        onYes={async () => deleteWholeOrder(!isEmpty)}
        onNo={async () => (isEmpty ? setShowModal(false) : deleteWholeOrder(false))}
        showModal={showModal}
      />
    </>
  );
};

export default DeleteOrder;
