import classNames from 'classnames';
import { useFormApi } from 'informed';
import { ComponentProps, ReactNode } from 'react';

import MinusIcon from '../icons/MinusIcon';
import PlusIcon from '../icons/PlusIcon';

type AvailableSpaceOwnProps = {
  value: ReactNode,
  onPlus: () => Promise<void> | (() => void),
  onMinus: () => Promise<void> | (() => void),
  disabled?: boolean,
};
type AvailableSpaceProps = AvailableSpaceOwnProps & Omit<ComponentProps<'div'>, keyof AvailableSpaceOwnProps>;

const AvailableSpace = ({ value, onPlus, onMinus, disabled, className = '', ...rest }: AvailableSpaceProps) => {
  const formApi = useFormApi();
  return (
    <div className={classNames('p-4 rounded-xl border border-secondary-100', className)} {...rest}>
      <p className="font-medium text-sm mb-3">Volných míst</p>
      <div
        className="h-8 flex items-center justify-between border border-secondary-400 rounded-lg px-1.5 py-1.5 box-border"
      >
        <button
          type="button"
          className="p-0"
          disabled={disabled}
          onClick={async () => {
            await onMinus();
            formApi.reset();
          }}
          aria-label="minus button"
        >
          <MinusIcon className="w-4" />
        </button>
        <div className="font-medium relative">
          {value}
        </div>
        <button
          type="button"
          className="p-0"
          disabled={disabled}
          onClick={async () => {
            await onPlus();
            formApi.reset();
          }}
          aria-label="plus button"
        >
          <PlusIcon className="w-4" />
        </button>
      </div>
    </div>
  );
};

export default AvailableSpace;
