import { Layout, Typography, Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageHeader from './PageHeader';
import { selectIsAuthenticated } from '../../data/store/slices/auth';
import { black, white } from '../../utils/colors';
import NotfoundImage from '../partials/NotfoundImage';

const { Content } = Layout;
const { Text } = Typography;

const NotFound = () => {
  const history = useNavigate();
  const isUserAuthenticated = useSelector(selectIsAuthenticated);

  const gotoPage = () => {
    if (isUserAuthenticated) history('/signin');
    else history('/');
  };

  return (
    <Layout>
      <PageHeader iconcolor={black} background={white} />
      <Content style={{ marginTop: '2%' }}>
        <Row justify="center" align="middle">
          <Col>
            <NotfoundImage />
            <Row justify="center" align="middle">
              <Col>
                <Text style={{ textAlign: 'center' }} strong>
                  Tato stránka neexistuje.
                </Text>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Col style={{ marginTop: '40px' }}>
                <Button
                  className="ty-button ty-button-primary"
                  style={{ color: { white } }}
                  onClick={gotoPage}
                  type="primary"
                >
                  Zpět domů
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default NotFound;
