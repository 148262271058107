import { Modal } from 'antd';
import PropTypes from 'prop-types';

/**
 * @deprecated
 * Use react-modal instead.
 */

const SimpleModal = ({
  title = '',
  visible,
  okText = '',
  showCancelButton = false,
  cancelText = '',
  onOk,
  onCancel,
  children,
  confirmLoading = false,
}) => (
  <Modal
    title={title}
    open={visible}
    okText={okText}
    confirmLoading={confirmLoading}
    okButtonProps={{
      className: 'ant-btn ant-btn-primary h-8 bg-primary ',
      style: { fontSize: 14, padding: '4px 15px' },
    }}
    cancelButtonProps={
      !showCancelButton ? {
        style: { display: 'none' },
      } : {
        className: 'ant-btn ant-btn-secondary h-8 ',
        style: { fontSize: 14, padding: '4px 15px' },
      }
  }
    cancelText={cancelText}
    onOk={onOk}
    onCancel={onCancel}
    className="ty-modal"
  >
    {children}
  </Modal>
);
SimpleModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool,
  okText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  cancelText: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

export default SimpleModal;
