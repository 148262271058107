import { Input, Form, useFieldApi, FormState } from 'informed';

import { SecondaryButton, SubmitButton } from '../../../../components/buttons';
import ChevronRight from '../../../../components/icons/ChevronRight';

export interface PayoutAmountFormProps {
  onSubmitPayoutAmount?: (value: number | string | null) => void
}

export interface PayoutAmountFormValuesProps {
  payoutAmountInput: string | number;
}

const ResetButton = ({ onSubmitPayoutAmount }: PayoutAmountFormProps) => {
  const { reset } = useFieldApi('payoutAmountInput');
  return (
    <SecondaryButton
      className="flex border border-solid text-xs text-center"
      onClick={() => { onSubmitPayoutAmount?.(null); reset(); }}
    >
      <span>reset</span>
    </SecondaryButton>
  );
};

const PayoutAmountForm = ({ onSubmitPayoutAmount }: PayoutAmountFormProps) => {
  const validateInput = (val: string | number) => {
    if (!/^[0-9\s]+$/.test(String(val))) return 'Pouze čísla!';
    return undefined;
  };
  return (
    <div>
      <Form
        className="flex pb-2 items-end"
        onSubmit={({ values }: FormState<PayoutAmountFormValuesProps>) => {
          onSubmitPayoutAmount?.(values.payoutAmountInput);
        }}

      >
        <div className="flex-col">
          Chci vyplatit částku (v Kč)
          <div className="flex-col w-72">
            <Input
              className="border border-solid rounded-lg p-2 outline-none text-sm font-medium leading-4 w-full h-full bg-white focus:border-primary focus:outline-none"
              placeholder="např. 10000"
              name="payoutAmountInput"
              type="text"
              validate={validateInput}
              validateOn="change"
            />
          </div>
        </div>
        <SubmitButton className="ty-icon-button mx-2 ty-icon-button-toggle bg-primary" icon={<ChevronRight />} />
        <ResetButton onSubmitPayoutAmount={onSubmitPayoutAmount} />
      </Form>

    </div>
  );
};
export default PayoutAmountForm;
